
import { LabelRole } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { ButtonSize, ButtonType, NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { ConnectionType } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { getSpriteAsIconStyle } from '../../sprites';
import { checkConnectionType, getCommandConditional, getPopupTooltipConditional, isACICSA, isCSA, isPirAS, isPirEU } from './static-menu-helper';

export function createC2CMenu_ExistingReinforcement(design: DesignC2C): TabC2C {
    return {
        DisplayKey: (isPirEU(design) && checkConnectionType(design, ConnectionType.StructuralJoints)) ?
            'Agito.Hilti.C2C.Navigation.Reinforcement.ConcreteReinforcement' : 'Agito.Hilti.C2C.Navigation.ExistingReinforcement',
        Id: 132,
        Name: 'existingreinforcement-tab', // if you rename this also rename this value in menu service (updateAllControls)
        Image: 'tab-existing-reinforcement',
        IconImage: getSpriteAsIconStyle('sprite-tab-existing-reinforcement'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createC2CMenu_ExistingReinforcement_Materials(design),
            createC2CMenu_ExistingReinforcement_LongitudinalReinforcement(),
            createC2CMenu_ExistingReinforcement_TransverseReinforcement(design),
            createC2CMenu_ExistingReinforcement_CastInSplicedReinforcement(design),
            createC2CMenu_ExistingReinforcement_ConcreteReinforcement()
        ]
    };
}

function createC2CMenu_ExistingReinforcement_Materials(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.Reinforcement.Materials',
        Id: 27,
        Name: 'materials-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'materials-fyk',
                TitleDisplayKey: isPirAS(design) ? 'Agito.Hilti.C2C.Reinforcement.Fsy' : 'Agito.Hilti.C2C.Reinforcement.Fyk',
                UIPropertyId: 10524,
                UnitGroup: UnitGroup.Stress,
            }
        ]
    };
}

function createC2CMenu_ExistingReinforcement_LongitudinalReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.LongitudinalReinforcement',
        Id: 28,
        Name: 'longitudinal-reinforcement-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                Name: 'LongitudinalReinforcement.LinkReinforcement',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.LinkReinforcement',
                Size: 100,
                UIPropertyId: 10740
            },
            {
                CodelistName: 'LongitudinalReinforcementOption',
                ControlType: 'Dropdown',
                Name: 'longitudinal-reinforcement-options',
                Size: 100,
                UIPropertyId: 10525
            },
            {
                ControlType: 'TabGroup',
                Name: 'longitudinal-reinforcement-layers-tab',
                Tabs: [
                    {
                        DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                        Name: 'longitudinal-reinforcement-layers-tab-top-layer',
                        Tag: 'top-layer'
                    },
                    {
                        DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                        Name: 'longitudinal-reinforcement-layers-tab-bottom-layer',
                        Tag: 'bottom-layer'
                    }
                ]
            },
            {
                CodelistName: 'ShapeType',
                ControlType: 'RadioButtonGroup',
                Name: 'longitudinal-reinforcement-shape-radio-buttons',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Shape',
                UIPropertyId: 10531,
            },
            {
                CodelistName: 'BondCondition',
                ControlType: 'RadioButtonGroup',
                Name: 'longitudinal-reinforcement-bond-radio-buttons',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.BondCondition',
                UIPropertyId: 10530
            },
            {
                CodelistName: 'GenericRebarDiameter',
                ControlType: 'Dropdown',
                Name: 'longitudinal-reinforcement-diameter-new',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                UIPropertyId: 10526,
                UnitGroup: UnitGroup.Length
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-number-of-bars',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBars',
                UIPropertyId: 10646
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-number-of-bars-x',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBarsX',
                UIPropertyId: 10527
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-number-of-bars-y',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBarsY',
                UIPropertyId: 10528
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-cover',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                UIPropertyId: 10529,
                UnitGroup: UnitGroup.Length
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-arrangement-frontCover',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.FrontCover',
                UIPropertyId: 10709,
                UnitGroup: UnitGroup.Length,
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                Name: 'longitudinal-reinforcement-top-layer',
                UIPropertyId: 10532,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'Dropdown',
                Name: 'longitudinal-reinforcement-top-layers-count',
                UIPropertyId: 10533,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.NumberOfLayers',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                CodelistName: 'NumberOfLayers'
            },
            {
                CodelistName: 'ShapeType',
                ControlType: 'RadioButtonGroup',
                Name: 'longitudinal-reinforcement-top-shape-type-radio-buttons',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Shape',
                UIPropertyId: 10541,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                CodelistName: 'BondCondition',
                ControlType: 'RadioButtonGroup',
                Name: 'longitudinal-reinforcement-top-bond-condition-radio-buttons',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BondCondition',
                UIPropertyId: 10542,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-layer-top-frontCover',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.FrontCover',
                UIPropertyId: 10708,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'Label',
                Name: 'longitudinal-reinforcement-top-layer-one',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerOne',
                UIPropertyId: 10534,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Role: LabelRole.SubHeading
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'longitudinal-reinforcement-top-diameter-l1',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                UIPropertyId: 10535,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                CodelistName: 'SpacingBarsOption',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                ControlType: 'RadioButtonGroup',
                Name: 'longitudinal-reinforcement-top-spacing-bars-l1',
                UIPropertyId: 10536,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-bars-l1',
                UIPropertyId: 10538,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-spacing-l1',
                UIPropertyId: 10537,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-top-cover-l1',
                UIPropertyId: 10539,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-side-cover-l1',
                UIPropertyId: 10540,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-min-side-cover-l1',
                UIPropertyId: 10744,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'Label',
                Name: 'longitudinal-reinforcement-top-layer-two',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerTwo',
                UIPropertyId: 10543,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Role: LabelRole.SubHeading
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'longitudinal-reinforcement-top-diameter-l2',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                UIPropertyId: 10544,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'RadioButtonGroup',
                CodelistName: 'SpacingBarsOption',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                Name: 'longitudinal-reinforcement-top-spacing-bars-l2',
                UIPropertyId: 10545,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-bars-l2',
                UIPropertyId: 10547,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-spacing-l2',
                UIPropertyId: 10546,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-top-cover-l2',
                UIPropertyId: 10548,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-side-cover-l2',
                UIPropertyId: 10549,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-top-min-side-cover-l2',
                UIPropertyId: 10745,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Size: 50
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                Name: 'longitudinal-reinforcement-bottom-layer',
                UIPropertyId: 10552,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'Dropdown',
                Name: 'longitudinal-reinforcement-bottom-layers-count',
                UIPropertyId: 10553,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.NumberOfLayers',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                CodelistName: 'NumberOfLayers'
            },
            {
                CodelistName: 'ShapeType',
                ControlType: 'RadioButtonGroup',
                Name: 'longitudinal-reinforcement-bottom-shape-type-radio-buttons',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Shape',
                UIPropertyId: 10561,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                CodelistName: 'BondCondition',
                ControlType: 'RadioButtonGroup',
                Name: 'longitudinal-reinforcement-bottom-bond-condition-radio-buttons',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BondCondition',
                UIPropertyId: 10562,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-layer-bottom-frontCover',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.FrontCover',
                UIPropertyId: 10708,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'Label',
                Name: 'longitudinal-reinforcement-bottom-layer-one',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerOne',
                UIPropertyId: 10554,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Role: LabelRole.SubHeading
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'longitudinal-reinforcement-bottom-diameter-l1',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                UIPropertyId: 10555,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                CodelistName: 'SpacingBarsOption',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                ControlType: 'RadioButtonGroup',
                Name: 'longitudinal-reinforcement-bottom-spacing-bars-l1-radio-buttons',
                UIPropertyId: 10556,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-spacing-l1',
                UIPropertyId: 10557,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-bars-l1',
                UIPropertyId: 10558,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-top-cover-l1',
                UIPropertyId: 10559,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-side-cover-l1',
                UIPropertyId: 10560,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-min-side-cover-l1',
                UIPropertyId: 10746,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'Label',
                Name: 'longitudinal-reinforcement-bottom-layer-two',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerTwo',
                UIPropertyId: 10563,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Role: LabelRole.SubHeading
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'longitudinal-reinforcement-bottom-diameter-l2',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                UIPropertyId: 10564,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                CodelistName: 'SpacingBarsOption',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                ControlType: 'RadioButtonGroup',
                Name: 'longitudinal-reinforcement-bottom-spacing-bars-l2-radio-buttons',
                UIPropertyId: 10565,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-spacing-l2',
                UIPropertyId: 10566,
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-bars-l2',
                UIPropertyId: 10567,
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50,
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-top-cover-l2',
                UIPropertyId: 10568,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-side-cover-l2',
                UIPropertyId: 10569,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            },
            {
                ControlType: 'TextBox',
                Name: 'longitudinal-reinforcement-bottom-min-side-cover-l2',
                UIPropertyId: 10747,
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                ParentControlName: 'longitudinal-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Size: 50
            }
        ]
    };
}

function createC2CMenu_ExistingReinforcement_TransverseReinforcement(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TransverseReinforcement',
        Id: 29,
        Name: 'transverse-reinforcement-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Define',
                Name: 'transverse-reinforcement-define',
                UIPropertyId: 10593,
                TooltipType: TooltipType.Popup,
                Command: 'OpenTransverseReinforcementDescriptionPopup'
            },
            {
                CodelistName: 'TransverseReinforcementOption',
                ControlType: 'Dropdown',
                Name: 'transverse-reinforcement-options',
                UIPropertyId: 10594
            },
            {
                ControlType: 'TabGroup',
                Name: 'transverse-reinforcement-layers-tab',
                Tabs: [
                    {
                        DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                        Name: 'transverse-reinforcement-layers-tab-top-layer',
                        Tag: 'top-layer'
                    },
                    {
                        DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                        Name: 'transverse-reinforcement-layers-tab-bottom-layer',
                        Tag: 'bottom-layer'
                    }
                ]
            },
            {
                ControlType: 'Label',
                Name: 'transverse-reinforcement-top-layer-one',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.LayerOne',
                UIPropertyId: 10615,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Role: LabelRole.SubHeading
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'transverse-reinforcement-top-diameter-l1',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10597,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-top-spacing-l1',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10598,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-top-cover-l1',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.TopCover',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10599,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'Label',
                Name: 'transverse-reinforcement-top-layer-two',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.LayerTwo',
                UIPropertyId: 10616,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'top-layer',
                Role: LabelRole.SubHeading
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'transverse-reinforcement-top-diameter-l2',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10600,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-top-spacing-l2',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10601,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-top-cover-l2',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.TopCover',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10602,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'top-layer'
            },
            {
                ControlType: 'Label',
                Name: 'transverse-reinforcement-bottom-layer-one',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.LayerOne',
                UIPropertyId: 10617,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Role: LabelRole.SubHeading
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'transverse-reinforcement-bottom-diameter-l1',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10605,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-bottom-spacing-l1',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10606,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-bottom-cover-l1',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10607,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'Label',
                Name: 'transverse-reinforcement-bottom-layer-two',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.LayerTwo',
                UIPropertyId: 10618,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer',
                Role: LabelRole.SubHeading
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'transverse-reinforcement-bottom-diameter-l2',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10608,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-bottom-spacing-l2',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10609,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-bottom-cover-l2',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10610,
                ParentControlName: 'transverse-reinforcement-layers-tab',
                ParentControlTag: 'bottom-layer'
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'transverse-reinforcement-stirrups-diameter',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10611
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-stirrups-spacing',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10612
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-stirrups-cover',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Cover',
                Size: 50,
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 10614
            },
            {
                ControlType: 'Button',
                ButtonSize: ButtonSize.Full,
                ButtonType: ButtonType.Default,
                Name: 'transverse-reinforcement-define-other-parameters',
                DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.DefineOtherParameters',
                UIPropertyId: 10613,
                Command: 'OpenDefineOtherRebarParameters'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.DetailedDefinition',
                Name: 'transverse-reinforcement-detailed-definition',
                UIPropertyId: 10409,
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenDetailedDefinitionPopup', isACICSA(design))
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-total-cross-sectional-area',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.TotalCrossSectionalArea',
                UIPropertyId: 10410,
                UnitGroup: UnitGroup.Area,
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-yield-strength',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.YieldStrengthTransverseReinforcement',
                UIPropertyId: 10755,
                UnitGroup: UnitGroup.Stress,
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-spacing',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing',
                UIPropertyId: 10411,
                UnitGroup: UnitGroup.Length,
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-number-of-bars',
                UIPropertyId: 10412,
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.NumberOfBars'
            },
            {
                ControlType: 'TextBox',
                Name: 'transverse-reinforcement-ktr',
                UIPropertyId: 10413,
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Ktr',
                UnitGroup: UnitGroup.Length
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.UnfavorableTolerance',
                Name: 'transverse-reinforcement-unfavorable-tolerance',
                UIPropertyId: 10683,
                TooltipType: TooltipType.Popup,
                Command: 'OpenTransverseReinforcementUnfavorableTolerancePopup'
            }
        ]
    };
}

function createC2CMenu_ExistingReinforcement_CastInSplicedReinforcement(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.CastInSplicedReinforcement',
        Id: 31,
        Name: 'cast-in-spliced-reinforcement-section',
        TooltipType: getPopupTooltipConditional(isACICSA(design)),
        Command: getCommandConditional('OpenCastInSplicedReinforcementPopup', isACICSA(design)),
        Controls: [
            {
                ControlType: 'Dropdown',
                CodelistName: 'BarType',
                Name: 'cast-in-spliced-reinforcement-bar-type',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarType',
                UIPropertyId: 10427
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'GenericRebarDiameter',
                Name: 'cast-in-spliced-reinforcement-diameter',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                UIPropertyId: 10428
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'RebarMaterial',
                Name: 'cast-in-spliced-reinforcement-grade',
                UIPropertyId: 10429,
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Grade'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.EpoxyCoatedReinforcement',
                Name: 'cast-in-spliced-reinforcement-epoxy-coated',
                UIPropertyId: 10430,
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenEpoxyCoatedReinforcementPopup', isACICSA(design))
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'LocationFactor',
                Name: 'cast-in-spliced-reinforcement-location-factor',
                TitleDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Reinforcement.k1' : 'Agito.Hilti.C2C.Reinforcement.LocationFactor',
                UIPropertyId: 10437,
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenLocationFactorPopup', isACICSA(design))
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Product.CompressionConfinement',
                Name: 'cast-in-spliced-reinforcement-compression-confinement',
                UIPropertyId: 10714,
                TooltipType: TooltipType.Popup,
                Command: 'OpenCompressionConfinementPopup'
            },
        ]
    };
}

function createC2CMenu_ExistingReinforcement_ConcreteReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.Reinforcement.ConcreteReinforcement',
        Id: 27,
        Name: 'concrete-reinforcement-section',
        Controls: [
            {
                CodelistName: 'ReinforcementType',
                ControlType: 'Dropdown',
                Name: 'reinforcement-type-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.ConcreteReinforcement',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.ConcreteReinforcement',
                UIPropertyId: 10698,
                TooltipType: TooltipType.Popup,
                Command: 'OpenSurfaceReinforcementInfoPopup'
            },
            {
                ControlType: 'TextBox',
                Name: 'strutandtie-reinforcement-concretecover',
                UIPropertyId: 10717,
                TitleDisplayKey: 'Agito.Hilti.C2C.StrutAndTie.ConcreteReinforcement',
                UnitGroup: UnitGroup.Length,
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.StrutAndTie.ConcreteReinforcement',
                TooltipDisplayKey: 'Agito.Hilti.C2C.StrutAndTie.ConcreteReinforcement.Tooltip',
            },
        ]
    };
}
