import { TitleStyle } from '@profis-engineering/pe-ui-common/components/modal-header/modal-header.common';
import { LocalizationService } from '../services/localization.service';
import { IInfoDialogItem, InfoDialogItemType, IComplexTranslationString, InfoDialogItemValue, PREVENT_UPPERCASE_CONSTANTS_CLASS } from './info-dialog-helper';

export class InfoDialogDataHelper {
    private get titleDialogItem(): IInfoDialogItem | undefined {
        return this.items.find(x => x.type == InfoDialogItemType.Title);
    }

    constructor(private localization: LocalizationService, private items: IInfoDialogItem[]) {
    }

    public getTitle(): string {
        if (this.titleDialogItem != undefined) {
            if (this.isComplexTranslationString(this.titleDialogItem.value)) {
                const translatedTitle = this.translate(this.titleDialogItem.value.translationString).toUpperCase();

                return this.translateComplexTranslationString(translatedTitle, this.titleDialogItem.value.constants);
            }

            return this.translate(this.titleDialogItem.value as string);
        }

        return '';
    }

    public getTitleStyle(): TitleStyle {
        return this.titleDialogItem?.style == PREVENT_UPPERCASE_CONSTANTS_CLASS
            ? TitleStyle.Initial
            : TitleStyle.Uppercase;
    }

    public translate(key: string | undefined) {
        return this.localization.getString(key ?? '', { tags: { ...LocalizationService.SubSup, ...LocalizationService.SubSup, ...LocalizationService.PBrB } });
    }

    private isComplexTranslationString(value?: InfoDialogItemValue): value is IComplexTranslationString {
        return ((value as IComplexTranslationString).constants != undefined);
    }

    private translateComplexTranslationString(translatedString: string, constants: Record<string, string>): string {
        for (const constant of Object.entries(constants)) {
            translatedString = translatedString.replaceAll(constant[0].toUpperCase(), constant[1]);
        }

        return translatedString;
    }
}