import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/c2c-gl-model/base-update';
import { LongitudinalRebarPointC2C, TransverseRebarPointC2C } from '@profis-engineering/c2c-gl-model/components/base-rebar-helper';

import { GenericRebar } from '../../../../shared/entities/code-lists/generic-rebar';
import { DesignCodeList as DesignCodeListC2C } from '../../../../shared/entities/design-code-list';
import {
    TransverseReinforcementPointC2C,
    LongitudinalReinforcementPointC2C,
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { ConnectionType } from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { DesignC2C } from '../../../../shared/entities/design-c2c';
import { IModelC2C } from '@profis-engineering/c2c-gl-model/gl-model';

export class ReinforcementUpdate extends BaseUpdate {

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged()
    private OnPropertiesChange(model: IModelC2C, design: DesignC2C) {
        if (!model.reinforcement) {
            throw new Error('model reinforcement is undefined');
        }

        if ((model.visibilityPropertiesC2C?.showEurocodeModels || model.visibilityPropertiesC2C?.showAustralianModels) && (design.connectionType == ConnectionType.Splices || design.connectionType == ConnectionType.StructuralJoints)) {
            model.reinforcement.longitudinalReinforcementPoints = design.designData.projectDesignC2C?.reinforcement.longitudinalReinforcementRebarPoints.map((p: LongitudinalReinforcementPointC2C) => {
                return {
                    diameter: p.diameter,
                    id: p.id,
                    layerId: p.layerId,
                    x: p.x,
                    y: p.y,
                    shape: p.shapeType as number,
                    hook: p.hook,
                    bond: p.bondCondition as number,
                    length: p.length,
                    temperature: p.temperature
                } as LongitudinalRebarPointC2C;
            }) ?? [];

            model.reinforcement.transverseReinforcementPoints = design.designData.projectDesignC2C?.reinforcement.transverseReinforcementRebarPoints.map((p: TransverseReinforcementPointC2C) => {
                return {
                    diameter: p.diameter,
                    id: p.id,
                    layerId: p.layerId,
                    x: p.z,
                    y: p.y,
                    shape: p.shapeType as number,
                    width: p.width,
                    height: p.height,
                    bond: 0
                } as TransverseRebarPointC2C;
            }) ?? [];
        }
    }

    @propertyValueChanged(UIProperty.Reinforcement_C2C_CastInSplicedDiameterId)
    private Reinforcement_C2C_CastInSplicedDiameterId(diameterId: number, model: IModelC2C, design: DesignC2C) {
        if (!model.reinforcementHNA) {
            throw new Error('model reinforcementHNA is undefined');
        }

        if (diameterId != null) {
            const connectorSizeCodeList = design.designData.designCodeListsC2C[DesignCodeListC2C.GenericRebarDiameter] as GenericRebar[];
            const connectorSize = connectorSizeCodeList.find((cs) => cs.id == diameterId);
            model.reinforcementHNA.castInSpliced.diameter = connectorSize?.diameter ?? 0;
        }
    }
}
