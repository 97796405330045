import {
    Tooltip
} from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import {
    ToggleButtonGroupItem
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { GuidService } from '@profis-engineering/pe-ui-common/services/guid.common';

import { TranslationFormatService } from '../../c2c/services/translation-format.service';
import { DesignC2C } from '../../shared/entities/design-c2c';
import {
    LoadCombinationC2C, TranslationFormatC2C, UIProperty
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, CalculationMode, ConnectionType, DesignStandard, EmbedmentDepthMode,
    LoadDefinitionSection, LoadDefinitionType, LoadingDefinitionType, LoadType
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C } from '../../shared/properties/properties';
import { CodeListService } from '../services/code-list.service';
import { LocalizationService } from '../services/localization.service';
import { ModalService } from '../services/modal.service';
import { UnitService } from '../services/unit.service';

export enum InternalLoadType {
    Unknown = 0,
    Static = 1,
    Seismic = 2,
    Fatigue = 3,
    Fire = 4
}

export interface LoadCombinationColumnProps<T> {
    name: T;
    tedX: T;
    tedY: T;
    forceVx: T;
    forceVy: T;
    forceN: T;
    momentMx: T;
    momentMy: T;
    momentMz: T;
    utilization: T;
    length: T;
    handrail: T;
    zone: T;
    zone1: T;
    zone2: T;
    zone3: T;
    zone4: T;
    zones: T;
}

export interface MousePosition {
    x: number;
    y: number;
}

export class LoadsComponentHelper {
    public static readonly minResizerColumnWidth: LoadCombinationColumnProps<number> = {
        name: 50,
        tedX: 50,
        tedY: 50,
        forceVx: 50,
        forceVy: 50,
        forceN: 50,
        momentMx: 50,
        momentMy: 50,
        momentMz: 50,
        utilization: 55,
        length: 50,
        handrail: 50,
        zone: 75,
        zone1: 75,
        zone2: 70,
        zone3: 70,
        zone4: 70,
        zones: 70,
    };

    public static readonly minResizerColumnWidthOverlay: LoadCombinationColumnProps<number> = {
        ...LoadsComponentHelper.minResizerColumnWidth,
        utilization: 200,
        zone: 200,
        zones: 200
    };

    private static readonly typeButtonWidth: number = 32; // sync with css variable @type-button-width

    // Column Resizing
    public resizerColumnWidth?: LoadCombinationColumnProps<number> | null;
    public resizerOverlayElement!: HTMLElement;
    public resizerTriggerPosition?: MousePosition;
    public resizerTriggerElement!: HTMLElement;

    public isUtilizationCompact = false;

    // Load
    private _addingNewLoad = false;
    public newLoad!: LoadCombinationC2C;

    public loadTypeEnum = InternalLoadType;
    public staticTooltip!: Tooltip;
    public seismicTooltip!: Tooltip;
    public fireTooltip!: Tooltip;
    public fatigueTooltip!: Tooltip;

    public tedXRange?: IValueRange;
    public tedYRange?: IValueRange;
    public forceXRange?: IValueRange;
    public forceYRange?: IValueRange;
    public forceZRange?: IValueRange;
    public bottomForceZRange?: IValueRange;
    public sustainedForceZRange?: IValueRange;
    public momentXRange?: IValueRange;
    public sustainedMomentXRange?: IValueRange;
    public momentYRange?: IValueRange;
    public zoneGenericRange?: IValueRange;
    public dynamicZoneGenericRange?: IValueRange;
    public zone1Range?: IValueRange;
    public dynamicZone1Range?: IValueRange;
    public zone2Range?: IValueRange;
    public dynamicZone2Range?: IValueRange;
    public zone3Range?: IValueRange;
    public dynamicZone3Range?: IValueRange;
    public zone4Range?: IValueRange;
    public dynamicZone4Range?: IValueRange;

    public numberZones = 0;
    public headerFlexValue = 0.7;

    private columnVisibilitiesStore!: LoadCombinationColumnProps<boolean>;

    private guid: GuidService;

    constructor(
        private localization: LocalizationService,
        public design: DesignC2C,
        private unit: UnitService,
        private modal: ModalService,
        private translationFormatService: TranslationFormatService,
        private codeListC2C: CodeListService
    ) {
        this.guid = new GuidService();
    }

    private get multiLoadEnabledOverlayEu() {
        const isOverlayEu = this.design.isC2COverlay && this.design.designStandardC2C?.id == DesignStandard.ETAG;
        return isOverlayEu && this.design.designData.projectDesignC2C?.product.calculationMode == CalculationMode.Verification;
    }

    public get columnTypeWidth() {
        const count = (this.isLoadTypeAllowed(InternalLoadType.Static) ? 1 : 0) +
            (this.isLoadTypeAllowed(InternalLoadType.Seismic) ? 1 : 0) +
            (this.isLoadTypeAllowed(InternalLoadType.Fire) ? 1 : 0) +
            (this.isLoadTypeAllowed(InternalLoadType.Fatigue) ? 1 : 0);

        return (count * LoadsComponentHelper.typeButtonWidth + 1) + 1; // +1 add left border for each button and +1 add right border for the last button
    }

    public initialize() {
        // Load type tooltips
        this.staticTooltip = {
            title: this.translate('Agito.Hilti.C2C.LoadType.Static.Tooltip.Title'),
            content: this.translate('Agito.Hilti.C2C.LoadType.Static.Tooltip')
        };

        this.seismicTooltip = {
            title: this.translate('Agito.Hilti.C2C.LoadType.Seismic.Tooltip.Title'),
            content: this.translate('Agito.Hilti.C2C.LoadType.Seismic.Tooltip')
        };

        this.fatigueTooltip = {
            title: this.translate('Agito.Hilti.C2C.LoadType.Fatigue.Tooltip.Title'),
            content: this.translate('Agito.Hilti.C2C.LoadType.Fatigue.Tooltip')
        };

        this.fireTooltip = {
            title: this.translate('Agito.Hilti.C2C.LoadType.Fire.Tooltip.Title'),
            content: this.translate('Agito.Hilti.C2C.LoadType.Fire.Tooltip')
        };

        // Resizing
        this.resizerOverlayElement = document.createElement('div');
        this.resizerOverlayElement.setAttribute('id', 'resize-drag-handle-vertical');

        // New load
        this.initializeNewLoad();

        // Load value ranges
        this.loadValueRanges();

        this.columnVisibilitiesStore = this.columnVisibilities;
    }

    private loadValueRanges() {
        this.tedXRange = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_TedX.id);
        this.tedYRange = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_TedY.id);

        this.forceXRange = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_ForceX.id);
        this.forceYRange = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_ForceY.id);
        this.forceZRange = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_ForceZ.id);
        this.bottomForceZRange = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_BottomForceZ.id);
        this.momentYRange = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_MomentY.id);

        this.zoneGenericRange = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_ZoneGeneric.id);
        this.dynamicZoneGenericRange = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_DynamicZoneGeneric.id);

        this.zone1Range = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_Zone1.id);
        this.dynamicZone1Range = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_DynamicZone1.id);
        this.zone2Range = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_Zone2.id);
        this.dynamicZone2Range = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_DynamicZone2.id);
        this.zone3Range = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_Zone3.id);
        this.dynamicZone3Range = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_DynamicZone3.id);
        this.zone4Range = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_Zone4.id);
        this.dynamicZone4Range = this.getLoadsRange(PropertyMetaDataC2C.Loads_C2C_DynamicZone4.id);
    }

    private getLoadsRange(uiProperty: UIProperty): IValueRange {
        const propertyValue = this.codeListC2C.getPropertyValue(uiProperty, this.design.region.id ?? -1, this.design.connectionType);

        return {
            max: propertyValue?.maxValue,
            min: propertyValue?.minValue
        };
    }

    public get columnVisibilities(): LoadCombinationColumnProps<boolean> {
        return {
            name: true,
            tedX: !this.areZonesVisible && this.isOverlayHNAAndIsShearStress,
            tedY: !this.areZonesVisible && this.isOverlayHNAAndIsShearStress,
            forceVx: !this.areZonesVisible && this.isNotOverlayHNAOrIsNotShearStress && !this.isPerBarPir,
            forceVy: !this.areZonesVisible && this.isNotOverlayHNAOrIsNotShearStress && !this.isPerBarPir,
            forceN: !this.areZonesVisible && !this.isOverlayHNA,
            momentMx: !this.areZonesVisible && !this.isOverlayHNA && !this.isPerBarPir,
            momentMy: !this.areZonesVisible && !this.isOverlayHNA && !this.isPerBarPir,
            momentMz: !this.areZonesVisible && !this.isOverlayHNA && !this.isPropertyHidden(PropertyMetaDataC2C.Loads_C2C_MomentZ.id),
            utilization: true,
            length: this.showLength,
            handrail: true,
            zone: this.areZonesVisible && this.isZoneVisible(0),
            zone1: this.areZonesVisible && this.isZoneVisible(1),
            zone2: this.areZonesVisible && this.isZoneVisible(2),
            zone3: this.areZonesVisible && this.isZoneVisible(3),
            zone4: this.areZonesVisible && this.isZoneVisible(4),
            zones: this.areZonesVisible
        };
    }

    public isLoadTypeAllowed(loadType: InternalLoadType) {
        if ((this.areZonesVisible) && (loadType == InternalLoadType.Fatigue)) {
            return false;
        }
        else {
            return !this.design.properties?.get(PropertyMetaDataC2C.Loads_C2C_LoadType.id).disabledValues?.some(allowed => allowed == loadType) && this.design.properties?.get(PropertyMetaDataC2C.Loads_C2C_LoadType.id).allowedValues?.some(allowed => allowed == loadType);
        }
    }

    public get loads() {
        return this.design.loadCombinationsC2C;
    }

    public get showDynamic() {
        return this.isLoadTypeZoneFatigue;
    }

    public get isLoadTypeZoneFatigue() {
        return this.areZonesVisible
            ? this.design.loadTypeC2C == LoadType.Fatigue
            : false;
    }

    public get areZonesVisible() {
        if (this.isOverlayHNA) {
            return false;
        }

        if (this.design.connectionType == ConnectionType.ConcreteOverlay) {
            return true;
        }

        return false;
    }

    public get multiLoadEnabledPirEu() {
        return !this.isPerBarPir && this.design.designData.projectDesignC2C?.product.calculationMode == CalculationMode.Verification;
    }

    public get isMultiLoadEnabled() {
        return this.multiLoadEnabledPirEu || this.multiLoadEnabledOverlayEu;
    }

    public get addingNewLoad() {
        if (this.design.isPirEuOrAus) {
            this._addingNewLoad = this._addingNewLoad && this.multiLoadEnabledPirEu;
        }

        return this._addingNewLoad;
    }

    public set addingNewLoad(value: boolean) {
        this._addingNewLoad = value;
    }

    public get isOverlayHNA() {
        const isHNA = this.design.designStandardC2C?.id == DesignStandard.ACI || this.design.designStandardC2C?.id == DesignStandard.CSA;
        return this.design.connectionType == ConnectionType.ConcreteOverlay && isHNA;
    }

    public get isOverlayHNAAndIsShearStress() {
        return this.isOverlayHNA && this.isShearStress;
    }

    public get isNotOverlayHNAOrIsNotShearStress() {
        return !this.isOverlayHNA || !this.isShearStress;
    }

    public get isMomentSustainedEnabled() {
        return !this.isPerBar
            && (this.design.designStandardC2C?.id == DesignStandard.ACI || this.design.designStandardC2C?.id == DesignStandard.CSA);
    }

    public get isPerBar() {
        return this.design.designData.projectDesignC2C?.loads?.loadDefinitionType == LoadDefinitionType.PerBar;
    }

    public get isExtensionsOrJointsHNA() {
        const isHNA = this.design.designStandardC2C?.id == DesignStandard.ACI || this.design.designStandardC2C?.id == DesignStandard.CSA;
        return isHNA && (this.design.connectionType == ConnectionType.Splices || this.design.connectionType == ConnectionType.StructuralJoints);
    }

    public get isPerBarPir(): boolean {
        return this.isPirEuOrAus && this.design.designData.projectDesignC2C?.loads?.loadDefinitionSection == LoadDefinitionSection.PerBar;
    }

    public get isPirEuOrAus() {
        const isEtagOrAs = this.design.designStandardC2C?.id == DesignStandard.ETAG || this.design.designStandardC2C?.id == DesignStandard.ASBased;
        return isEtagOrAs && (this.design.connectionType == ConnectionType.Splices || this.design.connectionType == ConnectionType.StructuralJoints);
    }

    public get isShearStress() {
        return this.design.designData.projectDesignC2C?.loads.loadingDefinitionType == LoadingDefinitionType.ShearStress;
    }

    public get isSplicesAndPerBar() {
        return (this.design.designStandardC2C?.id == DesignStandard.ETAG || this.design.designStandardC2C?.id == DesignStandard.ACI || this.design.designStandardC2C?.id == DesignStandard.CSA)
            && this.design.designData.projectDesignC2C?.loads?.loadDefinitionSection == LoadDefinitionSection.PerBar
            && this.design.connectionType == ConnectionType.Splices;
    }

    public getSizeFlexOrNull(size: number | undefined): string | null {
        return size != null ? size + 'px' : null;
    }

    public get zoneOneFlex() {
        if (this.resizerColumnWidth == null) {
            if (this.numberZones == 1) {
                return { flex: 2 * this.headerFlexValue };
            }
            else {
                return { flex: this.headerFlexValue };
            }
        }
        else {
            if (this.numberZones == 1) {
                return { 'flex-basis': this.zonesHeaderWidth + 'px' };
            }
            else {
                return { 'flex-basis': this.resizerColumnWidth.zone1 + 'px' };
            }
        }
    }

    public get zonesHeaderWidth() {
        if (this.design.applicationType == ApplicationType.GenericReinforcement) {
            return this.resizerColumnWidth?.zone;
        }
        else {
            let zonesWidth = 0;
            let index = 0;

            Object.keys(this.resizerColumnWidth ?? []).forEach(key => {
                if (key.includes('zone') && index <= this.numberZones && this.resizerColumnWidth != null) {
                    zonesWidth += this.resizerColumnWidth[key as keyof LoadCombinationColumnProps<number>];
                    index++;
                }
            });

            if (this.numberZones == 1) {
                const minZonesWidth = LoadsComponentHelper.minResizerColumnWidthOverlay['zones'];
                zonesWidth = zonesWidth >= minZonesWidth ? zonesWidth : minZonesWidth;
            }

            return (this.resizerColumnWidth == null) ? 0 : zonesWidth;
        }
    }

    public get showUtilizations() {
        if (this.design.isPirEuOrAus) {
            return this.loadsHasShear;
        }
        else {
            return this.showUtilizationsForNonPirEu();
        }
    }

    public get showLength() {
        return (this.design.isPirEuOrAus || this.design.isPirHna) && !this.isPerBarPir;
    }

    public get loadsHasShear() {
        return !this.design.loadCombinationsC2C.every(x => x.shear == null) || (this.design.loadsInterfaceShearC2C && !this.isPerBarPir);
    }

    public getPrecision(unit: Unit, uiProperty: UIProperty) {
        return this.unit.getPrecision(unit, uiProperty);
    }

    public isZoneVisible(zoneNumber: number) {
        if (this.design.applicationType == ApplicationType.GenericReinforcement) {
            return zoneNumber == 0;
        }

        if (zoneNumber == 0) {
            return false;
        }

        return this.areZonesVisible
            ? this.numberZones >= zoneNumber
            : false;
    }

    public initializeNewLoad() {
        this.resetNewLoad();
    }

    private resetNewLoad() {
        this.newLoad = {
            id: this.guid.new(),
            name: '',
            description: undefined,
            activeLoadType: LoadType.Static,
            tedX: 0,
            tedY: 0,
            forceX: 0,
            forceY: 0,
            forceZ: 0,
            bottomForceZ: 0,
            sustainedForceZ: 0,
            momentX: 0,
            momentY: 0,
            momentZ: 0,
            sustainedMomentX: 0,
            sustainedMomentY: 0,
            zone1: 0,
            zone2: 0,
            zone3: 0,
            zone4: 0,
            zoneGeneric: 0,
            dynamicZone1: 0,
            dynamicZone2: 0,
            dynamicZone3: 0,
            dynamicZone4: 0,
            dynamicZoneGeneric: 0,
            tension: undefined,
            shear: undefined,
            combination: undefined,
            maxUtilization: undefined,
            resultMessages: [],
            isWizardGenerated: false,
            hasSustainedLoads: this.isExtensionsOrJointsHNA,
            hasScopeChecksError: false,
            hasPostCalculationScopeChecksError: false,
            hasPreCalculationScopeChecksError: false
        };
    }

    public get lengthUnit() {
        return this.unit.getDefaultUnit(UnitGroup.Length);
    }

    public get forceUnit() {
        return this.unit.getDefaultUnit(UnitGroup.Force);
    }

    public get momentUnit() {
        return this.unit.getDefaultUnit(UnitGroup.Moment);
    }

    public get stressUnit() {
        return this.unit.getDefaultUnit(UnitGroup.Stress);
    }

    public get zoneUnit() {
        const loadingDefinitionType = this.design.model[PropertyMetaDataC2C.Loads_C2C_LoadingDefinitionType.id] as LoadingDefinitionType;
        return loadingDefinitionType == LoadingDefinitionType.ShearLoad
            ? this.forceUnit
            : this.stressUnit;
    }

    public showUtilizationsForNonPirEu(): boolean {
        const isOverlay = this.design.connectionType == ConnectionType.ConcreteOverlay;
        const isETAG = this.design.designStandardC2C?.id == DesignStandard.ETAG;
        const isNoCompressiveZonePresentPostMessage = (this.design.designData.reportDataC2C?.scopeCheckResultItems ?? []).filter(scopeCheck => scopeCheck.scopeCheckName == 'SC_NoCompressiveZonePresentPost').length > 0;

        return isNoCompressiveZonePresentPostMessage ? false : !isOverlay || !isETAG;
    }

    public isPropertyDisabled(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.disabled : true;
    }

    public isPropertyHidden(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.hidden : true;
    }

    public setCompactCollapsed(collapsed: boolean) {
        if (collapsed) {
            // Loads collapsed --> compact = true
            this.isUtilizationCompact = true;
            return true;
        }

        return false;
    }

    public calculateCompact(utilizationElement?: HTMLElement) {
        this.isUtilizationCompact = (utilizationElement?.offsetWidth ?? 0) < 210;
    }

    public beginColumnResizing(mouse: MousePosition, resizerTriggerElement: HTMLElement) {
        this.resizerOverlayElement.style.top = '0';
        this.resizerOverlayElement.style.bottom = '0';
        this.resizerOverlayElement.style.left = `${mouse.x}px`;

        this.resizerTriggerPosition = mouse;
        this.resizerTriggerElement = resizerTriggerElement;
    }

    public continueColumnResizing(mouse: MousePosition) {
        this.resizerOverlayElement.style.left = `${mouse.x}px`;
    }

    public endColumnResizing(mouse: MousePosition) {
        const diffMouse = {
            x: mouse.x - (this.resizerTriggerPosition?.x ?? 0),
            y: mouse.y - (this.resizerTriggerPosition?.y ?? 0)
        };

        this.resizeColumns(this.resizerTriggerElement, diffMouse.x);
    }

    public calculateMouseInsideElement(event: MouseEvent, element: HTMLElement): MousePosition {
        return {
            x: event.pageX - (element.getBoundingClientRect().left + (window.pageXOffset || document.documentElement.scrollLeft)),
            y: event.pageY - (element.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop))
        };
    }

    private resizeColumns(resizer: HTMLElement, sizeDifference: number) {
        if (resizer == null) {
            resizer = document.querySelector<HTMLElement>('.resizer-left') as HTMLElement;
        }

        let column: HTMLElement | undefined = resizer.closest<HTMLElement>('.resizer-column') as HTMLElement;
        const row = resizer.closest<HTMLElement>('.resizer-row') as HTMLElement;

        this.resizerColumnWidth = { // reset width of each column before distributing resized width
            name: row?.querySelector<HTMLElement>('.column-name')?.offsetWidth || 0,
            tedX: row?.querySelector<HTMLElement>('.column-tedx')?.offsetWidth || 0,
            tedY: row?.querySelector<HTMLElement>('.column-tedy')?.offsetWidth || 0,
            forceVx: row?.querySelector<HTMLElement>('.column-vx')?.offsetWidth || (this.isPerBarPir ? LoadsComponentHelper.minResizerColumnWidthOverlay.forceVx : 0),
            forceVy: row?.querySelector<HTMLElement>('.column-vy')?.offsetWidth || (this.isPerBarPir ? LoadsComponentHelper.minResizerColumnWidthOverlay.forceVy : 0),
            forceN: row?.querySelector<HTMLElement>('.column-n')?.offsetWidth || (this.isPerBarPir ? LoadsComponentHelper.minResizerColumnWidthOverlay.forceN : 0),
            momentMx: row?.querySelector<HTMLElement>('.column-mx')?.offsetWidth || (this.isPerBarPir ? LoadsComponentHelper.minResizerColumnWidthOverlay.momentMx : 0),
            momentMy: row?.querySelector<HTMLElement>('.column-my')?.offsetWidth || (this.isPerBarPir ? LoadsComponentHelper.minResizerColumnWidthOverlay.momentMy : 0),
            momentMz: row?.querySelector<HTMLElement>('.column-mz')?.offsetWidth || 0,
            utilization: row?.querySelector<HTMLElement>('.column-utilization, .column-add')?.offsetWidth || 0,
            length: row?.querySelector<HTMLElement>('.column-length')?.offsetWidth ?? 0,
            handrail: row?.querySelector<HTMLElement>('.column-handrail')?.offsetWidth || 0,
            zone: row?.querySelector<HTMLElement>('.column-zone-generic')?.offsetWidth || 0,
            zone1: row?.querySelector<HTMLElement>('.column-zone-1')?.offsetWidth || LoadsComponentHelper.minResizerColumnWidthOverlay['zone1'],
            zone2: row?.querySelector<HTMLElement>('.column-zone-2')?.offsetWidth || LoadsComponentHelper.minResizerColumnWidthOverlay['zone2'],
            zone3: row?.querySelector<HTMLElement>('.column-zone-3')?.offsetWidth || LoadsComponentHelper.minResizerColumnWidthOverlay['zone3'],
            zone4: row?.querySelector<HTMLElement>('.column-zone-4')?.offsetWidth || LoadsComponentHelper.minResizerColumnWidthOverlay['zone4'],
            zones: row?.querySelector<HTMLElement>('.column-zones')?.offsetWidth || LoadsComponentHelper.minResizerColumnWidthOverlay['zones']
        };

        const mapResizerColumnClassToProperty = (col: HTMLElement | undefined) => { // finds property name from column class
            const propertyNames: { [key: string]: string } = {
                'column-name': 'name',
                'column-tedx': 'tedX',
                'column-tedy': 'tedY',
                'column-vx': 'forceVx',
                'column-vy': 'forceVy',
                'column-n': 'forceN',
                'column-mx': 'momentMx',
                'column-my': 'momentMy',
                'column-mz': 'momentMz',
                'column-utilization': 'utilization',
                'column-handrail': 'handrail',
                'column-zones': 'zones',
                'column-zone-generic': 'zone',
                'column-zone-1': 'zone1',
                'column-zone-2': 'zone2',
                'column-zone-3': 'zone3',
                'column-zone-4': 'zone4',
                'column-add': 'utilization',
                'column-length': 'length'
            };

            for (const key in propertyNames) {
                if (col?.classList.contains(key)) {
                    return propertyNames[key];
                }
            }

            throw new Error('Unknown column class.');
        };

        const allColumns = Array.from(column.parentElement?.children ?? []) as HTMLElement[];
        const resizableColumns = allColumns.filter(col => col.classList.contains('resizer-column'));
        const columnIndex = resizableColumns.indexOf(column);

        const nextColumns = resizableColumns.slice(columnIndex + 1);
        const prevColumns = resizableColumns.slice(0, columnIndex).reverse(); // reverse previous columns, as we want to prioritize them from the closest one to the first one

        if (sizeDifference > 0) { // mouse move to right means that next columns should narrow
            if (resizer.classList.contains('resizer-left')) {
                nextColumns.unshift(column); // make column that has resizer next column and previous column as the one being resized
                column = prevColumns.shift();
            }

            const columnProp = mapResizerColumnClassToProperty(column);
            const columnPropKey = columnProp as keyof LoadCombinationColumnProps<number>;

            for (const nextColumn of nextColumns) {
                if (sizeDifference <= 0) {
                    break;
                }

                const nextColumnProp = mapResizerColumnClassToProperty(nextColumn);
                const nextColumnPropKey = nextColumnProp as keyof LoadCombinationColumnProps<number>;

                const minColumnWidth = this.getMinResizerColumnWidth(nextColumnPropKey);

                const availableSize = Math.max(this.resizerColumnWidth[nextColumnPropKey] - minColumnWidth, 0);
                const applicableSize = Math.min(availableSize, sizeDifference);

                this.resizerColumnWidth[columnPropKey] += applicableSize;
                this.resizerColumnWidth[nextColumnPropKey] -= applicableSize;
                sizeDifference -= applicableSize;
            }
        }
        else { // mouse move to left means that previous columns should narrow
            if (resizer.classList.contains('resizer-right')) {
                prevColumns.unshift(column); // make column that has resizer previous column and next column as the one being resized
                column = nextColumns.shift();
            }

            if (column !== undefined) {
                const columnProp = mapResizerColumnClassToProperty(column);
                const columnPropKey = columnProp as keyof LoadCombinationColumnProps<number>;

                sizeDifference = Math.abs(sizeDifference);
                for (const prevColumn of prevColumns) {
                    if (sizeDifference <= 0) {
                        break;
                    }

                    const prevColumnProp = mapResizerColumnClassToProperty(prevColumn);
                    const prevColumnPropKey = prevColumnProp as keyof LoadCombinationColumnProps<number>;

                    const minColumnWidth = this.getMinResizerColumnWidth(prevColumnPropKey);

                    const availableSize = Math.max(this.resizerColumnWidth[prevColumnPropKey] - minColumnWidth, 0);
                    const applicableSize = Math.min(availableSize, sizeDifference);

                    this.resizerColumnWidth[columnPropKey] += applicableSize;
                    this.resizerColumnWidth[prevColumnPropKey] -= applicableSize;
                    sizeDifference -= applicableSize;
                }
            }
        }
    }

    private getMinResizerColumnWidth(property: keyof LoadCombinationColumnProps<number>): number {
        if (this.design.connectionType == ConnectionType.ConcreteOverlay) {
            const minColumnWidth = LoadsComponentHelper.minResizerColumnWidthOverlay[property];

            if (property == 'zone1' && this.numberZones == 1) {
                return LoadsComponentHelper.minResizerColumnWidthOverlay['zones'];
            }

            return minColumnWidth;
        }

        return LoadsComponentHelper.minResizerColumnWidth[property];
    }

    public formatPercentage(valueStr?: string): string {
        if (valueStr == null)
            return '';

        const value = +valueStr;
        switch (value) {
            case undefined:
            case null:
                return '';
            case Number.NEGATIVE_INFINITY:
                return '-∞ %';
            case Number.POSITIVE_INFINITY:
                return '∞ %';
            default:
                return this.unit.formatNumber(value, 2) + '%';
        }
    }

    public isTensionInvalid(load: LoadCombinationC2C) {
        if (load.tension != null) {
            return load.tension.status;
        }

        return false;
    }

    public isShearInvalid(load: LoadCombinationC2C) {
        if (load.shear != null) {
            return load.shear.status;
        }

        return false;
    }

    public isTotalInvalid(load: LoadCombinationC2C) {
        return load.maxUtilization?.status;
    }

    public get isEuropeanDesignStandardOverlay(): boolean {
        return this.design.connectionType == ConnectionType.ConcreteOverlay
            && this.design.designStandardC2C?.id == DesignStandard.ETAG;
    }

    public hasMessages(load: LoadCombinationC2C) {
        if (load == null || load.resultMessages == null) {
            return false;
        }

        return load.resultMessages.length > 0;
    }

    public showMessages(load: LoadCombinationC2C) {
        this.modal.openLoadsMessages(this.getScopeCheckMessages(load.resultMessages));
    }

    private getScopeCheckMessages(scopeCheckMessages: TranslationFormatC2C[]) {
        const translatedMessages: string[] = [];

        scopeCheckMessages.forEach((message) => {
            const translation = this.translationFormatService.getLocalizedStringWithTranslationFormat(message);

            if (translation != null)
                translatedMessages.push(translation);
        });

        return translatedMessages;
    }

    public get haveSingleLoad() {
        return this.loads == null || this.loads.length <= 1;
    }

    public loadLoadTypeToggleItems(parentId: string, loadIndex: number, loadImages: Map<InternalLoadType, IIconStyle>) {
        const loadTypeToggleItems: ToggleButtonGroupItem<InternalLoadType>[] = [];

        let type = InternalLoadType.Static;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<InternalLoadType> = {
                id: '',
                value: type,
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-shock',
                tooltip: this.staticTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        type = InternalLoadType.Seismic;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<InternalLoadType> = {
                id: '',
                value: type,
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-seismic',
                tooltip: this.seismicTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        type = InternalLoadType.Fatigue;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<InternalLoadType> = {
                id: '',
                value: type,
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-fatigue',
                tooltip: this.fatigueTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        type = InternalLoadType.Fire;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<InternalLoadType> = {
                id: '',
                value: type,
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-fire-resistant',
                tooltip: this.fireTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        return loadTypeToggleItems;
    }

    public setLoadTypeToggleItemId(item: ToggleButtonGroupItem<InternalLoadType>, parentId: string, loadIndex: number) {
        switch (item.value) {
            case InternalLoadType.Static:
                item.id = `${loadIndex != null ? 'LC-table-static-loadtype-button-' + loadIndex : 'LC-table-add-new-combination-static-loadtype-button'}`;
                break;

            case InternalLoadType.Seismic:
                item.id = `${loadIndex != null ? 'LC-table-seismic-loadtype-button-' + loadIndex : 'LC-table-add-new-combination-seismic-loadtype-button'}`;
                break;

            case InternalLoadType.Fatigue:
                item.id = `${loadIndex != null ? 'LC-table-fatigue-loadtype-button-' + loadIndex : 'LC-table-add-new-combination-fatigue-loadtype-button'}`;
                break;

            case InternalLoadType.Fire:
                item.id = `${loadIndex != null ? 'LC-table-fire-loadtype-button-' + loadIndex : 'LC-table-add-new-combination-fire-loadtype-button'}`;
                break;
        }
    }

    public get isSustained() {
        return this.loads?.some(l => l.hasSustainedLoads);
    }

    public get showSustainedLoads() {
        return this.isSustained || this.isExtensionsOrJointsHNA;
    }

    public get firstRowLoadLegendTranslation() {
        return this.translate('Agito.Hilti.Profis3.Loads.Loads.Design');
    }

    public get secondRowLoadLegendTranslation() {
        return this.translate('Agito.Hilti.Profis3.Loads.Loads.Sustained');
    }

    public get isOptimizedEuDesignOverlay(): boolean {
        return this.isOptimizedDesignOverlay && this.design.designStandardC2C?.id == DesignStandard.ETAG;
    }

    public get isOptimizedEuDesignPirEU(): boolean {
        return this.isPirEuOrAus && this.design.designData.projectDesignC2C?.product.connectorEmbedmentDepthMode == EmbedmentDepthMode.Optimized;
    }

    public get isFullyOptimizedHnaDesignStandardOverlay(): boolean {
        return this.isOptimizedDesignOverlay && this.design.isC2CHNA;
    }

    public maxLoads(maxLoadsPirEu: number, maxLoads: number) {
        return this.design.isPirEu ? maxLoadsPirEu : maxLoads;
    }

    public resetAllColumnsWidth() {
        if (Object.keys(this.columnVisibilitiesStore).some(prop => {
            const propKey = prop as keyof LoadCombinationColumnProps<boolean>;
            return this.columnVisibilitiesStore[propKey] != this.columnVisibilities[propKey];
        })) {
            setTimeout(() => {
                this.resizerColumnWidth = undefined;
            });
        }

        this.columnVisibilitiesStore = this.columnVisibilities;
    }


    public translate(key: string) {
        return this.localization.getString(key);
    }

    private get isOptimizedDesignOverlay(): boolean {
        return this.design.isC2COverlay && (this.design.designData.projectDesignC2C?.product.optimizedLayout
            || this.design.designData.projectDesignC2C?.product.connectorEmbedmentDepthMode == EmbedmentDepthMode.Optimized);
    }
}
