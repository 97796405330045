<div id="zone-utilizations" class="utilizations box-section box-section-white" *ngIf="this.zoneUtilizationsAvailable">
    <div *ngFor="let zone of zones" [id]="getComponentId(zone, 'utilization')" [class.collapsed]="overlayCollapsing[zone]!.header">
        <!-- Primary utilization tab -->
        <c2c-utilization-tab
            [title]="translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone' + zone)"
            icon="sprite-rebar-icon"
            [collapsingControl]="getZoneCollapsingControl(zone)"
            [showPercentage]="true"
            [dataInvalid]="!getUtilizationCalculatedPerZone(zone)?.status"
            [utilizationPercent]="getUtilizationCalculatedPerZone(zone)?.value"
            [utilizationValue]="getUtilizationCalculatedPerZone(zone)?.value"
            [controlId]="getComponentId(zone, 'utilization')"
            [collapsed]="overlayCollapsing[zone]!.header"
            (collapseChange)="onCollapseChanged($any($event).detail, zone)">
        </c2c-utilization-tab>

        <ng-container *ngIf="!overlayCollapsing[zone]!.header">
            <ng-container *ngFor="let section of overlayUtilizationsCalculated[zone]!.sections; let si = index">

                <!-- Secondary utilization tabs -->
                <c2c-utilization-tab
                    [type]="TabType.Secondary"
                    [title]="translate(section.header)"
                    [showPercentage]="hasPercentage(section)"
                    [dataInvalid]="IsInvalidValue(section)"
                    [collapsed]="overlayCollapsing[zone]!.sections[si].header"
                    [utilizationPercent]="hasUtilizationValue(section)"
                    [utilizationValue]="hasUtilizationValue(section)"
                    [tooltipText]="null"
                    [controlId]="getComponentId(zone, 'utilization-details', si)"
                    [collapsingControl]="getCollapseComponentId(zone, 'utilization-detail-worst', si)"
                    (collapseChange)="onSectionCollapseChanged($any($event).detail, zone, si, section.section)">
                </c2c-utilization-tab>

                <ng-container *ngIf="!overlayCollapsing[zone]!.header && !overlayCollapsing[zone]!.sections[si].header">
                    <div class="items box-section-content">
                        <ng-container *ngFor="let detailGroup of section.groups; let dgi = index">
                            <div [id]="getComponentId(zone, 'utilization-group-details', si, dgi)">

                                <!-- Utilization item -->
                                <c2c-utilization-item
                                    [title]="translate(detailGroup.text!)"
                                    [header]="translate(getFirstGroupDetail(detailGroup.details, overlayCollapsing[zone]!.sections[si].groupDetails[dgi])?.header)"
                                    [showPercentBar]="hasPercentageInGroup(detailGroup.details)"
                                    [barInvalid]="IsInvalidValue(detailGroup)"
                                    [dataInvalid]="IsInvalidValue(getFirstGroupDetail(detailGroup.details, overlayCollapsing[zone]!.sections[si].groupDetails[dgi]))"
                                    [image]="getFirstGroupDetail(detailGroup.details, overlayCollapsing[zone]!.sections[si].groupDetails[dgi])?.image"
                                    [percentValue]="getWorstDetailPercentage(detailGroup.details)"
                                    [value]="getDetailValue(getFirstGroupDetail(detailGroup.details, overlayCollapsing[zone]!.sections[si].groupDetails[dgi]))"
                                    [controlId]="getComponentId(zone, 'utilization-detail-worst', si, dgi)">
                                </c2c-utilization-item>

                                <!-- Detailed collapsing group -->
                                <div class="details separator">
                                    <ng-container *ngIf="overlayCollapsing[zone]!.sections[si].groupDetails[dgi]">
                                        <div *ngFor="let detail of getRemainingGroupDetails(detailGroup.details); let dei = index">

                                            <!-- Detailed utilization item -->
                                            <c2c-utilization-item
                                                [dataInvalid]="IsInvalidValue(detail)"
                                                [header]="translate(detail.header)"
                                                [controlId]="getComponentId(zone, '', si, dgi, dei)"
                                                [value]="getDetailValue(detail)"
                                                [image]="detail.image">
                                            </c2c-utilization-item>

                                        </div>
                                    </ng-container>

                                    <!-- Detailed group collapse -->
                                    <c2c-utilizations-collapse
                                        *ngIf="detailGroup.details.length > 1"
                                        [state]="collapseState(zone, si, dgi)"
                                        [controlId]="getComponentId(zone, 'utilization-group-details', si, dgi)"
                                        (collapseToggled)="showDetails(zone, si, dgi)">
                                    </c2c-utilizations-collapse>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
