import { Component, ElementRef, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import {
    ConnectionType, DesignMethod, DesignMethodGroup, DesignStandard, LoadType
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import {
    UnitGroup, UnitType as Unit
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { FeatureFlagTypes } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import { CollapsingControls } from '../../../shared/enums/collapsing-controls';
import { DesignC2C as Design } from '../../../shared/entities/design-c2c';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { includeSprites } from '../../sprites';
import { UserService } from '../../services/user.service';
interface IUtilizationData {
    percentage?: string;
    status?: boolean;
    value?: string;
}

interface IUtilizationGroup {
    id: string;
    title: string;
    titleSuffix?: string;
    items: IUtilizationItem[];
    control: CollapsingControls;
    image?: string;
    visible?: boolean;
    collapsed?: boolean;
    tooltip?: string;
    orderNumber: number;
    infoPopup?: () => void;
}

interface IUtilizationItem {
    id: string;
    title?: string;
    progressBar?: boolean;
    comparable: boolean;
    image?: string;
    imageTitle?: string;
    property?: string;
    propertyUi?: string;
    data?: IUtilizationData;
    worstValue?: boolean;
    unitGroup?: UnitGroup;
    role: GroupItemRole;
    children?: string[];
    visible?: boolean;
    tooltip?: string;
}

enum GroupItemRole {
    Result,
    StringResult,
    Summary
}

@Component({
    templateUrl: './utilization-panel.component.html',
    styleUrls: ['../layer-utilizations/utilizations-base.component.scss', './utilization-panel.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})

export class UtilizationsPanelComponent implements OnInit {

    public groups: IUtilizationGroup[] = [];
    private design: Design;

    constructor(
        private userService: UserService,
        public localization: LocalizationService,
        private unitService: UnitService,
        private userSettingsService: UserSettingsService,
        public modalService: ModalService,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone
    ) {
        this.design = this.userService.design;
    }

    ngOnInit(): void {
        this.design.onStateChanged(() => {
            // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
            const onStateChanged = () => {
                this.updateValues();
            };
            return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
        });

        this.updateValues();

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-info-tooltip',
            'sprite-rebar-icon',
            'sprite-shear-stress',
            'sprite-tension-steel',
            'sprite-tension-breakout',
            'sprite-tension-combined');
    }

    public get utilizationsPanelAvailable() {
        // XXX: Ico: is this even needed?
        return this.design.isC2C
            && (this.design.designStandardC2C?.id == DesignStandard.ACI || this.design.designStandardC2C?.id == DesignStandard.CSA)
            && this.design.designData?.reportDataC2C?.scopeCheckResultItems?.filter(x => x.indicatesCalculationError).length == 0
            && this.design.designData?.reportDataC2C.utilizations != null;
    }

    private get isHna(): boolean {
        return this.design.designStandardC2C?.id == DesignStandard.ACI || this.design.designStandardC2C?.id == DesignStandard.CSA;
    }

    private get isHnaAnchorTheoryAndYieldDesign(): boolean {
        return this.isHna && this.displayAnchorTheoryCalculation && this.isDesignForYield;
    }

    private get isHnaImprovementEnabled() {
        return this.design.isFeatureEnabled(FeatureFlagTypes.HnaDesignMethods);
    }

    private get isAnchoringMethodSelected() {
        const anchoringMethods = [DesignMethod.AnchoringBarYield, DesignMethod.AnchoringExternalLoads];
        return anchoringMethods.includes(this.design.selectedDesignMethodC2C);
    }

    private get displayAnchorTheoryCalculation(): boolean {
        const isTheoryDisplayed = this.design.designData?.projectDesignC2C?.anchorTheory.isAnchorTheoryDisplayed ?? false;

        return (isTheoryDisplayed && !this.isHnaImprovementEnabled) || (this.isHnaImprovementEnabled && this.isAnchoringMethodSelected);
    }

    private get isDesignForYield(): boolean {
        return this.design.designData?.projectDesignC2C?.anchorTheory.isYieldDesign ?? false;
    }

    private get spliceExistingReinforcement() {
        return this.design.model[PropertyMetaDataC2C.Rebar_C2C_Reinforcement_SpliceToExistingReinforcement.id] as boolean;
    }

    private get displaySpliceLength() {
        return (this.isStructuralJoints || this.isExtension) && this.spliceExistingReinforcement;
    }

    private get displayDevelopmentLength() {
        const hnaImprovementCondition = this.isHnaImprovementEnabled && this.design.selectedDesignMethodC2C == DesignMethod.DevelopmentLength;
        const noImprovementCondition = !this.isHnaImprovementEnabled && !this.spliceExistingReinforcement && this.design.isPostInstalledRebarDesign;
        return hnaImprovementCondition || noImprovementCondition;
    }

    private get isStructuralJoints() {
        return this.design.connectionType == ConnectionType.StructuralJoints;
    }

    private get isExtension() {
        return this.design.connectionType == ConnectionType.Splices;
    }

    private get isJointsOrExtensions() {
        return this.isStructuralJoints || this.isExtension;
    }

    private get showVuVnMax() {
        return !this.design.model[PropertyMetaDataC2C['Option_C2C_UseHiltiRebarDesign'].id] as boolean;
    }

    private get isSeismic() {
        return this.design.loadTypeC2C == LoadType.Seismic;
    }

    private get showHnaFireDevelopmentLength() {
        return this.isHna && this.isJointsOrExtensions && this.design.loadTypeC2C == LoadType.Fire;
    }

    private get isJointsSpecialMomentsFrame() {
        return this.isSeismic && this.specialMomentsFrame && this.isStructuralJoints;
    }

    private get specialMomentsFrame() {
        return this.design.model[PropertyMetaDataC2C.Loads_C2C_SpecialMomentsFrame.id] as boolean;
    }

    private get showShearDevLengthUtilization() {
        const noHnaFlagCondition = !this.isHnaImprovementEnabled && this.design.isC2COverlay && !this.displayAnchorTheoryCalculation && !this.isHiltiRebarDesign();
        const hnaFlagCondition = this.isHnaImprovementEnabled && this.design.isC2COverlay && this.design.selectedDesignMethodC2C != DesignMethod.AnchoringBarYield && !this.isHiltiRebarDesign();

        return noHnaFlagCondition || hnaFlagCondition;
    }

    private get isShearInterface() {
        if (this.design.isC2COverlay)
            return true;

        const selectedLc = this.design.projectDesign.loads.loadCombinations.find(x => x.id == this.design.selectedLoadCombinationIdC2C);
        if (selectedLc == undefined) {
            throw new Error('Selected load combination is undefined.');
        }

        return selectedLc.forceX != 0 || selectedLc.forceY != 0;
    }

    private get aciShearVerificationStructure(): IUtilizationGroup {
        const numberOfBarsTitle = this.design.isC2COverlay && this.isHiltiRebarDesign()
            ? 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.NumberOfBars'
            : 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.NumberOfBars';
        const structure = {
            id: 'shear-verification',
            title: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification',
            image: 'sprite-rebar-icon',
            control: CollapsingControls.PirShearHiltiUtilization,
            progressBar: true,
            orderNumber: 1,
            items: [
                {
                    id: 'shear-verification-vu-vn',
                    image: 'sprite-shear-stress',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.VuVn',
                    property: 'vuVn',
                    progressBar: true,
                    unitGroup: UnitGroup.Percentage,
                    role: GroupItemRole.Result,
                    comparable: true
                },
                {
                    id: 'shear-verification-vu-vn-max',
                    image: 'sprite-shear-stress',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.VuVnMax',
                    property: 'vuVnMax',
                    progressBar: true,
                    unitGroup: UnitGroup.Percentage,
                    role: GroupItemRole.Result,
                    comparable: true,
                    visible: this.showVuVnMax
                },
                {
                    id: 'shear-verification-avf-numberofbars',
                    imageTitle: numberOfBarsTitle,
                    property: 'numberOfBars',
                    progressBar: false,
                    role: GroupItemRole.Result,
                    comparable: false
                },
                {
                    id: 'shear-verification-avf-numberofbars-provided',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.NumberOfBarsProvided',
                    property: 'numberOfBarsProvided',
                    progressBar: false,
                    role: GroupItemRole.Result,
                    comparable: false,
                },
                {
                    id: 'shear-verification-avf-sizeOfBar',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.SizeOfBar',
                    property: 'sizeOfBar',
                    progressBar: false,
                    role: GroupItemRole.StringResult,
                    comparable: false,
                    unitGroup: UnitGroup.None
                }
            ]
        };

        // Overlay has no VuVnMax so it's being attached after VuVn (index 1), PIR is attached after VuVnMax (index 2).
        const avfRequiredIndex = this.design.isC2COverlay ? 1 : 2;
        this.shearVerificationAvfRequired(structure, avfRequiredIndex);

        this.anchorTheoryStructure(structure);

        return structure;
    }

    private get csaShearVerificationStructure(): IUtilizationGroup {
        const numberOfBarsTitle = this.design.isC2COverlay && this.isHiltiRebarDesign()
            ? 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.NumberOfBars'
            : 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.NumberOfBars';

        const structure = {
            id: 'shear-verification',
            title: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification',
            image: 'sprite-rebar-icon',
            control: CollapsingControls.PirShearUtilization,
            progressBar: true,
            orderNumber: 1,
            items: [
                {
                    id: 'shear-verification-interface-utilization',
                    image: 'sprite-shear-stress',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.InterfaceUtilization',
                    property: 'interfaceUtilization',
                    progressBar: true,
                    unitGroup: UnitGroup.Percentage,
                    role: GroupItemRole.Result,
                    comparable: true
                },
                {
                    id: 'shear-verification-avf-numberofbars',
                    imageTitle: numberOfBarsTitle,
                    property: 'numberOfBars',
                    progressBar: false,
                    role: GroupItemRole.Result,
                    comparable: false
                },
                {
                    id: 'shear-verification-avf-numberofbars-provided',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.NumberOfBarsProvided',
                    property: 'numberOfBarsProvided',
                    progressBar: false,
                    role: GroupItemRole.Result,
                    comparable: false,
                },
                {
                    id: 'shear-verification-avf-sizeOfBar',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.SizeOfBar',
                    property: 'sizeOfBar',
                    progressBar: false,
                    role: GroupItemRole.StringResult,
                    comparable: false,
                    unitGroup: UnitGroup.None
                }
            ]
        };

        this.shearVerificationAvfRequired(structure, 1);

        this.anchorTheoryStructure(structure);

        return structure;
    }

    private get aciCsaAnchorTheoryStructure(): IUtilizationGroup {
        const structure: IUtilizationGroup = {
            id: 'anchor-design',
            title: 'Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign',
            image: 'sprite-rebar-icon',
            control: CollapsingControls.PirAnchorDesignUtilization,
            tooltip: 'Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign.Tooltip.Text',
            orderNumber: 3,
            items: [
                {
                    id: 'anchor-design-summary',
                    role: GroupItemRole.Summary,
                    progressBar: false,
                    children: ['anchor-design-tension-steel', 'anchor-design-tension-concrete-breakout', 'anchor-design-tension-bond', 'anchor-design-tension-sustained'],
                    comparable: false,
                    title: 'Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign.Tension'
                },
                {
                    id: 'anchor-design-tension-steel',
                    image: 'sprite-tension-steel',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign.SteelTension',
                    property: 'steelTension',
                    progressBar: false,
                    unitGroup: UnitGroup.Force,
                    role: GroupItemRole.Result,
                    comparable: true
                },
                {
                    id: 'anchor-design-tension-concrete-breakout',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign.ConcreteBreakoutTension',
                    image: 'sprite-tension-breakout',
                    property: 'concreteBreakoutTension',
                    progressBar: false,
                    unitGroup: UnitGroup.Force,
                    role: GroupItemRole.Result,
                    comparable: true
                },
                {
                    id: 'anchor-design-tension-bond',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign.BondTension',
                    image: 'sprite-tension-combined',
                    property: 'bondtension',
                    progressBar: false,
                    unitGroup: UnitGroup.Force,
                    role: GroupItemRole.Result,
                    comparable: true
                },
                {
                    id: 'anchor-design-embedment-depth',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign.EmbedmentDepth',
                    image: 'sprite-tension-combined',
                    property: 'embedmentDepth',
                    progressBar: false,
                    unitGroup: UnitGroup.Length,
                    role: GroupItemRole.Result,
                    comparable: false
                }
            ]
        };

        if (this.isStructuralJoints) {
            structure.items.push({
                id: 'anchor-design-tension-sustained',
                imageTitle: 'Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign.SustainedTension',
                image: 'sprite-tension-combined',
                property: 'sustainedTension',
                progressBar: false,
                unitGroup: UnitGroup.Percentage,
                role: GroupItemRole.Result,
                comparable: true
            });
        }

        return structure;
    }

    private get aciCsaFireDevelopmentLength(): IUtilizationGroup {
        const id = this.displaySpliceLength ? 'fire-splice-length' : 'fire-development-length';
        const title = this.displaySpliceLength
            ? 'Agito.Hilti.C2C.Utilizations.Fire.FireSpliceLength'
            : 'Agito.Hilti.C2C.Utilizations.Fire.FireDevelopmentLength';

        return {
            id,
            title,
            image: 'sprite-rebar-icon',
            control: CollapsingControls.PirFireLengthUtilization,
            orderNumber: 2,
            items: [
                {
                    id: `${id}-tension`,
                    comparable: false,
                    role: GroupItemRole.Result,
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.DevelopmentLength.Tension',
                    property: 'fireDevLengthTension',
                    unitGroup: UnitGroup.Length,
                },
                {
                    id: `${id}-compression`,
                    comparable: false,
                    role: GroupItemRole.Result,
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.DevelopmentLength.Compression',
                    property: 'fireDevLengthCompression',
                    unitGroup: UnitGroup.Length
                }
            ]
        };
    }

    private get aciCsaInstallationLength(): IUtilizationGroup {
        const id = 'installation-length';
        let title = 'Agito.Hilti.C2C.Utilizations.RebarUtilizations.InstallationLength';
        let titleSuffix = '';

        if (this.isJointsSpecialMomentsFrame) {
            title = 'Agito.Hilti.C2C.Utilizations.RebarUtilizations.InstallationLength.SpecialMomentFrame';
            titleSuffix = '(l<sub>inst</sub>)';
        }

        return {
            id,
            title: title,
            titleSuffix: titleSuffix,
            image: 'sprite-rebar-icon',
            control: CollapsingControls.PirInstallationUtilization,
            infoPopup: () => { this.modalService.openInfoDialogC2C('OpenAdditionalOffsetDistancePopup', 'lg', false); },
            orderNumber: 4,
            items: [
                {
                    id: `${id}-tension`,
                    comparable: false,
                    role: GroupItemRole.Result,
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.RebarUtilizations.InstallationLength.Tension',
                    property: 'installationLengthTension',
                    unitGroup: UnitGroup.Length
                },
                {
                    id: `${id}-compression`,
                    comparable: false,
                    role: GroupItemRole.Result,
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.RebarUtilizations.InstallationLength.Compression',
                    property: 'installationLengthCompression',
                    unitGroup: UnitGroup.Length
                }
            ]
        };
    }

    private get aciCsaDevOrSpliceLength(): IUtilizationGroup {
        const id = this.displayDevelopmentLength ? 'development-length' : 'splice-length';
        const devLengthTitle = this.isJointsSpecialMomentsFrame
            ? 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.DevelopmentLength.SpecialMomentFrame'
            : 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.DevelopmentLength';
        const title = this.displayDevelopmentLength ? devLengthTitle : 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.SpliceLength';
        const headerTooltip = this.displayDevelopmentLength ? 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.DevelopmentLength.Tooltip.Text' : undefined;
        const itemTooltip = !this.displayDevelopmentLength ? 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.SpliceLength.Tension.Tooltip.Text' : undefined;

        return {
            id,
            title,
            image: 'sprite-rebar-icon',
            control: CollapsingControls.PirSpliceLengthUtilization,
            tooltip: headerTooltip,
            orderNumber: 2,
            items: [
                {
                    id: `${id}-tension`,
                    comparable: false,
                    role: GroupItemRole.Result,
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.DevelopmentLength.Tension',
                    property: 'tension',
                    unitGroup: UnitGroup.Length,
                    tooltip: itemTooltip
                },
                {
                    id: `${id}-compression`,
                    comparable: false,
                    role: GroupItemRole.Result,
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.DevelopmentLength.Compression',
                    property: 'compression',
                    unitGroup: UnitGroup.Length
                }
            ]
        };
    }

    public l10n(key?: string) {
        return key != null ? this.localization.getString(key) : '';
    }

    public onCollapseChange(collapsed: boolean, group: IUtilizationGroup): void {
        group.collapsed = collapsed;
        this.userSettingsService.setSectionCollapsed(group.control, collapsed);
    }

    public getItemValue(item: IUtilizationItem) {
        // Do not format percentages
        if (item.data != null && item.data.percentage != '') {
            return item.data?.percentage;
        }

        // Do not format string values
        if (item.role == GroupItemRole.StringResult) {
            return item.data?.value;
        }

        return this.formatValue(item.data?.value ?? 0, item.unitGroup);
    }

    public getWorstValue(group: IUtilizationGroup) {
        const index = group.items.findIndex(x => x.worstValue == true);
        if (index < 0) {
            return null;
        }

        return group.items[index].data;
    }

    public showInfoPopup(group: IUtilizationGroup) {
        return !!group.infoPopup;
    }

    public infoPopup(group: IUtilizationGroup) {
        group.infoPopup?.();
    }

    public getLoadWidth(group: IUtilizationGroup) {
        const item = Number(this.getWorstValue(group)?.value);

        if (isNaN(item) || item > 100) {
            return 100;
        }

        return item;
    }

    public showProgressBar(item?: IUtilizationItem) {
        return item?.progressBar;
    }

    public showPercentage(item: IUtilizationItem) {
        return item.role != GroupItemRole.Summary;
    }

    public getCollapseComponentId(groupId: string) {
        return `control-${groupId}-collapse`;
    }

    private getValueProperties(property: string): IUtilizationData | undefined {
        const utilizationItem = this.design.designData.reportDataC2C?.utilizations?.find(obj => {
            return obj.name === property;
        });

        return utilizationItem?.value;
    }

    private getUiValueProperties(property?: string): IUtilizationData {
        return {
            status: true,
            percentage: '',
            value: property != null ? this.design.model[PropertyMetaDataC2C.getByName(property).id] : undefined
        } as IUtilizationData;
    }

    private getGroupsConfig() {
        const config: IUtilizationGroup[] = [];
        if (this.design.designStandardC2C?.id == DesignStandard.ACI) {
            if (this.isShearInterface || !this.isHnaImprovementEnabled)
                config.push(this.aciShearVerificationStructure);

            this.getGroupsAnchorTheoryConfig(config);
        }
        else if (this.design.designStandardC2C?.id == DesignStandard.CSA) {
            if (this.isShearInterface|| !this.isHnaImprovementEnabled)
                config.push(this.csaShearVerificationStructure);

            this.getGroupsAnchorTheoryConfig(config);
        }

        this.setCollapsingGroups(config);
        return config;
    }

    private getGroupsAnchorTheoryConfig(config: IUtilizationGroup[]) {
        if (this.displayAnchorTheoryCalculation) {
            config.push(this.aciCsaAnchorTheoryStructure);
        }

        if (this.displayDevelopmentLength || this.displaySpliceLength) {
            config.push(this.aciCsaDevOrSpliceLength);
            if (this.isSeismic) { this.setSeismicConfig(config); }
            if (this.showHnaFireDevelopmentLength) { config.push(this.aciCsaFireDevelopmentLength); }

            config.push(this.aciCsaInstallationLength);
        }
    }

    private setSeismicConfig(config: IUtilizationGroup[]) {
        config.push(this.getAciCsaSeismicSpliceOrSpecialMomentFrameLength());

        if (this.isJointsSpecialMomentsFrame) {
            config.push(this.getAciCsaSeismicSpliceOrSpecialMomentFrameLength(true));
        }
    }

    private setCollapsingGroups(groups: IUtilizationGroup[]) {
        for (const group of groups) {
            group.collapsed = this.isGroupCollapsed(group.control);
        }
    }

    private isHiltiRebarDesign() {
        const noFlagCondition = !this.isHnaImprovementEnabled && this.design.designMethodGroup?.id == DesignMethodGroup.HiltiRebarDesign;
        const flagCondition = this.isHnaImprovementEnabled && this.design.projectDesign.loads.shearDesignMethod == DesignMethod.HiltiMethodRebarDesign;

        return noFlagCondition || flagCondition;
    }

    private anchorTheoryStructure(structure: IUtilizationGroup) {
        if (this.showShearDevLengthUtilization) {
            structure.items.push({
                id: 'shear-verification-development-length',
                imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.DevelopmentLength',
                property: 'developmentLength',
                progressBar: false,
                role: GroupItemRole.Result,
                comparable: false,
                unitGroup: UnitGroup.Length,
                tooltip: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.DevelopmentLength.Tooltip.Text'
            });

            if (this.isSeismic) {
                structure.items.push({
                    id: 'shear-verification-seismic-development-length',
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.SeismicDevelopmentLength',
                    property: 'seismicDevelopmentLength',
                    progressBar: false,
                    role: GroupItemRole.Result,
                    comparable: false,
                    unitGroup: UnitGroup.Length,
                });
            }
        }
        else if (this.design.isC2COverlay && this.isHiltiRebarDesign()) {
            structure.items.push({
                id: 'shear-verification-embedment-length',
                imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.EmbedmentDepth',
                property: 'embedmentDepth',
                progressBar: false,
                role: GroupItemRole.Result,
                comparable: false,
                unitGroup: UnitGroup.Length,
                tooltip: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.DevelopmentLength.Tooltip.Text'
            });
        }
    }

    private isGroupCollapsed(control: CollapsingControls): boolean {
        return this.userSettingsService.isSectionCollapsed(control);
    }

    private getUnitPrecision(defaultUnit: Unit) {
        // Don't display irrelavant decimals in case of display results are in square inch (inch2)
        if (defaultUnit == Unit.inch2) {
            return 2;
        }
        else {
            return this.unitService.getPrecision(defaultUnit);
        }
    }

    private formatValue(value: number | string, unitGroup?: UnitGroup) {
        const itemUnitGroup = unitGroup ? unitGroup : UnitGroup.None;
        const defaultUnit = this.unitService.getDefaultUnit(itemUnitGroup);
        const internalUnit = this.unitService.getInternalUnit(itemUnitGroup);
        const defaultPrecision = this.getUnitPrecision(defaultUnit);
        const valueToFormat = this.unitService.convertUnitValueArgsToUnit(parseFloat(value.toString()), internalUnit, defaultUnit, false);
        return this.unitService.formatNumber(valueToFormat, defaultPrecision) + ' ' + this.unitService.formatUnit(this.unitService.getDefaultUnit(itemUnitGroup));
    }

    private updateValues() {
        if (!this.utilizationsPanelAvailable) {
            return;
        }

        this.groups = this.getGroupsConfig();

        if (this.isHnaAnchorTheoryAndYieldDesign) {
            this.groups.sort((a, b) => a.orderNumber - b.orderNumber);
        }

        for (const group of this.groups) {
            const summaryItems: IUtilizationItem[] = [];
            const resultItems: IUtilizationItem[] = [];

            for (const item of group.items) {
                const isResultRole = item.role == GroupItemRole.Result || item.role == GroupItemRole.StringResult;

                item.visible = item.visible == undefined ? true : item.visible;

                if (isResultRole) {
                    if (item.property != undefined) {
                        item.data = this.getValueProperties(item.property);
                        item.visible = item.data != null;
                    }
                    else {
                        item.data = this.getUiValueProperties(item.propertyUi);
                    }

                    if (item.visible != false) {
                        resultItems.push(item);
                    }
                }
                else {
                    summaryItems.push(item);
                }
            }

            this.setSummaryGroupItems(summaryItems, resultItems);
            const comparableItems = group.items.filter((data) => {
                return data.visible && data.comparable === true && data.role == GroupItemRole.Result;
            });

            if (comparableItems.length > 0) {
                const worstProperty = comparableItems.reduce((a, b) => (b.comparable == true && parseFloat((a.data as IUtilizationData).value as string) > parseFloat((b.data as IUtilizationData).value as string)) ? a : b);
                group.items.forEach((item) => {
                    if (item.id == worstProperty.id) {
                        item.worstValue = true;
                    }
                });
            }

            group.visible = group.items.filter(x => x.visible).length > 0;
        }
    }

    private setSummaryGroupItems(summaryItems: IUtilizationItem[], resultItems: IUtilizationItem[]) {
        const getIncludedValues = (children: string[], resultItems: IUtilizationItem[]) => {
            const filtered = resultItems.filter(x => {
                return children.indexOf(x.id) > -1;
            });

            return filtered;
        };

        for (const item of summaryItems) {
            const includedValues = getIncludedValues(item.children ?? [], resultItems);
            if (includedValues.length > 0) {
                const worstValue = includedValues.reduce((a, b) => (parseFloat((a.data as IUtilizationData).value as string) > parseFloat((b.data as IUtilizationData).value as string)) ? a : b);
                item.data = {
                    status: (worstValue.data as IUtilizationData).status,
                    percentage: (worstValue.data as IUtilizationData).percentage,
                    value: (worstValue.data as IUtilizationData).value
                };
            }
            else {
                item.visible = false;
            }
        }
    }

    private shearVerificationAvfRequired(structure: IUtilizationGroup, index: number) {
        if (!this.isHiltiRebarDesign()) {
            structure.items.splice(index, 0, {
                id: 'shear-verification-avf-required',
                imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ShearVerification.AvfRequired',
                property: 'avfRequired',
                progressBar: false,
                unitGroup: UnitGroup.Area,
                role: GroupItemRole.Result,
                comparable: false
            });
        }
    }

    private getAciCsaSeismicSpliceOrSpecialMomentFrameLength(getSpecialMomentFrameLength = false): IUtilizationGroup {
        let id: string;
        let tensionProperty: string;
        let compressionProperty: string;
        let title = 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.SeismicDevelopmentLength';
        let titleSuffix = '';

        if (this.isJointsSpecialMomentsFrame) {
            title = 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.SeismicDevelopmentLength.SpecialMomentFrame';
            titleSuffix = '(l<sub>d</sub>)';
        }

        if (!getSpecialMomentFrameLength) {
            id = this.displayDevelopmentLength ? 'seismic-development-length' : 'seismic-splice-length';
            title = this.displayDevelopmentLength ? title : 'Agito.Hilti.C2C.Utilizations.RebarUtilizations.SeismicSpliceLength';
            tensionProperty = 'seismicTension';
            compressionProperty = 'seismicCompression';
        }
        else {
            id = this.displayDevelopmentLength ? 'seismic-special-moment-development-length' : 'seismic-special-moment-splice-length';
            title = this.displayDevelopmentLength ? 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.SeismicSpecialMomentDevelopmentLength'
                : 'Agito.Hilti.C2C.Utilizations.RebarUtilizations.SeismicSpecialMomentSpliceLength';
            tensionProperty = 'specialMomentFrameSeismicTension';
            compressionProperty = 'specialMomentFrameSeismicCompression';
            titleSuffix = this.displayDevelopmentLength ? '(l<sub>dm</sub>)' : titleSuffix;
        }

        return {
            id,
            title,
            titleSuffix: titleSuffix,
            image: 'sprite-rebar-icon',
            control: CollapsingControls.PirSpliceLengthUtilization,
            orderNumber: 5,
            items: [
                {
                    id: `${id}-tension`,
                    comparable: false,
                    role: GroupItemRole.Result,
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.SeismicDevelopmentLength.Tension',
                    property: tensionProperty,
                    unitGroup: UnitGroup.Length,
                },
                {
                    id: `${id}-compression`,
                    comparable: false,
                    role: GroupItemRole.Result,
                    imageTitle: 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.SeismicDevelopmentLength.Compression',
                    property: compressionProperty,
                    unitGroup: UnitGroup.Length,
                },
            ]
        };
    }
}
