import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';
import lShapeLimitationImage from '../../../images/LShapeLimitation/l-shape-limitation.png';

@Component({
    templateUrl: './l-shape-limitation-modal.component.html',
    styleUrls: ['./l-shape-limitation-modal.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LShapeLimitationModalComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public lShapeLimitationImage = '';

    constructor(
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.lShapeLimitationImage = lShapeLimitationImage;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
