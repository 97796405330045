import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import {
    NewDesignDataEntityC2C
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    InterfaceShearCalculationParametersAs
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';
import { DesignStandard } from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IDetailedDisplayDesign } from './display-design';
import { UserSettingsC2C as UserSettings } from './user-settings';

export interface IInterfaceShearCalculationData {
    // Context
    design?: () => IDetailedDisplayDesign;
    settings?: () => UserSettings;
    connectionType?: number;
    addEditType?: AddEditType;

    // Units
    areaPerLengthUnit: UnitType;

    // Values
    shearStrutAngle?: number;
    shearInclination?: number;

    interfaceShearCalculationParametersAs: InterfaceShearCalculationParametersAs;
}

export function setInterfaceShearCalculationValuesToDesign(design: IDetailedDisplayDesign, data: IInterfaceShearCalculationData) {
    design.shearStrutAngle = data.shearStrutAngle;
    design.shearInclination = data.shearInclination;

    if (design.designStandardC2C?.id != DesignStandard.ASBased || !design.designOptionsAs)
        return;

    design.designOptionsAs.interfaceShearCalculationParameters = {
        shearTensileAngle: data.interfaceShearCalculationParametersAs.shearTensileAngle,
        strutAngleNewConcrete: data.interfaceShearCalculationParametersAs.strutAngleNewConcrete
    } as InterfaceShearCalculationParametersAs;
}

export function setInterfaceShearCalculationValuesToNewDesign(newDesign: NewDesignDataEntityC2C, data: IInterfaceShearCalculationData) {
    newDesign.shearStrutAngle = data.shearStrutAngle;
    newDesign.shearInclination = data.shearInclination;

    if (newDesign.designStandard != DesignStandard.ASBased || !newDesign.designOptionsAs)
        return;

    newDesign.designOptionsAs.interfaceShearCalculationParameters = {
        shearTensileAngle: data.interfaceShearCalculationParametersAs.shearTensileAngle,
        strutAngleNewConcrete: data.interfaceShearCalculationParametersAs.strutAngleNewConcrete
    } as InterfaceShearCalculationParametersAs;
}
