import { NewDesignDataEntityC2C } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { SteelMaterialOptionsAs } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';
import { DesignStandard } from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IDetailedDisplayDesign } from './display-design';
import { UserSettingsC2C as UserSettings } from './user-settings';

export interface ISteelMaterialInputsData {
    // Context
    design?: () => IDetailedDisplayDesign;
    settings?: () => UserSettings;
    connectionType?: number;
    unit?: number;

    // Values
    steelEpsilonUd?: number;
    steelE?: number;
    steelMaterialOptionsAs: SteelMaterialOptionsAs;
}

export function setSteelMaterialValuesToDesign(design: IDetailedDisplayDesign, data: ISteelMaterialInputsData) {
    design.epsilonUd = data.steelEpsilonUd;
    design.E = data.steelE;

    if (design.designStandardC2C?.id != DesignStandard.ASBased || !design.designOptionsAs)
        return;

    design.designOptionsAs.steelMaterialOptions = {
        e: data.steelMaterialOptionsAs.e,
        epsilonUd: data.steelMaterialOptionsAs.epsilonUd
    } as SteelMaterialOptionsAs;
}

export function setSteelMaterialValuesToNewDesign(newDesign: NewDesignDataEntityC2C, data: ISteelMaterialInputsData) {
    newDesign.epsilonUd = data.steelEpsilonUd;
    newDesign.e = data.steelE;

    if (newDesign.designStandard != DesignStandard.ASBased || !newDesign.designOptionsAs)
        return;

    newDesign.designOptionsAs.steelMaterialOptions = {
        e: data.steelMaterialOptionsAs.e,
        epsilonUd: data.steelMaterialOptionsAs.epsilonUd
    } as SteelMaterialOptionsAs;
}
