import { ButtonGroupDisplay, ButtonGroupType, NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { getSpriteAsIconStyle } from '../../sprites';
import { createC2CMenu_ExistingStructure_ExistingConcrete } from './static-menu-existing-structure';
import { getCommandConditional, getPopupTooltipConditional, isACICSA, isCSA, isPirHNA } from './static-menu-helper';

export function createC2CMenu_NewConcrete(design: DesignC2C): TabC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.NewConcrete',
        Id: 23,
        Name: 'newconcrete-tab',
        Image: 'tab-new-structure',
        IconImage: getSpriteAsIconStyle('sprite-tab-new-structure'),
        Width: NavigationTabWidth.Normal,
        TabRegions: isPirHNA(design) ? getNewConcreteHNARegions(design) : getNewConcreteRegions(design)
    };
}

function getNewConcreteRegions(design: DesignC2C): RegionC2C[] {
    return [
        createC2CMenu_ExistingStructure_ExistingConcrete(design),
        createC2CMenu_NewConcrete_NewConcreteGeometry(design),
        createC2CMenu_NewConcrete_ModelRotation(),
        createC2CMenu_NewConcrete_CalculationData(design),
        createC2CMenu_NewConcrete_OffsetDistance(design)
    ];
}

function getNewConcreteHNARegions(design: DesignC2C): RegionC2C[] {
    return [
        createC2CMenu_NewConcrete_NewConcreteGeometry(design),
        createC2CMenu_NewConcrete_ModelRotation(),
        createC2CMenu_NewConcrete_CalculationData(design),
        createC2CMenu_NewConcrete_OffsetDistance(design)
    ];
}

function createC2CMenu_NewConcrete_NewConcreteGeometry(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.NewConcrete.Title',
        Name: 'ACI-CSA-newconcretegeometry-section',
        Id: 24,
        Controls: [
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupDisplay: ButtonGroupDisplay.Join,
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'cross-section-new-buttons',
                UIPropertyId: 10517,
                CodelistName: 'CrossSection',
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.CrossSection',
                TooltipType: TooltipType.Normal,
                Hidden: !design.isPirHna
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Size: 50,
                Name: 'ACI-CSA-new-cross-diameter',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Diameter',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Diameter',
                UIPropertyId: 10521,
                Hidden: !design.isPirHna
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Size: 50,
                Name: 'ACI-CSA-new-width',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Width',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Width',
                UIPropertyId: 10399
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'ACI-CSA-new-height',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Height',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Height',
                UIPropertyId: 10400
            }
        ]
    };
}

function createC2CMenu_NewConcrete_ModelRotation(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Title',
        Name: 'ACI-CSA-3Drotation-section',
        Id: 25,
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: 800,
                Name: 'ACI-CSA-3Drotation',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Angle',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Angle',
                UIPropertyId: 10401
            },
            {
                ControlType: 'Rotate',
                RotateLeftUIPropertyId: 10403,
                RotateRightUIPropertyId: 10402,
                Name: 'ACI-CSA-3Drotation-buttons',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Rotation',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Rotation',
                UIPropertyId: 10402
            }
        ]
    };
}

function createC2CMenu_NewConcrete_CalculationData(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Title',
        Name: 'ACI-CSA-calculationdata-section',
        Id: 26,
        Controls: [
            {
                ControlType: 'Checkbox',
                Name: 'ACI-CSA-calculationdata-optimizevalues',
                UIPropertyId: 10404,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Optimized',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenOptimizedValuesPopup', isACICSA(design))
            },
            {
                ControlType: 'Checkbox',
                Name: 'ACI-CSA-calculationdata-usefullcrosssection',
                UIPropertyId: 10733,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.UseFullCrossSection',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenUseFullCrossSectionPopup', isACICSA(design))
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 200,
                Name: 'ACI-CSA-calculationdata-areaofconcreteshearinterface',
                DisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Acv' : 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Ac',
                TitleDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Acv' : 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Ac',
                UIPropertyId: 10405
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 200,
                Name: 'ACI-CSA-calculationdata-areaofsteelinshearinterface',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Avf',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Avf',
                UIPropertyId: 10406
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 400,
                Name: 'ACI-CSA-calculationdata-permanentnetcompression',
                DisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.NetCompression.CSA' : 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.NetCompression',
                TitleDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.NetCompression.CSA' : 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.NetCompression',
                UIPropertyId: 10407,
                TooltipType: getPopupTooltipConditional(isCSA(design)),
                Command: getCommandConditional('OpenNetCompressionPopup', isCSA(design))
            }
        ]
    };
}

function createC2CMenu_NewConcrete_OffsetDistance(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.OffsetDistance.Title',
        Name: 'ACI-CSA-offsetdistance-section',
        Id: 27,
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Length,
                Name: 'ACI-CSA-additionaloffsetdistance',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.OffsetDistance.AdditionalOffset',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.OffsetDistance.AdditionalOffset',
                UIPropertyId: 10501,
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenAdditionalOffsetDistancePopup', isACICSA(design))
            }
        ]
    };
}
