<div class="design-section box-section box-section-white" *ngIf="design">
    <div class="box-section-content">
        <div class="design-section-row">
            <button id="export-button" type="button" class="generate-report-button button button-primary"
                (click)="generateReport()"
                [disabled]="generateReportDisabled || loading">
                <pe-loading-button
                    [loading]="loading"
                    [color]="'#ffffff'">
                    <div class="generate-report-button-container">
                        <span class="text" l10n="Agito.Hilti.Profis3.Main.GenerateReport"></span>
                        <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-long-arrow-right-white"></span>
                    </div>
                </pe-loading-button>
            </button>
        </div>

        <div class="design-section-row">
            <button id="save-button" type="button" class="save-button button button-design-section"
                [class.button-design-section-desktop]="offlineService.isOffline"
                [disabled]="isDesignInReadOnlyMode"
                [class.unsaved-changes]="isStateChanged"
                *ngIf="offlineService.isOffline" (click)="saveDesignOffline()"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Save' | l10n: localizationService.selectedLanguage" placement="top">
                <div class="button-sprite-wrapper">
                    <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-save-design"></span>
                </div>
            </button>

            <button id="export-button" type="button" class="export-button button button-design-section"
                [disabled]="isDesignInReadOnlyMode" (click)="openExport()"
                [class.button-design-section-desktop]="offlineService.isOffline"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.ExportReport' | l10n: localizationService.selectedLanguage" placement="top">
                <div class="button-sprite-wrapper">
                    <span *ngIf="!offlineService.isOffline" class="pe-ui-c2c-sprite pe-ui-c2c-sprite-export-design"></span>
                    <span *ngIf="offlineService.isOffline" class="pe-ui-c2c-sprite pe-ui-c2c-sprite-export-design-desktop"></span>
                </div>
            </button>

            <button id="duplicate-button" type="button" class="duplicate-button button button-design-section"
                (click)="duplicateDesign()"
                [class.button-design-section-desktop]="offlineService.isOffline"
                [disabled]="duplicating || isDesignInReadOnlyMode"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.DuplicateDesign' | l10n: localizationService.selectedLanguage" placement="top">
                <div class="button-sprite-wrapper">
                    <pe-loading-button
                        [loading]="duplicating">
                        <span *ngIf="!offlineService.isOffline" class="pe-ui-c2c-sprite pe-ui-c2c-sprite-duplicate-design"></span>
                        <span *ngIf="offlineService.isOffline" class="pe-ui-c2c-sprite pe-ui-c2c-sprite-duplicate-design-desktop"></span>
                    </pe-loading-button>
                </div>
            </button>

            <button id="open-button" type="button" class="open-button button button-design-section"
                (click)="selectOpenFile()"
                [class.button-design-section-desktop]="offlineService.isOffline"
                [disabled]="openDesign || isDesignInReadOnlyMode"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.OpenDesign' | l10n: localizationService.selectedLanguage" placement="top">
                <div class="button-sprite-wrapper">
                    <pe-loading-button
                        [loading]="openDesign">
                        <span *ngIf="!offlineService.isOffline" class="pe-ui-c2c-sprite pe-ui-c2c-sprite-openfile-d-light"></span>
                        <span *ngIf="offlineService.isOffline" class="pe-ui-c2c-sprite pe-ui-c2c-sprite-openfile-desktop"></span>
                    </pe-loading-button>
                </div>
            </button>

            <input class="open-design-input" #openDesignInput (change)="importFileSelected()" name="project" type="file"
                accept=".pe,.pa2" (click)="$event.stopPropagation();" />
        </div>
    </div>
</div>
