import { ButtonGroupDisplay, ButtonGroupType, NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { getSpriteAsIconStyle } from '../../sprites';

export function createC2CMenu_Application(design: DesignC2C): TabC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.Application',
        Id: 38,
        Name: 'application-tab',
        Image: 'tab-application-pir',
        IconImage: getSpriteAsIconStyle('sprite-tab-application-pir'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createC2CMenu_Application_Connection(),
            createC2CMenu_Application_3dModelRotation(),
            createC2CMenu_Application_ExistingStructure(design),
            createC2CMenu_Application_NewStructure(design),
        ]
    };
}
function createC2CMenu_Application_Connection(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabApplication.RegionConnectionApplication',
        Id: 39,
        Name: 'connectionapplication-section',
        Controls: [
            {
                ControlType: 'ImageNameSelectionGroup',
                CodelistName: 'ConnectionTypesC2C',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabApplication.Connections',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabApplication.Connections',
                Name: 'connectiontype',
                UIPropertyId: 10014
            },
            {
                ControlType: 'ImageNameSelectionGroup',
                CodelistName: 'ApplicationTypesC2C',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabApplication.Applications',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabApplication.Applications',
                Name: 'applicationtype',
                UIPropertyId: 10015
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.ConnectionApplication.ExtensionAtSupport',
                Name: 'extensionatsupport',
                UIPropertyId: 10523,
                TooltipDisplayKey: '',
                TooltipType: TooltipType.Popup,
                Command: 'OpenExtensionAtSupportPopup'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.ConnectionApplication.XDirectionContinous',
                Name: 'xdirectioncontinous',
                UIPropertyId: 10515,
                TooltipDisplayKey: '',
                TooltipType: TooltipType.Popup,
                Command: 'OpenContinuousInXPopup'
            }
        ]
    };
}

function createC2CMenu_Application_3dModelRotation(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabApplication.Region3dModelRotation',
        Id: 40,
        Name: '3dmodelrotation-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: '3dmodelrotation-angle',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.3dModelRotation.Angle',
                UIPropertyId: 10516,
                UnitGroup: UnitGroup.Angle,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'Rotate',
                RotateLeftUIPropertyId: 10520,
                RotateRightUIPropertyId: 10519,
                Name: '3dmodelrotation-buttons',
                Size: 50,
                DisplayKey: '',
                TitleDisplayKey: 'Agito.Hilti.C2C.3dModelRotation.Rotation',
                UIPropertyId: 10520
            }
        ]
    };
}

function createC2CMenu_Application_ExistingStructure(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabApplication.RegionExistingStructure',
        Id: 41,
        Name: 'existingstructure-section',
        Controls: [
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupDisplay: ButtonGroupDisplay.Join,
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'cross-section-existing-buttons',
                UIPropertyId: 10518,
                CodelistName: 'CrossSection',
                TitleDisplayKey: 'Agito.Hilti.C2C.ExistingStructure.CrossSection',
                TooltipType: TooltipType.Normal,
                Hidden: design.isPirHna
            }
        ]
    };
}

function createC2CMenu_Application_NewStructure(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabApplication.RegionNewStructure',
        Id: 42,
        Name: 'newstructure-section',
        Controls: [
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupDisplay: ButtonGroupDisplay.Join,
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'cross-section-new-buttons',
                UIPropertyId: 10517,
                CodelistName: 'CrossSection',
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.CrossSection',
                TooltipType: TooltipType.Normal,
                Hidden: design.isPirHna
            }
        ]
    };
}
