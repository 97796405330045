import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';

import {
    NewDesignDataEntityC2C
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { StrutTies } from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IDetailedDisplayDesign } from './display-design';
import { UserSettingsC2C as UserSettings } from './user-settings';

export interface IStrutTiesInputsData {
    // Context
    design?: () => IDetailedDisplayDesign;
    settings?: () => UserSettings;
    connectionType?: number;
    addEditType?: AddEditType;

    // Values
    strutTies?: number;
    strutTiesInput?: number;
    concreteResistanceCompression?: number;
}

export function setStrutTiesModelCalculationValuesToDesign(design: IDetailedDisplayDesign, data: IStrutTiesInputsData) {
    design.strutTies = data.strutTies;
    design.strutTiesInput = data.strutTiesInput;
    design.concreteResistanceCompression = data.concreteResistanceCompression;
}

export function setStrutTiesModelCalculationValuesToNewDesign(newDesignC2C: NewDesignDataEntityC2C, data: IStrutTiesInputsData) {
    newDesignC2C.strutTies = data.strutTies as StrutTies;
    newDesignC2C.strutTiesInput = data.strutTiesInput;
    newDesignC2C.concreteResistanceCompression = data.concreteResistanceCompression;
}
