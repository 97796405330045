import { CodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    CodeListResponseC2C,
    PropertyValueEntityC2C
} from '../generated-modules/Hilti.PE.C2CCodeListService.Entities';


export abstract class CodeListServiceBase {
    public abstract initialize(data: CodeListResponseC2C): void;
    public abstract projectCodeListsC2C: { [codeList: number]: CodeList[] };
    public abstract getPropertyValue(id: number, regionId: number, connectionTypeId: number, designStandardId?: number): PropertyValueEntityC2C;
}

export class CodeListServiceInjected extends CodeListServiceBase {
    private baseService!: CodeListServiceBase;

    public get projectCodeListsC2C(): { [codeList: number]: CodeList[] } {
        return this.baseService.projectCodeListsC2C;
    }

    public setBaseService(baseService: CodeListServiceBase) {
        this.baseService = baseService;
        this.getPropertyValue = baseService.getPropertyValue.bind(baseService);
        this.initialize = baseService.initialize.bind(baseService);
    }

    // CodeListServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getPropertyValue(_id: number, _regionId: number, _connectionTypeId: number, _designStandardId?: number, _designMethodId?: number) {
        return {} as PropertyValueEntityC2C;
    }
    public initialize(_data: CodeListResponseC2C): void {
        return;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}

