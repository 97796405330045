import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { UIPropertyBaseControl } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitServiceBase } from '@profis-engineering/pe-ui-common/services/unit.common';
import { MainMenuCommandC2C } from '../command';
import { LongitudinalRebarShape, RebarBond } from '@profis-engineering/c2c-gl-model/components/base-component';
import { IdValuePair } from '@profis-engineering/c2c-gl-model/components/base-rebar-helper';
import { IDropdownItem } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';

export interface AdvancedPointsTable extends UIPropertyBaseControl<MainMenuCommandC2C> {
    ControlType: 'AdvancedPointsTable';
}

export interface ISelectRebarProps extends IControlProps {
    buttonText?: string;
    buttonImage?: string;
    buttonImageStyle?: IResponsiveIconStyle;
    isNew?: boolean;
    internalPortfolioOnly?: boolean;
    tag?: string;
    clicked?: () => void;
}

export interface ITemperatureTableProps extends IControlProps {
    unitService: UnitServiceBase;
    items?: ITemperatureTableItem[];
    defaultTemp?: number;
    minTemp?: number;
    maxTemp?: number;
    valueChanged?: (item: ITemperatureTableItem) => void;
}

export interface ITemperatureTableItem {
    id: string;
    temperature: number;
    translationKey: string;
}

export enum AdvancedPointsTableType {
    ExistingReinforcement = 100,
    PostInstallRebar = 101
}

export interface IAdvancedPointsTableProps extends IPointsTableDropdownValuesProps, IControlProps {
    items?: IAdvancedTableItemBase[];
    tableType?: AdvancedPointsTableType;
    isEditable?: boolean;
    canInsert?: boolean;
    addInput?: IAdvancedPointTableAddInput;
    rowsPerScroll?: number;
    unitService?: UnitServiceBase;
    valueChanged?: (item: IAdvancedModifiedItem) => void;
    onCommit?: (item: IAdvancedModifiedItem) => void;
    onDelete?: (index: number) => void;
    addInputChanged?: (item: IAdvancedPointTableAddInput) => void;
    addInputCommit?: (item: IAdvancedPointTableAddInput) => void;
}

export interface IAdvancedTableItemBase extends IAdvancedPointsTableItem {
    id: number;
}

export interface IAdvancedPointTableAddInput extends IPointsTableDropdownValuesProps {
    x?: string;
    y?: string;
}

export interface IAdvancedModifiedItem {
    id: number;
    x?: string;
    y?: string;
    rebarDiameter?: number;
    bond?: number;
    shape?: number;
    layerId?: string;
}

export interface IAdvancedPointsTableItem {
    x: number;
    y: number;
    formattedX: string;
    formattedY: string;
    exactValueX: string;
    exactValueY: string;
    rebarDiameter?: number;
    shape?: number;
    bond?: number;
    layerId?: string;
}

export interface ITableInputDataC2C extends ITableItemRebarBase {
    diameter: IdValuePair;
}

export interface ITableItemRebarBase extends IAdvancedTableItemBase {
    shape: LongitudinalRebarShape;
    bond: RebarBond;
    layerId: string;
}

export interface ITableItemC2C extends ITableItemRebarBase {
    diameter: number;
}

export interface IPointsTableDropdownValuesProps extends IAdvancedPointsTableDropdownsProps {
    diameter: number;
    bond: number;
    shape: number;
}

export interface IAdvancedPointsTableDropdownsProps {
    rebarDiameters?: IAdvancedPointsTableDropdownItem[];
    shapes?: IAdvancedPointsTableDropdownItem[];
    bonds?: IAdvancedPointsTableDropdownItem[];
}

export interface IAdvancedPointsTableDropdownItem extends IDropdownItem {
    isExtended: boolean;
}
