import clone from 'lodash-es/clone';

import {
    Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewEncapsulation
} from '@angular/core';

import { environment } from '../../../environments/environmentC2C';
import applicationTypeImages from '../../../images/ApplicationType';
import applicationTypeSquareImage from '../../../images/ApplicationType/Square.png';
import connectionTypeImages from '../../../images/ConnectionType';
import {
    ApplicationType as ApplicationTypeC2C
} from '../../../shared/entities/code-lists/application-type';
import {
    ConnectionType as ConnectionTypeC2C
} from '../../../shared/entities/code-lists/connection-type';
import {
    ISelectConnectionApplicationData, selectedDesignStandard
} from '../../../shared/entities/select-connection-application-data';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, ConnectionType, DesignStandard
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { isConnectionTypeVisibleC2C } from '../../../shared/helpers/connection-type-helper';
import { AppSettingsHelperC2C } from '../../helpers/app-settings-helper';
import { CodeListService as CodeListServiceC2C } from '../../services/code-list.service';
import { CommonCodeListService as CodeListService } from '../../services/common-code-list.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UserSettingsService } from '../../services/user-settings.service';
import {
    IGridImageSelectItem, IGridImageSelectProps
} from '../grid-image-select/grid-image-select.component';

@Component({
    templateUrl: './select-connection-application.component.html',
    styleUrls: ['./select-connection-application.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectConnectionApplicationComponent implements OnInit, DoCheck {
    @Input()
    public data!: ISelectConnectionApplicationData;

    @Input()
    public overlayOnly!: boolean;

    @Input()
    public standalone = false;

    @Output()
    public selectedConnectionTypeChange = new EventEmitter<ConnectionType>();

    public selectConnectionTypeC2C: IGridImageSelectProps = {};
    public selectApplicationTypeC2C: IGridImageSelectProps = {};
    public collapseRegion!: {
        Connection: boolean;
        Application: boolean;
    };
    public showApplication!: boolean;
    public showConnection!: boolean;
    public dataLoaded = false;

    private oldData!: ISelectConnectionApplicationData;
    private appSettingsHelper: AppSettingsHelperC2C;

    constructor(
        public localizationService: LocalizationService,
        private codeList: CodeListService,
        private codeListC2C: CodeListServiceC2C,
        private userSettings: UserSettingsService,
        private numberService: NumberService,
        private featureVisibilityService: FeaturesVisibilityService,
    ) {
        this.appSettingsHelper = new AppSettingsHelperC2C(this.localizationService, this.userSettings, this.codeList, this.codeListC2C, this.numberService);
    }

    private get isEntryPointSplitted() {
        return this.appSettingsHelper.isEntryPointSplitted(this.data.regionId ?? 0, this.data.designStandard);
    }

    ngOnInit(): void {
        this.collapseRegion = {
            Application: false,
            Connection: false
        };
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setConnectionApplicationTypeControls();
            this.dataLoaded = true;
        }

        this.updateData();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public isPirEuConnectionTypeAvailableC2C(): boolean {
        return (isConnectionTypeVisibleC2C(this.codeListC2C, ConnectionType.Splices, selectedDesignStandard(this.data, ConnectionType.Splices), this.data.regionId ?? 0)
            && !isConnectionTypeVisibleC2C(this.codeListC2C, ConnectionType.StructuralJoints, selectedDesignStandard(this.data, ConnectionType.StructuralJoints), this.data.regionId ?? 0)
            && isConnectionTypeVisibleC2C(this.codeListC2C, ConnectionType.ConcreteOverlay, selectedDesignStandard(this.data, ConnectionType.ConcreteOverlay), this.data.regionId ?? 0));
    }

    public onSelectConnectionTypeC2C(itemId: number) {
        this.setSelectedConnectionType(itemId);

        const connectionTypes = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.ConnectionTypesC2C] as ConnectionTypeC2C[];
        const applicationTypes = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.ApplicationTypesC2C] as ApplicationTypeC2C[];

        const selectedConnectionApplications = connectionTypes.find(conn => conn.id == this.data.selectedConnectionType)?.applicationTypes ?? [];
        const availableApplications = applicationTypes.filter(app => selectedConnectionApplications.includes(app.id ?? 0));

        if (!this.featureVisibilityService.isFeatureEnabled('C2C_ThinOverlay') && availableApplications.some(x => x.id == ApplicationType.ThinOverlay)) {
            availableApplications.splice(availableApplications.findIndex(x => x.id == ApplicationType.ThinOverlay), 1);
        }

        const applicationTypesItems = availableApplications
            .filter(appType => appType.designStandards?.some(id => id == this.data.designStandard))
            .map((appType) => ({
                image: appType.image == ''
                    ? applicationTypeSquareImage
                    : applicationTypeImages[`${appType.image}.png`],
                name: this.translate('Agito.Hilti.C2C.CodeList.ApplicationTypeEntity.' + appType.displayKey),
                id: appType.id,
                hidden: appType.hidden,
                disabled: this.isApplicationTypeDisabled(appType.id ?? 0),
                displayKey: appType.displayKey
            }));

        this.selectApplicationTypeC2C.numberOfColumns = applicationTypesItems.filter(x => !x.hidden).length;
        this.selectApplicationTypeC2C.items = applicationTypesItems.filter(x => !x.hidden);
        this.selectApplicationTypeC2C.selectedValue = applicationTypesItems.some((x) => x.id == this.selectApplicationTypeC2C.selectedValue && !x.disabled) ?
            this.selectApplicationTypeC2C.selectedValue : this.getDefaultValueForApplicationTypeC2C(itemId, applicationTypesItems);

        this.onSelectApplicationTypeC2C(this.selectApplicationTypeC2C.selectedValue ?? 0);
    }

    public onSelectApplicationTypeC2C(itemId: number) {
        this.data.selectedApplicationType = itemId;
    }

    private updateData() {
        let update = false;

        if (this.oldData != null) {
            if (this.oldData.designStandard != this.data.designStandard ||
                this.oldData.designStandardPIR != this.data.designStandardPIR) {
                update = true;
                this.setConnectionApplicationTypeControls();
            }

            if (this.oldData.selectedConnectionType != this.data.selectedConnectionType) {
                update = true;
                this.onSelectConnectionTypeC2C(this.data.selectedConnectionType ?? ConnectionType.Unknown);
                this.setConnectionApplicationTypeControls();
            }

            if (this.oldData.regionId != this.data.regionId) {
                update = true;
                this.setConnectionApplicationTypeControls();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);
        }
    }

    private getDefaultValueForApplicationTypeC2C(connectionTypeId: number, applicationTypesItems: Array<any>): number {
        const defaultValue = this.codeListC2C.getPropertyValue(UIProperty.General_C2C_ApplicationType, this.data.regionId ?? 0, connectionTypeId, this.data.designStandard)?.defaultValue;
        return applicationTypesItems.some((x) => x.id == defaultValue && !x.disabled) ? defaultValue : applicationTypesItems.find((x) => !x.disabled)?.id;
    }

    private isConnectionTypeDisabled(id: number) {
        return this.data.inEditMode
            ? id != this.data.selectedConnectionType
            : false;
    }

    private isApplicationTypeDisabled(id: number) {
        return this.data.inEditMode
            ? id != this.data.selectedApplicationType
            : false;
    }

    private setSelectedConnectionType(type: ConnectionType) {
        if (this.data.selectedConnectionType != type) {
            this.data.selectedConnectionType = type;
            this.selectedConnectionTypeChange.emit(this.data.selectedConnectionType);
        }
    }

    private setConnectionApplicationTypeControls() {
        const connectionTypes = this.getConnectionTypes();
        const connectionTypesItems = this.getConnectionTypesItems(connectionTypes);

        let selectedConnectionTypeId = this.data.selectedConnectionType ?? ConnectionType.Unknown;
        if (selectedConnectionTypeId == null || !connectionTypesItems.some(x => x.id == this.data.selectedConnectionType && !x.disabled && !x.hidden)) {
            const selectedConnectionTypeCandidate = connectionTypesItems.find((x) => !x.disabled && !x.hidden);
            // If all connection types are disabled
            if (selectedConnectionTypeCandidate == null) {
                this.showApplication = false;
                return;
            }

            selectedConnectionTypeId = selectedConnectionTypeCandidate.id ?? ConnectionType.Unknown;
        }

        this.selectConnectionTypeC2C = {
            numberOfColumns: connectionTypesItems.filter(x => !x.hidden).length,
            items: connectionTypesItems.filter(x => !x.hidden),
            selectedValue: selectedConnectionTypeId
        };

        const selectedApplicationTypeId = this.data.selectedApplicationType;
        this.selectApplicationTypeC2C = {
            selectedValue: selectedApplicationTypeId,
        };

        // trigger onSelect to init the application types
        this.onSelectConnectionTypeC2C(selectedConnectionTypeId);

        this.showApplication = this.isPir() || this.isHna()
            ? false
            : (this.selectApplicationTypeC2C.items?.length ?? 0) > 0;

        this.showConnection = !this.isEntryPointSplitted && (!this.overlayOnly || (this.selectApplicationTypeC2C.items?.length ?? 0) <= 1);
    }

    private getConnectionTypes(): ConnectionTypeC2C[] {
        return this.data.designStandardPIR == DesignStandard.ETAG
            ? this.codeListC2C.projectCodeListsC2C[ProjectCodeList.ConnectionTypesC2C] as ConnectionTypeC2C[]
            : this.getHnaControls();
    }

    private getConnectionTypesItems(connectionTypes: ConnectionTypeC2C[]): IGridImageSelectItem[] {
        return connectionTypes
            .filter(connType => (connType.applicationTypes?.length ?? 0) > 0)
            .map((conType) => ({
                image: connectionTypeImages[`${conType.image}.png`],
                name: this.getDesignRegionSpecificLocalizedString(conType.nameResourceKey ?? '', selectedDesignStandard(this.data, conType.id ?? 0)),
                id: conType.id,
                disabled: this.isConnectionTypeDisabled(conType.id ?? 0),
                displayKey: conType.displayKey,
                hidden: !isConnectionTypeVisibleC2C(this.codeListC2C, conType.id ?? 0, selectedDesignStandard(this.data, conType.id ?? 0), this.data.regionId ?? 0)
            }));
    }

    private getHnaControls() {
        if (environment.c2cOverlayDemoEnabled) {
            return this.codeListC2C.projectCodeListsC2C[ProjectCodeList.ConnectionTypesC2C]
                .filter(x => x.id == ConnectionType.ConcreteOverlay) as ConnectionTypeC2C[];
        }
        return this.codeListC2C.projectCodeListsC2C[ProjectCodeList.ConnectionTypesC2C] as ConnectionTypeC2C[];
    }

    private isPir(): boolean {
        return (this.data.selectedConnectionType == ConnectionType.Splices || this.data.selectedConnectionType == ConnectionType.StructuralJoints);
    }

    private isHna(): boolean {
        return this.data.designStandard == DesignStandard.ACI || this.data.designStandard == DesignStandard.CSA;
    }

    private getDesignRegionSpecificLocalizedString(translationKey: string, designStandard: DesignStandard) {
        if ([DesignStandard.ACI, DesignStandard.CSA].includes(designStandard)) {
            const translationKeyRegion = `${translationKey}.HNA`;

            if (this.localizationService.getKeyExists(translationKeyRegion)) {
                return this.translate(translationKeyRegion);
            }
        }

        if (this.data.designStandardPIR == DesignStandard.ETAG) {
            const translationKeyRegion = `${translationKey}.PIREU`;

            if (this.localizationService.getKeyExists(translationKeyRegion)) {
                return this.translate(translationKeyRegion);
            }
        }

        return this.translate(translationKey);
    }
}
