import { ConnectionType as ConnectionTypeC2C } from '../entities/code-lists/connection-type';
import {
    MethodGroupConnTypeStandardRegion
} from '../entities/code-lists/connection-type-method-standard-regions';
import {
    ConnectionType, DesignStandard
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { CodeListServiceBase as CodeListServiceC2C } from '../services/code-list.service.base';
import { ProjectCodeList } from '../enums/project-code-list';

export function isOnlyOverlay(codeListC2C: CodeListServiceC2C, regionId: number): boolean {
    const methodGroupConnTypeStandardRegionsC2C = codeListC2C.projectCodeListsC2C[ProjectCodeList.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];
    const connectionTypeItems = methodGroupConnTypeStandardRegionsC2C.filter(dmeth => dmeth.regionId == regionId);

    return !connectionTypeItems.some(x => x.connectionTypeId != ConnectionType.ConcreteOverlay);
}

export function getAvailableConnectionTypesForRegionC2C(codeListC2C: CodeListServiceC2C, regionId: number) {
    const allConnectionType = codeListC2C.projectCodeListsC2C[ProjectCodeList.ConnectionTypesC2C] as ConnectionTypeC2C[];
    const methodGroupConnTypeStandardRegionsC2C = codeListC2C.projectCodeListsC2C[ProjectCodeList.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];

    const filteredConnTypes = methodGroupConnTypeStandardRegionsC2C
        .filter(x => x.regionId == regionId)
        .map(x => x.connectionTypeId);

    return allConnectionType.filter(c => filteredConnTypes.includes(c.id)).map(c => c.id);
}

export function isConnectionTypeVisibleC2C(codeListC2C: CodeListServiceC2C, connectionTypeId: number, designStandard: number, regionId: number): boolean {
    const connectionTypeDesignStandardRegionsC2C = codeListC2C.projectCodeListsC2C[ProjectCodeList.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];

    // filter connection type by region and design standard
    const connectionTypeItems = connectionTypeDesignStandardRegionsC2C.filter(c => c.regionId == regionId && c.designStandardId == designStandard);

    return connectionTypeItems.some(c => c.connectionTypeId == connectionTypeId);
}

export function isPirEuConnectionTypeAvailableC2C(codeListC2C: CodeListServiceC2C, designStandard: number, regionId: number): boolean {
    return designStandard == DesignStandard.ETAG &&
        (isConnectionTypeVisibleC2C(codeListC2C, ConnectionType.Splices, designStandard, regionId)
            && !isConnectionTypeVisibleC2C(codeListC2C, ConnectionType.StructuralJoints, designStandard, regionId)
            && isConnectionTypeVisibleC2C(codeListC2C, ConnectionType.ConcreteOverlay, designStandard, regionId));
}
