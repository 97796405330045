import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { getSpriteAsIconStyle } from '../../sprites';
import { getCommandConditional, getPopupTooltipConditional, isACICSA, isCSA, isOverlay } from './static-menu-helper';
import { createC2CMenu_OverlayInstallationCondition_BaseMaterial } from './static-menu-overlay-concrete';

export function createC2CMenu_OverlayInstallationCondition(design: DesignC2C): TabC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.InstallationCondition',
        Id: 21,
        Name: 'overlay-installationcondition-tab',
        Image: 'tab-overlay-installation',
        IconImage: getSpriteAsIconStyle('sprite-tab-overlay-installation'),
        Width: NavigationTabWidth.Normal,
        TabRegions:
            isOverlay(design) && isACICSA(design)
                ? createC2CMenu_OverlayInstallationCondition_HNARegions(design)
                : createC2CMenu_OverlayInstallationCondition_Regions(design),
    };
}

function createC2CMenu_OverlayInstallationCondition_HNARegions(design: DesignC2C): RegionC2C[] {
    return [
        createC2CMenu_OverlayInstallationCondition_BaseMaterial(design),
        createC2CMenu_OverlayInstallationCondition_OverlayPostion(),
        createC2CMenu_OverlayInstallationCondition_Temperature(),
        createC2CMenu_OverlayInstallationCondition_SurfaceTreatment(design),
        createC2CMenu_OverlayInstallationCondition_InstallationConditions(),
        createC2CMenu_OverlayInstallationCondition_ContactSurfaceCondition(design)
    ];
}

function createC2CMenu_OverlayInstallationCondition_Regions(design: DesignC2C): RegionC2C[] {
    return [
        createC2CMenu_OverlayInstallationCondition_OverlayPostion(),
        createC2CMenu_OverlayInstallationCondition_Temperature(),
        createC2CMenu_OverlayInstallationCondition_SurfaceTreatment(design),
        createC2CMenu_OverlayInstallationCondition_InstallationConditions(),
        createC2CMenu_OverlayInstallationCondition_ContactSurfaceCondition(design)
    ];
}


function createC2CMenu_OverlayInstallationCondition_OverlayPostion(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabInstallationCondition.RegionOverlayPostion',
        Id: 17,
        Name: 'overlayposition-section',
        Controls: [{
            ControlType: 'RadioButtonGroup',
            Name: 'overlayposition',
            UIPropertyId: 10132,
            CodelistName: 'OverlayPosition',
            DisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.OverlayPosition.Position',
            TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.OverlayPosition.Position'
        }]
    };
}

function createC2CMenu_OverlayInstallationCondition_Temperature(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabInstallationCondition.RegionTemperature',
        Id: 17,
        Name: 'temperature-section',
        Controls: [{
            ControlType: 'TextBox',
            Name: 'shortterm-input',
            Size: 50,
            TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.Temperature.ShortTerm',
            UIPropertyId: 10133,
            UnitGroup: UnitGroup.Temperature,
            TooltipDisplayKey: '',
            TooltipTitleDisplayKey: '',
            DisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.Temperature.ShortTerm'
        },
        {
            ControlType: 'TextBox',
            Name: 'longterm-input',
            Size: 50,
            TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.Temperature.LongTerm',
            DisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.Temperature.LongTerm',
            UIPropertyId: 10134,
            UnitGroup: UnitGroup.Temperature,
            TooltipDisplayKey: '',
            TooltipTitleDisplayKey: ''
        },
        {
            CodelistName: 'TemperatureInstallation',
            ControlType: 'Dropdown',
            Name: 'temperatureinstallation-dropdown',
            TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.Temperature.Installation',
            DisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.Temperature.Installation',
            UIPropertyId: 10135,
            TooltipDisplayKey: '',
            TooltipTitleDisplayKey: ''
        }
        ]
    };
}

function createC2CMenu_OverlayInstallationCondition_SurfaceTreatment(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.RegionSurfaceTreatment',
        Id: 17,
        Name: 'surfacetreatment-section',
        Controls: [{
            ControlType: 'Dropdown',
            Name: 'jointroughness',
            UIPropertyId: 10136,
            CodelistName: 'JointRoughness',
            DisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.SurfaceTreatment.JointRoughness',
            TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.SurfaceTreatment.JointRoughness'
        },
        {
            ControlType: 'Dropdown',
            Name: 'surfacetreatment',
            UIPropertyId: 10263,
            DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.HiltiRebarRoughness',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.HiltiRebarRoughness',
            CodelistName: 'SurfaceTreatment',
            TooltipType: getPopupTooltipConditional(isACICSA(design)),
            Command: getCommandConditional('OpenSurfaceTreatmentPopup', isACICSA(design))
        }]
    };
}

function createC2CMenu_OverlayInstallationCondition_InstallationConditions(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.RegionInstallationConditions',
        Id: 17,
        Name: 'installationconditions-section',
        Controls: [{
            ControlType: 'Dropdown',
            Name: 'drillingtype',
            UIPropertyId: 10137,
            CodelistName: 'DrillingType',
            DisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.InstallationConditions.DrillingType',
            TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.InstallationConditions.DrillingType'
        },
        {
            ControlType: 'Dropdown',
            Name: 'holecondition',
            UIPropertyId: 10477,
            CodelistName: 'HoleCondition',
            DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionInstallationConditions.ControlHoleCondition',
            TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionInstallationConditions.ControlHoleCondition'
        },
        {
            ControlType: 'Dropdown',
            Name: 'holetype',
            UIPropertyId: 10138,
            CodelistName: 'HoleType',
            DisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.InstallationConditions.HoleType',
            TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.InstallationCondition.InstallationConditions.HoleType'
        },
        {
            ControlType: 'TextBox',
            Name: 'drillingangle',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.DrillingAngle',
            UIPropertyId: 10279,
            UnitGroup: UnitGroup.Angle,
            TooltipDisplayKey: '',
            TooltipTitleDisplayKey: '',
            DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.DrillingAngle'
        }]
    };
}

function createC2CMenu_OverlayInstallationCondition_ContactSurfaceCondition(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceConditionSection',
        Id: 36,
        Name: 'contactsurfacecondition-section',
        Controls: [{
            ControlType: 'Dropdown',
            Name: 'contactsurfacecondition-dropdown',
            UIPropertyId: 10476,
            CodelistName: 'ContactSurfaceCondition',
            DisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition.CSA' : 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition',
            TitleDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition.CSA' : 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition',
            TooltipType: getPopupTooltipConditional(isACICSA(design)),
            Command: getCommandConditional('OpenContactSurfaceConditionPopup', isACICSA(design))
        },
        {
            ControlType: 'Dropdown',
            Name: 'contactsurfacecondition-aci31811-dropdown',
            UIPropertyId: 10509,
            CodelistName: 'ContactSurfaceCondition',
            DisplayKey: 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition.ACI31811',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition.ACI31811',
            TooltipType: getPopupTooltipConditional(isACICSA(design)),
            Command: getCommandConditional('OpenContactSurfaceConditionPopup', isACICSA(design))
        }]
    };
}
