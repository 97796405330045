import {
    CommonRegion as Region
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Unit } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import {
    ApplicationSettingsCommon, GeneralValue, IDefaultApplicationSettingsBase,
    IGeneralApplicationSettingsBase, IQuickStartSettingsBase, UserSettings as UserSettingsCommon,
    UserSettingsValue
} from '@profis-engineering/pe-ui-common/entities/user-settings';
import { ApplicationState } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    IQuickStartUserSettings
} from '@profis-engineering/pe-ui-common/services/user-settings.common';

import { ImportLoadsNumberType } from '../enums/import-loads-number-type';
import { DesignMethodGroup } from './code-lists/design-method-group';
import { DesignStandard } from './code-lists/design-standard';

export interface QuickStartSettings extends IQuickStartUserSettings {
    general_region: Region;
    units_area?: Unit;
    units_force?: Unit;
    units_length?: Unit;
    units_length_large?: Unit;
    units_moment?: Unit;
    units_stress?: Unit;
    units_stress_small?: Unit;
    units_temperature?: Unit;
    units_force_per_length?: Unit;
    units_moment_per_length?: Unit;
    units_density?: Unit;
    units_area_per_length?: Unit;

    calculation_designMethodC2C: DesignMethodGroup;
    calculation_designStandardC2C: DesignStandard;
    calculation_overlayDesignMethodC2C: DesignMethodGroup;
    calculation_overlayDesignStandardC2C: DesignStandard;
    overlayUseHiltiRebarDesign: boolean;
}

export interface IC2CQuickStartSettings extends IQuickStartSettingsBase {
    applicationState: UserSettingsValue<ApplicationState | null>;
    calculationOverlayDesignMethodGroupId: GeneralValue<number | null>;
    calculationOverlayDesignStandardId: GeneralValue<number | null>;
    overlayUseHiltiRebarDesign: GeneralValue<boolean | null>;
    unitsAreaPerLengthId: GeneralValue<number | null>;
    unitsDensityId: GeneralValue<number | null>;
    unitsForcePerLengthId: GeneralValue<number | null>;
    unitsMomentPerLengthId: GeneralValue<number | null>;
}

export interface IQuickStartSettingsC2C {
    concrete2Concrete: IC2CQuickStartSettings;
}

export interface IApplicationConcrete2ConcreteSettings {
    selectedApplicationTypeId: UserSettingsValue<number | null>;
    selectedConnectionTypeId: UserSettingsValue<number | null>;
}


export interface IApplicationImportLoadsC2C {
    numberTypeOne: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwo: GeneralValue<ImportLoadsNumberType>;
    numberTypeThree: GeneralValue<ImportLoadsNumberType>;
    numberTypeFour: GeneralValue<ImportLoadsNumberType>;
    numberTypeFive: GeneralValue<ImportLoadsNumberType>;
    numberTypeSix: GeneralValue<ImportLoadsNumberType>;
    numberTypeSeven: GeneralValue<ImportLoadsNumberType>;
    numberTypeEight: GeneralValue<ImportLoadsNumberType>;
    numberTypeNine: GeneralValue<ImportLoadsNumberType>;
    numberTypeTen: GeneralValue<ImportLoadsNumberType>;
    numberTypeEleven: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwelve: GeneralValue<ImportLoadsNumberType>;
    numberTypeThirteen: GeneralValue<ImportLoadsNumberType>;
    numberTypeFourteen: GeneralValue<ImportLoadsNumberType>;
    numberTypeFifteen: GeneralValue<ImportLoadsNumberType>;
    numberTypeSixteen: GeneralValue<ImportLoadsNumberType>;
    numberTypeSeventeen: GeneralValue<ImportLoadsNumberType>;
    numberTypeEighteen: GeneralValue<ImportLoadsNumberType>;
    numberTypeNineteen: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwenty: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwentyone: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwentytwo: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwentythree: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwentyfour: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwentyfive: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwentysix: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwentyseven: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwentyeight: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwentynine: GeneralValue<ImportLoadsNumberType>;
    forceUnit: GeneralValue<UnitType | null>;
    momentUnit: GeneralValue<UnitType | null>;
    stressUnit: GeneralValue<UnitType | null>;
}

export class ApplicationSettingsC2C extends ApplicationSettingsCommon<IGeneralApplicationSettingsBase, IDefaultApplicationSettingsBase> {
    concrete2Concrete!: IApplicationConcrete2ConcreteSettings;
}

export interface IApplicationModelDisplayOptionsC2C {
    zonesVisible: GeneralValue<boolean>;
    loadsC2C: GeneralValue<boolean>;
    zonesDimensionsVisible: GeneralValue<boolean>;
    concreteDimensionsC2C: GeneralValue<boolean>;
    transparentBaseMaterialC2C: GeneralValue<boolean>;
    existingReinforcement: GeneralValue<boolean>;
    newConcreteStructure: GeneralValue<boolean>;
    existingConcreteStructure: GeneralValue<boolean>;
    connectorSpacingDimensions: GeneralValue<boolean>;
    connectorEdgeDistanceDimensions: GeneralValue<boolean>;
    postRebarsNumber: GeneralValue<boolean>;
    postRebarInstallationLengthsAndCovers: GeneralValue<boolean>;
    postRebarDimensions: GeneralValue<boolean>;
    continuousXDirectionRepresentation: GeneralValue<boolean>;
}

export class UserSettingsC2C extends UserSettingsCommon<IApplicationImportLoadsC2C, IQuickStartSettingsC2C, ApplicationSettingsC2C, IApplicationModelDisplayOptionsC2C> {
    public rebarFavorites: GeneralValue<{ [rebarId: number]: void }>;
    public overlayvirtualtourSeen: GeneralValue<boolean | null>;

    constructor(translate: boolean) {
        super();

        this.quickStart = {
            concrete2Concrete: {
                applicationState: new GeneralValue(ApplicationState.AlwaysAskMe),
                calculationDesignMethodGroupId: new GeneralValue(null),
                calculationDesignStandardId: new GeneralValue(null),
                calculationOverlayDesignMethodGroupId: new GeneralValue(null),
                calculationOverlayDesignStandardId: new GeneralValue(null),
                generalRegionId: new GeneralValue(null),
                overlayUseHiltiRebarDesign: new GeneralValue(null),
                unitsAreaId: new GeneralValue(null),
                unitsAreaPerLengthId: new GeneralValue(null),
                unitsDensityId: new GeneralValue(null),
                unitsForceId: new GeneralValue(null),
                unitsForcePerLengthId: new GeneralValue(null),
                unitsLengthId: new GeneralValue(null),
                unitsMomentId: new GeneralValue(null),
                unitsMomentPerLengthId: new GeneralValue(null),
                unitsStressId: new GeneralValue(null),
                unitsTemperatureId: new GeneralValue(null)
            }
        };

        this.application = {
            general: UserSettingsCommon.getBaseGeneralApplicationSettingsDefaults(translate),
            defaults: UserSettingsCommon.getBaseDefaultApplicationSettingsDefaults(),
            controls: UserSettingsCommon.getBaseControlsSettingsDefaults(),
            concrete2Concrete: {
                selectedApplicationTypeId: new GeneralValue(null),
                selectedConnectionTypeId: new GeneralValue(null)
            },
            advancedCalculation: UserSettingsCommon.getBaseAdvancedCalculationApplicationSettingsDefaults(),
        };

        this.rebarFavorites = new GeneralValue({});

        this.applicationImportLoads = {
            numberTypeOne: new GeneralValue(ImportLoadsNumberType.ForceX),
            numberTypeTwo: new GeneralValue(ImportLoadsNumberType.ForceY),
            numberTypeThree: new GeneralValue(ImportLoadsNumberType.ForceZ),
            numberTypeFour: new GeneralValue(ImportLoadsNumberType.MomentX),
            numberTypeFive: new GeneralValue(ImportLoadsNumberType.MomentY),
            numberTypeSix: new GeneralValue(ImportLoadsNumberType.MomentZ),
            numberTypeSeven: new GeneralValue(ImportLoadsNumberType.ForceZSustained),
            numberTypeEight: new GeneralValue(ImportLoadsNumberType.MomentXSustained),
            numberTypeNine: new GeneralValue(ImportLoadsNumberType.MomentYSustained),
            numberTypeTen: new GeneralValue(ImportLoadsNumberType.ForceXVariable),
            numberTypeEleven: new GeneralValue(ImportLoadsNumberType.ForceYVariable),
            numberTypeTwelve: new GeneralValue(ImportLoadsNumberType.MomentZVariable),
            numberTypeThirteen: new GeneralValue(ImportLoadsNumberType.Zone1),
            numberTypeFourteen: new GeneralValue(ImportLoadsNumberType.Zone2),
            numberTypeFifteen: new GeneralValue(ImportLoadsNumberType.Zone3),
            numberTypeSixteen: new GeneralValue(ImportLoadsNumberType.Zone4),
            numberTypeSeventeen: new GeneralValue(ImportLoadsNumberType.ZoneGeneric),
            numberTypeEighteen: new GeneralValue(ImportLoadsNumberType.ZoneGenericStatic),
            numberTypeNineteen: new GeneralValue(ImportLoadsNumberType.ZoneGenericDynamic),
            numberTypeTwenty: new GeneralValue(ImportLoadsNumberType.Zone1Static),
            numberTypeTwentyone: new GeneralValue(ImportLoadsNumberType.Zone1Dynamic),
            numberTypeTwentytwo: new GeneralValue(ImportLoadsNumberType.Zone2Static),
            numberTypeTwentythree: new GeneralValue(ImportLoadsNumberType.Zone2Dynamic),
            numberTypeTwentyfour: new GeneralValue(ImportLoadsNumberType.Zone3Static),
            numberTypeTwentyfive: new GeneralValue(ImportLoadsNumberType.Zone3Dynamic),
            numberTypeTwentysix: new GeneralValue(ImportLoadsNumberType.Zone4Static),
            numberTypeTwentyseven: new GeneralValue(ImportLoadsNumberType.Zone4Dynamic),
            numberTypeTwentyeight: new GeneralValue(ImportLoadsNumberType.TedX),
            numberTypeTwentynine: new GeneralValue(ImportLoadsNumberType.TedY),
            forceUnit: new GeneralValue(null),
            momentUnit: new GeneralValue(null),
            stressUnit: new GeneralValue(null)
        };

        this.overlayvirtualtourSeen = new GeneralValue(null);

        this.applicationModelDisplayOptions = {
            zonesVisible: new GeneralValue(true),
            loadsC2C: new GeneralValue(true),
            zonesDimensionsVisible: new GeneralValue(true),
            concreteDimensionsC2C: new GeneralValue(true),
            transparentBaseMaterialC2C: new GeneralValue(true),
            existingReinforcement: new GeneralValue(true),
            newConcreteStructure: new GeneralValue(true),
            existingConcreteStructure: new GeneralValue(true),
            connectorSpacingDimensions: new GeneralValue(true),
            connectorEdgeDistanceDimensions: new GeneralValue(true),
            postRebarsNumber: new GeneralValue(true),
            postRebarInstallationLengthsAndCovers: new GeneralValue(true),
            postRebarDimensions: new GeneralValue(true),
            continuousXDirectionRepresentation: new GeneralValue(true)
        };
    }
}
