import { Observable } from 'rxjs';

import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation
} from '@angular/core';
import {
    ExportReportRowBase, ExportReportRowStatus, IExportReportRowResult
} from '@profis-engineering/pe-ui-common/components/export-report-row-base/export-report-row-base.common';
import { IDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';

import { DesignC2C } from '../../../shared/entities/design-c2c';
import { ZoneAnalysisInput } from '../../../shared/entities/zone-analysis-input';
import {
    ApplicationType, ConnectionType, ReportPaperSize, ReportType, ZoneName
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    ReportOptionEntityC2C
} from '../../../shared/generated-modules/Hilti.PE.ReportService.Shared.Entities';
import { getZoneName, getZoneNameByName } from '../../../shared/helpers/zone-helper';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { DocumentServiceC2C } from '../../services/document.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';

enum ProgressDefinitions {
  DesignOpened = 10,
  CalculationDone = 70,
  ReportGenerated = 100,
}

@Component({
  templateUrl: './export-report-row.component.html',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportReportRowComponent extends ExportReportRowBase implements OnChanges {

  @Input()
  public displayDesign!: IDisplayDesign;

  @Input()
  public cancelExport!: Observable<boolean>;

  @Output()
  exportReportCompleated = new EventEmitter<IExportReportRowResult>();

  @Output()
  deleteExport = new EventEmitter<IDisplayDesign>();

  public design?: DesignC2C;

  public zoneAnalysisDiagramContent = '';

  public zoneAnalysisInput?: ZoneAnalysisInput;

  constructor(
    private calculationServiceC2C: CalculationServiceC2C,
    private documentService: DocumentServiceC2C,
    private userSettingsService: UserSettingsService,
    localizationService: LocalizationService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef, localizationService);
  }

  ngOnChanges(): void {
    this.cancelExport.subscribe(() => this.cancel());
    this.calculateDesignAndCreatePdf();
  }

  private async calculateDesignAndCreatePdf() {
    const documentDesign = this.documentService.findDesignById(this.displayDesign.id);
    this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.Generating');

    try {
      const projectDesign = await this.documentService.openDesignExclusiveC2C(documentDesign);
      this.percentage = ProgressDefinitions.DesignOpened;
      this.design = (await this.calculationServiceC2C.openFromProjectDesignC2C(projectDesign, documentDesign.id, false)).design;
      this.percentage = ProgressDefinitions.CalculationDone;

      if (this.zoneDiagramExist) {
        this.zoneAnalysisInput = {
          getZoneNameByName: zone => getZoneNameByName(zone) as unknown as ZoneName,
          calculateZoneColorFactors: (n, z) => this.calculateZoneColorFactors(n, z as unknown as ZoneName),
          getZoneName: (n) => getZoneName(n),
          createScreenshot: () => this.zoneAnalysisInput?.createScreenshot() ?? {} as Promise<string>
        };
      }

      if (this.design.designData?.reportDataC2C?.canExportReport) {
        await this.downloadReport();
      } else {
        this.canNotExportReport();
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  private calculateZoneColorFactors(numberOfZones: number, zoneName: ZoneName): number {
    const ZoneColorFactors: Map<number, Map<ZoneName, number>> = new Map([
      [4, new Map([[ZoneName.Z1, 0.25], [ZoneName.Z2, 0.41], [ZoneName.Z3, 0.58], [ZoneName.Z4, 0.75]])],
      [3, new Map([[ZoneName.Z1, 0.25], [ZoneName.Z2, 0.50], [ZoneName.Z3, 0.75]])],
      [2, new Map([[ZoneName.Z1, 0.25], [ZoneName.Z2, 0.75]])],
      [1, new Map([[ZoneName.Z1, 0.25]])],
    ]);
    const zoneColorFactors = ZoneColorFactors.get(numberOfZones);
    let result = 0;
    zoneColorFactors?.forEach((value: number, key: ZoneName) => {
      if (zoneName == key) {
        result = value;
      }
    });
    return result;
  }

  public get cancellationToken(): Deferred<void> | undefined {
    return this.design?.cancellationToken;
  }

  private async downloadReport() {
    if (!this.design) {
      throw new Error('design not defined');
    }

    this.changeDetectorRef.detectChanges();

    // zone diagram screenshot
    await this.createZoneDiagramImage();

    const reportOptions = this.getReportOptions();
    const blob = await this.calculationServiceC2C.generateAndDownloadReport(this.design, false, reportOptions, [this.zoneAnalysisDiagramContent]);

    this.percentage = ProgressDefinitions.ReportGenerated;
    if (this.design?.modelChanges?.changes?.length) {
      this.status = ExportReportRowStatus.Warning;
      this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.DesignDefaultsChanged');
    }
    else {
      this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.Generated');
    }
    this.exportResults(blob);
  }

  private getReportOptions(): ReportOptionEntityC2C {
    const designOptions = this.design?.designData?.projectDesignC2C?.options;

    return {
      notes: designOptions?.reportNotes,
      noteLoadResultingAnchorForces: designOptions?.noteLoadCaseResultingRebarForces,
      noteTensionLoad: designOptions?.noteTensionLoad,
      noteShearLoad: designOptions?.noteShearLoad,
      noteInstallationData: designOptions?.noteInstallationData,
      companyName: designOptions?.reportCompanyName,
      contactPerson: designOptions?.reportContactPerson,
      phoneNumber: designOptions?.reportPhoneNumber,
      email: designOptions?.reportEmail,
      type: (this.design?.reportTypeId as ReportType),
      reportLanguageId: designOptions?.reportLanguageId ?? 1033,
      paperSize: designOptions?.reportPaperSize ?? ReportPaperSize.A4,
      firstPage: designOptions?.reportFirstPage ?? 1,
      address: designOptions?.reportAddress,
      includeItemNumber: true,
      customPictureTypes: [],
      reportTemplateId: designOptions?.reportTemplateId ?? 0,
      trimbleConnectFolderId: designOptions?.trimbleConnectFolderId ?? '',
      trimbleConnectLocation: designOptions?.trimbleConnectLocation ?? '',
      trimbleConnectReportName: designOptions?.trimbleConnectReportName ?? '',
      trimbleConnectUpload: false,
      includeAnchorCalculations: false,
      reportTemplateOptions: [],
      removeProjectHeader: false,
      calculationSettings: [],
      includeApprovals: false,
      forceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value as boolean,
      includeSustainability: this.design?.designData?.reportDataC2C?.isSustainabilityAvailable ?? false
    };
  }

  private async createZoneDiagramImage() {
    if (this.zoneDiagramExist) {
      this.zoneAnalysisDiagramContent = await this.zoneAnalysisInput?.createScreenshot() ?? '';
    }
  }

  private get zoneDiagramExist() {
    return this.design?.connectionType == ConnectionType.ConcreteOverlay && this.design?.applicationType != ApplicationType.GenericReinforcement;
  }
}
