import { NewDesignDataEntityC2C } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { ConcreteMaterialOptionsAs } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';
import { DesignStandard } from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IDetailedDisplayDesign } from './display-design';
import { UserSettingsC2C as UserSettings } from './user-settings';

export interface IConcreteMaterialInputsData {
    // Context
    design?: () => IDetailedDisplayDesign;
    settings?: () => UserSettings;
    connectionType?: number;
    lengthUnit: number;

    // Values
    concreteAlphaCc?: number;
    concreteEpsilonC2?: number;
    concreteEpsilonCu2?: number;
    concreteAlphaCt?: number;
    minConcreteCover?: number;

    concreteMaterialOptionsAs: ConcreteMaterialOptionsAs;
}

export function setConcreteMaterialValuesToDesign(design: IDetailedDisplayDesign, data: IConcreteMaterialInputsData) {
    design.alphaCcC2C = data.concreteAlphaCc;
    design.concreteEpsilonC2 = data.concreteEpsilonC2;
    design.concreteEpsionCu2 = data.concreteEpsilonCu2;
    design.alphaCtC2C = data.concreteAlphaCt;
    design.minConcreteCover = data.minConcreteCover;

    if (design.designStandardC2C?.id != DesignStandard.ASBased || !design.designOptionsAs)
        return;

    design.designOptionsAs.concreteMaterialOptions = {
        beta: data.concreteMaterialOptionsAs.beta,
        epsilonC2: data.concreteMaterialOptionsAs.epsilonC2,
        epsilonCU2: data.concreteMaterialOptionsAs.epsilonCU2,
        minConcreteCover: data.concreteMaterialOptionsAs.minConcreteCover
    } as ConcreteMaterialOptionsAs;
}

export function setConcreteMaterialValuesToNewDesign(newDesign: NewDesignDataEntityC2C, data: IConcreteMaterialInputsData) {
    newDesign.alphaCC = data.concreteAlphaCc;
    newDesign.epsilonC2 = data.concreteEpsilonC2;
    newDesign.epsilonCU2 = data.concreteEpsilonCu2;
    newDesign.alphaCT = data.concreteAlphaCt;
    newDesign.minConcreteCover = data.minConcreteCover;

    if (newDesign.designStandard != DesignStandard.ASBased || !newDesign.designOptionsAs)
        return;

    newDesign.designOptionsAs.concreteMaterialOptions = {
        beta: data.concreteMaterialOptionsAs.beta,
        epsilonC2: data.concreteMaterialOptionsAs.epsilonC2,
        epsilonCU2: data.concreteMaterialOptionsAs.epsilonCU2,
        minConcreteCover: data.concreteMaterialOptionsAs.minConcreteCover
    } as ConcreteMaterialOptionsAs;
}
