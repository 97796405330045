import isEqual from 'lodash-es/isEqual';
import isNaN from 'lodash-es/isNaN';

import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ITemperatureTableItem, ITemperatureTableProps } from '../../../../shared/entities/main-menu/main-menu-controls';
import { LocalizationService } from '../../../services/localization.service';
import { UnitService } from '../../../services/unit.service';
import styles from './react-temparature-table.css';
import { ContolsStyleSheets } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';

const ROW_HEIGHT = 32;

const sheet = new CSSStyleSheet();
sheet.replaceSync(styles);


const ControlHeader = (window as any).pe.reactComponents.ControlHeader;
export class _TemperatureTable extends React.PureComponent<ITemperatureTableProps> {
    public static readonly styleSheets = [sheet];

    constructor(props: ITemperatureTableProps) {
        super(props);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const temperatureTableId = `${this.props.controlId}-temperature-table`;
        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;
        const displayTemperatureUnit = this.props.unitService.getDefaultUnit(UnitGroup.Temperature);

        const items = this.prepareItems();

        return (
            <div data-control-id={this.props.controlId} className={`control react-temperature-table-control ${this.props.sizeClass} full-width`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={temperatureTableId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className={'temperature-table-container'} style={{ ['--height' as any]: ROW_HEIGHT }} >
                    <div className={'table-row header'} id={`${this.props.controlId}-header`}>
                        <div className={'id'} id={`${this.props.controlId}-header-id`}>#</div>
                        <div className={'number'} id={`${this.props.controlId}-header-temp`}>{this.props.localization?.getString('Agito.Hilti.C2C.TemperatureTable.Temperature')}</div>
                    </div>

                    {
                        items.map(item =>
                            <TemperatureTableRow
                                controlName={this.props.controlId}
                                key={item.id}
                                item={item}
                                valueChanged={this.props.valueChanged}
                                minTemp={this.props.minTemp}
                                maxTemp={this.props.maxTemp}
                                localization={this.props.localization as LocalizationService}
                                unit={this.props.unitService as UnitService}
                                temperatureUnit={displayTemperatureUnit} />
                        )
                    }
                </div>
            </div>

        );
    }

    private prepareItems(): ITemperatureTableItem[] {
        return this.props.items!.map((item, i) => {
            const temp = item.temperature ?? this.props.defaultTemp ?? 0;

            return { ...item,
                temperature: temp,
                id: item.translationKey ? item.id : (i+1).toString()
            } as ITemperatureTableItem;
        });
    }
}

interface ITemperatureTableRowProps {
    controlName: string;
    item: ITemperatureTableItem;
    localization?: LocalizationService;
    unit: UnitService;
    temperatureUnit: Unit;
    minTemp?: number;
    maxTemp?: number;
    valueChanged?: (item: ITemperatureTableItem) => void;
}

interface ITemperatureTableRowState {
    temperature: string;
    exactValue?: string;
}

class TemperatureTableRow extends React.PureComponent<ITemperatureTableRowProps, ITemperatureTableRowState> {
    constructor(props: ITemperatureTableRowProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onEnter = this.onEnter.bind(this);

        this.state = {
            temperature: this.formatTemperature(this.props.item.temperature),
            exactValue: this.getExactTemperature(this.props.item.temperature)
        };
    }

    public override componentDidUpdate(prevProps: ITemperatureTableRowProps) {
        if (!isEqual(this.props, prevProps)) {
            this.setState({
                temperature: this.formatTemperature(this.props.item.temperature),
                exactValue: this.getExactTemperature(this.props.item.temperature)
            });
        }
    }

    public override render() {
        const rowId = this.props.item.id;

        const rowName = this.props.item.translationKey == null
            ? rowId
            : this.props.localization?.getString(this.props.item.translationKey);

        return (
            <div className='table-row' id={`${this.props.controlName}-row-${rowId}`}>
                <div className={'id'} id={`${this.props.controlName}-row-${rowId}-id`}>{rowName}</div>

                <input
                    id={`${this.props.controlName}-row-${rowId}-temp`}
                    className={'number input'}
                    value={this.state.temperature}
                    title={this.state.exactValue}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onKeyPress={this.onEnter}
                    type='text'
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    autoCapitalize={'off'}
                    spellCheck={false} />

            </div>
        );
    }

    private onChange(event: React.FormEvent) {
        const value = (event.target as HTMLInputElement).value;
        this.setState({ temperature: value });
    }

    private onBlur(event: React.FocusEvent) {
        this.commitValue();
    }

    private onEnter(event: React.KeyboardEvent) {
        if (event.key == 'Enter') {
            this.commitValue();
        }
    }

    private commitValue() {
        let value = this.props.unit.formatInternalValueAsDefault(this.props.item.temperature, UnitGroup.Temperature);

        if (value != this.state.temperature) {
            let inputTemperature
            if (this.state.temperature) {
                inputTemperature = this.convertTemperature(this.state.temperature);
            }
            else {
                inputTemperature = this.props.item.temperature;
            }

            const temperature = this.minMaxFilter(inputTemperature);

            this.setState({
                temperature: this.formatTemperature(temperature),
                exactValue: this.getExactTemperature(temperature)
            });

            if (!Number.isNaN(temperature)) {
                if (this.props.valueChanged != null) {
                    this.props.valueChanged({ ...this.props.item, temperature: temperature });
                }
            }
        }
    }

    private minMaxFilter(temperature: number): number {
        if (isNaN(temperature)) {
            return this.props.item.temperature;
        }

        const min = this.props.minTemp;
        const max = this.props.maxTemp;

        if (max != null && temperature > max) {
            temperature = max;
        }
        else if (min != null && temperature < min) {
            temperature = min;
        }

        return temperature;
    }

    private formatTemperature(internalValue: number): string {
        const internalUnit = this.props.unit.getInternalUnit(UnitGroup.Temperature);
        const defaultValue = this.props.unit.convertUnitValueArgsToUnit(internalValue, internalUnit, this.props.temperatureUnit);
        return this.props.unit.formatUnitValueArgs(defaultValue, this.props.temperatureUnit);
    }

    private convertTemperature(formattedTemperature: string): number {
        const unitValue = this.props.unit.parseUnitValue(formattedTemperature, UnitGroup.Temperature);
        const internalValue = this.props.unit.convertUnitValueToUnit(unitValue, this.props.unit.getInternalUnit(UnitGroup.Temperature));
        return internalValue.value;
    }

    private getExactTemperature(internalValue: number) {
        const maxPrecision = (this.props.unit.getDefaultPrecision() ?? 0) + 1; // Same as used in server code
        const unitValue = this.props.unit.convertInternalValueToDefaultUnitValue(internalValue, UnitGroup.Temperature);
        const displayedUnitValue = this.props.unit.formatUnitValue(unitValue);
        const exactUnitValue = this.props.unit.formatUnitValue(unitValue, maxPrecision);

        if (displayedUnitValue?.length < exactUnitValue?.length) {
            return exactUnitValue;
        }

        return undefined;
    }
}

export const TemperatureTable: typeof _TemperatureTable & ContolsStyleSheets = _TemperatureTable;
