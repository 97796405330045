<div class="interface-shear-calculation content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <ng-container *ngIf="!useAusOptions">
                <div class="control-interfaceShearCalculation">
                    <pe-numeric-text-box
                        [id]="shearStrutAngle.id"
                        [title]="shearStrutAngle.title"
                        [placeholder]="shearStrutAngle.placeholder"
                        [value]="data.shearStrutAngle"
                        (valueChange)="data.shearStrutAngle = $any($event).detail"
                        [unit]="shearStrutAngle.unit"
                        [minValue]="shearStrutAngle.minValue"
                        [maxValue]="shearStrutAngle.maxValue"
                        [disabled]="disabled">
                    </pe-numeric-text-box>
                </div>

                <div class="control-interfaceShearCalculation">
                    <pe-numeric-text-box
                        [id]="shearInclination.id"
                        [title]="shearInclination.title"
                        [placeholder]="shearInclination.placeholder"
                        [value]="data.shearInclination"
                        (valueChange)="data.shearInclination = $any($event).detail"
                        [unit]="shearInclination.unit"
                        [minValue]="shearInclination.minValue"
                        [maxValue]="shearInclination.maxValue"
                        [disabled]="disabled">
                    </pe-numeric-text-box>
                </div>
            </ng-container>
            <ng-container *ngIf="useAusOptions">
                <!-- strut angle new concrete -->
                <div class="control-interfaceShearCalculation">
                    <pe-numeric-text-box
                        [id]="strutAngleNewConcrete.id"
                        [title]="strutAngleNewConcrete.title"
                        [placeholder]="strutAngleNewConcrete.placeholder"
                        [value]="strutAngleNewConcreteValue"
                        (valueChange)="data.interfaceShearCalculationParametersAs.strutAngleNewConcrete = $any($event).detail"
                        [unit]="strutAngleNewConcrete.unit"
                        [minValue]="strutAngleNewConcrete.minValue"
                        [maxValue]="strutAngleNewConcrete.maxValue"
                        [disabled]="disabled">
                    </pe-numeric-text-box>
                </div>

                <!-- shear tensile angle -->
                <div class="control-interfaceShearCalculation">
                    <pe-numeric-text-box
                        [id]="shearTensileAngle.id"
                        [title]="shearTensileAngle.title"
                        [placeholder]="shearTensileAngle.placeholder"
                        [value]="shearTensileAngleValue"
                        (valueChange)="data.interfaceShearCalculationParametersAs.shearTensileAngle = $any($event).detail"
                        [unit]="shearTensileAngle.unit"
                        [minValue]="shearTensileAngle.minValue"
                        [maxValue]="shearTensileAngle.maxValue"
                        [disabled]="disabled">
                    </pe-numeric-text-box>
                </div>
            </ng-container>
        </div>
    </div>
</div>
