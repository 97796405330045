import cloneDeep from 'lodash-es/cloneDeep';

import {
    Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    ToggleButtonGroupItem
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    Unit as UnitCodeListItem
} from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { replace } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    CommonCodeList as ProjectCodeList
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { NumberType } from '@profis-engineering/pe-ui-common/services/number.common';

import { environment } from '../../../environments/environmentC2C';
import { ImportLoadsNumberType } from '../../../shared/enums/import-loads-number-type';
import {
    LoadCombinationC2C as LoadCombination
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, ConnectionType, DesignStandard as DesignStandardEnum, LoadingDefinitionType,
    LoadType, ShearUtilizationValueEntityC2C, UtilizationValueEntityC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C as PropertyMetaData } from '../../../shared/properties/properties';
import { InternalLoadType, LoadsComponentHelper } from '../../helpers/loads-component-helper';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { CodeListService as CodeListServiceC2C } from '../../services/code-list.service';
import { CommonCodeListService as CodeListService } from '../../services/common-code-list.service';
import { GuidService } from '../../services/guid.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle } from '../../sprites';

const keyTab = 'Tab';

interface IParseResponse {
    loads: LoadCombination[];
    limitReached: boolean;
}

export enum ImportLoadsView {
    copyPaste,
    import
}

interface ILoadsDropdownHeader {
    index: number;
    class: string;
    dropdown: DropdownProps<ImportLoadsNumberType>;
}

@Component({
    templateUrl: './import-loads.component.html',
    styleUrls: ['./import-loads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ImportLoadsComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;
    public view = ImportLoadsView.copyPaste;
    public importData!: string | null;
    public importedLoads: LoadCombination[] = [];
    public limitReached!: boolean;

    public forceUnitDropdown!: DropdownProps<Unit>;
    public momentUnitDropdown!: DropdownProps<Unit>;
    public stressUnitDropdown!: DropdownProps<Unit>;
    public loadingDefinitionType!: LoadingDefinitionType;
    public loadsDropdownHeaders: ILoadsDropdownHeader[] = [];
    public loadTypeToggleItems!: ToggleButtonGroupItem<InternalLoadType>[];
    public helper: LoadsComponentHelper;

    public importedLoadRowHeight = 31;   // must be the same value as in css
    public scrollElement!: Element;

    // constants
    public viewEnum = ImportLoadsView;
    public loadTypeEnum = InternalLoadType;

    private isPasted = false;
    private copyPasteInput!: HTMLInputElement;
    private pendingSave = false;
    public requiredValidator = Validators.required;

    constructor(
        public localization: LocalizationService,
        private modal: ModalService,
        private user: UserService,
        private userSettings: UserSettingsService,
        private unit: UnitService,
        private numberService: NumberService,
        private guid: GuidService,
        private calculationService: CalculationServiceC2C,
        translationFormatService: TranslationFormatService,
        public codeListC2C: CodeListServiceC2C,
        public codeList: CodeListService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        this.helper = new LoadsComponentHelper(
            localization,
            user.design,
            unit,
            modal,
            translationFormatService,
            codeListC2C
        );
    }

    public get design() {
        return this.user.design;
    }

    public get showForceUnit() {
        if (this.design.connectionType == ConnectionType.ConcreteOverlay) {
            return this.loadingDefinitionType == LoadingDefinitionType.ShearLoad;
        }

        return true;
    }

    public get showMomentUnit() {
        return this.design.connectionType != ConnectionType.ConcreteOverlay;
    }

    public get showStressUnit() {
        switch (this.design.connectionType) {
            case ConnectionType.ConcreteOverlay:
                return (this.loadingDefinitionType == LoadingDefinitionType.ShearLoad)
                    ? false
                    : true;
            default:
                return false;
        }
    }

    public get hasImportedLoads() {
        return this.importedLoads.length > 0;
    }

    public get showLoadTypes() {
        return !this.isOverlayFatigue;
    }

    private get isOverlayFatigue() {
        return this.design.connectionType == ConnectionType.ConcreteOverlay && this.design.loadTypeC2C == LoadType.Fatigue;
    }

    private get isAciCsa() {
        return this.design.designStandardC2C?.id == DesignStandardEnum.ACI || this.design.designStandardC2C?.id == DesignStandardEnum.CSA;
    }

    private get isExtensionsOrJointsHNA() {
        if (!this.isAciCsa) {
            return false;
        }

        return this.design.connectionType == ConnectionType.Splices || this.design.connectionType == ConnectionType.StructuralJoints;
    }

    private get isSustainedLoad() {
        if (!this.isAciCsa) {
            return false;
        }

        const anchorTheoryPropId = this.design.isC2COverlay
            ? PropertyMetaData.AnchorTheory_C2C_IsAnchorTheoryDisplayed.id
            : PropertyMetaData.Loads_C2C_IsAnchorTheoryDisplayed.id;

        return this.design.model[anchorTheoryPropId] as boolean ||
            this.design.connectionType == ConnectionType.StructuralJoints ||
            this.design.connectionType == ConnectionType.Splices;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        this.scrollElement = document.querySelector('.modal') as Element;

        const forceUnit = this.userSettings.settings.applicationImportLoads.forceUnit.value || this.unit.getDefaultUnit(UnitGroup.Force);
        const momentUnit = this.userSettings.settings.applicationImportLoads.momentUnit.value || this.unit.getDefaultUnit(UnitGroup.Moment);
        const stressUnit = this.userSettings.settings.applicationImportLoads.stressUnit.value ?? this.unit.getDefaultUnit(UnitGroup.Stress);
        this.loadingDefinitionType = this.design.model[PropertyMetaData.Loads_C2C_LoadingDefinitionType.id] as LoadingDefinitionType;

        // unit dropdowns
        this.forceUnitDropdown = this.createUnitDropdown('force-dropdown', 'ForceUnit', this.codeList.commonCodeLists[ProjectCodeList.UnitForce], forceUnit);
        this.momentUnitDropdown = this.createUnitDropdown('moment-dropdown', 'MomentUnit', this.codeList.commonCodeLists[ProjectCodeList.UnitMoment], momentUnit);
        this.stressUnitDropdown = this.createUnitDropdown('stress-dropdown', 'StressUnit', this.codeList.commonCodeLists[ProjectCodeList.UnitStress], stressUnit);

        // loads headers
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'one',
            dropdown: this.createNumberTypeDropdown('number-type-one', this.userSettings.settings.applicationImportLoads.numberTypeOne.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'two',
            dropdown: this.createNumberTypeDropdown('number-type-two', this.userSettings.settings.applicationImportLoads.numberTypeTwo.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'three',
            dropdown: this.createNumberTypeDropdown('number-type-three', this.userSettings.settings.applicationImportLoads.numberTypeThree.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'four',
            dropdown: this.createNumberTypeDropdown('number-type-four', this.userSettings.settings.applicationImportLoads.numberTypeFour.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'five',
            dropdown: this.createNumberTypeDropdown('number-type-five', this.userSettings.settings.applicationImportLoads.numberTypeFive.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'six',
            dropdown: this.createNumberTypeDropdown('number-type-six', this.userSettings.settings.applicationImportLoads.numberTypeSix.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'seven',
            dropdown: this.createNumberTypeDropdown('number-type-seven', this.userSettings.settings.applicationImportLoads.numberTypeSeven.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'eight',
            dropdown: this.createNumberTypeDropdown('number-type-eight', this.userSettings.settings.applicationImportLoads.numberTypeEight.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'nine',
            dropdown: this.createNumberTypeDropdown('number-type-nine', this.userSettings.settings.applicationImportLoads.numberTypeNine.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'ten',
            dropdown: this.createNumberTypeDropdown('number-type-ten', this.userSettings.settings.applicationImportLoads.numberTypeTen.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'eleven',
            dropdown: this.createNumberTypeDropdown('number-type-eleven', this.userSettings.settings.applicationImportLoads.numberTypeEleven.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twelve',
            dropdown: this.createNumberTypeDropdown('number-type-twelve', this.userSettings.settings.applicationImportLoads.numberTypeTwelve.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'thirteen',
            dropdown: this.createNumberTypeDropdown('number-type-thirteen', this.userSettings.settings.applicationImportLoads.numberTypeThirteen.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'fourteen',
            dropdown: this.createNumberTypeDropdown('number-type-fourteen', this.userSettings.settings.applicationImportLoads.numberTypeFourteen.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'fifteen',
            dropdown: this.createNumberTypeDropdown('number-type-fifteen', this.userSettings.settings.applicationImportLoads.numberTypeFifteen.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'sixteen',
            dropdown: this.createNumberTypeDropdown('number-type-sixteen', this.userSettings.settings.applicationImportLoads.numberTypeSixteen.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'seventeen',
            dropdown: this.createNumberTypeDropdown('number-type-seventeen', this.userSettings.settings.applicationImportLoads.numberTypeSeventeen.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'eighteen',
            dropdown: this.createNumberTypeDropdown('number-type-eighteen', this.userSettings.settings.applicationImportLoads.numberTypeEighteen.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'nineteen',
            dropdown: this.createNumberTypeDropdown('number-type-nineteen', this.userSettings.settings.applicationImportLoads.numberTypeNineteen.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twenty',
            dropdown: this.createNumberTypeDropdown('number-type-twenty', this.userSettings.settings.applicationImportLoads.numberTypeTwenty.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twentyone',
            dropdown: this.createNumberTypeDropdown('number-type-twentyone', this.userSettings.settings.applicationImportLoads.numberTypeTwentyone.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twentytwo',
            dropdown: this.createNumberTypeDropdown('number-type-twentytwo', this.userSettings.settings.applicationImportLoads.numberTypeTwentytwo.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twentythree',
            dropdown: this.createNumberTypeDropdown('number-type-twentythree', this.userSettings.settings.applicationImportLoads.numberTypeTwentythree.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twentyfour',
            dropdown: this.createNumberTypeDropdown('number-type-twentyfour', this.userSettings.settings.applicationImportLoads.numberTypeTwentyfour.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twentyfive',
            dropdown: this.createNumberTypeDropdown('number-type-twentyfive', this.userSettings.settings.applicationImportLoads.numberTypeTwentyfive.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twentysix',
            dropdown: this.createNumberTypeDropdown('number-type-twentysix', this.userSettings.settings.applicationImportLoads.numberTypeTwentysix.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twentyseven',
            dropdown: this.createNumberTypeDropdown('number-type-twentyseven', this.userSettings.settings.applicationImportLoads.numberTypeTwentyseven.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twentyeight',
            dropdown: this.createNumberTypeDropdown('number-type-twentyeight', this.userSettings.settings.applicationImportLoads.numberTypeTwentyeight.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'twentynine',
            dropdown: this.createNumberTypeDropdown('number-type-twentynine', this.userSettings.settings.applicationImportLoads.numberTypeTwentynine.value as ImportLoadsNumberType)
        });

        this.loadTypeToggleItems = [];
        if (this.helper.isLoadTypeAllowed(InternalLoadType.Static)) {
            this.loadTypeToggleItems.push({
                value: InternalLoadType.Static,
                image: getSpriteAsIconStyle('sprite-anchor-shock'),
                class: 'sprite-anchor-shock',
                tooltip: {
                    title: this.translate('Agito.Hilti.C2C.LoadType.Static.Tooltip.Title'),
                    content: this.translate('Agito.Hilti.C2C.LoadType.Static.Tooltip')
                }
            });
        }
        if (this.helper.isLoadTypeAllowed(InternalLoadType.Seismic)) {
            this.loadTypeToggleItems.push({
                value: InternalLoadType.Seismic,
                image: getSpriteAsIconStyle('sprite-anchor-seismic'),
                class: 'sprite-anchor-seismic',
                tooltip: {
                    title: this.translate('Agito.Hilti.C2C.LoadType.Seismic.Tooltip.Title'),
                    content: this.translate('Agito.Hilti.C2C.LoadType.Seismic.Tooltip')
                }
            });
        }
        if (this.helper.isLoadTypeAllowed(InternalLoadType.Fatigue)) {
            this.loadTypeToggleItems.push({
                value: InternalLoadType.Fatigue,
                image: getSpriteAsIconStyle('sprite-anchor-fatigue'),
                class: 'sprite-anchor-fatigue',
                tooltip: {
                    title: this.translate('Agito.Hilti.C2C.LoadType.Fatigue.Tooltip.Title'),
                    content: this.translate('Agito.Hilti.C2C.LoadType.Fatigue.Tooltip')
                }
            });
        }
        if (this.helper.isLoadTypeAllowed(InternalLoadType.Fire)) {
            this.loadTypeToggleItems.push({
                value: InternalLoadType.Fire,
                image: getSpriteAsIconStyle('sprite-anchor-fire-resistant'),
                class: 'sprite-anchor-fire-resistant',
                tooltip: {
                    title: this.translate('Agito.Hilti.C2C.LoadType.Fire.Tooltip.Title'),
                    content: this.translate('Agito.Hilti.C2C.LoadType.Fire.Tooltip')
                }
            });
        }

        setTimeout(() => {
            const element = this.elementRef.nativeElement.shadowRoot?.getElementById('import-loads-dialog') as HTMLInputElement;
            this.copyPasteInput = element.querySelector('.copy-paste-input') as HTMLInputElement;
            this.copyPasteInput.focus();
        });
    }

    public close() {
        this.modalInstance.close();
    }

    public cancel() {
        this.close();
    }

    public onImportPaste() {
        this.isPasted = true;
    }

    public get maxLoads() {
        return this.helper.maxLoads(environment.maxLoadCombinationsPirEuC2C, environment.maxLoadCombinationsC2C);
    }

    public onImportChange() {
        if (this.isPasted) {
            const maxLoads = Math.max(0, this.maxLoads - (this.design.loadCombinationsC2C == null ? 0 : this.design.loadCombinationsC2C.length));
            const parsed = this.parsePastedData(this.importData as string, maxLoads);

            this.limitReached = parsed.limitReached;
            this.importData = null;
            this.importedLoads = parsed.loads;
            this.view = ImportLoadsView.import;
        }
        else {
            this.importData = null;
        }
    }

    public onImportKeyDown(event: KeyboardEvent) {
        // prevent tab navigation
        if (event.key == keyTab) {
            event.preventDefault();
        }
    }

    public onImportBlur() {
        this.copyPasteInput.focus();
    }

    public virtualScrollChange() {
        // If input element had focus we need to blur it on virtual scroll change
        // (viewport end/start index changed)
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement != null && activeElement.tagName.toLowerCase() === 'input') {
            activeElement.blur();
        }
    }

    public identifyField: TrackByFunction<ILoadsDropdownHeader> = (_: number, field: ILoadsDropdownHeader) => field.index;

    // Returning index (inside virtual scroll's viewport) to ensure the same components are being reused (performance improvement!).
    public identifyLoad: TrackByFunction<LoadCombination> = (index: number) => index;

    public getLoadValue(load: LoadCombination, numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;
        return this.getLoadNumber(load, numberType);
    }

    public updateLoadValue(value: number, load: LoadCombination, numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;
        if (value != null && !Number.isNaN(value)) {
            this.setLoadNumber(load, numberType, value);
        }
    }

    public isColumnVisible(numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as number;
        return this.isLoadVisible(numberType);
    }

    public isLoadVisible(numberType: number) {
        return this.columnVisible(numberType);
    }

    public saveLoadCombinations(form: NgForm) {
        return this.save(form);
    }

    public validateNumberType(numberType: ImportLoadsNumberType, allowedTypeList: ImportLoadsNumberType[], defNumberType: ImportLoadsNumberType) {
        return allowedTypeList.some(zone => zone === numberType) ? numberType : defNumberType;
    }

    public numberTypeDropdownSelectedValueChange(selectedValue: ImportLoadsNumberType, dropdown: DropdownProps<ImportLoadsNumberType>) {
        const oldSelectedValue = dropdown.selectedValue as number;
        dropdown.selectedValue = selectedValue;

        // swap numbers in load
        for (const load of this.importedLoads || []) {
            const loadNumber = this.getLoadNumber(load, oldSelectedValue);

            this.setLoadNumber(load, oldSelectedValue, this.getLoadNumber(load, selectedValue));
            this.setLoadNumber(load, selectedValue, loadNumber);
        }

        // swap dropdown values
        for (const header of this.loadsDropdownHeaders) {
            if (header.dropdown !== dropdown && this.isSameNumberType(header.dropdown.selectedValue as number, selectedValue as number)) {
                header.dropdown.selectedValue = oldSelectedValue - oldSelectedValue % 2;
            }
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public isLoadPropertyDisabled(numberValue: number, activeLoadType: number, load: LoadCombination): boolean {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;

        if (this.design.isPirHna && activeLoadType == LoadType.Fire) {
            switch (numberType) {
                case ImportLoadsNumberType.ForceX:
                case ImportLoadsNumberType.ForceY:
                case ImportLoadsNumberType.ForceXMinus:
                case ImportLoadsNumberType.ForceYMinus:
                    this.setLoadNumber(load, numberType, 0);
                    return true;
                default:
                    return this.submitted;
            }
        }

        return this.submitted;
    }

    private setLoads(load: LoadCombination, unitFrom: Unit, unitTo: Unit) {
        load.zone1 = this.unit.convertUnitValueArgsToUnit(load.zone1, unitFrom, unitTo);
        load.zone2 = this.unit.convertUnitValueArgsToUnit(load.zone2, unitFrom, unitTo);
        load.zone3 = this.unit.convertUnitValueArgsToUnit(load.zone3, unitFrom, unitTo);
        load.zone4 = this.unit.convertUnitValueArgsToUnit(load.zone4, unitFrom, unitTo);
        load.zoneGeneric = this.unit.convertUnitValueArgsToUnit(load.zoneGeneric, unitFrom, unitTo);
        if (this.isOverlayFatigue) {
            load.dynamicZone1 = this.unit.convertUnitValueArgsToUnit(load.dynamicZone1, unitFrom, unitTo);
            load.dynamicZone2 = this.unit.convertUnitValueArgsToUnit(load.dynamicZone2, unitFrom, unitTo);
            load.dynamicZone3 = this.unit.convertUnitValueArgsToUnit(load.dynamicZone3, unitFrom, unitTo);
            load.dynamicZone4 = this.unit.convertUnitValueArgsToUnit(load.dynamicZone4, unitFrom, unitTo);
            load.dynamicZoneGeneric = this.unit.convertUnitValueArgsToUnit(load.dynamicZoneGeneric, unitFrom, unitTo);
        }
    }

    private columnVisibleForGenericReinforcement(numberType: number) {
        if (this.isAciCsa) {
            if (this.loadingDefinitionType == LoadingDefinitionType.ShearStress) {
                switch (numberType) {
                    case ImportLoadsNumberType.TedX:
                    case ImportLoadsNumberType.TedXMinus:
                    case ImportLoadsNumberType.TedY:
                    case ImportLoadsNumberType.TedYMinus:
                        return true;
                    default:
                        return false;
                }
            }
            else {
                switch (numberType) {
                    case ImportLoadsNumberType.ForceX:
                    case ImportLoadsNumberType.ForceXMinus:
                    case ImportLoadsNumberType.ForceY:
                    case ImportLoadsNumberType.ForceYMinus:
                        return true;
                    default:
                        return false;
                }
            }
        }
        else {
            switch (numberType) {
                case ImportLoadsNumberType.ZoneGenericStatic:
                case ImportLoadsNumberType.ZoneGenericMinusStatic:
                case ImportLoadsNumberType.ZoneGenericDynamic:
                case ImportLoadsNumberType.ZoneGenericMinusDynamic:
                    return this.isOverlayFatigue;
                case ImportLoadsNumberType.ZoneGeneric:
                case ImportLoadsNumberType.ZoneGenericMinus:
                    return !this.isOverlayFatigue;
                default:
                    return false;
            }
        }
    }

    private columnVisiblePirEuDefault(numberType: number) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
                return this.design.applicationType != ApplicationType.BeamToBeam && this.design.applicationType != ApplicationType.BeamToSlab && this.design.applicationType != ApplicationType.BeamToWall && this.design.applicationType != ApplicationType.BeamToColumn;
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
                return true;
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
                return this.design.applicationType == ApplicationType.ColumnExtension || this.design.applicationType == ApplicationType.ColumnToWall || this.design.applicationType == ApplicationType.ColumnOnSlab;
            default:
                return false;
        }
    }

    private columnVisibleDefault(numberType: number) {
        if (this.design.isPirEuOrAus) {
            return this.columnVisiblePirEuDefault(numberType);
        }

        if (this.isExtensionsOrJointsHNA) {
            switch (numberType) {
                case ImportLoadsNumberType.ForceX:
                case ImportLoadsNumberType.ForceXMinus:
                case ImportLoadsNumberType.ForceY:
                case ImportLoadsNumberType.ForceYMinus:
                case ImportLoadsNumberType.ForceZ:
                case ImportLoadsNumberType.ForceZMinus:
                case ImportLoadsNumberType.MomentX:
                case ImportLoadsNumberType.MomentXMinus:
                case ImportLoadsNumberType.ForceZSustained:
                case ImportLoadsNumberType.ForceZSustainedMinus:
                case ImportLoadsNumberType.MomentXSustained:
                case ImportLoadsNumberType.MomentXSustainedMinus:
                    return true;
                default:
                    return false;
            }
        }

        if (this.isAciCsa) {
            switch (numberType) {
                case ImportLoadsNumberType.ForceX:
                case ImportLoadsNumberType.ForceXMinus:
                case ImportLoadsNumberType.ForceY:
                case ImportLoadsNumberType.ForceYMinus:
                case ImportLoadsNumberType.ForceZ:
                case ImportLoadsNumberType.ForceZMinus:
                case ImportLoadsNumberType.MomentY:
                case ImportLoadsNumberType.MomentYMinus:
                    return true;
                default:
                    if (this.isSustainedLoad) {
                        switch (numberType) {
                            case ImportLoadsNumberType.ForceZSustained:
                            case ImportLoadsNumberType.ForceZSustainedMinus:
                            case ImportLoadsNumberType.MomentYSustained:
                            case ImportLoadsNumberType.MomentYSustainedMinus:
                                return true;
                        }
                        return false;
                    }
            }
        }

        return false;
    }

    private overlayFatigueColumnVisible(numberType: number, isOverlayFatigue: boolean, overlayFatigueZones: ImportLoadsNumberType[], overlayNoFatigueZones: ImportLoadsNumberType[]) {
        return (isOverlayFatigue && (overlayFatigueZones.indexOf(numberType) > -1)) || (!isOverlayFatigue && (overlayNoFatigueZones.indexOf(numberType) > -1));
    }

    private columnVisibleConcreteOverlay(numberType: number) {
        if (this.design.applicationType == ApplicationType.GenericReinforcement) {
            return this.columnVisibleForGenericReinforcement(numberType);
        }

        const zoneNumber = this.design.model[PropertyMetaData.Overlay_C2C_Zones_NumberOfZones.id] as number;
        if (zoneNumber >= 1) {
            if (this.overlayFatigueColumnVisible(numberType, this.isOverlayFatigue, [ImportLoadsNumberType.Zone1Static, ImportLoadsNumberType.Zone1MinusStatic, ImportLoadsNumberType.Zone1Dynamic, ImportLoadsNumberType.Zone1MinusDynamic], [ImportLoadsNumberType.Zone1, ImportLoadsNumberType.Zone1Minus])) {
                return true;
            }
        }
        if (zoneNumber >= 2) {
            if (this.overlayFatigueColumnVisible(numberType, this.isOverlayFatigue, [ImportLoadsNumberType.Zone2Static, ImportLoadsNumberType.Zone2MinusStatic, ImportLoadsNumberType.Zone2Dynamic, ImportLoadsNumberType.Zone2MinusDynamic], [ImportLoadsNumberType.Zone2, ImportLoadsNumberType.Zone2Minus])) {
                return true;
            }
        }
        if (zoneNumber >= 3) {
            if (this.overlayFatigueColumnVisible(numberType, this.isOverlayFatigue, [ImportLoadsNumberType.Zone3Static, ImportLoadsNumberType.Zone3MinusStatic, ImportLoadsNumberType.Zone3Dynamic, ImportLoadsNumberType.Zone3MinusDynamic], [ImportLoadsNumberType.Zone3, ImportLoadsNumberType.Zone3Minus])) {
                return true;
            }
        }
        if (zoneNumber >= 4) {
            if (this.overlayFatigueColumnVisible(numberType, this.isOverlayFatigue, [ImportLoadsNumberType.Zone4Static, ImportLoadsNumberType.Zone4MinusStatic, ImportLoadsNumberType.Zone4Dynamic, ImportLoadsNumberType.Zone4MinusDynamic], [ImportLoadsNumberType.Zone4, ImportLoadsNumberType.Zone4Minus])) {
                return true;
            }
        }

        return false;
    }

    private columnVisible(numberType: number) {
        if (this.design.connectionType == ConnectionType.ConcreteOverlay) {
            return this.columnVisibleConcreteOverlay(numberType);
        }

        switch (numberType) {
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
                return true;
            default:
                return this.columnVisibleDefault(numberType);
        }
    }

    /*
     * Parse pasted loads from excel to array. This parser is needed  because cells in excel can be multiple line and need to be parsed correctly
     * Special lines:
     * "``~~??''^^{}[]<>¸¸¸°°˘˘ˇˇ^^ˇ\n dasdasdas"	0	1	3	4	5	2,
     * Combination 12	0	13	"27\n 11"	4	5	2
     */
    private CSVToArray(csvString: string) {
        const rows: string[] = [];
        const newLInes = csvString.split('\n').length;
        let line = '';
        for (let i = 0; i <= newLInes - 1; i++) {
            line += (i == newLInes - 1) ? csvString : csvString.substring(0, csvString.indexOf('\n') + 1);
            csvString = csvString.slice(csvString.indexOf('\n') + 1, csvString.length);

            /*
             *If line starts with a quote or '-->"' (\t") exist in line, it means that any of the cell is multiple line.
             *There are some issues like: if there is a quote(") in cell or row ends with ("\n), ... In excel is the same issue.
             */
            if ((i < newLInes - 1) && (line[0] === '"' || line.indexOf('\t"') != -1)) {
                // Continiue reading line if \t" exist in line and count of \t" is not same as count of "\t
                if ((line.indexOf('\t"') != -1 && (line.match(/\t"/g) || []).length != (line.match(/"\t/g) || []).length)) {
                    continue;
                }
                // save line in array if \t exist in line or line has even number of quotes.
                else if ((line.indexOf('\t') != -1 || (line.match(/"/g)?.length ?? 0) == 0)) {
                    if (line[0] == '"' && line[1] != '\n' && line.indexOf('\n') < line.length - 1) {// specil case for remove extra quote
                        line = line.substr(1).replace('"\t', '\t');
                    }
                    // remove all extra euotes and replace \n with space
                    /* eslint-disable-next-line no-useless-escape */
                    rows.push(line.replace(/\"\n/g, '').replace(/\n\"/g, '').replace(/\n/g, ' ').replace(/\"\"/g, '"'));
                    line = '';
                }
            }
            // Save normal line in array (Lines: Comb11  0	12	25	4	5	2, Comb11    0	12	"25 4	5!	2, ...),
            else {
                if (line.indexOf('\n') != -1 && line[0] == '"') {// specil case for remove extra quote
                    /* eslint-disable-next-line no-useless-escape */
                    line = line.substr(1).replace(/\n\"/g, '').replace('"\t', '\t');
                }
                // remove all extra euotes and replace \n with space
                if (line.trim() != '') {
                    /* eslint-disable-next-line no-useless-escape */
                    rows.push(line.replace(/\"\n/g, '').replace(/\n\"/g, '').replace(/\n/g, ' ').replace(/\"\"/g, '"'));
                }
                line = '';
            }
        }
        return rows; // Return the parsed data Array
    }

    private parsePastedData(data: string, maxLoads: number): IParseResponse {
        const loads: LoadCombination[] = [];

        if (!data) {
            return { loads, limitReached: false };
        }

        // normalize new lines
        data = replace(data, '\r\n', '\n');
        const rows = this.CSVToArray(data);

        let limitReached = false;
        for (const row of rows) {
            // max loads
            if (loads.length >= maxLoads) {
                limitReached = true;
                break;
            }

            const load = this.parseRow(row);
            if (load) {
                loads.push(load);
            }
        }

        return { loads, limitReached };
    }

    private parseRow(row: string): LoadCombination | null {
        const columns = row.split('\t');
        const hasName = columns.length >= 7 || this.getNumber(columns, 0) == null;
        const columnOffset = hasName ? 1 : 0;
        const name = hasName ? columns[0].trim() : '';
        const activeLoadType = this.isOverlayFatigue ? LoadType.Fatigue : LoadType.Static;

        let load: Partial<LoadCombination> = {
            id: this.guid.new(),
            activeLoadType: (activeLoadType !== undefined && activeLoadType >= 1 && activeLoadType <= 4) ? activeLoadType : LoadType.Static,
            name,
            description: null as unknown as string,
            tedX: 0,
            tedY: 0,
            forceX: 0,
            forceY: 0,
            forceZ: 0,
            bottomForceZ: 0,
            sustainedForceZ: 0,
            momentX: 0,
            momentY: 0,
            momentZ: 0,
            sustainedMomentX: 0,
            sustainedMomentY: 0,
            zone1: 0,
            zone2: 0,
            zone3: 0,
            zone4: 0,
            zoneGeneric: 0,
            dynamicZone1: 0,
            dynamicZone2: 0,
            dynamicZone3: 0,
            dynamicZone4: 0,
            dynamicZoneGeneric: 0,
            tension: null as unknown as ShearUtilizationValueEntityC2C,
            shear: null as unknown as ShearUtilizationValueEntityC2C,
            combination: null as unknown as UtilizationValueEntityC2C,
            maxUtilization: null as unknown as ShearUtilizationValueEntityC2C,
            resultMessages: [],
            isWizardGenerated: false,
            hasSustainedLoads: false,
            hasScopeChecksError: false,
            hasPostCalculationScopeChecksError: false,
            hasPreCalculationScopeChecksError: false
        };

        switch (this.design.connectionType) {
            case ConnectionType.ConcreteOverlay:
                load = this.parseConcreteOverlayLoad(columns, columnOffset, load);
                break;
            default:
                load = this.parsePirLoad(columns, columnOffset, load);
                break;
        }

        if (this.isLoadValid(load)) {
            return load as LoadCombination;
        } else {
            return null;
        }
    }

    private parseConcreteOverlayLoad(columns: string[], columnOffset: number, load: Partial<LoadCombination>): Partial<LoadCombination> {
        if (this.design.applicationType == ApplicationType.GenericReinforcement) {
            load = this.parseGenericReinforcement(columns, columnOffset, load);
        } else {
            load = this.parseNonGenericReinforcement(columns, columnOffset, load);
        }

        return load;
    }

    private parseGenericReinforcement(columns: string[], columnOffset: number, load: Partial<LoadCombination>): Partial<LoadCombination> {
        if (this.isAciCsa) {
            load = this.parseOverlayAciCsa(columns, columnOffset, load);
        } else {
            load.zoneGeneric = this.getNumber(columns, 0 + columnOffset) ?? 0;
            if (this.isOverlayFatigue) {
                load.dynamicZoneGeneric = this.getNumber(columns, 1 + columnOffset) ?? 0;
            }
        }

        return load;
    }

    private parseOverlayAciCsa(columns: string[], columnOffset: number, load: Partial<LoadCombination>): Partial<LoadCombination> {
        if (this.loadingDefinitionType == LoadingDefinitionType.ShearStress) {
            load.tedX = this.getNumber(columns, 0 + columnOffset) ?? 0;
            load.tedY = this.getNumber(columns, 1 + columnOffset) ?? 0;
        } else {
            load.forceX = this.getNumber(columns, 0 + columnOffset) ?? 0;
            load.forceY = this.getNumber(columns, 1 + columnOffset) ?? 0;
        }

        return load;
    }

    private parseNonGenericReinforcement(columns: string[], columnOffset: number, load: Partial<LoadCombination>): Partial<LoadCombination> {
        const zoneNumber = this.design.model[PropertyMetaData.Overlay_C2C_Zones_NumberOfZones.id] as number;
        let currentOffset = 0;

        for (let i = 1; i <= zoneNumber; i++) {
            (load as any)[`zone${i}`] = this.getNumber(columns, currentOffset++ + columnOffset) ?? 0;
            if (this.isOverlayFatigue) {
                (load as any)[`dynamicZone${i}`] = this.getNumber(columns, currentOffset++ + columnOffset) ?? 0;
            }
        }

        return load;
    }

    private parsePirLoad(columns: string[], columnOffset: number, load: Partial<LoadCombination>): Partial<LoadCombination> {
        if (this.isExtensionsOrJointsHNA) {
            this.parseExtensionsOrJointsHNA(columns, columnOffset, load);
        } else if (this.design.isPirEuOrAus) {
            this.parsePirEuOrAus(columns, columnOffset, load);
        } else if (this.isAciCsa) {
            this.parsePirAciCsa(columns, columnOffset, load);
        } else {
            this.parsePirDefault(columns, columnOffset, load);
        }

        return load;
    }

    private parseExtensionsOrJointsHNA(columns: string[], columnOffset: number, load: Partial<LoadCombination>) {
        load.forceX = this.getNumber(columns, 0 + columnOffset) ?? 0;
        load.forceY = this.getNumber(columns, 1 + columnOffset) ?? 0;
        load.forceZ = this.getNumber(columns, 2 + columnOffset) ?? 0;
        load.momentX = this.getNumber(columns, 3 + columnOffset) ?? 0;
        if (this.isSustainedLoad) {
            load.sustainedForceZ = this.getNumber(columns, 4 + columnOffset) ?? 0;
            load.sustainedMomentX = this.getNumber(columns, 5 + columnOffset) ?? 0;
        }
    }

    private parsePirEuOrAus(columns: string[], columnOffset: number, load: Partial<LoadCombination>) {
        let currentOffset = 0;
        if (this.columnVisiblePirEuDefault(ImportLoadsNumberType.ForceX)) {
            load.forceX = this.getNumber(columns, currentOffset++ + columnOffset) ?? 0;
        }
        load.forceY = this.getNumber(columns, currentOffset++ + columnOffset) ?? 0;
        load.forceZ = this.getNumber(columns, currentOffset++ + columnOffset) ?? 0;
        load.momentX = this.getNumber(columns, currentOffset++ + columnOffset) ?? 0;
        if (this.columnVisiblePirEuDefault(ImportLoadsNumberType.MomentY)) {
            load.momentY = this.getNumber(columns, currentOffset + columnOffset) ?? 0;
        }
    }

    private parsePirAciCsa(columns: string[], columnOffset: number, load: Partial<LoadCombination>) {
        load.forceX = this.getNumber(columns, 0 + columnOffset) ?? 0;
        load.forceY = this.getNumber(columns, 1 + columnOffset) ?? 0;
        load.forceZ = this.getNumber(columns, 2 + columnOffset) ?? 0;
        load.momentY = this.getNumber(columns, 3 + columnOffset) ?? 0;

        if (this.isSustainedLoad) {
            load.sustainedForceZ = this.getNumber(columns, 4 + columnOffset) ?? 0;
            load.sustainedMomentX = this.getNumber(columns, 5 + columnOffset) ?? 0;
        }
    }

    private parsePirDefault(columns: string[], columnOffset: number, load: Partial<LoadCombination>) {
        load.forceX = this.getNumber(columns, 0 + columnOffset) ?? 0;
        load.forceZ = this.getNumber(columns, 1 + columnOffset) ?? 0;
        load.momentY = this.getNumber(columns, 2 + columnOffset) ?? 0;
    }

    private isLoadValid(load: Partial<LoadCombination>): boolean {
        return load.name != '' || load.forceX != null || load.forceY != null || load.forceZ != null || load.momentX != null || load.momentY != null || load.momentZ != null;
    }

    private getNumber(columns: string[], index: number) {
        if (index < columns.length) {
            const value = columns[index];
            const num = this.numberService.parse(value, NumberType.real);

            if (num != null && !Number.isNaN(num)) {
                return num;
            }
        }

        return null;
    }

    private createUnitDropdown(id: string, titleKey: string, codeList: UnitCodeListItem[], defaultUnit: Unit) {
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const dropdown: DropdownProps<Unit> = {
            id: 'import-loads-' + id,
            title: this.translate(`Agito.Hilti.Profis3.ImportLoads.${titleKey}`),
            items: codeList.map((unit) => {
                return {
                    id: `import-loads-${id}-${unit.id}`,
                    value: unit.id,
                    text: unit.getTranslatedNameText(codeListDeps)
                } as DropdownItem<Unit>;
            }),
            selectedValue: defaultUnit
        };

        return dropdown;
    }

    private getConcreteOverlayDropdownItems(zoneNumber: number, dropdown: DropdownProps<ImportLoadsNumberType>) {
        const zoneDropdownItems = [];

        if (zoneNumber >= 1) {
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone1));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone1Minus));
        }
        if (zoneNumber >= 2) {
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone2));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone2Minus));
        }
        if (zoneNumber >= 3) {
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone3));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone3Minus));
        }
        if (zoneNumber >= 4) {
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone4));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone4Minus));
        }
        return zoneDropdownItems;
    }

    private getFatigueConcreteOverlayDropdownItems(zoneNumber: number, dropdown: DropdownProps<ImportLoadsNumberType>) {
        const zoneDropdownItems = [];

        if (zoneNumber >= 1) {
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone1Static));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone1MinusStatic));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone1Dynamic));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone1MinusDynamic));
        }
        if (zoneNumber >= 2) {
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone2Static));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone2MinusStatic));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone2Dynamic));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone2MinusDynamic));
        }
        if (zoneNumber >= 3) {
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone3Static));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone3MinusStatic));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone3Dynamic));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone3MinusDynamic));
        }
        if (zoneNumber >= 4) {
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone4Static));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone4MinusStatic));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone4Dynamic));
            zoneDropdownItems.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.Zone4MinusDynamic));
        }
        return zoneDropdownItems;
    }

    private getNumberTypeDropdownItemsConcreteOverlay(dropdown: DropdownProps<ImportLoadsNumberType>) {
        if (this.isAciCsa) {
            if (this.loadingDefinitionType == LoadingDefinitionType.ShearStress) {
                return [
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.TedX),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.TedXMinus),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.TedY),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.TedYMinus)
                ];
            } else {
                return [
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceX),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceXMinus),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceY),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceYMinus)
                ];
            }
        }

        if (this.design.applicationType == ApplicationType.GenericReinforcement) {
            if (this.isOverlayFatigue) {
                return [
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ZoneGenericStatic),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ZoneGenericMinusStatic),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ZoneGenericDynamic),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ZoneGenericMinusDynamic)
                ];
            }
            return [
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ZoneGeneric),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ZoneGenericMinus)
            ];
        }
        else {
            const zoneNumber = this.design.model[PropertyMetaData.Overlay_C2C_Zones_NumberOfZones.id] as number;
            return this.isOverlayFatigue ?
                this.getFatigueConcreteOverlayDropdownItems(zoneNumber, dropdown) :
                this.getConcreteOverlayDropdownItems(zoneNumber, dropdown);
        }
    }

    private getNumberTypeDropdownItemsPirEu(dropdown: DropdownProps<ImportLoadsNumberType>) {
        let forceXItems = [];
        let momentYItems = [];
        if (this.columnVisiblePirEuDefault(ImportLoadsNumberType.ForceX)) {
            forceXItems = [
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceX),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceXMinus)
            ] as any[];
        }

        if (this.columnVisiblePirEuDefault(ImportLoadsNumberType.MomentY)) {
            momentYItems = [
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentY),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYMinus)
            ] as any[];
        }

        const items = [
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceY),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceYMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZ),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentX),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXMinus)
        ] as any[];

        return forceXItems.concat(items).concat(momentYItems);
    }

    private getNumberTypeDropdownItems(dropdown: DropdownProps<ImportLoadsNumberType>) {
        switch (this.design.connectionType) {
            case ConnectionType.Splices:
            case ConnectionType.StructuralJoints:
                if (this.isAciCsa) {
                    return [
                        this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceX),
                        this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceXMinus),
                        this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceY),
                        this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceYMinus),
                        this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZ),
                        this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZMinus),
                        this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentX),
                        this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXMinus),
                    ];
                } else if (this.design.isPirEuOrAus) {
                    return this.getNumberTypeDropdownItemsPirEu(dropdown);
                }

                return [
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceX),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceXMinus),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZ),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZMinus),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentX),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXMinus),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentY),
                    this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYMinus)
                ];

            case ConnectionType.ConcreteOverlay:
                return this.getNumberTypeDropdownItemsConcreteOverlay(dropdown);

            default:
                return [];
        }
    }

    private getPirSustainedDropdownItems(dropdown: DropdownProps<ImportLoadsNumberType>) {
        const items: DropdownItem<ImportLoadsNumberType>[] = [];

        if (this.design.connectionType != ConnectionType.ConcreteOverlay) {
            items.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZSustained));
            items.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZSustainedMinus));
            items.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXSustained));
            items.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXSustainedMinus));

            if (this.design.designStandardC2C?.id == DesignStandardEnum.ETAG) {
                items.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYSustained));
                items.push(this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYSustainedMinus));
            }
        }

        return items;
    }

    private createNumberTypeDropdown(id: string, selected: ImportLoadsNumberType) {
        const dropdown: DropdownProps<ImportLoadsNumberType> = {
            id: 'import-loads-' + id,
            selectedValue: selected
        };

        dropdown.items = this.getNumberTypeDropdownItems(dropdown);

        if (this.isSustainedLoad) {
            dropdown.items = [
                ...dropdown.items,
                ...this.getPirSustainedDropdownItems(dropdown)
            ];
        }

        return dropdown;
    }

    private createNumberDropdownItem(dropdown: DropdownProps<ImportLoadsNumberType>, numberType: ImportLoadsNumberType) {
        return {
            id: `import-loads-${dropdown.id}-${numberType}`,
            value: numberType,
            text: this.getNumberTypeString(numberType),
        } as DropdownItem<ImportLoadsNumberType>;
    }

    private getNumberTypeString(numberType: ImportLoadsNumberType) {
        let key = 'Agito.Hilti.Profis3.ImportLoads.';
        const keyC2C = 'Agito.Hilti.C2C.ImportLoads.';
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
                key += 'VxPlus';
                break;

            case ImportLoadsNumberType.ForceXMinus:
                key += 'VxMinus';
                break;

            case ImportLoadsNumberType.ForceY:
                key += 'VyPlus';
                break;

            case ImportLoadsNumberType.ForceYMinus:
                key += 'VyMinus';
                break;

            case ImportLoadsNumberType.ForceZ:
                key += 'NPlus';
                break;

            case ImportLoadsNumberType.ForceZMinus:
                key += 'NMinus';
                break;

            case ImportLoadsNumberType.MomentX:
                key += 'MxPlus';
                break;

            case ImportLoadsNumberType.MomentXMinus:
                key += 'MxMinus';
                break;

            case ImportLoadsNumberType.MomentY:
                key += 'MyPlus';
                break;

            case ImportLoadsNumberType.MomentYMinus:
                key += 'MyMinus';
                break;

            case ImportLoadsNumberType.MomentZ:
                key += 'MzPlus';
                break;

            case ImportLoadsNumberType.MomentZMinus:
                key += 'MzMinus';
                break;

            case ImportLoadsNumberType.ForceZSustained:
                key += 'NSustainedPlus';
                break;

            case ImportLoadsNumberType.ForceZSustainedMinus:
                key += 'NSustainedMinus';
                break;

            case ImportLoadsNumberType.MomentXSustained:
                key += 'MxSustainedPlus';
                break;

            case ImportLoadsNumberType.MomentXSustainedMinus:
                key += 'MxSustainedMinus';
                break;

            case ImportLoadsNumberType.MomentYSustained:
                key += 'MySustainedPlus';
                break;

            case ImportLoadsNumberType.MomentYSustainedMinus:
                key += 'MySustainedMinus';
                break;

            case ImportLoadsNumberType.Zone1:
                key = keyC2C + 'Zone1Plus';
                break;
            case ImportLoadsNumberType.Zone1Minus:
                key = keyC2C + 'Zone1Minus';
                break;

            case ImportLoadsNumberType.Zone2:
                key = keyC2C + 'Zone2Plus';
                break;
            case ImportLoadsNumberType.Zone2Minus:
                key = keyC2C + 'Zone2Minus';
                break;

            case ImportLoadsNumberType.Zone3:
                key = keyC2C + 'Zone3Plus';
                break;
            case ImportLoadsNumberType.Zone3Minus:
                key = keyC2C + 'Zone3Minus';
                break;

            case ImportLoadsNumberType.Zone4:
                key = keyC2C + 'Zone4Plus';
                break;
            case ImportLoadsNumberType.Zone4Minus:
                key = keyC2C + 'Zone4Minus';
                break;

            case ImportLoadsNumberType.ZoneGeneric:
                key = keyC2C + 'ZoneGenericPlus';
                break;
            case ImportLoadsNumberType.ZoneGenericMinus:
                key = keyC2C + 'ZoneGenericMinus';
                break;

            case ImportLoadsNumberType.Zone1Static:
                key = keyC2C + 'Zone1PlusStatic';
                break;
            case ImportLoadsNumberType.Zone1MinusStatic:
                key = keyC2C + 'Zone1MinusStatic';
                break;
            case ImportLoadsNumberType.Zone2Static:
                key = keyC2C + 'Zone2PlusStatic';
                break;
            case ImportLoadsNumberType.Zone2MinusStatic:
                key = keyC2C + 'Zone2MinusStatic';
                break;
            case ImportLoadsNumberType.Zone3Static:
                key = keyC2C + 'Zone3PlusStatic';
                break;
            case ImportLoadsNumberType.Zone3MinusStatic:
                key = keyC2C + 'Zone3MinusStatic';
                break;
            case ImportLoadsNumberType.Zone4Static:
                key = keyC2C + 'Zone4PlusStatic';
                break;
            case ImportLoadsNumberType.Zone4MinusStatic:
                key = keyC2C + 'Zone4MinusStatic';
                break;
            case ImportLoadsNumberType.ZoneGenericStatic:
                key = keyC2C + 'ZoneGenericPlusStatic';
                break;
            case ImportLoadsNumberType.ZoneGenericMinusStatic:
                key = keyC2C + 'ZoneGenericMinusStatic';
                break;
            case ImportLoadsNumberType.Zone1Dynamic:
                key = keyC2C + 'Zone1PlusDynamic';
                break;
            case ImportLoadsNumberType.Zone1MinusDynamic:
                key = keyC2C + 'Zone1MinusDynamic';
                break;
            case ImportLoadsNumberType.Zone2Dynamic:
                key = keyC2C + 'Zone2PlusDynamic';
                break;
            case ImportLoadsNumberType.Zone2MinusDynamic:
                key = keyC2C + 'Zone2MinusDynamic';
                break;
            case ImportLoadsNumberType.Zone3Dynamic:
                key = keyC2C + 'Zone3PlusDynamic';
                break;
            case ImportLoadsNumberType.Zone3MinusDynamic:
                key = keyC2C + 'Zone3MinusDynamic';
                break;
            case ImportLoadsNumberType.Zone4Dynamic:
                key = keyC2C + 'Zone4PlusDynamic';
                break;
            case ImportLoadsNumberType.Zone4MinusDynamic:
                key = keyC2C + 'Zone4MinusDynamic';
                break;
            case ImportLoadsNumberType.ZoneGenericDynamic:
                key = keyC2C + 'ZoneGenericPlusDynamic';
                break;
            case ImportLoadsNumberType.ZoneGenericMinusDynamic:
                key = keyC2C + 'ZoneGenericMinusDynamic';
                break;
            case ImportLoadsNumberType.TedX:
                key = keyC2C + 'TedXPlus';
                break;
            case ImportLoadsNumberType.TedXMinus:
                key = keyC2C + 'TedXYMinus';
                break;
            case ImportLoadsNumberType.TedY:
                key = keyC2C + 'TedYPlus';
                break;
            case ImportLoadsNumberType.TedYMinus:
                key = keyC2C + 'TedYMinus';
                break;

            default:
                throw new Error('Unknown number type.');
        }

        return this.translate(key);
    }

    private getLoadNumber(load: LoadCombination, numberType: ImportLoadsNumberType) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
                return load.forceX;
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
                return load.forceY;
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
                return load.forceZ;
            case ImportLoadsNumberType.BottomForceZ:
            case ImportLoadsNumberType.BottomForceZMinus:
                return load.bottomForceZ;
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
                return load.momentX;
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
                return load.momentY;
            case ImportLoadsNumberType.MomentZ:
            case ImportLoadsNumberType.MomentZMinus:
                return load.momentZ;
            case ImportLoadsNumberType.ForceZSustained:
            case ImportLoadsNumberType.ForceZSustainedMinus:
                return load.sustainedForceZ;
            case ImportLoadsNumberType.MomentXSustained:
            case ImportLoadsNumberType.MomentXSustainedMinus:
                return load.sustainedMomentX;
            case ImportLoadsNumberType.MomentYSustained:
            case ImportLoadsNumberType.MomentYSustainedMinus:
                return load.sustainedMomentY;
            case ImportLoadsNumberType.Zone1:
            case ImportLoadsNumberType.Zone1Minus:
            case ImportLoadsNumberType.Zone1Static:
            case ImportLoadsNumberType.Zone1MinusStatic:
                return load.zone1;
            case ImportLoadsNumberType.Zone2:
            case ImportLoadsNumberType.Zone2Minus:
            case ImportLoadsNumberType.Zone2Static:
            case ImportLoadsNumberType.Zone2MinusStatic:
                return load.zone2;
            case ImportLoadsNumberType.Zone3:
            case ImportLoadsNumberType.Zone3Minus:
            case ImportLoadsNumberType.Zone3Static:
            case ImportLoadsNumberType.Zone3MinusStatic:
                return load.zone3;
            case ImportLoadsNumberType.Zone4:
            case ImportLoadsNumberType.Zone4Minus:
            case ImportLoadsNumberType.Zone4Static:
            case ImportLoadsNumberType.Zone4MinusStatic:
                return load.zone4;
            case ImportLoadsNumberType.ZoneGeneric:
            case ImportLoadsNumberType.ZoneGenericMinus:
            case ImportLoadsNumberType.ZoneGenericStatic:
            case ImportLoadsNumberType.ZoneGenericMinusStatic:
                return load.zoneGeneric;
            case ImportLoadsNumberType.Zone1Dynamic:
            case ImportLoadsNumberType.Zone1MinusDynamic:
                return load.dynamicZone1;
            case ImportLoadsNumberType.Zone2Dynamic:
            case ImportLoadsNumberType.Zone2MinusDynamic:
                return load.dynamicZone2;
            case ImportLoadsNumberType.Zone3Dynamic:
            case ImportLoadsNumberType.Zone3MinusDynamic:
                return load.dynamicZone3;
            case ImportLoadsNumberType.Zone4Dynamic:
            case ImportLoadsNumberType.Zone4MinusDynamic:
                return load.dynamicZone4;
            case ImportLoadsNumberType.ZoneGenericDynamic:
            case ImportLoadsNumberType.ZoneGenericMinusDynamic:
                return load.dynamicZoneGeneric;
            case ImportLoadsNumberType.TedX:
            case ImportLoadsNumberType.TedXMinus:
                return load.tedX;
            case ImportLoadsNumberType.TedY:
            case ImportLoadsNumberType.TedYMinus:
                return load.tedY;
            default:
                return 0;
        }
    }

    private setLoadNumber(load: LoadCombination, numberType: ImportLoadsNumberType, value: number) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
                load.forceX = value;
                break;
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
                load.forceY = value;
                break;
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
                load.forceZ = value;
                break;
            case ImportLoadsNumberType.BottomForceZ:
            case ImportLoadsNumberType.BottomForceZMinus:
                load.bottomForceZ = value;
                break;
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
                load.momentX = value;
                break;
            case ImportLoadsNumberType.ForceZSustained:
            case ImportLoadsNumberType.ForceZSustainedMinus:
                load.sustainedForceZ = value;
                break;
            case ImportLoadsNumberType.MomentXSustained:
            case ImportLoadsNumberType.MomentXSustainedMinus:
                load.sustainedMomentX = value;
                break;
            case ImportLoadsNumberType.MomentYSustained:
            case ImportLoadsNumberType.MomentYSustainedMinus:
                load.sustainedMomentY = value;
                break;
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
                load.momentY = value;
                break;
            case ImportLoadsNumberType.Zone1:
            case ImportLoadsNumberType.Zone1Minus:
            case ImportLoadsNumberType.Zone1Static:
            case ImportLoadsNumberType.Zone1MinusStatic:
                load.zone1 = value;
                break;
            case ImportLoadsNumberType.Zone2:
            case ImportLoadsNumberType.Zone2Minus:
            case ImportLoadsNumberType.Zone2Static:
            case ImportLoadsNumberType.Zone2MinusStatic:
                load.zone2 = value;
                break;
            case ImportLoadsNumberType.Zone3:
            case ImportLoadsNumberType.Zone3Minus:
            case ImportLoadsNumberType.Zone3Static:
            case ImportLoadsNumberType.Zone3MinusStatic:
                load.zone3 = value;
                break;
            case ImportLoadsNumberType.Zone4:
            case ImportLoadsNumberType.Zone4Minus:
            case ImportLoadsNumberType.Zone4Static:
            case ImportLoadsNumberType.Zone4MinusStatic:
                load.zone4 = value;
                break;
            case ImportLoadsNumberType.ZoneGeneric:
            case ImportLoadsNumberType.ZoneGenericMinus:
            case ImportLoadsNumberType.ZoneGenericStatic:
            case ImportLoadsNumberType.ZoneGenericMinusStatic:
                load.zoneGeneric = value;
                break;
            case ImportLoadsNumberType.Zone1Dynamic:
            case ImportLoadsNumberType.Zone1MinusDynamic:
                load.dynamicZone1 = value;
                break;
            case ImportLoadsNumberType.Zone2Dynamic:
            case ImportLoadsNumberType.Zone2MinusDynamic:
                load.dynamicZone2 = value;
                break;
            case ImportLoadsNumberType.Zone3Dynamic:
            case ImportLoadsNumberType.Zone3MinusDynamic:
                load.dynamicZone3 = value;
                break;
            case ImportLoadsNumberType.Zone4Dynamic:
            case ImportLoadsNumberType.Zone4MinusDynamic:
                load.dynamicZone4 = value;
                break;
            case ImportLoadsNumberType.ZoneGenericDynamic:
            case ImportLoadsNumberType.ZoneGenericMinusDynamic:
                load.dynamicZoneGeneric = value;
                break;
            case ImportLoadsNumberType.TedX:
            case ImportLoadsNumberType.TedXMinus:
                load.tedX = value;
                break;
            case ImportLoadsNumberType.TedY:
            case ImportLoadsNumberType.TedYMinus:
                load.tedY = value;
                break;
        }
    }

    private isSameNumberType(numberTypeOne: number, numberTypeTwo: number) {
        return Math.floor(numberTypeOne / 2) == Math.floor(numberTypeTwo / 2);
    }

    private convertToStressUnit(load: LoadCombination) {
        const stressUnit = this.stressUnitDropdown.selectedValue as Unit;
        const internalStressUnit = this.unit.getInternalUnit(UnitGroup.Stress);

        this.setLoads(load, stressUnit, internalStressUnit);

        if ((this.design.connectionType == ConnectionType.ConcreteOverlay) && (this.design.applicationType == ApplicationType.GenericReinforcement)) {
            load.tedX = this.unit.convertUnitValueArgsToUnit(load.tedX, stressUnit, internalStressUnit);
            load.tedY = this.unit.convertUnitValueArgsToUnit(load.tedY, stressUnit, internalStressUnit);
        }
    }

    private convertToSelectedUnit(load: LoadCombination) {

        const forceUnit = this.forceUnitDropdown.selectedValue as Unit;
        const momentUnit = this.momentUnitDropdown.selectedValue as Unit;

        const internalForceUnit = this.unit.getInternalUnit(UnitGroup.Force);
        const internalMomentUnit = this.unit.getInternalUnit(UnitGroup.Moment);

        if (this.showStressUnit) {
            this.convertToStressUnit(load);
        }
        else {
            this.setLoads(load, forceUnit, internalForceUnit);
            load.forceX = this.unit.convertUnitValueArgsToUnit(load.forceX, forceUnit, internalForceUnit);
            load.forceZ = this.unit.convertUnitValueArgsToUnit(load.forceZ, forceUnit, internalForceUnit);
            load.momentX = this.unit.convertUnitValueArgsToUnit(load.momentX, momentUnit, internalMomentUnit);
            load.momentY = this.unit.convertUnitValueArgsToUnit(load.momentY, momentUnit, internalMomentUnit);
            if (this.isAciCsa || this.design.isPirEuOrAus) {
                load.forceY = this.unit.convertUnitValueArgsToUnit(load.forceY, forceUnit, internalForceUnit);
                if (this.isSustainedLoad) {
                    load.sustainedForceZ = this.unit.convertUnitValueArgsToUnit(load.sustainedForceZ, forceUnit, internalForceUnit);
                    load.sustainedMomentY = this.unit.convertUnitValueArgsToUnit(load.sustainedMomentY, momentUnit, internalMomentUnit);
                }
            }
            if (this.isExtensionsOrJointsHNA) {
                load.momentZ = this.unit.convertUnitValueArgsToUnit(load.momentZ, momentUnit, internalMomentUnit);
                if (this.isSustainedLoad) {
                    load.sustainedMomentX = this.unit.convertUnitValueArgsToUnit(load.sustainedMomentX, momentUnit, internalMomentUnit);
                }
            }
        }
    }

    private negateIfNeeded(load: LoadCombination) {
        for (let i = 0; i < this.loadsDropdownHeaders.length; i++) {
            // don't overwrite values of related number types (eg. Zone1Minus, Zone1Static)
            if (!this.isColumnVisible(i)) {
                continue;
            }
            const numberType = this.loadsDropdownHeaders[i].dropdown.selectedValue as number;
            const loadNumber = this.getLoadNumber(load, numberType);

            if (numberType % 2 != 0 && loadNumber != null) {
                this.setLoadNumber(load, numberType, -loadNumber);
            }
        }
    }

    private save(form: NgForm) {
        if (this.submitted || (form.enabled && !form.valid) || this.importedLoads.length == 0) {
            return;
        }

        this.design.usageCounterC2C.ExcelImportLoad++;

        this.submitted = true;
        this.pendingSave = true;

        // remove load combinations with all values zero
        const importedLoads = cloneDeep(this.importedLoads.filter((importedLoads) => this.loadCombinationHasValue(importedLoads)));

        this.design.loadCombinationsC2C = this.design.loadCombinationsC2C ?? [];

        if (importedLoads.length > 0) {
            this.design.loadCombinationsC2C = this.design.loadCombinationsC2C.filter((loadCombinationsC2C) => this.loadCombinationHasValue(loadCombinationsC2C)
            );
        }

        for (const load of importedLoads) {
            this.convertToSelectedUnit(load);
            this.negateIfNeeded(load);
            this.design.loadCombinationsC2C.push(load);
        }

        this.userSettings.settings.applicationImportLoads.numberTypeOne.value = this.loadsDropdownHeaders[0].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwo.value = this.loadsDropdownHeaders[1].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeThree.value = this.loadsDropdownHeaders[2].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeFour.value = this.loadsDropdownHeaders[3].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeFive.value = this.loadsDropdownHeaders[4].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeSix.value = this.loadsDropdownHeaders[5].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeSeven.value = this.loadsDropdownHeaders[6].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeEight.value = this.loadsDropdownHeaders[7].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeNine.value = this.loadsDropdownHeaders[8].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTen.value = this.loadsDropdownHeaders[9].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeEleven.value = this.loadsDropdownHeaders[10].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwelve.value = this.loadsDropdownHeaders[11].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeThirteen.value = this.loadsDropdownHeaders[12].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeFourteen.value = this.loadsDropdownHeaders[13].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeFifteen.value = this.loadsDropdownHeaders[14].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeSixteen.value = this.loadsDropdownHeaders[15].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeSeventeen.value = this.loadsDropdownHeaders[16].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeEighteen.value = this.loadsDropdownHeaders[17].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeNineteen.value = this.loadsDropdownHeaders[18].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwenty.value = this.loadsDropdownHeaders[19].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwentyone.value = this.loadsDropdownHeaders[20].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwentytwo.value = this.loadsDropdownHeaders[21].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwentythree.value = this.loadsDropdownHeaders[22].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwentyfour.value = this.loadsDropdownHeaders[23].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwentyfive.value = this.loadsDropdownHeaders[24].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwentysix.value = this.loadsDropdownHeaders[25].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwentyseven.value = this.loadsDropdownHeaders[26].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwentyeight.value = this.loadsDropdownHeaders[27].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.applicationImportLoads.numberTypeTwentynine.value = this.loadsDropdownHeaders[28].dropdown.selectedValue as ImportLoadsNumberType;

        this.userSettings.settings.applicationImportLoads.forceUnit.value = this.forceUnitDropdown.selectedValue as Unit;
        this.userSettings.settings.applicationImportLoads.momentUnit.value = this.momentUnitDropdown.selectedValue as Unit;
        this.userSettings.settings.applicationImportLoads.stressUnit.value = this.stressUnitDropdown.selectedValue as Unit;

        this.userSettings.save();

        // calculate
        this.calculationService.calculateAsync(this.design, undefined, { suppressLoadingFlag: true, importingLoadCases: true })
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    private loadCombinationHasValue(loadCombination: LoadCombination) {
        return loadCombination.forceX != null && loadCombination.forceX != 0 ||
            loadCombination.forceY != null && loadCombination.forceY != 0 ||
            loadCombination.forceZ != null && loadCombination.forceZ != 0 ||
            loadCombination.momentX != null && loadCombination.momentX != 0 ||
            loadCombination.momentY != null && loadCombination.momentY != 0 ||
            loadCombination.sustainedForceZ != null && loadCombination.sustainedForceZ != 0 ||
            loadCombination.sustainedMomentY != null && loadCombination.sustainedMomentY != 0 ||
            loadCombination.zone1 != null && loadCombination.zone1 != 0 ||
            loadCombination.zone2 != null && loadCombination.zone2 != 0 ||
            loadCombination.zone3 != null && loadCombination.zone3 != 0 ||
            loadCombination.zone4 != null && loadCombination.zone4 != 0 ||
            loadCombination.zoneGeneric != null && loadCombination.zoneGeneric != 0 ||
            loadCombination.dynamicZone1 != null && loadCombination.dynamicZone1 != 0 ||
            loadCombination.dynamicZone2 != null && loadCombination.dynamicZone2 != 0 ||
            loadCombination.dynamicZone3 != null && loadCombination.dynamicZone3 != 0 ||
            loadCombination.dynamicZone4 != null && loadCombination.dynamicZone4 != 0 ||
            loadCombination.dynamicZoneGeneric != null && loadCombination.dynamicZoneGeneric != 0 ||
            loadCombination.tedX != null && loadCombination.tedX != 0 ||
            loadCombination.tedY != null && loadCombination.tedY != 0;
    }
}
