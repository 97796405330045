<div class="rebar-loads box-section box-section-white" *ngIf="showRebarLoads">
    <c2c-utilization-tab
        [title]="translate('Agito.Hilti.C2C.RebarLoads')"
        icon="sprite-rebar-loads"
        [collapsingControl]="CollapsingControls.RebarLoads"
        [collapsed]="collapsed"
        (collapseChange)="onCollapsedChanged($any($event).detail)">
    </c2c-utilization-tab>

    <div class="rebar-loads-table" *ngIf="!collapsed">
        <table>
            <caption>Rebar loads table</caption>
            <tbody>
                <tr>
                    <th *ngIf=areLayersSelected [innerHtml]="translate('Agito.Hilti.C2C.RebarLoads.Table.Layers')"></th>
                    <th *ngIf=!areLayersSelected [innerHtml]="translate('Agito.Hilti.C2C.RebarLoads.Table.Rebar')"></th>
                    <th id="fpl"><span [innerHtml]="translate('Agito.Hilti.C2C.RebarLoads.Table.Fpl')"></span> {{getForceUnit()}}</th>
                    <th id="lv"><span [innerHtml]="translate('Agito.Hilti.C2C.RebarLoads.Table.Lv')"></span> {{getLengthUnit()}}</th>
                </tr>
                <tr *ngFor="let rebar of rebarsArray; trackBy: trackRebarLoadById">
                    <td *ngIf="areLayersSelected" style="white-space: nowrap;">{{rebar.id}}</td>
                    <td *ngIf=!areLayersSelected style="text-align: center; white-space: nowrap;">{{rebar.id}}</td>
                    <td>{{rebar.fpl}}</td>
                    <td>{{rebar.lv}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
