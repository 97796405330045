import cloneDeep from 'lodash-es/cloneDeep';

import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { SafetyFactorRegion } from '../../../shared/entities/code-lists/safety-factor-region';
import {
    ConnectionType
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IPropertyMetaDataC2C, PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { CodeListService as CodeListServiceC2C } from '../../services/code-list.service';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { IReductionFactorsInputsData } from '../../../shared/entities/reduction-factors-inputs-data';

@Component({
    templateUrl: './reduction-factors-inputs.component.html',
    styleUrls: ['./reduction-factors-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ReductionFactorsInputsComponent implements OnInit, DoCheck {
    @Input()
    public data!: IReductionFactorsInputsData;

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    public partialReductionFactorSteel!: NumericTextBoxProps;
    public partialReductionFactorConcrete!: NumericTextBoxProps;
    public partialReductionFactorFire!: NumericTextBoxProps;
    public alphaCC!: NumericTextBoxProps;
    public alphaCT!: NumericTextBoxProps;
    public dataLoaded = false;

    private oldRegionId!: number;
    private oldData!: IReductionFactorsInputsData;

    constructor(
        private localizationService: LocalizationService,
        private codeListC2C: CodeListServiceC2C,
        private unit: UnitService
    ) { }

    public get isReductionFactorFireVisible() {
        return this.data.connectionType != ConnectionType.ConcreteOverlay;
    }

    public get isAlphaCTVisible() {
        return this.data.connectionType == ConnectionType.ConcreteOverlay;
    }

    private get defaultReductionFactorSteel() {
        const safetyFactors = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.SafetyFactorRegionC2C] as SafetyFactorRegion[];
        return `${safetyFactors.find(sf => sf.regionId == this.regionId)?.defaultGammaS}`;
    }

    private get defaultReductionFactorConcrete() {
        const safetyFactors = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.SafetyFactorRegionC2C] as SafetyFactorRegion[];
        return `${safetyFactors.find(sf => sf.regionId == this.regionId)?.defaultGammaC}`;
    }

    private get defaultAlphaCC() {
        const safetyFactors = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.SafetyFactorRegionC2C] as SafetyFactorRegion[];
        return `${safetyFactors.find(sf => sf.regionId == this.regionId)?.defaultAlphaCC}`;
    }

    private get defaultAlphaCT() {
        const safetyFactors = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.SafetyFactorRegionC2C] as SafetyFactorRegion[];
        return `${safetyFactors.find(sf => sf.regionId == this.regionId)?.defaultAlphaCT}`;
    }

    ngOnInit(): void {
        this.initReductionFactorsControls();
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setDefaultValues();
            this.refreshControls();

            this.dataLoaded = true;
        }

        this.updateData();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private updateData() {
        let update = false;

        if (this.oldRegionId != this.regionId) {
            this.onRegionChange();

            this.oldRegionId = this.regionId;
        }

        if (this.oldData != null) {
            if (this.oldData.connectionType != this.data.connectionType) {
                update = true;
                this.onConnectionTypeChange();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = cloneDeep(this.data);
        }
    }

    private defaultReductionFactorFire(propertyMetaData: IPropertyMetaDataC2C) {
        const propertyValue = this.codeListC2C.getPropertyValue(propertyMetaData.id, this.regionId, this.data.connectionType ?? ConnectionType.Unknown);
        return `${propertyValue.defaultValue}`;
    }

    private updateControl(control: NumericTextBoxProps, propertyMetaData: IPropertyMetaDataC2C, defaultValue: string) {
        const propertyValue = this.codeListC2C.getPropertyValue(propertyMetaData.id, this.regionId, this.data.connectionType ?? ConnectionType.Unknown);

        control.maxValue = propertyValue.maxValue;
        control.minValue = propertyValue.minValue;
        control.placeholder = defaultValue;
    }

    private onConnectionTypeChange() {
        this.refreshControls();
    }

    private onRegionChange() {
        this.refreshControls();
    }

    private setDefaultValues() {
        if (this.data.design != null) {
            const design = this.data.design();
            this.data.partialReductionFactorSteel = design.partialReductionFactorSteel;
            this.data.partialReductionFactorConcrete = design.partialReductionFactorConcrete;
            this.data.partialReductionFactorFire = design.partialReductionFactorFire;
            this.data.alphaCC = design.alphaCcC2C;
            this.data.alphaCT = design.alphaCtC2C;
        }
    }

    private refreshControls() {
        this.updateControl(this.partialReductionFactorSteel, PropertyMetaDataC2C.Option_C2C_PartialReductionFactorSteel, this.defaultReductionFactorSteel);
        this.updateControl(this.partialReductionFactorConcrete, PropertyMetaDataC2C.Option_C2C_PartialReductionFactorConcrete, this.defaultReductionFactorConcrete);
        this.updateControl(this.partialReductionFactorFire, PropertyMetaDataC2C.Option_C2C_PartialReductionFactorFire, this.defaultReductionFactorFire(PropertyMetaDataC2C.Option_C2C_PartialReductionFactorFire));
        this.updateControl(this.alphaCC, PropertyMetaDataC2C.Option_C2C_AlphaCC, this.defaultAlphaCC);
        this.updateControl(this.alphaCT, PropertyMetaDataC2C.Option_C2C_AlphaCT, this.defaultAlphaCT);
    }

    private initReductionFactorsControls() {
        this.partialReductionFactorSteel = {
            id: 'reduction-factors-partialReductionFactorSteel',
            title: this.translate('Agito.Hilti.Profis3.ApplicationSettings.PartialReductionFactorSteel'),
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_PartialReductionFactorSteel.id)
        };

        this.partialReductionFactorConcrete = {
            id: 'reduction-factors-partialReductionFactorConcrete',
            title: this.translate('Agito.Hilti.Profis3.ApplicationSettings.PartialReductionFactorConcrete'),
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_PartialReductionFactorConcrete.id)
        };

        this.partialReductionFactorFire = {
            id: 'reduction-factors-partialReductionFactorFire',
            title: this.translate('Agito.Hilti.Profis3.ApplicationSettings.PartialReductionFactorFire'),
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_PartialReductionFactorFire.id)
        };

        this.alphaCC = {
            id: 'reduction-factors-partialReductionFactorAlphaCc',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ReductionFactors.AlphaCC'),
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_AlphaCC.id)
        };

        this.alphaCT = {
            id: 'reduction-factors-partialReductionFactorAlphaCt',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ReductionFactors.AlphaCT'),
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_AlphaCT.id)
        };
    }
}
