import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { CollapseState } from '../../../shared/enums/collapse-state';
import { includeSprites } from '../../sprites';

@Component({
  templateUrl: './utilizations-collapse.component.html',
  styleUrls: ['../layer-utilizations/utilizations-base.component.scss', './utilizations-collapse.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class UtilizationsCollapseComponent implements OnChanges {

  @Input()
  public controlId = '';

  @Input()
  public state: CollapseState = CollapseState.Collapsed;

  @Output()
  public collapseToggled = new EventEmitter<CollapseState>();

  public get collapsingClass(): string {
    return this.state == CollapseState.Extended ? 'extended' : '';
  }

  public get id(): string {
    return `${this.controlId}-collapse-button`;
  }

  public get isCollapsed(): boolean {
    return this.state == CollapseState.Collapsed;
  }

  constructor(private elementRef: ElementRef<HTMLElement>) { }

  ngOnChanges(): void {
    includeSprites(this.elementRef.nativeElement.shadowRoot,
        'sprite-arrow-up',
        'sprite-arrow-down'
    );
  }

  public toggleCollapse(): void {
    this.state = this.state == CollapseState.Collapsed ? CollapseState.Extended : CollapseState.Collapsed;
    this.collapseToggled.emit(this.state);
  }

}
