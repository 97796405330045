<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.Warning')" closeButtonId="l-shape-limitation-button-close">
</pe-modal-header>

<pe-modal-body>
    <div [innerHTML]="translate('Agito.Hilti.C2C.EnsureLateralOrInternalNode.Message')"></div>
    <img class="image" [src]="lShapeLimitationImage" alt="l shape limitation" />
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        [id]="'l-shape-limitation-button-ok'"
        [look]="'Cta'"
        (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.Ok')">
    </pe-button>
</pe-modal-footer>
