import { IScreenShotSettingsC2C } from '@profis-engineering/c2c-gl-model/gl-model';
import { View2dModeType } from '@profis-engineering/gl-model/gl-model';

export enum ReportDetails {
    // AdvanceBaseplate = 1, // PE
    // WeldDesign = 2, // PE
    // AnchorPlateDesign = 3, // PE
    // HandrailSafetyDesign = 4, // PE
    IncludeItemNumbers = 5, // PE & C2C
    // ParallelDesign = 6, // PE
    TrimbleConnectUpload = 7, // PE & C2C
    IncludeAnchorCalculation = 8, // C2C
    IncludeApprovals = 9, // C2C,
    IncludeSustainability = 10 // C2C (also common when needed)
}

export interface IExportReportSupportMethodsC2C {
    createGlModelScreenshot2D: (extraInfo: IScreenShotSettingsC2C, view2dMode: View2dModeType) => Promise<string>;
    createGlModelScreenshot: (extraInfo: IScreenShotSettingsC2C) => Promise<string>;
    createZoneDiagramScreenshot: () => Promise<string>;
}

export interface IExportReportComponentInputC2c extends IExportReportSupportMethodsC2C {
    onModelImagesCreated?: () => void;
    onModelImagesCreated2D?: () => void;
}
