import {
    CodeList as CodeListBase,
    ICodeListConstructor
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { TooltipType } from '@profis-engineering/pe-ui-common/services/code-list.common';

export interface ICodeListC2CConstructor extends ICodeListConstructor {
    tooltipType?: TooltipType;
    tooltipDisabledType?: TooltipType;
}

export abstract class CodeList extends CodeListBase {
    public declare tooltipType?: TooltipType;
    public declare tooltipDisabledType?: TooltipType;

    constructor(codeList: ICodeListC2CConstructor) {
        super(codeList);
    }

    protected static override baseFromService<TConstructor extends ICodeListC2CConstructor>(
        displayKeyPrefix: string,
        codeList: { [property: string]: any },
        constructor?: TConstructor
    ): TConstructor {
        return {
            id: codeList['id'],
            nameResourceKey: codeList['displayKey'] != null && codeList['displayKey'] != '' ? `Agito.Hilti.C2C.CodeList.${displayKeyPrefix}.${codeList['displayKey']}` : undefined,
            name: codeList['name'],
            image: codeList['image'] != null && codeList['image'] != '' ? 'sprite-' + codeList['image'] : undefined,
            displayKey: codeList['displayKey'],
            tooltipDisplayKey: codeList['tooltipDisplayKey'],
            tooltipTitleDisplayKey: codeList['tooltipTitleDisplayKey'],
            tooltipDisabledDisplayKey: codeList['tooltipDisabledDisplayKey'],
            tooltipDisabledTitleDisplayKey: codeList['tooltipDisabledTitleDisplayKey'],
            tooltipType: codeList['tooltipType'] != null ? codeList['tooltipType'] : TooltipType.Normal,
            tag: codeList['tested'] != null && !(codeList['tested'] as boolean) ? `Agito.Hilti.C2C.Rebars.Untested.Tag` : undefined,
            infoPopupCommand: codeList['infoPopupCommand'],
            ...constructor
        } as TConstructor;
    }
}
