import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';

import { DesignC2C, IDesignState } from '../../../../shared/entities/design-c2c';
import {
    ITemperatureTableItem, ITemperatureTableProps
} from '../../../../shared/entities/main-menu/main-menu-controls';
import { ProjectCodeList } from '../../../../shared/enums/project-code-list';
import {
    UIPropertyEntityC2C
} from '../../../../shared/generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { TemperatureTable } from '../../../components/main-menu/TemperatureTable/TemperatureTable';
import { CalculationServiceC2C } from '../../calculation-c2c.service';
import { CodeListService } from '../../code-list.service';
import { LocalizationService } from '../../localization.service';
import { UnitService } from '../../unit.service';

export function initializeTemperatureTable(
    controlProps: ITemperatureTableProps,
    unitService: UnitService,
    design: DesignC2C,
    uiPropertyId: number,
    codeListService: CodeListService,
    calculationService: CalculationServiceC2C,
    localizationService: LocalizationService) {

    controlProps.type = TemperatureTable;
    controlProps.unitService = unitService;
    controlProps.localization = localizationService;
    controlProps.items = design.model[uiPropertyId] as ITemperatureTableItem[] ?? [];

    const uiProperties = codeListService.projectCodeListsC2C[ProjectCodeList.UIPropertiesC2C] as unknown as UIPropertyEntityC2C[];
    const propertyValues = uiProperties.find(prop => prop.id == uiPropertyId)?.propertyValues;
    let propValue = propertyValues?.find((property) => property.regionId == design.region.id);
    if (!propValue) {
        propValue = propertyValues?.find((property) => property.regionId == 0);
    }

    if (propValue != null) {
        controlProps.defaultTemp = propValue.defaultValue;
        controlProps.minTemp = propValue.minValue;
        controlProps.maxTemp = propValue.maxValue;
    }

    controlProps.valueChanged = (value) => {
        if (uiPropertyId != null) {
            calculationService.calculateAsync(design, d => {
                const tempItemsArray = d.model[uiPropertyId] as ITemperatureTableItem[] ?? [];
                const item = tempItemsArray.find(t => t.id == value.id);
                if (item != null) {
                    item.temperature = value.temperature;
                    d.model[uiPropertyId] = tempItemsArray;
                }
            });
        }
    };

    const onStateChanged = (state: IDesignState, menu: IMenu) => {
        const control = menu.controls[controlProps.controlId] as ITemperatureTableProps;
        if (control == null) {
            return null;
        }
        return { items: design.model[uiPropertyId] as ITemperatureTableItem[] ?? [] } as ITemperatureTableProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}