import { Component, ElementRef, Input, OnChanges, ViewEncapsulation } from '@angular/core';

import { includeSprites, Sprite } from '../../sprites';

@Component({
    templateUrl: './utilization-item.component.html',
    styleUrls: ['./utilization-item.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UtilizationItemComponent implements OnChanges {
    @Input()
    public controlId = '';

    @Input()
    public title = '';

    @Input()
    public tooltip = '';

    @Input()
    public header = '';

    @Input()
    public sufix = '';

    @Input()
    public showPercentBar = false;

    @Input()
    public percentValue = 0;

    @Input()
    public dataInvalid = false;

    @Input()
    public barInvalid = false;

    @Input()
    public image = '';

    @Input()
    public value?: number;

    @Input()
    public showNumberContainer = true;


    private readonly spriteImagePrefix = 'pe-ui-c2c-';
    private initializedSpriteImages: string[] = [];


    constructor(
        private elementRef: ElementRef<HTMLElement>
    ) { }


    public get hasValue(): boolean {
        return this.value != null;
    }

    public get hasImage(): boolean {
        return this.image != '' && this.image != null;
    }

    public get spriteImage() {
        if (!this.hasImage) {
            return '';
        }

        return this.spriteImagePrefix + this.image;
    }


    ngOnChanges(): void {
        if (this.hasImage && !this.initializedSpriteImages.includes(this.image)) {
            includeSprites(this.elementRef.nativeElement.shadowRoot, this.image as Sprite);
            this.initializedSpriteImages.push(this.image);
        }
    }

    public getComponentId(type: string) {
        return `${this.controlId}-${type}`;
    }
}
