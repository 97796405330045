import { Injectable } from '@angular/core';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitServiceBase, UnitServiceInjected, UnitPrecision } from '@profis-engineering/pe-ui-common/services/unit.common';
import { UIProperty } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { PropertyMetaDataC2C } from '../../shared/properties/properties';

@Injectable({
    providedIn: 'root'
})
export class UnitService extends UnitServiceInjected {
    private baseService!: UnitServiceBase;

    public unitPrecision: UnitPrecision = {
        getPrecision: (unit: UnitType) => {
            if (unit == UnitType.mm) {
                return 0;
            }

            if (unit == UnitType.cm) {
                return 1;
            }

            if (unit == UnitType.None) {
                return 2;
            }

            return this.baseService.getPrecision(unit);
        },
        getPrecisionForProperty: (uiProperty: number, unit: UnitType) => {
            const additionalPrecision = (this as any)['getPrecision_' + PropertyMetaDataC2C.getById(uiProperty).name]?.(unit) ?? 0;
            return this.unitPrecision.getPrecision(unit) + additionalPrecision;
        }
    };

    public override setBaseService(baseService: UnitServiceBase): void {
        this.baseService = baseService;
        super.setBaseService(baseService);
        this.getPrecision = this._getPrecision;
        this.formatUnitValue = baseService.formatUnitValue;
        this.formatUnitValueArgs = baseService.formatUnitValueArgs;
        this.formatInternalValueAsDefault = baseService.formatInternalValueAsDefault;
    }

    public declare getPrecision: (unit: UnitType, uiProperty?: UIProperty, unitPrecision?: UnitPrecision) => number;

    private _getPrecision(unit: UnitType, uiProperty?: UIProperty) {
        return this.baseService.getPrecision(unit, uiProperty, this.unitPrecision);
    }

    private getPrecision_Loads_C2C_Zone_Base(unit: UnitType) {
        switch (unit) {
            case UnitType.Nmm2:
            case UnitType.Nm2:
            case UnitType.kgfcm2:
                return 6;
            default:
                return 0;
        }
    }

    private getPrecision_C2C_UnitStressBase(unit: UnitType) {
        switch (unit) {
            case UnitType.PSI:
            case UnitType.KSI:
            case UnitType.Nmm2:
            case UnitType.kNm2:
            case UnitType.Nm2:
            case UnitType.kgfcm2:
                return 1;
            default:
                return 0;
        }
    }

    private getPrecision_Loads_C2C_NormalStress(unit: UnitType) {
        switch (unit) {
            case UnitType.Nmm2:
            case UnitType.PSI:
            case UnitType.KSI:
            case UnitType.kNm2:
            case UnitType.Nm2:
            case UnitType.kgfcm2:
                return 2;
            default:
                return 0;
        }
    }

    private getPrecision_BaseMaterial_CubeStrength(unit: UnitType) {
        switch (unit) {
            case UnitType.Nmm2:
                return 1;
            case UnitType.PSI:
                return 1;
            case UnitType.KSI:
                return 1;
            default:
                return 0;
        }
    }

    private getPrecision_forGeneralValue() {
        return 2;
    }

    private getPrecision_Loads_C2C_SeismicLoadMultiplierValue() {
        return 1;
    }

    public getPrecision_Loads_C2C_Zone1 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_Zone2 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_Zone3 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_Zone4 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_ZoneGeneric = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZone1 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZone2 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZone3 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZone4 = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_DynamicZoneGeneric = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_NormalStressACI = this.getPrecision_Loads_C2C_NormalStress;
    public getPrecision_Loads_C2C_NormalStressCSA = this.getPrecision_Loads_C2C_NormalStress;
    public getPrecision_Overlay_C2C_BaseMaterial_ExistingStructure_FcCube = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_Overlay_C2C_BaseMaterial_ExistingStructure_FcCyl = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_Overlay_C2C_BaseMaterial_NewStructure_FcCube = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_Overlay_C2C_BaseMaterial_NewStructure_FcCyl = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_ExistingStructure_C2C_BaseMaterial_FcCube = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_ExistingStructure_C2C_BaseMaterial_FcCyl = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_ExistingStructure_C2C_BaseMaterial_NewFcCube = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_ExistingStructure_C2C_BaseMaterial_NewFcCyl = this.getPrecision_BaseMaterial_CubeStrength;
    public getPrecision_NewStructure_C2C_AdditionalInformation_TransversePressure = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Reinforcement_C2C_LongitudinalGrade = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Reinforcement_C2C_CastInSplicedGrade = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Reinforcement_C2C_ReinforcementYieldStrengthFyk = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Loads_C2C_MaxAmplitude = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Loads_C2C_MaxSteelStress = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_ExistingStructure_C2C_TransversePressure = this.getPrecision_C2C_UnitStressBase;
    public getPrecision_Loads_C2C_TedX = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Loads_C2C_TedY = this.getPrecision_Loads_C2C_Zone_Base;
    public getPrecision_Option_C2C_EpsilonC2 = this.getPrecision_forGeneralValue;
    public getPrecision_Option_C2C_EpsilonCU2 = this.getPrecision_forGeneralValue;
    public getPrecision_Option_C2C_EpsilonUD = this.getPrecision_forGeneralValue;
    public getPrecision_Options_C2C_AS_EpsilonUd = this.getPrecision_forGeneralValue;
    public getPrecision_Loads_C2C_SeismicLoadMultiplier = this.getPrecision_Loads_C2C_SeismicLoadMultiplierValue;
}
