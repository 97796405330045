import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { DesignC2C, IDesignState } from '../../../../shared/entities/design-c2c';
import { ISelectRebarProps } from '../../../../shared/entities/main-menu/main-menu-controls';
import { SelectRebar } from '../../../components/main-menu/SelectRebar/SelectRebar';
import { getSpriteAsIconStyleResponsive } from '../../../sprites';
import { LocalizationService } from '../../localization.service';
import { ModalService } from '../../modal.service';
import { updateMainMenuControl } from '../static-menu-helper';

export function initializeSelectRebar(
    controlProps: ISelectRebarProps,
    localizationService: LocalizationService,
    modalService: ModalService,
    design: DesignC2C,
    uiPropertyId: number,
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {

    const propertyValue = design.model[uiPropertyId] as number;
    controlProps.type = SelectRebar;
    controlProps.title = localizationService.getString('Agito.Hilti.C2C.RebarFamily.Title');

    const getRebarDataFn = (rebarFamilyId?: number) => {
        let buttonText = '';
        let tag: string | undefined;
        let buttonImage = '';
        let buttonImageStyle: IResponsiveIconStyle | undefined;

        if (rebarFamilyId != null) {
            const selectedRebarFamily = design.fastenerFamilies.find((rebarFamily) => rebarFamily.id == rebarFamilyId);
            buttonText = selectedRebarFamily != null
                ? selectedRebarFamily.getTranslatedNameText({ localizationService })
                : '';
            tag = selectedRebarFamily != null && !selectedRebarFamily.tested ? localizationService.getString('Agito.Hilti.C2C.Rebars.Untested.Tag').charAt(0) : undefined;

            buttonImage = selectedRebarFamily?.image ?? '';
            if (selectedRebarFamily?.image != null && selectedRebarFamily.image != '') {
                buttonImageStyle = {
                    elementStyle: {},
                    afterElementStyle: getSpriteAsIconStyleResponsive(selectedRebarFamily.image)
                };
            }
        }
        else {
            buttonText = localizationService.getString('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.SelectAnchor.NotSelected');
        }

        return {
            buttonText,
            tag,
            buttonImage,
            buttonImageStyle
        };
    };

    const buttonData = getRebarDataFn(propertyValue);
    controlProps.buttonText = buttonData.buttonText;
    controlProps.tag = buttonData.tag;
    controlProps.buttonImage = buttonData.buttonImage;
    controlProps.buttonImageStyle = buttonData.buttonImageStyle;

    controlProps.clicked = () => {
        modalService.openSelectRebar({
            onSelect: (selectedRebarFamilyId) => {
                // change menu
                setState(menu => updateMainMenuControl<ISelectRebarProps>(menu, controlProps.controlId, { selectedRebarFamilyId } as any));
            }
        });
    };

    const onStateChanged = (state: IDesignState) => {
        const buttonData = getRebarDataFn(state.model[uiPropertyId] as number);

        return {
            buttonText: buttonData.buttonText,
            tag: buttonData.tag,
            buttonImage: buttonData.buttonImage,
            buttonImageStyle: buttonData.buttonImageStyle
        } as ISelectRebarProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}
