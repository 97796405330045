import { Injectable } from '@angular/core';
import {
  BrowserServiceInjected
} from '@profis-engineering/pe-ui-common/services/browser.common';

@Injectable({
  providedIn: 'root'
})
export class BrowserService extends BrowserServiceInjected {
}
