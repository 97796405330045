<div class="concrete-material-settings content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="parabola-text">
                <span>
                    <span l10n="Agito.Hilti.C2C.Options.ConcreteMaterialSettings.Parabola"></span>
                    <button type="button" (click)="infoPopup()" class="info-button pe-ui-c2c-sprite pe-ui-c2c-sprite-info-tooltip"></button>
                </span>
            </div>
            <div class="control">
                <pe-numeric-text-box
                    [id]="concreteMaterialSettingsEpsilonC2TextBox.id"
                    [title]="concreteMaterialSettingsEpsilonC2TextBox.title"
                    [placeholder]="concreteMaterialSettingsEpsilonC2TextBox.placeholder"
                    [value]="data.concreteMaterialSettingsEpsilonC2"
                    (valueChange)="data.concreteMaterialSettingsEpsilonC2=$any($event).detail"
                    [minValue]="concreteMaterialSettingsEpsilonC2TextBox.minValue"
                    [maxValue]="concreteMaterialSettingsEpsilonC2TextBox.maxValue"
                    [unit]="concreteMaterialSettingsEpsilonC2TextBox.unit"
                    [disabled]="disabled"
                    [precision]="concreteMaterialSettingsEpsilonC2TextBox.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control">
                <pe-numeric-text-box
                    [id]="concreteMaterialSettingsEpsilonCu2TextBox.id"
                    [title]="concreteMaterialSettingsEpsilonCu2TextBox.title"
                    [placeholder]="concreteMaterialSettingsEpsilonCu2TextBox.placeholder"
                    [value]="data.concreteMaterialSettingsEpsilonCu2"
                    (valueChange)="data.concreteMaterialSettingsEpsilonCu2=$any($event).detail"
                    [minValue]="concreteMaterialSettingsEpsilonCu2TextBox.minValue"
                    [maxValue]="concreteMaterialSettingsEpsilonCu2TextBox.maxValue"
                    [unit]="concreteMaterialSettingsEpsilonCu2TextBox.unit"
                    [disabled]=true
                    [precision]="concreteMaterialSettingsEpsilonCu2TextBox.precision">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
