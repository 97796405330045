import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import fp700 from '../../../images/InfoDialog/recommend-fp700.png';
import { DesignC2C as Design } from '../../../shared/entities/design-c2c';
import { getInfoLink, IInfoLinkValue } from '../../helpers/info-dialog-helper';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';
import { IRecommendDifferentProductComponentInput } from '../../../shared/entities/recommend-different-product-input';

const productImages: { [id: string]: string } = {
  fp700: fp700,
};

@Component({
  templateUrl: './recommend-different-product.component.html',
  styleUrls: ['./recommend-different-product.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class RecommendDifferentProductComponent implements OnInit {

  @Input()
  public modalInstance!: ModalInstance<IRecommendDifferentProductComponentInput>;

  public pendingSave = false;

  private infoLink?: IInfoLinkValue | null;

  constructor(
    private user: UserService,
    public localization: LocalizationService,
    private projectCodeListC2C: CodeListService,
    private calculationService: CalculationServiceC2C,
  ) {
  }

  public get titleKey() {
    return this.modalInstance.input?.titleKey;
  }

  public get message() {
    return this.modalInstance.input?.message;
  }

  public get recommendedRebarFamilyId() {
    return this.modalInstance.input?.recommendedRebarFamilyId;
  }

  public get infoUrl() {
    return this.infoLink?.icidLinks[0].link;
  }
  public get infoKey() {
    return this.infoLink?.displayKey;
  }

  public get imageUrl() {
    return (this.modalInstance.input?.imageKey ?? '') in productImages ? productImages[this.modalInstance.input?.imageKey ?? ''] : null;
  }

  private get design(): Design {
    return this.user?.design;
  }

  ngOnInit(): void {
    // don't close the modal if save is pending
    this.modalInstance.setOnClosing(() => {
      return !this.pendingSave;
    });

    if (this.modalInstance.input?.command) {
      this.infoLink = getInfoLink(this.projectCodeListC2C, this.design.region?.id ?? -1, this.design.connectionTypeId, this.modalInstance.input?.command);
    }
  }

  public close() {
    this.modalInstance.close();
  }

  public no() {
    this.modalInstance.close();
  }

  public async yes() {
    this.pendingSave = true;
    await this.calculationService
      .calculateAsync(this.design,
        (design) => {
          design.model[this.design.globalMetaProperties.sourceMetaProperty] = this.recommendedRebarFamilyId;
        }
      );
    this.pendingSave = false;

    this.modalInstance.close();
  }

  public translate(key: string | undefined) {
    return key != null ? this.localization.getString(key) : '';
  }
}
