import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AppSettings, IDesignListInfo, IDesignManagement, IImportDesignProvider, IModuleInitialData, IModulePreInitialData,
    IQuickStartApplication, IServiceNameMapping, ITrackingInfo, IUserSettingsInfo, IVirtualTour
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';

import { environment } from '../../environments/environmentC2C';
import {
    CodeListResponseC2C
} from '../../shared/generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { IDesignInfo } from '../entities/module-initial-data';
import { ApiService } from './api.service';
import { ApplicationProviderService } from './application-provider.service';
import { CodeListService } from './code-list.service';
import { ImportDesignProviderService } from './import-design-provider.service';
import { TourService } from './tour.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataService {

    constructor(
        private applicationProviderService: ApplicationProviderService,
        private importDesignProviderService: ImportDesignProviderService,
        private tourService: TourService,
        private codelistService: CodeListService,
        private apiService: ApiService,
    ) { }

    public getPreInitialData(): IModulePreInitialData {
        return {
            // Common
            authenticationRequiredServices: this.getAuthenticationRequiredServices(),
            serviceNameMapping: this.getServiceNameMapping(),
            userSettingsInfo: this.getUserSettingsInfo(),
        };
    }

    public getInitialData(): IModuleInitialData {
        return {
            // Quick start
            quickStartApplications: this.getQuickStartApplications(),

            // App settings
            appSettingsData: this.getAppSettingsData(),

            // Virtual tours
            virtualTours: this.getVirtualTours(),

            // Design info
            designInfo: this.getDesignInfo(),

            // List of designs
            designListInfo: this.getDesignListInfo(),

            // Design types
            designTypes: this.applicationProviderService.getDesignTypes,

            // Favorites
            favoritesInfo: this.applicationProviderService.getFavoritesInfo(),

            // Import design provider
            importDesignProvider: this.getImportDesignProvider(),

            // Tracking info
            trackingInfo: this.getTrackingInfo(),

            // Design management
            designManagement: this.getDesignManagementInfo(),
        };
    }

    public updateModuleData(): IModuleInitialData {
        return {
            quickStartApplications: this.getQuickStartApplications(),
            appSettingsData: this.getAppSettingsData(),
            virtualTours: this.getVirtualTours(),
            designInfo: this.getDesignInfo(),
            designListInfo: this.getDesignListInfo(),
        };
    }

    private getAuthenticationRequiredServices() {
        return [
            environment.c2cCodeListServiceUrl,
            environment.c2cCalculationServiceUrl,
            environment.c2cCalculationServiceSignalRServer,
            environment.c2cReportServiceUrl,
            environment.c2cTranslationsServiceUrl
        ];
    }

    private getServiceNameMapping(): IServiceNameMapping {
        const mapping: IServiceNameMapping = {};

        mapping[environment.c2cCodeListServiceUrl] = 'c2c-codelist-service';
        mapping[environment.c2cCalculationServiceUrl] = 'c2c-calculation-service';
        mapping[environment.c2cReportServiceUrl] = 'c2c-report-service';
        mapping[environment.c2cTranslationsServiceUrl] = 'c2c-translations-service';

        return mapping;
    }

    private getQuickStartApplications(): IQuickStartApplication[] {
        return this.applicationProviderService.getQuickStartApplications();
    }

    private getAppSettingsData(): AppSettings[] {
        return this.applicationProviderService.getAppSettings();
    }

    private getVirtualTours(): IVirtualTour[] {
        return this.tourService.getVirtualTours();
    }

    private getDesignListInfo(): IDesignListInfo[] {
        return this.applicationProviderService.getDesignListInfo();
    }

    private getDesignInfo(): IDesignInfo[] {
        return this.applicationProviderService.getDesignInfo();
    }

    private getImportDesignProvider(): IImportDesignProvider {
        return this.importDesignProviderService;
    }

    private getUserSettingsInfo(): IUserSettingsInfo {
        return this.applicationProviderService.getUserSettingsInfo();
    }

    private getTrackingInfo(): ITrackingInfo[] {
        return this.applicationProviderService.getTrackingInfo();
    }

    private getDesignManagementInfo(): IDesignManagement[] {
        return this.applicationProviderService.getDesignManagementInfo();
    }


    public async initCodeList(options?: ApiOptions) {
        const url = `${environment.c2cCodeListServiceUrl}CodeList/projectcodelist`;

        const { body: dataEntity } = await this.apiService.request<CodeListResponseC2C>(new HttpRequest('GET', url), options);

        // init codelists
        this.codelistService.initialize(dataEntity ?? ({} as CodeListResponseC2C));
    }
}
