<div class="advanced-rebar-settings content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control">
                <pe-numeric-text-box
                    [id]="advancedRebarSettingsSteelStrainLimitTextBox.id"
                    [title]="advancedRebarSettingsSteelStrainLimitTextBox.title"
                    [placeholder]="advancedRebarSettingsSteelStrainLimitTextBox.placeholder"
                    [value]="data.advancedRebarSettingsSteelStrainLimit"
                    (valueChange)="data.advancedRebarSettingsSteelStrainLimit=$any($event).detail"
                    [minValue]="advancedRebarSettingsSteelStrainLimitTextBox.minValue"
                    [maxValue]="advancedRebarSettingsSteelStrainLimitTextBox.maxValue"
                    [unit]="advancedRebarSettingsSteelStrainLimitTextBox.unit"
                    [disabled]="disabled"
                    [precision]="advancedRebarSettingsSteelStrainLimitTextBox.precision"
                    [tooltip]="advancedRebarSettingsSteelStrainLimitTextBox.tooltip">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
