import { CorrespondingChapter } from '../../shared/entities/code-lists/corresponding-chapter';
import { DesignMethodConnectionTypeMethodGroup } from '../../shared/entities/code-lists/design-method-connection-type-method-group';
import { ICodeListsC2C } from '../../shared/entities/design-c2c';
import { DesignCodeList } from '../../shared/entities/design-code-list';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import { CodeListService } from '../services/code-list.service';
import { LocalizationService } from '../services/localization.service';

export interface IChapterQueryData {
    designMethodId: number;
    connectionTypeId: number;
    designMethodGroupId: number;
    loadTypeId: number;
}

export class CorrespondingChapterHelper {
    private get correspondingChapterItems() {
        return this.codeListService.projectCodeListsC2C[ProjectCodeList.CorrespondingChapterC2C] as CorrespondingChapter[];
    }

    private get designMethodConnectionTypeItems() {
        return this.designCodeList[DesignCodeList.DesignMethodConnectionTypeMethodGroup] as DesignMethodConnectionTypeMethodGroup[];
    }

    constructor(
        private codeListService: CodeListService,
        private designCodeList: ICodeListsC2C,
        private localizationService: LocalizationService
    ) {
    }

    public getCorrespondingChapterString(query: IChapterQueryData) {
        const chapterId = this.getFilteredChapterId(query);
        const chapterItem = this.getCorrespondingChapterById(chapterId);

        if (chapterItem != undefined) {
            const chapterName = chapterItem.name ?? '';
            return this.getTranslatedReference(!chapterItem.isTranslatable, chapterName);
        }

        return '';
    }

    private getFilteredChapterId(query: IChapterQueryData) {
        const filtered = this.designMethodConnectionTypeItems.find(x => x.designMethodId == query.designMethodId
            && x.connectionTypeId == query.connectionTypeId && x.designMethodGroupId == query.designMethodGroupId && x.loadTypeId == query.loadTypeId);

        return filtered?.correspondingChapterId;
    }

    private getCorrespondingChapterById(id: number | undefined) {
        return id != undefined
            ? this.correspondingChapterItems.find(x => x.id == id)
            : undefined;
    }

    private getTranslatedReference(isChapter: boolean, reference: string) {
        const chapterTranslation = this.localizationService.getString('Agito.Hilti.C2C.CodeList.DesignMethodEntity.General.Chapter');
        const translationPrefix = 'Agito.Hilti.C2C.CodeList.CorrespondingChapter';

        return isChapter
            ? `${chapterTranslation} ${reference}`
            : this.localizationService.getString(`${translationPrefix}.${reference}`);
    }
}