import { NavigationTabWidth, TooltipType, ButtonGroupDisplay, ButtonGroupType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { ConnectionType } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { DesignMethodGroupHelper } from '../../helpers/design-method-group-helper';
import { getSpriteAsIconStyle } from '../../sprites';
import { LocalizationService } from '../localization.service';

import { checkConnectionType, getCommandConditional, getPopupTooltipConditional, isACICSA, isCSA, isPirAS, isPirEU, isPirHNA } from './static-menu-helper';

export function createC2CMenu_ExistingStructure(design: DesignC2C, localizationService: LocalizationService): TabC2C {
    let image = 'tab-overlay-installation';
    if (isPirEU(design) || isPirAS(design)) {
        image = 'tab-base-material';
    }

    return {
        DisplayKey: isPirEU(design) || isPirAS(design) ? 'Agito.Hilti.C2C.Navigation.ConcreteMember' : 'Agito.Hilti.C2C.Navigation.RegionInstallationConditions',
        Id: 21,
        Name: 'existingstructure-tab',
        Image: image,
        IconImage: getSpriteAsIconStyle(`sprite-${image}`),
        Width: NavigationTabWidth.Normal,
        TabRegions: isPirHNA(design) ? createC2CMenu_ExistingStructure_HNARegions(design, localizationService) : createC2CMenu_ExistingStructure_Regions(design, localizationService)
    };
}

function createC2CMenu_ExistingStructure_HNARegions(design: DesignC2C, localizationService: LocalizationService): RegionC2C[] {
    return [
        createC2CMenu_ExistingStructure_BaseMaterial(design),
        createC2CMenu_ExistingStructure_ExistingConcrete(design),
        createC2CMenu_ExistingStructure_Temperature(),
        createC2CMenu_ExistingStructure_InstallationConditions(),
        createC2CMenu_ExistingStructure_SurfaceRoughness(localizationService),
        createC2CMenu_ExistingStructure_SurfaceTreatment(design),
        createC2CMenu_ExistingStructure_ContactSurfaceCondition(design),
        createC2CMenu_ExistingStructure_ExistingStructure(),
        createC2CMenu_ExistingStructure_Reinforcement(),
        createC2CMenu_ExistingStructure_FrameNode()
    ];
}

function createC2CMenu_ExistingStructure_Regions(design: DesignC2C, localizationService: LocalizationService): RegionC2C[] {
    return [
        createC2CMenu_ExistingStructure_BaseMaterial(design),
        createC2CMenu_ExistingStructure_Temperature(),
        createC2CMenu_ExistingStructure_InstallationConditions(),
        createC2CMenu_ExistingStructure_SurfaceRoughness(localizationService),
        createC2CMenu_ExistingStructure_SurfaceTreatment(design),
        createC2CMenu_ExistingStructure_ContactSurfaceCondition(design),
        createC2CMenu_ExistingStructure_ExistingStructure(),
        createC2CMenu_ExistingStructure_Reinforcement(),
        createC2CMenu_ExistingStructure_FrameNode()
    ];
}

function createC2CMenu_ExistingStructure_BaseMaterial(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabBaseMaterial.RegionBaseMaterial',
        Id: 30,
        Name: 'basematerial-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.BaseMaterial.CrackedConcrete',
                Name: 'basematerial-crackedconcrete',
                UIPropertyId: 10146,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.BaseMaterial.CrackedConcrete.Tooltip.Text' : '',
                TooltipType: getPopupTooltipConditional(!isACICSA(design)),
                Command: getCommandConditional('OpenCrackedConcretePopup', !isACICSA(design))
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.BaseMaterial.ParallelCracksTop',
                Name: 'basematerial-parallelcrackstop',
                UIPropertyId: 10147,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.BaseMaterial.ParallelCracksBottom',
                Name: 'basematerial-parallelcracksbottom',
                UIPropertyId: 10171,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                CodelistName: 'BaseMaterialStructure',
                ControlType: 'Dropdown',
                Name: 'basematerial-basematerial-dropdown',
                TitleDisplayKey: isPirEU(design) || isPirAS(design) ? 'Agito.Hilti.C2C.BaseMaterial.ExistingBaseMaterial' : 'Agito.Hilti.C2C.BaseMaterial.BaseMaterial',
                UIPropertyId: 10148,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-fccyl',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.FcCyl',
                UIPropertyId: 10149,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-fccube',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.FcCube',
                UIPropertyId: 10150,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-elasticitymodulus',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.ElasticityModulus',
                UIPropertyId: 10711,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'Checkbox',
                Name: 'basematerial-lightweightconcrete',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.LightweightConcrete.Title',
                DisplayKey: 'Agito.Hilti.C2C.BaseMaterial.LightweightConcrete',
                UIPropertyId: 10467,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-lambda',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.TensileStrength',
                UIPropertyId: 10471,
                TooltipType: TooltipType.Popup,
                Command: 'OpenTensileStrengthPopup'
            },
            {
                CodelistName: 'ConcreteDensity',
                ControlType: 'Dropdown',
                Name: 'concretedensity-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.ConcreteDensity',
                UIPropertyId: 10439,
                Size: 50,
                TooltipType: TooltipType.Popup,
                Command: 'OpenConcreteDensityPopup'
            },
            {
                CodelistName: 'BaseMaterialStructure',
                ControlType: 'Dropdown',
                Name: 'basematerial-new-basematerial-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.NewBaseMaterial',
                UIPropertyId: 10682,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-fccyl-new',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.FcCyl',
                UIPropertyId: 10688,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-fccube-new',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.FcCube',
                UIPropertyId: 10710,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-elasticitymodulus-new',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.ElasticityModulus',
                UIPropertyId: 10712,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            }
        ]
    };
}

function createC2CMenu_ExistingStructure_Temperature(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabExistingStructure.RegionTemperature',
        Id: 31,
        Name: 'temperature-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'temperature-shortterm',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.Temperature.ShortTerm',
                UIPropertyId: 10151,
                UnitGroup: UnitGroup.Temperature,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.Temperature.ShortTerm'
            },
            {
                ControlType: 'TextBox',
                Name: 'temperature-longterm',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.Temperature.LongTerm',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.Temperature.LongTerm',
                UIPropertyId: 10152,
                UnitGroup: UnitGroup.Temperature,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                CodelistName: 'TemperatureInstallation',
                ControlType: 'Dropdown',
                Name: 'temperature-temperatureinstallation-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.Temperature.Installation',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.Temperature.Installation',
                UIPropertyId: 10154,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            }
        ]
    };
}

function createC2CMenu_ExistingStructure_InstallationConditions(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.RegionInstallationConditions',
        Id: 32,
        Name: 'installationconditions-section',
        Controls: [{
            ControlType: 'Dropdown',
            Name: 'installationconditions-drillingtype-dropdown',
            UIPropertyId: 10155,
            CodelistName: 'DrillingType',
            DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.DrillingType',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.DrillingType'
        },
        {
            ControlType: 'Dropdown',
            Name: 'installationconditions-holecondition-dropdown',
            UIPropertyId: 10468,
            CodelistName: 'HoleCondition',
            DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionInstallationConditions.ControlHoleCondition',
            TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionInstallationConditions.ControlHoleCondition'
        },
        {
            ControlType: 'Dropdown',
            Name: 'installationconditions-holetype-dropdown',
            UIPropertyId: 10156,
            CodelistName: 'HoleType',
            DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.HoleType',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.HoleType'
        },
        {
            ControlType: 'Dropdown',
            Name: 'installationconditions-drillingaid-dropdown',
            UIPropertyId: 10157,
            CodelistName: 'DrillingAid',
            DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.DrillingAid',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.DrillingAid',
            TooltipType: TooltipType.Popup,
            Command: 'OpenDrillingAidPopup'
        },
        {
            ControlType: 'TextBox',
            Name: 'installationconditions-drillingangle',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.DrillingAngle',
            UIPropertyId: 10470,
            UnitGroup: UnitGroup.Angle,
            TooltipDisplayKey: '',
            TooltipTitleDisplayKey: '',
            DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.DrillingAngle'
        }]
    };
}

function createC2CMenu_ExistingStructure_SurfaceRoughness(localizationService: LocalizationService): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.RegionSurfaceTreatment',
        Id: 33,
        Name: 'surfaceroughness-section',
        Controls: [{
            ControlType: 'Dropdown',
            Name: 'surfacetreatment-roughness-dropdown',
            UIPropertyId: 10158,
            CodelistName: 'Roughness',
            DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.Roughness',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.Roughness',
            Command: 'OpenSurfaceRoughnessPopup',
            TooltipType: TooltipType.Popup,
            OnDropdownStateChanged: params => DesignMethodGroupHelper.updateRoughnessItemsTextForASWithASDesignMethod(params.control.items ?? [], params.design as DesignC2C, localizationService),
        }]
    };
}

function createC2CMenu_ExistingStructure_SurfaceTreatment(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.RegionSurfaceTreatment',
        Id: 37,
        Name: 'surfacetreatment-section',
        Controls: [{
            ControlType: 'Dropdown',
            Name: 'surfacetreatment-dropdown',
            UIPropertyId: 10707,
            DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.HiltiRebarRoughness',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.HiltiRebarRoughness',
            CodelistName: 'SurfaceTreatment',
            TooltipType: getPopupTooltipConditional(isACICSA(design)),
            Command: getCommandConditional('OpenSurfaceTreatmentPopup', isACICSA(design))
        }]
    };
}

function createC2CMenu_ExistingStructure_ContactSurfaceCondition(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceConditionSection',
        Id: 36,
        Name: 'contactsurfacecondition-section',
        Controls: [{
            ControlType: 'Dropdown',
            Name: 'contactsurfacecondition-dropdown',
            UIPropertyId: 10469,
            CodelistName: 'ContactSurfaceCondition',
            DisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition.CSA' : 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition',
            TitleDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition.CSA' : 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition',
            TooltipType: getPopupTooltipConditional(isACICSA(design)),
            Command: getCommandConditional('OpenContactSurfaceConditionPopup', isACICSA(design))
        },
        {
            ControlType: 'Dropdown',
            Name: 'contactsurfacecondition-aci31811-dropdown',
            UIPropertyId: 10508,
            CodelistName: 'ContactSurfaceCondition',
            DisplayKey: 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition.ACI31811',
            TitleDisplayKey: 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition.ACI31811',
            TooltipType: getPopupTooltipConditional(isACICSA(design)),
            Command: getCommandConditional('OpenContactSurfaceConditionPopup', isACICSA(design))
        }]
    };
}

function createC2CMenu_ExistingStructure_ExistingStructure(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.ExistingStructure',
        Id: 33,
        Name: 'existingstructure-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'existingstructure-mincovertop',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.ExistingStructure.MinCoverTop',
                UIPropertyId: 10159,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.ExistingStructure.MinCoverTop'
            },
            {
                ControlType: 'TextBox',
                Name: 'existingstructure-mincoverbottom',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.ExistingStructure.MinCoverBottom',
                UIPropertyId: 10160,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.ExistingStructure.MinCoverBottom'
            },
            {
                ControlType: 'TextBox',
                Name: 'existingstructure-transversepressure',
                TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.ExistingStructure.TransversePressure',
                UIPropertyId: 10161,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.ExistingStructure.TransversePressure'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.ExistingStructure.AtSupport',
                Name: 'existingstructure-atsupport',
                UIPropertyId: 10211,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            }
        ]
    };
}

function createC2CMenu_ExistingStructure_Reinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.Reinforcement',
        Id: 34,
        Name: 'reinforcement-section',
        Controls: [
            {
                CodelistName: 'ConcreteReinforcement',
                ControlType: 'Dropdown',
                Name: 'reinforcement-surfacereinforcement-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.Reinforcement.SurfaceReinforcement',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.Reinforcement.SurfaceReinforcement',
                UIPropertyId: 10162,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            }
        ]
    };
}

function createC2CMenu_ExistingStructure_FrameNode(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.FrameNode',
        Id: 35,
        Name: 'framenode-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.FrameNode.ManualEntry',
                Name: 'manualentry',
                UIPropertyId: 10163,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'manualentryparameter-input',
                TitleDisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.FrameNode.ManualEntryParameter',
                UIPropertyId: 10164,
                UnitGroup: UnitGroup.Angle,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                DisplayKey: 'Agito.Hilti.C2C.TabExistingStructure.FrameNode.ManualEntryParameter'
            }
        ]
    };
}

export function createC2CMenu_ExistingStructure_ExistingConcrete(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Title',
        Name: 'ACI-CSA-existingconcretegeometry-section',
        Id: 23,
        Controls: [
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupDisplay: ButtonGroupDisplay.Join,
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'cross-section-existing-buttons',
                UIPropertyId: 10518,
                CodelistName: 'CrossSection',
                TitleDisplayKey: 'Agito.Hilti.C2C.ExistingStructure.CrossSection',
                TooltipType: TooltipType.Normal,
                Hidden: !design.isPirHna
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Size: 50,
                Name: 'ACI-CSA-existing-cross-diameter',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Diameter',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Diameter',
                UIPropertyId: 10522,
                Hidden: !design.isPirHna
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'ACI-CSA-existing-length',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Length',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Length',
                UIPropertyId: checkConnectionType(design, ConnectionType.StructuralJoints) ? 10395 : 10396
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'ACI-CSA-existing-width',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Width',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Width',
                UIPropertyId: 10394
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'ACI-CSA-existing-height',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Height',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.Height',
                UIPropertyId: checkConnectionType(design, ConnectionType.StructuralJoints) ? 10396 : 10395
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'ACI-CSA-existing-end-cover-c1',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.EndCoverC1',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.EndCoverC1',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.EndCoverC1.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.EndCoverC1',
                UIPropertyId: 10484
            },
            {
                ControlType: 'Checkbox',
                Name: 'ACI-CSA-existing-extensionatsupport',
                UIPropertyId: 10397,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.ExtensionAtSupport',
            },
            {
                ControlType: 'Checkbox',
                Name: 'ACI-CSA-existing-extensionatintersection',
                UIPropertyId: 10398,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.ExtensionAtIntersection'
            }
        ]
    };
}
