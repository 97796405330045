import { IModelC2C } from '@profis-engineering/c2c-gl-model/gl-model';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/c2c-gl-model/base-update';

import {
    UIProperty as UIPropertyC2C
} from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';

export class ForceUpdate extends BaseUpdate {

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_ForceX)
    private Loads_ForceXC2C(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.design.X = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_ForceZ)
    private Loads_ForceZC2C(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.design.Z = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_ForceY)
    private Loads_ForceYC2C(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.design.Y = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_TedX)
    private Loads_TedXC2C(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.designShearStress.X = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_TedY)
    private Loads_TedYC2C(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.designShearStress.Y = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_StrutAndTie_N2)
    private Loads_C2C_StrutAndTie_N2(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.strutAndTieNode2.Y = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_StrutAndTie_V2)
    private Loads_C2C_StrutAndTie_V2(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.strutAndTieNode2.Z = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_StrutAndTie_M2)
    private Loads_C2C_StrutAndTie_M2(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.strutAndTieNode2.X = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_StrutAndTie_N3)
    private Loads_C2C_StrutAndTie_N3(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.strutAndTieNode3.Y = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_StrutAndTie_V3)
    private Loads_C2C_StrutAndTie_V3(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.strutAndTieNode3.Z = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_StrutAndTie_M3)
    private Loads_C2C_StrutAndTie_M3(value: number, model: IModelC2C) {
        if (!model.force?.loads) {
            throw new Error('model force loads is undefined');
        }

        model.force.loads.strutAndTieNode3.X = value;
    }
}
