import { Injectable } from '@angular/core';

import { fromService, SharedEnvironmentData } from '../entities/sharedEnvironment';

@Injectable({
    providedIn: 'root'
})
export class SharedEnvironmentService {
    public get data() {
        return this.dataInternal;
    }

    private dataInternal?: SharedEnvironmentData;

    public initialize(data: SharedEnvironmentData) {
        this.dataInternal = fromService(data);
    }
}
