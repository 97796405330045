import { Component, ElementRef, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignC2C as Design } from '../../../shared/entities/design-c2c';
import {
    ISectionCollapsing, IZoneUtilCalculated, IZoneUtilCollapsingData,
    IZoneUtilGroupSection,
    IZoneUtilGroupSectionCalculated, IZoneUtilPropertyDetail, IZoneUtilPropertyDetailCalculated,
    IZoneUtilPropertyGroup, IZoneUtilPropertyGroupCalculated, IZoneUtilPropertyGroupDetail,
    IZoneUtilPropertyGroupDetailCalculated
} from '../../../shared/entities/zone-utilizations';
import { CollapseState } from '../../../shared/enums/collapse-state';
import { CollapsingControls } from '../../../shared/enums/collapsing-controls';
import { TabType } from '../../../shared/enums/tab-type';
import { UtilizationSection } from '../../../shared/enums/utilization-section';
import {
    OverlayZoneEntityC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, ConnectionType, DesignStandard as DesignStandardC2C, LoadType, ZoneName
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { getZoneNameByName } from '../../../shared/helpers/zone-helper';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

@Component({
    templateUrl: './zone-utilizations.component.html',
    styleUrls: ['./zone-utilizations.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ZoneUtilizationsComponent implements OnInit {
    TabType = TabType;

    public zones: ZoneName[] = [];
    public baseHeader = 'Agito.Hilti.C2C.Utilizations.OverlayUtilization';
    public baseImages = {
        steel: 'sprite-tension-steel',
        pullout: 'sprite-tension-pullout',
        breakout: 'sprite-tension-breakout',
        blowout: 'sprite-tension-blowout',
        splitting: 'sprite-tension-splitting',
        shear: 'sprite-shear-stress'
    };
    // Utility function to create the details object
    public createDetail = (header: string, image: string, property: string) => ({
        header,
        image,
        property
    });
    // Base details for non-seismic section
    public nonSeismicDetails = [
        this.createDetail(`${this.baseHeader}.Shear`, this.baseImages.shear, 'shear'),
        this.createDetail(`${this.baseHeader}.UpliftForce`,
        this.baseImages.pullout, 'upliftForce')
    ];
    // Base details for seismic section
    public seismicDetails = [
        this.createDetail(`${this.baseHeader}.Shear`, this.baseImages.shear, 'shear_seismic')
    ];
    // Base details for connector design
    public connectorDesignDetails = {
        minResistance: [this.createDetail('', '', 'minGoverningResistance')],
        newConcrete: [
            this.createDetail(`${this.baseHeader}.Steel`, this.baseImages.steel, 'steelNew'),
            this.createDetail(`${this.baseHeader}.Pullout`, this.baseImages.pullout, 'pulloutNew'),
            this.createDetail(`${this.baseHeader}.ConcreteBreakout`, this.baseImages.breakout, 'concreteConeNew'),
            this.createDetail(`${this.baseHeader}.ConcreteBlowout`, this.baseImages.blowout, 'concreteBlowoutNew')
        ],
        existingConcrete: [
            this.createDetail(`${this.baseHeader}.Steel`, this.baseImages.steel, 'steelExisting'),
            this.createDetail(`${this.baseHeader}.Pullout`, this.baseImages.pullout, 'pulloutExisting'),
            this.createDetail(`${this.baseHeader}.CombinedPullout`, this.baseImages.pullout, 'combinedConcreteConePulloutExisting'),
            this.createDetail(`${this.baseHeader}.ConcreteBreakout`, this.baseImages.breakout, 'concreteConeExisting'),
            this.createDetail(`${this.baseHeader}.SplittingFailure`, this.baseImages.splitting, 'splittingFailureExisting')
        ]
    };
    // Combine all the sections with the appropriate details
    public overlayUtilization: IZoneUtilPropertyGroup = {
        image: 'sprite-rebar-icon',
        sections: [
            {
                header: `${this.baseHeader}.InterfaceVerification`,
                section: UtilizationSection.InterfaceVerification,
                groups: [
                    {
                        details: this.nonSeismicDetails,
                    },
                ],
            },
            {
                header: `${this.baseHeader}.ConnectorDesign`,
                section: UtilizationSection.ConnectorDesign,
                groups: [
                    {
                        text: `${this.baseHeader}.MinResistance`,
                        details: this.connectorDesignDetails.minResistance,
                    },
                    {
                        text: `${this.baseHeader}.NewConcrete`,
                        details: this.connectorDesignDetails.newConcrete,
                    },
                    {
                        text: `${this.baseHeader}.ExistingConcrete`,
                        details: this.connectorDesignDetails.existingConcrete,
                    },
                ],
            },
            {
                header: `${this.baseHeader}.InterfaceVerification.Seismic`,
                section: UtilizationSection.InterfaceVerification,
                groups: [
                    {
                        details: this.seismicDetails,
                    },
                ],
            },
            {
                header: `${this.baseHeader}.ConnectorDesign.Seismic`,
                section: UtilizationSection.ConnectorDesign,
                groups: [
                    {
                        text: `${this.baseHeader}.MinResistance`,
                        details: this.connectorDesignDetails.minResistance.map(
                            (detail) => ({
                                ...detail,
                                property: `${detail.property}_seismic`,
                            })
                        ),
                    },
                    {
                        text: `${this.baseHeader}.NewConcrete`,
                        details: this.connectorDesignDetails.newConcrete.map(
                            (detail) => ({
                                ...detail,
                                property: `${detail.property}_seismic`,
                            })
                        ),
                    },
                    {
                        text: `${this.baseHeader}.ExistingConcrete`,
                        details: this.connectorDesignDetails.existingConcrete.map(
                            (detail) => ({
                                ...detail,
                                property: `${detail.property}_seismic`,
                            })
                        ),
                    },
                ],
            },
        ],
    };

    public overlayCollapsing: Partial<Record<ZoneName, IZoneUtilCollapsingData>> = {};

    public overlayUtilizationsCalculated: Partial<Record<ZoneName, IZoneUtilPropertyGroupCalculated>> = {};

    private design: Design;

    private zoneCalculationResults: Partial<Record<ZoneName, OverlayZoneEntityC2C>> = {};

    constructor(
        private userService: UserService,
        private numberService: NumberService,
        private localization: LocalizationService,
        private unitService: UnitService,
        private userSettingsService: UserSettingsService,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone
    ) {
        this.design = this.userService.design;
    }

    public get zonesNumber() {
        if (this.design.designData.projectDesignC2C?.options.applicationType == ApplicationType.GenericReinforcement) {
            return 1;
        }

        return this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_NumberOfZones.id] as number;
    }

    public get zoneUtilizationsAvailable() {
        return this.design.connectionType == ConnectionType.ConcreteOverlay
            && this.design.designData.projectDesignC2C?.options.designStandard == DesignStandardC2C.ETAG
            && this.design.designData.reportDataC2C?.scopeCheckResultItems?.filter(x => x.indicatesCalculationError).length == 0
            && this.design.designData.reportDataC2C?.zonesUtilizations != null;
    }

    private get availableZones() {
        return [ZoneName.Z1, ZoneName.Z2, ZoneName.Z3, ZoneName.Z4];
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-rebar-icon',
            'sprite-shear-stress',
            'sprite-tension-steel',
            'sprite-tension-pullout',
            'sprite-tension-breakout',
            'sprite-tension-blowout'
        );

        this.design.onStateChanged(() => {
            // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
            const onStateChanged = () => {
                this.updateValues();
            };
            return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
        });

        this.updateValues();
    }

    public showDetails(zone: ZoneName, sectionIndex: number, groupIndex: number) {
        const currentData = (this.overlayCollapsing[zone] as IZoneUtilCollapsingData).sections[sectionIndex].groupDetails[groupIndex];
        (this.overlayCollapsing[zone] as IZoneUtilCollapsingData).sections[sectionIndex].groupDetails[groupIndex] = !currentData;
    }

    public collapseState(zone: ZoneName, sectionIndex: number, groupIndex: number): CollapseState {
        return (this.overlayCollapsing[zone] as IZoneUtilCollapsingData).sections[sectionIndex].groupDetails[groupIndex] ? CollapseState.Extended : CollapseState.Collapsed;
    }

    public translate(key: string | undefined) {
        if (key == undefined) {
            return undefined;
        }

        return this.localization.getString(key);
    }

    public getDetailValue(detail: IZoneUtilPropertyDetailCalculated | undefined) {
        if (detail == undefined) {
            return undefined;
        }

        if (detail.percentage === '' && detail.value != undefined) {
            return this.formatValue(detail.value);
        }

        return detail.percentage;
    }

    public getRemainingGroupDetails(calculatedDetails: IZoneUtilPropertyDetailCalculated[]) {
        return calculatedDetails.slice(1, calculatedDetails.length);
    }

    public IsInvalidValue(value: any) {
        const zoneUtilCalculated = value as IZoneUtilCalculated;
        return !zoneUtilCalculated.status;
    }

    public getCollapseComponentId(
        zone: ZoneName,
        postfix: string,
        sectionIndex?: number,
        detailGroupIndex?: number,
        detailIndex?: number
    ) {
        const componentId = this.getComponentId(zone, postfix, sectionIndex, detailGroupIndex, detailIndex);
        return `control-${componentId}-collapse`;
    }

    public getComponentId(
        zone: ZoneName,
        postfix: string,
        sectionIndex?: number,
        detailGroupIndex?: number,
        detailIndex?: number
    ) {
        let prefix = `zone${zone}`;
        if (sectionIndex != null) {
            prefix += `-section${sectionIndex + 1}`;
        }

        if (detailGroupIndex != null) {
            prefix += `-detailgroup${detailGroupIndex + 1}`;
        }

        if (detailIndex != null) {
            prefix += `-detail${detailIndex + 1}`;
        }

        return postfix != '' ? `${prefix}-${postfix}` : prefix;
    }

    public getFirstGroupDetail(calculatedDetails: IZoneUtilPropertyDetailCalculated[], isExpanded: boolean) {
        if (isExpanded) {
            return calculatedDetails[0];
        }

        return calculatedDetails.find(x => x.isWorst);
    }

    public getWorstDetailPercentage(calculatedDetails: IZoneUtilPropertyDetailCalculated[]) {
        if (this.hasPercentageInGroup(calculatedDetails)) {
            return calculatedDetails.filter(x => x.percentage !== '').find(x => x.isWorst)?.value;
        }

        return null;
    }

    public hasPercentage(value: any) {
        const zoneUtilCalculated = value as IZoneUtilCalculated;
        return zoneUtilCalculated?.percentage !== '';
    }

    public hasUtilizationValue(value: any) {
        const zoneUtilCalculated = value as IZoneUtilCalculated;
        return zoneUtilCalculated?.value;
    }

    public hasPercentageInGroup(calculatedDetails: IZoneUtilPropertyDetailCalculated[]) {
        const calculatedDetailsFiltered = calculatedDetails.filter(x => x.percentage !== '');

        return (calculatedDetailsFiltered.length > 0);
    }

    public getUtilizationCalculatedPerZone(zone: ZoneName): IZoneUtilGroupSectionCalculated | undefined {
        const sections = this.overlayUtilizationsCalculated[zone]?.sections as IZoneUtilGroupSectionCalculated[] | undefined;
        return (sections?.filter(x => x.percentage !== ''))?.reduce((a, b) => (a.value as number) > (b.value as number) ? a : b);
    }

    public onCollapseChanged(collapsed: boolean, zone: ZoneName) {
        const sectionId = this.getZoneCollapsingControl(zone);
        (this.overlayCollapsing[zone] as IZoneUtilCollapsingData).header = collapsed;
        this.userSettingsService.setSectionCollapsed(sectionId, collapsed);
    }

    public onSectionCollapseChanged(collapsed: boolean, zone: ZoneName, index: number, section: UtilizationSection) {
        const collapsingControl = this.getSectionCollapsingControl(section, zone);
        (this.overlayCollapsing[zone] as IZoneUtilCollapsingData).sections[index].header = collapsed;
        this.userSettingsService.setSectionCollapsed(collapsingControl, collapsed);
    }

    public getZoneCollapsingControl(zone: ZoneName): CollapsingControls {
        switch (zone) {
            case ZoneName.Z1:
                return CollapsingControls.OverlayZone1Utilization;
            case ZoneName.Z2:
                return CollapsingControls.OverlayZone2Utilization;
            case ZoneName.Z3:
                return CollapsingControls.OverlayZone3Utilization;
            case ZoneName.Z4:
                return CollapsingControls.OverlayZone4Utilization;
        }

        throw new Error('Cannot get collapsing control id of unknown zone.');
    }

    private formatValue(value: number) {
        if (value == Number.POSITIVE_INFINITY || value == Number.NEGATIVE_INFINITY) {
            return this.numberService.format(value);
        }
        else {
            const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Force);
            const internalUnit = this.unitService.getInternalUnit(UnitGroup.Force);
            const defaultPrecision = this.unitService.getPrecision(defaultUnit);
            const valueToFormat = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

            return this.unitService.formatNumber(valueToFormat, defaultPrecision) + ' ' + this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Force));
        }
    }

    private getValueProperties(zone: ZoneName, property: string) {
        const utilizationItem = this.zoneCalculationResults[zone]?.utilizations.find(obj => {
            return obj.name === property;
        });

        if (utilizationItem == null) {
            return null;
        }

        const utilizationValue = utilizationItem.value;

        return {
            status: utilizationValue?.status,
            percentage: utilizationValue?.percentage,
            value: parseFloat(utilizationValue?.value ?? '0')
        };
    }

    private getCalculatedGroup(zone: ZoneName, groups: IZoneUtilPropertyGroupDetail[]): IZoneUtilPropertyGroupDetailCalculated[] {
        const groupsCalculated: IZoneUtilPropertyGroupDetailCalculated[] = [];
        for (const group of groups) {
            const detailsCalculated = this.getPropertyCalculatedDetails(zone, group.details);

            if (detailsCalculated.length > 0) {
                const worst = detailsCalculated.reduce((a, b) => (a.value as number) > (b.value as number) ? a : b);

                groupsCalculated.push({
                    details: detailsCalculated,
                    text: group.text,
                    percentage: worst.percentage,
                    status: worst.status,
                    value: worst.value
                });
            }
        }

        return groupsCalculated;
    }

    private getPropertyCalculatedDetails(zone: ZoneName, details: IZoneUtilPropertyDetail[]): IZoneUtilPropertyDetailCalculated[] {
        const calculatedDetails: IZoneUtilPropertyDetailCalculated[] = [];
        for (const detail of details) {
            const valueProps = this.getValueProperties(zone, detail.property);

            if (valueProps != null) {
                calculatedDetails.push({
                    header: detail.header,
                    image: detail.image,
                    property: detail.property,
                    isWorst: false,
                    percentage: valueProps.percentage,
                    value: valueProps.value,
                    status: valueProps.status
                });
            }
        }

        this.flagWorstCalculatedDetail(calculatedDetails);

        return calculatedDetails;
    }

    private flagWorstCalculatedDetail(details: IZoneUtilPropertyDetailCalculated[]) {
        if (details.length > 0) {
            const worst = details.reduce((a, b) => (a.value as number) > (b.value as number) ? a : b);

            const index = details.findIndex(x => x.property == worst.property);

            details[index].isWorst = true;
        }
    }

    private initConcreteCollapsing() {
        for (const zone of this.zones) {
            if (this.overlayCollapsing[zone] == undefined) {
                const sectionId = this.getZoneCollapsingControl(zone);
                this.overlayCollapsing[zone] = {
                    header: this.userSettingsService.isSectionCollapsed(sectionId),
                    sections: this.getInitSectionCollapsingArray(zone)
                };
            }
        }
    }

    private getInitSectionCollapsingArray(zone: ZoneName) {
        const sections: ISectionCollapsing[] = [];
        for (const section of this.overlayUtilization.sections) {
            const controlId = this.getSectionCollapsingControl(section.section, zone);
            sections.push({
                header: this.userSettingsService.isSectionCollapsed(controlId),
                section: section.section,
                groupDetails: section.groups.map(() => false)
            });
        }
        return sections;
    }

    private updateValues() {
        this.zones = this.availableZones.splice(0, this.zonesNumber);
        this.initConcreteCollapsing();
        if (this.zoneUtilizationsAvailable) {
            this.setZoneCalculationResults();
            this.setUtilizationCalculated();
        }
    }

    private setZoneCalculationResults() {
        this.design.designData.reportDataC2C?.zonesUtilizations?.forEach((zoneResult) => {
            const currentZone = getZoneNameByName(zoneResult.name);
            this.zoneCalculationResults[currentZone] = zoneResult;
        });
    }

    private getSectionCollapsingControl(section: UtilizationSection, zone: ZoneName): CollapsingControls {
        return section == UtilizationSection.ConnectorDesign
            ? this.getConnectorDesignCollapsingControl(zone)
            : this.getInterfaceDesignCollapsingControl(zone);
    }

    private getConnectorDesignCollapsingControl(zone: ZoneName): CollapsingControls {
        switch (zone) {
            case ZoneName.Z1:
                return CollapsingControls.OverlayZone1ConnectorDesign;
            case ZoneName.Z2:
                return CollapsingControls.OverlayZone2ConnectorDesign;
            case ZoneName.Z3:
                return CollapsingControls.OverlayZone3ConnectorDesign;
            case ZoneName.Z4:
                return CollapsingControls.OverlayZone4ConnectorDesign;
        }

        throw new Error('Cannot get connector design collapsing control for unsupported zone.');
    }

    private getInterfaceDesignCollapsingControl(zone: ZoneName): CollapsingControls {
        switch (zone) {
            case ZoneName.Z1:
                return CollapsingControls.OverlayZone1InterfaceUtilization;
            case ZoneName.Z2:
                return CollapsingControls.OverlayZone2InterfaceUtilization;
            case ZoneName.Z3:
                return CollapsingControls.OverlayZone3InterfaceUtilization;
            case ZoneName.Z4:
                return CollapsingControls.OverlayZone4InterfaceUtilization;
        }

        throw new Error('Cannot get interface design collapsing control for unsupported zone.');
    }

    private setUtilizationCalculated() {
        for (const zone of this.zones) {
            const sectionsCalculated: IZoneUtilGroupSectionCalculated[] = [];
            for (const section of this.overlayUtilization.sections) {
                const groupsCalculated = this.getCalculatedGroup(zone, section.groups);

                this.getTranslationForSeismic(groupsCalculated, section);

                // Dinamically set the header and image for the minimum tensile resistance section
                const minResistanceGroup = groupsCalculated.filter(x => x.text == 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.MinResistance') ?? [];
                if (minResistanceGroup.length > 0) {
                    const minResistanceDiffGroup = groupsCalculated.filter(x => x.text != 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.MinResistance') ?? [];
                    if (minResistanceDiffGroup.length > 0) {
                        minResistanceDiffGroup.forEach(element => {
                            const minResistanceDiffGroupDetail = element.details.filter(x => x.value == minResistanceGroup[0].details[0].value);
                            if (minResistanceDiffGroupDetail.length > 0) {
                                minResistanceGroup[0].details[0].header = minResistanceDiffGroupDetail[0].header;
                                minResistanceGroup[0].details[0].image = minResistanceDiffGroupDetail[0].image;

                                return;
                            }
                        });
                    }
                }

                if (groupsCalculated.length > 0) {
                    const worst = groupsCalculated.reduce((a, b) => (a.value as number) > (b.value as number) ? a : b);

                    const sectionCalculated: IZoneUtilGroupSectionCalculated = {
                        section: section.section,
                        control: this.getSectionCollapsingControl(section.section, zone),
                        groups: groupsCalculated,
                        header: section.header,
                        percentage: worst.percentage,
                        status: worst.status,
                        value: worst.value == Infinity ? 100 : worst.value
                    };

                    sectionsCalculated.push(sectionCalculated);
                }
            }

            if (sectionsCalculated.length > 0) {
                const worst = sectionsCalculated.reduce((a, b) => (a.value as number) > (b.value as number) ? a : b);
                this.overlayUtilizationsCalculated[zone] = {
                    image: this.overlayUtilization.image,
                    sections: sectionsCalculated,
                    percentage: worst.percentage,
                    status: worst.status,
                    value: worst.value == Infinity ? 100 : worst.value
                };
            }
        }
    }

    private getTranslationForSeismic(groupsCalculated: IZoneUtilPropertyGroupDetailCalculated[], section: IZoneUtilGroupSection) {
        for (const group of groupsCalculated) {
            const seismicFlag = group.details.find(x => x.property.includes('_seismic')) ? true : false;
            if (section.section == UtilizationSection.InterfaceVerification) {
                section.header = this.getTranslationKeyInterfaceVerification(seismicFlag);
            }

            if (section.section == UtilizationSection.ConnectorDesign) {
                section.header = this.getTranslationKeyConnectorDesign(seismicFlag);
            }
        }
    }

    private getTranslationKeyInterfaceVerification(seismicFlag: boolean) {
        if (seismicFlag)
            return 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.InterfaceVerification.Seismic';

        return this.design.projectDesign?.loads.loadType == LoadType.Seismic && this.design.projectDesign?.concreteBlockDefinition.consideringEdgeArea
        ? 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.InterfaceVerification.Static' : 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.InterfaceVerification';
    }

    private getTranslationKeyConnectorDesign(seismicFlag: boolean) {
        if (seismicFlag)
            return 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ConnectorDesign.Seismic';

        return this.design.projectDesign?.loads.loadType == LoadType.Seismic && this.design.projectDesign?.concreteBlockDefinition.consideringEdgeArea
            ? 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ConnectorDesign.Static' : 'Agito.Hilti.C2C.Utilizations.OverlayUtilization.ConnectorDesign';
    }
}
