<div class="content-group" *ngIf="dataLoaded">
    <div class="content default-application">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.DefaultApplication')">
        </p>
        <div class="content-controls calculation-method">
            <pe-radio-button
                class="control"
                [title]="applicationStateControl.title"
                [items]="applicationStateControl.items"
                [selectedValue]="applicationStateControl.selectedValue"
                (selectedValueChange)="applicationStateControl.selectedValue = $any($event).detail"
                [disabled]="submitted">
            </pe-radio-button>
        </div>
    </div>

    <div class="content design-region">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.General')"></p>

        <div class="content-controls">
            <pe-dropdown
                [id]="regionDropdownC2C.id"
                [items]="regionDropdownC2C.items"
                [selectedValue]="c2cFixing.regionC2C"
                (selectedValueChange)="onRegionDropdownChangeC2C($any($event).detail)"
                [title]="regionDropdownC2C.title"
                class="control"
                [disabled]="submitted">
            </pe-dropdown>

            <div class="design-type-status control-row info">
                <p [class.error]="!allowC2CQuickStart">{{allowC2CStatus}}</p>
            </div>
        </div>
        <div class="content-controls">
            <span class="local-steel-material-disclaimer"
                [innerHtml]="translate('Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer')"></span>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="allowC2CQuickStart && isC2CNone">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.Units')"></p>

        <div class="content-controls">
            <pe-dropdown
                [id]="lengthDropdownC2C.id"
                [items]="lengthDropdownC2C.items"
                [selectedValue]="c2cFixing.lengthC2C"
                (selectedValueChange)="c2cFixing.lengthC2C = $any($event).detail"
                [title]="lengthDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown
                [id]="areaDropdownC2C.id"
                [items]="areaDropdownC2C.items"
                [selectedValue]="c2cFixing.areaC2C"
                (selectedValueChange)="c2cFixing.areaC2C = $any($event).detail"
                [title]="areaDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown
                [id]="stressDropdownC2C.id"
                [items]="stressDropdownC2C.items"
                [selectedValue]="c2cFixing.stressC2C"
                (selectedValueChange)="c2cFixing.stressC2C = $any($event).detail"
                [title]="stressDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown
                [id]="forceDropdownC2C.id"
                [items]="forceDropdownC2C.items"
                [selectedValue]="c2cFixing.forceC2C"
                (selectedValueChange)="c2cFixing.forceC2C = $any($event).detail"
                [title]="forceDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown
                [id]="momentDropdownC2C.id"
                [items]="momentDropdownC2C.items"
                [selectedValue]="c2cFixing.momentC2C"
                (selectedValueChange)="c2cFixing.momentC2C = $any($event).detail"
                [title]="momentDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown
                [id]="temperatureDropdownC2C.id"
                [items]="temperatureDropdownC2C.items"
                [selectedValue]="c2cFixing.temperatureC2C"
                (selectedValueChange)="c2cFixing.temperatureC2C = $any($event).detail"
                [title]="temperatureDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown
                [id]="forcePerLengthDropdownC2C.id"
                [items]="forcePerLengthDropdownC2C.items"
                [selectedValue]="c2cFixing.forcePerLengthC2C"
                (selectedValueChange)="c2cFixing.forcePerLengthC2C = $any($event).detail"
                [title]="forcePerLengthDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown
                [id]="momentPerLengthDropdownC2C.id"
                [items]="momentPerLengthDropdownC2C.items"
                [selectedValue]="c2cFixing.momentPerLengthC2C"
                (selectedValueChange)="c2cFixing.momentPerLengthC2C = $any($event).detail"
                [title]="momentPerLengthDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown
                [id]="densityDropdownC2C.id"
                [items]="densityDropdownC2C.items"
                [selectedValue]="c2cFixing.densityC2C"
                (selectedValueChange)="c2cFixing.densityC2C = $any($event).detail"
                [title]="densityDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown
                [id]="areaPerLengthDropdownC2C.id"
                [items]="areaPerLengthDropdownC2C.items"
                [selectedValue]="c2cFixing.areaPerLengthC2C"
                (selectedValueChange)="c2cFixing.areaPerLengthC2C = $any($event).detail"
                [title]="areaPerLengthDropdownC2C.title"
                class="control" [disabled]="submitted">
            </pe-dropdown>
        </div>
    </div>

    <div class="content calculation" *ngIf="allowC2CQuickStart && isC2CNone && isOverlayEnabled">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.OverlayDesign')"></p>

        <div class="content-controls">
            <div class="design-standard control-row column">
                <div class="content">
                    <pe-dropdown [id]="overlayDesignStandardDropdownC2C.id"
                        [items]="overlayDesignStandardDropdownC2C.items"
                        [selectedValue]="overlayDesignStandardDropdownC2C.selectedValue"
                        (selectedValueChange)="onOverlayDesignStandardChangeC2C($any($event).detail)"
                        [title]="overlayDesignStandardDropdownC2C.title"
                        class="control control-design-standard-method" [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-standard-note"
                        *ngIf="overlayDesignStandardDropdownC2C.selectedValue != null"
                        [innerHtml]="translate(overlayDesignStandardDescriptionC2C)"></div>
                </div>
            </div>

            <div class="design-method control-row column">
                <div class="content">
                    <pe-dropdown [id]="overlayDesignMethodDropdownC2C.id"
                        [items]="overlayDesignMethodDropdownC2C.items"
                        [selectedValue]="overlayDesignMethodDropdownC2C.selectedValue"
                        (selectedValueChange)="onOverlayDesignMethodChangeC2C($any($event).detail)"
                        [title]="overlayDesignMethodDropdownC2C.title"
                        class="control control-design-standard-method"
                        [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-method-note"
                        *ngIf="selectedC2COverlayDesignMethodDescription != null"
                        [innerHtml]="translate(selectedC2COverlayDesignMethodDescription)"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="content calculation" *ngIf="allowC2CPirQuickStart && isC2CNone && !overlayOnlyC2C">
        <p class="title-strike" [innerHtml]="translate(designSettingsTitleC2C)"></p>

        <div class="content-controls">
            <div class="design-standard control-row column">
                <div class="content">
                    <pe-dropdown [id]="designStandardDropdownC2C.id"
                        [items]="designStandardDropdownC2C.items"
                        [selectedValue]="designStandardDropdownC2C.selectedValue"
                        (selectedValueChange)="onDesignStandardChangeC2C($any($event).detail)"
                        [title]="designStandardDropdownC2C.title" class="control control-design-standard-method"
                        [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-standard-note" *ngIf="designStandardDropdownC2C.selectedValue != null"
                        [innerHtml]="translate(designStandardDescriptionC2C)"></div>
                </div>
            </div>

            <div class="design-method control-row column">
                <div class="content" *ngIf="isExtensionsAndJointsDesignMethodVisible">
                    <pe-dropdown [id]="designMethodDropdownC2C.id"
                        [items]="designMethodDropdownC2C.items"
                        [selectedValue]="designMethodDropdownC2C.selectedValue"
                        (selectedValueChange)="onDesignMethodChangeC2C($any($event).detail)"
                        [title]="designMethodDropdownC2C.title"
                        class="control control-design-standard-method"
                        [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-method-note"
                        *ngIf="selectedC2CDesignMethodDescription != null"
                        [innerHtml]="translate(selectedC2CDesignMethodDescription)"></div>
                </div>
            </div>
        </div>
    </div>
</div>
