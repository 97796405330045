import clone from 'lodash-es/clone';

import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { SafetyFactorRegion } from '../../../shared/entities/code-lists/safety-factor-region';
import { StrutTies } from '../../../shared/entities/code-lists/strut-ties';
import { IStrutTiesInputsData } from '../../../shared/entities/strut-ties-inputs-data';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ConnectionType
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { defaultUiPropertyValue } from '../../../shared/helpers/ui-property-helpers';
import { IPropertyMetaDataC2C, PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';

@Component({
    templateUrl: './strut-ties-model-calculation.component.html',
    styleUrls: ['./strut-ties-model-calculation.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class StrutTiesModelCalculationComponent implements OnInit, DoCheck {
    @Input()
    public data!: IStrutTiesInputsData;

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    public strutTies!: DropdownProps<StrutTies>;
    public strutTiesInput!: NumericTextBoxProps;
    public strutTiesItems!: DropdownItem<number>[];
    public concreteResistanceCompression!: NumericTextBoxProps;
    public dataLoaded = false;

    private oldRegionId!: number;
    private oldData!: IStrutTiesInputsData;

    constructor(
        private localizationService: LocalizationService,
        private codeList: CodeListService,
        private unit: UnitService
    ) { }

    ngOnInit(): void {
        this.populateDropdownItems();
        this.initSafetyFactorsControls();
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setDefaultValues();
            this.refreshControls();

            this.dataLoaded = true;
        }

        this.updateData();
    }

    private updateData() {
        let update = false;

        if (this.oldRegionId != this.regionId) {
            this.refreshControls();

            this.oldRegionId = this.regionId;
        }

        if (this.oldData != null) {
            if (this.oldData.connectionType != this.data.connectionType) {
                update = true;
                this.refreshControls();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);
        }
    }

    private updateControl(control: NumericTextBoxProps, propertyMetaData: IPropertyMetaDataC2C, defaultValue: number, unit: Unit, defaultUnit: Unit) {
        const propertyValue = this.codeList.getPropertyValue(propertyMetaData.id, this.regionId, this.data.connectionType ?? ConnectionType.Unknown);

        control.maxValue = propertyValue.maxValue;
        control.minValue = propertyValue.minValue;
        control.placeholder = this.getTextBoxPlaceholder(defaultValue, unit, defaultUnit);
        control.unit = unit;
        control.precision = this.unit.getPrecision(unit, propertyMetaData.id);
    }

    private updateControlDropdown(control: DropdownProps<any>, defaultValue: number | undefined) {
        control.selectedValue = defaultValue;

        if (this.data.addEditType == AddEditType.add)
            this.data.strutTies = defaultValue;
    }

    private setDefaultValues() {
        if (this.data.design != null) {
            const design = this.data.design();
            this.data.strutTies = design.strutTies;
            this.data.strutTiesInput = design.strutTiesInput;
            this.data.concreteResistanceCompression = design.concreteResistanceCompression;
        }
    }

    private refreshControls() {
        this.updateControlDropdown(this.strutTies, defaultUiPropertyValue(UIProperty.Option_C2C_StrutTies, this.codeList, this.regionId));
        this.updateControl(this.strutTiesInput, PropertyMetaDataC2C.Option_C2C_StrutTiesInput, defaultUiPropertyValue(UIProperty.Option_C2C_StrutTiesInput, this.codeList, this.regionId) as number, Unit.degree, Unit.rad);
        this.updateControl(this.concreteResistanceCompression, PropertyMetaDataC2C.Option_C2C_K2, this.defaultK2 ?? 0, Unit.None, Unit.None);
    }

    private get defaultK2() {
        const safetyFactors = this.codeList.projectCodeListsC2C[ProjectCodeList.SafetyFactorRegionC2C] as SafetyFactorRegion[];
        return safetyFactors.find(sf => sf.regionId == this.regionId)?.k2;
    }

    private initSafetyFactorsControls() {
        this.strutTies = {
            id: 'strut-and-ties-model-calculation-parameter-strutAngle-dropdown',
            title: this.translate('Agito.Hilti.C2C.StrutTiesModelCalculation.StrutTies'),
        };

        this.strutTiesInput = {
            id: 'strut-and-ties-model-calculation-parameter-strutAngle-textbox',
            title: this.translate('Agito.Hilti.C2C.StrutTiesModelCalculation.StrutTiesInput'),
            unit: Unit.degree
        };

        this.concreteResistanceCompression = {
            id: 'concrete-material-K2',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.ResistanceCompression'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.ResistanceCompression'), content: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.ResistanceCompression.Tooltip') }
        };
    }

    private getTextBoxPlaceholder(value: number, unit: Unit, defaultUnit: Unit) {
        const defaultPrecision = this.unit.getPrecision(unit);

        const result = this.unit.formatUnitValueArgs(
            this.unit.convertUnitValueArgsToUnit(
                value,
                defaultUnit,
                unit
            ),
            unit,
            defaultPrecision
        );

        if (result == null) {
            return this.translate('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }

        return result;
    }

    private populateDropdownItems() {
        const strutTies = this.codeList.projectCodeListsC2C[ProjectCodeList.StrutTiesC2C] as StrutTies[];

        this.strutTiesItems = strutTies.map(item => ({
            value: item.id,
            text: this.translate(`Agito.Hilti.C2C.CodeList.StrutTiesEntityC2C.${item.displayKey}`)
        }) as DropdownItem<number>);
    }

    public strutTiesChanged(value: number) {
        this.data.strutTies = value;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

}
