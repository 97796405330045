import anchorTypeBonding from './AnchorType_Bonding.png';
import anchorTypeFriction from './AnchorType_Friction.png';
import anchorTypeKeying from './AnchorType_Keying.png';
import cleaningAutomatic from './Cleaning_Automatic.png';
import cleaningCompressedAir from './Cleaning_CompressedAir.png';
import cleaningManual from './Cleaning_Manual.png';
import corrosionMaterialWarning from './CorrosionMaterial_Warning.png';
import threadedExternal from './Threaded_External.png';
import threadedInternal from './Threaded_Internal.png';

const images = {
    'AnchorType_Bonding.png': anchorTypeBonding,
    'AnchorType_Friction.png': anchorTypeFriction,
    'AnchorType_Keying.png': anchorTypeKeying,
    'Cleaning_Automatic.png': cleaningAutomatic,
    'Cleaning_CompressedAir.png': cleaningCompressedAir,
    'Cleaning_Manual.png': cleaningManual,
    'CorrosionMaterial_Warning.png': corrosionMaterialWarning,
    'Threaded_External.png': threadedExternal,
    'Threaded_Internal.png': threadedInternal
} satisfies Record<string, string>;

export default images as typeof images & { [imageName: string]: string };
