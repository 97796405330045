import { Injectable } from '@angular/core';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { NumericalParameterC2C } from '../../shared/entities/translation-parameters';
import {
    ConstantParameterC2C, TemplateParameterC2C, TranslatableParameterC2C, TranslationFormatC2C,
    TranslationParameterC2C
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    TranslationParameterType as TranslationParameterTypeC2C
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { LocalizationService } from './localization.service';
import { UnitService } from './unit.service';

@Injectable({
    providedIn: 'root'
})
export class TranslationFormatService {
    constructor(
        private localizationService: LocalizationService,
        private unitService: UnitService
    ) { }

    public getLocalizedStringWithTranslationFormat(
        translationFormat?: TranslationFormatC2C,
        roundValue = true,
        preTransformedParams?: Record<string, string>
    ): string | undefined {
        if (translationFormat == null) {
            return undefined;
        }

        let localizedString = '';

        // Transforms the translation parameters array into a JSON object.
        const transformedParams = this.transformTranslationParameters(translationFormat.translationParameters, roundValue, preTransformedParams);

        // Gets the template from the attribute or checks the template object (recursive call, because it's a nested object of the same type).
        const translation = translationFormat.template != null
            ? translationFormat.template
            : this.getLocalizedStringWithTranslationFormat(translationFormat.templateFormat, roundValue);

        // Sets the values based on the template and parameter array.
        if (translation == null) {
            translationFormat.translationParameters.forEach(key => {
                transformedParams[key.name] = transformedParams[key.name].split('{').join(`{${key.name}_`);
                localizedString += formatKeyValue(`{${key.name}}`, transformedParams) + '\n';
            });
        }
        else {
            localizedString = formatKeyValue(translation, transformedParams);
        }

        return localizedString.trimEnd();
    }

    public transformTranslationParameters(
        translationParameters: TranslationParameterC2C[],
        roundValue: boolean,
        preTransformedParams?: Record<string, string>,
        customParameterTransformation?: (parameter: TranslationParameterC2C, roundValue: boolean) => string | undefined | null
    ): Record<string, string> {
        const keys: Record<string, string> = {};

        translationParameters.forEach((parameter) => {
            let handled = false;

            if (preTransformedParams != null && parameter.name in preTransformedParams) {
                keys[parameter.name] = preTransformedParams[parameter.name];
                handled = true;
            }
            else if (customParameterTransformation != null) {
                const value = customParameterTransformation(parameter, roundValue);
                if (value != null) {
                    keys[parameter.name] = value;
                    handled = true;
                }
            }

            if (!handled) {
                if (parameter.parameterType == TranslationParameterTypeC2C.Numerical) {
                    const numericalParameter = parameter as NumericalParameterC2C;

                    let value: string;
                    if (numericalParameter.value == null) {
                        value = '';
                    }
                    else {
                        const defaultPrecision = this.unitService.getDefaultPrecision();

                        // The number might not have a unit
                        if (numericalParameter.unitGroup != null) {
                            const internalUnit = this.unitService.getInternalUnit(numericalParameter.unitGroup);
                            const defaultUnit = this.unitService.getDefaultUnit(numericalParameter.unitGroup);
                            const precision = (roundValue == false)
                                ? defaultPrecision
                                : this.unitService.getPrecision(defaultUnit) + (numericalParameter.additionalPrecision ?? 0);
                            let unitValueNumber = this.unitService.convertUnitValueArgsToUnit(numericalParameter.value, internalUnit, defaultUnit);
                            if (typeof unitValueNumber === 'string') {
                                unitValueNumber = this.unitService.parseNumber(unitValueNumber);
                            }
                            value = this.unitService.formatUnitValueArgs(unitValueNumber, defaultUnit, precision);
                        }
                        else {
                            const precision = (roundValue == false)
                                ? defaultPrecision
                                : this.unitService.getPrecision(Unit.None) + (numericalParameter.additionalPrecision ?? 0);
                            value = this.unitService.formatNumber(numericalParameter.value, precision);
                        }
                    }

                    keys[parameter.name] = value;
                }
                else if (parameter.parameterType == TranslationParameterTypeC2C.Translatable) {
                    const translatableParameter = parameter as TranslatableParameterC2C;

                    let translated: string;
                    if (translatableParameter.value == null || translatableParameter.value.trim() == '') {
                        translated = '';
                    }
                    else {
                        translated = this.localizationService.getString(translatableParameter.value);
                    }

                    keys[parameter.name] = translated;
                }
                else if (parameter.parameterType == TranslationParameterTypeC2C.Constant) {
                    const constantParameter = parameter as ConstantParameterC2C;

                    keys[parameter.name] = constantParameter.value;
                }
                else if (parameter.parameterType == TranslationParameterTypeC2C.Template) {
                    const templateParameter = parameter as TemplateParameterC2C;

                    keys[parameter.name] = this.getLocalizedStringWithTranslationFormat(templateParameter.value, roundValue) ?? '';
                }
                else {
                    throw new Error('Unknown parameter type');
                }
            }
        });

        return keys;
    }
}
