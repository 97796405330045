import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import {
    RenderType, View2dModeType
} from '@profis-engineering/gl-model/gl-model';
import { IPointResult } from '@profis-engineering/gl-model/components/base-component';
import { GLEvent, GLModelBaseComponent } from './GLModelBase';
import { CanvasContext3d } from '@profis-engineering/gl-model/canvas-context-3d';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { NumberService } from '../../services/number.service';
import { UserService } from '../../services/user.service';
import { MathService } from '../../services/math.service';
import { TooltipService } from '../../services/tooltip.service';
import { ModalService } from '../../services/modal.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import {
    getText2DFromGlobal, getText2DString, isText2DEnabled, updateText2D
} from '@profis-engineering/gl-model/consoleUpdatePropertyMode';
import { IdValuePair, RebarPointBase2dC2C } from '@profis-engineering/c2c-gl-model/components/base-rebar-helper';
import { getOrCreateContext3d } from '@profis-engineering/c2c-gl-model/context-3d';
import { environment } from '../../../environments/environmentC2C';
import { IGlModelC2CComponent } from '../../../shared/components/gl-model';
import { Change, Update } from '@profis-engineering/gl-model/base-update';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { BaseMaterialsC2CUpdate } from './Update/BaseMaterialsC2CUpdate';
import { CommonDimensionsC2CUpdate } from './Update/CommonDimensionsC2CUpdate';
import { CoordinateSystemUpdate } from './Update/CoordinateSystemUpdate';
import { ForceUpdate } from './Update/ForceUpdate';
import { MomentUpdate } from './Update/MomentUpdate';
import { RebarUpdate } from './Update/RebarUpdate';
import { ReinforcementUpdate } from './Update/ReinforcementUpdate';
import { ZonesC2CUpdate } from './Update/ZonesC2CUpdate';
import { SceneCoordinateSystemUpdate } from './Update/SceneCoordinateSystemUpdate';
import { Center2dUpdate } from './Update/2D/Center2dUpdate';
import { GLModelC2C, GLModelUpdateC2C, IModelC2C, IScreenShotSettingsC2C } from '@profis-engineering/c2c-gl-model/gl-model';

import existingStructureConcrete from '@profis-engineering/gl-model/images/existing-concrete.jpg';
import fontTexture from '@profis-engineering/gl-model/text/default-font-texture';
import newStructureConcrete from '@profis-engineering/gl-model/images/new-concrete.jpg';
import reinforcement from '@profis-engineering/gl-model/images/rebar.jpg';
import { GLImages } from '@profis-engineering/gl-model/images';
import { MaterialCacheC2C } from '@profis-engineering/c2c-gl-model/cache/material-cache';
import { MeshCacheC2C } from '@profis-engineering/c2c-gl-model/cache/mesh-cache';

export interface IPointsEditorPoint {
    id: number;
    x: number;
    y: number;
}

const enum Stepper {
    increment,
    decrement
}

@Component({
    templateUrl: './gl-model.component.html',
    styleUrls: ['./gl-model.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class GlModelComponent extends GLModelBaseComponent<GLModelC2C> implements OnDestroy, IGlModelC2CComponent {

    @Input()
    public zoomToFit = (extraInfo: IScreenShotSettingsC2C) => this.zoomToFitInternal(extraInfo);
    @Input()
    public update = (model: IModelC2C, replace?: boolean, changeDetection?: boolean) => this.updateInternal(model, replace, changeDetection);
    @Input()
    public getModel = () => this.getModelInternal();
    @Input()
    public clearSelected = () => this.clearSelectedInternal();
    @Input()
    public movePirPoint2dC2C = (index: number, x: number, y: number, diameter: IdValuePair, bond: number) => this.movePirPoint2dC2CInternal(index, x, y, diameter, bond);
    @Input()
    public deletePirPoint2dC2C = (index: number) => this.deletePirPoint2dC2CInternal(index);
    @Input()
    public addPirPoint2dC2C = (point: RebarPointBase2dC2C) => this.addPirPoint2dC2CInternal(point);
    @Input()
    public addPoint2dC2C = (point: RebarPointBase2dC2C) => this.addPoint2dC2CInternal(point);
    @Input()
    public movePoint2dC2C = (index: number, x: number, y: number, diameter: IdValuePair, shape: number, bond: number) => this.movePoint2dC2CInternal(index, x, y, diameter, shape, bond);
    @Input()
    public deletePoint2dC2C = (index: number) => this.deletePoint2dC2CInternal(index);
    @Input()
    public resizeNextFrame = (which?: number, renderCount?: number) => this.resizeNextFrameInternal(which, renderCount);
    @Input()
    public resetCamera = () => this.resetCameraInternal();
    @Input()
    public createDesignScreenshot = (extraInfo: IScreenShotSettingsC2C) => this.createDesignScreenshotInternal(extraInfo);
    @Input()
    public createDesignScreenshot2D = (extraInfo: IScreenShotSettingsC2C, view2dMode: View2dModeType) => this.createDesignScreenshot2DInternal(extraInfo, view2dMode);
    @Input()
    public propertyValueChanged = (changes: Change[], design: DesignC2C, update: Update, model?: IModelC2C) => this.propertyValueChangedInternal(changes, design, update, model);
    @Input()
    public resetHighlightedComponents = () => this.resetHighlightedComponentsInternal();
    @Input()
    public cameraZoomIn = () => this.cameraZoomInInternal();
    @Input()
    public cameraZoomOut = () => this.cameraZoomOutInternal();
    @Input()
    public cameraZoom = (percentage: number) => this.cameraZoomInternal(percentage);

    @Output()
    public fontsLoaded = new EventEmitter<void>();

    @Output()
    public zoom = new EventEmitter<number>();

    @Output()
    public selectTab = new EventEmitter<string>();

    @Output()
    public positionsChanged = new EventEmitter<Record<string, boolean>>();

    @Output()
    public draggingSelectionChanged = new EventEmitter<boolean>();

    @Output()
    public rebarClicked = new EventEmitter<boolean>();

    public Stepper: Record<keyof typeof Stepper, Stepper> = {
        increment: Stepper.increment,
        decrement: Stepper.decrement
    };

    private textEditor!: HTMLInputElement | undefined;

    constructor(
        unitService: UnitService,
        numberService: NumberService,
        localizationService: LocalizationService,
        userService: UserService,
        mathService: MathService,
        tooltipService: TooltipService,
        modalService: ModalService,
        userSettingsService: UserSettingsService,
        calculationServiceC2C: CalculationServiceC2C,
        ngZone: NgZone,
        changeDetection: ChangeDetectorRef,
        private elementRef: ElementRef<HTMLElement>
    ) {
        super(
            unitService,
            numberService,
            localizationService,
            userService,
            mathService,
            tooltipService,
            modalService,
            userSettingsService,
            calculationServiceC2C,
            ngZone,
            changeDetection
        );

        if (typeof window == 'object') {
            (window as any).updateText2D = updateText2D;
            (window as any).getText2DFromGlobal = getText2DFromGlobal;
            (window as any).getText2DString = getText2DString;
            (window as any).isText2DEnabled = isText2DEnabled;
        }
    }

    public override ngOnDestroy(): void {
        this.textEditor?.remove();

        super.ngOnDestroy();
    }

    public clearSelectedInternal() {
        this.glModel3d.clearSelected();
    }

    public async createDesignScreenshotInternal(extraInfo: IScreenShotSettingsC2C): Promise<string> {
        if (!extraInfo.loadsVisibilityInfo) {
            extraInfo.loadsVisibilityInfo = {
                modifyLoads: false,
                preview: false,
            };
        }

        return await this.glModel3d.createDesignScreenshot(extraInfo);
    }

    public async createDesignScreenshot2DInternal(extraInfo: IScreenShotSettingsC2C, view2dMode: View2dModeType): Promise<string> {
        return await this.glModel3d.createDesignScreenshot2D(extraInfo, view2dMode);
    }

    public zoomToFitInternal(extraInfo: IScreenShotSettingsC2C) {
        this.glModel3d.zoomToFit(extraInfo);
    }

    public movePirPoint2dC2CInternal(index: number, x: number, y: number, diameter: IdValuePair, bond: number): IPointResult | undefined {
        return this.glModel3d.movePirPoint2dC2C(index, x, y, diameter, bond);
    }

    public deletePirPoint2dC2CInternal(index: number): IPointResult | undefined {
        return this.glModel3d.deletePirPoint2dC2C(index);
    }

    public addPirPoint2dC2CInternal(point: RebarPointBase2dC2C): IPointResult | undefined {
        return this.glModel3d.addPirPoint2dC2C(point);
    }

    public addPoint2dC2CInternal(point: RebarPointBase2dC2C): IPointResult | undefined {
        return this.glModel3d.addPoint2dC2C(point.id, point.layerId, point.x, point.y, point.diameter, point.bond, point.shape);
    }

    public movePoint2dC2CInternal(index: number, x: number, y: number, diameter: IdValuePair, shape: number, bond: number): IPointResult | undefined {
        return this.glModel3d.movePoint2dC2C(index, x, y, diameter, shape, bond);
    }

    public deletePoint2dC2CInternal(index: number): IPointResult | undefined {
        return this.glModel3d.deletePoint2dC2C(index);
    }

    public resetHighlightedComponentsInternal() {
        this.glModel3d.resetHighlightedComponents();
    }

    public stepperClick(stepper: Stepper) {
        const unitValue = this.unitService.parseUnknownUnitValue(this.glModel3d.textEditorValue ?? '');

        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            const stepValue = this.unitService.incDecValueByUnit(unitValue.unit);
            unitValue.value = (stepper == Stepper.increment ? unitValue.value + stepValue : unitValue.value - stepValue);

            this.glModel3d.textEditorValue = this.unitService.formatUnitValue(unitValue);
            this.glModel3d.textEditorValueChange();
        }
    }

    protected createGLModelBase3D(): GLModelC2C {
        const nativeElement = this.elementRef.nativeElement;
        this.textEditor = nativeElement.shadowRoot?.querySelector<HTMLInputElement>('.gl-model-text-editor') ?? undefined;

        setTimeout(() => {
            // canvas is not the correct size yet so we wait
            this.resizeInternal();

            // append the text editor to body
            if (this.textEditor) {
                document.body.appendChild(this.textEditor);
            }
        });

        const modelUpdate: GLModelUpdateC2C = {
            center2dCtor: Center2dUpdate,
            baseMaterialsC2CCtor: BaseMaterialsC2CUpdate,
            commonDimensionsC2CCtor: CommonDimensionsC2CUpdate,
            coordinateSystemCtor: CoordinateSystemUpdate,
            forceCtor: ForceUpdate,
            momentCtor: MomentUpdate,
            rebarCtor: RebarUpdate,
            reinforcementCtor: ReinforcementUpdate,
            sceneCoordinateSystemCtor: SceneCoordinateSystemUpdate,
            zonesC2CCtor: ZonesC2CUpdate
        };

        const images: GLImages = {
            existingStructureConcrete,
            newStructureConcrete,
            reinforcement,
            fontTexture
        };

        const context3d = getOrCreateContext3d(this.context3dKey, () => new CanvasContext3d(this.context3dKey, MaterialCacheC2C, MeshCacheC2C));
        context3d.parentContainer = nativeElement;

        // run outside angular zone because of requestAnimationFrame
        return this.ngZone.runOutsideAngular(() => new GLModelC2C({
            showDebugLayer: environment.debugGlModel,
            context3d,
            images,
            renderType: this.continuousRender ? RenderType.Continuous : RenderType.Auto,
            textEditor: this.textEditor,
            // we bind glModel values in html (like glModel3d.textEditorVisible) so we need to call angular change detection to check for html changes
            textEditorChange: () => this.changeDetection.detectChanges(),
            unitConverter: this.glUnitConverter,
            propertyInfo: this.glPropertyInfo,
            mathCalculator: this.glMathCalculator,
            tooltip: this.glTooltip,
            eventNotifier: this.glEventNotifier,
            inputSettings: this.glInputSettings,
            model: this.model,
            modelUpdate
        }));
    }

    protected override onEvent(event: GLEvent, eventArgs?: unknown) {
        switch (event) {
            case 'Fonts_Loaded':
                this.fontsLoaded.next();
                break;
            case 'Zoom':
                this.zoom.next(eventArgs as number);
                break;
            case 'Select_Tab':
                this.selectTab.next(eventArgs as string);
                break;
            case 'positionsChanged':
                this.positionsChanged.next(eventArgs as Record<string, boolean>);
                break;
            case 'draggingSelectionChanged':
                this.draggingSelectionChanged.next(eventArgs as boolean);
                break;
            case 'rebarClicked':
                this.rebarClicked.next(eventArgs as boolean);
                break;
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}

