import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { DesignC2C as Design } from '../../../shared/entities/design-c2c';
import { ViewType } from '../layer-utilizations/layer-utilizations.component';
import { UnitService } from '../../services/unit.service';

@Component({
  templateUrl: './sustainability-panel.component.html',
  styleUrls: ['./sustainability-panel.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class SustainabilityPanelComponent {

    @Input()
    public design?: Design;

    @Input()
    public collapsed = true;

    @Output()
    public collapsedChange = new EventEmitter<boolean>();

    constructor(
        public localizationService: LocalizationService,
        public unit: UnitService,
        private modalService: ModalService
    ) {
    }

    public get showPanel() {
        return this.design?.designData?.reportDataC2C?.utilizations?.some(u => u.name == ViewType.TotalProductionEmissions) ?? false;
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.collapsedChange.emit(this.collapsed);
    }

    public get totalProductionEmissions() {
        const utilization = this.design?.designData.reportDataC2C?.utilizations?.find(u => u.name == ViewType.TotalProductionEmissions);
        return this.unit.formatNumber(parseFloat(utilization?.value?.value ?? '0'), 2);
    }

    public get infoBoxPopup(): any {
        return () => { this.modalService.openInfoDialogC2C('OpenSustainability'); };
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}
