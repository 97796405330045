import { Injectable } from '@angular/core';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';

import { DesignC2C } from '../../shared/entities/design-c2c';
import { MenuC2C } from '../../shared/services/static-menu.service.base';
import { CalculationServiceC2C } from './calculation-c2c.service';
import { CodeListService } from './code-list.service';
import { FeaturesVisibilityService } from './features-visibility.service';
import { createC2CMenu_AnchorCalculationData } from './helpers/static-menu-anchor-calculation';
import { createC2CMenu_ExistingReinforcement } from './helpers/static-menu-existing-reinforcement';
import { createC2CMenu_ExistingStructure } from './helpers/static-menu-existing-structure';
import { createC2CMenu_Loads } from './helpers/static-menu-loads';
import { createC2CMenu_Application } from './helpers/static-menu-loads-application';
import { createC2CMenu_NewConcrete } from './helpers/static-menu-new-concrete';
import { createC2CMenu_NewStructure } from './helpers/static-menu-new-structure';
import { createC2CMenu_OverlayApplication } from './helpers/static-menu-overlay-application';
import {
    createC2CMenu_OverlayConcreteBlockDefinition
} from './helpers/static-menu-overlay-concrete';
import {
    createC2CMenu_OverlayInstallationCondition
} from './helpers/static-menu-overlay-installation';
import { createC2CMenu_Product } from './helpers/static-menu-product';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { UnitService } from './unit.service';

@Injectable({
    providedIn: 'root'
})
export class StaticMenuService {
    constructor(
        private localizationService: LocalizationService,
        private codeListService: CodeListService,
        private featureVisibilityService: FeaturesVisibilityService,
        private modalService: ModalService,
        private unitService: UnitService,
        private calculationService: CalculationServiceC2C
    ) { }

    public initializeMenu(design: DesignC2C) {
        const menu = this.createC2CMenu(design);
        this.fillControlProperties(menu);
        return menu;
    }

    private fillControlProperties(menu: MenuC2C): void {

        const allControls = menu.Tabs.flatMap(tab => tab.TabRegions).flatMap(region => region.Controls);
        allControls.forEach(control => {
            control.Size = control.Size ?? 100;
            control.TooltipType = control.TooltipType ?? TooltipType.Normal;
        });
    }

    private createC2CMenu(design: DesignC2C): MenuC2C {
        return {
            Id: 1,
            Favorites: true,
            Tabs: [
                createC2CMenu_Application(design),
                createC2CMenu_ExistingStructure(design, this.localizationService),
                createC2CMenu_ExistingReinforcement(design),
                createC2CMenu_NewStructure(),
                createC2CMenu_NewConcrete(design),
                createC2CMenu_OverlayApplication(),
                createC2CMenu_OverlayInstallationCondition(design),
                createC2CMenu_OverlayConcreteBlockDefinition(design),
                createC2CMenu_Product(design, this.featureVisibilityService, this.localizationService, this.modalService),
                createC2CMenu_Loads(design, this.codeListService, this.featureVisibilityService, this.localizationService, this.unitService, this.calculationService),
                createC2CMenu_AnchorCalculationData(design)
            ],
            Footer: {
                Controls: []
            }
        };
    }
}
