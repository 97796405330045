// This is used by UserSettings so no existing value should be changed!
export enum CollapsingControls {
    Utilization = 122,
    RebarDesign = 137,
    ZoneUtilizationsC2C = 143,
    UtilizationsC2C = 144,
    DesignMethod = 147,
    RebarLoads = 148,
    ShearUtilization = 149,
    TopLayer1Utilization = 151,
    TopLayer2Utilization = 152,
    BottomLayer1Utilization = 153,
    BottomLayer2Utilization = 154,
    DrillLengthUtilization = 155,
    ConcreteBreakoutUtilization = 156,
    ConcreteSplittingUtilization = 157,
    StrutAndTiesUtilization = 158,
    OverlayZone1Utilization = 159,
    OverlayZone2Utilization = 160,
    OverlayZone3Utilization = 161,
    OverlayZone4Utilization = 162,
    OverlayZone1InterfaceUtilization = 163,
    OverlayZone2InterfaceUtilization = 164,
    OverlayZone3InterfaceUtilization = 165,
    OverlayZone4InterfaceUtilization = 166,
    OverlayZone1ConnectorDesign = 167,
    OverlayZone2ConnectorDesign = 168,
    OverlayZone3ConnectorDesign = 169,
    OverlayZone4ConnectorDesign = 170,
    PirShearHiltiUtilization = 171,
    PirShearUtilization = 172,
    PirAnchorDesignUtilization = 173,
    PirInstallationUtilization = 175,
    PirSpliceLengthUtilization = 176,
    PirFireLengthUtilization = 177
}
