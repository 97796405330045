import { Injectable, NgZone } from '@angular/core';
import { IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IImportData } from '@profis-engineering/pe-ui-common/entities/import-data';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    IImportDesignProvider, IProjectDesignEntity
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';

import { DesignC2C } from '../../shared/entities/design-c2c';
import { ProjectOpenType } from '../../shared/entities/tracking-data';
import {
    ProjectDesignEntityC2C
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { CalculationServiceC2C } from './calculation-c2c.service';

@Injectable({
    providedIn: 'root'
})
export class ImportDesignProviderService implements IImportDesignProvider {
    public path = UrlPath.main;

    constructor(
        private calculationService: CalculationServiceC2C,
        private ngZone: NgZone
    ) { }

    public static GetAllowedImportExtensions(): string[] {
        return ['.pe', '.pa2', '.profis3'];
    }

    public getValidFileExtensions(): string[] {
        return ImportDesignProviderService.GetAllowedImportExtensions();
    }

    public checkFile(fileContent: string): boolean {
        try {
            const designEntity = JSON.parse(fileContent);
            return designEntity?.designTypeId == null &&
                designEntity?.options?.connectionType != null;
        }
        catch (err) {
            return false;
        }
    }

    public async createProjectDesignEntity(_projectDesign: File | Blob, _projectName: string, fileNameProposal: string, _designName: string, projectDesignContent?: string): Promise<IProjectDesignEntity> {
        if (projectDesignContent == null)
            throw new Error('Argument \'projectDesignContent\' can not be null.');

        const p = JSON.parse(projectDesignContent);
        p.designName = fileNameProposal;
        return p;
    }

    public async updateDesignFromExternalFile(oldDesign: IBaseDesign, projectDesign: IProjectDesignEntity, disableCalcMessages?: boolean): Promise<DesignC2C> {
        return this.ngZone.run(() => this.calculationService.updateDesignFromExternalFileC2C(oldDesign, projectDesign as ProjectDesignEntityC2C, disableCalcMessages));
    }

    public async createDesign(importData: IImportData, projectId: string): Promise<IImportData> {
        // create design
        const designOrImportData = await this.ngZone.run(async () => this.calculationService.createAndOpenFromProjectDesignC2C(importData.projectDesign as ProjectDesignEntityC2C, projectId, undefined, { supressErrorMessage: true }));
        designOrImportData.projectOpenType = ProjectOpenType.ImportExisting;

        importData.design = designOrImportData;

        return importData;
    }

    public setDesignName(projectDesignEntity: IProjectDesignEntity, name: string): void {
        (projectDesignEntity as ProjectDesignEntityC2C).designName = name;
    }
}
