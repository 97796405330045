import {
    CalculationType, IBaseDesign
} from '@profis-engineering/pe-ui-common/entities/design';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    CalculateDesignRequestDataBase, CalculationServiceBase, ICalculateInternalOptionsBase,
    ICalculationResultBase, IPropertyChanges
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';

import { DesignC2C, ReportDataC2C } from '../entities/design-c2c';
import {
    CalculationResultEntityC2C as ICalculationResultEntityC2C,
    DesignReportDataClientEntityC2C as IDesignReportDataClientEntityC2C, NewDesignDataEntityC2C,
    ProjectDesignEntityC2C
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { DesignStandard } from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { ReportOptionEntityC2C } from '../generated-modules/Hilti.PE.ReportService.Shared.Entities';
import { UIPropertiesC2C } from '../properties/properties';

export interface ICalculationResult extends ICalculationResultBase<DesignC2C> {
    reportData: unknown;    // Not used by C2C?
}

export function isHnaBasedDesignStandard(value?: number) {
    switch (value) {
        case DesignStandard.ACI:
        case DesignStandard.CSA:
            return true;

        default:
            return false;
    }
}

export abstract class CalculationServiceBaseC2C extends CalculationServiceBase {
    public abstract createAndOpenC2C(dataEntity: NewDesignDataEntityC2C): Promise<DesignC2C>;

    public abstract createAndOpenTemplateC2C(projectDesign: ProjectDesignEntityC2C, templateId: string, templateName: string): Promise<DesignC2C>;

    public abstract createAndOpenFromProjectDesignC2C(projectDesign: ProjectDesignEntityC2C, projectId: string, templateId?: string, apiOptions?: ApiOptions): Promise<DesignC2C>;

    public abstract createFromProjectDesignC2C(projectDesign: ProjectDesignEntityC2C, projectId: string): Promise<DesignC2C>;

    public abstract openFromDocumentDesignC2C(documentDesign: IBaseDesign): Promise<ICalculationResult>;

    public abstract openFromProjectDesignC2C(projectDesign: ProjectDesignEntityC2C, designId: string, trackingEnabled?: boolean): Promise<ICalculationResult>;

    public abstract updateDesignFromExternalFileC2C(oldDesign: IBaseDesign, projectDesign: ProjectDesignEntityC2C, disableCalcMessages?: boolean): Promise<DesignC2C>;

    public abstract generateAndDownloadReport(design: DesignC2C, downloadPdf: boolean, options: ReportOptionEntityC2C, designImages: string[]): Promise<Blob>;

    public abstract calculateAllC2CSignalR(
        design: DesignC2C,
        changes?: Change[],
        onProgress?: (progress: unknown) => void
    ): Promise<void>;

    public abstract calculateAsync(design: DesignC2C, changeFn?: (design: DesignC2C) => void, options?: ICalculateInternalOptionsBase): Promise<ICalculationResult>;

    public abstract calculate(design: DesignC2C, calculateId: string, options: CalculateDesignRequestDataBase): void;

    public abstract updateReportData(design: DesignC2C, reportData: IDesignReportDataClientEntityC2C): ReportDataC2C;

    public abstract updateCalculationData(
        design: DesignC2C,
        data: ICalculationResultEntityC2C, calculationLanguage: string, calculationType: CalculationType, messagesClosedDeferred?: Deferred<void>,
        disableCalcMessages?: boolean,
        keepMissingProperties?: boolean
    ): {
        [property: string]: Change;
    };

    public abstract updateFromProperties(design: DesignC2C, propertiesC2C: UIPropertiesC2C, keepMissingProperties?: boolean): {
        modelChanges: {
            [property: string]: Change;
        };
        propertyChanges: IPropertyChanges;
    };

    public abstract updatePropertyValues(design: DesignC2C, properties: UIPropertiesC2C, keepMissingProperties?: boolean): IPropertyChanges;

    public abstract loadState(design: DesignC2C, index: number): void;

    public abstract undo(design: DesignC2C): Promise<void>;

    public abstract redo(design: DesignC2C): Promise<void>;
}
