<pe-modal-header [modalInstance]="modalInstance" [title]="translate(titleKey)">
</pe-modal-header>

<pe-modal-body>
    <div class="content">
        <div class="image" *ngIf="imageUrl">
            <img [src]="imageUrl" alt="Product image">
        </div>
        <p [innerHtml]="message"></p>
    </div>
    <a class="url" *ngIf="infoUrl" [href]="infoUrl" target="_blank" [innerHtml]="translate(infoKey)"></a>
</pe-modal-body>

<pe-modal-footer>

    <pe-button
        [id]="'default-calculation-method-cancel-button'"
        [look]="'Default'"
        [text]="translate('Agito.Hilti.Profis3.Main.AdvancedBaseplateDesign.No')"
        (buttonClick)="no()"
        [disabled]="pendingSave">
    </pe-button>

    <pe-button
        [id]="'default-calculation-method-save-button'"
        [look]="'Cta'"
        (buttonClick)="yes()"
        [disabled]="pendingSave">

        <pe-loading-button [loading]="pendingSave"
            [text]="translate('Agito.Hilti.Profis3.Main.AdvancedBaseplateDesign.Yes')">
        </pe-loading-button>
    </pe-button>

</pe-modal-footer>