import { ICheckboxGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-group-props';
import { IDropdownItem } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { DesignC2C } from '../../shared/entities/design-c2c';
import { DesignMethodGroup } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { LocalizationService } from '../services/localization.service';

export class DesignMethodGroupHelper {
    static isGermanNationalAnnexAvailable(isPirEu: boolean, regionId: number): boolean {
        return isPirEu && regionId == KnownRegion.Germany;
    }

    static changeDesignMethodItemsForGermanNationalAnnex(items: ICheckboxGroupItem[], design: DesignC2C, localizationService: LocalizationService) {
        if (!design.isPirEu) {
            return;
        }

        const relevantGroups = [DesignMethodGroup.EN199211, DesignMethodGroup.TR069ETA, DesignMethodGroup.HiltiMethod];
        const isGermanNationalAnnex = design.designData.projectDesignC2C?.loads?.useGermanNationalAnnex ?? false;
        items.filter(i => relevantGroups.includes(i.value))
            .forEach(i => i.text = localizationService.getString(`${i.textTranslationKey}${(isGermanNationalAnnex ? '-DE-NA' : '')}`));
    }

    static updateRoughnessItemsTextForASWithASDesignMethod(items: IDropdownItem[], design: DesignC2C, localizationService: LocalizationService) {
        items.forEach(i => i.text = localizationService.getString(`${i.textTranslationKey}${design.isPirASAS ? '.AS' : ''}`));
    }
}
