

import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import {
    DocumentHelper as DocumentHelperCommon
} from '@profis-engineering/pe-ui-common/helpers/document-helper';
import { ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    BrowserServiceBase
} from '@profis-engineering/pe-ui-common/services/browser.common';
import {
    DocumentServiceBase
} from '@profis-engineering/pe-ui-common/services/document.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import {
    OfflineServiceBase
} from '@profis-engineering/pe-ui-common/services/offline.common';

import { DesignC2C } from '../../shared/entities/design-c2c';
import {
    ProjectDesignEntityC2C
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';

export class DocumentHelper extends DocumentHelperCommon {
    public static download(
        apiService: ApiServiceBase,
        browser: BrowserServiceBase,
        localization: LocalizationServiceBase,
        document: DocumentServiceBase,
        offline: OfflineServiceBase,
        design: DesignC2C,
        overwrite?: boolean) {

        return this.downloadInternal({ apiService, browser, localization, document, offline }, design, this.generateBlob, overwrite);
    }
    public static generateBlob(design: Design, apiService: ApiServiceBase, browser: BrowserServiceBase, document: DocumentServiceBase) {
        if ((design as DesignC2C).designData.projectDesignC2C == null) {
            throw new Error('ProjectDesign(or C2C) not set.');
        }

        if (design.designName == null || design.designName == '') {
            throw new Error('designName not set.');
        }

        if (!design.isTemplate) {
            const project = document.findProjectByDesignId(design.id);
            if (project == null) {
                throw new Error('Design is not in any projects.');
            }
        }

        return Promise.resolve(browser.base64toBlob(browser.toBase64((design as DesignC2C).designData.projectDesignC2C as ProjectDesignEntityC2C), 'application/json'));
    }
}
