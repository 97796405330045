import clone from 'lodash-es/clone';

import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import {
    MaxReinforcementSpacing as MaxReinforcementSpacingCodeList
} from '../../../shared/entities/code-lists/max-reinforcement-spacing';
import {
    MaximumAreaReinforcement as MaximumAreaReinforcementCodeList
} from '../../../shared/entities/code-lists/maximum-area-reinforcement';
import {
    MinimumAreaReinforcement as MinimumAreaReinforcementCodeList
} from '../../../shared/entities/code-lists/minimum-area-reinforcement';
import {
    SpacingMaximum as SpacingMaximumCodeList
} from '../../../shared/entities/code-lists/spacing-maximum';
import { IRebarCalulationInputsData } from '../../../shared/entities/rebar-calulation-inputs-data';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    DesignMethodGroup, MaximumAreaReinforcement, MinimumAreaReinforcement, SpacingMaximum
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IPropertyMetaDataC2C, PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { getPropertyValueById } from '../../helpers/ui-property-helper';
import { RebarCalculationParametersAs } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';

@Component({
    templateUrl: './rebar-calculation-parameters-inputs.component.html',
    styleUrls: ['./rebar-calculation-parameters-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class RebarCalculationParametersInputsComponent implements OnInit, DoCheck {
    @Input()
    public data!: IRebarCalulationInputsData;

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    @Input()
    public designMethodGroupId?: number;

    public existingReinforcementAlpha3!: NumericTextBoxProps;
    public factorK4!: NumericTextBoxProps;
    public factorK6!: NumericTextBoxProps;
    public transversePressure!: NumericTextBoxProps;
    public minimumAreaReinforcement!: DropdownProps<MinimumAreaReinforcementCodeList>;
    public minimumAreaReinforcementInput!: NumericTextBoxProps;
    public maximumAreaReinforcement!: DropdownProps<MaximumAreaReinforcementCodeList>;
    public maximumAreaReinforcementInput!: NumericTextBoxProps;
    public spacingMaximum!: DropdownProps<SpacingMaximumCodeList>;
    public spacingMaximumInput!: NumericTextBoxProps;
    public minimumAreReinforcementItems!: DropdownItem<number>[];
    public maximumAreReinforcementItems!: DropdownItem<number>[];
    public spacingMaximumItems!: DropdownItem<number>[];
    public dataLoaded = false;

    private oldRegionId!: number;
    private oldDesignMethodGroupId: number | undefined;
    private oldData!: IRebarCalulationInputsData;

    constructor(
        private localizationService: LocalizationService,
        private codeList: CodeListService,
        private unit: UnitService
    ) { }

    ngOnInit(): void {
        this.populateDropdownItems();
        this.initRebarCalculationControls();
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setDefaultValues();
            this.refreshControls();

            this.dataLoaded = true;
        }

        this.updateData();
    }

    private updateData() {
        let update = false;

        if (this.oldRegionId != this.regionId) {
            this.refreshControls();

            this.oldRegionId = this.regionId;
        }

        if (this.oldDesignMethodGroupId != this.designMethodGroupId) {
            this.refreshControls();
            this.refreshTooltips();

            this.oldDesignMethodGroupId = this.designMethodGroupId;
        }

        if (this.oldData != null) {
            if (this.oldData.connectionType != this.data.connectionType) {
                update = true;
                this.refreshControls();
            }

            if (this.oldData.lengthUnit != this.data.lengthUnit) {
                update = true;
                this.refreshControls();
            }

            if (this.oldData.areaUnit != this.data.areaUnit) {
                update = true;
                this.refreshControls();
            }

            if (this.oldData.stressUnit != this.data.stressUnit) {
                update = true;
                this.refreshControls();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);
        }
    }

    public get useAusOptions() {
        return this.designMethodGroupId == DesignMethodGroup.AS3600ETA;
    }

    private updateControl(control: NumericTextBoxProps, propertyMetaData: IPropertyMetaDataC2C, defaultValue: number, unit: Unit, defaultUnit: Unit = Unit.None, customPrecision?: number) {
        const propertyValue = this.codeList.getPropertyValue(propertyMetaData.id, this.regionId, this.data.connectionType ?? 0, undefined, this.designMethodGroupId ?? 0);

        if (propertyValue) {
            control.maxValue = propertyValue.maxValue;
            control.minValue = propertyValue.minValue;
        }

        control.placeholder = this.getTextBoxPlaceholder(defaultValue, unit, defaultUnit);
        control.unit = unit;

        if (customPrecision != undefined) {
            control.precision = customPrecision;
        }
    }

    private updateControlDropdown(control: DropdownProps<any>, propertyMetaData: IPropertyMetaDataC2C, defaultValue: number) {
        control.selectedValue = defaultValue;

        switch (propertyMetaData) {
            case this.minimumAreaReinforcementProperty:
                this.minimumAreaReinforcementChanged(defaultValue);
                break;
            case this.maximumAreaReinforcementProperty:
                this.maximumAreaReinforcementChanged(defaultValue);
                break;
            case this.spacingMaximumProperty:
                this.spacingMaximumChanged(defaultValue);
                break;
        }
    }

    private setDefaultValues() {
        if (this.data.design != null) {
            const design = this.data.design();
            this.data.existingReinforcementAlpha3 = design.existingReinforcementAlpha3;
            this.data.transversePressure = design.transversePressure;
            this.data.minimumAreaReinforcement = design.minimumAreaReinforcement;
            this.data.minimumAreaReinforcementInput = design.minimumAreaReinforcementInput;
            this.data.maximumAreaReinforcement = design.maximumAreaReinforcement;
            this.data.maximumAreaReinforcementInput = design.maximumAreaReinforcementInput;
            this.data.spacingMaximum = design.spacingMaximum;
            this.data.spacingMaximumInput = design.spacingMaximumInput;

            this.data.rebarCalculationParametersAs = {
                factorK4: design.designOptionsAs?.rebarCalculationParameters.factorK4,
                factorK6: design.designOptionsAs?.rebarCalculationParameters.factorK6,
                transversePressure: design.designOptionsAs?.rebarCalculationParameters.transversePressure,
                minimumAreaReinforcement: design.designOptionsAs?.rebarCalculationParameters.minimumAreaReinforcement,
                minimumAreaReinforcementInput: design.designOptionsAs?.rebarCalculationParameters.minimumAreaReinforcementInput,
                maximumAreaReinforcement: design.designOptionsAs?.rebarCalculationParameters.maximumAreaReinforcement,
                maximumAreaReinforcementInput: design.designOptionsAs?.rebarCalculationParameters.maximumAreaReinforcementInput,
                spacingMaximum: design.designOptionsAs?.rebarCalculationParameters.spacingMaximum,
                spacingMaximumInput: design.designOptionsAs?.rebarCalculationParameters.spacingMaximumInput
            } as RebarCalculationParametersAs;
        }
    }

    private get defaultExistingReinforcementAlpha3() {
        return this.getPropertyValueById(PropertyMetaDataC2C.Option_C2C_ExistingReinforcementAlpha3.id)?.defaultValue ?? 0;
    }

    private get defaultFactorK4() {
        return this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_FactorK4.id)?.defaultValue ?? 0;
    }

    private get defaultFactorK6() {
        return this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_FactorK6.id)?.defaultValue ?? 0;
    }

    private get defaultTransversePressure() {
        return this.getPropertyValueById(this.transversePressureProperty.id)?.defaultValue ?? 0;
    }

    private get defaultMinimumAreaReinforcement() {
        return this.getPropertyValueById(this.minimumAreaReinforcementProperty.id)?.defaultValue ?? 0;
    }

    private get defaultMinimumAreaReinforcementInput() {
        return this.getPropertyValueById(this.minimumAreaReinforcementInputProperty.id)?.defaultValue ?? 0;
    }

    private get defaultMaximumAreaReinforcement() {
        return this.getPropertyValueById(this.maximumAreaReinforcementProperty.id)?.defaultValue ?? 0;
    }

    private get defaultMaximumAreaReinforcementInput() {
        return this.getPropertyValueById(this.maximumAreaReinforcementInputProperty.id)?.defaultValue ?? 0;
    }

    private get defaultSpacingMaximum() {
        return this.getPropertyValueById(this.spacingMaximumProperty.id)?.defaultValue ?? 0;
    }

    private get defaultSpacingMaximumInput() {
        if (this.useAusOptions) {
            return this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximumInput.id)?.defaultValue ?? 0;
        }

        return this.getMaxReinforcementSpacing(this.codeList, this.regionId);
    }

    private get transversePressureProperty() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_TransversePressure : PropertyMetaDataC2C.Option_C2C_TransversePressure;
    }

    private get minimumAreaReinforcementProperty() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcement : PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcement;
    }

    private get minimumAreaReinforcementInputProperty() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcementInput : PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcementInput;
    }

    private get maximumAreaReinforcementProperty() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcement : PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcement;
    }

    private get maximumAreaReinforcementInputProperty() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcementInput : PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcementInput;
    }

    private get spacingMaximumProperty() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximum : PropertyMetaDataC2C.Option_C2C_SpacingMaximum;
    }

    private get spacingMaximumInputProperty() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximumInput : PropertyMetaDataC2C.Option_C2C_SpacingMaximumInput;
    }

    private get transversePressurePrecision() {
        return this.useAusOptions
            ? 1
            : this.unit.getPrecision(Unit.Nmm2);
    }

    private refreshControls() {
        this.updateControl(this.existingReinforcementAlpha3, PropertyMetaDataC2C.Option_C2C_ExistingReinforcementAlpha3, this.defaultExistingReinforcementAlpha3, Unit.None);
        this.updateControl(this.factorK4, PropertyMetaDataC2C.Options_C2C_AS_FactorK4, this.defaultFactorK4, Unit.None);
        this.updateControl(this.factorK6, PropertyMetaDataC2C.Options_C2C_AS_FactorK6, this.defaultFactorK6, Unit.None);
        this.updateControl(this.transversePressure, this.transversePressureProperty, this.defaultTransversePressure, this.data.stressUnit as Unit, Unit.Nmm2, this.transversePressurePrecision);
        this.updateControl(this.minimumAreaReinforcementInput, this.minimumAreaReinforcementInputProperty, this.defaultMinimumAreaReinforcementInput, this.data.areaUnit as Unit, Unit.mm2);
        this.updateControl(this.maximumAreaReinforcementInput, this.maximumAreaReinforcementInputProperty, this.defaultMaximumAreaReinforcementInput, this.data.areaUnit as Unit, Unit.mm2);
        this.updateControl(this.spacingMaximumInput, this.spacingMaximumInputProperty, this.defaultSpacingMaximumInput, this.data.lengthUnit as Unit, Unit.mm);

        this.updateControlDropdown(this.minimumAreaReinforcement, this.minimumAreaReinforcementProperty, this.minimumAreaReinforcementValue ?? this.defaultMinimumAreaReinforcement);
        this.updateControlDropdown(this.maximumAreaReinforcement, this.maximumAreaReinforcementProperty, this.maximumAreaReinforcementValue ?? this.defaultMaximumAreaReinforcement);
        this.updateControlDropdown(this.spacingMaximum, this.spacingMaximumProperty, this.spacingMaximumValue ?? this.defaultSpacingMaximum);
    }

    private refreshTooltips() {
        this.transversePressure.tooltip = this.transversePressureTooltip;
        this.transversePressure.title = this.transversePressureTitle;
    }

    private getMaxReinforcementSpacing(codeList: CodeListService, regionId: number) {
        const propertyValues = codeList.projectCodeListsC2C[ProjectCodeList.MaxReinforcementSpacingC2C] as MaxReinforcementSpacingCodeList[];

        let propertyValue = propertyValues.find((property) => property.regionId == regionId);
        if (!propertyValue) {
            propertyValue = propertyValues.find((property) => property.regionId == 0);
        }
        return propertyValue?.maxValue ?? 0;
    }

    private initRebarCalculationControls() {
        this.existingReinforcementAlpha3 = {
            id: 'rebar-calculation-parameters-existingReinforcementAlpha3',
            title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.ExistingReinforcementAlpha3'),
            tooltip: {
                title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.ExistingReinforcementAlpha3'),
                content: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.ExistingReinforcementAlpha3.Tooltip')
            }
        };

        this.factorK4 = {
            id: 'rebar-calculation-parameters-factorK4',
            title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.FactorK4'),
            tooltip: {
                title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.FactorK4'),
                content: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.FactorK4.Tooltip')
            }
        };

        this.factorK6 = {
            id: 'rebar-calculation-parameters-factorK6',
            title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.FactorK6'),
            tooltip: {
                title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.FactorK6'),
                content: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.FactorK6.Tooltip')
            }
        };

        this.transversePressure = {
            id: 'rebar-calculation-parameters-transversePressure',
            title: this.transversePressureTitle,
            tooltip: this.transversePressureTooltip,
            precision: this.transversePressurePrecision
        };

        this.minimumAreaReinforcement = {
            id: 'rebar-calculation-parameters-minumumAreaOfReinforcement-dropdown',
            title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.MinimumAreaOfReinforcement')
        };

        this.minimumAreaReinforcementInput = {
            id: 'rebar-calculation-parameters-minumumAreaOfReinforcement-textbox',
            title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.MinimumAreaOfReinforcementInput')
        };

        this.maximumAreaReinforcement = {
            id: 'rebar-calculation-parameters-maximumAreaOfReinforcement-dropdown',
            title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.MaximumAreaOfReinforcement')
        };

        this.maximumAreaReinforcementInput = {
            id: 'rebar-calculation-parameters-maximumAreaOfReinforcement-textbox',
            title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.MaximumAreaOfReinforcementInput')
        };

        this.spacingMaximum = {
            id: 'rebar-calculation-parameters-spacing-dropdown',
            title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.Spacing')
        };

        this.spacingMaximumInput = {
            id: 'rebar-calculation-parameters-spacing-textbox',
            title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.SpacingInput')
        };
    }

    private get transversePressureTitle() {
        return this.useAusOptions
            ? this.translate('Agito.Hilti.C2C.RebarCalculationParameters.TransversePressure.AS')
            : this.translate('Agito.Hilti.C2C.RebarCalculationParameters.TransversePressure');
    }

    private get transversePressureTooltip() {
        return this.useAusOptions
            ? {
                title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.TransversePressure.AS'),
                content: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.TransversePressure.Tooltip.AS')
            }
            : {
                title: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.TransversePressure'),
                content: this.translate('Agito.Hilti.C2C.RebarCalculationParameters.TransversePressure.Tooltip')
            };
    }

    private getTextBoxPlaceholder(value: number, unit: Unit, defaultUnit: Unit) {
        const defaultPrecision = this.unit.getPrecision(unit);

        const result = this.unit.formatUnitValueArgs(
            this.unit.convertUnitValueArgsToUnit(
                value,
                defaultUnit,
                unit
            ),
            unit,
            defaultPrecision
        );

        if (result == null) {
            return this.translate('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }

        return result;
    }

    private getPropertyValueById(id: number) {
        return getPropertyValueById(id, this.codeList, this.regionId, undefined, this.data.connectionType, this.designMethodGroupId);
    }

    private populateDropdownItems() {
        const minimumAreaReinforcement = this.codeList.projectCodeListsC2C[ProjectCodeList.MinimumAreaReinforcementC2C] as MinimumAreaReinforcementCodeList[];
        const maximumAreaReinforcement = this.codeList.projectCodeListsC2C[ProjectCodeList.MaximumAreaReinforcementC2C] as MaximumAreaReinforcementCodeList[];
        const spacingMaximum = this.codeList.projectCodeListsC2C[ProjectCodeList.SpacingMaximumC2C] as SpacingMaximumCodeList[];

        this.spacingMaximumItems = spacingMaximum.map(item => ({
            value: item.id,
            text: this.translate(`Agito.Hilti.C2C.CodeList.SpacingMaximumEntityC2C.${item.displayKey}`)
        }) as DropdownItem<number>);

        this.maximumAreReinforcementItems = maximumAreaReinforcement.map(item => ({
            value: item.id,
            text: this.translate(`Agito.Hilti.C2C.CodeList.MaximumAreaReinforcementEntityC2C.${item.displayKey}`)
        }) as DropdownItem<number>);

        this.minimumAreReinforcementItems = minimumAreaReinforcement.map(item => ({
            value: item.id,
            text: this.translate(`Agito.Hilti.C2C.CodeList.MinimumAreaReinforcementEntityC2C.${item.displayKey}`)
        }) as DropdownItem<number>);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public get transversePressureValue() {
        return this.useAusOptions ? this.data.rebarCalculationParametersAs.transversePressure : this.data.transversePressure;
    }

    public get minimumAreaReinforcementValue() {
        return this.useAusOptions ? this.data.rebarCalculationParametersAs.minimumAreaReinforcement : this.data.minimumAreaReinforcement;
    }

    public get minimumAreaReinforcementInputValue() {
        return this.useAusOptions ? this.data.rebarCalculationParametersAs.minimumAreaReinforcementInput : this.data.minimumAreaReinforcementInput;
    }

    public get maximumAreaReinforcementValue() {
        return this.useAusOptions ? this.data.rebarCalculationParametersAs.maximumAreaReinforcement : this.data.maximumAreaReinforcement;
    }

    public get maximumAreaReinforcementInputValue() {
        return this.useAusOptions ? this.data.rebarCalculationParametersAs.maximumAreaReinforcementInput : this.data.maximumAreaReinforcementInput;
    }

    public get spacingMaximumValue() {
        return this.useAusOptions ? this.data.rebarCalculationParametersAs.spacingMaximum : this.data.spacingMaximum;
    }

    public get spacingMaximumInputValue() {
        return this.useAusOptions ? this.data.rebarCalculationParametersAs.spacingMaximumInput : this.data.spacingMaximumInput;
    }

    public transversePressureChanged(value: number) {
        if (this.useAusOptions)
            this.data.rebarCalculationParametersAs.transversePressure = value;
        else
            this.data.transversePressure = value;
    }

    public minimumAreaReinforcementChanged(value: number) {
        if (this.useAusOptions)
            this.data.rebarCalculationParametersAs.minimumAreaReinforcement = value;
        else
            this.data.minimumAreaReinforcement = value;
    }

    public minimumAreaReinforcementInputChanged(value: number) {
        if (this.useAusOptions)
            this.data.rebarCalculationParametersAs.minimumAreaReinforcementInput = value;
        else
            this.data.minimumAreaReinforcementInput = value;
    }

    public maximumAreaReinforcementChanged(value: number) {
        if (this.useAusOptions)
            this.data.rebarCalculationParametersAs.maximumAreaReinforcement = value;
        else
            this.data.maximumAreaReinforcement = value;
    }

    public maximumAreaReinforcementInputChanged(value: number) {
        if (this.useAusOptions)
            this.data.rebarCalculationParametersAs.maximumAreaReinforcementInput = value;
        else
            this.data.maximumAreaReinforcementInput = value;
    }

    public spacingMaximumChanged(value: number) {
        if (this.useAusOptions)
            this.data.rebarCalculationParametersAs.spacingMaximum = value;
        else
            this.data.spacingMaximum = value;
    }

    public spacingMaximumInputChanged(value: number) {
        if (this.useAusOptions)
            this.data.rebarCalculationParametersAs.spacingMaximumInput = value;
        else
            this.data.spacingMaximumInput = value;
    }

    public get isMaximumAreaReinforcementUserDefined() {
        return this.maximumAreaReinforcementValue == MaximumAreaReinforcement.UserDefined;
    }

    public get isMinimumAreaReinforcementUserDefined() {
        return this.minimumAreaReinforcementValue == MinimumAreaReinforcement.UserDefined;
    }

    public get isSpacingMaximumUserDefined() {
        return this.spacingMaximumValue == SpacingMaximum.UserDefined;
    }
}
