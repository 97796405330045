
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import isEqual from 'lodash-es/isEqual';
import { ContolsStyleSheets, Size } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { DropdownType } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { buildHtmlTooltip } from '@profis-engineering/pe-ui-common/helpers/tooltip-helper';
import { LocalizationService } from '../../../services/localization.service';
import { UnitService } from '../../../services/unit.service';
import styles from './react-points-table.css';
import { AdvancedPointsTableType, IAdvancedModifiedItem, IAdvancedPointTableAddInput, IAdvancedPointsTableDropdownItem, IAdvancedPointsTableProps, IAdvancedTableItemBase } from '../../../../shared/entities/main-menu/main-menu-controls';
import { ITableItem } from '../../../services/helpers/custom-controls-helper/advanced-points-table';

const sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

interface IVector {
    x: number;
    y: number;
}

export function initializeAdvancedPointsTable(controlProps: IAdvancedPointsTableProps, unitService: UnitService) {
    controlProps.type = AdvancedPointsTable;
    controlProps.unitService = unitService;

    return {
        controlProps
    };
}

// that should be same as defined in scss file
const ROW_HEIGHT = 32;
const ControlHeader = (window as any).pe.reactComponents.ControlHeader;
const Dropdown = (window as any).pe.reactComponents.Dropdown;
class _AdvancedPointsTable extends React.PureComponent<IAdvancedPointsTableProps> {

    public static readonly styleSheets = [sheet];

    private containerRef: HTMLDivElement | null = null;

    constructor(props: IAdvancedPointsTableProps) {
        super(props);
        this.onDiameterDropdownClick = this.onDiameterDropdownClick.bind(this);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const pointsTableId = `${this.props.controlId}-points-table`;
        const items = this.prepareItems();
        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;
        const isDeletable = items.length > 1;
        const scrollableClass = this.props.rowsPerScroll != null && this.props.rowsPerScroll > 0 ? 'scrollable' : '';

        return (
            <div data-control-id={this.props.controlId} className={`control react-points-table-control no-padding ${this.props.sizeClass} full-width`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={pointsTableId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className={'points-table-container ' + scrollableClass} style={{ ['--height' as any]: this.rowsHeight }} ref={(ref) => { this.containerRef = ref; }}>
                    <div className={'table-row header'} id={`${this.props.controlId}-header`}>
                        <div className={'id'} id={`${this.props.controlId}-header-id`}>#</div>
                        {this.props.rebarDiameters && <div className={this.getDropdownClassName('diameter', this.props.rebarDiameters)} id={`${this.props.controlId}-header-diameter`}>{this.props.localization!.getString('Agito.Hilti.C2C.PointsTable.Diameter')}</div>}
                        <div className={'number'} id={`${this.props.controlId}-header-x`}>X</div>
                        <div className={'number'} id={`${this.props.controlId}-header-y`}>Y</div>
                        {this.props.shapes && <div className={this.getDropdownClassName('shape', this.props.shapes)} id={`${this.props.controlId}-header-shape`}>{this.props.localization!.getString('Agito.Hilti.C2C.ExportReport.InputData.Shape')}</div>}
                        {this.props.bonds && <div className={this.getDropdownClassName('bond', this.props.bonds)} id={`${this.props.controlId}-header-bond`}>{this.props.localization!.getString('Agito.Hilti.C2C.ExportReport.InputData.BondCondition')}</div>}
                        <div className={'delete'}></div>
                    </div>

                    {
                        items.map(item =>
                            <PointsTableRow
                                controlName={this.props.controlId}
                                key={item.id}
                                item={item}
                                isEditable={this.props.isEditable!}
                                isDeleteable={isDeletable}
                                tooltip={this.props.tooltip!}
                                tooltipDisabled={this.props.tooltipDisabled!}
                                valueChanged={this.props.valueChanged!}
                                commitValue={this.props.onCommit!}
                                onDelete={this.props.onDelete!}
                                unitService={this.props.unitService as UnitService}
                                localization={this.props.localization as LocalizationService}
                                rebarDiameters={this.props.rebarDiameters}
                                bonds={this.props.bonds}
                                shapes={this.props.shapes}
                                onDiameterDropdownClick={this.onDiameterDropdownClick} />
                        )
                    }

                    {this.props.canInsert &&
                        <PointsTableInput
                            lastId={this.props.items != null ? this.props.items.length + 1 : 1}
                            parentControlName={this.props.controlId}
                            isEditable={this.props.isEditable!}
                            item={this.props.addInput!}
                            valueChanged={this.props.addInputChanged!}
                            commitValue={this.props.addInputCommit!}
                            tableType={this.props.tableType}
                            unitService={this.props.unitService as UnitService}
                            localization={this.props.localization as LocalizationService}
                            rebarDiameters={this.props.rebarDiameters}
                            shapes={this.props.shapes}
                            bonds={this.props.bonds}
                            onDiameterDropdownClick={this.onDiameterDropdownClick} />
                    }
                </div>
            </div>
        );
    }

    private getDropdownClassName(prefix: string, items: IAdvancedPointsTableDropdownItem[]) {
        const isExtended = items.some(it => it.isExtended);
        return isExtended ? `${prefix}-extended` : `${prefix}`;
    }

    private get rowsHeight(): string {
        return `${(this.props.rowsPerScroll ?? 0) * ROW_HEIGHT}px`;
    }

    private formatNumber(internalValue: number) {
        const internalUnit = this.props.unitService!.getInternalUnit(UnitGroup.Length);
        const defaultUnit = this.props.unitService!.getDefaultUnit(UnitGroup.Length);

        const defaultValue = this.props.unitService!.convertUnitValueArgsToUnit(internalValue, internalUnit, defaultUnit);
        return this.props.unitService!.formatUnitValueArgs(defaultValue, defaultUnit);
    }

    private getExactValue(internalValue: number) {
        const maxPrecision = this.props.unitService!.getDefaultPrecision() + 1; // Same as used in server code
        const unitValue = this.props.unitService!.convertInternalValueToDefaultUnitValue(internalValue, UnitGroup.Length);
        const displayedUnitValue = this.props.unitService!.formatUnitValue(unitValue);
        const exactUnitValue = this.props.unitService!.formatUnitValue(unitValue, maxPrecision);

        if (displayedUnitValue?.length < exactUnitValue?.length) {
            return exactUnitValue;
        }

        return null;
    }

    private mapItems(): IAdvancedTableItemBase[] {
        if (this.props.items == null) {
            return [];
        }
        return this.props.items.map((item, i) => {
            const formattedX = item.formattedX ?? this.formatNumber(item.x);
            const formattedY = item.formattedY ?? this.formatNumber(item.y);

            const exactValueX = item.exactValueX ?? this.getExactValue(item.x);
            const exactValueY = item.exactValueY ?? this.getExactValue(item.y);

            return {
                ...item,
                formattedX,
                formattedY,
                exactValueX,
                exactValueY,
                id: i
            } as IAdvancedTableItemBase;
        });
    }

    private prepareItems(): IAdvancedTableItemBase[] {
        const items = this.mapItems();

        return items;
    }

    private vectorDot(vectorOne: IVector, vectorTwo: IVector) {
        return vectorOne.x * vectorTwo.x + vectorOne.y * vectorTwo.y;
    }

    private vectorDeterminant(vectorOne: IVector, vectorTwo: IVector) {
        return vectorOne.x * vectorTwo.y - vectorOne.y * vectorTwo.x;
    }

    private vectorAngle(vectorOne: IVector, vectorTwo: IVector) {
        const internalUnit = this.props.unitService!.getInternalUnit(UnitGroup.Angle);
        const defaultUnit = this.props.unitService!.getDefaultUnit(UnitGroup.Angle);
        const angle = (Math.PI - Math.atan2(this.vectorDeterminant(vectorOne, vectorTwo), this.vectorDot(vectorOne, vectorTwo)) + Math.PI) % (2 * Math.PI);

        return this.props.unitService!.convertUnitValueArgsToUnit(angle, internalUnit, defaultUnit);
    }

    private onDiameterDropdownClick(isOpened: boolean, rowIndex: number) {
        if (!isOpened)
            return;

        const itemsCount = this.props.items?.length ?? 0;
        const rowNumber = rowIndex < 0 ? itemsCount : rowIndex;
        const scrollTo = (rowNumber / itemsCount) * this.containerRef!.scrollHeight - 2 * ROW_HEIGHT;
        this.containerRef!.scroll(0, scrollTo);
    }
}

interface IPointsTableRowProps {
    controlName: string;
    item: ITableItem;
    isEditable: boolean;
    isDeleteable: boolean;
    tooltip: string;
    tooltipDisabled: string;
    unitService: UnitService;
    localization: LocalizationService;
    rebarDiameters?: IAdvancedPointsTableDropdownItem[];
    shapes?: IAdvancedPointsTableDropdownItem[];
    bonds?: IAdvancedPointsTableDropdownItem[];
    valueChanged: (item: IAdvancedModifiedItem) => void;
    commitValue: (item: IAdvancedModifiedItem) => void;
    onDelete: (index: number) => void;
    onDiameterDropdownClick?: (isOpened: boolean, rowIndex: number) => void;
}

class PointsTableRow extends React.PureComponent<IPointsTableRowProps> {
    constructor(props: IPointsTableRowProps) {
        super(props);

        this.onDelete = this.onDelete.bind(this);
        this.onXValueChanged = this.onXValueChanged.bind(this);
        this.onYValueChanged = this.onYValueChanged.bind(this);
        this.onDiameterValueChanged = this.onDiameterValueChanged.bind(this);
        this.onShapeValueChanged = this.onShapeValueChanged.bind(this);
        this.onBondValueChanged = this.onBondValueChanged.bind(this);
        this.onCommitX = this.onCommitX.bind(this);
        this.onCommitY = this.onCommitY.bind(this);
        this.onCommitDiameter = this.onCommitDiameter.bind(this);
        this.onCommitBond = this.onCommitBond.bind(this);
        this.onCommitShape = this.onCommitShape.bind(this);
        this.onDiameterDropdownClick = this.onDiameterDropdownClick.bind(this);
    }

    public override render() {
        const classStr = 'table-row' + (!this.props.isEditable ? ' disabled' : '');
        const tooltip = this.props.isEditable ? buildHtmlTooltip(this.props.tooltip) : buildHtmlTooltip(this.props.tooltipDisabled);

        return (
            <div className={classStr}
                data-tip={tooltip}
                data-html={tooltip != null ? true : null}
                id={`${this.props.controlName}-row-${this.rowId}`}>
                <div className={'id'} id={`${this.props.controlName}-row-${this.rowId}-id`}>{this.rowId}</div>

                {this.props.rebarDiameters && this.props.item.rebarDiameter != null &&
                    <PointsTableCellDropdown
                        friendlyName={'diameter'}
                        parentControlName={this.props.controlName}
                        rowId={this.rowId}
                        value={this.props.item.rebarDiameter}
                        unitService={this.props.unitService}
                        localization={this.props.localization}
                        commitValue={this.onCommitDiameter}
                        valueChanged={this.onDiameterValueChanged}
                        isEditable={this.props.isEditable}
                        items={this.props.rebarDiameters}
                        isHidden={false}
                        isInputMode={false}
                        onClick={this.onDiameterDropdownClick} />
                }

                <PointsTableCellText
                    friendlyName={'x'}
                    parentControlName={this.props.controlName}
                    rowId={this.rowId}
                    value={this.props.item.formattedX}
                    valueTitle={this.props.item.exactValueX}
                    isEditable={this.props.isEditable}
                    valueChanged={this.onXValueChanged}
                    commitValue={this.onCommitX} />

                <PointsTableCellText
                    friendlyName={'y'}
                    parentControlName={this.props.controlName}
                    rowId={this.rowId}
                    value={this.props.item.formattedY}
                    valueTitle={this.props.item.exactValueY}
                    isEditable={this.props.isEditable}
                    valueChanged={this.onYValueChanged}
                    commitValue={this.onCommitY} />

                {this.props.shapes && this.props.item.shape != null &&
                    <PointsTableCellDropdown
                        friendlyName={'shape'}
                        parentControlName={this.props.controlName}
                        rowId={this.rowId}
                        value={this.props.item.shape}
                        unitService={this.props.unitService}
                        isEditable={this.props.isEditable}
                        items={this.props.shapes}
                        isHidden={false}
                        isInputMode={false}
                        localization={this.props.localization}
                        commitValue={this.onCommitShape}
                        valueChanged={this.onShapeValueChanged} />
                }

                {this.props.bonds && this.props.item.bond != null &&
                    <PointsTableCellDropdown
                        friendlyName={'bond'}
                        parentControlName={this.props.controlName}
                        rowId={this.rowId}
                        value={this.props.item.bond}
                        unitService={this.props.unitService}
                        localization={this.props.localization}
                        isEditable={this.props.isEditable}
                        items={this.props.bonds}
                        isHidden={false}
                        isInputMode={false}
                        commitValue={this.onCommitBond}
                        valueChanged={this.onBondValueChanged} />
                }

                {this.props.item.angle != null && <div className={'angle'}>{this.props.item.formattedAngle}</div>}

                <div className={'delete'} id={`${this.props.controlName}-row-${this.rowId}-delete`}>
                    <button type='button' tabIndex={-1} onClick={this.onDelete} disabled={!this.props.isDeleteable || !this.props.isEditable}>
                        <span className={'sprite sprite-trash'}></span>
                    </button>
                </div>
            </div>
        );
    }

    private get rowId(): number {
        return this.props.item.id + 1;
    }

    private onDiameterValueChanged(value: number) {
        this.props.valueChanged({ id: this.props.item.id, rebarDiameter: value, layerId: this.props.item.layerId });
    }

    private onXValueChanged(value: string) {
        this.props.valueChanged({ id: this.props.item.id, x: value, layerId: this.props.item.layerId });
    }

    private onYValueChanged(value: string) {
        this.props.valueChanged({ id: this.props.item.id, y: value, layerId: this.props.item.layerId });
    }

    private onBondValueChanged(value: number) {
        this.props.valueChanged({ id: this.props.item.id, bond: value, layerId: this.props.item.layerId });
    }

    private onShapeValueChanged(value: number) {
        this.props.valueChanged({ id: this.props.item.id, shape: value, layerId: this.props.item.layerId });
    }

    private onCommitDiameter(value: number) {
        this.props.commitValue({ id: this.props.item.id, rebarDiameter: value, layerId: this.props.item.layerId });
    }

    private onCommitX(value: string) {
        this.props.commitValue({ id: this.props.item.id, x: value, layerId: this.props.item.layerId });
    }

    private onCommitY(value: string) {
        this.props.commitValue({ id: this.props.item.id, y: value, layerId: this.props.item.layerId });
    }

    private onCommitShape(value: number) {
        this.props.commitValue({ id: this.props.item.id, shape: value, layerId: this.props.item.layerId });
    }

    private onCommitBond(value: number) {
        this.props.commitValue({ id: this.props.item.id, bond: value, layerId: this.props.item.layerId });
    }

    private onDelete() {
        if (this.props.onDelete != null) {
            this.props.onDelete(this.props.item.id);
        }
    }

    private onDiameterDropdownClick(isOpened: boolean) {
        if (this.props.onDiameterDropdownClick != null)
            this.props.onDiameterDropdownClick(isOpened, this.rowId);
    }
}

interface IPointsTableCellTextProps {
    parentControlName: string;
    rowId: number;
    friendlyName: string;
    value: string;
    valueTitle: string;
    isEditable: boolean;
    valueChanged: (value: string) => void;
    commitValue: (value: string) => void;
}

interface IPointsTableCellTextState {
    textValue: string;
}

class PointsTableCellText extends React.PureComponent<IPointsTableCellTextProps, IPointsTableCellTextState> {
    constructor(props: IPointsTableCellTextProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.state = { textValue: this.props.value };
    }

    public override componentDidUpdate(prevProps: IPointsTableCellTextProps) {
        if (!isEqual(this.props, prevProps)) {
            this.setState({ textValue: this.props.value });
        }
    }

    public override render() {
        return (
            <input
                id={`${this.props.parentControlName}-row-${this.props.rowId}-${this.props.friendlyName}`}
                className={'number input'}
                value={this.state.textValue}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onKeyPress={this.onEnter}
                disabled={!this.props.isEditable}
                type='text' autoComplete={'off'}
                autoCorrect={'off'}
                autoCapitalize={'off'}
                spellCheck={false}
                title={this.props.valueTitle} />
        );
    }

    private onChange(event: React.FormEvent) {
        const value = (event.target as HTMLInputElement).value;
        this.setState({ textValue: value });
        this.props.valueChanged((event.target as HTMLInputElement).value);
    }

    private onBlur(event: React.FocusEvent) {
        this.props.commitValue(this.state.textValue);
    }

    private onEnter(event: React.KeyboardEvent) {
        if (event.key == 'Enter') {
            this.props.commitValue(this.state.textValue);
        }
    }
}



interface IPointsTableCellDropdownProps {
    rowId: number;
    parentControlName: string;
    friendlyName: string;
    value: number;
    unitService: UnitService;
    isEditable: boolean;
    items: IAdvancedPointsTableDropdownItem[];
    isHidden: boolean;
    isInputMode: boolean;
    localization: LocalizationService;
    title?: string;
    valueChanged: (value: number) => void;
    commitValue: (value: number) => void;
    onClick?: (isOpened: boolean) => void;
}

interface IPointsTableCellDropdownState {
    selectedValue: number;
    isInputMode: boolean;
}

class PointsTableCellDropdown extends React.PureComponent<IPointsTableCellDropdownProps, IPointsTableCellDropdownState> {
    constructor(props: IPointsTableCellDropdownProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);

        this.state = {
            selectedValue: props.value,
            isInputMode: props.isInputMode
        };
    }

    override componentDidMount() {
        this.refreshState();
    }

    override componentDidUpdate(prevProps: IPointsTableCellDropdownProps) {
        if (this.props.value !== prevProps.value || this.props.isInputMode !== prevProps.isInputMode) {
            this.refreshState();
        }
    }

    public override render() {
        const rowIdString = this.props.rowId > 0 ? `${this.props.rowId}` : `add`;
        const controlId = `${this.props.parentControlName}-row-${rowIdString}-${this.props.friendlyName}`
        return (
            <Dropdown
                controlId={controlId}
                title={this.props.title}
                disabled={!this.props.isEditable}
                hidden={this.props.isHidden}
                size={Size.full}
                dropdownType={DropdownType.block}
                type={Dropdown}
                items={this.props.items}
                localization={this.props.localization}
                selectedValue={this.state.selectedValue}
                valueChanged={this.onChange}
                sizeClass={`no-padding ${this.className}`}
                onClick={this.onClick} />
        );
    }

    private get className(): string {
        const isExtended = this.props.items.some(item => item.isExtended);
        const prefix = `${this.props.friendlyName}-dropdown dropdown`;
        return isExtended ? `${prefix}-extended` : prefix;
    }

    private refreshState() {
        this.setState({
            selectedValue: this.props.value,
            isInputMode: this.props.isInputMode
        });
    }

    private onChange(value: number) {
        this.props.valueChanged(value);
        if (!this.state.isInputMode) {
            this.props.commitValue(value);
        }
    }

    private onClick(isOpened: boolean) {
        if (this.props.onClick != null)
            this.props.onClick(isOpened);
    }
}

interface IPointsTableInputProps {
    parentControlName: string;
    lastId: number;
    isEditable: boolean;
    item: IAdvancedPointTableAddInput;
    unitService: UnitService;
    localization: LocalizationService;
    valueChanged: (item: IAdvancedPointTableAddInput) => void;
    commitValue: (item: IAdvancedPointTableAddInput) => void;
    tableType?: AdvancedPointsTableType;
    rebarDiameters?: IAdvancedPointsTableDropdownItem[];
    bonds?: IAdvancedPointsTableDropdownItem[];
    shapes?: IAdvancedPointsTableDropdownItem[];
    onDiameterDropdownClick?: (isOpened: boolean, rowIndex: number) => void;
}

class PointsTableInput extends React.PureComponent<IPointsTableInputProps> {
    private xInput!: string;
    private yInput!: string;
    private diameterInput!: number;
    private shapeInput!: number;
    private bondInput!: number;

    private xIn!: HTMLInputElement;
    private yIn!: HTMLInputElement;

    constructor(props: IPointsTableInputProps) {
        super(props);

        this.onDiameterDropdownClick = this.onDiameterDropdownClick.bind(this);
    }

    public override render() {
        if (!this.props.isEditable) {
            return null;
        }

        this.xInput = this.props.item?.x ?? '';
        this.yInput = this.props.item?.y ?? '';
        this.diameterInput = this.props.item?.diameter ?? 0;
        this.shapeInput = this.props.item?.shape ?? 0;
        this.bondInput = this.props.item?.bond ?? 0;

        return (
            <div className={'table-row input-row'} id={`${this.props.parentControlName}-row-add`}>
                <div className={'id'} id={`${this.props.parentControlName}-row-add-id`}>{this.props.lastId}</div>

                {this.props.rebarDiameters && this.diameterInput > 0 &&
                    <PointsTableCellDropdown
                        friendlyName={`diameter`}
                        rowId={-1}
                        parentControlName={this.props.parentControlName}
                        value={this.diameterInput}
                        unitService={this.props.unitService}
                        valueChanged={this.onDiameterValueChanged.bind(this)}
                        commitValue={() => undefined}
                        isEditable={this.props.isEditable}
                        items={this.props.rebarDiameters}
                        isHidden={false}
                        isInputMode={true}
                        localization={this.props.localization}
                        onClick={this.onDiameterDropdownClick} />
                }

                <input
                    id={`${this.props.parentControlName}-row-add-x`}
                    data-id={`${this.props.tableType}-x`}
                    ref={(input) => { this.xIn = input!; }}
                    className={'number input'}
                    type='text'
                    value={this.xInput}
                    onChange={this.onChange.bind(this, 'x')}
                    onBlur={this.onBlur.bind(this, 'x')}
                    onKeyPress={this.onEnter.bind(this, 'x')}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    autoCapitalize={'off'}
                    spellCheck={false} />

                <input
                    id={`${this.props.parentControlName}-row-add-y`}
                    data-id={`${this.props.tableType}-y`}
                    ref={(input) => { this.yIn = input!; }}
                    className={'number input'}
                    type='text'
                    value={this.yInput}
                    onChange={this.onChange.bind(this, 'y')}
                    onBlur={this.onBlur.bind(this, 'y')}
                    onKeyPress={this.onEnter.bind(this, 'y')}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    autoCapitalize={'off'}
                    spellCheck={false} />

                {this.props.shapes && this.shapeInput > 0 &&
                    <PointsTableCellDropdown
                        friendlyName={'shape'}
                        parentControlName={this.props.parentControlName}
                        rowId={-1}
                        value={this.props.item.shape}
                        unitService={this.props.unitService}
                        isEditable={this.props.isEditable}
                        items={this.props.shapes}
                        isHidden={false}
                        isInputMode={false}
                        commitValue={() => undefined}
                        localization={this.props.localization}
                        valueChanged={this.onShapeValueChanged.bind(this)} />
                }

                {this.props.bonds && this.bondInput > 0 &&
                    <PointsTableCellDropdown
                        friendlyName={'bond'}
                        parentControlName={this.props.parentControlName}
                        rowId={-1}
                        value={this.props.item.bond}
                        unitService={this.props.unitService}
                        localization={this.props.localization}
                        isEditable={this.props.isEditable}
                        items={this.props.bonds}
                        isHidden={false}
                        isInputMode={false}
                        commitValue={() => undefined}
                        valueChanged={this.onBondValueChanged.bind(this)} />
                }
                <div className={'delete'}><button type='button' data-id='focus'></button></div>
            </div>
        );
    }

    private get object() {
        return {
            x: this.xInput,
            y: this.yInput,
            diameter: this.diameterInput,
            shape: this.shapeInput,
            bond: this.bondInput
        };
    }

    private onDiameterValueChanged(value: number) {
        this.onDropdownValueChanged(value, 'diameter');
    }

    private onShapeValueChanged(value: number) {
        this.onDropdownValueChanged(value, 'shape');
    }

    private onBondValueChanged(value: number) {
        this.onDropdownValueChanged(value, 'bond');
    }

    private onDropdownValueChanged(value: number, property: 'diameter' | 'shape' | 'bond') {
        const obj = this.object;
        obj[property] = value;
        this.props.valueChanged(obj);
    }

    private onChange(key: 'x' | 'y', event: React.FormEvent) {
        const obj = this.object;
        obj[key] = (event.target as HTMLInputElement).value;
        this.props.valueChanged(obj);
    }

    private onBlur(key: string, event: React.FocusEvent) {
        this.props.commitValue(this.object);

        let id: string = '';
        const elt = event.relatedTarget as HTMLElement;
        if (elt != null) {
            id = elt.dataset['id']!;
        }

        if (id == 'focus' || id == `${this.props.tableType}-y`) {
            this.focus();
        }
    }

    private onEnter(key: string, event: React.KeyboardEvent) {
        if (event.key == 'Enter') {
            this.props.commitValue(this.object);
            this.focus();
        }
    }

    private focus() {
        if (this.xInput == '' && this.yInput != '') {
            this.xIn.focus();
        }
        else if (this.xInput != '' && this.yInput == '') {
            this.yIn.focus();
        }
        else if (this.xInput != '' && this.yInput != '') {
            this.xIn.focus();
        }
    }

    private onDiameterDropdownClick(isOpened: boolean) {
        if (this.props.onDiameterDropdownClick != null)
            this.props.onDiameterDropdownClick(isOpened, -1);
    }
}

export const AdvancedPointsTable: typeof _AdvancedPointsTable & ContolsStyleSheets = _AdvancedPointsTable;
