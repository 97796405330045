import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    ButtonGroupDisplay, ButtonGroupType, ButtonSize, ButtonType, CustomControl, NavigationTabWidth,
    TooltipType
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { InfoLink } from '../../../shared/entities/code-lists/info-link';
import { Command, commandFromService, MainMenuCommandC2C } from '../../../shared/entities/command';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { ITemperatureTableProps } from '../../../shared/entities/main-menu/main-menu-controls';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    ConnectionType, DesignMethodGroup, LoadingDefinitionType
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { FeatureFlagTypes } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import { Control, RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { ApplicationTypeHelper } from '../../helpers/application-type-helper';
import { DesignMethodGroupHelper } from '../../helpers/design-method-group-helper';
import { getSpriteAsIconStyle } from '../../sprites';
import { CalculationServiceC2C } from '../calculation-c2c.service';
import { CodeListService } from '../code-list.service';
import { FeaturesVisibilityService } from '../features-visibility.service';
import { LocalizationService } from '../localization.service';
import { UnitService } from '../unit.service';
import { initializeTemperatureTable } from './custom-controls-helper/temperature-table';
import {
    checkConnectionType, getCommandConditional, getPopupTooltipConditional, isACICSA, isCSA,
    isOverlay, isOverlayEU, isPirEU
} from './static-menu-helper';

export function createC2CMenu_Loads(
    design: DesignC2C,
    codeList: CodeListService,
    featureVisibilityService: FeaturesVisibilityService,
    localizationService: LocalizationService,
    unitService: UnitService,
    calculationService: CalculationServiceC2C): TabC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads',
        Id: 5,
        Name: 'load-tab',
        Image: 'tab-loads',
        IconImage: getSpriteAsIconStyle('sprite-tab-loads'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createC2CMenu_Loads_LoadType(design, localizationService, unitService, codeList, calculationService),
            createC2CMenu_Loads_CrackWidth(featureVisibilityService),
            createC2CMenu_Loads_LoadDefinition(),
            createC2CMenu_Loads_Design(design, codeList, localizationService),
            createC2CMenu_Loads_AlternateDesign_ShearInterfaceVerification(design),
            createC2CMenu_Loads_FactoredLoadInput(design),
            createC2CMenu_Loads_OverlayStress(),
            createC2CMenu_Loads_OverlayPermanentNetCompression(design),
            createC2CMenu_Loads_OverlayUnfactoredPermanentLoad(design),
            createC2CMenu_Loads_OverlayLoadingDefinition(design),
            createC2CMenu_Loads_ShearDesignOptions(),
            createC2CMenu_Loads_ConsiderOfMinAndMaxReinforcement()
        ]
    };
}

function createC2CMenu_Loads_OverlayUnfactoredPermanentLoad(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACICSA',
        Id: 20,
        Name: 'overlay-unfactoredpermanentload-section',
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: design.designData.projectDesignC2C?.loads?.loadingDefinitionType == LoadingDefinitionType.ShearStress ? UnitGroup.Stress : UnitGroup.Force,
                Name: 'overlay-unfactoredpermanentload-input',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACICSA.PermanentNetCompression',
                UIPropertyId: 10286,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.CSA.PermanentNetCompression',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACICSA.PermanentNetCompression',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenPermanentNetCompressionPopup', isACICSA(design)),
            }]
    };
}

function createC2CMenu_Loads_OverlayLoadingDefinition(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadingDefinition',
        Id: 19,
        Name: 'overlay-loadingdefinition-section',
        Controls: [{
            ControlType: 'RadioButtonGroup',
            Name: 'loadinginputtype-radio-buttons',
            UIPropertyId: 10275,
            CodelistName: 'LoadingDefinitionTypes',
            DisplayKey: 'loading-definition-radio-buttons',
            TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadingDefinition.Title'
        },
        {
            ControlType: 'TextBox',
            UnitGroup: UnitGroup.None,
            Name: 'shearload-beta-textbox',
            Size: 50,
            TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadingDefinition.Beta.Title',
            UIPropertyId: 10276,
            TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadingDefinition.Beta.Tooltip',
            TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadingDefinition.Beta.Tooltip.Title',
            TooltipType: getPopupTooltipConditional(!isACICSA(design)),
            Command: getCommandConditional('OpenRatioBInfoBox', !isACICSA(design))
        },
        {
            ControlType: 'TextBox',
            UnitGroup: UnitGroup.Length,
            Name: 'shearload-z-textbox',
            Size: 50,
            TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadingDefinition.Z.Title',
            UIPropertyId: 10277,
            TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadingDefinition.Z.Tooltip',
            TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadingDefinition.Z.Tooltip.Title'
        }
        ]
    };
}

function createC2CMenu_Loads_ShearDesignOptions(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.ShearDesignOption.Title',
        Id: 20,
        Name: 'sheardesignoptions-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.ShearDesignOption.DeltaFs',
                Name: 'considerdeltafs-checkbox',
                UIPropertyId: 10637,
                TooltipType: TooltipType.Popup,
                Command: 'OpenShearDesignOptionDeltaFsPopup'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.ShearDesignOption.InterfaceShear',
                Name: 'interfaceshearcheck-checkbox',
                UIPropertyId: 10638,
                TooltipType: TooltipType.Popup,
                Command: 'OpenShearDesignOptionInterfacePopup'
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'ShearInterfaceType',
                Name: 'shear-design-options-dropdown',
                UIPropertyId: 10639
            }
        ]
    };
}

function createC2CMenu_Loads_LoadDefinition(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadDefinition',
        Id: 19,
        Name: 'loaddefinition-section',
        Controls: [{
            ControlType: 'RadioButtonGroup',
            Name: 'loaddefinition-radio-buttons',
            UIPropertyId: 10143,
            CodelistName: 'LoadDefinitionTypes',
            DisplayKey: 'load-definition-radio-buttons',
            TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadDefinition.LoadDefinitionType.Title'
        }, {
            ControlType: 'RadioButtonGroup',
            Name: 'loaddefinition-sections-radio-buttons',
            UIPropertyId: 10636,
            CodelistName: 'LoadDefinitionSections',
            DisplayKey: 'load-definition-section-radio-buttons',
            TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.LoadDefinition.LoadDefinitionType.Title'
        }, {
            ControlType: 'TextBox',
            Name: 'pireu-sustained-load-ratio-alphasus',
            TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.AlphaSus',
            UIPropertyId: 10699,
            TooltipType: TooltipType.Popup,
            Command: 'OpenAlphaSustained',
            UnitGroup: UnitGroup.None
        }]
    };
}

function createC2CMenu_Loads_Design(design: DesignC2C, codeList: CodeListService, localizationService: LocalizationService): RegionC2C {
    return {
        DisplayKey: loadsDesignTabText(design),
        Id: 15,
        Name: 'design-section',
        Controls: [
            {
                CodelistName: 'DesignMethodGroupsC2C',
                ControlType: 'Dropdown',
                Name: 'designmethod-dropdown',
                TitleDisplayKey: getDesignMethodGroupsControlTitleKey(design),
                UIPropertyId: 10006,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection.Tooltip.HNA' : 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection.Tooltip.Title',
                Command: isOverlayEU(design) ? 'OpenDesignMethodGroupsOverlayPopup' : getCommandConditional('OpenUseHiltiDesign', isHiltiMethodInfoPopupVisible(design, codeList)),
                TooltipTypeFunc: (design, condition = isHiltiMethodInfoPopupVisible((design as DesignC2C), codeList)) => {
                    return ((condition && (design as DesignC2C).designMethodGroup?.id == DesignMethodGroup.HiltiRebarDesign) || isOverlayEU(design as DesignC2C)) ? TooltipType.Popup : TooltipType.Normal;
                }
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Loads.UseGermanNationalAnnex',
                Name: 'use-german-national-annex',
                UIPropertyId: 10729,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Loads.UseGermanNationalAnnex.Tooltip.Description',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Loads.UseGermanNationalAnnex.Tooltip.Title'
            },
            {
                ControlType: 'CheckboxGroup',
                CodelistName: 'DesignMethodGroupsC2C',
                Name: 'designmethods-group',
                UIPropertyId: 10635,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection',
                TooltipType: getPopupTooltipConditional(isPirEU(design)),
                Command: getCommandConditional('OpenDesignMethodGroupsSelectionPopup', isPirEU(design)),
                OnStateChanged: params => DesignMethodGroupHelper.changeDesignMethodItemsForGermanNationalAnnex(params.control.items ?? [], params.design as DesignC2C, localizationService),
            },
            {
                ControlType: 'CheckboxGroup',
                CodelistName: 'DesignMethods',
                Name: 'designmethodsAs',
                UIPropertyId: 10811,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection',
                Command: 'OpenDesignMethodGroupsSelectionPopup',
                TooltipType: TooltipType.Popup,
            },
            ...createC2CMenu_Loads_Design_HnaDesignMethodControls(design),
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.DebugMode.DisableSc',
                Name: 'disableSc',
                UIPropertyId: 10690,
                TooltipDisplayKey: ''
            }]
    } as RegionC2C;
}

function createC2CMenu_Loads_Design_HnaDesignMethodControls(design: DesignC2C): Control[] {
    if (isHnaImprovementFeatureEnabled(design))
        return createC2CMenu_Loads_Design_NewHnaDesignMethodControls(design);

    return createC2CMenu_Loads_Design_OldHnaDesignMethodControls(design);
}


function createC2CMenu_Loads_Design_OldHnaDesignMethodControls(design: DesignC2C): Control[] {
    return [
        {
            ControlType: 'Checkbox',
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign',
            Name: 'desigforyield',
            UIPropertyId: 10446,
            TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign',
            TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign',
            TooltipType: getPopupTooltipConditional(isACICSA(design)),
            Command: getCommandConditional('OpenIsYieldDesignPopup', isACICSA(design)),

        },
        {
            ControlType: 'Checkbox',
            DisplayKey: anchorTheoryText(design),
            Name: 'displayanchortheorycalculationresults',
            UIPropertyId: 10447,
            TooltipDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed.CSA' : 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed.ACI',
            TooltipTitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed.HNA' : 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed',
            TooltipType: getPopupTooltipConditional(isHnaOverlayOrJoints(design)),
            Command: getCommandConditional('OpenAnchorTheoryPopup', isHnaOverlayOrJoints(design)),
        }
    ] as Control[];
}

function createC2CMenu_Loads_Design_NewHnaDesignMethodControls(design: DesignC2C): Control[] {
    return [
        {
            ControlType: 'Checkbox',
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.AllowResearchBased',
            Name: 'isresearchbased',
            UIPropertyId: 10787,
            TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.AllowResearchBased',
            TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.AllowResearchBased',
            TooltipType: TooltipType.Popup,
            Command: 'OpenAllowResearchBasedPopup'
        },
        {
            ControlType: 'Checkbox',
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign',
            Name: 'desigforyield',
            UIPropertyId: 10730,
            TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign',
            TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign',
            TooltipType: getPopupTooltipConditional(isHnaOverlayOrJoints(design)),
            Command: getCommandConditional('OpenIsYieldDesignPopup', isHnaOverlayOrJoints(design))
        },
        {
            ControlType: 'Checkbox',
            Name: 'rebarsplicetoexistingreinforcement',
            DisplayKey: 'Agito.Hilti.C2C.Reinforcement.SpliceToExistingReinforcement',
            UIPropertyId: 10453,
            TooltipType: getPopupTooltipConditional(isACICSA(design)),
            Command: getCommandConditional('OpenSpliceToExistingReinforcementPopup', isACICSA(design))
        },
        {
            ControlType: 'RadioButtonGroup',
            Name: 'splicesclasses-radio-buttons',
            UIPropertyId: 10454,
            Size: 50,
            CodelistName: 'SpliceClass',
            DisplayKey: 'splice-classes-radio-buttons',
            TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.SpliceClasses',
            TooltipType: TooltipType.Popup,
            Command: 'OpenSpliceClassesAlternativePopup',
        },
        {
            ControlType: 'CheckboxGroup',
            CodelistName: 'DesignMethods',
            Name: 'designmethods',
            UIPropertyId: 10788,
            TitleDisplayKey: isOverlay(design) ? 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.EmbedmentDepthOptions' : 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection',
        },
    ] as Control[];
}

function createC2CMenu_Loads_AlternateDesign_ShearInterfaceVerification(design: DesignC2C): RegionC2C {
    if (isHnaImprovementFeatureEnabled(design))
        return createC2CMenu_Loads_ShearInterfaceVerification();

    return createC2CMenu_Loads_AlternateDesign(design);
}

function createC2CMenu_Loads_AlternateDesign(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: (isHnaOverlayOrJoints(design)) ? 'Agito.Hilti.C2C.Navigation.TabLoads.DesignOptions' : 'Agito.Hilti.C2C.Navigation.TabLoads.AlternateDesign',
        Id: 16,
        Name: 'alternatedesign-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign',
                Name: 'desigforyieldalt',
                UIPropertyId: 10730,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenIsYieldDesignPopup', isACICSA(design))
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: anchorTheoryText(design),
                Name: 'displayanchortheorycalculationresultsalt',
                UIPropertyId: 10731,
                TooltipDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed.CSA' : 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed.ACI',
                TooltipTitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed.HNA' : 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed',
                TooltipType: getPopupTooltipConditional(isHnaOverlayOrJoints(design)),
                Command: getCommandConditional('OpenAnchorTheoryPopup', isHnaOverlayOrJoints(design)),
            }]
    };
}

function createC2CMenu_Loads_ShearInterfaceVerification(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.ShearInterfaceVerification',
        Id: 16,
        Name: 'alternatedesign-section',
        Controls: [
            {
                ControlType: 'RadioButtonGroup',
                Name: 'sheardesignmethods',
                UIPropertyId: 10789,
                CodelistName: 'DesignMethods',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection',
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.OptimizedLayout',
                Name: 'loads-optimized-layout',
                UIPropertyId: 10481,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Reinforcement.OptimizedLayout.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.OptimizedLayout.Tooltip.Title'
            },
            {
                ControlType: 'Group',
                TitleDisplayKey: 'Agito.Hilti.C2C.Connector.EmbedmentDepth.Title',
                DisplayKey: 'Agito.Hilti.C2C.Connector.EmbedmentDepth.Title',
                Name: 'product-tab-connector',
                TooltipType: TooltipType.Normal,
                UIPropertyId: 10066
            },
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'connector-embedmentdepth-mode',
                UIPropertyId: 10065,
                CodelistName: 'EmbedmentDepthMode',
                ParentControlName: 'product-tab-connector'
            },
            {
                UnitGroup: UnitGroup.Length,
                ControlType: 'TextBox',
                Name: 'connector-embedmentdepth',
                UIPropertyId: 10064,
                ParentControlName: 'product-tab-connector'
            }
        ]
    };
}

function createC2CMenu_Loads_FactoredLoadInput(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads',
        Id: 16,
        Name: 'factoredloadinput-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.ShowInputLoads',
                Name: 'showloadinputs-checkbox',
                UIPropertyId: 10012,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.ShowInputLoads.Tooltip.HNA' : 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.ShowInputLoads.Tooltip',
                TooltipTitleDisplayKey: isACICSA(design) ? '' : 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.ShowInputLoads.Tooltip.Title'
            },
            {
                ControlType: 'TextBox',
                Name: 'sustained-load-ratio-alphasus',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.AlphaSus',
                UIPropertyId: 10010,
                TooltipType: TooltipType.Popup,
                Command: 'OpenAlphaSustained',
                UnitGroup: UnitGroup.None
            }]
    };
}

function createC2CMenu_Loads_OverlayStress(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress',
        Id: 17,
        Name: 'overlay-stress-section',
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Stress,
                Name: 'overlay-stress-input',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.NormalStress',
                UIPropertyId: 10018,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.NormalStress.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.NormalStress.Tooltip.Title'
            }]
    };
}

function createC2CMenu_Loads_OverlayPermanentNetCompression(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACICSA',
        Id: 19,
        Name: 'overlay-permanentnetcompression-section',
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: design.designData.projectDesignC2C?.loads?.loadingDefinitionType == LoadingDefinitionType.ShearStress ? UnitGroup.Stress : UnitGroup.Force,
                Name: 'overlay-permanentnetcompression-input',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACICSA.PermanentNetCompression',
                UIPropertyId: 10285,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACI.PermanentNetCompression',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACICSA.PermanentNetCompression',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenPermanentNetCompressionPopup', isACICSA(design))
            }]
    };
}
function createC2CMenu_Loads_CrackWidth(featureVisibilityService: FeaturesVisibilityService): RegionC2C {
    if (!featureVisibilityService.isFeatureEnabled('C2C_TR069Seismic')) {
        return {
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.CrackWidth.Title',
            Id: 22,
            Name: 'crackwidth-section',
            Controls: []
        };
    }

    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.CrackWidth.Title',
        Id: 22,
        Name: 'crackwidth-section',
        TooltipType: TooltipType.Popup,
        Command: 'OpenCrackWidthPopup',
        Controls: [{
            CodelistName: 'CrackWidth',
            ControlType: 'Dropdown',
            Name: 'crack-width-dropdown',
            Size: 100,
            TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.CrackWidth.Wk',
            UIPropertyId: 10752
        }]
    };
}

function createC2CMenu_Loads_ConsiderOfMinAndMaxReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.ConsiderOfMinAndMaxReinforcement.Title',
        Id: 21,
        Name: 'considerofminandmaxreinforcement-section',
        Controls: [
            {
                UIPropertyId: 10640,
                ControlType: 'Checkbox',
                Name: 'minreinforcement-checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.ConsiderMinReinforcement.Title',
                Command: 'OpenMinAreaReinforcementItemsC2C',
                TooltipType: TooltipType.Popup
            },
            {
                UIPropertyId: 10641,
                ControlType: 'Checkbox',
                Name: 'maxreinforcement-checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.ConsiderMaxReinforcement.Title',
                TooltipType: TooltipType.Popup,
                Command: 'OpenMaxAreaReinforcementPopup'
            },
            {
                ControlType: 'Button',
                ButtonSize: ButtonSize.Full,
                ButtonType: ButtonType.Default,
                Name: 'definitionofminandmaxreinforcement-button',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.DefinitionOfMinMaxReinforcement',
                UIPropertyId: 10643,
                Command: 'OpenDefinitionOfMinMaxReinforcement'
            }
        ]
    };
}

function createC2CMenu_Loads_LoadType(
    design: DesignC2C,
    localizationService: LocalizationService,
    unitService: UnitService,
    codelistService: CodeListService,
    calculationService: CalculationServiceC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionLoads',
        Id: 14,
        Name: 'loadtype-section',
        Controls: [
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupDisplay: ButtonGroupDisplay.Join,
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'loadtype-buttons',
                UIPropertyId: 10013,
                CodelistName: 'LoadType',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Loads.LoadType',
                TooltipType: TooltipType.Normal
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 900,
                Name: 'fatigue-partofstaticload',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.StaticLoad',
                UIPropertyId: 10008,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.StaticLoad.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.StaticLoad.Tooltip.Title'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Stress,
                Name: 'fatigue-maxamplitude',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.MaxAmplitude',
                UIPropertyId: 10009,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.MaxAmplitude.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.MaxAmplitude.Tooltip.Title'
            },
            {
                CodelistName: 'ApplicationTypesC2C',
                ControlType: 'Dropdown',
                Name: 'fireapplication-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.FireApplicationType',
                UIPropertyId: 10753,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                OnDropdownStateChanged: params => ApplicationTypeHelper.changeApplicationTypeItemsForExtensionAtSupport(params.control.items ?? [], params.design as DesignC2C, localizationService)
            },
            {
                ControlType: 'Checkbox',
                Name: 'fireapplication-extensionatsupport',
                UIPropertyId: 10783,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.ExtensionAtSupport',
            },
            {
                ControlType: 'RadioButtonGroup',
                Name: 'fire-input-type-radio-buttons',
                UIPropertyId: 10701,
                CodelistName: 'FireInputTypes',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.FireInputType',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.FireInputType.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.FireInputType.Tooltip.Title',
            },
            {
                ControlType: 'CustomControl',
                /* eslint-disable @typescript-eslint/no-unused-vars */
                initialize: (controlProps: ITemperatureTableProps, _modals: Record<number, (input?: object) => IModalOpened>, _setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) => initializeTemperatureTable(controlProps, unitService, design, 10705, codelistService, calculationService, localizationService),
                Name: 'fire-temperature-by-pir-table',
                UIPropertyId: 10705
            } as unknown as CustomControl<ITemperatureTableProps, MainMenuCommandC2C>,
            {
                ControlType: 'CustomControl',
                /* eslint-disable @typescript-eslint/no-unused-vars */
                initialize: (controlProps: ITemperatureTableProps, _modals: Record<number, (input?: object) => IModalOpened>, _setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) => initializeTemperatureTable(controlProps, unitService, design, 10706, codelistService, calculationService, localizationService),
                Name: 'fire-temperature-by-pir-intersection-table',
                UIPropertyId: 10706
            } as unknown as CustomControl<ITemperatureTableProps, MainMenuCommandC2C>,
            {
                CodelistName: 'FireDuration',
                ControlType: 'Dropdown',
                Name: 'fire-resistanceduration-dropdown',
                Size: 100,
                TitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.FireResistance.HNA' : 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.FireResistance',
                UIPropertyId: 10011,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.FireResistance.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.FireResistance.Tooltip.Title',
                TooltipType: TooltipType.Popup,
                Command: 'OpenFireResistanceDurationPopup'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Stress,
                Name: 'fire-maxsteelstress',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.MaxSteelStress',
                UIPropertyId: 10007,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.MaxSteelStress.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionMultipleLoads.MaxSteelStress.Tooltip.Title'
            },
            {
                CodelistName: 'DesignForYield',
                ControlType: 'Dropdown',
                Name: 'loadtype-designforyield-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignForYield',
                UIPropertyId: 10144,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignForYield.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignForYield.Tooltip.Title',
                TooltipType: TooltipType.Popup,
                Command: 'OpenDesignForYieldPopup'
            },
            {
                CodelistName: 'DesignWorkingLife',
                ControlType: 'Dropdown',
                Name: 'loadtype-designworkinglife-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.DesignWorkingLife',
                UIPropertyId: 10634,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.DesignWorkingLife.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.DesignWorkingLife.Tooltip.Title'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.None,
                Name: 'seismic-load-multiplier',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.SeismicLoadMultiplier',
                UIPropertyId: 10734,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.SeismicLoadMultiplier.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.SeismicLoadMultiplier.Tooltip.Title',
                FixedDecimals: true
            },
            {
                ControlType: 'Checkbox',
                Name: 'special-moments-frame',
                UIPropertyId: 10735,
                DisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabLoads.SpecialMomentsFrame.CSA' : 'Agito.Hilti.C2C.Navigation.TabLoads.SpecialMomentsFrame.ACI',
                TooltipTitleDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabLoads.SpecialMomentsFrame.CSA' : 'Agito.Hilti.C2C.Navigation.TabLoads.SpecialMomentsFrame.ACI',
                TooltipDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabLoads.SpecialMomentsFrame.CSA' : 'Agito.Hilti.C2C.Navigation.TabLoads.SpecialMomentsFrame.ACI',
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Length,
                Name: 'confined-core-depth',
                Size: 50,
                UIPropertyId: 10736,
            },
            {
                ControlType: 'RadioButtonGroup',
                Name: 'category-radio-buttons',
                UIPropertyId: 10252,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.Category.Title',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.Category.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.Category.Tooltip.Title',
                CodelistName: 'LoadTypeCategory'
            },
            {
                ControlType: 'Checkbox',
                Name: 'failure-mode-existing-concrete-dropdown',
                UIPropertyId: 10250,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.FailureModeConcrete.Title',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.FailureModeConcrete.Tooltip.Title',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.FailureModeConcrete.Tooltip'
            },
            {
                ControlType: 'Checkbox',
                Name: 'failure-mode-overlay-dropdown',
                UIPropertyId: 10251,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.FailureModeOverlay.Title',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.FailureModeOverlay.Tooltip.Title',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabLoads.FailureModeOverlay.Tooltip'
            }
        ]
    };
}

function isHnaImprovementFeatureEnabled(design: DesignC2C) {
    return design.isFeatureEnabled(FeatureFlagTypes.HnaDesignMethods);
}

function getDesignMethodGroupsControlTitleKey(design: DesignC2C) {
    const isHnaWithFeature = design.isC2CHNA && isHnaImprovementFeatureEnabled(design);

    return isHnaWithFeature
        ? 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignCodeSelection'
        : 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection';
}

function isHiltiMethodInfoPopupVisible(design: DesignC2C, codeList: CodeListService) {
    if (!isACICSA(design)) {
        return false;
    }

    const infoLinks = codeList.projectCodeListsC2C[ProjectCodeList.InfoLinkC2C] as InfoLink[];
    return infoLinks.some(x => commandFromService(x.commandId as Command) == 'OpenUseHiltiDesign' && x?.regionLinks?.find(y => y.regionId == design.region.id) != null);
}

function loadsDesignTabText(design: DesignC2C): string {
    if (isACICSA(design) && checkConnectionType(design, ConnectionType.ConcreteOverlay) && !isHnaImprovementFeatureEnabled(design)) {
        return 'Agito.Hilti.C2C.Navigation.TabLoads.DesignOptions';
    }

    return isACICSA(design)
        ? 'Agito.Hilti.C2C.Navigation.TabLoads.DesignMethod'
        : 'Agito.Hilti.C2C.Navigation.TabLoads.Design';
}

function anchorTheoryText(design: DesignC2C): string {
    if (isHnaOverlayOrJoints(design)) {
        return 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed.HNAJointsOrOverlay';
    }

    return isACICSA(design)
        ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed.HNA'
        : 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsAnchorTheoryDisplayed';
}

function isHnaOverlayOrJoints(design: DesignC2C): boolean {
    return (isACICSA(design) && (checkConnectionType(design, ConnectionType.ConcreteOverlay) || checkConnectionType(design, ConnectionType.StructuralJoints)));
}
