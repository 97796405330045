import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    IAdvancedRebarSettingsInputsData
} from '../../../shared/entities/advanced-rebar-settings';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UIProperty } from '../../../shared/entities/code-lists/UI-property';
import { IPropertyMetaDataC2C, PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { CodeListService as CodeListServiceC2C } from '../../services/code-list.service';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { RebarSafetyFactorsRegion } from '../../../shared/entities/code-lists/rebar-safety-factory-region';
import clone from 'lodash-es/clone';

interface INumericTextBoxProperty {
    defaultValue: number;
    maxValue?: number;
    minValue?: number;
    precision: number;
    unit: Unit;
}

@Component({
    templateUrl: './advanced-rebar-settings-inputs.component.html',
    styleUrls: ['./advanced-rebar-settings-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AdvancedRebarSettingsInputsComponent implements OnInit, DoCheck {
    @Input()
    public data!: IAdvancedRebarSettingsInputsData;

    @Input()
    public disabled!: boolean;
    public dataLoaded = false;

    public advancedRebarSettingsSteelStrainLimitTextBox!: NumericTextBoxProps;

    private oldData!: IAdvancedRebarSettingsInputsData;

    private get defaultEpsilonUd(): number {
        const safetyFactors = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.RebarSafetyFactorsRegionC2C] as RebarSafetyFactorsRegion[];
        return safetyFactors.find(sf => sf.regionId == this.data.regionId)?.defaultEpsilonUd ?? 0;
    }

    constructor(
        private localizationService: LocalizationService,
        private codeListC2C: CodeListServiceC2C,
        private unit: UnitService
    ) { }

    ngOnInit(): void {
        this.initYieldStrengthControl();
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setDefaultValue();
            this.refreshControl();

            this.dataLoaded = true;
        }

        this.updateData();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private getNumericTextBoxProperty(propertyMetaData: IPropertyMetaDataC2C, unit: Unit, defaultValue: number | undefined = undefined): INumericTextBoxProperty {
        const c2cUIProperties = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.UIPropertiesC2C] as UIProperty[];
        const propertyValues = c2cUIProperties.find((property) => property.id == propertyMetaData.id);
        let propertyValue = propertyValues?.propertyValues?.find((property) => property.regionId == this.data.regionId);

        if (!propertyValue) {
            propertyValue = propertyValues?.propertyValues?.find((property) => property.regionId == 0);
        }

        return {
            defaultValue: defaultValue != undefined ? defaultValue : propertyValue?.defaultValue ?? 0,
            maxValue: propertyValue?.maxValue,
            minValue: propertyValue?.minValue,
            precision: this.unit.getPrecision(unit),
            unit: unit
        };
    }

    private initYieldStrengthControl() {
        this.advancedRebarSettingsSteelStrainLimitTextBox = {
            id: `advanced-rebar-settings-advancedRebarSettingsSteelStrainLimit-textbox`,
            title: this.translate(`Agito.Hilti.C2C.ApplicationSettings.AdvancedRebarSettingsSteelStrainLimit`),
            tooltip: this.translate(`Agito.Hilti.C2C.ApplicationSettings.AdvancedRebarSettingsSteelStrainLimit.Tooltip`)
        };
    }

    private updateData() {
        let update = false;

        if (this.oldData != null) {
            if (this.oldData.regionId != this.data.regionId) {
                update = true;
                this.onRegionChange();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);
        }
    }

    private onRegionChange() {
        this.refreshControl();
    }

    private setDefaultValue() {
        if (this.data.design != null) {
            const design = this.data.design();
            this.data.advancedRebarSettingsSteelStrainLimit = design.advancedRebarSettingsSteelStrainLimit;
        }
    }

    private refreshControl() {
        const steelStrainLimitProperty = this.getNumericTextBoxProperty(PropertyMetaDataC2C.Option_C2C_AdvancedRebarSettingsSteelStrainLimit, Unit.None, this.defaultEpsilonUd);

        this.advancedRebarSettingsSteelStrainLimitTextBox.maxValue = steelStrainLimitProperty.maxValue;
        this.advancedRebarSettingsSteelStrainLimitTextBox.minValue = steelStrainLimitProperty.minValue;
        this.advancedRebarSettingsSteelStrainLimitTextBox.precision = steelStrainLimitProperty.precision;
        this.advancedRebarSettingsSteelStrainLimitTextBox.placeholder = this.getTextBoxPlaceholder(steelStrainLimitProperty.defaultValue, steelStrainLimitProperty.unit, steelStrainLimitProperty.precision);
    }

    private getTextBoxPlaceholder(value: number, unit: Unit, defaultPrecision: number) {

        const result = this.unit.formatUnitValueArgs(
            this.unit.convertUnitValueArgsToUnit(
                value,
                unit,
                unit
            ),
            unit,
            defaultPrecision
        );

        if (result == null) {
            return this.translate('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }

        return result;
    }
}
