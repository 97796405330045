<pe-modal-header [modalInstance]="modalInstance" [title]="dataHelper.getTitle()" [titleStyle]="dataHelper.getTitleStyle()"></pe-modal-header>

<pe-modal-body>
    <div *ngFor="let item of items">
        <div *ngIf="isItemSubHeading(item)" class="info-dialog-subheading" [style]="item.style">
            <span class="info-dialog-subheading" l10n="{{item.value}}"></span>
            <span *ngIf="objectContainsProperty(item, 'appendText')" class="info-dialog-subheading">{{ item.appendText }}</span>
        </div>
        <div *ngIf="isItemText(item)" class="info-dialog-description" [style]="item.style">
            <span class="info-dialog-description" l10n="{{item.value}}"></span>
            <span *ngIf="objectContainsProperty(item, 'appendText')" class="info-dialog-description">{{ item.appendText }}</span>
        </div>
        <div *ngIf="isItemListItem(item)" class="info-dialog-list-item" [style]="item.style">
            &bull;&nbsp;
            <span class="info-dialog-list-item" l10n="{{item.value}}"></span>
            <span *ngIf="objectContainsProperty(item, 'appendText')" class="info-dialog-list-item">{{ item.appendText }}</span>
        </div>
        <div *ngIf="isItemImage(item)" class="info-dialog-image" [style]="item.style">
            <img src="{{item.value}}" alt="image">
        </div>
        <div *ngIf="isItemLinkTitle(item)" [style]="item.style">
            <a [href]="infoLinkValue" target="_blank"><span l10n="{{infoLinkTitle}}"></span></a>
        </div>
        <div *ngIf="isItemTable(item)" [style]="item.style">
            <c2c-info-dialog-table [table]="table"></c2c-info-dialog-table>
        </div>
        <div *ngIf="isItemUnorderedList(item)" [style]="item.style">
            <c2c-info-dialog-unordered-list [unorderedList]="unorderedList"></c2c-info-dialog-unordered-list>
        </div>
        <div *ngIf="isItemLineBreak(item)">
            <br>
        </div>
        <div *ngIf="isItemThematicBreak(item)">
            <hr>
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button id="info-dialog-ok-button" [look]="'Primary'" (buttonClick)="close()" [text]="dataHelper.translate('Agito.Hilti.Profis3.Ok')">
    </pe-button>
</pe-modal-footer>
