import { IModelC2C } from '@profis-engineering/c2c-gl-model/gl-model';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/c2c-gl-model/base-update';

import {
    UIProperty as UIPropertyC2C
} from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';

export class MomentUpdate extends BaseUpdate {

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_MomentX)
    private Loads_MomentXC2C(value: number, model: IModelC2C) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads is undefined');
        }

        model.moment.loads.design.X = value;
    }

    @propertyValueChanged(UIPropertyC2C.Loads_C2C_MomentY)
    private Loads_MomentYC2C(value: number, model: IModelC2C) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads is undefined');
        }

        model.moment.loads.design.Y = value;
    }
}
