import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { environment } from '../../../environments/environmentC2C';
import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';
import { UserService } from '../../services/user.service';

interface IMultipleApprovalsComponentInput {
  files: string[];
  approvalLinks?: string[];
}

@Component({
  templateUrl: './multiple-approvals.component.html',
  styleUrls: ['./multiple-approvals.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class MultipleApprovalsComponent {

  @Input()
  public modalInstance!: ModalInstance<IMultipleApprovalsComponentInput>;

  constructor(
    private user: UserService,
    private offline: OfflineService,
    private localizationService: LocalizationService
  ) { }

  public close() {
    this.modalInstance.close();
  }

  public translate(key: string) {
    return this.localizationService.getString(key);
  }

  public openApproval(index: number) {
    const design = this.user.design;
    design.usageCounterC2C.Approval++;

    const openUrl = `${environment.baseUrl}approvals/${this.approvalLinks[index]}`;
    this.offline.nativeLocalPathOpen(openUrl, this.files[index].toString(), true, true);
  }

  public get files() {
    return this.modalInstance.input?.files ?? [];
  }

  private get approvalLinks() {
    return this.modalInstance.input?.approvalLinks ?? [];
  }
}

