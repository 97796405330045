<div class="optimization-strategy-parameter content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control-optimizationStrategy">
                <pe-dropdown *ngIf="dataLoaded"
                [id]="optimizationStrategy.id"
                [title]="optimizationStrategy.title"
                [items]="optimizationStrategyItems"
                [selectedValue]="optimizationStrategyValue"
                (selectedValueChange)="optimizationStrategyChanged($any($event).detail)">
                </pe-dropdown>
            </div>
        </div>
    </div>
</div>
