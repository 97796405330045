import { DesignMethod } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';

export class DesignMethodHelper {
    public static mapDesignMethodToString(method: DesignMethod) {
        switch (method) {
            case DesignMethod.EN199211:
                return 'EN199211';
            case DesignMethod.EN199211StrutAndTie:
                return 'EN199211StrutAndTie';
            case DesignMethod.TR069:
                return 'TR069';
            case DesignMethod.HiltiMethodAnchorage:
                return 'HiltiMethodAnchorage';
            case DesignMethod.HiltiStrutsAndTies:
                return 'HiltiStrutsAndTies';
            case DesignMethod.AS3600:
                return 'AS3600';
            case DesignMethod.AnchoringBarYield:
                return 'AnchoringBarYield';
            case DesignMethod.DevelopmentLength:
                return 'DevelopmentLength';
            case DesignMethod.AnchoringExternalLoads:
                return 'AnchoringExternalLoads';
            case DesignMethod.HiltiMethodRebarDesign:
                return 'HiltiRebarDesign';
            case DesignMethod.LapSpliceLength:
                return 'LapSpliceLength';
            case DesignMethod.ShearFriction:
                return 'ShearFriction';
            case DesignMethod.AEFACTN08:
                return 'AEFACTN08';
            case DesignMethod.HiltiMethod:
                return 'HiltiMethod';
            default:
                throw new Error('Unknown design method!');
        }
    }
}