<div class="concrete-material-inputs content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control-concreteMaterial" *ngIf="!useAusOptions">
                <pe-numeric-text-box
                    [id]="concreteAlphaCc.id"
                    [title]="concreteAlphaCc.title"
                    [placeholder]="concreteAlphaCc.placeholder"
                    [value]="data.concreteAlphaCc"
                    (valueChange)="data.concreteAlphaCc = $any($event).detail"
                    [unit]="concreteAlphaCc.unit"
                    [minValue]="concreteAlphaCc.minValue"
                    [maxValue]="concreteAlphaCc.maxValue"
                    [disabled]="disabled"
                    [tooltip]="concreteAlphaCc.tooltip"
                    [precision]="concreteAlphaCc.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-concreteMaterial">
                <pe-numeric-text-box
                    [id]="concreteEpsilonC2.id"
                    [title]="concreteEpsilonC2.title"
                    [placeholder]="concreteEpsilonC2.placeholder"
                    [value]="epsilonC2Value"
                    (valueChange)="onEpsilonC2Change($any($event).detail)"
                    [unit]="concreteEpsilonC2.unit"
                    [minValue]="concreteEpsilonC2.minValue"
                    [maxValue]="concreteEpsilonC2.maxValue"
                    [disabled]="disabled"
                    [tooltip]="concreteEpsilonC2.tooltip"
                    [precision]="concreteEpsilonC2.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-concreteMaterial">
                <pe-numeric-text-box
                    [id]="concreteEpsilonCu2.id"
                    [title]="concreteEpsilonCu2.title"
                    [placeholder]="concreteEpsilonCu2.placeholder"
                    [value]="epsilonCu2Value"
                    (valueChange)="onEpsilonCu2Change($any($event).detail)"
                    [unit]="concreteEpsilonCu2.unit"
                    [minValue]="concreteEpsilonCu2.minValue"
                    [maxValue]="concreteEpsilonCu2.maxValue"
                    [disabled]="disabled"
                    [tooltip]="concreteEpsilonCu2.tooltip"
                    [precision]="concreteEpsilonCu2.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-concreteMaterial" *ngIf="!useAusOptions">
                <pe-numeric-text-box
                    [id]="concreteAlphaCt.id"
                    [title]="concreteAlphaCt.title"
                    [placeholder]="concreteAlphaCt.placeholder"
                    [value]="data.concreteAlphaCt"
                    (valueChange)="data.concreteAlphaCt = $any($event).detail"
                    [unit]="concreteAlphaCt.unit"
                    [minValue]="concreteAlphaCt.minValue"
                    [maxValue]="concreteAlphaCt.maxValue"
                    [disabled]="disabled"
                    [tooltip]="concreteAlphaCt.tooltip"
                    [precision]="concreteAlphaCt.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-concreteMaterial" *ngIf="useAusOptions">
                <pe-numeric-text-box
                    [id]="beta.id"
                    [title]="beta.title"
                    [placeholder]="beta.placeholder"
                    [value]="data.concreteMaterialOptionsAs.beta"
                    (valueChange)="data.concreteMaterialOptionsAs.beta = $any($event).detail"
                    [unit]="beta.unit"
                    [minValue]="beta.minValue"
                    [maxValue]="beta.maxValue"
                    [disabled]="disabled"
                    [tooltip]="beta.tooltip"
                    [precision]="beta.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-concreteMaterial">
                <pe-numeric-text-box
                    [id]="concreteMinCover.id"
                    [title]="concreteMinCover.title"
                    [placeholder]="concreteMinCover.placeholder"
                    [value]="minConcreteCoverValue"
                    (valueChange)="onMinConcreteCoverChange($any($event).detail)"
                    [unit]="concreteMinCover.unit"
                    [minValue]="concreteMinCover.minValue"
                    [maxValue]="concreteMinCover.maxValue"
                    [disabled]="disabled"
                    [tooltip]="concreteMinCover.tooltip"
                    [precision]="concreteMinCover.precision">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
