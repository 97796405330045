import { Injectable } from '@angular/core';
import { IntroJs, TourServiceInjected } from '@profis-engineering/pe-ui-common/services/tour.common';
import { IVirtualTour } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { UserSettingsService } from './user-settings.service';
import { ConnectionType, DesignStandard } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { UserService } from './user.service';
import { LocalizationService } from './localization.service';
import { ILocalizationExtension } from '@profis-engineering/pe-ui-common/services/extensions.common';

@Injectable({
    providedIn: 'root'
})
export class TourService extends TourServiceInjected {
    private activeRebarUnlockTour: IntroJs | null = null;

    constructor(
        private userSettingsService: UserSettingsService,
        private userService: UserService,
        private localization: LocalizationService
    ) {
        super();
    }

    public initialize() {
        this.baseService.localizationExtension = {
            getTranslation: this.localization.getString.bind(this.localization)
        } as ILocalizationExtension;
    }

    public getVirtualTours(): IVirtualTour[] {
        return [
            // Overlay virtual tour
            {
                name: 'OverlayWalktrough',
                order: 1,
                buttonKey: 'Agito.Hilti.Profis3.VirtualTour.OverlayWalktrough',
                buttonText: this.localization.getString('Agito.Hilti.Profis3.VirtualTour.OverlayWalktrough'),
                openTour: this.overlayTour.bind(this),
                isAvailable: () => {
                    return this.userService.design?.connectionType == ConnectionType.ConcreteOverlay && this.userService.design?.designStandardC2C?.id == DesignStandard.ETAG;
                },
                alreadySeen: () => {
                    return this.userSettingsService.settings.overlayvirtualtourSeen.value ?? false;
                },
                markAsSeen: async () => {
                    this.userSettingsService.settings.overlayvirtualtourSeen.value = true;
                    await this.userSettingsService.save();
                }
            },
            // Unlock rebar tour
            {
                name: 'TourUnlockRebar',
                order: 2,
                openTour: this.unlockRebarTour.bind(this),
                isAvailable: () => { return false; },
                alreadySeen: () => { return false; },
                markAsSeen: () => { return Promise.resolve(); },
            }
        ];
    }

    public overlayTour() {
        const title = 'Agito.Hilti.Profis3.VirtualTour.OverlayWalktrough';
        const numberOfSteps = 4;
        const menuContainer = this.rootElement?.querySelector('.menu-container')?.shadowRoot ?? undefined;

        return this.start({
            highlightClass: 'no-background',
            steps: [
                {
                    element: this.rootElement?.querySelector('.main-header') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.C2C.TourMainPage.MessageHeader', numberOfSteps, 1),
                    position: 'bottom-middle-aligned',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: menuContainer?.querySelector('.tab-menu') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.C2C.TourMainPage.TabsMenu', numberOfSteps, 2),
                    position: 'right',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.loads-container-wrapper') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.C2C.TourMainPage.Loads', numberOfSteps, 3),
                    position: 'auto',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.main-content-right-util') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.C2C.TourMainPage.Results', numberOfSteps, 4),
                    position: 'left',
                    tooltipClass: 'virtual-tour-popup-modal'
                }
            ]
        });
    }

    public unlockRebarTour() {
        if (this.activeRebarUnlockTour != null) {
            return this.activeRebarUnlockTour;
        }

        const title = 'Agito.Hilti.C2C.TourUnlockRebar.Title';
        const menuContainer = this.rootElement?.querySelector('.menu-container')?.shadowRoot ?? undefined;

        const tour = this.start({
            highlightClass: 'no-background',
            steps: [
                {
                    element: () => menuContainer?.querySelector('[data-control-id^="region-calculation-mode"]') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.C2C.TourUnlockRebar.Message'),
                    position: 'right',
                    tooltipClass: 'virtual-tour-popup-modal',
                },
                // The below step is added because version of IntroJS currently being used is not working properly with one step
                // The next button will be hidden in this case to make it work like a single step
                {
                    element: menuContainer?.querySelector('[data-control-id^="region-calculation-mode"]') as HTMLElement,
                    intro: '',
                    position: 'right',
                    tooltipClass: 'virtual-tour-popup-modal'
                }
            ],
            hideNextButton: true
        });

        tour.onexit(() => {
            this.activeRebarUnlockTour = null;
        });

        this.activeRebarUnlockTour = tour;
        return tour;
    }

    private get rootElement() {
        return document.documentElement.querySelector('c2c-main')?.shadowRoot;
    }
}
