import { ImportLoadsNumberTypeBase } from '@profis-engineering/pe-ui-common/entities/import-loads-number-type-base';

/*
   Note: Do not change order of properties, it is used to store user settings data.
   If you have to change the order, prepare user settings service script to properly update all settings.

   IMPORTANT: When adding a new type, make sure that the order number of the new type is the largest of all Import loads types:(ImportLoadsNumberTypeBase in pe-ui-common, ImportLoadsNumberType in pe-ui-c2c, ImportLoadsNumberType in pe-ui-pe)
*/
export class ImportLoadsNumberType extends ImportLoadsNumberTypeBase {
    public static readonly Zone1 = 24;
    public static readonly Zone1Minus = 25;
    public static readonly Zone2 = 26;
    public static readonly Zone2Minus = 27;
    public static readonly Zone3 = 28;
    public static readonly Zone3Minus = 29;
    public static readonly Zone4 = 30;
    public static readonly Zone4Minus = 31;
    public static readonly ZoneGeneric = 32;
    public static readonly ZoneGenericMinus = 33;
    public static readonly Zone1Static = 34;
    public static readonly Zone1MinusStatic = 35;
    public static readonly Zone2Static = 36;
    public static readonly Zone2MinusStatic = 37;
    public static readonly Zone3Static = 38;
    public static readonly Zone3MinusStatic = 39;
    public static readonly Zone4Static = 40;
    public static readonly Zone4MinusStatic = 41;
    public static readonly ZoneGenericStatic = 42;
    public static readonly ZoneGenericMinusStatic = 43;
    public static readonly Zone1Dynamic = 44;
    public static readonly Zone1MinusDynamic = 45;
    public static readonly Zone2Dynamic = 46;
    public static readonly Zone2MinusDynamic = 47;
    public static readonly Zone3Dynamic = 48;
    public static readonly Zone3MinusDynamic = 49;
    public static readonly Zone4Dynamic = 50;
    public static readonly Zone4MinusDynamic = 51;
    public static readonly ZoneGenericDynamic = 52;
    public static readonly ZoneGenericMinusDynamic = 53;
    public static readonly BottomForceZ = 54;
    public static readonly BottomForceZMinus = 55;
    public static readonly TedX = 56;
    public static readonly TedXMinus = 57;
    public static readonly TedY = 58;
    public static readonly TedYMinus = 59;
}
