import { Component, Input, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignC2C as Design } from '../../../shared/entities/design-c2c';
import { CollapsingControls } from '../../../shared/enums/collapsing-controls';
import { FeatureFlagTypes } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import {
    ProjectDesignEntityC2C, UIProperty
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ConnectionType, DesignMethod, DesignMethodGroup, LoadType
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environmentC2C';
import { DesignMethodHelper } from '../../helpers/design-method-helper';

@Component({
    templateUrl: './design-method.component.html',
    styleUrls: ['./design-method.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DesignMethodComponent implements OnInit {
    public CollapsingControls = CollapsingControls;

    @Input()
    public id: string;

    @Input()
    public design: Design;

    public collapsed = false;
    public unitGroupEnum = {
        Length: UnitGroup.Length
    };

    public designMethodEnum = {
        EN199211: DesignMethod.EN199211,
        EN199211StrutAndTie: DesignMethod.EN199211StrutAndTie,
        TR069: DesignMethod.TR069,
        AS3600: DesignMethod.AS3600,
        HiltiMethodAnchorage: DesignMethod.HiltiMethodAnchorage,
        HiltiStrutsAndTies: DesignMethod.HiltiStrutsAndTies,
        DevelopmentLength: DesignMethod.DevelopmentLength,
        LapSpliceLength: DesignMethod.LapSpliceLength,
        AnchoringBarYield: DesignMethod.AnchoringBarYield,
        AnchoringExternalLoads: DesignMethod.AnchoringExternalLoads,
        AEFACTN08: DesignMethod.AEFACTN08
    };

    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        public unit: UnitService,
        userService: UserService,
        private ngZone: NgZone
    ) {
        this.id = '';
        this.design = userService.design;
    }

    public get hasScopeChecksC2CErrors(): boolean {
        const scopeChecks = this.design.designData.reportDataC2C?.scopeChecks;
        if (scopeChecks == undefined)
            return false;

        return scopeChecks.filter(sc => sc.indicatesCalculationError).length > 0 ?? false;
    }

    public get isSplices() {
        return this.design.connectionType == ConnectionType.Splices;
    }

    public get isStructuralJoints() {
        return this.design.connectionType == ConnectionType.StructuralJoints;
    }

    public get isEN199211Checked() {
        // TODO C2C-9303, remove this logic - that nonsense logic is only temporary solution and should be removed in mentioned task
        return this.design.isPirAus
            ? this.isDesignMethodSelected(DesignMethod.EN199211)
            : this.isDesignMethodGroupSelected(DesignMethodGroup.EN199211);
    }

    public get isHiltiMethodChecked() {
        // TODO C2C-9303, remove this logic - that nonsense logic is only temporary solution and should be removed in mentioned task
        return this.design.isPirAus
            ? this.isDesignMethodSelected(DesignMethod.HiltiMethod)
            : this.isDesignMethodGroupSelected(DesignMethodGroup.HiltiMethod);
    }

    public get isTR069ETAChecked() {
        // TODO C2C-9303, remove this logic - that nonsense logic is only temporary solution and should be removed in mentioned task
        return this.design.isPirAus
            ? this.isDesignMethodSelected(DesignMethod.TR069)
            : this.isDesignMethodGroupSelected(DesignMethodGroup.TR069ETA);
    }

    public get isAS3600Checked() {
        // TODO C2C-9303, remove this logic - that nonsense logic is only temporary solution and should be removed in mentioned task
        return this.design.isPirAus
            ? this.isDesignMethodSelected(DesignMethod.AS3600)
            : this.isDesignMethodGroupSelected(DesignMethodGroup.AS3600ETA);
    }

    public get isAEFACTN08Checked() {
        return this.design.isPirAus && this.isDesignMethodSelected(DesignMethod.AEFACTN08);
    }

    public get germanNationAnnexSuffix() {
        return this.design.isPirEu && this.design.designData.projectDesignC2C?.loads.useGermanNationalAnnex ? '-DE-NA' : '';
    }

    public get loadTypeKey() {
        const loadType = this.design.designData.projectDesignC2C?.loads.loadType;
        return this.design.isFeatureEnabled(FeatureFlagTypes.TR069Fire) && loadType == LoadType.Fire ? '.Fire' : '';
    }

    public get isDevelopmentLengthChecked() {
        return this.design.designData.projectDesignC2C?.loads.designMethods.includes(DesignMethod.DevelopmentLength);
    }

    public get isLapSpliceLengthChecked() {
        return this.design.designData.projectDesignC2C?.loads.designMethods.includes(DesignMethod.LapSpliceLength);
    }

    public get isAnchoringBarYieldChecked() {
        return this.design.designData.projectDesignC2C?.loads.designMethods.includes(DesignMethod.AnchoringBarYield);
    }

    public get isAnchoringExternalLoadsChecked() {
        return this.design.designData.projectDesignC2C?.loads.designMethods.includes(DesignMethod.AnchoringExternalLoads);
    }

    public get isA2Regions() {
        const a2Regions = environment?.c2cStrutAndTieDisabledCountries ?? [];
        return a2Regions.includes(this.design.region.id ?? 0);
    }

    public get canShowUtilizationTab() {
        const hnaCondition = this.design.isPirHna || this.design.isC2COverlay;

        return this.design.isPirEuOrAus || (hnaCondition && this.isHnaImprovementEnabled);
    }

    public get itemsShown() {
        return this.canShowUtilizationTab && !this.collapsed;
    }

    public get isHnaImprovementEnabled() {
        return this.design.isFeatureEnabled(FeatureFlagTypes.HnaDesignMethods);
    }

    public maxDrillingLength(method: DesignMethod): number {
        const key = DesignMethodHelper.mapDesignMethodToString(method);
        const results = this.design.designData.reportDataC2C?.designMethodResultsItems[key];
        if (results == undefined) {
            throw new Error('This design method not have results!');
        }

        return typeof results.maxDrillingLength === 'number' ? results.maxDrillingLength : this.unit.parseNumber(results.maxDrillingLength);
    }

    public isSelected(method: DesignMethod): boolean {
        return this.design.designData.projectDesignC2C?.options.selectedDesignMethod == method;
    }

    ngOnInit(): void {
        this.design.onStateChanged(() => {
            // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
            const onStateChanged = () => {
                this.updateValues();
            };
            return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
        });

        this.updateValues();
    }

    private updateValues() {
        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.DesignMethod);
    }

    // TODO C2C-9303: that logic should be removed
    private isDesignMethodSelected(designMethod: DesignMethod) {
        const selectedMethods = this.design.designData?.projectDesignC2C?.loads?.designMethods ?? [];
        return selectedMethods.includes(designMethod);
    }

    // TODO C2C-9303: that logic should be removed
    private isDesignMethodGroupSelected(designMethodGroup: DesignMethodGroup) {
        const selectedMethodGroups = this.design.designData?.projectDesignC2C?.options?.designMethodGroups ?? [];
        return selectedMethodGroups.includes(designMethodGroup);
    }

    public onCollapseChange(collapsed: boolean): void {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.DesignMethod, collapsed);
    }

    public onChangeDesignMethod(method: DesignMethod) {
        this.design.addModelChange(UIProperty.Option_C2C_SelectedDesignMethod, true, method)
            .finally(() => (this.design.designData.projectDesignC2C as ProjectDesignEntityC2C).options.selectedDesignMethod = method);
    }

    public haveResultsForMethod(method: DesignMethod): boolean {
        const key = DesignMethodHelper.mapDesignMethodToString(method);
        return this.design.designData.reportDataC2C?.designMethodResultsItems[key] !== undefined;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}
