import { Injectable } from '@angular/core';
import { ModalSize } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ModalServiceInjected } from '@profis-engineering/pe-ui-common/services/modal.common';
import { MainMenuCommandC2C } from '../../shared/entities/command';
import { IInfoDialogComponentInput } from '../../shared/entities/info-dialog-inputs';
import { IRecommendDifferentProductComponentInput } from '../../shared/entities/recommend-different-product-input';
import { ISelectRebarComponentInput } from '../../shared/entities/select-rebar-component-input';
import { DialogsEntityC2C } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { DetailedScopeCheckInfoPopup } from '../../shared/generated-modules/Hilti.PE.ScopeChecks.Common.Enums';

@Injectable({
    providedIn: 'root'
})
export class ModalService extends ModalServiceInjected {
    private dialogDataInternalC2C?: DialogsEntityC2C;

    public set dialogDataC2C(dialog) {
        this.dialogDataInternalC2C = dialog;
    }
    public get dialogDataC2C() {
        return this.dialogDataInternalC2C;
    }


    public openRecommendDifferentProductC2C(props?: IRecommendDifferentProductComponentInput) {
        this.openWebComponentModal('c2c-recommend-different-product', { size: 'md' }, props);
    }

    public openImportLoads() {
        this.openWebComponentModal('c2c-import-loads', { size: 'xl' });
    }

    public openSelectRebar(props?: ISelectRebarComponentInput) {
        return this.openWebComponentModal('c2c-select-rebar', { size: 'xl', noAnimation: true }, props);
    }

    public openDesignConsideration() {
        return this.openWebComponentModal('c2c-design-consideration', { size: 'xl' });
    }

    public openExportReportC2C(props?: object) {
        this.openWebComponentModal('c2c-export-report', { size: 'lg' }, props);
    }

    public openMultipleApprovals(files: string[], approvalLinks: string[]) {
        const inputValues = {
            files,
            approvalLinks
        };
        this.openWebComponentModal('c2c-multiple-approvals', { size: 'sm' }, inputValues);
    }

    public openInfoDialogC2C(command: MainMenuCommandC2C, size: ModalSize = 'md', forceLinkToOpen = false) {
        const inputValues = {
            isScopeCheckInfoPopup: false,
            command,
            forceLinkToOpen
        } as IInfoDialogComponentInput;

        this.openWebComponentModal('c2c-info-dialog', { size }, inputValues);
    }

    public openLShapeLimitation() {
        return this.openWebComponentModal('c2c-l-shape-limitation-modal');
    }

    public InfoPopupScopeCheck(detailedScopeCheckInfoPopup: DetailedScopeCheckInfoPopup) {
        const inputValues = {
            isScopeCheckInfoPopup: true,
            detailedScopeCheckInfoPopup: detailedScopeCheckInfoPopup,
        } as IInfoDialogComponentInput;

        this.openWebComponentModal('c2c-info-dialog', { size: 'md' }, inputValues);
    }

    public openInfoScopeCheckDialogC2C(detailedScopeCheckInfoPopup: DetailedScopeCheckInfoPopup) {
        this.InfoPopupScopeCheck(detailedScopeCheckInfoPopup);
    }
}
