<div class="box-section" *ngIf="showPanel">
    <c2c-utilization-tab
        [title]="translate('Agito.Hilti.C2C.SustainabilityPanel.Title')"
        icon="sprite-sustainability-icon"
        controlId="sustainability-panel"
        [showNewLabel]="true"
        [collapsed]="collapsed"
        [infoPopup]="infoBoxPopup"
        [tooltipText]="null"
        (collapseChange)="onCollapsedChanged($any($event).detail)"
        >
    </c2c-utilization-tab>

    <div class="box-section-content" *ngIf="!collapsed">
        <c2c-utilization-item
            [header]="translate('Agito.Hilti.C2C.SustainabilityPanel.AdhesiveEmissions')"
            [value]="totalProductionEmissions"
            sufix=" kgCO<sub>2</sub>">
        </c2c-utilization-item>
    </div>
</div>
