<pe-main-header class="main-header"
    [designName]="title"
    [designRegion]="design.region"
    [beforeLogout]="beforeLogout"
    [openDesignSettings]="openDesignSettings"
    [openSaveAsTemplate]="openSaveAsTemplate"
    [startTour]="startTour"
    [openGeneralNotes]="openGeneralNotes"
    [displayLDFlags]="displayLDFlags"
    [useNewHomePage]="isNewHomePage"
    (menuOpened)="menuOpened()"
    (hiltiDataPrivacyUrlOpened)="hiltiDataPrivacyUrlOpened()"
    (regionLinkOpened)="regionLinkOpened()">
</pe-main-header>

<div class="main-content">
    <!-- LEFT -->

    <div class="main-content-left" [class.width-extended]="hasExtendedWidth" [class.main-content-left-hidden]="hideLeftMenu">
        <div id="main-content-left-container" class="main-content-left-container">
            <div class="black-bar"></div>
            <pe-main-menu
                #mainMenuRef
                class="menu-container"
                [disableDragToMoveText]="true"
                [tabsHidden]="hideLeftMenu"
                [moduleLocalizationService]="localizationService"
                *ngIf="selectedMenu != null">
            </pe-main-menu>
        </div>
    </div>

    <div class="main-content-center-right" #mainContentCenterRightRef>
        <div class="main-content-center-right-top" #mainContentCenterRightTopRef>
            <!-- CENTER -->
            <div class="main-content-center">
                <pe-button id="hide-left-menu" class="hide-menu-button hide-menu-button-left"
                    [look]="'DefaultIcon'"
                    (buttonClick)="toggleLeftMenu()"
                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.CollapseMenu' | l10n: localizationService.selectedLanguage">
                    <span class="pe-ui-c2c-sprite" [class.pe-ui-c2c-sprite-arrow-left-medium]="!hideLeftMenu"
                        [class.pe-ui-c2c-sprite-arrow-right-medium]="hideLeftMenu"></span>
                </pe-button>

                <pe-button id="hide-right-menu" class="hide-menu-button hide-menu-button-right"
                    [look]="'DefaultIcon'"
                    (buttonClick)="toggleRightMenu()"
                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.CollapseMenu' | l10n: localizationService.selectedLanguage">
                    <span class="pe-ui-c2c-sprite" [class.pe-ui-c2c-sprite-arrow-right-medium]="!hideRightMenu"
                        [class.pe-ui-c2c-sprite-arrow-left-medium]="hideRightMenu"></span>
                </pe-button>

                <div class="main-content-position">
                    <div class="model-view-options click-through">
                        <div class="options-left">
                            <div class="button-group undo-redo-section click-reset" role="group">
                                <div class="buttons join">
                                    <div class="tooltip-wrapper" [ngbTooltip]="undoTooltip" placement="auto top">
                                        <pe-button id="main-undo-button"
                                            [look]="'DefaultIcon'"
                                            (buttonClick)="undo()"
                                            [disabled]="!canUndo()">
                                            <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-undo"></span>
                                        </pe-button>
                                    </div>
                                    <div class="tooltip-wrapper" [ngbTooltip]="redoTooltip" placement="auto top">
                                        <pe-button id="main-redo-button"
                                            [look]="'DefaultIcon'"
                                            (buttonClick)="redo()"
                                            [disabled]="!canRedo()">
                                            <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-redo"></span>
                                        </pe-button>
                                    </div>
                                </div>
                            </div>

                            <div class="button-group click-reset" role="group">
                                <pe-numeric-text-box
                                    [value]="modelViewZoom"
                                    (valueChange)="zoomPercentageChange($any($event).detail)"
                                    [unit]="this.zoomUnit"
                                    [minValue]="0"
                                    [maxValue]="100"
                                    [updateOnBlur]="true"
                                    [required]="true"
                                    [width]="80"
                                    [height]="32">
                                </pe-numeric-text-box>
                            </div>

                            <div class="button-group click-reset" role="group" *ngIf="view == ViewType.View3d">
                                <pe-button id="main-zoom-to-fit-button"
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.ZoomToFit' | l10n: localizationService.selectedLanguage"
                                    (buttonClick)="zoomToFit()">
                                    <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-search"></span>
                                </pe-button>
                            </div>

                            <div class="button-group click-reset" role="group">
                                <pe-button id="main-reset-camera-button"
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.ResetCamera' | l10n: localizationService.selectedLanguage"
                                    (buttonClick)="resetCamera()">
                                    <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-center"></span>
                                </pe-button>
                            </div>
                            <div class="button-group click-reset" role="group" ngbDropdown [autoClose]="false" closeOutsideNgbDropdown>
                                <pe-button id="main-model-display-options-button" ngbDropdownToggle
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="displayOptionsTooltip">
                                    <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-view"></span>
                                </pe-button>

                                <div id="display-options-dropdown" ngbDropdownMenu>
                                    <pe-checkbox-button [items]="displayOptionsCheckbox.items"
                                        [selectedValues]="displayOptionsCheckbox.selectedValues"
                                        (itemToggle)="displayOptionsCheckboxItemToggle($any($event).detail)">
                                    </pe-checkbox-button>
                                </div>
                            </div>

                            <div class="btn-group button-group click-reset" role="group" *ngIf="view == ViewType.View2d">
                                <pe-toggle-button-group
                                    [look]="'Default'"
                                    [itemWidth]="40"
                                    [items]="mode2dToggleButtonGroup.items"
                                    [model]="mode2d"
                                    (modelChange)="mode2d = $any($event).detail">
                                </pe-toggle-button-group>
                            </div>

                            <div class="btn-group button-group click-reset" *ngIf="view == ViewType.View2d">
                                <pe-toggle-button-group
                                    [itemWidth]="40"
                                    [items]="view2dModeToggleButtonGroup.items"
                                    [model]="view2dMode"
                                    (modelChange)="view2dMode = $any($event).detail">
                                </pe-toggle-button-group>
                            </div>

                            <div class="flex-line-break"></div>

                            <div class="bottom">
                                <div class="options-left-bottom">
                                    <div class="button-group" role="group"
                                        *ngIf="view != ViewType.View2d">
                                        <pe-button id="main-3D-editor-mode-button" class="open-close-3d click-reset"
                                            [look]="'Primary'"
                                            [width]="'124px'"
                                            [disabled]="selectedMenu == null || design.isReadOnlyDesignMode"
                                            (buttonClick)="openClose2d()"
                                            [ngbTooltip]="'Agito.Hilti.Profis3.Main.2D.Enter2DEditor' | l10n: localizationService.selectedLanguage">
                                            <span l10n="Agito.Hilti.Profis3.Main.2D.2DEditorMode"></span>
                                        </pe-button>
                                    </div>
                                    <div class="button-group" role="group" *ngIf="view == ViewType.View2d">
                                        <pe-button id="main-2D-editor-mode-button" class="open-close-2d click-reset"
                                            [look]="'Primary'"
                                            [width]="'124px'"
                                            (buttonClick)="openClose2d()"
                                            [ngbTooltip]="'Agito.Hilti.Profis3.Main.2D.Leave2DEditor' | l10n: localizationService.selectedLanguage">
                                            <div class="span-wrapper">
                                                <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-arrow-left-white button-rich-caret"></span>
                                                <span l10n="Agito.Hilti.Profis3.Main.3D.3DEditorMode"></span>
                                            </div>
                                        </pe-button>
                                    </div>
                                    <div class="flex-line-break"></div>
                                    <div class="loading-options">
                                        <div class="loading-container" [class.show-loading]="isLoading">
                                            <pe-loading [color]="'#524f53'"></pe-loading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="model-view-container">
                        <c2c-gl-model #glModelRef *ngIf="glModel != null" [context3dKey]="Context3dKeyMain"
                            [continuousRender]="glModel.continuousRender" [model]="glModel.model"
                            (fontsLoaded)="glModel.onFontsLoaded()" (zoom)="glModel.onZoom($any($event).detail)"
                            (selectTab)="glModel.onSelectTab($any($event).detail)"
                            (positionsChanged)="glModel.onPositionsChanged($any($event).detail)"
                            (draggingSelectionChanged)="glModel.onDraggingSelectionChanged($any($event).detail)"
                            (rebarClicked)="glModel.onRebarClicked($any($event).detail)">
                        </c2c-gl-model>
                    </div>
                </div>

                <div id="floating-dropdowns" class="click-through">
                    <c2c-info-section
                        class="info-section-container click-reset"
                        [collapsed]="floatingInfo != FloatingInfo.InfoSection"
                        (collapsedChange)="floatingInfoChange(FloatingInfo.InfoSection, $any($event).detail)"
                        [parentElement]="mainContentCenterRightTopRef">
                    </c2c-info-section>

                    <c2c-zone-analysis *ngIf="showZoneAnalysis"
                        class="click-reset"
                        [collapsed]="floatingInfo != FloatingInfo.ZoneAnalysis"
                        [zoneAnalysisInput]="zoneAnalysisInput"
                        (collapsedChange)="floatingInfoChange(FloatingInfo.ZoneAnalysis, $any($event).detail)">
                    </c2c-zone-analysis>

                    <c2c-sustainability-panel
                        class="click-reset"
                        [design]="design"
                        [collapsed]="floatingInfo != FloatingInfo.Sustainability"
                        (collapsedChange)="floatingInfoChange(FloatingInfo.Sustainability, $any($event).detail)">
                    </c2c-sustainability-panel>
                </div>
            </div>
        </div>

        <!-- LOADS -->
        <c2c-loads
            *ngIf="loadsVisible()"
            class="loads-container-wrapper"
            [selectedLoadId]="design.selectedLoadCombinationIdC2C"
            [decisiveLoadId]="design.decisiveLoadCombinationIdC2C"
            [resize3d]="resize3d"
            [parentElement]="mainContentCenterRightRef">
        </c2c-loads>
    </div>

    <div class="main-content-right-sidebar" *ngIf="!hideRightMenu">
        <!-- RIGHT -->
        <div class="main-content-right" *ngIf="rightSideLoaded">
            <div class="main-content-right-container">
                <div class="main-content-right-scroll">
                    <div class="design-section-wrapper">
                        <c2c-design-section #designSectionRef
                            [exportReportSupportMethods]="exportReportSupportMethods">
                        </c2c-design-section>
                    </div>
                    <div class="main-content-right-util" sortablejs [sortablejsOptions]="sortableMenu3DRightOptions"
                        (sortablejsInit)="sortMenu3DRight($any($event))">

                        <div [attr.data-id]="CollapsingControls.DesignMethod" *ngIf="showDesignMethods">
                            <c2c-design-method id="design-method" [design]="design">
                            </c2c-design-method>
                        </div>

                        <div [attr.data-id]="CollapsingControls.ZoneUtilizationsC2C">
                            <c2c-zone-utilizations></c2c-zone-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.UtilizationsC2C">
                            <c2c-utilization-panel></c2c-utilization-panel>
                        </div>

                        <div [attr.data-id]="CollapsingControls.RebarLoads">
                            <c2c-rebar-loads id="rebar-loads" [design]="design">
                            </c2c-rebar-loads>
                        </div>

                        <div [attr.data-id]="CollapsingControls.TopLayer1Utilization">
                            <c2c-layer-utilizations id="top-layer-1-utilization" [design]="design" [collapsingControl]=CollapsingControls.TopLayer1Utilization>
                            </c2c-layer-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.TopLayer2Utilization">
                            <c2c-layer-utilizations id="top-layer-2-utilization" [design]="design" [collapsingControl]=CollapsingControls.TopLayer2Utilization>
                            </c2c-layer-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.BottomLayer1Utilization">
                            <c2c-layer-utilizations id="bottom-layer-1-utilization" [design]="design" [collapsingControl]=CollapsingControls.BottomLayer1Utilization>
                            </c2c-layer-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.BottomLayer2Utilization">
                            <c2c-layer-utilizations id="bottom-layer-2-utilization" [design]="design" [collapsingControl]=CollapsingControls.BottomLayer2Utilization>
                            </c2c-layer-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.DrillLengthUtilization">
                            <c2c-layer-utilizations id="drill-length-utilization" [design]="design" [collapsingControl]=CollapsingControls.DrillLengthUtilization>
                            </c2c-layer-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.ConcreteBreakoutUtilization">
                            <c2c-layer-utilizations id="concrete-breakout-utilization" [design]="design" [collapsingControl]=CollapsingControls.ConcreteBreakoutUtilization>
                            </c2c-layer-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.ConcreteSplittingUtilization">
                            <c2c-layer-utilizations id="concrete-splitting-utilization" [design]="design" [collapsingControl]=CollapsingControls.ConcreteSplittingUtilization>
                            </c2c-layer-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.ShearUtilization">
                            <c2c-layer-utilizations id="shear-utilization" [design]="design" [collapsingControl]=CollapsingControls.ShearUtilization>
                            </c2c-layer-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.StrutAndTiesUtilization">
                            <c2c-layer-utilizations id="strut-and-ties-utilization" [design]="design" [collapsingControl]=CollapsingControls.StrutAndTiesUtilization>
                            </c2c-layer-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.NotificationsDesign">
                            <pe-notifications
                                [isVisible]="notificationComponentInputs.isVisible()"
                                [inputs]="notificationComponentInputs">
                            </pe-notifications>
                        </div>

                        <div [attr.data-id]="CollapsingControls.ValidationError">
                            <pe-design-validation-error [validationError]="design.validationError"
                                [menu]="selectedMenu">
                            </pe-design-validation-error>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- modals -->
<pe-loading-custom
    [loadingText]="modalService.loadingCustomLoadingText"
    [shown]="modalService.loadingCustomShown"
    [cancelFn]="modalService.loadingCustomOnCancel">
</pe-loading-custom>
