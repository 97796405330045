import {
    DesignStandard as DesignStandardC2C
} from '../entities/code-lists/design-standard';
import {
    ConnectionType,
    DesignMethod,
    DesignStandard as DesignStandardEnumC2C,
    DesignMethodGroup as DesignMethodGroupEnumC2C
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { FeatureFlagTypes } from '../generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import {
    DesignMethodGroup as DesignMethodGroupC2C
} from '../entities/code-lists/design-method-group';
import { DesignC2C as Design } from '../entities/design-c2c';
import clone from 'lodash-es/clone';

export function getUKDesignStandardKey(regionId: number, designStandard: DesignStandardC2C, featureFlagUKTA = false) {
    if (designStandard.id == DesignStandardEnumC2C.ETAG) {
        if (featureFlagUKTA && regionId == 5) {
            designStandard.nameResourceKey = 'Agito.Hilti.C2C.CodeList.DesignStandardEntity.Eurocode.Uk';
            designStandard.descriptionResourceKey = 'Agito.Hilti.C2C.CodeList.DesignStandardEntity.Eurocode.Uk.Description';
        }
        else {
            designStandard.nameResourceKey = 'Agito.Hilti.C2C.CodeList.DesignStandardEntity.Eurocode';
            designStandard.descriptionResourceKey = 'Agito.Hilti.C2C.CodeList.DesignStandardEntity.Eurocode.Description';
        }
    }

    return designStandard;
}

export function getUKDesignMethodKey(regionId: number, designMethod: DesignMethodGroupC2C, featureFlagUKTA = false) {
    const result = clone(designMethod);
    if (result.id == DesignMethodGroupEnumC2C.TR066ETA) {
        if (featureFlagUKTA && regionId == 5) {
            result.nameResourceKey = 'Agito.Hilti.C2C.CodeList.DesignMethodGroupEntity.TR066.Uk';
            result.displayKey = 'TR066.Uk';
        }
        else {
            result.nameResourceKey = 'Agito.Hilti.C2C.CodeList.DesignMethodGroupEntity.TR066ETA';
        }
    }

    return result;
}

export function isHnaAnchoringBarYieldMethod(design: Design | undefined): boolean {
    if (!design) {
        return false;
    }

    return (
        design.connectionType === ConnectionType.StructuralJoints ||
        design.connectionType === ConnectionType.ConcreteOverlay
    ) &&
    design.isFeatureEnabled(FeatureFlagTypes.HnaDesignMethods) &&
    design.designData.projectDesignC2C.options.selectedDesignMethod == DesignMethod.AnchoringBarYield &&
    design.isC2CHNA;
}
