import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/c2c-gl-model/base-update';
import {
    LongitudinalRebarPointC2C, PostInstalledRebarPointC2C
} from '@profis-engineering/c2c-gl-model/components/base-rebar-helper';
import {
    EmbedmentDepthExisting
} from '../../../../shared/entities/code-lists/embedment-depth-existing';
import {
    FastenerSize
} from '../../../../shared/entities/code-lists/fastener-size';
import { DesignC2C } from '../../../../shared/entities/design-c2c';
import {
    DesignCodeList as DesignCodeListC2C
} from '../../../../shared/entities/design-code-list';
import {
    LongitudinalReinforcementPointC2C, UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ConnectionType
} from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IModelC2C } from '@profis-engineering/c2c-gl-model/gl-model';


export class RebarUpdate extends BaseUpdate {

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged()
    private Product_C2C_PostInstalledRebar_RebarPoints(model: IModelC2C, design: DesignC2C) {
        if (!model.postInstalledRebar?.points) {
            throw new Error('model postInstalledRebar points is undefined');
        }
        if (design.connectionType == ConnectionType.Splices || design.connectionType == ConnectionType.StructuralJoints) {
            model.postInstalledRebar.points = design.designData.projectDesignC2C?.product.postInstalledRebarPoints.map(p => {
                return {
                    ...p,
                    diameter: p.diameter,
                    id: p.id,
                    x: p.absX,
                    y: p.absY,
                    installationLength: p.installationLength,
                    bond: p.bondCondition as number,
                    layerId: p.layerId
                } as PostInstalledRebarPointC2C;
            }) ?? [];
        }
    }

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged(UIProperty.Product_C2C_TopRebarDiameter)
    private Product_DiameterTop(value: number, model: IModelC2C, design: DesignC2C) {
        if (!model.rebarTop) {
            throw new Error('model rebarTop is undefined');
        }
        if (value != null) {
            const codeList = design.designData.designCodeListsC2C[DesignCodeListC2C.FastenerSize] as FastenerSize[];
            const diameter = codeList?.find((diameter) => diameter.id == value);
            model.rebarTop.diameter = diameter?.size;
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_BottomRebarDiameter)
    private Product_DiameterBottom(value: number, model: IModelC2C, design: DesignC2C) {
        if (!model.rebarBottom) {
            throw new Error('model rebarBottom is undefined');
        }
        if (value != null) {
            const codeList = design.designData.designCodeListsC2C[DesignCodeListC2C.FastenerSize] as FastenerSize[];
            const diameter = codeList?.find((diameter) => diameter.id == value);
            model.rebarBottom.diameter = diameter?.size;
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConnectorSize)
    private Product_ConnectorSize(value: number, model: IModelC2C, design: DesignC2C) {
        if (!model.rebarTop) {
            throw new Error('model rebarTop is undefined');
        }
        if (value != null) {
            const codeList = design.designData.designCodeListsC2C[DesignCodeListC2C.FastenerSize] as FastenerSize[];
            const size = codeList?.find((size) => size.id == value);
            model.rebarTop.diameter = size?.size;
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConnectorEmbedmentDepth)
    @propertyValueChanged(UIProperty.Product_C2C_TopEmbedmentDepth)
    private Product_EmbedmentDepthTop(value: number, model: IModelC2C) {
        if (!model.rebarTop || !model.postInstalledRebarHNA) {
            throw new Error('model rebarTop or postInstalledRebarHNA is undefined');
        }
        if (value != null) {
            model.rebarTop.embedmentDepth = value;
            model.postInstalledRebarHNA.embedmentDepth = value;
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_BottomEmbedmentDepth)
    private Product_EmbedmentDepthBottom(value: number, model: IModelC2C) {
        if (!model.rebarBottom) {
            throw new Error('model rebarBottom is undefined');
        }
        model.rebarBottom.embedmentDepth = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z1SpacingX)
    private Product_Z1SpacingX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone1.spacingX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z1SpacingY)
    private Product_Z1SpacingY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone1.spacingY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z2SpacingX)
    private Product_Z2SpacingX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone2.spacingX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z2SpacingY)
    private Product_Z2SpacingY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone2.spacingY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z3SpacingX)
    private Product_Z3SpacingX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone3.spacingX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z3SpacingY)
    private Product_Z3SpacingY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone3.spacingY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z4SpacingX)
    private Product_Z4SpacingX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone4.spacingX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z4SpacingY)
    private Product_Z4SpacingY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone4.spacingY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z1EdgeDistanceX)
    private Product_Z1EdgeDistanceX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone1.edgeDistanceX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z1EdgeDistanceY)
    private Product_Z1EdgeDistanceY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone1.edgeDistanceY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z1EdgeDistanceXOpposite)
    private Product_Z1EdgeDistanceXOpposite(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone1.edgeDistanceXOpposite = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z1EdgeDistanceYOpposite)
    private Product_Z1EdgeDistanceYOpposite(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone1.edgeDistanceYOpposite = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z2EdgeDistanceX)
    private Product_Z2EdgeDistanceX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone2.edgeDistanceX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z2EdgeDistanceY)
    private Product_Z2EdgeDistanceY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone2.edgeDistanceY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z3EdgeDistanceX)
    private Product_Z3EdgeDistanceX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone3.edgeDistanceX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z3EdgeDistanceY)
    private Product_Z3EdgeDistanceY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone3.edgeDistanceY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z4EdgeDistanceX)
    private Product_Z4EdgeDistanceX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone4.edgeDistanceX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z4EdgeDistanceY)
    private Product_Z4EdgeDistanceY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone4.edgeDistanceY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z1RowsNumberX)
    private Product_Z1RowsNumberX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone1.rowsX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z1RowsNumberY)
    private Product_Z1RowsNumberY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone1.rowsY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z2RowsNumberX)
    private Product_Z2RowsNumberX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone2.rowsX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z2RowsNumberY)
    private Product_Z2RowsNumberY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone2.rowsY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z3RowsNumberX)
    private Product_Z3RowsNumberX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone3.rowsX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z3RowsNumberY)
    private Product_Z3RowsNumberY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone3.rowsY = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z4RowsNumberX)
    private Product_Z4RowsNumberX(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone4.rowsX = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConcreteZones_Z4RowsNumberY)
    private Product_Z4RowsNumberY(value: number, model: IModelC2C) {
        if (!model.rebarTop?.zones) {
            throw new Error('model rebarTop zones is undefined');
        }
        model.rebarTop.zones.zone4.rowsY = value;
    }

    @propertyValueChanged(UIProperty.Loads_C2C_BottomForceZ)
    private Loads_BottomForceZC2C(value: number, model: IModelC2C) {
        if (!model.rebarBottom) {
            throw new Error('model rebarBottom is undefined');
        }
        model.rebarBottom.forceZ = value;
    }

    @propertyValueChanged(UIProperty.Loads_C2C_ForceZ)
    private Loads_ForceZC2C(value: number, model: IModelC2C) {
        if (!model.rebarTop) {
            throw new Error('model rebarTop is undefined');
        }
        model.rebarTop.forceZ = value;
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConnectorEmbedmentDepthSelection)
    private Product_C2C_ConnectorEmbedmentDepthSelection(value: number, model: IModelC2C, design: DesignC2C) {
        if (!model.rebarTop) {
            throw new Error('model rebarTop is undefined');
        }

        if (value != null) {
            const codeList = design.designData.designCodeListsC2C[DesignCodeListC2C.EmbedmentDepthExisting] as EmbedmentDepthExisting[];
            const depth = codeList.find((depth) => depth.id == value);
            model.rebarTop.embedmentDepth = depth?.depth;
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_ConnectorEmbedmentDepthNew)
    private Product_C2C_ConnectorEmbedmentDepthNew(value: number, model: IModelC2C) {
        if (!model.rebarTop) {
            throw new Error('model rebarTop is undefined');
        }

        if (value != null) {
            model.rebarTop.embedmentDepthNew = value;
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_RebarSize)
    private Product_C2C_RebarSize(value: number, model: IModelC2C, design: DesignC2C) {
        if (!model.postInstalledRebarHNA) {
            throw new Error('model postInstalledRebarHNA is undefined');
        }

        if (value != null) {
            const codeList = design.designData.designCodeListsC2C[DesignCodeListC2C.FastenerSize] as FastenerSize[];
            const diameter = codeList?.find((diameter) => diameter.id == value);
            model.postInstalledRebarHNA.diameter = diameter?.size;
        }
    }

    @propertyValueChanged(UIProperty.ExistingStructure_C2C_Reinforcement_RebarPoints)
    private ExistingStructure_C2C_Reinforcement_RebarPoints(value: LongitudinalReinforcementPointC2C[], model: IModelC2C) {
        if (!model.reinforcement) {
            throw new Error('model reinforcement is undefined');
        }

        if (value != null) {
            model.reinforcement.longitudinalReinforcementPoints = value.map(p => ({
                x: p.x,
                y: p.y,
                bond: p.bondCondition as number,
                shape: p.shapeType as number,
                diameter: p.diameter,
                layerId: p.layerId
            }) as LongitudinalRebarPointC2C);
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_CalculationMode)
    private Product_C2C_CalculationMode(value: number, model: IModelC2C) {
        if (!model.postInstalledRebar) {
            throw new Error('model postInstalledRebar is undefined');
        }

        if (value != null) {
            model.postInstalledRebar.calculationMode = value;
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_AllowedFastenerSizes)
    private Product_C2C_AllowedFastenerSizes(value: number, model: IModelC2C, design: DesignC2C) {
        if (!model.postInstalledRebar) {
            throw new Error('model postInstalledRebar is undefined');
        }

        if (value != null) {
            const codeList = design.designData.designCodeListsC2C[DesignCodeListC2C.FastenerSize] as FastenerSize[];
            const diameter = codeList?.find((diameter) => diameter.id == value);
            model.postInstalledRebar.defaultDiameter = { id: value, value: diameter?.size ?? 10 };
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_OverlayEmbedmentDepthNew)
    private Product_C2C_OverlayEmbedmentDepthNew(value: number, model: IModelC2C) {
        if (!model.rebarTop) {
            throw new Error('model rebarTop is undefined');
        }

        if (value != null) {
            model.rebarTop.embedmentDepthNew = value;
        }
    }

    @propertyValueChanged(UIProperty.Product_C2C_OverlayEmbedmentDepthExisting)
    private Product_C2C_OverlayEmbedmentDepthExisting(value: number, model: IModelC2C) {
        if (!model.rebarTop) {
            throw new Error('model rebarTop is undefined');
        }

        if (value != null) {
            model.rebarTop.embedmentDepth = value;
        }
    }
}
