import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    DisplayDesignType, IDetailedDisplayDesign as IDetailedDisplayDesignCommon,
    IDisplayDesign as IDisplayDesignCommon
} from '@profis-engineering/pe-ui-common/entities/display-design';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    CommonCodeList, CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';

import { ProjectCodeList } from '../enums/project-code-list';
import {
    ProjectDesignEntityC2C
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    DesignOptionsASbased
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options';
import {
    ApplicationType, ConnectionType, DesignStandard as DesignStandardEnum, LoadType,
    MaximumAreaReinforcement, MinimumAreaReinforcement, OptimizationStrategy, SpacingMaximum,
    StrutTies
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { fromC2cUnit, toC2cUnit } from '../helpers/unit-helper';
import { CodeListServiceBase } from '../services/code-list.service.base';
import { DesignMethodGroup } from './code-lists/design-method-group';
import { DesignStandard } from './code-lists/design-standard';
import { DesignType as DesignTypeEntity } from './code-lists/design-type';
import { DesignType } from './tracking-data';

export interface IDisplayDesign extends IDisplayDesignCommon {
    designStandardC2C: DesignStandard;
    designMethodGroupC2C: DesignMethodGroup;
}
export interface IDetailedDisplayDesign extends IDetailedDisplayDesignCommon {
    designStandardC2C?: DesignStandard;
    designMethodGroupC2C?: DesignMethodGroup;
    partialReductionFactorConcrete?: number;
    partialReductionFactorSteel?: number;
    partialReductionFactorFire?: number;
    connectionType?: ConnectionType;
    templateName?: string;
    isHnaFireDesign?: boolean;
    applicationType?: ApplicationType;
    alphaCcC2C?: number;
    alphaCtC2C?: number;
    advancedRebarSettingsSteelStrainLimit?: number;
    concreteResistanceCompression?: number;
    concreteEpsilonC2?: number;
    concreteEpsionCu2?: number;
    minConcreteCover?: number;
    E?: number;
    epsilonUd?: number;
    partialSafetyFactorConcrete?: number;
    partialSafetyFactorSteel?: number;
    existingReinforcementAlpha3?: number;
    transversePressure?: number;
    minimumAreaReinforcement?: MinimumAreaReinforcement;
    minimumAreaReinforcementInput?: number;
    maximumAreaReinforcement?: MaximumAreaReinforcement;
    maximumAreaReinforcementInput?: number;
    spacingMaximum?: SpacingMaximum;
    spacingMaximumInput?: number;
    shearStrutAngle?: number;
    shearInclination?: number;
    strutTies?: StrutTies;
    strutTiesInput?: number;
    optimizationStrategy?: OptimizationStrategy;
    gammaRd?: number;
    useGermanNationalAnnex?: boolean;
    metricEquation?: number;
    designOptionsAs?: DesignOptionsASbased;
}

export function detailedDisplayDesignToProjectDesignC2C(projectDesign: ProjectDesignEntityC2C, displayDesign: IDetailedDisplayDesign) {
    projectDesign.designName = displayDesign.name;
    projectDesign.options.designStandard = displayDesign.designStandardC2C?.id ?? 0;
    projectDesign.options.designMethodGroup = displayDesign.designMethodGroupC2C?.id ?? 0;
    projectDesign.projectName = displayDesign.projectName;
    projectDesign.options.unitLength = toC2cUnit(displayDesign.unitLength);
    projectDesign.options.unitArea = toC2cUnit(displayDesign.unitArea);
    projectDesign.options.unitStress = toC2cUnit(displayDesign.unitStress);
    projectDesign.options.unitForce = toC2cUnit(displayDesign.unitForce);
    projectDesign.options.unitMoment = toC2cUnit(displayDesign.unitMoment);
    projectDesign.options.unitTemperature = toC2cUnit(displayDesign.unitTemperature);
    projectDesign.options.unitForcePerLength = toC2cUnit(displayDesign.unitForcePerLength);
    projectDesign.options.unitMomentPerLength = toC2cUnit(displayDesign.unitMomentPerLength);
    projectDesign.options.unitDensity = toC2cUnit(displayDesign.unitDensity);
    projectDesign.options.unitAreaPerLength = toC2cUnit(displayDesign.unitAreaPerLength);
    projectDesign.options.partialReductionFactorConcrete = displayDesign.partialReductionFactorConcrete;
    projectDesign.options.partialReductionFactorFire = displayDesign.partialReductionFactorFire;
    projectDesign.options.partialReductionFactorSteel = displayDesign.partialReductionFactorSteel;
    projectDesign.options.alphaCC = displayDesign.alphaCcC2C;
    projectDesign.options.alphaCT = displayDesign.alphaCtC2C;
    projectDesign.options.connectionType = displayDesign.connectionType ?? ConnectionType.Unknown;
    projectDesign.options.applicationType = displayDesign.applicationType ?? ApplicationType.Unknown;
    projectDesign.options.advancedRebarSettingsSteelStrainLimit = displayDesign.advancedRebarSettingsSteelStrainLimit;
    projectDesign.options.concreteResistanceCompression = displayDesign.concreteResistanceCompression;
    projectDesign.options.minConcreteCover = displayDesign.minConcreteCover;
    projectDesign.options.e = displayDesign.E;
    projectDesign.options.epsilonC2 = displayDesign.concreteEpsilonC2;
    projectDesign.options.epsilonCU2 = displayDesign.concreteEpsionCu2;
    projectDesign.options.epsilonUd = displayDesign.epsilonUd;
    projectDesign.options.partialSafetyFactorConcrete = displayDesign.partialSafetyFactorConcrete;
    projectDesign.options.partialSafetyFactorSteel = displayDesign.partialSafetyFactorSteel;
    projectDesign.options.existingReinforcementAlpha3 = displayDesign.existingReinforcementAlpha3;
    projectDesign.options.transversePressure = displayDesign.transversePressure;
    projectDesign.options.minimumAreaReinforcement = displayDesign.minimumAreaReinforcement ?? 0;
    projectDesign.options.minimumAreaReinforcementInput = displayDesign.minimumAreaReinforcementInput;
    projectDesign.options.maximumAreaReinforcement = displayDesign.maximumAreaReinforcement ?? 0;
    projectDesign.options.maximumAreaReinforcementInput = displayDesign.maximumAreaReinforcementInput;
    projectDesign.options.spacingMaximum = displayDesign.spacingMaximum ?? 0;
    projectDesign.options.spacingMaximumInput = displayDesign.spacingMaximumInput;
    projectDesign.options.shearStrutAngle = displayDesign.shearStrutAngle;
    projectDesign.options.shearInclination = displayDesign.shearInclination;
    projectDesign.options.strutTies = displayDesign.strutTies ?? 0;
    projectDesign.options.strutTiesInput = displayDesign.strutTiesInput;
    projectDesign.options.optimizationStrategy = displayDesign.optimizationStrategy ?? 0;
    projectDesign.options.gammaRd = displayDesign.gammaRd;
    projectDesign.loads.useGermanNationalAnnex = displayDesign.useGermanNationalAnnex ?? false;
    projectDesign.options.designOptionsAs = displayDesign.designOptionsAs ?? {} as DesignOptionsASbased;

    return projectDesign;
}

export function projectDesignToDetailedDisplayDesign(
    projectDesignC2C: ProjectDesignEntityC2C,
    documentDesign: IDesignListItem | DesignTemplateEntity,
    codeListServiceC2C: CodeListServiceBase,
    commonCodeListService: CommonCodeListServiceBase,
    localizationService: LocalizationServiceBase): IDetailedDisplayDesign {
    let designId: string;
    let designName: string;
    let projectId: string;
    let projectName: string;
    let designType: DesignTypeEntity | undefined = undefined;
    let designStandardC2C: DesignStandard | undefined = undefined;
    let designMethodGroupC2C: DesignMethodGroup | undefined = undefined;
    let isTemplate: boolean;

    const designTypes = codeListServiceC2C.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C] as DesignTypeEntity[];
    const regions = commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
    const designStandardsC2C = codeListServiceC2C.projectCodeListsC2C?.[ProjectCodeList.DesignStandardsC2C] as DesignStandard[];
    const designMethodGroupsC2C = codeListServiceC2C.projectCodeListsC2C?.[ProjectCodeList.DesignMethodGroupsC2C] as DesignMethodGroup[];

    designStandardC2C = designStandardsC2C.find(standardC2C => standardC2C.id == projectDesignC2C.options.designStandard);
    designMethodGroupC2C = designMethodGroupsC2C.find(dMeth => dMeth.id == projectDesignC2C.options.designMethodGroup);
    const region = regions.find(region => region.id == projectDesignC2C.options.regionId) as CommonRegion;

    // fill the entity based on whether the design is a template or not
    if ('metaData' in documentDesign) {
        const design = documentDesign as IDesignListItem;

        isTemplate = false;
        designId = design.id;
        designName = design.designName;
        projectId = design.projectId;
        projectName = design.projectName;

        if (design.metaData?.designType != null) {
            designType = designTypes.find(type => type.id == design.metaData.designType) as DesignTypeEntity;
        }
    }
    else {
        const design = documentDesign as DesignTemplateEntity;

        designId = design.DesignTemplateDocumentId;
        designName = design.DesignTemplateName;
        designType = designTypes.find(type => type.id == design.DesignTypeId) as DesignTypeEntity;
        projectName = designType.getTranslatedNameText(getCodeListTextDeps(localizationService)) ?? '';
        projectId = null as unknown as string;
        isTemplate = true;
    }


    const design: IDetailedDisplayDesign = {
        id: designId,
        name: designName,
        projectId,
        projectName,
        region,
        designType: designType?.id ?? DesignType.Concrete2Concrete,
        displayDesignType: isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
        unitLength: fromC2cUnit(projectDesignC2C.options.unitLength),
        unitArea: fromC2cUnit(projectDesignC2C.options.unitArea),
        unitStress: fromC2cUnit(projectDesignC2C.options.unitStress),
        unitForce: fromC2cUnit(projectDesignC2C.options.unitForce),
        unitMoment: fromC2cUnit(projectDesignC2C.options.unitMoment),
        unitTemperature: fromC2cUnit(projectDesignC2C.options.unitTemperature),
        unitForcePerLength: fromC2cUnit(projectDesignC2C.options.unitForcePerLength),
        unitMomentPerLength: fromC2cUnit(projectDesignC2C.options.unitMomentPerLength),
        unitDensity: fromC2cUnit(projectDesignC2C.options.unitDensity),
        unitAreaPerLength: fromC2cUnit(projectDesignC2C.options.unitAreaPerLength),
        unitLengthLarge: null as unknown as UnitType,
        unitStressSmall: null as unknown as UnitType,
        templateName: isTemplate ? designName : '',
        partialReductionFactorConcrete: projectDesignC2C.options.partialReductionFactorConcrete,
        partialReductionFactorSteel: projectDesignC2C.options.partialReductionFactorSteel,
        partialReductionFactorFire: projectDesignC2C.options.partialReductionFactorFire,
        alphaCcC2C: projectDesignC2C.options.alphaCC,
        alphaCtC2C: projectDesignC2C.options.alphaCT,
        designStandardC2C,
        designMethodGroupC2C,
        connectionType: projectDesignC2C.options.connectionType,
        applicationType: projectDesignC2C.options.applicationType,
        isHnaFireDesign: isHnaFire(projectDesignC2C),
        advancedRebarSettingsSteelStrainLimit: projectDesignC2C.options.advancedRebarSettingsSteelStrainLimit,
        concreteResistanceCompression: projectDesignC2C.options.concreteResistanceCompression,
        E: projectDesignC2C.options.e,
        concreteEpsilonC2: projectDesignC2C.options.epsilonC2,
        concreteEpsionCu2: projectDesignC2C.options.epsilonCU2,
        epsilonUd: projectDesignC2C.options.epsilonUd,
        minConcreteCover: projectDesignC2C.options.minConcreteCover,
        partialSafetyFactorConcrete: projectDesignC2C.options.partialSafetyFactorConcrete,
        partialSafetyFactorSteel: projectDesignC2C.options.partialSafetyFactorSteel,
        shearStrutAngle: projectDesignC2C.options.shearStrutAngle,
        shearInclination: projectDesignC2C.options.shearInclination,
        strutTies: projectDesignC2C.options.strutTies,
        strutTiesInput: projectDesignC2C.options.strutTiesInput,
        optimizationStrategy: projectDesignC2C.options.optimizationStrategy,
        existingReinforcementAlpha3: projectDesignC2C.options.existingReinforcementAlpha3,
        transversePressure: projectDesignC2C.options.transversePressure,
        minimumAreaReinforcement: projectDesignC2C.options.minimumAreaReinforcement,
        minimumAreaReinforcementInput: projectDesignC2C.options.minimumAreaReinforcementInput,
        maximumAreaReinforcement: projectDesignC2C.options.maximumAreaReinforcement,
        maximumAreaReinforcementInput: projectDesignC2C.options.maximumAreaReinforcementInput,
        spacingMaximum: projectDesignC2C.options.spacingMaximum,
        spacingMaximumInput: projectDesignC2C.options.spacingMaximumInput,
        gammaRd: projectDesignC2C.options.gammaRd,
        useGermanNationalAnnex: projectDesignC2C.loads.useGermanNationalAnnex,
        metricEquation: projectDesignC2C.options.metricEquation,
        designOptionsAs: projectDesignC2C.options.designOptionsAs
    };

    return design;
}

export function isHnaFire(projectDesignC2C: ProjectDesignEntityC2C) {
    return projectDesignC2C.loads.loadType === LoadType.Fire && (projectDesignC2C.options.designStandard === DesignStandardEnum.ACI || projectDesignC2C.options.designStandard === DesignStandardEnum.CSA);
}

export function convertStringToLatestDesign(projectDesignAsString: string) {
    return JSON.parse(projectDesignAsString) as ProjectDesignEntityC2C;
}
