import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import { IGetStringOptions, LocalizationServiceBase, LocalizationServiceInjected } from '@profis-engineering/pe-ui-common/services/localization.common';
import { environment } from '../../environments/environmentC2C';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { LanguageTranslations } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.TranslationsService.Shared.Entities';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';

const TranslationLibraryName = 'ConcreteToConcrete';
const TranslationsUrl = environment.c2cTranslationsServiceUrl;

@Injectable({
    providedIn: 'root'
})
export class LocalizationService extends LocalizationServiceInjected {
    private _moduleTranslations: Record<string, string> = {};
    private _moduleTranslationsLoaded = false;
    private _moduleTranslationsLoading = false;

    private _missingModuleTranslations: string[] = [];
    logMissingTranslation(translationKey: string) {
        if (!translationKey || this._missingModuleTranslations.includes(translationKey))
            return;

        this._missingModuleTranslations.push(translationKey);
        this.loggerService.log(`Missing ${TranslationLibraryName} key: ` + translationKey, LogType.warn);
    }

    constructor(
        private loggerService: LoggerService,
        private apiService: ApiService
    ) {
        super();
    }

    override setBaseService(baseService: LocalizationServiceBase): void {
        super.setBaseService(baseService);
        this.getStringBase = baseService.getString.bind(baseService);
        this.hasTranslationBase = baseService.hasTranslation.bind(baseService);
        this.getString = this.getModuleTranslation;
        this.hasTranslation = this.hasModuleOrBaseTranslation;
        this.getKeyExists = this.getModuleKeyExists;

        this.addGetTranslationsHook(this.updateModuleTranslations.bind(this));
    }

    public async initTranslations() {
        await this.loadModuleTranslations({ supressErrorMessage: true });

        if (!this._moduleTranslationsLoaded) {
            throw new Error(`Failed to initialize ${TranslationLibraryName} translations.`);
        }
    }

    async updateModuleTranslations(): Promise<void> {
        if (!this._moduleTranslationsLoading)
            await this.loadModuleTranslations();
    }

    getModuleTranslation(translationKey: string, options?: IGetStringOptions | undefined): string {
        // if module translations are not loaded, return base service translation
        if (!this._moduleTranslationsLoaded) {
            return this.getStringBase(translationKey, options);
        }

        let result = this._moduleTranslations[translationKey];

        if (options?.tags) {
            result = this.sanitizeText(result, options.tags);
        }

        // if module translation is not found, return base service translation and log missing translation
        if (!result) {
            this.logMissingTranslation(translationKey);
            return this.getStringBase(translationKey, options);
        }

        return result;
    }

    hasModuleOrBaseTranslation(translationKey: string): boolean {
        if (!translationKey?.trim() || !this.getModuleKeyExists(translationKey)) {
            return false;
        }

        const translation = this.getModuleTranslation(translationKey);
        const hasModuleTranslation = Boolean(translation?.trim());

        // check for either module or base service translations
        return hasModuleTranslation || this.hasTranslationBase(translationKey);
    }

    getModuleKeyExists(translationKey: string): boolean {
        const hasModuleKey = !this._moduleTranslationsLoaded ? false : this._moduleTranslations[translationKey] != null;
        return hasModuleKey || this.baseService.getKeyExists(translationKey);
    }

    async loadModuleTranslations(options?: ApiOptions) {
        this._moduleTranslationsLoading = true;
        // call service to receive translations for selected language
        await this.getModuleTranslationsFromService(this.selectedLanguage, options)
            .then((translations) => {
                this.setModuleTranslations(translations);
            })
            .catch(() => {
                this._moduleTranslationsLoaded = false;
            })
            .finally(() => {
                this._moduleTranslationsLoading = false;
            });
    }

    private setModuleTranslations(languageTranslations: LanguageTranslations | null): void {
        if (!languageTranslations) {
            this._moduleTranslationsLoaded = false;
            this.loggerService.log(`No ${TranslationLibraryName} translations!`, LogType.error);
            return;
        }

        this._moduleTranslations = languageTranslations.Translations;
        this._moduleTranslationsLoaded = true;
    }

    private async getModuleTranslationsFromService(languageCultureName: string, options?: ApiOptions): Promise<LanguageTranslations | null> {
        const url = `${TranslationsUrl}Translations/${TranslationLibraryName}/${languageCultureName}`;
        return (await this.apiService.request<LanguageTranslations>(new HttpRequest('GET', url), options)).body;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getStringBase(translationKey: string, options?: IGetStringOptions): string {
        return '';
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public hasTranslationBase(translationKey: string): boolean {
        return false;
    }
}
