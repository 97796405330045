export enum ProjectCodeList {
    UIPropertiesC2C,
    ConnectionTypesC2C,
    DesignStandardsC2C,
    DesignMethodGroupsC2C,
    SafetyFactorRegionC2C,
    ReportTypeC2C,
    ApplicationTypesC2C,
    MethodGroupConnTypeStandardRegionsC2C,
    BaseMaterialDataC2C,
    RebarSafetyFactorsRegionC2C,
    MinimumAreaReinforcementC2C,
    MaximumAreaReinforcementC2C,
    SpacingMaximumC2C,
    OptimizationStrategyC2C,
    StrutTiesC2C,
    MaxReinforcementSpacingC2C,
    InfoLinkC2C,
    SplitEntryPointC2C,
    MetricEquationC2C,
    DesignTypeC2C,
    CorrespondingChapterC2C
}
