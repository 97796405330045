import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { NewDesignDataEntityC2C } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { RebarCalculationParametersAs } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';
import {
    DesignStandard,
    MaximumAreaReinforcement, MinimumAreaReinforcement, SpacingMaximum
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IDetailedDisplayDesign } from './display-design';
import { UserSettingsC2C as UserSettings } from './user-settings';

export interface IRebarCalulationInputsData {
    // Context
    design?: () => IDetailedDisplayDesign;
    settings?: () => UserSettings;
    connectionType?: number;
    areaUnit: number;
    stressUnit: number;
    lengthUnit: number;
    addEditType: AddEditType;

    // Values
    existingReinforcementAlpha3?: number;
    transversePressure?: number;
    minimumAreaReinforcement?: number;
    minimumAreaReinforcementInput?: number;
    maximumAreaReinforcement?: number;
    maximumAreaReinforcementInput?: number;
    spacingMaximum?: number;
    spacingMaximumInput?: number;

    rebarCalculationParametersAs: RebarCalculationParametersAs;
}

export function setRebarCalculationValuesToDesign(design: IDetailedDisplayDesign, data: IRebarCalulationInputsData) {
    design.existingReinforcementAlpha3 = data.existingReinforcementAlpha3;
    design.transversePressure = data.transversePressure;
    design.minimumAreaReinforcement = data.minimumAreaReinforcement;
    design.minimumAreaReinforcementInput = data.minimumAreaReinforcementInput;
    design.maximumAreaReinforcement = data.maximumAreaReinforcement;
    design.maximumAreaReinforcementInput = data.maximumAreaReinforcementInput;
    design.spacingMaximum = data.spacingMaximum;
    design.spacingMaximumInput = data.spacingMaximumInput;

    if (design.designStandardC2C?.id != DesignStandard.ASBased || !design.designOptionsAs)
        return;

    design.designOptionsAs.rebarCalculationParameters = {
        factorK4: data.rebarCalculationParametersAs.factorK4,
        factorK6: data.rebarCalculationParametersAs.factorK6,
        transversePressure: data.rebarCalculationParametersAs.transversePressure,
        minimumAreaReinforcement: data.rebarCalculationParametersAs.minimumAreaReinforcement,
        minimumAreaReinforcementInput: data.rebarCalculationParametersAs.minimumAreaReinforcementInput,
        maximumAreaReinforcement: data.rebarCalculationParametersAs.maximumAreaReinforcement,
        maximumAreaReinforcementInput: data.rebarCalculationParametersAs.maximumAreaReinforcementInput,
        spacingMaximum: data.rebarCalculationParametersAs.spacingMaximum,
        spacingMaximumInput: data.rebarCalculationParametersAs.spacingMaximumInput
    } as RebarCalculationParametersAs;
}

export function setRebarCalculationValuesToNewDesign(newDesign: NewDesignDataEntityC2C, data: IRebarCalulationInputsData) {
    newDesign.existingReinforcementAlpha3 = data.existingReinforcementAlpha3;
    newDesign.transversePressure = data.transversePressure;
    newDesign.minimumAreaReinforcement = data.minimumAreaReinforcement as MinimumAreaReinforcement;
    newDesign.minimumAreaReinforcementInput = data.minimumAreaReinforcementInput;
    newDesign.maximumAreaReinforcement = data.maximumAreaReinforcement as MaximumAreaReinforcement;
    newDesign.maximumAreaReinforcementInput = data.maximumAreaReinforcementInput;
    newDesign.spacingMaximum = data.spacingMaximum as SpacingMaximum;
    newDesign.spacingMaximumInput = data.spacingMaximumInput;

    if (newDesign.designStandard != DesignStandard.ASBased || !newDesign.designOptionsAs)
        return;

    newDesign.designOptionsAs.rebarCalculationParameters = {
        factorK4: data.rebarCalculationParametersAs.factorK4,
        factorK6: data.rebarCalculationParametersAs.factorK6,
        transversePressure: data.rebarCalculationParametersAs.transversePressure,
        minimumAreaReinforcement: data.rebarCalculationParametersAs.minimumAreaReinforcement,
        minimumAreaReinforcementInput: data.rebarCalculationParametersAs.minimumAreaReinforcementInput,
        maximumAreaReinforcement: data.rebarCalculationParametersAs.maximumAreaReinforcement,
        maximumAreaReinforcementInput: data.rebarCalculationParametersAs.maximumAreaReinforcementInput,
        spacingMaximum: data.rebarCalculationParametersAs.spacingMaximum,
        spacingMaximumInput: data.rebarCalculationParametersAs.spacingMaximumInput
    } as RebarCalculationParametersAs;
}
