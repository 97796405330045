import { Injectable } from '@angular/core';
import {
    CalculationType, IBaseDesign
} from '@profis-engineering/pe-ui-common/entities/design';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    CalculateDesignRequestDataBase, ICalculateInternalOptionsBase, IPropertyChanges
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';

import { DesignC2C, ReportDataC2C } from '../../shared/entities/design-c2c';
import {
    CalculationResultEntityC2C as ICalculationResultEntityC2C,
    DesignReportDataClientEntityC2C as IDesignReportDataClientEntityC2C, NewDesignDataEntityC2C,
    ProjectDesignEntityC2C
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ReportOptionEntityC2C
} from '../../shared/generated-modules/Hilti.PE.ReportService.Shared.Entities';
import { UIPropertiesC2C } from '../../shared/properties/properties';
import {
    CalculationServiceBaseC2C, ICalculationResult
} from '../../shared/services/calculation.common';

@Injectable({
    providedIn: 'root'
})
export class CalculationServiceC2C extends CalculationServiceBaseC2C {
    public setBaseService(baseService: CalculationServiceBaseC2C) {
        this.createAndOpenC2C = baseService.createAndOpenC2C.bind(baseService);
        this.createAndOpenTemplateC2C = baseService.createAndOpenTemplateC2C.bind(baseService);
        this.createAndOpenFromProjectDesignC2C = baseService.createAndOpenFromProjectDesignC2C.bind(baseService);
        this.createFromProjectDesignC2C = baseService.createFromProjectDesignC2C.bind(baseService);
        this.openFromDocumentDesignC2C = baseService.openFromDocumentDesignC2C.bind(baseService);
        this.openFromProjectDesignC2C = baseService.openFromProjectDesignC2C.bind(baseService);
        this.updateDesignFromExternalFileC2C = baseService.updateDesignFromExternalFileC2C.bind(baseService);
        this.generateAndDownloadReport = baseService.generateAndDownloadReport.bind(baseService);
        this.calculateAllC2CSignalR = baseService.calculateAllC2CSignalR.bind(baseService);
        this.calculateAsync = baseService.calculateAsync.bind(baseService);
        this.calculate = baseService.calculate.bind(baseService);
        this.updateReportData = baseService.updateReportData.bind(baseService);
        this.updateCalculationData = baseService.updateCalculationData.bind(baseService);
        this.updateFromProperties = baseService.updateFromProperties.bind(baseService);
        this.updatePropertyValues = baseService.updatePropertyValues.bind(baseService);
        this.loadState = baseService.loadState.bind(baseService);
        this.undo = baseService.undo.bind(baseService);
        this.redo = baseService.redo.bind(baseService);
    }


    // CalculationServiceBaseC2C methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public createAndOpenC2C(_dataEntity: NewDesignDataEntityC2C) {
        return {} as Promise<DesignC2C>;
    }

    public createAndOpenTemplateC2C(_projectDesign: ProjectDesignEntityC2C, _templateId: string, _templateName: string) {
        return {} as Promise<DesignC2C>;
    }

    public createAndOpenFromProjectDesignC2C(_projectDesign: ProjectDesignEntityC2C, _projectId: string, _templateId?: string, _apiOptions?: ApiOptions) {
        return {} as Promise<DesignC2C>;
    }

    public createFromProjectDesignC2C(_projectDesign: ProjectDesignEntityC2C, _projectId: string) {
        return {} as Promise<DesignC2C>;
    }

    public openFromDocumentDesignC2C(_documentDesign: IBaseDesign) {
        return {} as Promise<ICalculationResult>;
    }

    public openFromProjectDesignC2C(_projectDesign: ProjectDesignEntityC2C, _designId: string, _trackingEnabled?: boolean) {
        return {} as Promise<ICalculationResult>;
    }

    public updateDesignFromExternalFileC2C(_oldDesign: IBaseDesign, _projectDesign: ProjectDesignEntityC2C, _disableCalcMessages?: boolean) {
        return {} as Promise<DesignC2C>;
    }

    public generateAndDownloadReport(_design: DesignC2C, _downloadPdf: boolean, _options: ReportOptionEntityC2C, _designImages: string[]) {
        return {} as Promise<Blob>;
    }

    public calculateAllC2CSignalR(
        _design: DesignC2C,
        _changes?: Change[],
        _onProgress?: (progress: unknown) => void
    ) {
        return {} as Promise<void>;
    }

    public calculateAsync(_design: DesignC2C, _changeFn?: (design: DesignC2C) => void, _options?: ICalculateInternalOptionsBase) {
        return {} as Promise<ICalculationResult>;
    }

    public calculate(_design: DesignC2C, _calculateId: string, _options: CalculateDesignRequestDataBase) {
        // Nothing to do.
    }

    public updateReportData(_design: DesignC2C, _reportData: IDesignReportDataClientEntityC2C) {
        return {} as ReportDataC2C;
    }

    public updateCalculationData(
        _design: DesignC2C,
        _data: ICalculationResultEntityC2C, _calculationLanguage: string, _calculationType: CalculationType, _messagesClosedDeferred?: Deferred<void>,
        _disableCalcMessages?: boolean,
        _keepMissingProperties?: boolean
    ) {
        return {} as {
            [property: string]: Change;
        };
    }

    public updateFromProperties(_design: DesignC2C, _propertiesC2C: UIPropertiesC2C, _keepMissingProperties?: boolean) {
        return {} as {
            modelChanges: {
                [property: string]: Change;
            };
            propertyChanges: IPropertyChanges;
        };
    }

    public updatePropertyValues(_design: DesignC2C, _properties: UIPropertiesC2C, _keepMissingProperties?: boolean) {
        return {} as IPropertyChanges;
    }

    public loadState(_design: DesignC2C, _index: number) {
        return;
    }

    public undo(_design: DesignC2C) {
        return {} as Promise<void>;
    }

    public redo(_design: DesignC2C) {
        return {} as Promise<void>;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
