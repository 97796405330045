

import { Injectable } from '@angular/core';
import {
    ILocalizationExtension
} from '@profis-engineering/pe-ui-common/services/extensions.common';
import { MenuServiceInjected } from '@profis-engineering/pe-ui-common/services/menu.common';

import { DesignC2C } from '../../shared/entities/design-c2c';
import { CalculationServiceC2C } from './calculation-c2c.service';
import { CodeListService } from './code-list.service';
import { FavoritesService } from './favorites.service';
import { FeaturesVisibilityService } from './features-visibility.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { MenuServiceExtensions } from './menu-extensions';
import { ModalService } from './modal.service';
import { StaticMenuService } from './static-menu.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService extends MenuServiceInjected<DesignC2C> {

    constructor(
        private codeListService: CodeListService,
        private calculationService: CalculationServiceC2C,
        private featureVisibilityService: FeaturesVisibilityService,
        private localizationService: LocalizationService,
        private loggerService: LoggerService,
        private modalService: ModalService,
        private staticMenuService: StaticMenuService,
        private unitService: UnitService,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private favoritesService: FavoritesService
    ) {
        super();
    }

    public initialize() {
        this.setMenuExtensions(new MenuServiceExtensions(
            this.codeListService,
            this.calculationService,
            this.featureVisibilityService,
            this.localizationService,
            this.loggerService,
            this.modalService,
            this.staticMenuService,
            this.unitService,
            this.userService,
            this.userSettingsService,
            this.favoritesService));

        this.menuExtensions.localizationExtension = {
            getTranslation: this.localizationService.getString.bind(this.localizationService),
            checkForTranslation: this.localizationService.hasTranslation.bind(this.localizationService)
        } as ILocalizationExtension;
    }
}
