<div class="hide-model" *ngIf="glModel3d == null || glModel3d.isHidden"></div>

<div class="gl-model-text-editor stepper-container"
    [style]="glModel3d != null ? glModel3d.textEditorStyle : null" [style.display]="glModel3d != null && glModel3d.textEditorVisible ? 'block' : 'none'">
    <input
        *ngIf="glModel3d != null"
        class="input stepper-input"
        type="text"
        [(ngModel)]="glModel3d.textEditorValue"
        (blur)="glModel3d.onTextEditorBlur()"
        (keyup.enter)="glModel3d.hideTextEditor()"
        (input)="glModel3d.textEditorValueChange()" autocomplete="off" autocorrect="off" autocapitalize="off"
        spellcheck="false" />

    <div class="stepper">
        <button class="stepper-button increment button" tabindex="-1" type="button"
            (blur)="glModel3d.onTextEditorBlur();"
            (click)="stepperClick(Stepper.increment)"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.TextBox.StepperIncrement')">+</button>
        <button class="stepper-button decrement button" tabindex="-1" type="button"
            (blur)="glModel3d.onTextEditorBlur();"
            (click)="stepperClick(Stepper.decrement)"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.TextBox.StepperDecrement')">-</button>
    </div>
</div>
