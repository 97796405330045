import cloneDeep from 'lodash-es/cloneDeep';
import sortBy from 'lodash-es/sortBy';

import {
    Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Feature, KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { hasProperty } from '@profis-engineering/pe-ui-common/helpers/object-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import selectAnchorTooltipImages from '../../../images/SelectAnchorTooltip';
import {
    ProductFilter as RebarFilterEntity
} from '../../../shared/entities/code-lists/product-filter';
import { ProductFilterGroup } from '../../../shared/entities/code-lists/product-filter-group';
import {
    DesignC2C as Design, ICalculateAllRebarsDetailed as ICalculateAllRebarBase,
    ICalculateAllShearConnectorsDetailed as ICalculateAllConnectorBase
} from '../../../shared/entities/design-c2c';
import { DesignCodeList as DesignCodeListC2C } from '../../../shared/entities/design-code-list';
import { ISelectRebarComponentInput } from '../../../shared/entities/select-rebar-component-input';
import {
    UIProductFilterInputC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.ProductFilter';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites, Sprite } from '../../sprites';

interface IProduct {
    id: number;
    name: string;
    description: string;
    image: string;
    tag: string;
    isNoCleaningRequired: boolean;
    isAutomaticCleaningApproved: boolean;
    isCrackedConcreteAllowed: boolean;
    isFatigueAllowed: boolean;
    isShock: boolean;
    isSeismicAllowed: boolean;
    isSmallEdgeDistAndSpacing: boolean;
    isVariableEmbedment: boolean;
    isNPPApprovalDiBt: boolean;
    isFireAllowed: boolean;
    isCleanTech: boolean;
    isStainlessSteelOrHCRAvailable: boolean;
    isShallowEmbedmentDepth: boolean;
    isDiamondCoredHolesSuitable: boolean;
    returnPeriod: number;
    hasTR069: boolean;
    sortOrder: string | number;
    allowedInputFilters: { [key: string]: number[] };
    holeDiameters: number[];
}

interface ICalculateAllProduct {
    defaultSortOrder: number;
    description: string;
    id: number;
    name: string;
    detailed: ICalculateAllRebarDetailed[] | ICalculateAllShearConnectorsDetailed[];
}

interface ICalculateAllShearConnectorsDetailed extends ICalculateAllConnectorBase {
    utilizationFormated: string;
    sizeFormated: string;
    embedmentDepthFormated: string;
}

interface ICalculateAllRebarDetailed extends ICalculateAllRebarBase {
    embedmentDepthFormated: string;
    sizeFormated: string;
    utilizationFormated: string;
}

export enum SelectRebarSortBy {
    default,
    name
}

enum SelectRebarMode {
    normal,
    calculateAll
}

enum SelectRebarIcon {
    noCleaningRequired,
    automaticCleaningApproved,
    crackedConcreteAllowed,
    fatigueAllowed,
    shock,
    seismicAllowed,
    smallEdgeDistAndSpacing,
    variableEmbedment,
    nppApprovalDiBt,
    fireAllowed,
    cleanTech,
    stainlessSteelOrHCRAvailable,
    shallowEmbedmentDepth,
    diamondCoredHolesSuitable
}

interface IIcon {
    readonly image: string;
    readonly tooltip: SelectRebarIcon;
    readonly visibleExpression: string;
}

interface ISelectRebarFilterGroupInfoPopup {
    enabled: boolean;
    show: boolean;
    title: string;
    content: string;
}

interface ISelectRebarFilterGroupConstructor {
    id?: number;
    titleKey?: string;
    items?: SelectRebarFilterGroupItem[];
    infoPopup?: ISelectRebarFilterGroupInfoPopup;
}

class SelectRebarFilterGroup {
    public id?: number;
    public titleKey?: string;
    public items?: SelectRebarFilterGroupItem[];
    public infoPopup?: ISelectRebarFilterGroupInfoPopup;

    constructor(ctor?: ISelectRebarFilterGroupConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.titleKey = ctor.titleKey;
            this.items = ctor.items;
            this.infoPopup = ctor.infoPopup;
        }
    }
}

interface ISelectRebarFilterGroupItemConstructor {
    id?: number;
    checkbox?: CheckboxButtonProps<boolean>;
    nameKey?: string;
}

class SelectRebarFilterGroupItem implements SelectRebarFilterGroupItem {
    public id?: number;
    public checkbox?: CheckboxButtonProps<boolean>;
    public nameKey?: string;

    constructor(ctor?: ISelectRebarFilterGroupItemConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.checkbox = ctor.checkbox;
            this.nameKey = ctor.nameKey;
        }
    }
}

enum CalculateAllProductUiItemType {
    Header = 0,
    Item = 1
}

interface ICalculateAllProductUiItem {
    // Type
    type: CalculateAllProductUiItemType;

    // Anchor data
    id: number;
    name: string;

    // Anchor details
    details?: ICalculateAllRebarDetailed | ICalculateAllShearConnectorsDetailed;
}

@Component({
    templateUrl: './select-rebar.component.html',
    styleUrls: ['./select-rebar.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectRebarComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<ISelectRebarComponentInput>;

    public rebars!: IProduct[];
    public productsData!: IProduct[];
    public calculateAllRebars!: ICalculateAllProductUiItem[];
    public sortDropdown!: DropdownProps<SelectRebarSortBy>;
    public filter: string | undefined;
    public mode!: SelectRebarMode;
    public calculateAllLoading!: boolean;
    public calculateAllData!: ICalculateAllProduct[];
    public loaded!: boolean;
    public filterGroups!: SelectRebarFilterGroup[];
    public progress!: number;

    public fixtureThicknessMin!: number;
    public fixtureThicknessMax!: number;
    public holeDiameterMin: number | undefined;
    public holeDiameterMax: number | undefined;
    public uiProductFilterInputInit!: UIProductFilterInputC2C;

    public icons: Array<IIcon> = [
        {
            image: 'sprite-anchor-no-cleaning-required',
            tooltip: SelectRebarIcon.noCleaningRequired,
            visibleExpression: 'isNoCleaningRequired'
        },
        {
            image: 'sprite-anchor-approved-automatic-cleaning',
            tooltip: SelectRebarIcon.automaticCleaningApproved,
            visibleExpression: 'isAutomaticCleaningApproved'
        },
        {
            image: 'sprite-anchor-tensile-zone-cracked-concrete',
            tooltip: SelectRebarIcon.crackedConcreteAllowed,
            visibleExpression: 'isCrackedConcreteAllowed'
        },
        {
            image: 'sprite-anchor-fatigue',
            tooltip: SelectRebarIcon.fatigueAllowed,
            visibleExpression: 'isFatigueAllowed'
        },
        {
            image: 'sprite-anchor-shock',
            tooltip: SelectRebarIcon.shock,
            visibleExpression: 'isShock'
        },
        {
            image: 'sprite-anchor-seismic',
            tooltip: SelectRebarIcon.seismicAllowed,
            visibleExpression: 'isSeismicAllowed'
        },
        {
            image: 'sprite-anchor-small-edge-dist-and-spacing',
            tooltip: SelectRebarIcon.smallEdgeDistAndSpacing,
            visibleExpression: 'isSmallEdgeDistAndSpacing'
        },
        {
            image: 'sprite-anchor-variable-embedment-depth',
            tooltip: SelectRebarIcon.variableEmbedment,
            visibleExpression: 'isVariableEmbedment'
        },
        {
            image: 'sprite-anchor-npp-approval-di-bt',
            tooltip: SelectRebarIcon.nppApprovalDiBt,
            visibleExpression: 'isNPPApprovalDiBt'
        },
        {
            image: 'sprite-anchor-fire-resistant',
            tooltip: SelectRebarIcon.fireAllowed,
            visibleExpression: 'isFireAllowed'
        },
        {
            image: 'sprite-anchor-clean-tech',
            tooltip: SelectRebarIcon.cleanTech,
            visibleExpression: 'isCleanTech'
        },
        {
            image: 'sprite-anchor-stainless-steel-hcr',
            tooltip: SelectRebarIcon.stainlessSteelOrHCRAvailable,
            visibleExpression: 'isStainlessSteelOrHCRAvailable'
        },
        {
            image: 'sprite-anchor-shallow-embedment-depth',
            tooltip: SelectRebarIcon.shallowEmbedmentDepth,
            visibleExpression: 'isShallowEmbedmentDepth'
        },
        {
            image: 'sprite-anchor-diamond-cored-holes-suitable',
            tooltip: SelectRebarIcon.diamondCoredHolesSuitable,
            visibleExpression: 'isDiamondCoredHolesSuitable'
        }
    ];

    public design!: Design;
    public unitLength;
    public selectRebarModeEnum = SelectRebarMode;
    public calculateAllProductUiItemTypeEnum = CalculateAllProductUiItemType;

    public rebarRowHeight = 90;                 // must be the same value as in css
    public calculateAllRebarRowHeight = 35;     // must be the same value as in css
    public scrollElement!: Element;

    private initializedSpriteImages: string[] = [];

    constructor(
        public localizationService: LocalizationService,
        private user: UserService,
        private unit: UnitService,
        private userSettings: UserSettingsService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private modal: ModalService,
        private calculationService: CalculationServiceC2C,
        private numberService: NumberService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        this.unitLength = this.unit.getDefaultUnit(UnitGroup.Length);
    }

    public get selectedRebarFamilyId() {
        return this.design.model[this.design.globalMetaProperties.sourceMetaProperty] as number;
    }
    public set selectedRebarFamilyId(value: number) {
        this.design.model[this.design.globalMetaProperties.sourceMetaProperty] = value;
    }

    public get selectedRebarTypeId() {
        return this.design.model[this.design.globalMetaProperties.sourceMetaPropertyType ?? 0] as number;
    }
    public set selectedRebarTypeId(value: number) {
        this.design.model[this.design.globalMetaProperties.sourceMetaPropertyType] = value;
    }

    public get selectedRebarFamilySizeId() {
        return this.design.model[this.design.globalMetaProperties.sourceMetaPropertySize] as number;
    }
    public set selectedRebarFamilySizeId(value: number) {
        this.design.model[this.design.globalMetaProperties.sourceMetaPropertySize] = value;
    }

    public get selectedDetailedProductId() {
        if (this.design.globalMetaProperties.isConcreteOverlay) {
            return this.selectedRebarFamilySizeId;
        }

        return this.selectedRebarFamilySizeId;
    }

    public get calculateAllButtonKey() {
        return 'Agito.Hilti.C2C.SelectRebar.' + (this.mode == null || this.mode == SelectRebarMode.normal
            ? 'CalculateAll'
            : 'CalculateAllClose');
    }

    public get filterPlaceholder() {
        return this.translate(`Agito.Hilti.C2C.SelectRebar.${this.mode == null || this.mode == SelectRebarMode.normal
            ? 'FilterInputPlaceholder'
            : 'FilterInputCalculateAllPlaceholder'}`);
    }

    public get rebarFamily() {
        return this.design.fastenerFamilies.find((rebar) => rebar.id == this.selectedRebarFamilyId);
    }

    public get favoriteTooltip() {
        return this.featuresVisibilityInfo.tooltip(Feature.Menu_Favorites) ||
            this.translate('Agito.Hilti.C2C.Main.Region.AddRemoveFromFavorites');
    }

    ngOnInit(): void {
        // don't close the modal if calculate all is pending
        this.modalInstance.setOnClosing(() => {
            if (this.calculateAllLoading) {
                return false;
            }

            this.setFiltersToProperties();
            return true;
        });

        this.scrollElement = document.querySelector('.modal') as Element;

        // Controls
        this.sortDropdown = {
            id: 'select-rebar-sort-rebar-sort-by-dropdown',
            items: [
                {
                    text: 'Default',    // XXX: missing translation
                    value: SelectRebarSortBy.default
                },
                {
                    text: 'Name',       // XXX: missing translation
                    value: SelectRebarSortBy.name
                }
            ],
            selectedValue: SelectRebarSortBy.default
        };

        this.design = this.user.design;

        // load filters
        const rebarFilters = this.design.designData.designCodeListsC2C[DesignCodeListC2C.ProductFilter] as RebarFilterEntity[] ?? [];
        let rebarFilterGroups = this.design.designData.designCodeListsC2C[DesignCodeListC2C.ProductFilterGroup] as ProductFilterGroup[] ?? [];

        // remove Design Basis if region is not UK
        if (this.design.region.id != KnownRegion.UnitedKingdom) {
            rebarFilterGroups = rebarFilterGroups.filter(x => x.id != 4);
        }

        // load saved filters
        const savedRebarFilters = this.design.model[PropertyMetaDataC2C.Product_C2C_ProductFilters.id] as UIProductFilterInputC2C;
        this.uiProductFilterInputInit = savedRebarFilters;

        this.holeDiameterMin = savedRebarFilters.holeDiameterMin;
        this.holeDiameterMax = savedRebarFilters.holeDiameterMax;

        // create filter structure
        this.filterGroups = rebarFilterGroups.map((filterGroup) => new SelectRebarFilterGroup({
            id: filterGroup.id,
            titleKey: filterGroup.displayKey,
            items: rebarFilters.filter((filter) => filter.filterGroupId == filterGroup.id).map((rebarfilter) => new SelectRebarFilterGroupItem({
                id: rebarfilter.id,
                nameKey: rebarfilter.displayKey,
                checkbox: SimpleCheckboxButtonHelper.createSimpleCheckbox({
                    itemText: this.translate(rebarfilter.nameResourceKey ?? ''),
                    // disabled: savedAnchorFilters.CheckboxFiltersDisabled.some(disabled => disabled.Id == anchorFilter.id),    // XXX: not needed?
                    checked: savedRebarFilters.checkboxFilters.includes(rebarfilter.id ?? 0)
                })
            }))
        }));

        // track used filters
        this.trackFilters();

        // load rebar family detailed
        let rebars = this.design.fastenerFamilies;
        const allowedValues = this.design.properties.get(this.design.globalMetaProperties.sourceMetaProperty).allowedValues;
        if (allowedValues != null) {
            rebars = rebars.filter((rebar) => allowedValues.includes(rebar.id ?? 0));
        }

        const rebarIds = rebars.map((rebar) => rebar.id) as number[] ?? [];
        this.setRebarsData(rebarIds);

        this.refreshRebars();
        this.refreshCalculateAllProducts();
        this.filterIcons();

        if (!this.loaded) {
            includeSprites(this.elementRef.nativeElement.shadowRoot,
                'sprite-favorite-true',
                'sprite-favorite-false',
                'sprite-anchor-family-no-photo-available'
            );

            this.icons.forEach(icon => {
                includeSprites(this.elementRef.nativeElement.shadowRoot, icon.image as Sprite);
            });
        }

        this.loaded = true;

        if (this.modalInstance.input?.calculateAll) {
            this.onCalculateAllButtonClick();
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public identifyFilterGroup: TrackByFunction<SelectRebarFilterGroup> = function (_: number, filterGroup: SelectRebarFilterGroup) {
        return filterGroup.id;
    };

    public identifyFilterGroupItem: TrackByFunction<SelectRebarFilterGroupItem> = function (_: number, filterGroupItem: SelectRebarFilterGroupItem) {
        return filterGroupItem.id;
    };

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public identifyRebar: TrackByFunction<IProduct> = function (index: number, _: IProduct) {
        // Returning index (inside virtual scroll's viewport) to ensure
        // the same components are being reused (performance improvement!).
        return index;
    };

    public identifyCalculateAllRebar: TrackByFunction<ICalculateAllProductUiItem> = function (index: number, _: ICalculateAllProductUiItem) {
        // Returning index (inside virtual scroll's viewport) to ensure
        // the same components are being reused (performance improvement!).
        return index;
    };
    /* eslint-enable @typescript-eslint/no-unused-vars */

    public identifyIcon: TrackByFunction<any> = function (index: number) {
        return index;
    };

    public isTscOk() {
        return true;
    }

    public getPrecisionLength() {
        return this.unit.getPrecision(this.design.unitLength);
    }

    public async selectRebar(rebar: IProduct) {
        this.setFiltersToProperties();
        this.selectedRebarFamilyId = rebar.id;
        await this.calculationService.calculateAsync(this.design);

        if (this.modalInstance.input?.onSelect != null) {
            this.modalInstance.input.onSelect(this.selectedRebarFamilyId);
        }

        this.close();
    }

    public onCalculateAllButtonClick() {
        if (this.mode == null || this.mode == SelectRebarMode.normal) {
            this.mode = SelectRebarMode.calculateAll;

            this.design.usageCounterC2C.CalculateAll++;
            this.calculateAllSignalR();
        }
        else {
            this.mode = SelectRebarMode.normal;

            this.refreshRebars();
        }
    }

    public async onClearAllButtonClick() {
        this.filter = undefined;
        this.holeDiameterMax = undefined;
        this.holeDiameterMin = undefined;
        this.sortDropdown.selectedValue = SelectRebarSortBy.default;

        for (const filterGroup of this.filterGroups) {
            if (filterGroup.items != undefined)
                for (const filterItem of filterGroup.items) {
                    if (filterItem.checkbox != undefined)
                        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(filterItem.checkbox, false);
                }
        }

        await this.resetRebarData();
        this.refreshRebars();
        this.refreshCalculateAllProducts();
    }

    public async resetRebarData() {
        await this.setFiltersToProperties();

        // load rebar family detailed
        let rebars = this.design.fastenerFamilies;
        const allowedValues = this.design.properties.get(this.design.globalMetaProperties.sourceMetaProperty).allowedValues;
        if (allowedValues != null) {
            rebars = rebars.filter((rebar) => allowedValues.includes(rebar.id ?? 0));
        }

        const rebarIds = rebars.map((rebar) => rebar.id) as number[] ?? [];
        this.setRebarsData(rebarIds);
    }

    public async selectRebarDetailed(rebarId: number, rebarDetailed: ICalculateAllRebarDetailed) {
        this.selectedRebarFamilyId = rebarId;

        if (this.design.globalMetaProperties.isConcreteOverlay) {
            const connectorDetailed = rebarDetailed as ICalculateAllShearConnectorsDetailed;
            this.selectedRebarTypeId = connectorDetailed?.typeId;
            this.selectedRebarFamilySizeId = connectorDetailed?.sizeId;
        }
        else {
            this.selectedRebarFamilySizeId = rebarDetailed.id;
        }

        await this.calculationService.calculateAsync(this.design);

        if (this.modalInstance.input?.onSelect != null) {
            this.modalInstance.input.onSelect(this.selectedRebarFamilyId, this.selectedRebarFamilySizeId, rebarDetailed.embedmentDepth);
        }

        this.close();
    }

    public getIconTooltip(icon: SelectRebarIcon) {
        let key: string;

        switch (icon) {
            case SelectRebarIcon.noCleaningRequired:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.NoCleaningRequired';
                break;

            case SelectRebarIcon.automaticCleaningApproved:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImageApprovedAutomaticCleaning';
                break;

            case SelectRebarIcon.crackedConcreteAllowed:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.TensileZoneCrackedConcrete';
                break;

            case SelectRebarIcon.fatigueAllowed:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.Fatigue';
                break;

            case SelectRebarIcon.shock:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.Shock';
                break;

            case SelectRebarIcon.seismicAllowed:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.Seismic';
                break;

            case SelectRebarIcon.smallEdgeDistAndSpacing:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.SmallEdgeDistAndSpacing';
                break;

            case SelectRebarIcon.variableEmbedment:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.VariableEmbedmentDepth';
                break;

            case SelectRebarIcon.nppApprovalDiBt:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.NPPApprovalDiBt';
                break;

            case SelectRebarIcon.fireAllowed:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.FireResistant';
                break;

            case SelectRebarIcon.cleanTech:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.CleanTech';
                break;

            case SelectRebarIcon.stainlessSteelOrHCRAvailable:
                key = this.design.isC2CHNA ? 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.StainlessSteelHCR' : 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.StainlessSteelHCR';
                break;

            case SelectRebarIcon.shallowEmbedmentDepth:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.ShallowEmbedmentDepth';
                break;

            case SelectRebarIcon.diamondCoredHolesSuitable:
                key = 'Agito.Hilti.C2C.Main.SelectRebar.RebarImage.DiamondCoredHolesSuitable';
                break;

            default:
                throw new Error('Unknown icon.');
        }

        return this.translate(key);
    }

    public getFavoriteSprite(rebarId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.design.region.id)) {
            return 'pe-ui-c2c-sprite-favorite-false disabled';
        }

        return hasProperty(this.userSettings?.settings?.rebarFavorites?.value ?? {}, rebarId.toString())
            ? 'pe-ui-c2c-sprite-favorite-true'
            : 'pe-ui-c2c-sprite-favorite-false';
    }

    public favoriteToggle(rebarId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.design.region.id)) {
            return;
        }

        if (hasProperty(this.userSettings?.settings?.rebarFavorites?.value ?? {}, rebarId.toString())) {
            this.userSettings.settings.rebarFavorites.value = Object.fromEntries(Object.entries(
                this.userSettings?.settings?.rebarFavorites?.value ?? {})
                .filter(([favoriteId]) => favoriteId != rebarId.toString()));
        }
        else {
            this.userSettings.settings.rebarFavorites.value = {
                ...this.userSettings.settings.rebarFavorites.value,
                [rebarId]: undefined
            };
        }

        this.refreshRebars();
        this.refreshCalculateAllProducts();

        this.userSettings.save();
    }

    public close() {
        this.modalInstance.close();
    }

    public resetFilter() {
        this.onFilterChange(undefined);
    }

    public onFilterChange(filter: string | undefined) {
        this.filter = filter;

        this.refreshRebars();
        this.refreshCalculateAllProducts();
    }

    public onFilterInputValueChanged(
        numberValue: number,
        property: 'holeDiameterMin' | 'holeDiameterMax'
    ) {
        // set other value if needed
        switch (property) {
            case 'holeDiameterMin': {
                if (numberValue != null && this.holeDiameterMax != null && numberValue > this.holeDiameterMax) {
                    this.holeDiameterMax = numberValue;
                }

                const recalculate = this.uiProductFilterInputInit.holeDiameterMin != undefined ? numberValue < this.uiProductFilterInputInit.holeDiameterMin : false;
                this.onMinMaxHoleDiameterChange(numberValue, this.holeDiameterMin ?? numberValue, recalculate);
                this.holeDiameterMin = numberValue;
                break;
            }
            case 'holeDiameterMax':
                {
                    if (numberValue != null && this.holeDiameterMin != null && numberValue < this.holeDiameterMin) {
                        this.holeDiameterMin = numberValue;
                    }

                    this.onMinMaxHoleDiameterChange(numberValue, this.holeDiameterMax ?? numberValue, this.uiProductFilterInputInit.holeDiameterMax != null && this.uiProductFilterInputInit.holeDiameterMax < numberValue);
                    this.holeDiameterMax = numberValue;
                    break;
                }
        }
    }

    public async onFilterCheckboxChange(filterItem?: SelectRebarFilterGroupItem, filterGroup?: SelectRebarFilterGroup) {
        const filteredItems = filterGroup?.items?.filter(item => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox ?? {})) ?? [];
        const filterItemsInit = filterGroup?.items?.filter(item => this.uiProductFilterInputInit.checkboxFilters.some(x => item.id == x)) ?? [];

        if ((filterItemsInit.length > 0 && filteredItems.filter(x => !filterItemsInit.some(fi => fi.id == x.id)).length > 0) ||
            (filterItemsInit.length > 0 && filterItemsInit.length < (filterGroup?.items?.length ?? 0) && filteredItems.length == 0) ||
            (filterItemsInit.length == 1 && ((filterGroup?.items?.length ?? 0) == 1) && filteredItems.length == 0)) {
            await this.resetRebarData();
        }
        this.refreshRebars();
        this.refreshCalculateAllProducts();

        // tracking
        if (filterItem && filterGroup) {
            this.trackFilters();
        }
    }

    public onSortChange(selectedValue: SelectRebarSortBy) {
        this.sortDropdown.selectedValue = selectedValue;
        this.refreshRebars();
        this.refreshCalculateAllProducts();
    }

    public openFilterGroupInfoPopup(filterGroup: SelectRebarFilterGroup) {
        let rawContent = filterGroup.infoPopup?.content;

        if (selectAnchorTooltipImages != null) {
            rawContent = rawContent?.replace(/agt-img="(.*?)"/g, (_, name: string) =>
                `src="${selectAnchorTooltipImages[name.startsWith('SelectAnchorTooltip/') ? name.substring('SelectAnchorTooltip/'.length) : name] ?? ''}"`);
        }

        this.modal.openConfirmChange({
            id: 'tooltip-popup',
            title: filterGroup.infoPopup?.title,
            message: rawContent,
            confirmButtonText: this.translate('Agito.Hilti.Profis3.ControlTooltip.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }

    private calculateAllSignalR() {
        this.calculateAllLoading = true;
        this.progress = 0;

        this.calculationService.calculateAllC2CSignalR(this.design, undefined,
            (prog: any) => {
                this.progress = Math.round(prog.Progress);
            })
            .then(() => {
                this.setDesignCalculateAll();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.mode = SelectRebarMode.normal;
            })
            .finally(() => {
                this.calculateAllLoading = false;
            });
    }

    private setDesignCalculateAll() {
        this.calculateAllData = (cloneDeep(this.design.designData.calculateAllData) as any) || [];

        for (const product of this.calculateAllData) {
            product.description = this.productsData.find((displayRebar) => displayRebar.id == product.id)?.description ?? '';

            // detailed rebars
            if (this.design.globalMetaProperties.isConcreteOverlay) {
                for (const connectorDetailed of product.detailed as ICalculateAllShearConnectorsDetailed[]) {
                    connectorDetailed.utilizationFormated = this.formatPercentage(connectorDetailed.utilization);
                    connectorDetailed.sizeFormated = this.formatLength(connectorDetailed.size);
                    connectorDetailed.embedmentDepthFormated = this.formatLength(connectorDetailed.embedmentDepth);
                }
            }
            else {
                for (const rebarDetailed of product.detailed as ICalculateAllRebarDetailed[]) {
                    rebarDetailed.sizeFormated = this.formatLength(rebarDetailed.size);
                    rebarDetailed.embedmentDepthFormated = this.formatLength(rebarDetailed.embedmentDepth);
                    rebarDetailed.utilizationFormated = this.formatPercentage(rebarDetailed.utilization);
                }
            }
        }

        this.refreshCalculateAllProducts();
    }

    private formatPercentage(value: number) {
        if (value == null) {
            return '';
        }

        return this.unit.formatNumber(value, 2) + '%';
    }

    private formatLength(internalValue: number) {
        if (internalValue == null) {
            return '';
        }

        const internalUnit = this.unit.getInternalUnit(UnitGroup.Length);
        const defaultUnit = this.unitLength;

        const defaultValue = this.unit.convertUnitValueArgsToUnit(internalValue, internalUnit, defaultUnit);

        return this.unit.formatUnitValueArgs(defaultValue, defaultUnit);
    }

    private filterByCheckboxes(rebars: IProduct[]) {
        const selectedRebarGroups = this.filterGroups.filter((filterGroup) => filterGroup.items?.some((filterItem) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(filterItem.checkbox ?? {})));
        let filteredRebars: IProduct[] = [];

        filteredRebars = rebars.filter(anchorDetailed => {
            const allowed = anchorDetailed.allowedInputFilters;

            // is allowed in 'or' groups
            return selectedRebarGroups.every(groups => {
                const allowedGroup = groups.items?.filter((item) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox ?? {})) ?? [];

                return allowedGroup.some(item => {
                    if (allowed[item.nameKey ?? '']) {
                        if (this.holeDiameterMin != undefined && this.holeDiameterMax != undefined) {
                            if (allowed[item.nameKey ?? ''].length == 0 || allowed[item.nameKey ?? ''].every((holeDiameter) => holeDiameter < (this.holeDiameterMin ?? 0) || holeDiameter > (this.holeDiameterMax ?? 0))) {
                                return false;
                            }
                        }
                        else if (this.holeDiameterMin != undefined) {
                            if (allowed[item.nameKey ?? ''].length == 0 || allowed[item.nameKey ?? ''].every((holeDiameter) => holeDiameter < (this.holeDiameterMin ?? 0))) {
                                return false;
                            }
                        }
                        else if (this.holeDiameterMax != undefined) {
                            if (allowed[item.nameKey ?? ''].length == 0 || allowed[item.nameKey ?? ''].every((holeDiameter) => holeDiameter > (this.holeDiameterMax ?? 0))) {
                                return false;
                            }
                        }

                        return true;
                    }

                    return false;
                });
            });
        });

        return filteredRebars;
    }

    private filterByMinMax(rebars: IProduct[]) {
        return rebars.filter(r => {
            if (this.holeDiameterMin != undefined && this.holeDiameterMax != undefined) {
                if (r.holeDiameters.length == 0 || r.holeDiameters.every((holeDiameter) => holeDiameter < (this.holeDiameterMin ?? 0) || holeDiameter > (this.holeDiameterMax ?? 0))) {
                    return false;
                }
            }
            else if (this.holeDiameterMin != undefined) {
                if (r.holeDiameters.length == 0 || r.holeDiameters.every((holeDiameter) => holeDiameter < (this.holeDiameterMin ?? 0))) {
                    return false;
                }
            }
            else if (this.holeDiameterMax != undefined) {
                if (r.holeDiameters.length == 0 || r.holeDiameters.every((holeDiameter) => holeDiameter > (this.holeDiameterMax ?? 0))) {
                    return false;
                }
            }

            return true;
        });
    }

    private filterBySearch(rebars: IProduct[], filter: string) {
        if (this.filter == null || this.filter.trim() == '') {
            return rebars;
        }

        const filteredRebars: IProduct[] = [];
        const lowerCaseFilter = filter.toLowerCase();

        for (const rebar of rebars) {
            const containsName = rebar.name != null ? rebar.name.toLowerCase().indexOf(lowerCaseFilter) > -1 : false;
            const containsDescription = rebar.description != null ? rebar.description.toLowerCase().indexOf(lowerCaseFilter) > -1 : false;

            if (containsName || containsDescription) {
                filteredRebars.push(rebar);
            }
        }

        return filteredRebars;
    }

    private sortRebarFamilyBy(rebars: IProduct[]) {
        if (this.sortDropdown.selectedValue == undefined) {
            return [];
        }
        switch (this.sortDropdown.selectedValue) {
            case SelectRebarSortBy.default:
                return sortBy(rebars,
                    rebar => hasProperty(this.userSettings?.settings?.rebarFavorites?.value ?? {}, rebar.id.toString()) ? 0 : 1,
                    rebar => rebar.sortOrder);

            case SelectRebarSortBy.name:
                return sortBy(rebars,
                    rebar => hasProperty(this.userSettings?.settings?.rebarFavorites?.value ?? {}, rebar.id.toString()) ? 0 : 1,
                    rebar => rebar.name.toLowerCase());

            default:
                throw new Error('Invalid sort by.');
        }
    }

    private sortCalculateAllBy(rebars: ICalculateAllProduct[]) {
        if (rebars == null) {
            return null;
        }

        switch (this.sortDropdown.selectedValue) {
            case SelectRebarSortBy.default:
                return sortBy(rebars, 1, rebar => rebar.id);

            case SelectRebarSortBy.name:
                return sortBy(rebars, 1, rebar => rebar.name.toLowerCase());

            default:
                throw new Error('Invalid sort by.');
        }
    }

    private setRebarsData(rebarIds: number[]) {
        const filtered = this.design.fastenerFamilies.filter((rebar) => rebarIds.some((id) => id == rebar.id));
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        this.productsData = filtered.map((fastener): IProduct => ({
            id: fastener.id ?? 0,
            name: fastener.getTranslatedNameText(codeListDeps),
            image: fastener.image ?? '',
            description: this.formatDescription(fastener.descriptionFormat ?? '', fastener.descriptionKeys ?? []),
            isNoCleaningRequired: fastener.isZeroCleaningAllowed ?? false,
            isAutomaticCleaningApproved: fastener.isAutomaticCleaningApproved ?? false,
            isCrackedConcreteAllowed: fastener.isCrackedConcreteAllowed ?? false,
            isFatigueAllowed: fastener.isFatigueAllowed ?? false,
            isShock: fastener.isShock ?? false,
            isSeismicAllowed: fastener.isSeismicAllowed ?? false,
            isSmallEdgeDistAndSpacing: fastener.isSmallEdgeDistanceAndSpacing ?? false,
            isVariableEmbedment: fastener.isVariableEmbedment ?? false,
            isNPPApprovalDiBt: fastener.isNuclearApproved_EU ?? false,
            isFireAllowed: fastener.isFireAllowed ?? false,
            isCleanTech: fastener.isCleanTech ?? false,
            isStainlessSteelOrHCRAvailable: fastener.isStainlessSteelOrHCRAvailable ?? false,
            isShallowEmbedmentDepth: fastener.isShallowEmbedmentDepth ?? false,
            isDiamondCoredHolesSuitable: fastener.isDiamondDrillingSuitable ?? false,
            tag: !fastener.tested
                ? this.translate('Agito.Hilti.C2C.Rebars.Untested.Tag')
                : '',
            returnPeriod: parseInt(fastener.returnPeriod ?? ''),
            hasTR069: fastener.hasTR069 ?? false,
            sortOrder: fastener.sortOrder ?? 0,
            allowedInputFilters: fastener.allowedFilterInputs ?? {},
            holeDiameters: fastener.holeDiameters ?? []
        }));
    }

    private filterProducts(noSearchFilter?: boolean) {
        let rebars = this.productsData.slice();

        // allowed values
        const allowedValues = this.design.properties.get(this.design.globalMetaProperties.sourceMetaProperty).allowedValues;
        if (allowedValues != null) {
            rebars = rebars.filter((rebar) => allowedValues.includes(rebar.id));
        }

        // search filter
        if (!noSearchFilter) {
            rebars = this.filterBySearch(rebars, this.filter ?? '');
        }

        // search filter - min max
        rebars = this.filterByMinMax(rebars);

        // checkbox filters
        rebars = this.filterByCheckboxes(rebars);

        // sorting
        rebars = this.sortRebarFamilyBy(rebars);

        return rebars;
    }

    private refreshRebars() {
        this.rebars = this.filterProducts();

        this.rebars.forEach(rebar => {
            if (!this.initializedSpriteImages.includes(rebar.image)) {
                includeSprites(this.elementRef.nativeElement.shadowRoot, rebar.image as Sprite);
                this.initializedSpriteImages.push(rebar.image);
            }
        });
    }

    private onMinMaxHoleDiameterChange(minMax: number, oldMinMax: number, recalculate: boolean) {
        this.onMinMaxChange(minMax, oldMinMax, recalculate);
        if (minMax !== oldMinMax) {
            this.design.usageCounterC2C.BasePlateHoleDiameterFilter++;
        }
    }

    private async onMinMaxChange(minMax: number, oldMinMax: number, recalculate: boolean) {
        if (minMax !== oldMinMax) {
            if (recalculate) {
                await this.resetRebarData();
            }
            this.refreshRebars();
            this.refreshCalculateAllProducts();
        }
    }

    private filterCalculateAllProducts() {
        if (this.calculateAllData == null) {
            return [];
        }

        const rebars = this.filterProducts();
        let calculateAllRebars = cloneDeep(this.calculateAllData);

        calculateAllRebars = calculateAllRebars.filter(calculateAllRebar => rebars.some((rebar) => rebar.id == calculateAllRebar.id));

        calculateAllRebars = this.sortCalculateAllBy(calculateAllRebars) ?? [];

        return calculateAllRebars;
    }

    private flattenCalculateAllProductData(calculateAllProducts: ICalculateAllProduct[]) {
        const retVal: ICalculateAllProductUiItem[] = [];

        if (calculateAllProducts != null && calculateAllProducts.length > 0) {
            for (const product of calculateAllProducts) {
                retVal.push({
                    type: CalculateAllProductUiItemType.Header,
                    id: product.id,
                    name: product.name
                });

                for (const details of product.detailed) {
                    retVal.push({
                        type: CalculateAllProductUiItemType.Item,
                        id: product.id,
                        name: product.name,
                        details
                    });
                }
            }
        }

        return retVal;
    }

    private refreshCalculateAllProducts() {
        this.calculateAllRebars = this.flattenCalculateAllProductData(this.filterCalculateAllProducts());
    }

    private formatDescription(descriptionFormat: string, descriptionKeys: string[]) {
        return format(descriptionFormat, ...descriptionKeys.map((descriptionKey) => this.translate(descriptionKey)));
    }

    private async setFiltersToProperties() {
        const filteredGroups = this.filterGroups.filter((filterGroup) => filterGroup.items?.some((filterItem) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(filterItem.checkbox ?? {})));

        this.design.model[PropertyMetaDataC2C.Product_C2C_ProductFilters.id] = {
            holeDiameterMin: this.holeDiameterMin,
            holeDiameterMax: this.holeDiameterMax,
            checkboxFilters: filteredGroups.flatMap(group =>
                group.items?.filter(item => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox ?? {})).map((item) => item.id)),

        } as UIProductFilterInputC2C;

        await this.calculationService.calculateAsync(this.design);

        // load saved filters
        this.uiProductFilterInputInit = this.design.model[PropertyMetaDataC2C.Product_C2C_ProductFilters.id] as UIProductFilterInputC2C;
    }

    // Removes unwanted icons based on the design.
    private filterIcons() {
        let unusedIcons = Array<SelectRebarIcon>();

        // Sets the unused icons array based on the design settings.
        if (this.design.isC2CHNA) {
            unusedIcons = [SelectRebarIcon.smallEdgeDistAndSpacing, SelectRebarIcon.shallowEmbedmentDepth, SelectRebarIcon.shock, SelectRebarIcon.nppApprovalDiBt];
            if (this.design.isC2COverlay) {
                unusedIcons.push(SelectRebarIcon.fireAllowed);
            }
        }

        // Removes all the unused icons from the main icon array.
        for (const unusedIcon of unusedIcons) {
            const removeIndex = this.icons.findIndex(iconObject => iconObject.tooltip === unusedIcon);
            this.icons.splice(removeIndex, 1);
        }
    }

    private trackFilters() {
        this.design.usageCounterC2C.FilterUsed = this.filterGroups.some((filterGroup) => filterGroup.items?.some((item) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox ?? {})));
        this.design.usageCounterC2C.trackFilterGroupUsed(this.filterGroups.filter(group =>
            group.items?.some(item => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox ?? {}) ?? false)
        ).map(group => group.titleKey ?? ''));
    }
}
