import { IDropdownItem } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { DesignC2C } from '../../shared/entities/design-c2c';
import { ApplicationType, LoadType } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { LocalizationService } from '../services/localization.service';

export class ApplicationTypeHelper {
    static changeApplicationTypeItemsForExtensionAtSupport(items: IDropdownItem[], design: DesignC2C, localizationService: LocalizationService) {
        if (!(design.isC2CHNA && design.loadTypeC2C == LoadType.Fire)) {
            return;
        }

        const relevantApplicationTypes = [ApplicationType.SlabToSlab, ApplicationType.BeamToBeam, ApplicationType.BeamToSlab];
        const isExtensionAtSupport = design.designData.projectDesignC2C?.newStructure.existingConcrete_ExtensionAtSupport ?? false;

        items.filter(i => relevantApplicationTypes.includes(i.value))
            .forEach(i => i.text = localizationService.getString(`${i.textTranslationKey}${(isExtensionAtSupport ? '.Support' : '')}`));
    }
}
