import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { CheckboxButtonProps } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { ApplicationState } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { SimpleCheckboxButtonHelper } from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { UIProperty } from '../../../shared/entities/code-lists/UI-property';
import { MethodGroupConnTypeStandardRegion } from '../../../shared/entities/code-lists/connection-type-method-standard-regions';
import { DesignMethodGroup } from '../../../shared/entities/code-lists/design-method-group';
import { DesignStandard } from '../../../shared/entities/code-lists/design-standard';
import { DesignType as DesignTypeEntity } from '../../../shared/entities/code-lists/design-type';
import { DesignType } from '../../../shared/entities/tracking-data';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { DesignService } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    templateUrl: './add-edit-design-footer.component.html',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AddEditDesignFooterComponent implements OnChanges {
    @Input()
    public addEditType!: AddEditType;
    @Input()
    public selectedRegionId!: number;
    @Input()
    public submitted!: boolean;
    @Input()
    public save = () => this.saveInternal();

    public applyToAllDesignsCheckbox!: CheckboxButtonProps<boolean>;
    public isLoaded = false;

    private _designType!: DesignTypeEntity;

    constructor(
        private localizationService: LocalizationService,
        private userSettingsService: UserSettingsService,
        private codeListService: CodeListService,
        private commonCodeList: CommonCodeListService,
        private designService: DesignService
    ) { }

    public get isVisible() {
        return this.addEditType == AddEditType.addFromQuickStart;
    }

    private get designStandardId() {
        return this.getDesignStandardForRegion(this.selectedRegionId) || this.designStandards[0].id;
    }

    private get designStandards() {
        const allDesignStandards = this.codeListService.projectCodeListsC2C[ProjectCodeList.DesignStandardsC2C] as DesignStandard[];
        const methodGroupConnTypeStandardRegions = this.codeListService.projectCodeListsC2C[ProjectCodeList.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];

        const designMethodGroups = (this.codeListService.projectCodeListsC2C[ProjectCodeList.DesignMethodGroupsC2C] as DesignMethodGroup[])
            .filter((methodGroup) => methodGroupConnTypeStandardRegions.some(x => x.designMethodGroupId == methodGroup.id && x.regionId == this.selectedRegionId)) || [];

        const standards = allDesignStandards
            .filter(standard => designMethodGroups
                .filter(designMethod => methodGroupConnTypeStandardRegions
                    .filter(x => x.designMethodGroupId == designMethod.id && x.regionId == this.selectedRegionId)
                    .map(y => y.designStandardId)
                    .includes(standard.id)).length > 0);

        return standards;
    }

    private get designType() {
        if (this._designType == null) {
            const desingTypeCodeList = this.codeListService.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C] as DesignTypeEntity[];

            this._designType = desingTypeCodeList.find((desingTypeCodeListItem) => desingTypeCodeListItem.id == DesignType.Concrete2Concrete) as DesignTypeEntity;
        }

        return this._designType;
    }

    ngOnChanges(): void {
        if (!this.isLoaded) {
            const checked = this.designService.applyToAllDesigns(this.userSettingsService, this.codeListService, this.designStandardId);

            this.applyToAllDesignsCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
                itemId: 'add-edit-design-apply-to-all-checkbox',
                itemText: this.localizationService.getString('Agito.Hilti.Profis3.AddEditDesign.ApplyToAll'),
                checked: checked
            });

            this.isLoaded = true;
        }
    }

    private saveInternal() {
        const applyToAllDesigns = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.applyToAllDesignsCheckbox);
        this.userSettingsService.settings.quickStart.concrete2Concrete.applicationState.value = applyToAllDesigns ? ApplicationState.SelectedConnection : ApplicationState.AlwaysAskMe;
    }

    private getDesignStandardForRegion(regionId: number) {
        let designStandard = null;

        const defaultStandardId = this.getDefaultStandardId(regionId);
        const designStandardsC2C = this.codeListService.projectCodeListsC2C[ProjectCodeList.DesignStandardsC2C] as DesignStandard[];
        designStandard = designStandardsC2C.find((ds) => ds.id == defaultStandardId);

        if (designStandard) {
            return designStandard.id;
        }

        const designStandardRegion = this.designType?.allowedDesignStandardRegions?.find((row) => row.RegionId == regionId);

        if (designStandardRegion != null && designStandardRegion.DesignStandards.length > 0) {
            return designStandardRegion.DesignStandards[0];
        }

        return null;
    }

    private getDefaultStandardId(regionId: number) {
        const uIProperties = this.codeListService.projectCodeListsC2C[ProjectCodeList.UIPropertiesC2C] as UIProperty[];
        const propertyValues = uIProperties.find((property) => property.id == PropertyMetaDataC2C.Option_C2C_DesignStandard.id);

        let propertyValue = propertyValues?.propertyValues?.find((property) => property.regionId == regionId);
        const notExistingDesignStandard = !this.designStandards.some(e => e.id === propertyValue?.defaultValue);
        if (!propertyValue || notExistingDesignStandard) {
            propertyValue = propertyValues?.propertyValues?.find((property) => property.regionId == 0);
        }

        return propertyValue?.defaultValue;
    }
}
