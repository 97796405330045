import { buildHtmlTooltip } from '@profis-engineering/pe-ui-common/helpers/tooltip-helper';
import { ISelectRebarProps } from '../../../../shared/entities/main-menu/main-menu-controls';
import { getMenuControlImageReactData, getReactCSSProperties } from '../../../helpers/react-helpers';
import { getSpriteAsIconStyleResponsive } from '../../../sprites';
import styles from './react-select-rebar.css';
import { ContolsStyleSheets } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';

const sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

const ControlHeader = (window as any).pe.reactComponents.ControlHeader;

class _SelectRebar extends React.PureComponent<ISelectRebarProps> {
    private noPhotoImageData: React.CSSProperties | undefined;
    public static readonly styleSheets = [sheet];

    constructor(props: ISelectRebarProps) {
        super(props);

        this.noPhotoImageData = getReactCSSProperties(getSpriteAsIconStyleResponsive('sprite-anchor-family-no-photo-available'));
        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const buttonId = `${this.props.controlId}-button`;
        const tooltip = this.props.title != null && this.props.title != '' ? null : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        const imageData = getMenuControlImageReactData(
            this.props.buttonImage,
            this.props.buttonImageStyle?.elementStyle,
            this.props.buttonImageStyle?.afterElementStyle
        );
        const useResponsiveStyle = imageData.elementAfterStyle != null;

        return (
            <div data-control-id={this.props.controlId} className={`react-select-rebar control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization} />

                <button
                    data-control-id={buttonId}
                    className='button-rich select-rebar-button'
                    type='button'
                    disabled={this.props.disabled}
                    onClick={this.onClick}
                    data-tip={tooltip}
                    data-html={tooltip != null ? true : null}>
                    <div className='select-rebar-button-content button-rich-content'>
                        <div className='button-rich-image'>
                            <div className='image-wrapper'>
                                <span className='no-image sprite sprite-responsive'>
                                    <span className='sprite-responsive-after' style={this.noPhotoImageData}></span>
                                </span>

                                <div className='rebar-image-container'>
                                    <span className={`rebar-image sprite sprite-responsive ${imageData.spriteName ?? ''}`} style={imageData.elementStyle}>
                                        {
                                            useResponsiveStyle
                                                ? <span className='sprite-responsive-after' style={imageData.elementAfterStyle}></span>
                                                : null
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='button-rich-seperator' />
                        <p className='rebar-name button-rich-text'>{this.props.buttonText ?? ''}</p>
                        {this.props.tag != null && this.props.tag.length > 0 ? <span className='tag'>{this.props.tag}</span> : null}
                        <span className='sprite sprite-arrow-right button-rich-caret'></span>
                    </div>
                </button>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked();
        }
    }

}

export const SelectRebar: typeof _SelectRebar & ContolsStyleSheets = _SelectRebar;