import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    CommonRegion as Region
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Unit as UnitItem } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import { getSpecialRegionName } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { sortByUnicode } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    CommonCodeList as ProjectCodeList
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';

import {
    MethodGroupConnTypeStandardRegion
} from '../../shared/entities/code-lists/connection-type-method-standard-regions';
import {
    DesignMethodGroup as DesignMethodGroupC2C
} from '../../shared/entities/code-lists/design-method-group';
import {
    DesignStandard as DesignStandardC2C
} from '../../shared/entities/code-lists/design-standard';
import { DesignType } from '../../shared/entities/code-lists/design-type';
import { DesignType as DesignTypeEnum } from '../../shared/entities/tracking-data';
import { ProjectCodeList as ProjectCodeListC2C } from '../../shared/enums/project-code-list';
import {
    AppSettingsHelperC2C as AppSettingsHelperC2CShared
} from '../../shared/helpers/app-settings-helper';
import {
    CodeListServiceBase as CodeListServiceC2C
} from '../../shared/services/code-list.service.base';
import { CommonCodeListService as CodeListService } from '../services/common-code-list.service';
import { LocalizationService } from '../services/localization.service';
import { NumberService } from '../services/number.service';
import { UserSettingsService } from '../services/user-settings.service';
import { getDesignTypeSpecificKey } from './localization-helper';

export class AppSettingsHelperC2C extends AppSettingsHelperC2CShared {
    constructor(
        protected localizationService: LocalizationService,
        protected userSettings: UserSettingsService,
        protected codeList: CodeListService,
        codeListC2C: CodeListServiceC2C,
        protected numberService: NumberService
    ) {
        super(codeList, codeListC2C);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public get designTypeC2C() {
        return this.getDesignType(DesignTypeEnum.Concrete2Concrete);
    }

    // Design standard and method
    public getDesignStandardsC2C(designTypeRegionId: number, defaultRegionId: number, connectionTypeId: number) {
        const methodGroupConnTypeStandardRegionsC2C = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];
        const allDesignStandard = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignStandardsC2C] as DesignStandardC2C[];

        // if default is selected in c2c settings use the general default region
        const regionId = designTypeRegionId > 0
            ? designTypeRegionId
            : defaultRegionId;

        const filteredDesignStandardIds = methodGroupConnTypeStandardRegionsC2C
            .filter(x => x.regionId == regionId && x.connectionTypeId == connectionTypeId).map(x => x.designStandardId);

        return allDesignStandard.filter(x => filteredDesignStandardIds.some(i => i == x.id));
    }

    public getDesignMethodGroupTitle(designMethodGroup: DesignMethodGroupC2C): string | null {
        if (!designMethodGroup || !designMethodGroup.nameResourceKey) {
            return null;
        }

        return this.translate(designMethodGroup.nameResourceKey);
    }

    public getDesignStandardTitle(localization: LocalizationService, designTypeId: DesignTypeEnum, designStandard?: DesignStandardC2C): string | undefined {
        if (!designStandard) {
            return undefined;
        }

        if (!designStandard.nameResourceKey) {
            return undefined;
        }

        return this.translate(getDesignTypeSpecificKey(localization, designStandard.nameResourceKey, designTypeId));
    }

    public createDropdownComponent<TValue>(id: string, translationKey: string, items?: DropdownItem<TValue>[], selectedValue?: TValue) {
        const dropdown: DropdownProps<TValue> = {
            id: `application-settings-${id}`,
            title: translationKey ? this.translate(`Agito.Hilti.Profis3.ApplicationSettings.${translationKey}`) : '',
            items,
            selectedValue
        };

        return dropdown;
    }

    public createUnitDropdown(id: string, translationKey: string, codeList: ProjectCodeList) {
        return this.createDropdownComponent<number>(
            `unit-${id}`,
            translationKey,
            (this.codeList.commonCodeLists[codeList] as UnitItem[]).map(unit => ({
                value: unit.id,
                text: unit.name
            }) as DropdownItem<number>)
        );
    }

    public getSpecialRegions() {
        const specialRegions: any[] = [];

        for (let i = 0; i < 2; i++) {
            specialRegions.push({
                id: -(i + 1),
                nameResourceKey: 'Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.' + getSpecialRegionName(-(i + 1))
            });
        }

        return specialRegions;
    }

    public getAvailableRegions(designType: DesignType) {
        // none and default regions
        const specialRegions = this.getSpecialRegions();
        const allRegions = this.codeList.commonCodeLists[ProjectCodeList.Region] as Region[];
        const availableRegions = allRegions.filter((region) => designType.allowedDesignStandardRegions?.some((row) => row.RegionId == region.id));

        // sort
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const availableRegionsSorted = sortByUnicode(
            availableRegions.map(
                (region) => {
                    return {
                        region,
                        text: region.getTranslatedNameText(codeListDeps) ?? ''
                    };
                }
            ),
            'text'
        ).map(
            (o) => {
                return o.region;
            }
        );

        return [...specialRegions, ...availableRegionsSorted];
    }

    private getDesignType(type: DesignTypeEnum) {
        const designTypeCodeList = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignTypeC2C] as DesignType[];
        return designTypeCodeList.find(designType => designType.id == type);
    }
}
