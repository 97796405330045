export abstract class LicenseServiceBase {
    public abstract get floatingLimitReached(): boolean;
    public abstract isFree(): boolean;
    public abstract isOnlyBasic(): boolean;
}

export abstract class LicenseServiceInjected extends LicenseServiceBase {
    protected baseService!: LicenseServiceBase;

    public setBaseService(baseService: LicenseServiceBase) {
        this.baseService = baseService;

        this.isFree = baseService.isFree.bind(baseService);
        this.isOnlyBasic = baseService.isOnlyBasic.bind(baseService);
    }

    public get floatingLimitReached(): boolean {
        return this.baseService.floatingLimitReached;
    }

    public isFree(): boolean {
        return false;
    }
    public isOnlyBasic(): boolean {
        return false;
    }
}