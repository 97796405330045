import clone from 'lodash-es/clone';

import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';

import { OptimizationStrategy } from '../../../shared/entities/code-lists/optimization-strategy';
import { IOptimizationStrategyInputsData } from '../../../shared/entities/optimization-strategy-inputs-data';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { DesignMethodGroup } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { getPropertyValueById } from '../../helpers/ui-property-helper';
import { OptimizationStrategyOptionsAs } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';

@Component({
    templateUrl: './optimization-strategy-inputs.component.html',
    styleUrls: ['./optimization-strategy-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class OptimizationStrategyInputsComponent implements OnInit, DoCheck {
    @Input()
    public data!: IOptimizationStrategyInputsData;

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    @Input()
    public designMethodGroupId?: number;

    public optimizationStrategy!: DropdownProps<OptimizationStrategy>;
    public optimizationStrategyItems!: DropdownItem<number>[];

    private oldRegionId!: number;
    private oldData!: IOptimizationStrategyInputsData;
    private oldDesignMethodGroupId: number | undefined;
    public dataLoaded = false;

    constructor(
        private localizationService: LocalizationService,
        private codeList: CodeListService
    ) { }

    ngOnInit(): void {
        this.initSafetyFactorsControls();
        this.populateDropdownItems();
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setDefaultValues();
            this.refreshControls();

            this.dataLoaded = true;
        }

        this.updateData();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public get optimizationStrategyValue() {
        return this.useAusOptions ? this.data.optimizationStrategyOptionsAs.optimizationStrategy : this.data.optimizationStrategy;
    }

    private updateData() {
        let update = false;

        if (this.oldRegionId != this.regionId) {
            this.refreshControls();

            this.oldRegionId = this.regionId;
        }

        if (this.oldDesignMethodGroupId != this.designMethodGroupId) {
            this.refreshControls();

            this.oldDesignMethodGroupId = this.designMethodGroupId;
        }

        if (this.oldData != null) {
            if (this.oldData.connectionType != this.data.connectionType) {
                update = true;
                this.refreshControls();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);
        }
    }

    private get useAusOptions() {
        return this.designMethodGroupId == DesignMethodGroup.AS3600ETA;
    }

    private updateControlDropdown(defaultValue: number) {
        this.optimizationStrategyChanged(defaultValue);
    }

    private setDefaultValues() {
        if (this.data.design != null) {
            const design = this.data.design();

            this.data.optimizationStrategy = design.optimizationStrategy;

            this.data.optimizationStrategyOptionsAs = {
                optimizationStrategy: design.designOptionsAs?.optimizationStrategyOptions.optimizationStrategy
            } as OptimizationStrategyOptionsAs;
        }
    }

    private get optimizationStrategyPropertyMetaData() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_OptimizationStrategy : PropertyMetaDataC2C.Option_C2C_OptimizationStrategy;
    }

    private get defaultOptimizationStrategy() {
        return this.optimizationStrategyValue ?? this.getPropertyValueById(this.optimizationStrategyPropertyMetaData.id)?.defaultValue ?? 0;
    }

    private refreshControls() {
        this.updateControlDropdown(this.defaultOptimizationStrategy);
    }

    private getPropertyValueById(id: number) {
        return getPropertyValueById(id, this.codeList, this.regionId, undefined, this.data.connectionType, this.designMethodGroupId);
    }

    private initSafetyFactorsControls() {
        this.optimizationStrategy = {
            id: 'optimization-strategy-parameter-dropdown',
            title: this.translate('Agito.Hilti.C2C.OptimizationStrategy.OptimizationStrategy'),
        };
    }

    private populateDropdownItems() {
        const strutTies = this.codeList.projectCodeListsC2C[ProjectCodeList.OptimizationStrategyC2C] as OptimizationStrategy[];

        this.optimizationStrategyItems = strutTies.map(item => ({
            value: item.id,
            text: this.translate(`Agito.Hilti.C2C.CodeList.OptimizationStrategyEntityC2C.${item.displayKey}`)
        }) as DropdownItem<number>);
    }

    public optimizationStrategyChanged(value: number) {
        if (this.useAusOptions)
            this.data.optimizationStrategyOptionsAs.optimizationStrategy = value;
        else
            this.data.optimizationStrategy = value;
    }
}
