import { SortablejsModule } from 'ngx-sortablejs';

import { APP_BASE_HREF } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NgbDropdownModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import {
    AddEditDesignFooterComponent
} from './components/add-edit-design-footer/add-edit-design-footer.component';
import { AddEditDesignComponent } from './components/add-edit-design/add-edit-design.component';
import {
    AdvancedRebarSettingsInputsComponent
} from './components/advanced-rebar-settings-inputs/advanced-rebar-settings-inputs.component';
import {
    AppSettingsC2cFixingComponent
} from './components/app-settings-c2c-fixing/app-settings-c2c-fixing.component';
import {
    ConcreteMaterialInputsComponent
} from './components/concrete-material-inputs/concrete-material-inputs.component';
import {
    ConcreteMaterialSettingsInputsComponent
} from './components/concrete-material-settings-inputs/concrete-material-settings-inputs.component';
import { DesignMethodComponent } from './components/design-method/design-method.component';
import { DesignSectionComponent } from './components/design-section/design-section.component';
import {
    ExportReportRowComponent
} from './components/export-report-row/export-report-row.component';
import { ExportReportComponent } from './components/export-report/export-report.component';
import { GlModelComponent } from './components/gl-model/gl-model.component';
import {
    GridImageSelectComponent
} from './components/grid-image-select/grid-image-select.component';
import { ImportLoadsComponent } from './components/import-loads/import-loads.component';
import { DesignConsiderationComponent } from './components/design-consideration/design-consideration.component';
import {
    InfoDialogTableComponent
} from './components/info-dialog-table/info-dialog-table.component';
import {
    InfoDialogUnorderedListComponent
} from './components/info-dialog-unordered-list/info-dialog-unordered-list.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { InfoSectionComponent } from './components/info-section/info-section.component';
import {
    InterfaceShearCalculationParametersComponent
} from './components/interface-shear-calculation-parameters/interface-shear-calculation-parameters.component';
import {
    LShapeLimitationModalComponent
} from './components/l-shape-limitation-modal/l-shape-limitation-modal.component';
import {
    LayerUtilizationsComponent
} from './components/layer-utilizations/layer-utilizations.component';
import { LoadsRowComponent } from './components/loads-row/loads-row.component';
import { LoadsComponent } from './components/loads/loads.component';
import { MainComponent } from './components/main/main.component';
import {
    MathJaxParagraphComponent
} from './components/math-jax-paragraph/math-jax-paragraph.compotent';
import {
    MultipleApprovalsComponent
} from './components/multiple-approvals/multiple-approvals.component';
import {
    OptimizationStrategyInputsComponent
} from './components/optimization-strategy-inputs/optimization-strategy-inputs.component';
import {
    RebarCalculationParametersInputsComponent
} from './components/rebar-calculation-parameters-inputs/rebar-calculation-parameters-inputs.component';
import { RebarLoadsComponent } from './components/rebar-loads/rebar-loads.component';
import {
    RecommendDifferentProductComponent
} from './components/recommend-different-product/recommend-different-product.component';
import {
    ReductionFactorsInputsComponent
} from './components/reduction-factors-inputs/reduction-factors-inputs.component';
import {
    SafetyFactorsInputsComponent
} from './components/safety-factors-inputs/safety-factors-inputs.component';
import {
    SelectConnectionApplicationComponent
} from './components/select-connection-application/select-connection-application.component';
import { SelectRebarComponent } from './components/select-rebar/select-rebar.component';
import {
    SteelMaterialInputsComponent
} from './components/steel-material-inputs/steel-material-inputs.component';
import {
    StrutTiesModelCalculationComponent
} from './components/strut-ties-model-calculation/strut-ties-model-calculation.component';
import {
    SustainabilityPanelComponent
} from './components/sustainability-panel/sustainability-panel.component';
import { UiC2CInitComponent } from './components/ui-c2c-init/ui-c2c-init.component';
import { UtilizationItemComponent } from './components/utilization-item/utilization-item.component';
import {
    UtilizationsPanelComponent
} from './components/utilization-panel/utilization-panel.component';
import { UtilizationTabComponent } from './components/utilization-tab/utilization-tab.component';
import {
    UtilizationsCollapseComponent
} from './components/utilizations-collapse/utilizations-collapse.component';
import { ZoneAnalysisComponent } from './components/zone-analysis/zone-analysis.component';
import {
    ZoneUtilizationsComponent
} from './components/zone-utilizations/zone-utilizations.component';
import {
    CloseOutsideNgbDropdownDirective
} from './directives/close-outside-ngb-dropdown.directive';
import { L10nDirective } from './directives/l10n.directive';
import { L10nPipe } from './pipes/l10n.pipe';

@NgModule({
    declarations: [
        AddEditDesignComponent,
        AddEditDesignFooterComponent,
        AdvancedRebarSettingsInputsComponent,
        ConcreteMaterialSettingsInputsComponent,
        AppSettingsC2cFixingComponent,
        ConcreteMaterialInputsComponent,
        DesignMethodComponent,
        DesignSectionComponent,
        ExportReportComponent,
        ExportReportRowComponent,
        GlModelComponent,
        GridImageSelectComponent,
        ImportLoadsComponent,
        DesignConsiderationComponent,
        InfoDialogComponent,
        InfoDialogTableComponent,
        InfoDialogUnorderedListComponent,
        InfoSectionComponent,
        InterfaceShearCalculationParametersComponent,
        LayerUtilizationsComponent,
        LoadsComponent,
        LoadsRowComponent,
        LShapeLimitationModalComponent,
        MainComponent,
        MultipleApprovalsComponent,
        OptimizationStrategyInputsComponent,
        RebarCalculationParametersInputsComponent,
        RebarLoadsComponent,
        RecommendDifferentProductComponent,
        ReductionFactorsInputsComponent,
        SafetyFactorsInputsComponent,
        SelectConnectionApplicationComponent,
        SelectRebarComponent,
        SteelMaterialInputsComponent,
        StrutTiesModelCalculationComponent,
        UtilizationItemComponent,
        UtilizationsCollapseComponent,
        UtilizationsPanelComponent,
        UtilizationTabComponent,
        ZoneAnalysisComponent,
        ZoneUtilizationsComponent,
        SustainabilityPanelComponent,
        CloseOutsideNgbDropdownDirective,
        L10nDirective,
        L10nPipe,
        MathJaxParagraphComponent
  ],
    imports: [
        BrowserModule,
        NgbTooltipModule,
        NgbDropdownModule,
        FormsModule,
        VirtualScrollerModule,
        SortablejsModule.forRoot({})
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: '/cdn/pe-ui-c2c/' }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private injector: Injector,
        ngbTooltipConfig: NgbTooltipConfig
    ) {
        this.configureTooltip(ngbTooltipConfig);

        this.registerCustomElement('ui-c2c-init', UiC2CInitComponent);
        this.registerCustomElement('add-edit-design', AddEditDesignComponent);
        this.registerCustomElement('add-edit-design-footer', AddEditDesignFooterComponent);
        this.registerCustomElement('advanced-rebar-settings-inputs', AdvancedRebarSettingsInputsComponent);
        this.registerCustomElement('concrete-material-settings-inputs', ConcreteMaterialSettingsInputsComponent);
        this.registerCustomElement('app-settings-c2c-fixing', AppSettingsC2cFixingComponent);
        this.registerCustomElement('concrete-material-inputs', ConcreteMaterialInputsComponent);
        this.registerCustomElement('design-method', DesignMethodComponent);
        this.registerCustomElement('design-section', DesignSectionComponent);
        this.registerCustomElement('export-report', ExportReportComponent);
        this.registerCustomElement('export-report-row', ExportReportRowComponent);
        this.registerCustomElement('gl-model', GlModelComponent);
        this.registerCustomElement('grid-image-select', GridImageSelectComponent);
        this.registerCustomElement('import-loads', ImportLoadsComponent);
        this.registerCustomElement('design-consideration', DesignConsiderationComponent);
        this.registerCustomElement('info-dialog', InfoDialogComponent);
        this.registerCustomElement('info-dialog-table', InfoDialogTableComponent);
        this.registerCustomElement('info-dialog-unordered-list', InfoDialogUnorderedListComponent);
        this.registerCustomElement('info-section', InfoSectionComponent);
        this.registerCustomElement('interface-shear-calculation-parameters', InterfaceShearCalculationParametersComponent);
        this.registerCustomElement('l-shape-limitation-modal', LShapeLimitationModalComponent);
        this.registerCustomElement('layer-utilizations', LayerUtilizationsComponent);
        this.registerCustomElement('loads', LoadsComponent);
        this.registerCustomElement('loads-row', LoadsRowComponent);
        this.registerCustomElement('main', MainComponent);
        this.registerCustomElement('multiple-approvals', MultipleApprovalsComponent);
        this.registerCustomElement('optimization-strategy-inputs', OptimizationStrategyInputsComponent);
        this.registerCustomElement('rebar-calculation-parameters-inputs', RebarCalculationParametersInputsComponent);
        this.registerCustomElement('rebar-loads', RebarLoadsComponent);
        this.registerCustomElement('recommend-different-product', RecommendDifferentProductComponent);
        this.registerCustomElement('reduction-factors-inputs', ReductionFactorsInputsComponent);
        this.registerCustomElement('safety-factors-inputs', SafetyFactorsInputsComponent);
        this.registerCustomElement('select-connection-application', SelectConnectionApplicationComponent);
        this.registerCustomElement('select-rebar', SelectRebarComponent);
        this.registerCustomElement('steel-material-inputs', SteelMaterialInputsComponent);
        this.registerCustomElement('strut-ties-model-calculation', StrutTiesModelCalculationComponent);
        this.registerCustomElement('utilization-item', UtilizationItemComponent);
        this.registerCustomElement('utilization-panel', UtilizationsPanelComponent);
        this.registerCustomElement('utilization-tab', UtilizationTabComponent);
        this.registerCustomElement('utilizations-collapse', UtilizationsCollapseComponent);
        this.registerCustomElement('zone-analysis', ZoneAnalysisComponent);
        this.registerCustomElement('zone-utilizations', ZoneUtilizationsComponent);
        this.registerCustomElement('sustainability-panel', SustainabilityPanelComponent);
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @angular-eslint/use-lifecycle-interface, @typescript-eslint/no-empty-function
    public ngDoBootstrap() {
        // Nothing to do.
    }

    private configureTooltip(config: NgbTooltipConfig) {
        config.container = 'body';
        config.triggers = 'hover';
        config.openDelay = 500;
    }

    private registerCustomElement(componentName: string, component: Type<any>) {
        const customElement = createCustomElement(component, { injector: this.injector });
        customElements.define(`c2c-${componentName}`, customElement);
    }
}

