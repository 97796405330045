<div class="steel-material-inputs content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control-steelMaterial">
                <pe-numeric-text-box
                    [id]="steelEpsilonUd.id"
                    [title]="steelEpsilonUd.title"
                    [placeholder]="steelEpsilonUd.placeholder"
                    [value]="steelEpsilonUdValue"
                    (valueChange)="onSteelEpsilonChange($any($event).detail)"
                    [unit]="steelEpsilonUd.unit"
                    [minValue]="steelEpsilonUd.minValue"
                    [maxValue]="steelEpsilonUd.maxValue"
                    [disabled]="disabled"
                    [tooltip]="steelEpsilonUd.tooltip"
                    [precision]="steelEpsilonUd.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-steelMaterial">
                <pe-numeric-text-box
                    [id]="steelE.id"
                    [title]="steelE.title"
                    [placeholder]="steelE.placeholder"
                    [value]="steelEValue"
                    (valueChange)="onSteelEChange($any($event).detail)"
                    [unit]="steelE.unit"
                    [minValue]="steelE.minValue"
                    [maxValue]="steelE.maxValue"
                    [disabled]="disabled"
                    [tooltip]="steelE.tooltip"
                    [precision]="steelE.precision">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
