import {
    NewDesignDataEntityC2C
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { IDetailedDisplayDesign } from './display-design';
import { UserSettingsC2C as UserSettings } from './user-settings';

export interface IReductionFactorsInputsData {
    // Context
    design?: () => IDetailedDisplayDesign;
    settings?: () => UserSettings;
    connectionType?: number;

    // Values
    partialReductionFactorSteel?: number;
    partialReductionFactorConcrete?: number;
    partialReductionFactorFire?: number;
    alphaCC?: number;
    alphaCT?: number;
}

export function setReductionFactorsValuesToDesign(design: IDetailedDisplayDesign, data: IReductionFactorsInputsData) {
    design.partialReductionFactorSteel = data.partialReductionFactorSteel;
    design.partialReductionFactorConcrete = data.partialReductionFactorConcrete;
    design.partialReductionFactorFire = data.partialReductionFactorFire;
    design.alphaCcC2C = data.alphaCC;
    design.alphaCtC2C = data.alphaCT;
}

export function setReductionFactorsValuesToNewDesign(newDesignC2C: NewDesignDataEntityC2C, data: IReductionFactorsInputsData) {
    newDesignC2C.partialReductionFactorSteel = data.partialReductionFactorSteel;
    newDesignC2C.partialReductionFactorConcrete = data.partialReductionFactorConcrete;
    newDesignC2C.partialReductionFactorFire = data.partialReductionFactorFire;
    newDesignC2C.alphaCC = data.alphaCC;
    newDesignC2C.alphaCT = data.alphaCT;
}
