import {
    ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewEncapsulation
} from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    RadioButtonProps
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import {
    IAppSettingsComponent
} from '@profis-engineering/pe-ui-common/entities/app-settings-component';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    ApplicationState, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { format, formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    CommonCodeList as ProjectCodeList
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';

import { environment } from '../../../environments/environmentC2C';
import { IC2CFixing } from '../../../shared/entities/c2c-fixing';
import {
    DesignMethodGroup as DesignMethodGroupC2C
} from '../../../shared/entities/code-lists/design-method-group';
import {
    DesignStandard as DesignStandardC2C
} from '../../../shared/entities/code-lists/design-standard';
import { DesignType } from '../../../shared/entities/code-lists/design-type';
import { DesignType as DesignTypeEnum } from '../../../shared/entities/tracking-data';
import { ProjectCodeList as ProjectCodeListC2C } from '../../../shared/enums/project-code-list';
import {
    ConnectionType, DesignStandard as DesignStandardEnumC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    getAvailableConnectionTypesForRegionC2C, isOnlyOverlay
} from '../../../shared/helpers/connection-type-helper';
import {
    getUKDesignMethodKey, getUKDesignStandardKey
} from '../../../shared/helpers/design-standard-methods-helper';
import { AppSettingsHelperC2C } from '../../helpers/app-settings-helper';
import { CodeListService as CodeListServiceC2C } from '../../services/code-list.service';
import { CommonCodeListService as CodeListService } from '../../services/common-code-list.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    templateUrl: './app-settings-c2c-fixing.component.html',
    styleUrls: ['./app-settings-c2c-fixing.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AppSettingsC2cFixingComponent implements IAppSettingsComponent<IC2CFixing>, OnInit, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public decimalSeparatorId!: number;

    @Input()
    public thousandsSeparatorId!: number;

    @Input()
    public submitted = false;

    @Input()
    public settings: IC2CFixing = {
        regionC2C: KnownRegion.Unknown,
        designStandardIdC2C: undefined as unknown as number,
        designMethodGroupIdC2C: undefined as unknown as number,
        overlayDesignStandardIdC2C: undefined as unknown as number,
        overlayDesignMethodGroupIdC2C: undefined as unknown as number
    };

    public get c2cFixing() {
        return this.settings;
    }

    public regionDropdownC2C!: DropdownProps<number>;
    public lengthDropdownC2C!: DropdownProps<number>;
    public areaDropdownC2C!: DropdownProps<number>;
    public stressDropdownC2C!: DropdownProps<number>;
    public forceDropdownC2C!: DropdownProps<number>;
    public momentDropdownC2C!: DropdownProps<number>;
    public temperatureDropdownC2C!: DropdownProps<number>;
    public forcePerLengthDropdownC2C!: DropdownProps<number>;
    public momentPerLengthDropdownC2C!: DropdownProps<number>;
    public densityDropdownC2C!: DropdownProps<number>;
    public areaPerLengthDropdownC2C!: DropdownProps<number>;
    public designStandardDropdownC2C!: DropdownProps<number>;
    public designMethodDropdownC2C!: DropdownProps<number>;
    public overlayDesignStandardDropdownC2C!: DropdownProps<number>;
    public overlayDesignMethodDropdownC2C!: DropdownProps<number>;
    public applicationStateControl!: RadioButtonProps<ApplicationState>;
    public overlayUseHiltiRebarDesignCheckboxC2C!: CheckboxButtonProps<boolean>;

    private appSettingsHelper: AppSettingsHelperC2C;
    public dataLoaded = false;

    constructor(
        private codeList: CodeListService,
        private codeListC2C: CodeListServiceC2C,
        private userSettings: UserSettingsService,
        private localizationService: LocalizationService,
        private numberService: NumberService,
        public changeDetection: ChangeDetectorRef,
        private featureVisibilityService: FeaturesVisibilityService
    ) {
        this.appSettingsHelper = new AppSettingsHelperC2C(this.localizationService, this.userSettings, this.codeList, this.codeListC2C, this.numberService);
    }

    ngOnInit(): void {
        this.createControlsRegion();
    }

    ngOnChanges(): void {
        this.createControlsGeneral();
        this.createControlCalculation();
        this.updateQuickStartC2C(
            this.c2cFixing.regionC2C == SpecialRegion.Default
                ? this.selectedRegionId ?? 0
                : this.c2cFixing.regionC2C,
            false
        );
        this.dataLoaded = true;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public get allowC2C() {
        return this.appSettingsHelper.designTypeC2C?.allowedDesignStandardRegions?.some((row) => row.RegionId == this.selectedRegionId);
    }

    public get allowC2CQuickStart() {
        const nonDefaultValue = this.c2cFixing.regionC2C < 0
            ? this.selectedRegionId
            : this.c2cFixing.regionC2C;

        return this.appSettingsHelper.designTypeC2C?.allowedDesignStandardRegions?.some((row) => row.RegionId == nonDefaultValue);
    }

    public get allowC2CPirQuickStart() {
        const regionId = this.c2cFixing.regionC2C < 0
            ? this.selectedRegionId ?? 0
            : this.c2cFixing.regionC2C;

        return this.allowC2CQuickStart && (environment.c2cForceAvailableCountriesEnabled ?
            environment.c2cForceAvailableCountriesPir.includes(regionId) :
            true);
    }

    public get isC2CNone() {
        return this.c2cFixing.regionC2C != SpecialRegion.None;
    }

    public get isOverlayEnabled(): boolean {
        return this.connectionTypeC2C.includes(ConnectionType.ConcreteOverlay);
    }

    public get overlayOnlyC2C() {
        const regionId = this.c2cFixing.regionC2C > 0 ? this.c2cFixing.regionC2C : this.selectedRegionId ?? 0;
        return isOnlyOverlay(this.codeListC2C, regionId);
    }

    public get isExtensionsAndJointsDesignMethodVisible() {
        return this.c2cFixing.designStandardIdC2C != DesignStandardEnumC2C.ETAG;
    }

    public get allowC2CStatus() {
        let translation = '';

        if (this.allowC2CQuickStart) {
            translation = this.translate('Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.Status.Ok');
            translation = format(translation, this.translate('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.None'));
        }
        else {
            translation = this.translate('Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.Status.Error');
            const region = this.appSettingsHelper.getRegionById(this.selectedRegionId);
            translation = formatKeyValue(translation, { region: region?.getTranslatedNameText(getCodeListTextDeps(this.localizationService, this.numberService)) ?? '' });
        }

        return translation;
    }

    public get selectedC2CDesignStandardDescription(): string | undefined {
        if (!this.designStandardC2C) {
            return undefined;
        }

        return this.designStandardC2C.descriptionResourceKey ?? undefined;
    }

    public get designSettingsTitleC2C(): string {
        return 'Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.ExtensionsAndJointsDesign';
    }

    public get designStandardDescriptionC2C() {
        return this.getDesignStandardDescriptionC2C(this.c2cFixing.designStandardIdC2C);
    }

    public get overlayDesignStandardDescriptionC2C() {
        return this.getDesignStandardDescriptionC2C(this.c2cFixing.overlayDesignStandardIdC2C);
    }

    public get selectedC2CDesignMethodDescription(): string | undefined {
        const uktaDesignMethod = getUKDesignMethodKey(
            this.regionC2CId,
            this.designMethodC2C as DesignMethodGroupC2C,
            this.featureVisibilityService.isFeatureEnabled('C2C_UKTA')
        );
        if (!uktaDesignMethod?.displayKey) {
            return undefined;
        }

        return `Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.DesignMethod.${uktaDesignMethod.displayKey}`;
    }

    public get selectedC2COverlayDesignMethodDescription(): string | undefined {
        const uktaDesignMethod = getUKDesignMethodKey(
            this.regionC2CId,
            this.overlayDesignMethodC2C as DesignMethodGroupC2C,
            this.featureVisibilityService.isFeatureEnabled('C2C_UKTA')
        );
        if (!uktaDesignMethod?.displayKey) {
            return undefined;
        }

        return `Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.DesignMethod.${uktaDesignMethod.displayKey}`;
    }

    public get isC2CHna() {
        return this.c2cFixing.designStandardIdC2C == DesignStandardEnumC2C.ACI || this.c2cFixing.designStandardIdC2C == DesignStandardEnumC2C.CSA;
    }

    public get isC2CHnaOverlay() {
        return this.c2cFixing.overlayDesignStandardIdC2C == DesignStandardEnumC2C.ACI || this.c2cFixing.overlayDesignStandardIdC2C == DesignStandardEnumC2C.CSA;
    }

    private get connectionTypeC2C() {
        return getAvailableConnectionTypesForRegionC2C(this.codeListC2C, this.regionC2CId);
    }

    private get designStandardC2C(): DesignStandardC2C | undefined {
        return this.getDesignStandardC2CById(this.c2cFixing.designStandardIdC2C);
    }

    private get designMethodC2C(): DesignMethodGroupC2C | undefined {
        return this.getDesignMethodC2CById(this.c2cFixing.designMethodGroupIdC2C);
    }

    private get overlayDesignMethodC2C(): DesignMethodGroupC2C | undefined {
        return this.getDesignMethodC2CById(this.c2cFixing.overlayDesignMethodGroupIdC2C);
    }

    private get regionC2CId(): number {
        return this.c2cFixing.regionC2C < 0
            ? this.selectedRegionId ?? 0
            : this.c2cFixing.regionC2C;
    }

    public getDesignStandardDescriptionC2C(designStandardId: number): string {
        switch (designStandardId)  {
            case DesignStandardEnumC2C.ACI:
                return 'Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.DesignStandard.ACI';
            case DesignStandardEnumC2C.CSA:
                return 'Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.DesignStandard.CSA';
            case DesignStandardEnumC2C.ETAG:
                return 'Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.DesignStandard.ETAG';
            case DesignStandardEnumC2C.ASBased:
                return 'Agito.Hilti.Profis3.ApplicationSettings.ConcreteToConcrete.DesignStandard.ASBased';
            default:
                throw new Error('Unsupported design standard');
        }
    }

    public onRegionDropdownChangeC2C(regionId: number) {
        this.c2cFixing.regionC2C = regionId;
        this.updateQuickStartC2C(regionId, true);
        this.changeDetection.detectChanges();
    }

    public onDesignStandardChangeC2C(designStandardId: number) {
        this.c2cFixing.designStandardIdC2C = designStandardId;
        this.modifyDesignStandardDropdownC2C(this.designStandardDropdownC2C, this.c2cFixing.designStandardIdC2C, ConnectionType.Splices);
        this.modifyDesignMethodsC2C(this.designMethodDropdownC2C, this.c2cFixing.designStandardIdC2C, ConnectionType.Splices);
        this.setDefaultC2CDesignMethod(this.regionC2CId, this.c2cFixing.designStandardIdC2C);
        this.changeDetection.detectChanges();
        this.isEtagAndAciSelected(designStandardId);
    }

    public onDesignMethodChangeC2C(designMethodId: number) {
        this.c2cFixing.designMethodGroupIdC2C = designMethodId;
        this.modifyDesignMethodsC2C(this.designMethodDropdownC2C, this.c2cFixing.designStandardIdC2C, ConnectionType.Splices, this.c2cFixing.designMethodGroupIdC2C);
        this.changeDetection.detectChanges();
    }

    public onOverlayDesignStandardChangeC2C(designStandardId: number) {
        this.c2cFixing.overlayDesignStandardIdC2C = designStandardId;
        this.modifyDesignStandardDropdownC2C(this.overlayDesignStandardDropdownC2C, this.c2cFixing.overlayDesignStandardIdC2C, ConnectionType.ConcreteOverlay);
        this.modifyDesignMethodsC2C(this.overlayDesignMethodDropdownC2C, this.c2cFixing.overlayDesignStandardIdC2C, ConnectionType.ConcreteOverlay, this.c2cFixing.overlayDesignMethodGroupIdC2C);
        this.setDefaultC2CDesignMethod(this.regionC2CId, this.c2cFixing.overlayDesignStandardIdC2C, ConnectionType.ConcreteOverlay);
        this.changeDetection.detectChanges();
        this.isEtagAndAciSelected(designStandardId);
    }

    private isEtagAndAciSelected(designStandardId: number) {
        if (!((this.c2cFixing.overlayDesignStandardIdC2C == DesignStandardEnumC2C.ACI && this.c2cFixing.designStandardIdC2C == DesignStandardEnumC2C.ETAG) ||
        (this.c2cFixing.overlayDesignStandardIdC2C == DesignStandardEnumC2C.ETAG && this.c2cFixing.designStandardIdC2C == DesignStandardEnumC2C.ACI))) {
            return;
        }

        this.c2cFixing.designStandardIdC2C == designStandardId ? this.onOverlayDesignStandardChangeC2C(designStandardId) : this.onDesignStandardChangeC2C(designStandardId);
    }

    public onOverlayDesignMethodChangeC2C(designMethodId: number) {
        this.c2cFixing.overlayDesignMethodGroupIdC2C = designMethodId;
        this.modifyDesignMethodsC2C(this.overlayDesignMethodDropdownC2C, this.c2cFixing.overlayDesignStandardIdC2C, ConnectionType.ConcreteOverlay, this.c2cFixing.overlayDesignMethodGroupIdC2C);
        this.changeDetection.detectChanges();
    }

    public onOverlayUseHiltiRebarDesignC2CChangeC2C() {
        this.c2cFixing.overlayUseHiltiRebarDesignC2C = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.overlayUseHiltiRebarDesignCheckboxC2C) ?? false;
        this.changeDetection.detectChanges();
    }

    public getDesignMethodC2CById(designMethodId: number) {
        const designMethods = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignMethodGroupsC2C] as DesignMethodGroupC2C[];
        return designMethods.find((designMethod) => designMethod.id == designMethodId);
    }

    private getDesignStandardC2CById(designStandardId: number) {
        const designStandards = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignStandardsC2C] as DesignStandardC2C[];
        return designStandards.find((designStandard) => designStandard.id == designStandardId);
    }

    private createControlsRegion() {
        this.regionDropdownC2C = this.appSettingsHelper.createDropdownComponent(
            'c2c-region',
            'Concrete.Region',
            this.appSettingsHelper.getAvailableRegions(this.appSettingsHelper.designTypeC2C ?? new DesignType({ allowedDesignStandardRegions: [] }))
                .map(region => ({
                    value: region.id,
                    text: this.translate(region.nameResourceKey)
                }) as DropdownItem<number>)
        );
    }

    private createControlsGeneral() {
        this.forcePerLengthDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-force-per-length', 'ConcreteToConcrete.ForcePerLength', ProjectCodeList.UnitForcePerLength);
        this.momentPerLengthDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-moment-per-length', 'ConcreteToConcrete.MomentPerLength', ProjectCodeList.UnitMomentPerLength);
        this.lengthDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-length', 'ConcreteToConcrete.Length', ProjectCodeList.UnitLength);
        this.areaDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-area', 'ConcreteToConcrete.Area', ProjectCodeList.UnitArea);
        this.stressDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-stress', 'ConcreteToConcrete.Stress', ProjectCodeList.UnitStress);
        this.forceDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-force', 'ConcreteToConcrete.Force', ProjectCodeList.UnitForce);
        this.momentDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-moment', 'ConcreteToConcrete.Moment', ProjectCodeList.UnitMoment);
        this.temperatureDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-temperature', 'ConcreteToConcrete.Temperature', ProjectCodeList.UnitTemperature);
        this.densityDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-density-length', 'ConcreteToConcrete.Density', ProjectCodeList.UnitDensity);
        this.areaPerLengthDropdownC2C = this.appSettingsHelper.createUnitDropdown('c2c-area-per-length', 'ConcreteToConcrete.AreaPerLength', ProjectCodeList.UnitAreaPerLength);

        this.forcePerLengthDropdownC2C.selectedValue = this.c2cFixing.forcePerLengthC2C;
        this.momentPerLengthDropdownC2C.selectedValue = this.c2cFixing.momentPerLengthC2C;
        this.lengthDropdownC2C.selectedValue = this.c2cFixing.lengthC2C;
        this.areaDropdownC2C.selectedValue = this.c2cFixing.areaC2C;
        this.stressDropdownC2C.selectedValue = this.c2cFixing.stressC2C;
        this.forceDropdownC2C.selectedValue = this.c2cFixing.forceC2C;
        this.momentDropdownC2C.selectedValue = this.c2cFixing.momentC2C;
        this.temperatureDropdownC2C.selectedValue = this.c2cFixing.temperatureC2C;
        this.densityDropdownC2C.selectedValue = this.c2cFixing.densityC2C;
        this.areaPerLengthDropdownC2C.selectedValue = this.c2cFixing.areaPerLengthC2C;

        const isDisabled = this.userSettings.settings.application.concrete2Concrete.selectedConnectionTypeId.value == null;

        this.applicationStateControl = {
            title: this.translate('Agito.Hilti.Profis3.ApplicationSettings.DefaultApplication.Title'),
            items: [
                {
                    text: this.translate('Agito.Hilti.Profis3.ApplicationSettings.AlwaysAskMe'),
                    value: ApplicationState.AlwaysAskMe,
                    disabled: isDisabled,
                    id: 'application-settings-c2c-application-state-1-radio'
                },
                {
                    text: this.translate('Agito.Hilti.Profis3.ApplicationSettings.selectedConnection'),
                    value: ApplicationState.SelectedConnection,
                    disabled: isDisabled,
                    id: 'application-settings-c2c-application-state-2-radio'
                }
            ],
            selectedValue: this.userSettings.settings.quickStart.concrete2Concrete.applicationState.value || ApplicationState.AlwaysAskMe
        };
    }

    private createControlCalculation() {
        this.overlayUseHiltiRebarDesignCheckboxC2C = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            id: 'application-settings-c2c-overlay-use-hilti-rebar-design-checkbox',
            itemText: this.translate('Agito.Hilti.C2C.Design.UseHiltiRebarDesign'),
            checked: this.c2cFixing.overlayUseHiltiRebarDesignC2C
        });
        // C2C design standard
        this.designStandardDropdownC2C = this.appSettingsHelper.createDropdownComponent<number>(
            'c2c-design-standard-dropdown', 'ConcreteToConcrete.DesignStandard');
        this.modifyDesignStandardDropdownC2C(this.designStandardDropdownC2C, this.c2cFixing.designStandardIdC2C, ConnectionType.Splices);

        this.designMethodDropdownC2C = this.appSettingsHelper.createDropdownComponent<number>(
            'c2c-design-method-dropdown', 'Concrete2Concrete.DesignMethod');
        this.modifyDesignMethodsC2C(this.designMethodDropdownC2C, this.c2cFixing.designStandardIdC2C, ConnectionType.Splices, this.c2cFixing.designMethodGroupIdC2C);

        this.overlayDesignStandardDropdownC2C = this.appSettingsHelper.createDropdownComponent<number>(
            'c2c-overlay-design-standard-dropdown', 'ConcreteToConcrete.DesignStandard');
        this.modifyDesignStandardDropdownC2C(this.overlayDesignStandardDropdownC2C, this.c2cFixing.overlayDesignStandardIdC2C, ConnectionType.ConcreteOverlay);

        this.overlayDesignMethodDropdownC2C = this.appSettingsHelper.createDropdownComponent<number>(
            'c2c-overlay-design-method-dropdown', 'Concrete2Concrete.DesignMethod');
        this.modifyDesignMethodsC2C(this.overlayDesignMethodDropdownC2C, this.c2cFixing.overlayDesignStandardIdC2C, ConnectionType.ConcreteOverlay, this.c2cFixing.overlayDesignMethodGroupIdC2C);
    }

    private modifyDesignStandardDropdownC2C(dropdown: DropdownProps, selectedValue: number, connectionTypeId: number): void {
        const designStandardsC2C = this.appSettingsHelper.getDesignStandardsC2C(this.c2cFixing.regionC2C, this.selectedRegionId ?? 0, connectionTypeId);
        if (designStandardsC2C.length == 0) {
            return;
        }

        if (!designStandardsC2C.some(x => x.id == selectedValue)) {
            selectedValue = designStandardsC2C[0].id ?? 0;
            if (connectionTypeId == ConnectionType.ConcreteOverlay) {
                this.c2cFixing.overlayDesignStandardIdC2C = selectedValue;
            }
            else {
                this.c2cFixing.designStandardIdC2C = selectedValue;
            }
        }

        dropdown.items = designStandardsC2C
            .map(designStandard => ({
                value: designStandard.id,
                text: this.appSettingsHelper.getDesignStandardTitle(this.localizationService, DesignTypeEnum.Concrete2Concrete, getUKDesignStandardKey(this.regionC2CId, designStandard, this.featureVisibilityService.isFeatureEnabled('C2C_UKTA'))),
            }) as DropdownItem<number>);

        dropdown.selectedValue = selectedValue;
    }

    private modifyDesignMethodsC2C(dropdown: DropdownProps, designStandardId: number, connectionTypeId: number, selectedValue?: number): void {
        const designMethods = this.appSettingsHelper.getDesignMethodGroupsC2C(
            designStandardId,
            connectionTypeId,
            this.regionC2CId);

        if (designMethods.length == 0) {
            return;
        }

        if (!designMethods.some(designMethodGroup => designMethodGroup.id == selectedValue)) {
            selectedValue = designMethods[0].id ?? 0;
            if (connectionTypeId == ConnectionType.ConcreteOverlay) {
                this.c2cFixing.overlayDesignMethodGroupIdC2C = selectedValue;
            }
            else {
                this.c2cFixing.designMethodGroupIdC2C = selectedValue;
            }
        }

        dropdown.items = designMethods.map(designMethod => ({
            value: designMethod.id,
            text: this.appSettingsHelper.getDesignMethodGroupTitle(getUKDesignMethodKey(this.regionC2CId, designMethod, this.featureVisibilityService.isFeatureEnabled('C2C_UKTA'))),
        }) as DropdownItem<number>);

        dropdown.selectedValue = selectedValue;
    }

    private updateQuickStartC2C(regionId: number, regionChanged: boolean) {
        if (regionId != SpecialRegion.None || this.c2cFixing.lengthC2C == null) {
            if (regionChanged) {
                regionId = regionId < 0
                    ? this.selectedRegionId ?? KnownRegion.Unknown
                    : regionId;
                const region = this.appSettingsHelper.getRegionById(regionId) ?? this.appSettingsHelper.createDefaultRegion();

                this.appSettingsHelper.setDefaultC2CUnits(this.c2cFixing, region);
                this.setDefaultC2CDesignStandard(region.id ?? 0);
                this.setDefaultC2CDesignStandard(region.id ?? 0, ConnectionType.ConcreteOverlay);
                this.setDefaultC2CDesignMethod(region.id ?? 0, this.c2cFixing.designStandardIdC2C);
                this.setDefaultC2CDesignMethod(region.id ?? 0, this.c2cFixing.overlayDesignStandardIdC2C, ConnectionType.ConcreteOverlay);

            }
        }

        this.changeDetection.detectChanges();
    }

    private setDefaultC2CDesignStandard(regionId: number, connectionTypeId?: number) {
        this.appSettingsHelper.setDefaultC2CDesignStandard(this.c2cFixing, regionId, connectionTypeId ?? ConnectionType.Unknown);

        if (connectionTypeId == ConnectionType.ConcreteOverlay) {
            this.onOverlayDesignStandardChangeC2C(this.c2cFixing.overlayDesignStandardIdC2C);
        }
        else {
            this.onDesignStandardChangeC2C(this.c2cFixing.designStandardIdC2C);
        }
    }

    private setDefaultC2CDesignMethod(regionId: number, designStandardId: number, connectionTypeId?: number) {
        this.appSettingsHelper.setDefaultC2CDesignMethod(this.c2cFixing, regionId, designStandardId, connectionTypeId ?? ConnectionType.Unknown);

        if (connectionTypeId == ConnectionType.ConcreteOverlay) {
            this.onOverlayDesignMethodChangeC2C(this.c2cFixing.overlayDesignMethodGroupIdC2C);
        }
        else {
            this.onDesignMethodChangeC2C(this.c2cFixing.designMethodGroupIdC2C);
        }
    }
}
