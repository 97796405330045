import { Controls2dEditorBase, IMenu2dContext as IMenu2dContextBase } from '@profis-engineering/pe-ui-common/services/menu-2d.common';
import { IGlModelC2CComponent } from '../components/gl-model';

export const ROWS_PER_SCROLL_C2C = 7;

export class Controls2dEditor extends Controls2dEditorBase {
    public static readonly ExistingReinforcement = 'ExistingReinforcement.ExistingReinforcement';
    public static readonly PostInstalledRebar = 'PostInstalledRebar.PostInstalledRebar';
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMenu2dContext extends IMenu2dContextBase<IGlModelC2CComponent> {}

export interface IDiameterPosition {
    x: number;
    y: number;
    diameter: number;
    bond: number;
    shape: number;
}
