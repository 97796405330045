import clone from 'lodash-es/clone';

import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SafetyFactorRegion } from '../../../shared/entities/code-lists/safety-factor-region';
import { CodeListService } from '../../services/code-list.service';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { LocalizationService } from '../../services/localization.service';
import { IPropertyMetaDataC2C, PropertyMetaDataC2C } from '../../../shared/properties/properties';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ISafetyFactorsInputsData } from '../../../shared/entities/safety-factors-inputs-data';
import { UnitService } from '../../services/unit.service';
import {
    ConnectionType, DesignMethodGroup
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { getPropertyValueById } from '../../helpers/ui-property-helper';
import { SafetyFactorsAs } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';

@Component({
    templateUrl: './safety-factors-inputs.component.html',
    styleUrls: ['./safety-factors-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SafetyFactorsInputsComponent implements OnInit, DoCheck {
    @Input()
    public data!: ISafetyFactorsInputsData;

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    @Input()
    public designMethodGroupId?: number;

    public partialSafetyFactorSteel!: NumericTextBoxProps;
    public partialSafetyFactorConcrete!: NumericTextBoxProps;
    public gammaRd!: NumericTextBoxProps;
    public steelStress!: NumericTextBoxProps;
    public concreteStressCrossSection!: NumericTextBoxProps;
    public concreteStressShearInterface!: NumericTextBoxProps;
    public dataLoaded = false;

    private oldRegionId!: number;
    private oldDesignMethodGroupId: number | undefined;
    private oldData!: ISafetyFactorsInputsData;

    constructor(
        private localizationService: LocalizationService,
        private codeList: CodeListService,
        private unit: UnitService
    ) { }

    ngOnInit(): void {
        this.initSafetyFactorsControls();
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setDefaultValues();
            this.refreshControls();

            this.dataLoaded = true;
        }

        this.updateData();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public get useAusOptions() {
        return this.designMethodGroupId == DesignMethodGroup.AS3600ETA;
    }

    private updateData() {
        let update = false;

        if (this.oldRegionId != this.regionId) {
            this.refreshControls();

            this.oldRegionId = this.regionId;
        }

        if (this.oldDesignMethodGroupId != this.designMethodGroupId) {
            this.refreshControls();

            this.oldDesignMethodGroupId = this.designMethodGroupId;
        }

        if (this.oldData != null) {
            if (this.oldData.connectionType != this.data.connectionType) {
                update = true;
                this.refreshControls();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);
        }
    }

    private get defaultSafetyFactorSteel(): string {
        const safetyFactors = this.codeList.projectCodeListsC2C[ProjectCodeList.SafetyFactorRegionC2C] as SafetyFactorRegion[];
        return `${safetyFactors.find(sf => sf.regionId == this.regionId)?.defaultGammaS}`;
    }

    private get defaultSafetyFactorConcrete() {
        const safetyFactors = this.codeList.projectCodeListsC2C[ProjectCodeList.SafetyFactorRegionC2C] as SafetyFactorRegion[];
        return `${safetyFactors.find(sf => sf.regionId == this.regionId)?.defaultGammaC}`;
    }

    private get defaultGammaRd() {
        const gammaRd = this.codeList.getPropertyValue(PropertyMetaDataC2C.Option_C2C_GammaRd.id, this.regionId, this.data.connectionType ?? ConnectionType.Unknown);
        return `${gammaRd.defaultValue}`;
    }

    private get defaultSteelStress() {
        const steelStress = this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_SteelStressReduction.id);
        return `${steelStress?.defaultValue ?? 0}`;
    }

    private get defaultConcreteStressCrossSection() {
        const concreteStressCrossSection = this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReduction.id);
        return `${concreteStressCrossSection?.defaultValue ?? 0}`;
    }

    private get defaultConcreteStressShearInterface() {
        const concreteStressShearInterface = this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReductionVerification.id);
        return `${concreteStressShearInterface?.defaultValue ?? 0}`;
    }

    private getPropertyValueById(id: number) {
        return getPropertyValueById(id, this.codeList, this.regionId, undefined, this.data.connectionType, this.designMethodGroupId);
    }

    private updateControl(control: NumericTextBoxProps, propertyMetaData: IPropertyMetaDataC2C, defaultValue: string) {
        const propertyValue = this.codeList.getPropertyValue(propertyMetaData.id, this.regionId, this.data.connectionType ?? 0, undefined, this.designMethodGroupId ?? 0);

        if (propertyValue) {
            control.maxValue = propertyValue.maxValue;
            control.minValue = propertyValue.minValue;
        }

        control.placeholder = defaultValue;
    }

    private setDefaultValues() {
        if (this.data.design != null) {
            const design = this.data.design();

            this.data.partialSafetyFactorSteel = design.partialSafetyFactorSteel;
            this.data.partialSafetyFactorConcrete = design.partialSafetyFactorConcrete;
            this.data.gammaRd = design.gammaRd;

            this.data.safetyFactorsAs = {
                steelStress: design.designOptionsAs?.safetyFactors.steelStress,
                concreteStressCrossSection: design.designOptionsAs?.safetyFactors.concreteStressCrossSection,
                concreteStressShearInterface: design.designOptionsAs?.safetyFactors.concreteStressShearInterface
            } as SafetyFactorsAs;
        }
    }

    private refreshControls() {
        this.updateControl(this.partialSafetyFactorSteel, PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorSteel, this.defaultSafetyFactorSteel);
        this.updateControl(this.partialSafetyFactorConcrete, PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorConcrete, this.defaultSafetyFactorConcrete);
        this.updateControl(this.gammaRd, PropertyMetaDataC2C.Option_C2C_GammaRd, this.defaultGammaRd);
        this.updateControl(this.steelStress, PropertyMetaDataC2C.Options_C2C_AS_SteelStressReduction, this.defaultSteelStress);
        this.updateControl(this.concreteStressCrossSection, PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReduction, this.defaultConcreteStressCrossSection);
        this.updateControl(this.concreteStressShearInterface, PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReductionVerification, this.defaultConcreteStressShearInterface);
    }

    private initSafetyFactorsControls() {
        this.partialSafetyFactorSteel = {
            id: 'safety-factors-partialSafetyFactorSteel',
            title: this.translate('Agito.Hilti.C2C.SafetyFactors.PartialSafetySteel'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.SafetyFactors.PartialSafetySteel'), content: this.translate('Agito.Hilti.C2C.SafetyFactors.PartialSafetySteel.Tooltip') },
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorSteel.id)
        };

        this.partialSafetyFactorConcrete = {
            id: 'safety-factors-partialSafetyFactorConcrete',
            title: this.translate('Agito.Hilti.C2C.SafetyFactors.PartialSafetyConcrete'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.SafetyFactors.PartialSafetyConcrete'), content: this.translate('Agito.Hilti.C2C.SafetyFactors.PartialSafetyConcrete.Tooltip') },
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorConcrete.id)
        };

        this.gammaRd = {
            id: 'safety-factors-gammaRd',
            title: this.translate('Agito.Hilti.C2C.SafetyFactors.GammaRd'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.SafetyFactors.GammaRd'), content: this.translate('Agito.Hilti.C2C.SafetyFactors.GammaRd.Tooltip') },
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_GammaRd.id)
        };

        this.steelStress = {
            id: 'safety-factors-steelStress',
            title: this.translate('Agito.Hilti.C2C.SafetyFactors.SteelStress'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.SafetyFactors.SteelStress'), content: this.translate('Agito.Hilti.C2C.SafetyFactors.SteelStress.Tooltip') },
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_GammaRd.id)
        };

        this.concreteStressCrossSection = {
            id: 'safety-factors-concreteStressCrossSection',
            title: this.translate('Agito.Hilti.C2C.SafetyFactors.ConcreteStressCrossSection'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.SafetyFactors.ConcreteStressCrossSection'), content: this.translate('Agito.Hilti.C2C.SafetyFactors.ConcreteStressCrossSection.Tooltip') },
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_GammaRd.id)
        };

        this.concreteStressShearInterface = {
            id: 'safety-factors-concreteStressShearInterface',
            title: this.translate('Agito.Hilti.C2C.SafetyFactors.ConcreteStressShearInterface'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.SafetyFactors.ConcreteStressShearInterface'), content: this.translate('Agito.Hilti.C2C.SafetyFactors.ConcreteStressShearInterface.Tooltip') },
            precision: this.unit.getPrecision(Unit.None, PropertyMetaDataC2C.Option_C2C_GammaRd.id)
        };
    }
}
