import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { getSpriteAsIconStyle } from '../../sprites';

export function createC2CMenu_NewStructure(): TabC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.NewStructure',
        Id: 22,
        Name: 'newstructure-tab',
        Image: 'tab-new-structure',
        IconImage: getSpriteAsIconStyle('sprite-tab-new-structure'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createC2CMenu_NewStructure_TopReinforcement(),
            createC2CMenu_NewStructure_BottomReinforcement(),
            createC2CMenu_NewStructure_AdditionalInformation(),
            createC2CMenu_NewStructure_ShearReinforcement()
        ]
    };
}

function createC2CMenu_NewStructure_TopReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewStructure.RegionTopReinforcement',
        Id: 40,
        Name: 'topreinforcement-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-numberoflayers',
                Size: 100,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.NumberOfLayers',
                UIPropertyId: 10219,
                UnitGroup: UnitGroup.None,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover1',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover1',
                UIPropertyId: 10220,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover2',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover2',
                UIPropertyId: 10221,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover3',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover3',
                UIPropertyId: 10222,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover4',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover4',
                UIPropertyId: 10223,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover5',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover5',
                UIPropertyId: 10224,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover6',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover6',
                UIPropertyId: 10225,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover7',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover7',
                UIPropertyId: 10226,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover8',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover8',
                UIPropertyId: 10227,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover9',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover9',
                UIPropertyId: 10228,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-concretecover10',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover10',
                UIPropertyId: 10229,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                CodelistName: 'BondCondition',
                ControlType: 'Dropdown',
                Name: 'topreinfcement-bondcondition-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.BondConditions',
                DisplayKey: 'Agito.Hilti.C2C.NewStructure.BondConditions',
                UIPropertyId: 10230,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'topreinforcement-minimumreinforcement',
                Size: 100,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.MinimumReinforcement',
                UIPropertyId: 10231,
                UnitGroup: UnitGroup.AreaPerLength,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            }
        ]
    };
}

function createC2CMenu_NewStructure_BottomReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewStructure.RegionBottomReinforcement',
        Id: 41,
        Name: 'bottomreinforcement-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-numberoflayers',
                Size: 100,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.NumberOfLayers',
                UIPropertyId: 10232,
                UnitGroup: UnitGroup.None,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover1',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover1',
                UIPropertyId: 10233,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover2',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover2',
                UIPropertyId: 10234,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover3',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover3',
                UIPropertyId: 10235,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover4',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover4',
                UIPropertyId: 10236,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover5',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover5',
                UIPropertyId: 10237,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover6',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover6',
                UIPropertyId: 10238,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover7',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover7',
                UIPropertyId: 10239,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover8',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover8',
                UIPropertyId: 10240,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover9',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover9',
                UIPropertyId: 10242,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-concretecover10',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.ConcreteCover10',
                UIPropertyId: 10243,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                CodelistName: 'BondCondition',
                ControlType: 'Dropdown',
                Name: 'bottomreinforcement-bondcondition-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.BondConditions',
                DisplayKey: 'Agito.Hilti.C2C.NewStructure.BondConditions',
                UIPropertyId: 10244,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'bottomreinforcement-minimumreinforcement',
                Size: 100,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.MinimumReinforcement',
                UIPropertyId: 10245,
                UnitGroup: UnitGroup.AreaPerLength,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            }
        ]
    };
}

function createC2CMenu_NewStructure_AdditionalInformation(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewStructure.AdditionalInformation',
        Id: 43,
        Name: 'additionalinformation-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'additionalinformation-coveroftension',
                Size: 100,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.CoverOfTension',
                UIPropertyId: 10246,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'additionalinformation-transversepressure',
                Size: 100,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.TransversePressure',
                UIPropertyId: 10247,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            }
        ]
    };
}

function createC2CMenu_NewStructure_ShearReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewStructure.ShearReinforcement',
        Id: 43,
        Name: 'shearreinforcement-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                Name: 'shearreinforcement-shearreinforcement',
                DisplayKey: 'Agito.Hilti.C2C.NewStructure.ShearReinforcement',
                UIPropertyId: 10248,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'shearreinforcement-inclinationcompression',
                Size: 100,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.InclinationCompression',
                UIPropertyId: 10249,
                UnitGroup: UnitGroup.Angle,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'shearreinforcement-inclinationshear',
                Size: 100,
                TitleDisplayKey: 'Agito.Hilti.C2C.NewStructure.InclinationShear',
                UIPropertyId: 10264,
                UnitGroup: UnitGroup.Angle,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            }
        ]
    };
}
