import { Injectable } from '@angular/core';
import {
    DateTimeServiceInjected
} from '@profis-engineering/pe-ui-common/services/date-time.common';

@Injectable({
    providedIn: 'root'
})
export class DateTimeService extends DateTimeServiceInjected {
}
