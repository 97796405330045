import clone from 'lodash-es/clone';

import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import {
    RebarSafetyFactorsRegion
} from '../../../shared/entities/code-lists/rebar-safety-factory-region';
import { ISteelMaterialInputsData } from '../../../shared/entities/steel-material-inputs-data';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    DesignMethodGroup
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IPropertyMetaDataC2C, PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { getPropertyValueById } from '../../helpers/ui-property-helper';
import { SteelMaterialOptionsAs } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';

@Component({
    templateUrl: './steel-material-inputs.component.html',
    styleUrls: ['./steel-material-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SteelMaterialInputsComponent implements OnInit, DoCheck {
    @Input()
    public data!: ISteelMaterialInputsData;

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    @Input()
    public designMethodGroupId?: number;

    public steelEpsilonUd!: NumericTextBoxProps;
    public steelE!: NumericTextBoxProps;
    public dataLoaded = false;

    private oldRegionId!: number;
    private oldDesignMethodGroupId: number | undefined;
    private oldData!: ISteelMaterialInputsData;

    constructor(
        private localizationService: LocalizationService,
        private codeList: CodeListService,
        private unit: UnitService
    ) { }

    ngOnInit(): void {
        this.initSteelMaterialControls();
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setDefaultValues();
            this.refreshControls();

            this.dataLoaded = true;
        }

        this.updateData();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private updateData() {
        let update = false;

        if (this.oldRegionId != this.regionId) {
            this.refreshControls();

            this.oldRegionId = this.regionId;
        }

        if (this.oldDesignMethodGroupId != this.designMethodGroupId) {
            this.refreshControls();
            this.refreshTooltips();

            this.oldDesignMethodGroupId = this.designMethodGroupId;
        }

        if (this.oldData != null) {

            if (this.oldData.connectionType != this.data.connectionType) {
                update = true;
                this.refreshControls();
            }

            if (this.oldData.unit != this.data.unit) {
                update = true;
                this.refreshControls();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);
        }
    }

    public get steelEValue() {
        return this.useAusOptions ? this.data.steelMaterialOptionsAs.e : this.data.steelE;
    }

    public get steelEpsilonUdValue() {
        return this.useAusOptions ? this.data.steelMaterialOptionsAs.epsilonUd : this.data.steelEpsilonUd;
    }

    private get steelEPropertyMetaData() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_SteelE : PropertyMetaDataC2C.Option_C2C_E;
    }

    private get epsilonUdPropertyMetaData() {
        return this.useAusOptions ? PropertyMetaDataC2C.Options_C2C_AS_EpsilonUd : PropertyMetaDataC2C.Option_C2C_EpsilonUD;
    }

    private get useAusOptions() {
        return this.designMethodGroupId == DesignMethodGroup.AS3600ETA;
    }

    private get defaultEpsilonUd() {
        if (this.useAusOptions)
            return this.data.steelMaterialOptionsAs.epsilonUd ?? (this.getPropertyValueById(this.epsilonUdPropertyMetaData.id).defaultValue ?? 0);

        const safetyFactors = this.codeList.projectCodeListsC2C[ProjectCodeList.RebarSafetyFactorsRegionC2C] as RebarSafetyFactorsRegion[];
        return this.data.steelEpsilonUd ?? safetyFactors.find(sf => sf.regionId == this.regionId)?.defaultEpsilonUd;
    }

    private get defaultE() {
        const defaultValue = this.useAusOptions ? this.data.steelMaterialOptionsAs.e : this.data.steelE;
        return defaultValue ?? this.getPropertyValueById(this.steelEPropertyMetaData.id).defaultValue ?? 0;
    }

    public onSteelEpsilonChange(value: number) {
        if (this.useAusOptions)
            this.data.steelMaterialOptionsAs.epsilonUd = value;
        else
            this.data.steelEpsilonUd = value;
    }

    public onSteelEChange(value: number) {
        if (this.useAusOptions)
            this.data.steelMaterialOptionsAs.e = value;
        else
            this.data.steelE = value;
    }

    private updateControl(control: NumericTextBoxProps, propertyMetaData: IPropertyMetaDataC2C, defaultValue: number, unit: Unit) {
        const propertyValue = this.codeList.getPropertyValue(propertyMetaData.id, this.regionId, this.data.connectionType ?? 0, undefined, this.designMethodGroupId ?? 0);
        const defaultPrecision = this.unit.getPrecision(unit, propertyMetaData.id);

        if (propertyValue) {
            control.maxValue = propertyValue.maxValue;
            control.minValue = propertyValue.minValue;
        }

        control.placeholder = this.getTextBoxPlaceholder(defaultValue, unit, defaultPrecision);
        control.unit = unit;
        control.precision = defaultPrecision;
    }

    private getPropertyValueById(id: number) {
        return getPropertyValueById(id, this.codeList, this.regionId, undefined, this.data.connectionType, this.designMethodGroupId);
    }

    private setDefaultValues() {
        if (this.data.design != null) {
            const design = this.data.design();
            this.data.connectionType = design.connectionType;

            this.data.steelE = design.E;
            this.data.steelEpsilonUd = design.epsilonUd;

            this.data.steelMaterialOptionsAs = {
                e: design.designOptionsAs?.steelMaterialOptions.e,
                epsilonUd: design.designOptionsAs?.steelMaterialOptions.epsilonUd
            } as SteelMaterialOptionsAs;
        }
    }

    private getTextBoxPlaceholder(value: number, unit: Unit, defaultPrecision: number) {
        const defaultUnit = unit == Unit.None ? Unit.None : Unit.Nmm2;

        const result = this.unit.formatUnitValueArgs(
            this.unit.convertUnitValueArgsToUnit(
                value,
                defaultUnit,
                unit
            ),
            unit,
            defaultPrecision
        );

        if (result == null) {
            return this.translate('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }

        return result;
    }

    private refreshControls() {
        this.updateControl(this.steelE, this.steelEPropertyMetaData, this.defaultE ?? 0, this.data.unit ?? 0);
        this.updateControl(this.steelEpsilonUd, this.epsilonUdPropertyMetaData, this.defaultEpsilonUd ?? 0, Unit.None);
    }

    private refreshTooltips() {
        this.steelE.tooltip = this.steelETooltip;
        this.steelEpsilonUd.tooltip = this.steelEpsilonUdTooltip;
    }

    private initSteelMaterialControls() {
        this.steelE = {
            id: 'steel-material-E',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.SteelMaterial.E'),
            tooltip: this.steelETooltip
        };

        this.steelEpsilonUd = {
            id: 'steel-material-epsilonUD',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.SteelMaterial.EpsilonUd'),
            tooltip: this.steelEpsilonUdTooltip
        };
    }

    private get steelETooltip() {
        return { title: this.translate('Agito.Hilti.C2C.ApplicationSettings.SteelMaterial.E'), content: this.translate('Agito.Hilti.C2C.ApplicationSettings.SteelMaterial.E.Tooltip') };
    }

    private get steelEpsilonUdTooltip() {
        return { title: this.translate('Agito.Hilti.C2C.ApplicationSettings.SteelMaterial.EpsilonUd'), content: this.translate('Agito.Hilti.C2C.ApplicationSettings.SteelMaterial.EpsilonUd.Tooltip') };
    }
}
