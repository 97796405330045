import {
    ReportType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';

import {
    ReportType as ReportTypeC2C
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';

export function covertCommonReportTypeToC2C(reportType: ReportType) {
    switch (reportType) {
        case ReportType.Detailed:
        case ReportType.Long:
            return ReportTypeC2C.Detailed;
        case ReportType.Short:
            return ReportTypeC2C.Short;
        default:
            return ReportTypeC2C.Unknown;
    }
}