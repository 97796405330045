<div class="utilization" [class.invalid-value]="barInvalid">
    <div class="utilization-button" [id]="controlId" [ngbTooltip]="tooltip">
        <div class="button-content">

            <!-- Percentage -->
            <div class="text-progress-container">
                <p class="text" [id]="getComponentId('title')">{{ title }}</p>
                <div class="progress-percent-container" *ngIf="showPercentBar">
                    <div class="progress-percent" [class.invalid-value]="barInvalid" [ngStyle]="{ 'width': percentValue + '%' }"></div>
                </div>
            </div>

            <!-- Numeric utilization -->
            <div class="number-container" *ngIf="showNumberContainer">
                <div class="worst-result details-line" [class.invalid-value]="dataInvalid" >
                    <span *ngIf="hasImage" class="pe-ui-c2c-sprite" [class]="spriteImage" [id]="getComponentId('image')"></span>
                    <p class="text" [innerHTML]="header" [id]="getComponentId('header')"></p>
                    <p class="number" *ngIf="hasValue" [id]="getComponentId('percentage')">{{ value }}<span *ngIf="sufix" [innerHTML]="sufix"></span></p>
                </div>
            </div>
        </div>
    </div>
</div>
