<div class="utilizations box-section box-section-white" *ngIf="showUtilizations">
    <c2c-utilization-tab
        [title]="utilizationTitle"
        icon="sprite-rebar-icon"
        *ngIf="hasUtilizations"
        [dataInvalid]="utilizationInvalid"
        [utilizationValue]="utilizationValue"
        [utilizationPercent]="progressPercent(utilizationValue)"
        [controlId]="id"
        [showPercentage]="true"
        [collapsed]="collapsed"
        [collapsingControl]="collapsingControl"
        [infoPopup]="infoBoxPopup"
        (collapseChange)="onCollapsedChanged($any($event).detail)">
    </c2c-utilization-tab>

    <div class="items box-section-content" *ngIf="hasUtilizations && !collapsed">
        <ng-container *ngFor="let layerData of layersData">
            <div [id]="layerData.id">
                <!-- Utilization value and percentage -->
                <c2c-utilization-item
                    *ngIf="layerData != null && layerData.showMainUtilization"
                    [title]="layerData.subtitle"
                    [showPercentBar]="true"
                    [percentValue]="progressPercent(layerData.value)"
                    [dataInvalid]="layerData.valueError"
                    [barInvalid]="layerData.valueError"
                    [value]="getPercentageValue(layerData.value)">
                </c2c-utilization-item>

                <!-- Detailed layer result utilization items -->
                <ng-container *ngIf="layerData.detailedResults">
                    <c2c-utilization-item
                        *ngFor="let detailedResult of layerData.detailedResults"
                        [header]="detailedResult.title"
                        [value]="unit.formatInternalValueAsDefault(detailedResult.value, detailedResult.unitGroup)">
                    </c2c-utilization-item>
                </ng-container>

                <!-- Drilling length -->
                <c2c-utilization-item
                    *ngIf="layerData.drillingLength != null"
                    [header]="translate('DrillingLength')"
                    [value]="unit.formatInternalValueAsDefault(layerData.drillingLength, unitGroupEnum.Length)">
                </c2c-utilization-item>

                <!-- Drilling length, if layer is spliceExtension -->
                <c2c-utilization-item
                    *ngIf="showDrillingLengthSpliceExt(layerData)"
                    [value]="unit.formatInternalValueAsDefault(layerData.drillingLength, unitGroupEnum.Length)"
                    [header]="drillingLengthSpliceExtTitle">
                </c2c-utilization-item>

                <!-- Anchorage Length in Tension and in Compression -->
                <ng-template [ngIf]="showCompressionTensionUtilizations">
                    <c2c-utilization-item
                        *ngIf="layerData.anchorageLengthTension"
                        [header]="translate(checkYieldText('AnchorageLengthTension'))"
                        [value]="unit.formatInternalValueAsDefault(layerData.anchorageLengthTension, unitGroupEnum.Length)">
                    </c2c-utilization-item>

                    <c2c-utilization-item
                        *ngIf="layerData.anchorageLengthCompression"
                        [header]="translate(checkYieldText('AnchorageLengthCompression'))"
                        [value]="unit.formatInternalValueAsDefault(layerData.anchorageLengthCompression, unitGroupEnum.Length)">
                    </c2c-utilization-item>
                </ng-template>

                <!-- Lap length, if layer is spliceExtension -->
                <c2c-utilization-item
                    *ngIf="showLapLengthSpliceExt(layerData)"
                    [header]="translate('LapLength')"
                    [value]="unit.formatInternalValueAsDefault(layerData.lapLength, unitGroupEnum.Length)">
                </c2c-utilization-item>

                <!-- Collapsable group of utilizations -->
                <div *ngIf="showCollapsingUtilizations(layerData)">

                    <!-- Lap length CIP -->
                    <c2c-utilization-item
                        [value]="unit.formatInternalValueAsDefault(layerData.lapLengthCIP, unitGroupEnum.Length)"
                        [header]="translate('LapLengthCIP')">
                    </c2c-utilization-item>

                    <!-- Lap length PI -->
                    <c2c-utilization-item
                        [value]="unit.formatInternalValueAsDefault(layerData.lapLengthPI, unitGroupEnum.Length)"
                        [header]="translate('LapLengthPI')">
                    </c2c-utilization-item>

                </div>

                <c2c-utilizations-collapse
                    *ngIf="collapsingUtilizationsAvailable(layerData)"
                    [controlId]="layerData.id"
                    [state]="collapseState"
                    (collapseToggled)="collapseToggled($any($event).detail)">
                </c2c-utilizations-collapse>
            </div>
        </ng-container>
</div>
