import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { TabType } from '../../../shared/enums/tab-type';

import { CollapsingControls } from '../../../shared/enums/collapsing-controls';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { includeSprites, Sprite } from '../../sprites';

@Component({
  templateUrl: './utilization-tab.component.html',
  styleUrls: ['./utilization-tab.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class UtilizationTabComponent implements OnInit, OnChanges {

  @Input()
  public controlId = '';

  @Input()
  public title = '';

  @Input()
  public dataInvalid = false;

  @Input()
  public utilizationValue = 0;

  @Input()
  public utilizationPercent = 0;

  @Input()
  public collapsingControl: CollapsingControls | undefined;

  @Input()
  public icon = '';

  @Input()
  public showPercentage = false;

  @Input()
  public tooltipText = 'Agito.Hilti.Profis3.Main.Region.DragToMove';

  @Input()
  public collapsed = false;

  @Input()
  public showNewLabel = false;

  @Input()
  public type: TabType = TabType.Primary;

  @Input()
  public infoPopup?: () => void;

  @Output()
  public collapseChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly spriteIconPrefix = 'pe-ui-c2c-';
  private initializedSpriteIcons: string[] = [];

  public get utilizationValueString(): string {
    return this.unit.formatInternalValueAsDefault(this.utilizationValue, UnitGroup.Percentage);
  }

  public get imageId(): string {
    return `${this.controlId}-image`;
  }

  public get headerId(): string {
    return `${this.controlId}-header`;
  }

  public get percentageId(): string {
    return `${this.controlId}-percentage`;
  }

  public get typeClass(): string {
    return this.type == TabType.Secondary ? 'secondary-type' : '';
  }

  public get showTooltip(): boolean {
    return this.tooltipText != '';
  }

  public get hasIcon(): boolean {
    return this.icon != '' && this.icon != null;
  }

  public get spriteIcon() {
    if (!this.hasIcon) {
      return '';
    }

    return this.spriteIconPrefix + this.icon;
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private unit: UnitService,
    public localizationService: LocalizationService
  ) { }

  ngOnInit(): void {
    this.collapsingControl = {} as CollapsingControls;
  }

  ngOnChanges(): void {
    if (this.hasIcon && !this.initializedSpriteIcons.includes(this.icon)) {
      includeSprites(this.elementRef.nativeElement.shadowRoot,
        this.icon as Sprite,
        'sprite-info-tooltip');
      this.initializedSpriteIcons.push(this.icon);
    }
  }

  public onCollapsedChanged(collapsed: boolean) {
    this.collapsed = collapsed;
    this.collapseChange.emit(collapsed);
  }

  public getTooltipText() {
    return this.localizationService.getString(this.tooltipText);
  }

  public getComponentId(type: string) {
    return `${this.controlId}-${type}`;
  }
}
