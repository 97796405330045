<div class="strut-ties-model-calculation content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control-strutTiesModelCalculation">
                <pe-dropdown
                [id]="strutTies.id"
                [title]="strutTies.title"
                [items]="strutTiesItems"
                [selectedValue]="data.strutTies"
                (selectedValueChange)="strutTiesChanged($any($event).detail)">
                </pe-dropdown>
            </div>

            <div class="control-strutTiesModelCalculation" *ngIf="data.strutTies == 2">
                <pe-numeric-text-box
                    [id]="strutTiesInput.id"
                    [title]="strutTiesInput.title"
                    [placeholder]="strutTiesInput.placeholder"
                    [value]="data.strutTiesInput"
                    (valueChange)="data.strutTiesInput = $any($event).detail"
                    [unit]="strutTiesInput.unit"
                    [minValue]="strutTiesInput.minValue"
                    [maxValue]="strutTiesInput.maxValue">
                </pe-numeric-text-box>
            </div>

            <div class="control-strutTiesModelCalculation">
                <pe-numeric-text-box
                    [id]="concreteResistanceCompression.id"
                    [title]="concreteResistanceCompression.title"
                    [placeholder]="concreteResistanceCompression.placeholder"
                    [value]="data.concreteResistanceCompression"
                    (valueChange)="data.concreteResistanceCompression = $any($event).detail"
                    [unit]="concreteResistanceCompression.unit"
                    [minValue]="concreteResistanceCompression.minValue"
                    [maxValue]="concreteResistanceCompression.maxValue"
                    [disabled]="disabled"
                    [tooltip]="concreteResistanceCompression.tooltip"
                    [precision]="concreteResistanceCompression.precision">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
