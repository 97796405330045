import cloneDeep from 'lodash-es/cloneDeep';

import { Injectable } from '@angular/core';
import { Design as DesignCommon, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import {
    DesignExternalMetaData as DesignExternalMetaDataCommon, DocumentAccessMode, DocumentServiceInjected,
    IDefferedData as IDefferedDataCommon, IDesignListItem as IDesignListItemCommon
} from '@profis-engineering/pe-ui-common/services/document.common';
import { DesignC2C } from '../../shared/entities/design-c2c';
import { detailedDisplayDesignToProjectDesignC2C, IDetailedDisplayDesign } from '../../shared/entities/display-design';
import {
    ProjectDesignEntityC2C, UIProperty
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { DesignExternalMetaDataC2C, getMetaDataFromDesign } from '../entities/design-external-metadata';

export interface LocalDocument extends IBaseDesign {
    projectDesignC2C: ProjectDesignEntityC2C;
}

export interface LocalDesignListItemCommon extends IDesignListItemCommon {
    projectDesignC2C: ProjectDesignEntityC2C;
}

export type IDefferedDataInternal = IDefferedDataCommon<ProjectDesignEntityC2C, IDetailedDisplayDesign>;

/**
 * DocumentServiceBaseC2C base
 */
export abstract class DocumentServiceBaseC2C extends DocumentServiceInjected {
    public abstract openDesignC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C>;
    public abstract openDesignExclusiveC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C>;
    public abstract addDesignC2C(projectId: string, design: DesignC2C, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItemCommon>;
    public abstract setDesignPropertiesC2C(design: DesignC2C, documentDesign: IDesignListItemCommon): void;
    public abstract updateDesignWithNewContentC2C(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignEntityC2C,
        metadataOverride: DesignExternalMetaDataC2C,
        displayDesign: IDetailedDisplayDesign,
        unlock?: boolean,
        exclusiveLock?: boolean,
        documentAccessMode?: DocumentAccessMode
    ): Promise<void>;
}


@Injectable({
    providedIn: 'root'
})
export class DocumentServiceC2C extends DocumentServiceBaseC2C {
    public override setBaseService(baseService: DocumentServiceBaseC2C) {
        super.setBaseService(baseService);
    }

    public async openDesignC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        return await this.openDesignCommon<ProjectDesignEntityC2C>(
            design,
            (design: IBaseDesign) => (design as LocalDocument).projectDesignC2C
        );
    }

    public async openDesignExclusiveC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        return await this.openDesignExclusiveBase<ProjectDesignEntityC2C>(
            design,
            (content, designName, projectName) => {
                content.designName = designName;
                content.projectName = projectName;
                return content;
            },
            (design: IBaseDesign) => (design as LocalDocument).projectDesignC2C
        );
    }

    public async addDesignC2C(projectId: string, design: DesignC2C, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItemCommon> {
        return await this.addDesignBase({
            projectId,

            designType: design.designTypeId,
            design,

            canGenerateUniqueName,
            ignoreConflict,

            setProjectDesign: (newDesign: IDesignListItemCommon, designCommon: DesignCommon) => {
                (newDesign as IDesignListItemCommon & LocalDocument).projectDesignC2C = (designCommon as DesignC2C).designData.projectDesignC2C;
            },
            adjustDesignListItemContents: (newDesign: IDesignListItemCommon, designCommon: DesignCommon, project: Project) => {
                newDesign.integrationDocument = designCommon.integrationDocument;
                newDesign.projectName = project.name ?? '';
                newDesign.isSharedByMe = project.isSharedByMe;

                this.setDesignPropertiesC2C(design, newDesign);
            },
            getDesignMetadata: (_designType: number, design: object) => {
                return getMetaDataFromDesign(design as DesignC2C);
            },
            getDesignObject: (designCommon: DesignCommon) => {
                const design = designCommon as DesignC2C;
                const data = {...design.designData.projectDesignC2C};
                delete data.designName;
                delete data.projectName;
                return data;
            }
        });
    }

    public setDesignPropertiesC2C(design: DesignC2C, documentDesign: IDesignListItemCommon) {
        design.projectName = documentDesign.projectName;

        // change project design name if it was changed by document service
        if (design.designData.projectDesignC2C.designName != documentDesign.designName) {
            design.designData.projectDesignC2C = {
                ...design.designData.projectDesignC2C,
                designName: documentDesign.designName
            };

            design.model[UIProperty.Option_DesignName] = documentDesign.designName;
        }
    }

    public async updateDesignWithNewContentC2C(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignEntityC2C,
        metadataOverride: DesignExternalMetaDataC2C,
        displayDesign: IDetailedDisplayDesign,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        const defferedData: IDefferedDataInternal = {
            designId,
            projectId,
            designName,
            contentOverride,
            metadataOverride,
            unlock,
            displayDesign,
            exclusiveLock,
            documentAccessMode
        };

        await this.updateDesignWithNewContentBase({
            designId,

            projectId,
            designName,
            metadata: metadataOverride,
            contentOverride,
            displayDesign,
            defferedData,

            updateFn: async (projectDesign: ProjectDesignEntityC2C, internalDesign: IDesignListItemCommon, metadata: DesignExternalMetaDataCommon, displayDesign?: IDetailedDisplayDesign) => {
                if (displayDesign != null) {
                    detailedDisplayDesignToProjectDesignC2C(projectDesign, displayDesign);
                }

                const design = this.findDesignById(designId);
                (design as LocalDesignListItemCommon).projectDesignC2C = projectDesign;

                if (metadata != null) {
                    // update meta-data in proxy store
                    internalDesign.metaData.productName = metadata.productName;
                    internalDesign.metaData.designType = metadata.designType;
                    internalDesign.metaData.name = metadata.name;
                    internalDesign.metaData.region = metadata.region;
                    internalDesign.metaData.standard = metadata.standard;
                    internalDesign.metaData.designMethod = metadata.designMethod;
                    internalDesign.metaData.approvalNumber = metadata.approvalNumber;

                    const mdSrc = metadata as DesignExternalMetaDataC2C;
                    const mdTrgt = internalDesign.metaData as DesignExternalMetaDataC2C;
                    mdTrgt.calculationType = mdSrc.calculationType;
                    mdTrgt.handrailConnectionType = mdSrc.handrailConnectionType;
                }
            },
            putSmallDesignChangePromiseFn: (data: IDefferedDataInternal) => {
                return this.putSmallDesignChangePromiseC2C(data);
            }
        });
    }


    private async smallDesignChangeC2C(
        internalDesign: IDesignListItemCommon,
        projectDesign: ProjectDesignEntityC2C,
        data: IDefferedDataInternal,
        metaData: DesignExternalMetaDataCommon
    ): Promise<void> {
        await this.smallDesignChangeBase({
            internalDesign,
            projectDesign,
            data,
            metaData,

            getProjectDesign: (projectDesign: ProjectDesignEntityC2C) => projectDesign,
            setDesignListItemMetaData: (internalDesign: IDesignListItemCommon, metaData: DesignExternalMetaDataCommon) => {
                internalDesign.metaData.productName = metaData.productName;
                internalDesign.metaData.designType = metaData.designType;
                internalDesign.metaData.name = metaData.name;
                internalDesign.metaData.region = metaData.region;
                internalDesign.metaData.standard = metaData.standard;
                internalDesign.metaData.designMethod = metaData.designMethod;
                internalDesign.metaData.approvalNumber = metaData.approvalNumber;

                const mdSrc = metaData as DesignExternalMetaDataC2C;
                const mdTrgt = internalDesign.metaData as DesignExternalMetaDataC2C;
                mdTrgt.calculationType = mdSrc.calculationType;
                mdTrgt.handrailConnectionType = mdSrc.handrailConnectionType;
            },
            putSmallDesignChangePromise: (data: IDefferedDataInternal) => {
                return this.putSmallDesignChangePromiseC2C(this.defferedRequests[data.designId].defferedData as IDefferedDataInternal);
            }
        });
    }

    private async putSmallDesignChangePromiseC2C(data: IDefferedDataInternal): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        delete projectDesign.designName;
        delete projectDesign.projectName;
        const metaData = cloneDeep(data.metadataOverride);

        if (data.displayDesign != null) {
            detailedDisplayDesignToProjectDesignC2C(projectDesign, data.displayDesign);
        }

        await this.smallDesignChangeC2C(internalDesign, projectDesign, data, metaData);
    }
}
