import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import { ConnectionType } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { getSpriteAsIconStyle } from '../../sprites';
import { checkConnectionType, getCommandConditional, getPopupTooltipConditional, isACICSA, isCSA } from './static-menu-helper';

export function createC2CMenu_AnchorCalculationData(design: DesignC2C): TabC2C {
    return {
        DisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.HnaJointsOverlay' : 'Agito.Hilti.C2C.Navigation.TabAnchorTheory',
        Id: 6,
        Name: 'anchorcalculationdata-tab',
        Image: 'tab-anchor-theory',
        IconImage: getSpriteAsIconStyle('sprite-tab-anchor-theory'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createC2CMenu_AnchorCalculationData_AnchorTheoryData(design),
            createC2CMenu_AnchorCalculationData_SupplementaryReinforcement()
        ]
    };
}

function createC2CMenu_AnchorCalculationData_AnchorTheoryData(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData',
        Id: 36,
        Name: 'anchortheorydata-section',
        TooltipType: getPopupTooltipConditional(isACICSA(design) && checkConnectionType(design, ConnectionType.StructuralJoints)),
        Command: getCommandConditional('OpenAnchorTheoryDataPopup', isACICSA(design) && checkConnectionType(design, ConnectionType.StructuralJoints)),
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsOverwrittenFromESR.Title',
                Name: 'overwritevaluefromesr',
                UIPropertyId: 10441,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsOverwrittenFromESR' : null as unknown as string,
                TooltipTitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsOverwrittenFromESR.Title' : null as unknown as string,
                TooltipDisabledTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsOverwrittenFromESR.Title',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenPhiTensionPopup', isACICSA(design))
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.SyncPsiBondPsiBreakout',
                Name: 'sync-phibond-phibreakout',
                UIPropertyId: 10489,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.SyncPsiBondPsiBreakout',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.SyncPsiBondPsiBreakout'
            },
            {
                ControlType: 'TextBox',
                Name: 'phibond',
                TitleDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBond.CSA' :
                    'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBond',
                UIPropertyId: 10490,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBond' : null as unknown as string,
                TooltipTitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBond' : null as unknown as string,
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenBondBreakoutPopup', isACICSA(design))
            },
            {
                ControlType: 'TextBox',
                Name: 'phibreakout',
                TitleDisplayKey: isCSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBreakout.CSA' :
                    'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBreakout',
                UIPropertyId: 10491,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBreakout' : null as unknown as string,
                TooltipTitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBreakout' : null as unknown as string,
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenBondBreakoutPopup', isACICSA(design))
            },
            {
                ControlType: 'TextBox',
                UnitGroup: UnitGroup.Length,
                Name: 'embedmentdepthinexistingconcrete',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.ExistingConcreteEmbedmentDepth',
                UIPropertyId: 10443,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.ExistingConcreteEmbedmentDepth',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.ExistingConcreteEmbedmentDepth'
            },
            {
                ControlType: 'Dropdown',
                Name: 'reinforcementoverstrength-dropdown',
                UIPropertyId: 10452,
                CodelistName: 'Overstrength',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.ReinforcementOverstrength',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.ReinforcementOverstrength',
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenOverstrengthPopup', isACICSA(design))
            },
            {
                ControlType: 'TextBox',
                Name: 'reinforcementoverstrengthcustomoverstrength',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.CustomReinforcementOverstrength',
                UIPropertyId: 10445,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.CustomReinforcementOverstrength',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.CustomReinforcementOverstrength',
                FixedDecimals: true
            }
        ]
    };
}

function createC2CMenu_AnchorCalculationData_SupplementaryReinforcement(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.SupplementaryReinforcement',
        Id: 37,
        Name: 'supplementary-reinforcement-section',
        Controls: [
            {
                ControlType: 'RadioButtonGroup',
                Name: 'reinforcement-tension-conditions-radio-buttons',
                UIPropertyId: 10492,
                CodelistName: 'ReinforcementTensionCondition',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.ReinforcementTensionConditions'
            }
        ]
    };
}
