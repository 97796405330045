import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';

import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { getSpriteAsIconStyle } from '../../sprites';

export function createC2CMenu_OverlayApplication(): TabC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.OverlayApplicationTab',
        Id: 24,
        Name: 'overlay-application-tab',
        Image: 'tab-overlay-application',
        IconImage: getSpriteAsIconStyle('sprite-tab-overlay-application'),
        Width: NavigationTabWidth.Normal,
        TabRegions: createC2CMenu_OverlayApplication_Regions()

    };
}

function createC2CMenu_OverlayApplication_Regions() {
    return ([
        createC2CMenu_OverlayApplication_CrossSections(),
    ]);
}

function createC2CMenu_OverlayApplication_CrossSections(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabApplication.CrossSections',
        Id: 50,
        Name: 'cross-sections',
        Controls: [
            {
                ControlType: 'ImageNameSelectionGroup',
                CodelistName: 'OverlayCrossSection',
                Name: 'overlay-cross-section',
                UIPropertyId: 10817
            }]
    };
}