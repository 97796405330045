<div class="reduction-factors-inputs content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control-partialReductionFactor">
                <pe-numeric-text-box
                    [id]="partialReductionFactorSteel.id"
                    [title]="partialReductionFactorSteel.title"
                    [placeholder]="partialReductionFactorSteel.placeholder"
                    [value]="data.partialReductionFactorSteel"
                    (valueChange)="data.partialReductionFactorSteel = $any($event).detail"
                    [unit]="partialReductionFactorSteel.unit"
                    [minValue]="partialReductionFactorSteel.minValue"
                    [maxValue]="partialReductionFactorSteel.maxValue"
                    [disabled]="disabled"
                    [precision]="partialReductionFactorSteel.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-partialReductionFactor">
                <pe-numeric-text-box
                    [id]="partialReductionFactorConcrete.id"
                    [title]="partialReductionFactorConcrete.title"
                    [placeholder]="partialReductionFactorConcrete.placeholder"
                    [value]="data.partialReductionFactorConcrete"
                    (valueChange)="data.partialReductionFactorConcrete = $any($event).detail"
                    [unit]="partialReductionFactorConcrete.unit"
                    [minValue]="partialReductionFactorConcrete.minValue"
                    [maxValue]="partialReductionFactorConcrete.maxValue"
                    [disabled]="disabled"
                    [precision]="partialReductionFactorConcrete.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-partialReductionFactor" *ngIf="isReductionFactorFireVisible">
                <pe-numeric-text-box
                    [id]="partialReductionFactorFire.id"
                    [title]="partialReductionFactorFire.title"
                    [placeholder]="partialReductionFactorFire.placeholder"
                    [value]="data.partialReductionFactorFire"
                    (valueChange)="data.partialReductionFactorFire = $any($event).detail"
                    [unit]="partialReductionFactorFire.unit"
                    [minValue]="partialReductionFactorFire.minValue"
                    [maxValue]="partialReductionFactorFire.maxValue"
                    [disabled]="disabled"
                    [precision]="partialReductionFactorFire.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-alphacc">
                <pe-numeric-text-box
                    [id]="alphaCC.id"
                    [title]="alphaCC.title"
                    [placeholder]="alphaCC.placeholder"
                    [value]="data.alphaCC"
                    (valueChange)="data.alphaCC = $any($event).detail"
                    [unit]="alphaCC.unit"
                    [minValue]="alphaCC.minValue"
                    [maxValue]="alphaCC.maxValue"
                    [disabled]="disabled"
                    [precision]="alphaCC.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-alphact" *ngIf="isAlphaCTVisible">
                <pe-numeric-text-box
                    [id]="alphaCT.id"
                    [title]="alphaCT.title"
                    [placeholder]="alphaCT.placeholder"
                    [value]="data.alphaCT"
                    (valueChange)="data.alphaCT = $any($event).detail"
                    [unit]="alphaCT.unit"
                    [minValue]="alphaCT.minValue"
                    [maxValue]="alphaCT.maxValue"
                    [disabled]="disabled"
                    [precision]="alphaCT.precision">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
