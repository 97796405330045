import 'mathjax/es5/tex-chtml.js';

import { Component, Input, OnInit, ViewChild } from '@angular/core';

// Declare MathJax as a global variable so that it can be used in this TypeScript file
declare global {
  interface Window {
    MathJax: {
      typesetPromise: () => void;
    };
  }
}

@Component({
  selector: 'c2c-math-jax-paragraph',
  templateUrl: './math-jax-paragraph.component.html',
  styleUrls: ['./math-jax-paragraph.component.scss']
})
export class MathJaxParagraphComponent implements OnInit {
    @ViewChild('mathParagraph') paragraphElement: any;

    @Input()
    mathString?: string;

    ngOnInit(): void {
        Promise.resolve().then(() => {
            // Insert the input string
            this.paragraphElement.nativeElement.innerHTML = this.mathString;

            // Render the Latex
            window.MathJax.typesetPromise();
        });
    }
}
