<div class="utilizations box-section box-section-white">
    <div class="box-section-header box-section-header-with-img" [class]="typeClass">
        <div class="box-section-title drag-handle-static utilizaion-title-wrapper" [ngbTooltip]="getTooltipText()">
            <div class="image" [id]="getComponentId('image')" *ngIf="hasIcon">
                <div class="pe-ui-c2c-sprite" [class]="spriteIcon"></div>
            </div>
            <div class="no-image" *ngIf="!hasIcon"></div>
            <span class="bar-title" [id]="headerId">
                {{ title }}
                <button type="button" *ngIf="infoPopup" (click)="infoPopup()" class="info-button pe-ui-c2c-sprite pe-ui-c2c-sprite-info-tooltip"></button>
                <span class="new-label" *ngIf="showNewLabel" l10n="Agito.Hilti.C2C.Loads.NewStatus"></span>
            </span>

            <span class="bar-subtitle" *ngIf="!collapsed"></span>
        </div>

        <pe-utilization-bar *ngIf="showPercentage && collapsed" [collapsed]="collapsed" [utilizationInvalid]="dataInvalid" [utilizationValue]="utilizationValue"></pe-utilization-bar>

        <pe-collapse
            [id]="collapsingControl"
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>
</div>
