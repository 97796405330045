import { ProjectCodeList } from '../enums/project-code-list';
import {
    ApplicationType, ConnectionType, DesignStandard
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { CodeListServiceBase as CodeListServiceC2C } from '../services/code-list.service.base';
import {
    MethodGroupConnTypeStandardRegion
} from './code-lists/connection-type-method-standard-regions';

export interface ISelectConnectionApplicationData {
    // Context
    inEditMode: boolean;
    designStandard: DesignStandard;
    designStandardPIR: DesignStandard;
    regionId?: number;
    c2cEnabled?: number;

    // Values
    selectedConnectionType?: ConnectionType;
    selectedApplicationType?: ApplicationType;
}

export function loadSelectConnectionApplicationDataDefaults(
    data: ISelectConnectionApplicationData,
    overlayOnly: boolean,
    codeListC2C: CodeListServiceC2C
) {
    // SelectedConnectionType
    if (overlayOnly) {
        data.selectedConnectionType = ConnectionType.ConcreteOverlay;
    }
    else {
        const allConnectionTypes = codeListC2C.projectCodeListsC2C[ProjectCodeList.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];
        const filteredConnectionTypes = allConnectionTypes.filter(x => x.regionId == data.regionId);

        if (filteredConnectionTypes.length > 0 &&
            (data.selectedConnectionType == null || !filteredConnectionTypes.some(x => x.connectionTypeId == data.selectedConnectionType && x.designStandardId == selectedDesignStandard(data, data.selectedConnectionType ?? ConnectionType.Unknown)))) {
            data.selectedConnectionType = filteredConnectionTypes[0].connectionTypeId;
        }
    }
}

export function selectedDesignStandard(data: ISelectConnectionApplicationData, connectionType: ConnectionType) {
    switch (connectionType) {
        case ConnectionType.Splices:
        case ConnectionType.StructuralJoints:
            return data.designStandardPIR;
        default:
            return data.designStandard;
    }
}
