<div class="rebar-calculation-parameters-inputs content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control-rebarCalculationParameters" *ngIf="!useAusOptions">
                <pe-numeric-text-box
                    [id]="existingReinforcementAlpha3.id"
                    [title]="existingReinforcementAlpha3.title"
                    [placeholder]="existingReinforcementAlpha3.placeholder"
                    [value]="data.existingReinforcementAlpha3"
                    (valueChange)="data.existingReinforcementAlpha3 = $any($event).detail"
                    [unit]="existingReinforcementAlpha3.unit"
                    [minValue]="existingReinforcementAlpha3.minValue"
                    [maxValue]="existingReinforcementAlpha3.maxValue"
                    [disabled]="disabled"
                    [tooltip]="existingReinforcementAlpha3.tooltip">
                </pe-numeric-text-box>
            </div>

            <div class="control-rebarCalculationParameters" *ngIf="useAusOptions">
                <pe-numeric-text-box
                    [id]="factorK4.id"
                    [title]="factorK4.title"
                    [placeholder]="factorK4.placeholder"
                    [value]="data.rebarCalculationParametersAs.factorK4"
                    (valueChange)="data.rebarCalculationParametersAs.factorK4 = $any($event).detail"
                    [unit]="factorK4.unit"
                    [minValue]="factorK4.minValue"
                    [maxValue]="factorK4.maxValue"
                    [disabled]="disabled"
                    [tooltip]="factorK4.tooltip">
                </pe-numeric-text-box>
            </div>

            <div class="control-rebarCalculationParameters" *ngIf="useAusOptions">
                <pe-numeric-text-box
                    [id]="factorK6.id"
                    [title]="factorK6.title"
                    [placeholder]="factorK6.placeholder"
                    [value]="data.rebarCalculationParametersAs.factorK6"
                    (valueChange)="data.rebarCalculationParametersAs.factorK6 = $any($event).detail"
                    [unit]="factorK6.unit"
                    [minValue]="factorK6.minValue"
                    [maxValue]="factorK6.maxValue"
                    [disabled]="disabled"
                    [tooltip]="factorK6.tooltip">
                </pe-numeric-text-box>
            </div>

            <div class="control-rebarCalculationParameters">
                <pe-numeric-text-box
                    [id]="transversePressure.id"
                    [title]="transversePressure.title"
                    [placeholder]="transversePressure.placeholder"
                    [value]="transversePressureValue"
                    (valueChange)="transversePressureChanged($any($event).detail)"
                    [unit]="transversePressure.unit"
                    [minValue]="transversePressure.minValue"
                    [maxValue]="transversePressure.maxValue"
                    [disabled]="disabled"
                    [tooltip]="transversePressure.tooltip"
                    [precision]="transversePressure.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-rebarCalculationParameters">
                <pe-dropdown
                    [id]="minimumAreaReinforcement.id"
                    [title]="minimumAreaReinforcement.title"
                    [items]="minimumAreReinforcementItems"
                    [selectedValue]="minimumAreaReinforcementValue"
                    (selectedValueChange)="minimumAreaReinforcementChanged($any($event).detail)">
                </pe-dropdown>
            </div>

            <div class="control-rebarCalculationParameters" *ngIf="isMinimumAreaReinforcementUserDefined">
                <pe-numeric-text-box
                    [id]="minimumAreaReinforcementInput.id"
                    [title]="minimumAreaReinforcementInput.title"
                    [placeholder]="minimumAreaReinforcementInput.placeholder"
                    [value]="minimumAreaReinforcementInputValue"
                    (valueChange)="minimumAreaReinforcementInputChanged($any($event).detail)"
                    [unit]="minimumAreaReinforcementInput.unit"
                    [minValue]="minimumAreaReinforcementInput.minValue"
                    [maxValue]="minimumAreaReinforcementInput.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control-rebarCalculationParameters">
                <pe-dropdown
                    [id]="maximumAreaReinforcement.id"
                    [title]="maximumAreaReinforcement.title"
                    [items]="maximumAreReinforcementItems"
                    [selectedValue]="maximumAreaReinforcementValue"
                    (selectedValueChange)="maximumAreaReinforcementChanged($any($event).detail)">
                </pe-dropdown>
            </div>

            <div class="control-rebarCalculationParameters" *ngIf="isMaximumAreaReinforcementUserDefined">
                <pe-numeric-text-box
                    [id]="maximumAreaReinforcementInput.id"
                    [title]="maximumAreaReinforcementInput.title"
                    [placeholder]="maximumAreaReinforcementInput.placeholder"
                    [value]="maximumAreaReinforcementInputValue"
                    (valueChange)="maximumAreaReinforcementInputChanged($any($event).detail)"
                    [unit]="maximumAreaReinforcementInput.unit"
                    [minValue]="maximumAreaReinforcementInput.minValue"
                    [maxValue]="maximumAreaReinforcementInput.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control-rebarCalculationParameters">
                <pe-dropdown
                    [id]="spacingMaximum.id"
                    [title]="spacingMaximum.title"
                    [items]="spacingMaximumItems"
                    [selectedValue]="spacingMaximumValue"
                    (selectedValueChange)="spacingMaximumChanged($any($event).detail)">
                </pe-dropdown>
            </div>

            <div class="control-rebarCalculationParameters" *ngIf="isSpacingMaximumUserDefined">
                <pe-numeric-text-box
                    [id]="spacingMaximumInput.id"
                    [title]="spacingMaximumInput.title"
                    [placeholder]="spacingMaximumInput.placeholder"
                    [value]="spacingMaximumInputValue"
                    (valueChange)="spacingMaximumInputChanged($any($event).detail)"
                    [unit]="spacingMaximumInput.unit"
                    [minValue]="spacingMaximumInput.minValue"
                    [maxValue]="spacingMaximumInput.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
