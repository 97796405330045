<pe-modal-header [modalInstance]="modalInstance" [title]="translate(design.globalMetaProperties.selectProductTitleTranslationKey ?? '')" closeButtonId="select-rebar-close-button">
</pe-modal-header>

<div class="modal-body" id="product-tab-selectrebar-dialog" [class.loaded]="loaded">
    <div class="modal-body-content">
        <div class="left-side">
            <div class="filters-title-row input-filters" *ngIf="filterGroups.length > 0">
                <div class="input-filter-row">
                    <div class="input-filter-content">
                        <div class="input-filter-content-column">
                            <p class="checkbox-filters-title" [innerHtml]="translate('Agito.Hilti.C2C.SelectRebar.CheckboxFilters')"></p>
                        </div>
                        <div class="input-filter-content-column">
                            <pe-button type="button" id="selectrebar-dialog-clearallfilters-button"
                                class="modal-button button button-default input-filter-content-column-button"
                                [look]="'Default'"
                                (click)="onClearAllButtonClick()">
                                <span class="text" [innerHtml]="translate('Agito.Hilti.C2C.SelectRebar.ClearAllFilters')"></span>
                            </pe-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="input-filters">
                <div class="input-filter-row" *ngIf="!design.isPirEu && filterGroups.length > 0">
                    <p class="input-filter-title title-strike" [innerHtml]="translate('Agito.Hilti.C2C.SelectProduct.HoleDiameter')" id="selectrebar-dialog-holediameter-section"></p>

                    <div class="input-filter-content">
                        <div class="input-filter-content-column">
                            <pe-numeric-text-box
                                [id]="'selectrebar-dialog-Holediameter-section-min-value-text'"
                                [title]="translate('Agito.Hilti.C2C.SelectProduct.HoleDiameter.Min')"
                                [value]="holeDiameterMin"
                                (valueChange)="onFilterInputValueChanged($any($event).detail, 'holeDiameterMin')"
                                [unit]="unitLength"
                                [precision]="getPrecisionLength()"
                                [minValue]="0"
                                [maxValue]="100"
                                [debounceStepperChanges]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="input-filter-content-column">
                            <pe-numeric-text-box
                                [id]="'selectrebar-dialog-Holediameter-section-max-value-text'"
                                [title]="translate('Agito.Hilti.C2C.SelectProduct.HoleDiameter.Max')"
                                [value]="holeDiameterMax"
                                (valueChange)="onFilterInputValueChanged($any($event).detail, 'holeDiameterMax')"
                                [unit]="unitLength"
                                [precision]="getPrecisionLength()"
                                [minValue]="0"
                                [maxValue]="100"
                                [debounceStepperChanges]="true">
                            </pe-numeric-text-box>
                        </div>
                    </div>
                </div>
            </div>

            <div class="checkbox-filters" id="selectrebar-dialog-filter-section">
                <div class="checkbox-group" *ngFor="let filterGroup of filterGroups; trackBy:identifyFilterGroup">
                    <ng-template #filterGroupTooltipTemplate>
                        <div class="control-tooltip">
                            <p class="title" [innerHtml]="filterGroup.infoPopup?.title" *ngIf="filterGroup.infoPopup?.title != null"></p>
                            <p class="content" [innerHtml]="filterGroup.infoPopup?.content" *ngIf="filterGroup.infoPopup?.content != null"></p>
                        </div>
                    </ng-template>

                    <p class="checkbox-group-title title-strike" id="selectrebar-dialog-{{filterGroup.id}}-group">
                        <span
                            class="checkbox-group-title-label">{{translate(filterGroup.titleKey ?? '')}}</span>
                        <button type="button" class="control-tooltip-popup sprite sprite-info-tooltip"
                            *ngIf="filterGroup.infoPopup?.show" (click)="openFilterGroupInfoPopup(filterGroup)"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.ControlTooltip.Popup')"></button>
                    </p>

                    <div class="checkbox-item checkbox" *ngFor="let filterItem of filterGroup.items; trackBy:identifyFilterGroupItem"
                        id="selectrebar-dialog-{{filterItem.id}}-checkbox">
                        <pe-checkbox-button
                            class="selectrebar-dialog-{{filterItem.id}}-checkbox"
                            [items]="filterItem.checkbox?.items"
                            [selectedValues]="filterItem.checkbox?.selectedValues"
                            (selectedValuesChange)="onFilterCheckboxChange(filterItem, filterGroup)"
                            [disabled]="filterItem.checkbox?.disabled">
                        </pe-checkbox-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="right-side">
            <div class="top-side">
                <div class="filter-input-container">
                    <pe-text-box
                        [id]="'selectrebar-dialog-search-textbox'"
                        [placeholder]="filterPlaceholder"
                        [value]="filter"
                        (valueChange)="onFilterChange($any($event).detail)"
                        [debounceChanges]="true"
                        [marginBottom]="'None'">
                    </pe-text-box>

                    <button id="select-rebar-sort-cancel-button" type="button" class="cancel-filter"
                        (click)="resetFilter()" *ngIf="filter">
                        <span class="image sprite sprite-x-circle"></span>
                    </button>
                </div>

                <div class="sort-dropdown">
                    <pe-dropdown
                        [id]="sortDropdown.id"
                        [items]="sortDropdown.items"
                        [selectedValue]="sortDropdown.selectedValue"
                        (selectedValueChange)="onSortChange($any($event).detail)">
                    </pe-dropdown>
                </div>
            </div>

            <div class="rebars"
                data-control-id='selectrebar-dialog-rebarlist-section'
                id="selectrebardialogrebarlistsection"
                *ngIf="mode == null || mode == selectRebarModeEnum.normal">

                <span class="sprite-anchor-family-HIT-hy200RV3_Rebar"></span>

                <virtual-scroller #scrollRebars
                    [items]="rebars" [parentScroll]="scrollElement" [scrollAnimationTime]="0"
                    [ngStyle]="{ 'height': (rebars.length * rebarRowHeight) + 'px' }">
                    <div id="select-rebar-{{rebar.id}}-row"
                        *ngFor="let rebar of $any(scrollRebars).viewPortItems; trackBy:identifyRebar"
                        class="rebar modal-grid-button"
                        [class.selected]="selectedRebarFamilyId == rebar.id"
                        (click)="selectRebar(rebar)">

                        <div class="image-container">
                            <div class="no-image-container">
                                <span class="no-image pe-ui-c2c-sprite sprite-responsive pe-ui-c2c-sprite-anchor-family-no-photo-available"></span>
                            </div>
                            <span class="rebar-image pe-ui-c2c-sprite sprite-responsive" [ngClass]="'pe-ui-c2c-' + rebar.image"></span>
                        </div>

                        <div class="text-container">
                            <div class="text-wrapper">
                                <p class="rebar-name">{{rebar.name}}</p>
                                <p class="rebar-description">{{rebar.description}}</p>
                                <p class="rebar-tags" *ngIf="rebar.tag">
                                    <span class="tag">{{rebar.tag}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="favorite-container">
                            <button class="favorite-button"
                                (click)="$event.preventDefault(); $event.stopPropagation(); favoriteToggle(rebar.id)"
                                type="button"
                                [ngbTooltip]="favoriteTooltip">
                                <span class="favorite-image sprite pe-ui-c2c-sprite" [ngClass]="getFavoriteSprite(rebar.id)"></span>
                            </button>
                        </div>
                        <div class="icons-container">
                            <div class="icons-wrapper">
                                <span *ngFor="let icon of icons; trackBy:identifyIcon"
                                    class="icon sprite pe-ui-c2c-sprite pe-ui-c2c-{{icon.image}}"
                                    [class.visible]="$any(rebar)[icon.visibleExpression]"
                                    [ngbTooltip]="getIconTooltip(icon.tooltip)"
                                    [openDelay]="0"></span>
                            </div>
                        </div>
                    </div>
                </virtual-scroller>
            </div>

            <div class="calculating" *ngIf="calculateAllLoading">
                <p class="calculating-title" [innerHtml]="translate('Agito.Hilti.Profis3.CalculateAll.Calculating.Title')"></p>

                <pe-circle-loader [loadingProgress]="progress"></pe-circle-loader>
            </div>

            <div class="calculate-all" *ngIf="mode == selectRebarModeEnum.calculateAll && !calculateAllLoading">
                <div class="calculate-all-rebars" id="selectrebar-dialog-rebarlist-section">
                    <virtual-scroller #scrollCalculateAllRebars
                        [items]="calculateAllRebars" [parentScroll]="scrollElement" [scrollAnimationTime]="0"
                        [ngStyle]="{ 'height': (calculateAllRebars.length * calculateAllRebarRowHeight) + 'px' }">
                        <div class="calculate-all-rebars-row"
                            *ngFor="let rebar of $any(scrollCalculateAllRebars).viewPortItems; trackBy:identifyCalculateAllRebar">
                            <div [ngSwitch]="rebar.type">
                                <div class="calculate-all-rebar"
                                    *ngSwitchCase="calculateAllProductUiItemTypeEnum.Header">
                                    <div class="agt-column value-column name-column">
                                        <span class="value" [ngbTooltip]="rebar.name">{{rebar.name}}</span>
                                    </div>
                                    <div class="agt-column value-column size-column">
                                        <span class="value"
                                            [ngbTooltip]="translate('Agito.Hilti.C2C.SelectRebar.CalculateAll.Size')">{{translate('Agito.Hilti.C2C.SelectRebar.CalculateAll.Size')}}</span>
                                    </div>
                                    <div class="agt-column value-column embedment-depth-column">
                                        <span class="value"
                                            [ngbTooltip]="translate('Agito.Hilti.C2C.SelectRebar.CalculateAll.EmbedmentDepth')">{{translate('Agito.Hilti.C2C.SelectRebar.CalculateAll.EmbedmentDepth')}}</span>
                                    </div>
                                    <div class="agt-column value-column utilisation-column">
                                        <span class="value"
                                            [ngbTooltip]="translate('Agito.Hilti.C2C.SelectRebar.CalculateAll.Utilisation')">{{translate('Agito.Hilti.C2C.SelectRebar.CalculateAll.Utilisation')}}</span>
                                    </div>
                                    <div class="agt-column value-column geometry-column">
                                        <span class="value"
                                            [ngbTooltip]="translate('Agito.Hilti.C2C.SelectRebar.CalculateAll.Geometry')">{{translate('Agito.Hilti.C2C.SelectRebar.CalculateAll.Geometry')}}</span>
                                    </div>
                                </div>

                                <div class="calculate-all-rebar-detailed modal-grid-button"
                                    *ngSwitchCase="calculateAllProductUiItemTypeEnum.Item"
                                    [class.selected]="rebar.details?.id == selectedDetailedProductId"
                                    (click)="selectRebarDetailed(rebar.id, rebar.details!)">
                                    <div class="agt-column value-column name-column">
                                        <span class="value"
                                            [ngbTooltip]="rebar.details?.name">{{rebar.details?.name}}</span>
                                    </div>
                                    <div class="agt-column value-column size-column">
                                        <span class="value"
                                            [ngbTooltip]="rebar.details?.sizeFormated">{{rebar.details?.sizeFormated}}</span>
                                    </div>
                                    <div class="agt-column value-column embedment-depth-column">
                                        <span class="value"
                                            [ngbTooltip]="rebar.details?.embedmentDepthFormated">{{rebar.details?.embedmentDepthFormated}}</span>
                                    </div>
                                    <div class="agt-column value-column utilisation-column"
                                        [class.invalid]="!isTscOk()"
                                        [class.ok]="isTscOk()">
                                        <span class="value"
                                            [ngbTooltip]="utilizationTooltipTemplate"
                                            tooltipClass="select-rebar-utilisation-tooltip"
                                            [openDelay]="0">{{rebar.details?.utilizationFormated}}</span>
                                    </div>
                                    <div class="agt-column icon-column geometry-column">
                                        <span *ngIf="rebar.details?.isGeometryOk"
                                            class="image sprite sprite-notification-ok geometry-image"></span>
                                        <span *ngIf="!rebar.details?.isGeometryOk"
                                            class="image sprite sprite-notification-alert geometry-image"
                                            [ngbTooltip]="geometryTooltipTemplate"
                                            tooltipClass="select-rebar-geometry-tooltip"
                                            [openDelay]="0"></span>
                                    </div>

                                    <ng-template #utilizationTooltipTemplate>
                                        <!-- <ul class="utilisation-container">
                                            <li class="utilisation-container-item">
                                                <span
                                                    class="text">{{translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Utilisation.Tooltip.Tension').replace('{tension}', rebar.details.tFormated)}}</span>
                                                <span class="image sprite sprite-warning-gray" *ngIf="rebar.details.t > 100"></span>
                                            </li>
                                            <li class="utilisation-container-item">
                                                <span
                                                    class="text">{{translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Utilisation.Tooltip.Shear').replace('{shear}', rebar.details.sFormated)}}</span>
                                                <span class="image sprite sprite-warning-gray" *ngIf="rebar.details.s > 100"></span>
                                            </li>
                                            <li class="utilisation-container-item">
                                                <span
                                                    class="text">{{translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll.Utilisation.Tooltip.Combination').replace('{combination}', rebar.details.cFormated)}}</span>
                                                <span class="image sprite sprite-warning-gray" *ngIf="rebar.details.c > 100"></span>
                                            </li>
                                        </ul> -->
                                    </ng-template>

                                    <ng-template #geometryTooltipTemplate>
                                        <!-- <ul class="geometry-container">
                                            <li class="geometry-container-item" *ngIf="!rebar.details.isAnchorPlateSizeOk">
                                                <span class="image sprite sprite-warning-gray"></span>
                                                <span class="text">{{translate('Agito.Hilti.Profis3.BoundaryConditions.PlateSize')}}</span>
                                            </li>
                                            <li class="geometry-container-item" *ngIf="!rebar.details.isAnchorPlatePositionOk">
                                                <span class="image sprite sprite-warning-gray"></span>
                                                <span class="text">{{translate('Agito.Hilti.Profis3.BoundaryConditions.PlatePosition')}}</span>
                                            </li>
                                            <li class="geometry-container-item" *ngIf="!rebar.details.isEdgeDistanceOk">
                                                <span class="image sprite sprite-warning-gray"></span>
                                                <span class="text">{{translate('Agito.Hilti.Profis3.BoundaryConditions.EdgeDistance')}}</span>
                                            </li>
                                            <li class="geometry-container-item" *ngIf="!rebar.details.isAxialSpacingOk">
                                                <span class="image sprite sprite-warning-gray"></span>
                                                <span class="text">{{translate('Agito.Hilti.Profis3.BoundaryConditions.AxialSpacing')}}</span>
                                            </li>
                                            <li class="geometry-container-item" *ngIf="!rebar.details.isBaseMaterialThicknessOk">
                                                <span class="image sprite sprite-warning-gray"></span>
                                                <span class="text">{{translate('Agito.Hilti.Profis3.BoundaryConditions.BaseMaterialThickness')}}</span>
                                            </li>
                                        </ul> -->
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </virtual-scroller>
                </div>
            </div>
        </div>
    </div>

    <div class="loading-indicator">
        <pe-loading [color]="'#524f53'"></pe-loading>
    </div>
</div>
