<div class="utilizations box-section box-section-white">
    <c2c-utilization-tab
        *ngIf="canShowUtilizationTab"
        [title]="translate('Agito.Hilti.C2C.DesignMethod.Title')"
        icon="sprite-design-method-icon"
        [controlId]="id"
        [collapsingControl]="CollapsingControls.DesignMethod"
        [collapsed]="collapsed"
        (collapseChange)="onCollapseChange($any($event).detail)">
    </c2c-utilization-tab>

    <div class="items box-section-content" *ngIf="itemsShown">
        <div class="utilization">
            <div id="{{id}}-profile-utilizations-button" class="utilization-button">
                <div class="button-content">

                    <!-- ETAG OR AUS -->
                    <div *ngIf="design.isPirEuOrAus">
                        <!-- EN 199211 -->
                        <div [hidden]="!isEN199211Checked">
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.EN199211}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                    <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.EN199211}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.EN199211)"
                                            [checked]="isSelected(designMethodEnum.EN199211)"
                                            [value]="designMethodEnum.EN199211">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.EN199211}}">
                                            <strong *ngIf="isSplices" [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.EN1992-1-1' + germanNationAnnexSuffix)"></strong><strong *ngIf="isStructuralJoints" [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.EN1992-1-1.Anchorage' + germanNationAnnexSuffix)"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.EN199211)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.MaxDrillingLength')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.EN199211), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.EN199211)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- EN 199211 Strut & Tie -->
                        <div [hidden]="!(isEN199211Checked && isStructuralJoints) || isA2Regions">
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.EN199211StrutAndTie}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                        <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.EN199211StrutAndTie}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.EN199211StrutAndTie)"
                                            [checked]="isSelected(designMethodEnum.EN199211StrutAndTie)"
                                            [value]="designMethodEnum.EN199211StrutAndTie">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.EN199211StrutAndTie}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.EN1992-1-1.StrutAndTie' + germanNationAnnexSuffix)"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.EN199211StrutAndTie)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.MaxDrillingLength')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.EN199211StrutAndTie), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.EN199211StrutAndTie)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- TR069 -->
                        <div [hidden]="!isTR069ETAChecked">
                        <div class="form-check" id="design-method-radio-{{designMethodEnum.TR069}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                        <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.TR069}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.TR069)"
                                            [checked]="isSelected(designMethodEnum.TR069)"
                                            [value]="designMethodEnum.TR069">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.TR069}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.TR069' + loadTypeKey + germanNationAnnexSuffix)"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.TR069)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.MaxDrillingLength')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.TR069), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.TR069)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Hilti method anchorage -->
                        <div [hidden]="!isHiltiMethodChecked">
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.HiltiMethodAnchorage}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                        <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.HiltiMethodAnchorage}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.HiltiMethodAnchorage)"
                                            [checked]="isSelected(designMethodEnum.HiltiMethodAnchorage)"
                                            [value]="designMethodEnum.HiltiMethodAnchorage">
                                    </div>
                                    <div class="flex-item">
                                        <label for="design-method-name-{{designMethodEnum.HiltiMethodAnchorage}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.HiltiMethod.Anchorage' + germanNationAnnexSuffix)"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.HiltiMethodAnchorage)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.MaxDrillingLength')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.HiltiMethodAnchorage), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.HiltiMethodAnchorage)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.HiltiStrutsAndTies}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                        <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.HiltiStrutsAndTies}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.HiltiStrutsAndTies)"
                                            [checked]="isSelected(designMethodEnum.HiltiStrutsAndTies)"
                                            [value]="designMethodEnum.HiltiStrutsAndTies">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.HiltiStrutsAndTies}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.HiltiMethod.StrutAndTie' + germanNationAnnexSuffix)"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.HiltiStrutsAndTies)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.MaxDrillingLength')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.HiltiStrutsAndTies), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.HiltiStrutsAndTies)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- AS 3600 -->
                        <div [hidden]="!isAS3600Checked">
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.AS3600}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                    <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.AS3600}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.AS3600)"
                                            [checked]="isSelected(designMethodEnum.AS3600)"
                                            [value]="designMethodEnum.AS3600">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.AS3600}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.AS3600')"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.AS3600)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.MaxDrillingLength')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.AS3600), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.AS3600)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- HNA -->
                    <div *ngIf="design.isC2CHNA && isHnaImprovementEnabled">
                        <!-- Development length -->
                        <div [hidden]="!isDevelopmentLengthChecked">
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.DevelopmentLength}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                    <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.DevelopmentLength}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.DevelopmentLength)"
                                            [checked]="isSelected(designMethodEnum.DevelopmentLength)"
                                            [value]="designMethodEnum.DevelopmentLength">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.DevelopmentLength}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.CodeList.DesignMethodEntity.DevelopmentLength')"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.DevelopmentLength)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.Utilizations.RebarUtilizations.InstallationLength')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.DevelopmentLength), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.DevelopmentLength)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Lap splice length -->
                        <div [hidden]="!isLapSpliceLengthChecked">
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.LapSpliceLength}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                    <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.LapSpliceLength}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.LapSpliceLength)"
                                            [checked]="isSelected(designMethodEnum.LapSpliceLength)"
                                            [value]="designMethodEnum.LapSpliceLength">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.LapSpliceLength}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.CodeList.DesignMethodEntity.LapSpliceLength')"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.LapSpliceLength)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.Utilizations.RebarUtilizations.InstallationLength')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.LapSpliceLength), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.LapSpliceLength)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Anchoring to concrete to design for yield -->
                        <div [hidden]="!isAnchoringBarYieldChecked">
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.AnchoringBarYield}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                    <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.AnchoringBarYield}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.AnchoringBarYield)"
                                            [checked]="isSelected(designMethodEnum.AnchoringBarYield)"
                                            [value]="designMethodEnum.AnchoringBarYield">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.AnchoringBarYield}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.CodeList.DesignMethodEntity.AnchoringBarYield')"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.AnchoringBarYield)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign.EmbedmentDepth')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.AnchoringBarYield), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.AnchoringBarYield)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Anchoring to concrete to design for external load -->
                        <div [hidden]="!isAnchoringExternalLoadsChecked">
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.AnchoringExternalLoads}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                    <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.AnchoringExternalLoads}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.AnchoringExternalLoads)"
                                            [checked]="isSelected(designMethodEnum.AnchoringExternalLoads)"
                                            [value]="designMethodEnum.AnchoringExternalLoads">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.AnchoringExternalLoads}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.CodeList.DesignMethodEntity.AnchoringExternalLoads')"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.AnchoringExternalLoads)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.Utilizations.JointsOverlayACICSA.AnchorDesign.EmbedmentDepth')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.AnchoringExternalLoads), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.AnchoringExternalLoads)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Australia-->
                    <div *ngIf="design.isPirAus">

                        <!-- AEFACTN08 (TR069 AUS) -->
                        <div [hidden]="!isAEFACTN08Checked">
                            <div class="form-check" id="design-method-radio-{{designMethodEnum.AEFACTN08}}">
                                <div class="container">
                                    <div class="fixed form-check">
                                    <input
                                            type="radio"
                                            class="form-check-input"
                                            id="design-method-name-{{designMethodEnum.AEFACTN08}}"
                                            name="design-method-name"
                                            (change)="onChangeDesignMethod(designMethodEnum.AEFACTN08)"
                                            [checked]="isSelected(designMethodEnum.AEFACTN08)"
                                            [value]="designMethodEnum.AEFACTN08">
                                    </div>
                                    <div class="flex-item">
                                        <label class="form-check-label" for="design-method-name-{{designMethodEnum.AEFACTN08}}">
                                            <strong [innerHtml]="translate('Agito.Hilti.C2C.CodeList.DesignMethodEntity.AEFACTN08')"></strong>,
                                            <span *ngIf="haveResultsForMethod(designMethodEnum.AEFACTN08)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.MaxDrillingLength')"></span> = {{unit.formatInternalValueAsDefault(maxDrillingLength(designMethodEnum.AEFACTN08), unitGroupEnum.Length)}}
                                            </span>
                                            <span *ngIf="!haveResultsForMethod(designMethodEnum.AEFACTN08)">
                                                <span [innerHtml]="translate('Agito.Hilti.C2C.DesignMethod.NotAvailable')"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
