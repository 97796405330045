import clone from 'lodash-es/clone';

import { Component, DoCheck, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { UIProperty } from '../../../shared/entities/code-lists/UI-property';
import {
    IConcreteMaterialSettingsInputsData
} from '../../../shared/entities/concrete-material-settings';
import { IInfoDialogComponentInput } from '../../../shared/entities/info-dialog-inputs';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { IPropertyMetaDataC2C, PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { CodeListService as CodeListServiceC2C } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UnitService } from '../../services/unit.service';
import { includeSprites } from '../../sprites';

interface INumericTextBoxProperty {
    defaultValue: number;
    maxValue?: number;
    minValue?: number;
    precision: number;
    unit: Unit;
}

@Component({
    templateUrl: './concrete-material-settings-inputs.component.html',
    styleUrls: ['./concrete-material-settings-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ConcreteMaterialSettingsInputsComponent implements OnInit, DoCheck {
    @Input()
    public data!: IConcreteMaterialSettingsInputsData;

    @Input()
    public disabled!: boolean;
    public dataLoaded = false;

    public concreteMaterialSettingsEpsilonC2TextBox!: NumericTextBoxProps;
    public concreteMaterialSettingsEpsilonCu2TextBox!: NumericTextBoxProps;

    private oldData!: IConcreteMaterialSettingsInputsData;

    constructor(
        private localizationService: LocalizationService,
        private codeListC2C: CodeListServiceC2C,
        private unit: UnitService,
        private elementRef: ElementRef<HTMLElement>,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        this.initControls();

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-info-tooltip');
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setDefaultValue();
            this.refreshControl();

            this.dataLoaded = true;
        }

        this.updateData();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public infoPopup() {
        const inputValues = {
            isScopeCheckInfoPopup: false,
            command: 'OpenConcreteMaterialSettingsInfoPopup',
        } as IInfoDialogComponentInput;

        this.modalService.openWebComponentModal('c2c-info-dialog', { size: 'md' }, inputValues);
    }

    private getNumericTextBoxProperty(propertyMetaData: IPropertyMetaDataC2C, unit: Unit): INumericTextBoxProperty {
        const c2cUIProperties = this.codeListC2C.projectCodeListsC2C[ProjectCodeList.UIPropertiesC2C] as UIProperty[];
        const propertyValues = c2cUIProperties.find((property) => property.id == propertyMetaData.id);
        let propertyValue = propertyValues?.propertyValues?.find((property) => property.designStandardId == this.data.designStandardId);

        if (!propertyValue) {
            propertyValue = propertyValues?.propertyValues?.find((property) => property.designStandardId == 0);
        }

        return {
            defaultValue: propertyValue?.defaultValue ?? 0,
            maxValue: propertyValue?.maxValue,
            minValue: propertyValue?.minValue,
            precision: 4,
            unit: unit
        };
    }

    private initControls() {
        this.concreteMaterialSettingsEpsilonC2TextBox = {
            id: `concrete-material-settings-concreteMaterialSettingsEpsilonC2-textbox`,
            title: 'ε<sub>c2</sub>',
        };

        this.concreteMaterialSettingsEpsilonCu2TextBox = {
            id: `concrete-material-settings-concreteMaterialSettingsEpsilonCu2-textbox`,
            title: 'ε<sub>cu2</sub>',
        };
    }

    private updateData() {
        let update = false;

        if (this.oldData != null) {
            if (this.oldData.designStandardId != this.data.designStandardId) {
                update = true;
                this.onDesignStandardChange();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);
        }
    }

    private onDesignStandardChange() {
        this.refreshControl();
    }

    private setDefaultValue() {
        if (this.data.design != null) {
            const design = this.data.design();
            this.data.concreteMaterialSettingsEpsilonC2 = design.concreteEpsilonC2;
            this.data.concreteMaterialSettingsEpsilonCu2 = design.concreteEpsionCu2;
        }
    }

    private refreshControl() {
        const epsilonCu2LimitProperty = this.getNumericTextBoxProperty(PropertyMetaDataC2C.Option_C2C_EpsilonCU2, Unit.None);

        this.concreteMaterialSettingsEpsilonCu2TextBox.maxValue = epsilonCu2LimitProperty.maxValue;
        this.concreteMaterialSettingsEpsilonCu2TextBox.minValue = epsilonCu2LimitProperty.minValue;
        this.concreteMaterialSettingsEpsilonCu2TextBox.precision = epsilonCu2LimitProperty.precision;
        this.concreteMaterialSettingsEpsilonCu2TextBox.placeholder = this.getTextBoxPlaceholder(epsilonCu2LimitProperty.defaultValue, epsilonCu2LimitProperty.unit, epsilonCu2LimitProperty.precision);

        const epsilonC2LimitProperty = this.getNumericTextBoxProperty(PropertyMetaDataC2C.Option_C2C_EpsilonC2, Unit.None);

        this.concreteMaterialSettingsEpsilonC2TextBox.maxValue = epsilonC2LimitProperty.maxValue;
        this.concreteMaterialSettingsEpsilonC2TextBox.minValue = epsilonC2LimitProperty.minValue;
        this.concreteMaterialSettingsEpsilonC2TextBox.precision = epsilonC2LimitProperty.precision;
        this.concreteMaterialSettingsEpsilonC2TextBox.placeholder = this.getTextBoxPlaceholder(epsilonC2LimitProperty.defaultValue, epsilonC2LimitProperty.unit, epsilonC2LimitProperty.precision);
    }

    private getTextBoxPlaceholder(value: number, unit: Unit, defaultPrecision: number) {

        const result = this.unit.formatUnitValueArgs(
            this.unit.convertUnitValueArgsToUnit(
                value,
                unit,
                unit
            ),
            unit,
            defaultPrecision
        );

        if (result == null) {
            return this.translate('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }

        return result;
    }
}
