import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';

// import sprites info and images
import mainSprite from './bundles/main.json';
import mainImage from './bundles/main.png';

import anchorFamilySprite from './bundles/anchor-family.json';
import anchorFamilyImage from './bundles/anchor-family.png';

// sprite types
export type MainSprite = keyof typeof mainSprite;
export type AnchorFamilySprite = keyof typeof anchorFamilySprite;

// add more sprites with |
export type Sprite =
    MainSprite |
    AnchorFamilySprite;

export type SpriteClass = `pe-ui-c2c-${Sprite}`;

// add more sprites with ...
const spritesInfo = {
    ...toSpriteInfo(mainSprite, 'main.png'),
    ...toSpriteInfo(anchorFamilySprite, 'anchor-family.png')
};

const imagesUrl: Record<string, string> = {
    'main.png': mainImage,
    'anchor-family.png': anchorFamilyImage
};

function toSpriteInfo<T>(sprites: Record<string, T>, image: string): Record<string, T & { image: string }> {
    return Object.fromEntries((Object.entries(sprites) as [string, T & { image: string }][]).map(([spriteName, sprite]) => {
        sprite.image = image;

        return [spriteName, sprite];
    }));
}

export function includeSprites(root: Node | null, ...sprites: Sprite[]): void {
    if (root != null) {
        let cssExists = false;
        let css = `
            .pe-ui-c2c-sprite {
                display: block;
            }
        `;

        for (const sprite of sprites) {
            const spriteInfo = spritesInfo[sprite];
            if (spriteInfo == null) {
                console.warn(`Sprite with name '${sprite}' does not exist (missing json)`);
                continue;
            }

            const imageUrl = imagesUrl[spriteInfo.image];

            if (!imageUrl) {
                console.warn(`Sprite with name '${sprite}' does not exist (missing image)`);
                continue;
            }

            css += `
                .pe-ui-c2c-sprite.pe-ui-c2c-${sprite} {
                    background-image: url(${imageUrl});
                    background-position: -${spriteInfo.x}px -${spriteInfo.y}px;
                    width: ${spriteInfo.w}px;
                    min-width: ${spriteInfo.w}px;
                    height: ${spriteInfo.h}px;
                }
            `;

            cssExists = true;
        }

        if (cssExists) {
            const spriteStyle = document.createElement('style');
            spriteStyle.textContent = css;

            root.insertBefore(spriteStyle, root.firstChild);
        }
    }
}

export function getSpriteAsIconStyle(image: Sprite): IIconStyle;
export function getSpriteAsIconStyle(image: string): IIconStyle;
export function getSpriteAsIconStyle(image: Sprite | string): IIconStyle {
    const spriteInfo = getSpriteInfo(image);
    const imageUrl = imagesUrl[spriteInfo.image];

    return {
        'background-image': `url(${imageUrl})`,
        'background-position': `-${spriteInfo.x}px -${spriteInfo.y}px`,
        'width': `${spriteInfo.w}px`,
        'min-width': `${spriteInfo.w}px`,
        'height': `${spriteInfo.h}px`,
    };
}

export function getSpriteAsIconStyleResponsive(image: Sprite): IIconStyle;
export function getSpriteAsIconStyleResponsive(image: string): IIconStyle;
export function getSpriteAsIconStyleResponsive(image: Sprite | string): IIconStyle {
    const spriteInfo = getSpriteInfo(image);
    const imageUrl = imagesUrl[spriteInfo.image];

    const x = ((spriteInfo.x * 100) / (spriteInfo.tw - spriteInfo.w));
    const y = ((spriteInfo.y * 100) / (spriteInfo.th - spriteInfo.h));
    const bgWidth = spriteInfo.tw * 100 / spriteInfo.w;
    const paddingBottom = spriteInfo.h * 100 / spriteInfo.w;
    const width = spriteInfo.tw * 100 / spriteInfo.w;

    return {
        'background-image': `url(${imageUrl})`,
        'background-position': `${x}% ${y}%`,
        'background-size': `${bgWidth}% auto`,
        'width': `${width}%`,
        'max-width': '100%',
        'height': '0',
        'padding-bottom': `${paddingBottom}%`,
        'display': 'block'
    };
}

function getSpriteInfo(image: Sprite | string): any {
    if (!(image in spritesInfo)) {
        console.warn(`Sprite with name '${image}' does not exist (missing json)`);
        return {};
    }

    const spriteInfo = spritesInfo[image as Sprite];

    const imageUrl = imagesUrl[spriteInfo.image];
    if (!imageUrl) {
        console.warn(`Sprite with name '${image}' does not exist (missing image)`);
        return {};
    }

    return spriteInfo;
}
