import { Vector2 } from '@babylonjs/core/Maths/math.vector';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/c2c-gl-model/base-update';
import { IRebarOverlayZonePoint } from '@profis-engineering/c2c-gl-model/components/base-rebar-helper';
import { IZone } from '@profis-engineering/c2c-gl-model/components/zones-c2c';
import { DesignC2C } from '../../../../shared/entities/design-c2c';

import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { IModelC2C } from '@profis-engineering/c2c-gl-model/gl-model';

interface ZoneValues {
    static: number;
    dynamic: number;
}
export class ZonesC2CUpdate extends BaseUpdate {

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged()
    private OnPropertiesChange(model: IModelC2C, design: DesignC2C) {
        if (design.designData.reportDataC2C != null && model.zoneModel && model.rebarTop) {
            model.zoneModel.edgeArea = design.designData.reportDataC2C.zonesEdgeArea;

            model.rebarTop.points = new Array<IRebarOverlayZonePoint>();
            model.zoneModel.computedZones = [];
            if (design.designData.reportDataC2C.zones != null) {
                for (let i = 0; i < design.designData.reportDataC2C.zones.length; i++) {
                    if (model.zoneModel.computedZones[i] == null) {
                        model.zoneModel.computedZones[i] = {} as IZone;
                    }

                    const zonePropertyValue = this.getZonePropertyValue(design.designData.reportDataC2C.zones[i].name, design);

                    model.zoneModel.computedZones[i].stress = zonePropertyValue.static;
                    model.zoneModel.computedZones[i].stressDynamic = zonePropertyValue.dynamic;

                    model.zoneModel.computedZones[i].name = design.designData.reportDataC2C.zones[i].name;
                    model.zoneModel.computedZones[i].width = design.designData.reportDataC2C.zones[i].width;
                    model.zoneModel.computedZones[i].length = design.designData.reportDataC2C.zones[i].length;
                    model.zoneModel.computedZones[i].isPerimeterZone = design.designData.reportDataC2C.zones[i].isPerimeterZone;
                    model.zoneModel.computedZones[i].positionX = design.designData.reportDataC2C.zones[i].position.x;
                    model.zoneModel.computedZones[i].positionY = design.designData.reportDataC2C.zones[i].position.y;

                    design.designData.reportDataC2C.zones[i].connectorPositions.forEach(connector => {
                        model.rebarTop?.points?.push({
                            position: new Vector2(connector.x, connector.y),
                            associatedZone: connector.associatedZone,
                            isEdgeArea: connector.isEdgeArea
                        });
                    });
                }
            }
        }
    }

    private getZonePropertyValue(zoneName: string, design: DesignC2C): ZoneValues {
        switch (zoneName) {
            case 'Z1':
            case 'Z1\'':
                return {
                    static: design.model[UIProperty.Loads_C2C_Zone1] as number,
                    dynamic: design.model[UIProperty.Loads_C2C_DynamicZone1] as number
                };
            case 'Z2':
            case 'Z2\'':
                return {
                    static: design.model[UIProperty.Loads_C2C_Zone2] as number,
                    dynamic: design.model[UIProperty.Loads_C2C_DynamicZone2] as number
                };
            case 'Z3':
            case 'Z3\'':
                return {
                    static: design.model[UIProperty.Loads_C2C_Zone3] as number,
                    dynamic: design.model[UIProperty.Loads_C2C_DynamicZone3] as number
                };
            case 'Z4':
            case 'Z4\'':
                return {
                    static: design.model[UIProperty.Loads_C2C_Zone4] as number,
                    dynamic: design.model[UIProperty.Loads_C2C_DynamicZone4] as number
                };
            default:
                throw new Error('Unknown zone name');
        }
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Zones_NumberOfZones)
    private Overlay_Zones_NumberOfZones(value: number, model: IModelC2C) {
        if (!model.zoneModel) {
            throw new Error('model zoneModel is undefined');
        }
        model.zoneModel.numberOfZones = value;
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Zones_Symmetric)
    private Overlay_Zones_Symmetric(value: boolean, model: IModelC2C) {
        if (!model.zoneModel) {
            throw new Error('model zoneModel is undefined');
        }
        model.zoneModel.symmetric = value;
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Zones_ConsideringEdgeArea)
    private Overlay_Zones_ConsiderEdgeArea(value: boolean, model: IModelC2C) {
        if (!model.zoneModel) {
            throw new Error('model zoneModel is undefined');
        }
        model.zoneModel.considerEdgeArea = value;
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Zones_Z1Width)
    private Overlay_Zones_1Width(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[0].width = value;
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Zones_Z2Width)
    private Overlay_Zones_2Width(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[1].width = value;
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Zones_Z3Width)
    private Overlay_Zones_3Width(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[2].width = value;
    }

    @propertyValueChanged(UIProperty.Overlay_C2C_Zones_Z4Width)
    private Overlay_Zones_4Width(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[3].width = value;
    }

    @propertyValueChanged(UIProperty.Loads_C2C_Zone1)
    private Loads_C2C_Zone1Stress(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[0].stress = value;

        this.setEdgeZonesStress('Z1', value, model);
    }

    @propertyValueChanged(UIProperty.Loads_C2C_Zone2)
    private Loads_C2C_Zone2Stress(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[1].stress = value;

        this.setEdgeZonesStress('Z2', value, model);
    }

    @propertyValueChanged(UIProperty.Loads_C2C_Zone3)
    private Loads_C2C_Zone3Stress(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[2].stress = value;

        this.setEdgeZonesStress('Z3', value, model);
    }

    @propertyValueChanged(UIProperty.Loads_C2C_Zone4)
    private Loads_C2C_Zone4Stress(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[3].stress = value;

        this.setEdgeZonesStress('Z4', value, model);
    }

    @propertyValueChanged(UIProperty.Loads_C2C_DynamicZone1)
    private Loads_C2C_Zone1StressDynamic(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[0].stressDynamic = value;

        this.setEdgeZonesDynamicStress('Z1', value, model);
    }

    @propertyValueChanged(UIProperty.Loads_C2C_DynamicZone2)
    private Loads_C2C_Zone2StressDynamic(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[1].stressDynamic = value;

        this.setEdgeZonesDynamicStress('Z2', value, model);
    }

    @propertyValueChanged(UIProperty.Loads_C2C_DynamicZone3)
    private Loads_C2C_Zone3StressDynamic(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[2].stressDynamic = value;

        this.setEdgeZonesDynamicStress('Z3', value, model);
    }

    @propertyValueChanged(UIProperty.Loads_C2C_DynamicZone4)
    private Loads_C2C_Zone4StressDynamic(value: number, model: IModelC2C) {
        if (!model.zoneModel?.zones) {
            throw new Error('model zoneModel zones is undefined');
        }
        model.zoneModel.zones[3].stressDynamic = value;

        this.setEdgeZonesDynamicStress('Z4', value, model);
    }

    private setEdgeZonesDynamicStress(edgeZoneName: string, load: number, model: IModelC2C) {
        if (!model.zoneModel) {
            throw new Error('model zoneModel is undefined');
        }
        const edgeZones = model.zoneModel.computedZones?.filter(x => x.name.includes(edgeZoneName));
        edgeZones?.forEach(edgeZone => {
            edgeZone.stressDynamic = load;
        });
    }

    private setEdgeZonesStress(edgeZoneName: string, load: number, model: IModelC2C) {
        if (!model.zoneModel) {
            throw new Error('model zoneModel is undefined');
        }
        const edgeZones = model.zoneModel.computedZones?.filter(x => x.name.includes(edgeZoneName));
        edgeZones?.forEach(edgeZone => {
            edgeZone.stress = load;
        });
    }
}
