<div id="resize-info-section" class="info-section box-section box-section-white"
    [class.openedPosition]="!collapsed">
    <!--
        Note: change this value if CSS is changed!
        correction: accomodates for target element's margins and other fixes
    -->
    <pe-resizer
        [disabled]="productHidden || collapsed"
        [targetHtmlElement]="infoSectionContainer"
        [parentHtmlElement]="parentElement"
        [correction]="-16"
        (sizeChange)="onResize()">
        <div *ngIf="design != null" id="info-section-tour" class="box-section-header">
            <span class="info-section-header-collapsed-anchor-image pe-ui-c2c-sprite pe-ui-c2c-sprite-rebar-icon"></span>

            <span class="box-section-title"
                *ngIf="!collapsed"
                [innerHtml]="translate(design.globalMetaProperties.infoSectionTranslationKey)"></span>

            <div class="info-section-header-collapsed"
                [class.info-section-shortcuts-hidden]="!collapsed">
                <div class="info-section-header-collapsed-anchor-name" [ngbTooltip]="productName" [innerHtml]="productName"></div>
            </div>

            <pe-collapse
                [container]="'box-section-content'"
                [collapsed]="collapsed"
                (collapsedChange)="onInfoSectionCollapse($any($event).detail)">
            </pe-collapse>
        </div>

        <div *ngIf="design != null" class="box-section-content">
            <div class="info-section-anchor-detailed info-section-region">
                <div id="info-section-content" class="info-section-region-content">
                    <div class="info-section-calculate">
                        <pe-button
                            [id]="'rebar-portfolio-filter-button'"
                            class="button button-icon"
                            [look]="'DefaultIcon'"
                            [disabled]="design.isReadOnlyDesignMode"
                            (buttonClick)="openSelectProduct()"
                            [ngbTooltip]="filterButtonTooltip">
                            <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-filter"></span>
                        </pe-button>
                    </div>

                    <div class="info-section-active-anchor-filters-title" *ngIf="displayedFilters.length > 3" [innerHtml]="filtersTitle"></div>

                    <div *ngIf="displayedFilters.length > 3"
                        class="design-right-collapse-container"
                        [class.opened]="filtersOpened">
                        <span class="design-right-collapse-line"></span>

                        <button
                            type="button"
                            class="design-right-collapse-button"
                            (click)="filtersOpened = !filtersOpened">
                            <span class="pe-ui-c2c-sprite" [ngClass]="filtersOpened ? 'pe-ui-c2c-sprite-arrow-up' : 'pe-ui-c2c-sprite-arrow-down'"></span>
                        </button>

                        <span class="design-right-collapse-line"></span>
                    </div>

                    <div class="info-section-anchor-filters"
                        [class.max-show-filters]="displayedFilters.length > 5"
                        *ngIf="displayedFilters.length > 0 && (filtersOpened || displayedFilters.length < 4)">
                        <div class="info-section-anchor-filter-container"
                            *ngFor="let filter of displayedFilters; trackBy: trackFilterById">
                            <div class="info-section-anchor-filter-remove-button">
                                <div class="info-section-anchor-filter-remove-button-container">
                                    <button
                                        type="button"
                                        class="info-section-anchor-filter-remove-button"
                                        [disabled]="design.isReadOnlyDesignMode"
                                        (click)="removeProductFilter(filter.id)">
                                        <span class="pe-ui-c2c-sprite sprite-responsive pe-ui-c2c-sprite-x"></span>
                                    </button>

                                    <div class="line-seperator"></div>

                                    <div class="info-section-anchor-detailed-anchor-name" [innerHtml]="translate(filter.title)"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- rebars -->
                    <div class="info-section-anchor-detailed-anchors" id="rebar-portfolio-rebarlist-section"
                        *ngIf="!productHidden && rebars != null && rebars.length > 0">
                        <div class="info-section-anchor-detailed-anchor info-section-anchor-detailed-down-anchor"
                            *ngFor="let rebar of rebars; trackBy: trackRebarById">
                            <div [id]="'info-section-select-rebar-' + rebar.id + '-row'"
                                class="info-section-anchor-detailed-anchor-select-button"
                                [class.active]="rebar.id == getProductFamilyId()"
                                [attr.disabled]="design.isReadOnlyDesignMode ? true : null"
                                (click)="setProduct(rebar.id)">
                                <div class="info-section-anchor-detailed-anchor-select-button-container">
                                    <div class="info-section-anchor-detailed-anchor-image">
                                        <div class="no-image-container">
                                            <span
                                                class="no-image pe-ui-c2c-sprite sprite-responsive pe-ui-c2c-sprite-anchor-family-no-photo-available"></span>
                                        </div>
                                        <span class="pe-ui-c2c-sprite sprite-responsive" [ngClass]="'pe-ui-c2c-' + rebar.image"></span>
                                    </div>

                                    <div class="line-seperator"></div>

                                    <div class="info-section-anchor-detailed-anchor-name" [innerHtml]="rebar.name"></div>

                                    <span class="tag" *ngIf="!rebar.tested" [innerHtml]="shortTagC2C(rebar.tested)"></span>

                                    <button
                                        type="button"
                                        class="info-section-anchor-detailed-anchor-favorite"
                                        (click)="favoriteToggle(rebar.id); $event.preventDefault(); $any($event).stopPropagation()"
                                        [ngbTooltip]="favoriteTooltip">
                                        <span class="pe-ui-c2c-sprite" [ngClass]="getFavoriteSpriteRebar(rebar.id)"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="info-section-anchor-detailed-type-size-embedment-depth-container" *ngIf="design.isC2CHNA && !design.globalMetaProperties.isConcreteOverlay">
                        <div class="info-section-anchor-detailed-type-size-container">
                            <div class="info-section-anchor-detailed-type"
                                *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_RebarType.id).hidden">
                                <pe-dropdown
                                    [id]="'rebar-product-type-dropdown'"
                                    [title]="rebarTypeDropdown.title"
                                    [tooltip]="rebarTypeDropdown.tooltip"
                                    [tags]="selectedRebarTag('type')"
                                    [items]="rebarTypeDropdown.items"
                                    [selectedValue]="design.model[propertyMetaDataEnumC2C.Product_C2C_RebarType.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_RebarType.id] = $any($event).detail; runCalculation()">
                                </pe-dropdown>
                            </div>

                            <div class="info-section-anchor-detailed-type"
                                *ngIf="!design.properties.get(propertyMetaDataEnumC2C.ExistingStructure_C2C_Product_RebarStandard.id).hidden">
                                <pe-dropdown
                                    [id]="'rebar-product-standard-dropdown'"
                                    [title]="rebarStandardDropdown.title"
                                    [tooltip]="rebarStandardDropdown.tooltip"
                                    [items]="rebarStandardDropdown.items"
                                    [selectedValue]="design.model[propertyMetaDataEnumC2C.ExistingStructure_C2C_Product_RebarStandard.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnumC2C.ExistingStructure_C2C_Product_RebarStandard.id] = $any($event).detail; runCalculation()">
                                </pe-dropdown>
                            </div>

                            <div class="info-section-anchor-detailed-type"
                                *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_RebarSize.id).hidden">
                                <pe-dropdown
                                    [id]="'rebar-product-size-dropdown'"
                                    [title]="rebarSizeDropdown.title"
                                    [tooltip]="rebarSizeDropdown.tooltip"
                                    [tags]="selectedRebarTag('size')"
                                    [items]="rebarSizeDropdown.items"
                                    [selectedValue]="design.model[propertyMetaDataEnumC2C.Product_C2C_RebarSize.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_RebarSize.id] = $any($event).detail; runCalculation()">
                                </pe-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="info-section-anchor-detailed-type-size-embedment-depth-container"
                        *ngIf="(rebars != null && rebars.length > 0) || !productHidden">
                        <!--concrete overlay-->
                        <div class="info-section-anchor-detailed-type-size-container"
                            *ngIf="design.globalMetaProperties.isConcreteOverlay">
                            <div class="info-section-anchor-detailed-type"
                                *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorType.id).hidden">
                                <pe-dropdown
                                    [id]="'product-portfolio-type-dropdown'"
                                    [title]="typeConcreteDropdown.title"
                                    [tooltip]="typeConcreteDropdown.tooltip"
                                    [items]="typeConcreteDropdown.items"
                                    [tags]="typeConcreteDropdownTags"
                                    [selectedValue]="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorType.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorType.id] = $any($event).detail; runCalculation()">
                                </pe-dropdown>
                            </div>

                            <div class="info-section-anchor-detailed-type"
                                *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Overlay_C2C_Product_RebarStandard.id).hidden">
                                <pe-dropdown
                                    [id]="'product-portfolio-standard-dropdown'"
                                    [title]="rebarStandardDropdown.title"
                                    [tooltip]="rebarStandardDropdown.tooltip"
                                    [items]="rebarStandardDropdown.items"
                                    [selectedValue]="design.model[propertyMetaDataEnumC2C.Overlay_C2C_Product_RebarStandard.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnumC2C.Overlay_C2C_Product_RebarStandard.id] = $any($event).detail; runCalculation()">
                                </pe-dropdown>
                            </div>

                            <div class="info-section-anchor-detailed-type"
                                *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorSize.id).hidden">
                                <pe-dropdown
                                    [id]="'product-portfolio-size-dropdown'"
                                    [title]="sizeConcreteDropdown.title"
                                    [tooltip]="sizeConcreteDropdown.tooltip"
                                    [items]="sizeConcreteDropdown.items"
                                    [tags]="sizeConcreteDropdownTags"
                                    [selectedValue]="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorSize.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorSize.id] = $any($event).detail; runCalculation()"
                                    [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorSize.id).disabled">
                                </pe-dropdown>
                            </div>

                            <div class="info-section-anchor-detailed-type"
                                *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_AnchorLength.id).hidden">
                                <pe-dropdown
                                    [id]="'product-portfolio-anchor-length-dropdown'"
                                    [title]="anchorLengthDropdown.title"
                                    [items]="anchorLengthDropdown.items"
                                    [selectedValue]="design.model[propertyMetaDataEnumC2C.Product_C2C_AnchorLength.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_AnchorLength.id] = $any($event).detail; runCalculation()"
                                    [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_AnchorLength.id).disabled">
                                </pe-dropdown>
                            </div>

                            <pe-button
                                *ngIf="!approvalProperty.hidden"
                                [id]="'connector-viewetaapproval-button'"
                                data-control-id="connector-viewetaapproval-button"
                                class="button view-approval"
                                [look]="'Default'"
                                width="100%"
                                [text]="translate(viewApprovalTranslationKey)"
                                (buttonClick)="openApproval()"
                                [disabled]="approvalProperty.disabled">
                            </pe-button>

                            <pe-button
                                *ngIf="!uktaApprovalProperty.hidden"
                                [id]="'connector-viewuktaapproval-button'"
                                data-control-id="connector-viewuktaapproval-button"
                                class="button view-approval ukta-approval"
                                [look]="'Default'"
                                width="100%"
                                [text]="translate(viewUKTAApprovalTranslationKey)"
                                (buttonClick)="openUKTAApproval()"
                                [disabled]="uktaApprovalProperty.disabled">
                            </pe-button>

                            <div class="info-section-anchor-detailed-article-number-container">
                                <div class="info-section-anchor-detailed-article-number-container-element"
                                    *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_FastenerItem.id).hidden">
                                    <div class="info-section-anchor-detailed-article-number-content">
                                        <pe-label
                                            [id]="'product-portfolio-fastenerroditem-number'"
                                            [title]="mechArticleNumberLabelC2C"
                                            [text]="design.model[propertyMetaDataEnumC2C.Product_C2C_FastenerItem.id]">
                                        </pe-label>
                                    </div>
                                </div>

                                <div class="info-section-anchor-detailed-article-number-container-element"
                                    *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorNumber.id).hidden">
                                    <div class="info-section-anchor-detailed-article-number-content">
                                        <pe-label
                                            [id]="'product-portfolio-anchoritem-number'"
                                            [title]="translate('Agito.Hilti.C2C.Navigation.TabProduct.Rebar.ArticleNumberChemical.Tooltip.Title')"
                                            [text]="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorNumber.id]">
                                        </pe-label>
                                    </div>
                                </div>
                            </div>

                            <div class="info-section-anchor-detailed-embedment-depth-container">
                                <label class="info-section-anchor-detailed-article-number-title control-label"
                                    *ngIf="!connectorEmbedmentDepthTextboxHidden || !connectorEmbedmentDepthDropdownHidden || !connectorEmbedmentDepthTextboxWithFlagHidden || !connectorEmbedmentDepthDropdownWithFlagHidden"
                                    [innerHtml]="translate('Agito.Hilti.C2C.Connector.EmbedmentDepth.Title')">
                                </label>

                                <div class="info-section-anchor-detailed-embedment-depth-content">
                                     <pe-toggle-button-group
                                        class="info-section-anchor-detailed-embedment-depth-type"
                                        *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthMode.id).hidden"
                                        [id]="embedmentDepthTypeConcreteToggleButtonGroup.id"
                                        [items]="embedmentDepthTypeConcreteToggleButtonGroup.items"
                                        [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthMode.id).disabled"
                                        [model]="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthMode.id]"
                                        (modelChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthMode.id] = $any($event).detail; runCalculation()">
                                    </pe-toggle-button-group>

                                    <pe-numeric-text-box
                                        class="info-section-anchor-detailed-embedment-depth-value-input"
                                        *ngIf="!connectorEmbedmentDepthTextboxHidden"
                                        [id]="'product-portfolio-embedment-depth-textbox'"
                                        [value]="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepth.id]"
                                        (valueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepth.id] = $any($event).detail; runCalculation()"
                                        [unit]="design.unitLength"
                                        [updateOnBlur]="true"
                                        [debounceStepperChanges]="true"
                                        [required]="true"
                                        [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepth.id).disabled"
                                        [precision]="getPrecision(propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepth.id)">
                                    </pe-numeric-text-box>

                                    <pe-toggle-button-group
                                        class="info-section-anchor-detailed-embedment-depth-type"
                                        *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthMode.id).hidden"
                                        [id]="embedmentDepthTypeConcreteToggleButtonGroup.id"
                                        [items]="embedmentDepthTypeConcreteToggleButtonGroup.items"
                                        [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthMode.id).disabled"
                                        [model]="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthMode.id]"
                                        (modelChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthMode.id] = $any($event).detail; runCalculation()">
                                    </pe-toggle-button-group>

                                    <pe-numeric-text-box
                                        class="info-section-anchor-detailed-embedment-depth-value-input"
                                        *ngIf="!connectorEmbedmentDepthTextboxWithFlagHidden"
                                        [id]="'product-portfolio-embedment-depth-textbox'"
                                        [value]="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthExisting.id]"
                                        (valueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthExisting.id] = $any($event).detail; runCalculation()"
                                        [unit]="design.unitLength"
                                        [updateOnBlur]="true"
                                        [debounceStepperChanges]="true"
                                        [required]="true"
                                        [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthExisting.id).disabled"
                                        [precision]="getPrecision(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthExisting.id)">
                                    </pe-numeric-text-box>

                                    <pe-dropdown
                                        class="info-section-anchor-detailed-embedment-depth-value-dropdown"
                                        *ngIf="!connectorEmbedmentDepthDropdownHidden"
                                        [id]="'product-portfolio-connector-embedmentdepth-dropdown'"
                                        [notSelectedText]="embedmentDepthValueDropdownC2C.notSelectedText"
                                        [items]="embedmentDepthValueDropdownC2C.items"
                                        [openUp]="true"
                                        [selectedValue]="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthSelection.id]"
                                        (selectedValueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthSelection.id] = $any($event).detail; runCalculation()"
                                        [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthSelection.id).disabled">
                                    </pe-dropdown>

                                    <pe-dropdown
                                        class="info-section-anchor-detailed-embedment-depth-value-dropdown"
                                        *ngIf="!connectorEmbedmentDepthDropdownWithFlagHidden"
                                        [id]="'product-portfolio-connector-embedmentdepth-dropdown'"
                                        [notSelectedText]="embedmentDepthValueDropdownC2C.notSelectedText"
                                        [items]="embedmentDepthValueDropdownC2C.items"
                                        [openUp]="true"
                                        [selectedValue]="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthExistingSelection.id]"
                                        (selectedValueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthExistingSelection.id] = $any($event).detail; runCalculation()"
                                        [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthExistingSelection.id).disabled">
                                    </pe-dropdown>
                                </div>

                                <div class="info-section-anchor-detailed-embedment-depth-new-content">

                                    <pe-numeric-text-box
                                        class="info-section-anchor-detailed-embedment-depth-new-value-input"
                                        *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthNew.id).hidden"
                                        [id]="'product-portfolio-embedment-depth-new'"
                                        [title]="translate('Agito.Hilti.C2C.Connector.EmbedmentDepthNew.Title')"
                                        [value]="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthNew.id]"
                                        (valueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthNew.id] = $any($event).detail; runCalculation()"
                                        [unit]="design.unitLength"
                                        [updateOnBlur]="true"
                                        [debounceStepperChanges]="true"
                                        [required]="true"
                                        [precision]="getPrecision(propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthNew.id)"
                                        [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_ConnectorEmbedmentDepthNew.id).disabled">
                                    </pe-numeric-text-box>

                                    <pe-numeric-text-box
                                        class="info-section-anchor-detailed-embedment-depth-new-value-input"
                                        *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthNew.id).hidden"
                                        [id]="'product-portfolio-embedment-depth-new'"
                                        [title]="translate('Agito.Hilti.C2C.Connector.EmbedmentDepthNew.Title')"
                                        [value]="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthNew.id]"
                                        (valueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthNew.id] = $any($event).detail; runCalculation()"
                                        [unit]="design.unitLength"
                                        [updateOnBlur]="true"
                                        [debounceStepperChanges]="true"
                                        [required]="true"
                                        [precision]="getPrecision(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthNew.id)"
                                        [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthNew.id).disabled">
                                    </pe-numeric-text-box>

                                    <pe-dropdown
                                        class="info-section-anchor-detailed-embedment-depth-value-dropdown"
                                        *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthNewSelection.id).hidden"
                                        [id]="'product-portfolio-connector-embedmentdepth-overlay-dropdown'"
                                        [title]="translate('Agito.Hilti.C2C.Connector.EmbedmentDepthNew.Title')"
                                        [notSelectedText]="embedmentDepthNewValueDropdownC2C.notSelectedText"
                                        [items]="embedmentDepthNewValueDropdownC2C.items"
                                        [openUp]="true"
                                        [selectedValue]="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthNewSelection.id]"
                                        (selectedValueChange)="design.model[propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthNewSelection.id] = $any($event).detail; runCalculation()"
                                        [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_OverlayEmbedmentDepthNewSelection.id).disabled">
                                    </pe-dropdown>

                                </div>
                            </div>
                        </div>

                        <pe-button
                            *ngIf="showApprovalButton"
                            [id]="'rebar-portfolio-viewetaapproval-button'"
                            class="button view-approval"
                            [look]="'Default'"
                            width="100%"
                            [text]="translate(viewApprovalTranslationKey)"
                            (buttonClick)="openApproval()"
                            [disabled]="approvalProperty.disabled">
                        </pe-button>

                        <pe-button
                            *ngIf="showUKTAApprovalButton"
                            [id]="'rebar-portfolio-viewuktaapproval-button'"
                            class="button view-approval ukta-approval"
                            [look]="'Default'"
                            width="100%"
                            [text]="translate(viewUKTAApprovalTranslationKey)"
                            (buttonClick)="openUKTAApproval()"
                            [disabled]="uktaApprovalProperty.disabled">
                        </pe-button>

                        <div class="info-section-anchor-detailed-article-number-container">
                            <div class="info-section-anchor-detailed-pir-eu-article-number-content"
                                *ngIf="!design.properties.get(propertyMetaDataEnumC2C.Product_C2C_RebarNumber.id).hidden">
                                <pe-label
                                    [id]="'pir-eu-adhesiveitem-number'"
                                    [title]="translate('Agito.Hilti.C2C.Navigation.TabProduct.Rebar.ArticleNumberChemical.Tooltip.Title')"
                                    [text]="design.model[propertyMetaDataEnumC2C.Product_C2C_RebarNumber.id]"
                                    [disabled]="design.properties.get(propertyMetaDataEnumC2C.Product_C2C_RebarNumber.id).disabled"
                                    [tooltip]="articleNumberMechanicalTooltip">
                                </pe-label>
                            </div>
                        </div>
                    </div>
                    <div class="info-section-space-filler"></div>
                </div>
            </div>
        </div>
    </pe-resizer>
</div>
