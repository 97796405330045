import overlay from './overlay.png';
import splices from './splices.png';
import structuralJoints from './structural-joints.png';

const images = {
    'overlay.png': overlay,
    'splices.png': splices,
    'structural-joints.png': structuralJoints
} satisfies Record<string, string>;

export default images as typeof images & { [imageName: string]: string };
