<div id="utilizations-panel" class="utilizations box-section box-section-white" *ngIf="this.utilizationsPanelAvailable">
    <ng-container *ngFor="let group of groups; let panelIndex = index">
        <div *ngIf="group.visible" id="{{ group.id }}-panel" class="utilizations box-section box-section-white { 'invalid-value': !getWorstValue(group).status }">
            <div class="box-section-header">
                <div class="box-section-title drag-handle-static utilizaion-title-wrapper">
                    <div class="image" *ngIf="group.image" id="{{ group.id }}-image">
                        <div class="pe-ui-c2c-sprite" [class]="'pe-ui-c2c-'+group.image"></div>
                    </div>
                    <span [ngbTooltip]="l10n(group.tooltip)" class="bar-title" id="{{group.id}}-header">
                        <span>{{ l10n(group.title) }}
                            <span *ngIf="group.titleSuffix" class="utilizaion-title-suffix" [innerHTML]="group.titleSuffix"></span>
                        </span>
                        <button type="button" (click)="infoPopup(group)" *ngIf="showInfoPopup(group)" class="info-button pe-ui-c2c-sprite pe-ui-c2c-sprite-info-tooltip"></button>
                    </span>
                </div>
                <div class="utilization-combination bar-length" *ngIf="getWorstValue(group) != null && showProgressBar($any(group)) && group.collapsed"
                    [class]="{ 'utilization-combination-hidden': !group.collapsed, 'invalid': !getWorstValue(group)?.status }">
                        <div class="utilization-combination-percent" [style.width.%]="getLoadWidth(group)"></div>
                        <div class="utilization-combination-value" id="{{ group.id }}-percentage">
                            {{ getWorstValue(group)?.percentage }}
                        </div>
                </div>

                <pe-collapse
                    [id]="getCollapseComponentId(group.id)"
                    [collapsed]="group.collapsed"
                    (collapsedChange)="onCollapseChange($any($event).detail, group)">
                </pe-collapse>
            </div>
            <ng-container *ngIf="!group.collapsed">
                <div class="items box-section-content">
                    <ng-container *ngFor="let item of group.items">
                        <c2c-utilization-item
                            *ngIf="item.visible"
                            [title]="l10n(item.title)"
                            [tooltip]="l10n(item.tooltip)"
                            [header]="l10n(item.imageTitle)"
                            [showPercentBar]="showProgressBar(item)"
                            [dataInvalid]="!item.data?.status"
                            [barInvalid]="!item.data?.status"
                            [image]="item.image"
                            [percentValue]="item.data?.value"
                            [value]="getItemValue(item)"
                            [controlId]="item.id"
                            [showNumberContainer]="item.property || item.propertyUi">
                        </c2c-utilization-item>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>