<div class="box-section" *ngIf="dataLoaded">
    <div class="box-section-header">
        <span class="box-section-title" [innerHtml]="translate('Agito.Hilti.C2C.ZoneAnalysis.Title')"></span>

        <pe-collapse [id]="'zones-analysis-diagram-collapse-button'" [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>
    <div class="box-section-content">
        <div class="zone-analysis-diagram-container">
            <ng-template #tipContent let-zoneValue="zoneValue">
                <div [innerHTML]="zoneValue"></div>
            </ng-template>
            <svg width="230" height="200" id="zoneanalysis-diagram" style="transform-origin: top left;"
                xmlns="http://www.w3.org/2000/svg">
                <!-- asymmetric -->
                <line *ngIf="!isSymmetric" id="horizontal-line-asymmetric" x1="6" y1="110" x2="167" y2="110"
                    class="zone-asymmetric" style="stroke:black;stroke-width: 1.5px;" />
                <line *ngIf="!isSymmetric" id="vertical-line-asymmetric" x1="6" y1="20" x2="6" y2="200"
                    class="zone-asymmetric" style="stroke:black;stroke-width: 1.5px;" />
                <ng-container *ngIf="!isSymmetric">
                    <rect *ngFor="let data of analysisDataDisplay.display" [attr.x]="data.x" [attr.y]="data.y"
                        [attr.width]="data.width" [attr.height]="data.height" class="zone-chart"
                        [style.opacity]="data.shading" [attr.id]="data.elementId" [ngbTooltip]="tipContent"
                        #tooltip="ngbTooltip"
                        [placement]="tooltipPlacement(data.isNegative)"
                        (mouseenter)="toggleZoneTooltip(tooltip, getTooltip(true, data))"
                        (mouseleave)="toggleZoneTooltip(tooltip)" [ngStyle]="fillColor(data)" />
                </ng-container>
                <ng-container *ngIf="!isSymmetric && isFatigue">
                    <rect *ngFor="let data of analysisDataDisplay.display" [attr.x]="data.x" [attr.y]="data.yDynamic"
                        [attr.width]="data.width" [attr.height]="data.heightDynamic" class="zone-chart-dynamic"
                        [style.opacity]="data.shading" [id]="data.elementIdDynamic" [ngbTooltip]="tipContent"
                        #tooltip="ngbTooltip"
                        [placement]="tooltipPlacement(data.isNegativeDynamic)"
                        (mouseenter)="toggleZoneTooltip(tooltip, getTooltip(false, data))"
                        (mouseleave)="toggleZoneTooltip(tooltip)" style="fill: #671C3E" />
                </ng-container>
                <text x="0" y="12" *ngIf="!isSymmetric" style="font-family: Arial, Helvetica, sans-serif;"
                    id="unit-stress-asymmetric">
                    <tspan class="zone-label-larger"
                        style="font-family:'Times New Roman', math, sans-serif;font-size: 18px;">
                        {{isShearStress?'&tau;':'V'}}</tspan>
                    <tspan class="zone-label-sub" dx="-2" dy="2" style="font-size: 7px;">Ed</tspan>
                    <tspan dy="-2" style="font-size: 12px"> [{{ stressUnitName }}]</tspan>
                </text>

                <!-- symmetric -->
                <line *ngIf="isSymmetric" id="horizontal-line-symmetric" x1="6" y1="110" x2="175" y2="110"
                    class="zone-asymmetric" style="stroke:black;stroke-width: 1.5px;" />
                <line *ngIf="isSymmetric" id="vertical-line-symmetric" x1="90" y1="20" x2="90" y2="200"
                    style="stroke:black;stroke-width: 1.5px; stroke-width: 1.2px;stroke-dasharray: 5 5;" />
                <ng-container *ngIf="isSymmetric">
                    <rect *ngFor="let data of analysisDataDisplay.display" [attr.x]="data.x" [attr.y]="data.y"
                        [attr.width]="data.width" [attr.height]="data.height" class="zone-chart"
                        [style.opacity]="data.shading" [attr.id]="data.elementId" [ngbTooltip]="tipContent"
                        #tooltip="ngbTooltip" [ngStyle]="fillColor(data)"
                        [placement]="tooltipPlacement(data.isNegative)"
                        (mouseenter)="toggleZoneTooltip(tooltip, getTooltip(true, data))"
                        (mouseleave)="toggleZoneTooltip(tooltip)" />
                </ng-container>
                <ng-container *ngIf="isSymmetric && isFatigue">
                    <rect *ngFor="let data of analysisDataDisplay.display" [attr.x]="data.x" [attr.y]="data.yDynamic"
                        [attr.width]="data.width" [attr.height]="data.heightDynamic" class="zone-chart-dynamic"
                        [style.opacity]="data.shading" [attr.id]="data.elementIdDynamic" [ngbTooltip]="tipContent"
                        #tooltip="ngbTooltip"
                        [placement]="tooltipPlacement(data.isNegativeDynamic)"
                        (mouseenter)="toggleZoneTooltip(tooltip, getTooltip(false, data))"
                        (mouseleave)="toggleZoneTooltip(tooltip)" style="fill: #671C3E;" />
                </ng-container>

                <text x="70" y="12" *ngIf="isSymmetric" style="font-family: Arial, Helvetica, sans-serif;"
                    id="unit-stress-symmetric">
                    <tspan class="zone-label-larger"
                        style="font-family:'Times New Roman', math, sans-serif, none;font-size: 18px;">
                        {{isShearStress?'&tau;':'V'}}</tspan>
                    <tspan class="zone-label-sub" dx="-2" dy="2" style="font-size: 7px;">Ed</tspan>
                    <tspan dy="-2" style="font-size: 12px"> [{{ stressUnitName }}]</tspan>
                </text>

                <!-- common labels -->
                <text x="178" y="110" id="unit-length">
                    <tspan style="font-size: 12px; font-family: Arial, Helvetica, sans-serif;">L [{{ lengthUnitName }}]
                    </tspan>
                </text>
                <text class="zone-label" style="font-size: 8px;" [attr.x]="dataLabel.x" [attr.y]="dataLabel.y"
                    *ngFor="let dataLabel of analysisDataDisplay.labels">
                    {{ dataLabel.label }}
                </text>
                <text class="zone-label" style="font-size: 8px;" [attr.x]="stressLabel.x"
                        [attr.y]="stressLabel.y" *ngFor="let stressLabel of analysisDataDisplay.stress">
                        <tspan *ngIf="isFatigue">{{ stressLabel.value }}</tspan>
                </text>
                <text class="zone-label" style="font-size: 8px;" [attr.x]="stressLabel.x"
                    [attr.y]="stressLabel.yDiagramHeightSum" *ngFor="let stressLabel of analysisDataDisplay.stress">
                    {{ parseNumber(stressLabel.yValueSum) }}
                </text>
            </svg>
        </div>
    </div>
</div>