import cloneDeep from 'lodash-es/cloneDeep';
import { DesignStandardRegionEntity } from '@profis-engineering/pe-ui-common/entities/code-lists/design-type';
import { CodeList, ICodeListC2CConstructor } from '../code-lists/code-list';
import { DesignStandardEntityC2C, DesignTypeEntityC2C } from '../../generated-modules/Hilti.PE.C2CCodeListService.Entities';

export interface IDesignTypeConstructor extends ICodeListC2CConstructor {
    isAllowedInDesignStandardSto?: boolean;
    allowedDesignStandardRegions: DesignStandardRegionEntity[];
    kbTooltip?: string;
}

export class DesignType extends CodeList {
    public allowedDesignStandardRegions?: DesignStandardRegionEntity[];

    constructor(codeList: IDesignTypeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.allowedDesignStandardRegions = cloneDeep(codeList.allowedDesignStandardRegions);
            this.kbNumberRegion = codeList.kbNumberRegion;
            this.kbTooltip = codeList.kbTooltip ?? undefined;
        }
    }

    public static fromService(codeList: DesignTypeEntityC2C, designStandards: DesignStandardEntityC2C[], regions: number[]) {
        const allowedDesignStandardRegions = regions.map((regionId) => (
            {
                RegionId: regionId,
                DesignStandards: designStandards.map((standard) => standard.id)
            } as DesignStandardRegionEntity)).flat();

        return new DesignType(CodeList.baseFromService<IDesignTypeConstructor>('DesignTypeEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey,
            image: codeList.image,
            allowedDesignStandardRegions: allowedDesignStandardRegions
        }));
    }

    public override cloneDeep() {
        const codeList: DesignType = super.cloneDeep() as DesignType;
        codeList.allowedDesignStandardRegions = cloneDeep(this.allowedDesignStandardRegions);

        return codeList;
    }
}
