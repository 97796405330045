import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    TrackDesignChangeRequest, TrackDesignCloseRequest, TrackOnOpenRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Entities';
import { TrackingDesignType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Enums';
import { UserDetails } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Tracking';
import { trySendingUsingFetch } from '@profis-engineering/pe-ui-common/helpers/browser';
import { DateWithTimezone, getJSONDateWithTimezoneOffset } from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { DesignStandard } from '../../shared/entities/code-lists/design-standard';
import { DesignType } from '../../shared/entities/code-lists/design-type';
import {
    DesignType as DesignTypeC2C, OnProjectCloseRequestC2C, ProjectOpenType, TrackOnProjectCloseRequestC2C,
    TrackOnProjectDesignOpenRequestC2C, TrackOnProjectTemplateOpenRequestC2C, UsageCounterC2C
} from '../../shared/entities/tracking-data';
import { TrackingUsageCounterC2C } from '../../shared/entities/tracking-usage-counter';
import { ProjectDesignEntityC2C } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { ConnectionType, DesignStandard as DesignStandardC2C } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { ApiService } from './api.service';
import { CommonCodeListService } from './common-code-list.service';
import { CommonTrackingService } from './common-tracking.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { UserService } from './user.service';

/**
 * proxy for tracking service that tracks design details
 * tracking service is responsible for:
 * - tracking details when new design is created or existing design is opened
 * - tracking details when design is closed
 */
@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(
        private apiService: ApiService,
        private commonTrackingService: CommonTrackingService,
        private sharedEnvironmentService: SharedEnvironmentService,
        private localizationService: LocalizationService,
        private userService: UserService,
        private commonCodeListService: CommonCodeListService,
        private loggerService: LoggerService
    ) { }


    public async saveTrackingActData(
        projectDesign: ProjectDesignEntityC2C,
        usageCounter: UsageCounterC2C,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId?: string,
        uiVersion?: string
    ): Promise<void> {
        usageCounter.dateClosed = new Date();

        //TODO C2C Team: seperate template tracking from design tracking.
        const endpoint = designId ? 'TrackOnProjectDesignChange' : 'TrackOnProjectTemplateChange';
        const url = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}Tracking/${endpoint}`;
        const trackingData = this.getDesignTrackingData(projectDesign, usageCounter, projectOpenType, designId, createdFromTemplate, templateId ?? '', uiVersion ?? '');

        const data: TrackDesignChangeRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            DesignId: designId,
            TemplateId: templateId
        };

        const request = new HttpRequest<TrackDesignChangeRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }


    public async trackOnTemplateOpen(templateId: string, designStandard: DesignStandardC2C, connectionType: ConnectionType): Promise<void> {
        const date = getJSONDateWithTimezoneOffset();
        const url = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}Tracking/TrackOnProjectTemplateOpen`;
        const trackingData = this.getTrackOnProjectTemplateOpenRequest(templateId, date, designStandard, connectionType);

        const data: TrackOnOpenRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            TemplateId: templateId
        };

        const request = new HttpRequest<TrackOnOpenRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnTemplateClose(
        projectDesign: ProjectDesignEntityC2C,
        usageCounter: UsageCounterC2C,
        projectOpenType: ProjectOpenType,
        createdFromTemplate: boolean,
        templateId: string,
        uiVersion: string
    ): Promise<void> {
        const url = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}Tracking/TrackOnProjectTemplateClose`;
        const trackingData = this.getDesignTrackingData(projectDesign, usageCounter, projectOpenType, '', createdFromTemplate, templateId, uiVersion);

        const data: TrackDesignCloseRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            TemplateId: templateId
        };

        const request = new HttpRequest<TrackDesignCloseRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }


    public async trackOnDesignOpen(designId: string, connectionType: ConnectionType, uiVersion: string, designType?: DesignType, designStandard?: DesignStandard): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}Tracking/TrackOnProjectDesignOpen`;

        const designStandardC2C = designStandard?.id as DesignStandardC2C;
        const designTypeC2C = designType?.id as DesignTypeC2C;

        const trackingData: TrackOnProjectDesignOpenRequestC2C = {
            connectionType,
            designId,
            designStandard: designStandardC2C,
            designType: designTypeC2C,
            timestamp: date.timestamp as any,
            userDetails,
            uiVersion
        };

        const data: TrackOnOpenRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            DesignId: designId
        };

        const request = new HttpRequest<TrackOnOpenRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnDesignClose(
        projectDesign: ProjectDesignEntityC2C,
        usageCounter: UsageCounterC2C,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string,
        uiVersion: string
    ): Promise<void> {
        usageCounter.dateClosed = new Date();

        const url = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}Tracking/TrackOnProjectDesignClose`;
        const trackingData = this.getDesignTrackingData(projectDesign, usageCounter, projectOpenType, designId, createdFromTemplate, templateId, uiVersion);

        const data: TrackDesignCloseRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            DesignId: designId
        };

        const request = new HttpRequest<TrackDesignCloseRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }


    public async handleDesignBrowserUnload(
        sessionKey: string,
        projectDesign: ProjectDesignEntityC2C,
        usageCounter: TrackingUsageCounterC2C,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string | undefined,
        appVersion?: string
    ): Promise<void> {
        const trackingData = this.getDesignTrackingData(
            projectDesign,
            usageCounter.toUsageCounterC2C(),
            projectOpenType,
            designId,
            createdFromTemplate,
            templateId ?? '',
            appVersion ?? ''
        );

        const data: OnProjectCloseRequestC2C = {
            DesignId: designId,
            SessionKey: sessionKey,
            TrackingData: trackingData
        };

        const url = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}OnProjectCloseSync`;
        const jsonData = data != null
            ? JSON.stringify(data)
            : '';
        const httpHeaders = this.userService.getHeaders(url, true);
        const trackingPromise = trySendingUsingFetch(url, httpHeaders, jsonData, this.loggerService)
            .catch((error) => {
                console.error(error);
                this.loggerService.log(error.message, LogType.error, error);
            })
            .then(() => {
                if (data != null) {
                    usageCounter.resetCounter();
                }
            });
        return trackingPromise;
    }


    private getDesignTrackingData(projectDesign: ProjectDesignEntityC2C, usageCounter: UsageCounterC2C, projectOpenType: ProjectOpenType, designId: string, createdFromTemplate: boolean, templateId: string, uiVersion: string): TrackOnProjectCloseRequestC2C {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        return {
            designId,
            projectDesign,
            language: this.localizationService.selectedLanguage,
            region: this.getRegionNameById(projectDesign.options.regionId),
            usageCounter,
            timestamp: date.timestamp as any,
            userDetails,
            projectOpenType,
            createdFromTemplate,
            templateId,
            uiVersion
        };
    }

    private getTrackOnProjectTemplateOpenRequest(templateId: string, date: DateWithTimezone, designStandard: DesignStandardC2C, connectionType: ConnectionType): TrackOnProjectTemplateOpenRequestC2C {
        return {
            connectionType,
            designType: DesignTypeC2C.Concrete2Concrete,
            designStandard,
            templateId,
            timestamp: date.timestamp as any,
            uiVersion: this.sharedEnvironmentService.data?.applicationVersion as string,
            userDetails: this.getUserDetails(date.timezoneOffset)
        };
    }

    private getUserDetails(timezoneOffset: number): UserDetails {
        const srcData = this.commonTrackingService.getTrackingUserData(timezoneOffset);

        return {
            BrowserType: srcData.BrowserType,
            OperatingSystem: srcData.OperatingSystem,
            TimezoneOffset: srcData.TimezoneOffset,
            IPAddress: srcData.UserIpAddress,
            UserName: srcData.UserName,
            UserId: srcData.UserId,
            DiagnosticsAgreement: srcData.DiagnosticsAgreement,
            CustomerId: srcData.CustomerId,
            CustomerOriginId: srcData.CustomerOriginId,
            SalesOrg: srcData.SalesOrg,
            CustomerType: srcData.CustomerType,
            License: srcData.License,
            CountryOfResidence: srcData.CountryOfResidence,
            CustomerCountry: srcData.CustomerCountry,
            BuyingCustomerOriginId: srcData.BuyingCustomerOriginId,
            BuyingSalesOrg: srcData.BuyingSalesOrg,
            BuyingCustomerType: srcData.BuyingCustomerType,
            IsTrial: srcData.IsTrial
        };
    }

    private getRegionNameById(regionId: number) {
        const codeLists = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return codeLists.find(x => x.id == regionId)?.displayKey ?? '';
    }
}
