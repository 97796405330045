import { Injectable } from '@angular/core';
import {
    CommonRegion as Region, ICommonRegionConstructor as IRegionConstructor
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Unit } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import {
    SpecialRegion, SpecialRegionName
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    UserSettingsServiceInjected
} from '@profis-engineering/pe-ui-common/services/user-settings.common';
import {
    MethodGroupConnTypeStandardRegion
} from '../../shared/entities/code-lists/connection-type-method-standard-regions';
import { DesignMethodGroup } from '../../shared/entities/code-lists/design-method-group';
import { DesignStandard } from '../../shared/entities/code-lists/design-standard';
import { DesignType } from '../../shared/entities/code-lists/design-type';
import { DesignType as DesignTypeId } from '../../shared/entities/tracking-data';
import { QuickStartSettings, UserSettingsC2C } from '../../shared/entities/user-settings';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import {
    PropertyValueEntityC2C
} from '../../shared/generated-modules/Hilti.PE.C2CCodeListService.Entities';
import {
    ConnectionType, DesignMethodGroup as DesignMethodGroupEnum, DesignStandard as DesignStandardEnum
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C } from '../../shared/properties/properties';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService extends UserSettingsServiceInjected<UserSettingsC2C> {
    constructor(
        private commonCodeListService: CommonCodeListService,
        private codeListService: CodeListService
    ) {
        super();
    }

    private get globalOrDefaultRegion(): Region {
        return new Region({
            id: SpecialRegion.Default,
            countryCode: SpecialRegionName.Default,
            supportPhone: '',
            supportHours: '',
            worldAreaId: 1,
            profis3KBUrl: '',
            profis3KBUrlAci: '',
            hiltiOnlineUrl: '',
            onlineTenderTextsUrl: '',
            hubId: 1,
            hiltiDataConsentUrl: '',
            bpRigidityCheckUrl: '',
            onlineTechnicalLibraryUrl: '',
            culture: '',
            zipCodeFormat: '',
            dlubalEnabled: false
        } as IRegionConstructor);
    }

    /**
     * Settings for metal deck design. If specific setting for metal deck is not set, global setting is used. If neither is found, default one from region is used.
     */
    public get quickStartConcrete2ConcreteSettings(): QuickStartSettings {
        const generalRegion = this.getRegionById(this.settings.quickStart.concrete2Concrete.generalRegionId.value);
        const region = this.getSelectedOrGlobalAllowedRegion(generalRegion.id);

        const propertyValue = this.codeListService.getPropertyValue(PropertyMetaDataC2C.Option_C2C_DesignStandard.id, region.id, 0, 0);
        const unitLengthPropertyValue = this.codeListService.getPropertyValue(PropertyMetaDataC2C.Option_UnitLength.id, region.id, 0, 0);
        const unitAreaPropertyValue = this.codeListService.getPropertyValue(PropertyMetaDataC2C.Option_UnitArea.id, region.id, 0, 0);
        const unitAreaPerLengthPropertyValue = this.codeListService.getPropertyValue(PropertyMetaDataC2C.Option_UnitAreaPerLength.id, region.id, 0, 0);

        const designStandardC2C = this.getValueWithFallbacks<DesignStandard>(
            this.getDesignStandardC2CById(this.settings.quickStart.concrete2Concrete.calculationDesignStandardId.value as number),
            this.getDesignStandardC2CById(propertyValue.defaultValue as number));

        const defaultMethodC2C = propertyValue.designMethodGroupId != 0 ? propertyValue.designMethodGroupId : propertyValue.defaultValue;
        const designMethodC2C = this.getValueWithFallbacks<DesignMethodGroup>(
            this.getDesignMethodGroupC2CById(this.settings.quickStart.concrete2Concrete.calculationDesignMethodGroupId.value ?? 0),
            this.getDesignMethodGroupC2CById(defaultMethodC2C ?? 0));
        const overlayDesignStandardC2C = this.getValueWithFallbacks<DesignStandard>(
            this.getDesignStandardC2CById(this.settings.quickStart.concrete2Concrete.calculationOverlayDesignStandardId.value as number),
            this.getDesignStandardC2CById(propertyValue.defaultValue as number));
        const overlayDesignMethodC2C = this.getValueWithFallbacks<DesignMethodGroup>(
            this.getDesignMethodGroupC2CById(this.settings.quickStart.concrete2Concrete.calculationOverlayDesignMethodGroupId.value ?? 0),
            this.getDesignMethodGroupC2CById(defaultMethodC2C ?? 0));

        return {
            general_region: generalRegion,
            units_area: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitArea, unitAreaPropertyValue?.defaultValue),
                this.getUnitFromCodeList(CommonCodeList.UnitArea, this.settings.quickStart.concrete2Concrete.unitsAreaId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitArea, region.defaultUnitArea)),
            units_force: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitForce, this.settings.quickStart.concrete2Concrete.unitsForceId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitForce, region.defaultUnitForce)),
            units_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitLength, unitLengthPropertyValue?.defaultValue),
                this.getUnitFromCodeList(CommonCodeList.UnitLength, this.settings.quickStart.concrete2Concrete.unitsLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitLength, region.defaultUnitLength)),
            units_moment: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, this.settings.quickStart.concrete2Concrete.unitsMomentId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, region.defaultUnitMoment)),
            units_stress: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitStress, this.settings.quickStart.concrete2Concrete.unitsStressId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitStress, region.defaultUnitStress)),
            units_temperature: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, this.settings.quickStart.concrete2Concrete.unitsTemperatureId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, region.defaultUnitTemperature)),
            units_force_per_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, this.settings.quickStart.concrete2Concrete.unitsForcePerLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, region.defaultUnitForcePerLength)),
            units_moment_per_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, this.settings.quickStart.concrete2Concrete.unitsMomentPerLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, region.defaultUnitMomentPerLength)),
            units_length_large: this.getUnitFromCodeList(CommonCodeList.UnitLength, region.defaultUnitLengthLarge),
            units_stress_small: this.getUnitFromCodeList(CommonCodeList.UnitStress, region.defaultUnitStressSmall),
            units_density: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitDensity, this.settings.quickStart.concrete2Concrete.unitsDensityId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitDensity, region.defaultUnitDensity)),
            units_area_per_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitAreaPerLength, unitAreaPerLengthPropertyValue?.defaultValue),
                this.getUnitFromCodeList(CommonCodeList.UnitAreaPerLength, this.settings.quickStart.concrete2Concrete.unitsAreaPerLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitAreaPerLength, region.defaultUnitAreaPerLength)),
            calculation_designStandardC2C: designStandardC2C
                ?? null as unknown as DesignStandard,
            calculation_designMethodC2C: designMethodC2C
                ?? null as unknown as DesignMethodGroup,
            calculation_overlayDesignStandardC2C: overlayDesignStandardC2C
                ?? null as unknown as DesignStandard,
            calculation_overlayDesignMethodC2C: overlayDesignMethodC2C
                ?? null as unknown as DesignMethodGroup,
            overlayUseHiltiRebarDesign: this.settings.quickStart.concrete2Concrete.overlayUseHiltiRebarDesign.value
                ?? false,
        };
    }

    public initUserSettingsValidation(): void {
        this.validateSettings();
        this.userSettingsSaving.subscribe(() => this.validateSettings());
    }

    public validateSettings(): void {
        if (this.settings.application.general.regionId.value == null) {
            return;
        }

        const generalRegionField = this.getRegionById(this.settings.quickStart.concrete2Concrete.generalRegionId.value as number);

        const region = this.getSelectedOrGlobalAllowedRegion(generalRegionField.id);
        const regionId = region?.id ?? 0;
        const propertyValue = this.codeListService.getPropertyValue(PropertyMetaDataC2C.Option_C2C_DesignStandard.id, regionId, 0, 0);
        const propertyValueOverlay = this.codeListService.getPropertyValue(PropertyMetaDataC2C.Option_C2C_DesignStandard.id, regionId, ConnectionType.ConcreteOverlay);
        const unitLengthValue = this.codeListService.getPropertyValue(PropertyMetaDataC2C.Option_UnitLength.id, regionId, 0, 0)?.defaultValue ?? region.defaultUnitLength;
        const unitAreaValue = this.codeListService.getPropertyValue(PropertyMetaDataC2C.Option_UnitArea.id, regionId, 0, 0)?.defaultValue ?? region.defaultUnitArea;
        const unitAreaPerLengthValue = this.codeListService.getPropertyValue(PropertyMetaDataC2C.Option_UnitAreaPerLength.id, regionId, 0, 0)?.defaultValue ?? region.defaultUnitAreaPerLength;

        const { designStandardC2C, overlayDesignStandardC2C, designMethodC2C, defaultMethodC2C, overlayDesignMethodC2C } = this.getValuesToEvaluate(propertyValue, propertyValueOverlay);

        const validDsId = this.getValidDesignStandard(designStandardC2C?.id ?? 0, region.id ?? 0, ConnectionType.Splices, propertyValue.defaultValue);
        const validDsOverlayId = this.getValidDesignStandard(overlayDesignStandardC2C?.id ?? 0, region.id ?? 0, ConnectionType.ConcreteOverlay, propertyValue.defaultValue);
        const validDmId = this.getValidDesignMethodGroup(validDsId, region.id ?? 0, ConnectionType.Splices, designMethodC2C?.id ?? 0, defaultMethodC2C);
        const validDmOverlayId = this.getValidDesignMethodGroup(validDsOverlayId, region.id ?? 0, ConnectionType.ConcreteOverlay, overlayDesignMethodC2C?.id ?? 0, defaultMethodC2C);

        const quickStartC2C = this.settings.quickStart.concrete2Concrete;
        const shouldCorrectValues = ((quickStartC2C.calculationDesignStandardId.value != validDsId)
            || (quickStartC2C.calculationOverlayDesignStandardId.value != validDsOverlayId)
            || (quickStartC2C.calculationDesignMethodGroupId.value != validDmId)
            || (quickStartC2C.calculationOverlayDesignMethodGroupId.value != validDmOverlayId));

        if (shouldCorrectValues) {
            quickStartC2C.calculationDesignStandardId.value = validDsId;
            quickStartC2C.calculationOverlayDesignStandardId.value = validDsOverlayId;
            quickStartC2C.calculationDesignMethodGroupId.value = validDmId;
            quickStartC2C.calculationOverlayDesignMethodGroupId.value = validDmOverlayId;
            quickStartC2C.unitsLengthId.value = unitLengthValue as unknown as number;
            quickStartC2C.unitsAreaId.value = unitAreaValue as unknown as number;
            quickStartC2C.unitsForceId.value = region.defaultUnitForce as unknown as number;
            quickStartC2C.unitsMomentId.value = region.defaultUnitMoment as unknown as number;
            quickStartC2C.unitsStressId.value = region.defaultUnitStress as unknown as number;
            quickStartC2C.unitsTemperatureId.value = region.defaultUnitTemperature as unknown as number;
            quickStartC2C.unitsForcePerLengthId.value = region.defaultUnitForcePerLength as unknown as number;
            quickStartC2C.unitsMomentPerLengthId.value = region.defaultUnitMomentPerLength as unknown as number;
            quickStartC2C.unitsDensityId.value = region.defaultUnitDensity as unknown as number;
            quickStartC2C.unitsAreaPerLengthId.value = unitAreaPerLengthValue as unknown as number;
        }
    }

    private getValuesToEvaluate(propertyValue: PropertyValueEntityC2C, propertyValueOverlay: PropertyValueEntityC2C) {
        const designStandardC2C = this.getDesignStandardC2CById(this.settings.quickStart.concrete2Concrete.calculationDesignStandardId.value as number)
            ?? this.getDesignStandardC2CById(propertyValue.defaultValue as number);

        const defaultMethodC2C = propertyValue.designMethodGroupId != 0 ? propertyValue.designMethodGroupId : propertyValue.defaultValue;
        const designMethodC2C = this.getDesignMethodGroupC2CById(this.settings.quickStart.concrete2Concrete.calculationDesignMethodGroupId.value ?? 0)
            ?? this.getDesignMethodGroupC2CById(defaultMethodC2C ?? 0);
        const overlayDesignStandardC2C = this.getDesignStandardC2CById(this.settings.quickStart.concrete2Concrete.calculationOverlayDesignStandardId.value as number)
            ?? this.getDesignStandardC2CById(propertyValueOverlay.defaultValue as number);
        const overlayDesignMethodC2C = this.getDesignMethodGroupC2CById(this.settings.quickStart.concrete2Concrete.calculationOverlayDesignMethodGroupId.value ?? 0)
            ?? this.getDesignMethodGroupC2CById(defaultMethodC2C ?? 0);
        return { designStandardC2C, overlayDesignStandardC2C, designMethodC2C, defaultMethodC2C, overlayDesignMethodC2C };
    }

    public getRegionById(regionId: number | null) {
        const regionCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId) ?? this.globalOrDefaultRegion;
    }

    private getDesignTypeById(): DesignType | undefined {
        const designTypeCodeList = this.codeListService.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C] as DesignType[];
        return designTypeCodeList.find(designType => designType.id == DesignTypeId.Concrete2Concrete);
    }

    private getSelectedOrGlobalAllowedRegion(id: number) {
        const designType = this.getDesignTypeById();
        const regionId = id == SpecialRegion.Default ? (this.settings.application.general.regionId.value as number) : id;

        if (designType?.allowedDesignStandardRegions != undefined && designType.allowedDesignStandardRegions.some((row) => row.RegionId == regionId)) {
            return this.getRegionById(regionId);
        }

        return this.globalOrDefaultRegion;
    }

    private getDesignStandardC2CById(designStandardId: number): DesignStandard | undefined {
        const designStandardCodeList = this.codeListService.projectCodeListsC2C[ProjectCodeList.DesignStandardsC2C] as DesignStandard[];
        return designStandardCodeList.find(designStandard => designStandard.id == designStandardId);
    }

    private getDesignMethodGroupC2CById(designMethodId: number): DesignMethodGroup | undefined {
        const designMethodCodeList = this.codeListService.projectCodeListsC2C[ProjectCodeList.DesignMethodGroupsC2C] as DesignMethodGroup[];
        return designMethodCodeList.find(method => method.id == designMethodId);
    }

    private getValidDesignStandard(currentDesignStandardId: number, regionId: number, connectionTypeId: number, defaultDesignStandardId: number | undefined): DesignStandardEnum {
        const connectionTypeDesignStandardRegionsC2C = this.codeListService.projectCodeListsC2C[ProjectCodeList.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];
        const connectionTypeItems = connectionTypeDesignStandardRegionsC2C.filter(c => c.regionId == regionId && c.designStandardId == currentDesignStandardId);
        const isValid = connectionTypeItems.some(c => c.connectionTypeId == connectionTypeId);

        let result = currentDesignStandardId;
        if (!isValid) {
            result = defaultDesignStandardId != undefined
                ? defaultDesignStandardId
                : connectionTypeDesignStandardRegionsC2C.filter(c => c.regionId == regionId && c.connectionTypeId == connectionTypeId)[0].designStandardId as DesignStandardEnum;
        }

        return result;
    }

    private getValidDesignMethodGroup(designStandardId: number, regionId: number, connectionTypeId: number, currentDesignMethodId: number, defaultDesignMethodGroupId: number | undefined): DesignMethodGroupEnum {
        const connectionTypeDesignStandardRegionsC2C = this.codeListService.projectCodeListsC2C[ProjectCodeList.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];
        const connectionTypeItems = connectionTypeDesignStandardRegionsC2C.filter(c => c.regionId == regionId && c.designStandardId == designStandardId && c.designMethodGroupId == currentDesignMethodId);
        const isValid = connectionTypeItems.some(c => c.connectionTypeId == connectionTypeId);

        let result = currentDesignMethodId;
        if (!isValid) {
            result = defaultDesignMethodGroupId != null
                ? defaultDesignMethodGroupId
                : connectionTypeDesignStandardRegionsC2C.filter(c => c.regionId == regionId && c.connectionTypeId == connectionTypeId && c.designStandardId == designStandardId)[0].designMethodGroupId as DesignMethodGroupEnum;
        }

        return result;
    }

    /**
     * Reads the code list value based on the string stored in the settings service.
     * Implementation details:
     *      If value returned form service is equal to null or 0, than ste unit is null whitch also indicates that default unit should be used.
     * @param codeListType The code list type
     * @param val The val
     */
    private getUnitFromCodeList(codeListType: CommonCodeList, val: string | number | null | undefined): Unit | undefined {
        let valNum: number;

        if (val == null) {
            return undefined;
        }

        if (typeof val === 'number') {
            if (val == null || val == 0) {
                return undefined;
            }
            valNum = val;
        }
        else if (typeof val === 'string') {
            if (val == null || val == '' || val == '0') {
                return undefined;
            }
            valNum = parseInt(val, 10);
        }

        const unitsCodeList = this.commonCodeListService.commonCodeLists[codeListType] as Unit[];
        return unitsCodeList.find((unit) => unit.id == valNum);
    }
}
