import {
    Component, DoCheck, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignC2C as Design } from '../../../shared/entities/design-c2c';
import { CollapsingControls } from '../../../shared/enums/collapsing-controls';
import {
    PostInstalledReinforcementPointC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    CalculationStatus, DesignMethod, LongitudinalReinforcementOption
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { DesignMethodHelper } from '../../helpers/design-method-helper';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { includeSprites } from '../../sprites';

interface IRebarLoad {
    id: string;
    fpl: string;
    lv: string;
}

@Component({
    templateUrl: './rebar-loads.component.html',
    styleUrls: ['./rebar-loads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class RebarLoadsComponent implements OnInit, DoCheck {

    @Input()
    public design?: Design;

    public dataLoaded = false;
    public collapsed = false;
    public tableElement: string;
    public rebarLoadsElement: string;
    public CollapsingControls = CollapsingControls;

    private fullHeight = 0;
    private rebars: IRebarLoad[] = [];
    private rebarPoints: PostInstalledReinforcementPointC2C[] = [];

    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        private unit: UnitService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        this.rebarLoadsElement = '.rebar-loads';
        this.tableElement = '.rebar-loads-table';
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot, 'sprite-rebar-loads');
    }

    ngDoCheck(): void {
        if (this.design == null || this.dataLoaded)
            return;

        this.fillRebarArray();

        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.RebarLoads);
        setTimeout(() => this.setMinimumHeight());
        this.dataLoaded = true;
    }

    public get showRebarLoads() {
        return this.design?.isPirEuOrAus
            && this.design.designData?.reportDataC2C?.calculationStatus == CalculationStatus.OK
            && (this.design.designData?.projectDesignC2C?.product.postInstalledRebarPoints.length ?? 0) > 0
            && (this.design.designData?.projectDesignC2C?.product.connectorEmbedmentDepth ?? 0) > 0
            && this.haveResultsForMethod(this.design.designData?.projectDesignC2C?.options.selectedDesignMethod ?? DesignMethod.Unknown);
    }

    public get rebarsArray(): IRebarLoad[] {
        if (!this.equalArrays(this.rebarPoints, this.design?.designData?.projectDesignC2C?.product.postInstalledRebarPoints)) {
            this.rebars = [];
            this.fillRebarArray();
        }

        return this.rebars;
    }

    public get areLayersSelected() {
        return this.design?.designData?.projectDesignC2C?.product.reinforcementOption == LongitudinalReinforcementOption.ReinforcementLayers;
    }

    public get defaultUnit() {
        const unitGroup = UnitGroup.Force;
        return this.unit.getDefaultUnit(unitGroup, this.design);
    }

    private get minHeight() {
        return 30 + 31;
    }

    public haveResultsForMethod(method: DesignMethod): boolean {
        const key = DesignMethodHelper.mapDesignMethodToString(method);
        return this.design?.designData?.reportDataC2C?.designMethodResultsItems[key] !== undefined;
    }

    public getForceUnit() {
        const unitGroup = UnitGroup.Force;
        return `[${this.unit.formatUnit(this.unit.getDefaultUnit(unitGroup))}]`;
    }

    public getLengthUnit() {
        const unitGroup = UnitGroup.Length;
        return `[${this.unit.formatUnit(this.unit.getDefaultUnit(unitGroup))}]`;
    }

    public trackRebarLoadById: TrackByFunction<IRebarLoad> = (_: number, rebarLoad: IRebarLoad) => rebarLoad.id;

    public setMinimumHeight() {
        let minimumHeight = 0;
        if (!this.collapsed) {
            minimumHeight = this.minHeight;
        }

        // if rebarLoadsElement is even shown
        if (this.showRebarLoads) {
            const rebarLoadsElement = this.elementRef.nativeElement.shadowRoot?.querySelector<HTMLElement>(this.rebarLoadsElement);
            if (rebarLoadsElement != null) {
                rebarLoadsElement.style.minHeight = minimumHeight + 'px';
            }
        }
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.RebarLoads, collapsed);

        setTimeout(() => {
            this.setHeight();
            this.setMinimumHeight();
        });
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private equalArrays(array1: any[], array2?: any[]) {
        return array1.length == array2?.length && array1.every((val, index) => val == array2[index]);
    }

    private fillRebarArray(): IRebarLoad[] {
        const unitForce = this.unit.getDefaultUnit(UnitGroup.Force, this.design);
        const unitLength = this.unit.getDefaultUnit(UnitGroup.Length, this.design);

        if (this.areLayersSelected) {
            if (this.design?.designData?.projectDesignC2C?.product.topLayer.showLayer) {
                if (this.design.designData.projectDesignC2C.product.topLayer.layer1.showLayerLabel) {
                    const layerName = this.translate('Agito.Hilti.C2C.RebarLoads.Table.Layers.TopLayer1');
                    this.pushRebar('TopLayer1', layerName, unitForce, unitLength);
                }
                if (this.design.designData.projectDesignC2C.product.topLayer.layer2.showLayerLabel) {
                    const layerName = this.translate('Agito.Hilti.C2C.RebarLoads.Table.Layers.TopLayer2');
                    this.pushRebar('TopLayer2', layerName, unitForce, unitLength);
                }
            }

            if (this.design?.designData.projectDesignC2C?.product.bottomLayer.showLayer) {
                if (this.design.designData.projectDesignC2C.product.bottomLayer.layer1.showLayerLabel) {
                    const layerName = this.translate('Agito.Hilti.C2C.RebarLoads.Table.Layers.BottomLayer1');
                    this.pushRebar('BottomLayer1', layerName, unitForce, unitLength);
                }
                if (this.design.designData.projectDesignC2C.product.bottomLayer.layer2.showLayerLabel) {
                    const layerName = this.translate('Agito.Hilti.C2C.RebarLoads.Table.Layers.BottomLayer2');
                    this.pushRebar('BottomLayer2', layerName, unitForce, unitLength);
                }
            }
        }
        else {
            const data = this.design?.designData.projectDesignC2C?.product.postInstalledRebarPoints ?? [];
            for (let i = 0; i < data.length; i++) {
                this.rebars.push(this.getRebar(data[i].id, data[i].fpl, data[i].kernelInstallationLength, unitForce, unitLength));
            }
        }

        this.setHeight();
        return this.rebars;
    }

    private pushRebar(layerId: string, layerName: string, unitForce: Unit, unitLength: Unit) {
        const filteredData = (this.design?.designData.projectDesignC2C?.product.postInstalledRebarPoints ?? []).filter(u => u.layerId == layerId);
        const sumFpl = filteredData.reduce((total, point) => point.fpl + total, 0);
        const maxInstallationLength = filteredData.reduce((acc, point) => acc = acc > point.installationLength ? acc : point.installationLength, 0);
        this.rebars.push(this.getRebar(layerName, sumFpl, maxInstallationLength, unitForce, unitLength));
    }

    private getRebar(rebarId: string, force: number, installationLength: number, unitForce: any, unitLength: any) {
        const fpl = this.unit.convertInternalValueToDefaultValue(force, UnitGroup.Force);
        const lv = this.unit.convertInternalValueToDefaultValue(installationLength, UnitGroup.Length);

        return {
            id: rebarId,
            fpl: this.unit.formatUnitValueArgs(fpl, unitForce, undefined, undefined, undefined, undefined, false),
            lv: this.unit.formatUnitValueArgs(lv, unitLength, undefined, undefined, undefined, undefined, false)
        };
    }

    private setHeight() {
        const tableElt = this.elementRef.nativeElement.shadowRoot?.querySelector(this.tableElement) as HTMLElement;
        const rebarLoadsElt = this.elementRef.nativeElement.shadowRoot?.querySelector(this.rebarLoadsElement) as HTMLElement;

        if (this.collapsed) {
            rebarLoadsElt.style.height = '';
            rebarLoadsElt.style.overflowY = '';
        }
        else {
            const numOfRebars = this.rebars.length;
            const tableHeight = 4 * 30 + 30;  // default height with 4 rebars

            if (numOfRebars == 1) {
                this.fullHeight = numOfRebars * 30 + 31 + 33;
                if (tableElt != null) {
                    tableElt.style.height = this.fullHeight - 33 + 'px';
                    tableElt.style.overflowY = '';
                }
                if (rebarLoadsElt != null) {
                    rebarLoadsElt.style.overflowY = '';
                }
            }

            else if (numOfRebars <= 4) {
                this.fullHeight = numOfRebars * 30 + 31 + 33;
                if (tableElt != null) {
                    tableElt.style.height = this.fullHeight - 33 + 'px';
                    tableElt.style.overflowY = '';
                }
                if (rebarLoadsElt != null) {
                    rebarLoadsElt.style.overflowY = '';
                }
            }

            else {
                this.fullHeight = 4 * 30 + 31 + 33;
                if (tableElt != null) {
                    tableElt.style.height = tableHeight + 'px';
                    tableElt.style.overflowY = 'scroll';
                }
                if (rebarLoadsElt != null) {
                    rebarLoadsElt.style.width = 'auto';
                }
            }

            if (rebarLoadsElt != null) {
                rebarLoadsElt.style.height = this.fullHeight + 'px';
            }
        }
    }
}
