import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { NewDesignDataEntityC2C } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { OptimizationStrategyOptionsAs } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';
import { DesignStandard, OptimizationStrategy } from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';

import { IDetailedDisplayDesign } from './display-design';
import { UserSettingsC2C as UserSettings } from './user-settings';

export interface IOptimizationStrategyInputsData {
    // Context
    design?: () => IDetailedDisplayDesign;
    settings?: () => UserSettings;
    connectionType?: number;
    addEditType?: AddEditType;

    // Values
    optimizationStrategy?: number;
    optimizationStrategyOptionsAs: OptimizationStrategyOptionsAs;
}

export function setOptimizationStrategyValuesToDesign(design: IDetailedDisplayDesign, data: IOptimizationStrategyInputsData) {
    design.optimizationStrategy = data.optimizationStrategy;

    if (design.designStandardC2C?.id != DesignStandard.ASBased || !design.designOptionsAs)
        return;

    design.designOptionsAs.optimizationStrategyOptions = {
        optimizationStrategy: data.optimizationStrategyOptionsAs.optimizationStrategy
    };
}

export function setOptimizationStrategyValuesToNewDesign(newDesign: NewDesignDataEntityC2C, data: IOptimizationStrategyInputsData) {
    newDesign.optimizationStrategy = data.optimizationStrategy as OptimizationStrategy;

    if (newDesign.designStandard != DesignStandard.ASBased || !newDesign.designOptionsAs)
        return;

    newDesign.designOptionsAs.optimizationStrategyOptions = {
        optimizationStrategy: data.optimizationStrategyOptionsAs.optimizationStrategy
    };
}
