import { Injectable } from '@angular/core';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    ApplicationState, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';

import { IC2CFixing } from '../../shared/entities/c2c-fixing';
import {
    ConnectionType
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { AppSettingsHelperC2C } from '../helpers/app-settings-helper';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { LocalizationService } from './localization.service';
import { NumberService } from './number.service';
import { UserSettingsService } from './user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    private appSettingsHelper: AppSettingsHelperC2C;

    constructor(
        private localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        private codeList: CommonCodeListService,
        private codeListC2C: CodeListService,
        private numberService: NumberService
    ) {
        this.appSettingsHelper = new AppSettingsHelperC2C(this.localizationService, this.userSettings, this.codeList, this.codeListC2C, this.numberService);
    }

    public loadSettings() {
        const c2cFixing: IC2CFixing = {
            regionC2C: KnownRegion.Unknown,

            lengthC2C: undefined,
            areaC2C: undefined,
            stressC2C: undefined,
            forceC2C: undefined,
            momentC2C: undefined,
            temperatureC2C: undefined,
            forcePerLengthC2C: undefined,
            momentPerLengthC2C: undefined,
            densityC2C: undefined,
            areaPerLengthC2C: undefined,
            designStandardIdC2C: undefined as unknown as number,
            designMethodGroupIdC2C: undefined as unknown as number,
            overlayDesignStandardIdC2C: undefined as unknown as number,
            overlayDesignMethodGroupIdC2C: undefined as unknown as number,
            overlayUseHiltiRebarDesignC2C: undefined,

            applicationStateControl: undefined
        };

        if (this.userSettings.quickStartConcrete2ConcreteSettings.general_region != null) {
            c2cFixing.regionC2C = this.userSettings.quickStartConcrete2ConcreteSettings.general_region.id;
        }
        else {
            c2cFixing.regionC2C = undefined as unknown as number;
        }

        c2cFixing.lengthC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_length?.id;
        c2cFixing.areaC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_area?.id;
        c2cFixing.stressC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_stress?.id;
        c2cFixing.forceC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_force?.id;
        c2cFixing.momentC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_moment?.id;
        c2cFixing.temperatureC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_temperature?.id;
        c2cFixing.forcePerLengthC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_force_per_length?.id;
        c2cFixing.momentPerLengthC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_moment_per_length?.id;
        c2cFixing.densityC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_density?.id;
        c2cFixing.areaPerLengthC2C = this.userSettings.quickStartConcrete2ConcreteSettings.units_area_per_length?.id;
        c2cFixing.overlayUseHiltiRebarDesignC2C = this.userSettings.quickStartConcrete2ConcreteSettings.overlayUseHiltiRebarDesign;

        if (this.userSettings.quickStartConcrete2ConcreteSettings.overlayUseHiltiRebarDesign != null) {
            c2cFixing.overlayUseHiltiRebarDesignC2C = this.userSettings.quickStartConcrete2ConcreteSettings.overlayUseHiltiRebarDesign;
        }
        else {
            c2cFixing.overlayUseHiltiRebarDesignC2C = false;
        }

        if (this.userSettings.quickStartConcrete2ConcreteSettings.calculation_designStandardC2C != null) {
            c2cFixing.designStandardIdC2C = this.userSettings.quickStartConcrete2ConcreteSettings.calculation_designStandardC2C.id;
        }
        else {
            c2cFixing.designStandardIdC2C = undefined as unknown as number;
        }

        if (this.userSettings.quickStartConcrete2ConcreteSettings.calculation_designMethodC2C != null) {
            c2cFixing.designMethodGroupIdC2C = this.userSettings.quickStartConcrete2ConcreteSettings.calculation_designMethodC2C.id;
        }
        else {
            c2cFixing.designMethodGroupIdC2C = undefined as unknown as number;
        }

        if (this.userSettings.quickStartConcrete2ConcreteSettings.calculation_overlayDesignStandardC2C != null) {
            c2cFixing.overlayDesignStandardIdC2C = this.userSettings.quickStartConcrete2ConcreteSettings.calculation_overlayDesignStandardC2C.id;
        }
        else {
            c2cFixing.overlayDesignStandardIdC2C = undefined as unknown as number;
        }

        if (this.userSettings.quickStartConcrete2ConcreteSettings.calculation_overlayDesignMethodC2C != null) {
            c2cFixing.overlayDesignMethodGroupIdC2C = this.userSettings.quickStartConcrete2ConcreteSettings.calculation_overlayDesignMethodC2C.id;
        }
        else {
            c2cFixing.overlayDesignMethodGroupIdC2C = undefined as unknown as number;
        }

        return c2cFixing;
    }

    public handleRegionChange(c2cFixing: IC2CFixing, regionId: number) {
        if (c2cFixing.regionC2C == SpecialRegion.Default) {
            const region = this.appSettingsHelper.getRegionById(regionId) ?? this.appSettingsHelper.createDefaultRegion();
            this.appSettingsHelper.setDefaultC2CUnits(c2cFixing, region);
            this.appSettingsHelper.setDefaultC2CDesignStandard(c2cFixing, regionId, ConnectionType.Splices);
            this.appSettingsHelper.setDefaultC2CDesignStandard(c2cFixing, regionId, ConnectionType.ConcreteOverlay);
            this.appSettingsHelper.setDefaultC2CDesignMethod(c2cFixing, regionId, c2cFixing.designStandardIdC2C, ConnectionType.Splices);
            this.appSettingsHelper.setDefaultC2CDesignMethod(c2cFixing, regionId, c2cFixing.overlayDesignStandardIdC2C, ConnectionType.ConcreteOverlay);
        }
    }

    public updateSettings(c2cFixing: IC2CFixing) {
        if (c2cFixing == null) {
            return;
        }

        const settings = this.userSettings.settings.quickStart.concrete2Concrete;

        this.appSettingsHelper.updateSetting(settings.generalRegionId, c2cFixing.regionC2C);
        this.appSettingsHelper.updateSetting(settings.unitsLengthId, c2cFixing.lengthC2C);
        this.appSettingsHelper.updateSetting(settings.unitsAreaId, c2cFixing.areaC2C);
        this.appSettingsHelper.updateSetting(settings.unitsStressId, c2cFixing.stressC2C);
        this.appSettingsHelper.updateSetting(settings.unitsForceId, c2cFixing.forceC2C);
        this.appSettingsHelper.updateSetting(settings.unitsMomentId, c2cFixing.momentC2C);
        this.appSettingsHelper.updateSetting(settings.unitsTemperatureId, c2cFixing.temperatureC2C);
        this.appSettingsHelper.updateSetting(settings.unitsForcePerLengthId, c2cFixing.forcePerLengthC2C);
        this.appSettingsHelper.updateSetting(settings.unitsMomentPerLengthId, c2cFixing.momentPerLengthC2C);
        this.appSettingsHelper.updateSetting(settings.unitsDensityId, c2cFixing.densityC2C);
        this.appSettingsHelper.updateSetting(settings.unitsAreaPerLengthId, c2cFixing.areaPerLengthC2C);
        settings.overlayUseHiltiRebarDesign.value = c2cFixing.overlayUseHiltiRebarDesignC2C ?? null;

        if (settings.calculationDesignStandardId.value != c2cFixing.designStandardIdC2C ||
            settings.calculationDesignMethodGroupId.value != c2cFixing.designMethodGroupIdC2C ||
            settings.calculationOverlayDesignStandardId.value != c2cFixing.overlayDesignStandardIdC2C ||
            settings.calculationOverlayDesignMethodGroupId.value != c2cFixing.overlayDesignMethodGroupIdC2C
        ) {

            if (settings.calculationDesignStandardId.value != c2cFixing.designStandardIdC2C) {
                settings.calculationDesignStandardId.value = c2cFixing.designStandardIdC2C;
            }

            if (settings.calculationDesignMethodGroupId.value != c2cFixing.designMethodGroupIdC2C) {
                settings.calculationDesignMethodGroupId.value = c2cFixing.designMethodGroupIdC2C;
            }

            if (settings.calculationOverlayDesignStandardId.value != c2cFixing.overlayDesignStandardIdC2C) {
                settings.calculationOverlayDesignStandardId.value = c2cFixing.overlayDesignStandardIdC2C;
            }

            if (settings.calculationOverlayDesignMethodGroupId.value != c2cFixing.overlayDesignMethodGroupIdC2C) {
                settings.calculationOverlayDesignMethodGroupId.value = c2cFixing.overlayDesignMethodGroupIdC2C;
            }

            settings.applicationState.value = ApplicationState.AlwaysAskMe;
            this.userSettings.settings.application.concrete2Concrete.selectedConnectionTypeId.value = null;
            this.userSettings.settings.application.concrete2Concrete.selectedApplicationTypeId.value = null;
        }
        else {
            settings.applicationState.value = c2cFixing.applicationStateControl ?? null;
        }
    }
}
