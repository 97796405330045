// This is used by UserSettings so no existing value should be changed!
export enum CollapsingControls {
    ValidationError = 118,
    NotificationsDesign = 119,

    RebarDesign = 137, // never used
    ZoneUtilizationsC2C = 143,
    UtilizationsC2C = 144,
    DesignMethod = 147,
    RebarLoads = 148,
    ShearUtilization = 149,
    TopLayer1Utilization = 151,
    TopLayer2Utilization = 152,
    BottomLayer1Utilization = 153,
    BottomLayer2Utilization = 154,
    DrillLengthUtilization = 155,
    ConcreteBreakoutUtilization = 156,
    ConcreteSplittingUtilization = 157,
    StrutAndTiesUtilization = 158,
    PirShearHiltiUtilization = 171, // never used
    PirShearUtilization = 172, // never used
    PirAnchorDesignUtilization = 173, // never used
    PirTensionUtilization = 174, // never used
    PirInstallationUtilization = 175, // never used
    PirSpliceLengthUtilization = 176, // never used
    PirSeismicSpliceLengthUtilization = 177, // never used
}
