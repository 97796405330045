import { IdValuePair, RebarPointBase2dC2C } from '@profis-engineering/c2c-gl-model/components/base-rebar-helper';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitValue } from '@profis-engineering/pe-ui-common/services/unit.common';
import { IGlModelC2CComponent } from '../../../../shared/components/gl-model';
import { FastenerSize } from '../../../../shared/entities/code-lists/fastener-size';
import { GenericRebar } from '../../../../shared/entities/code-lists/generic-rebar';
import { DesignC2C } from '../../../../shared/entities/design-c2c';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { AdvancedPointsTableType, IAdvancedModifiedItem, IAdvancedPointTableAddInput, IAdvancedPointsTableDropdownItem, IAdvancedPointsTableItem, IAdvancedPointsTableProps, IAdvancedTableItemBase, ITableInputDataC2C } from '../../../../shared/entities/main-menu/main-menu-controls';
import { ProjectCodeList } from '../../../../shared/enums/project-code-list';
import { PropertyValueEntityC2C, UIPropertyEntityC2C } from '../../../../shared/generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { UIProperty } from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { ApplicationType } from '../../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C } from '../../../../shared/properties/properties';
import { Controls2dEditor, IDiameterPosition, IMenu2dContext, ROWS_PER_SCROLL_C2C } from '../../../../shared/services/menu-2d.service.base';
import { CodeListService } from '../../code-list.service';
import { LocalizationService } from '../../localization.service';
import { UnitService } from '../../unit.service';
import { updateMainMenuControl } from '../static-menu-helper';
import { NumberService } from '../../number.service';

export interface PointsTableServices {
    localizationService: LocalizationService;
    numberService: NumberService;
    unitService: UnitService;
    codeListService: CodeListService;
}

export interface ITableItem extends IAdvancedTableItemBase {
    angle?: number;
    formattedAngle?: string;
}

export function createAdvancedPointsTable2d(design: DesignC2C, services: PointsTableServices, glModelComponent: IGlModelC2CComponent, navControlName: string, pointsTableProps: IAdvancedPointsTableProps) {
    const model = glModelComponent.getModel();
    switch (navControlName) {
        case Controls2dEditor.PostInstalledRebar: {
            pointsTableProps.localization = services.localizationService;
            pointsTableProps.items = (model.postInstalledRebar?.points ?? []).map(p => {
                return {
                    x: p.x - p.offsetX,
                    y: p.y - p.offsetY,
                    diameter: p.diameter,
                    rebarDiameter: p.diameter.id,
                    bond: p.bond,
                    layerId: p.layerId,
                    shape: p.shape
                } as ITableInputDataC2C;
            });
            const allowedDiameters = design.properties.get(PropertyMetaDataC2C.Product_C2C_AllowedFastenerSizes.id).allowedValues;
            const defaultDiameter = design.model[PropertyMetaDataC2C.Product_C2C_AllowedFastenerSizes.id] as number;
            const pirData = getPointsTableData(design, services.localizationService, services.numberService, DesignCodeList.FastenerSize, allowedDiameters, defaultDiameter);
            pointsTableProps.rebarDiameters = pirData.dropdowns.rebarDiameters;
            pointsTableProps.bonds = !design.isPirASAS ? pirData.dropdowns.bonds : undefined;
            pointsTableProps.addInput = pirData.values as IAdvancedPointTableAddInput;
            pointsTableProps.isEditable = !pirTableVisualProperties(design).disabled;
            pointsTableProps.rowsPerScroll = ROWS_PER_SCROLL_C2C;
            pointsTableProps.tooltipDisabled = services.localizationService.getString('Agito.Hilti.C2C.Reinforcement.ChangeToVerificationMode.Tooltip');
            return AdvancedPointsTableType.PostInstallRebar;
        }
        case Controls2dEditor.ExistingReinforcement: {
            pointsTableProps.localization = services.localizationService;
            pointsTableProps.items = (model?.reinforcement?.longitudinalReinforcementPoints ?? []).map(p => {
                const offsetX = ((model?.baseMaterialsC2C?.newStructure?.offsetRight ?? 0) - (model?.baseMaterialsC2C?.newStructure?.offsetLeft ?? 0)) / 2;
                const offsetY = ((model?.baseMaterialsC2C?.newStructure?.offsetTop ?? 0) - (model?.baseMaterialsC2C?.newStructure?.offsetBottom ?? 0)) / 2;
                return {
                    x: p.x + offsetX,
                    y: p.y + offsetY,
                    rebarDiameter: p.diameter.id,
                    shape: p.shape,
                    bond: p.bond
                } as ITableItem;
            });
            const defaultDiameter = design.model[PropertyMetaDataC2C.Reinforcement_C2C_Longitudinal_TopDiameterIdL1.id] as number;
            const reinforcementData = getPointsTableData(design, services.localizationService, services.numberService, DesignCodeList.GenericRebarDiameter, undefined, defaultDiameter);
            pointsTableProps.rebarDiameters = reinforcementData.dropdowns.rebarDiameters;
            pointsTableProps.shapes = reinforcementData.dropdowns.shapes;
            pointsTableProps.bonds = !design.isPirASAS ?  reinforcementData.dropdowns.bonds : undefined;
            pointsTableProps.addInput = reinforcementData.values as IAdvancedPointTableAddInput;
            pointsTableProps.isEditable = !reinforcementTableVisualProperties(design).disabled;
            pointsTableProps.rowsPerScroll = ROWS_PER_SCROLL_C2C;
            return AdvancedPointsTableType.ExistingReinforcement;
        }
        default:
            return undefined;
    }
}

export function pirTableVisualProperties(design: DesignC2C) {
    const hidden = design.properties.get(UIProperty.Product_C2C_PostInstalledRebar_RebarPoints).hidden;
    const disabled = design.properties.get(UIProperty.Product_C2C_PostInstalledRebar_RebarPoints).disabled;
    const canInsert = design.applicationType != ApplicationType.Single;

    return {
        hidden,
        disabled,
        canInsert
    };
}

export function isPointsTableHidden(design: DesignC2C, items: IAdvancedTableItemBase[] | undefined, tableType: AdvancedPointsTableType) {
    switch (tableType) {
        case AdvancedPointsTableType.PostInstallRebar:
            return pirTableVisualProperties(design).hidden;
        case AdvancedPointsTableType.ExistingReinforcement:
            return reinforcementTableVisualProperties(design).hidden;
        default:
            return (items?.length ?? 0) < 1;
    }
}
export function onChangePointsTableCell(context: IMenu2dContext, type: number, item: IAdvancedModifiedItem) {
    const setString = (items: IAdvancedPointsTableItem[], item: IAdvancedModifiedItem) => {
        if (item.x != null) {
            items[item.id].formattedX = item.x;
        }

        if (item.y != null) {
            items[item.id].formattedY = item.y;
        }

        if (item.rebarDiameter != null) {
            items[item.id].rebarDiameter = item.rebarDiameter;
        }

        if (item.shape != null) {
            items[item.id].shape = item.shape;
        }

        if (item.bond != null) {
            items[item.id].bond = item.bond;
        }
    };

    const items = getPointsTableItems(context, type);
    setString(items, item);
    context.setState(menu => updateMainMenuControl(menu, `control-${getPointTableControlName(type)}`, { items } as any));
}

export function onCommitPointTable(
    context: IMenu2dContext,
    design: DesignC2C,
    services: PointsTableServices,
    type: number,
    item: IAdvancedModifiedItem) {
    const value = item.x ?? item.y;
    const nonCoordinatePropertyChanged = value == null && (item.rebarDiameter != null || item.shape != null || item.bond != null);

    const unitValue = services.unitService.parseUnitValue(value ?? '', UnitGroup.Length);

    if ((unitValue != null && !Number.isNaN(unitValue.value)) || nonCoordinatePropertyChanged) {
        const internalValue = nonCoordinatePropertyChanged
            ? null
            : services.unitService.convertUnitValueToUnit(minMaxFilter(design, services.codeListService, unitValue, services.unitService), services.unitService.getInternalUnit(UnitGroup.Length));

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const result = getPointTableCommitResult(context, design, services.unitService, item, value, internalValue, type);

        if (result != null) {
            const propertyId = getPointTablePropertyId(type);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            context.save2dState({ [propertyId]: true }, false);
        }
    }

    updatePointsTable(context, design, services.localizationService, services.numberService, type);
}

export function onDeletePointTable(
    context: IMenu2dContext,
    design: DesignC2C,
    services: PointsTableServices,
    type: number,
    index: number
) {
    const result = getPointTableDeleteResult(context, index, type);
    if (result != null) {
        updatePointsTable(context, design, services.localizationService, services.numberService, type);
        const propertyId = getPointTablePropertyId(type);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        context.save2dState({ [propertyId]: true }, false);
    }
}

export function onChangeAddPointTable(
    context: IMenu2dContext,
    type: number,
    item: IAdvancedPointTableAddInput
) {
    const input = { addInput: item } as any;
    context.setState(menu => updateMainMenuControl(menu, `control-${getPointTableControlName(type)}`, input));
}

export function onCommitAddPointTable(
    context: IMenu2dContext,
    design: DesignC2C,
    services: PointsTableServices,
    type: number,
    item: IAdvancedPointTableAddInput
) {
    const unitValueX = services.unitService.parseUnitValue(item.x ?? '', UnitGroup.Length);
    const unitValueY = services.unitService.parseUnitValue(item.y ?? '', UnitGroup.Length);

    if (unitValueX != null && !Number.isNaN(unitValueX.value) && unitValueY != null && !Number.isNaN(unitValueY.value)) {
        const internalUnitValueX = services.unitService.convertUnitValueToUnit(minMaxFilter(design, services.codeListService, unitValueX, services.unitService), services.unitService.getInternalUnit(UnitGroup.Length));
        const internalUnitValueY = services.unitService.convertUnitValueToUnit(minMaxFilter(design, services.codeListService, unitValueY, services.unitService), services.unitService.getInternalUnit(UnitGroup.Length));
        const addInputItem: IAdvancedPointTableAddInput = {} as IAdvancedPointTableAddInput;

        const result = getPointTableCommitAddResult(
            context,
            design,
            services.localizationService,
            services.numberService,
            item,
            internalUnitValueX,
            internalUnitValueY,
            addInputItem,
            type
        );

        updatePointTableAddInput(context, type, addInputItem);
        if (result != null) {
            updatePointsTable(context, design, services.localizationService, services.numberService, type);

            const propertyId = getPointTablePropertyId(type);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            context.save2dState({ [propertyId]: true }, false);
        }
    }
    else {
        updatePointTableAddInput(
            context,
            type,
            {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                x: unitValueX != null && !Number.isNaN(unitValueX.value) ? unitService.formatUnitValue(minMaxFilter(design, codeListService, unitValueX, unitService)) : null,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                y: unitValueY != null && !Number.isNaN(unitValueY.value) ? unitService.formatUnitValue(minMaxFilter(design, codeListService, unitValueY, unitService)) : null,
                diameter: item.diameter,
                shape: item.shape,
                bond: item.bond
            }
        );
    }
}

function getPointTableCommitAddResult(context: IMenu2dContext,
    design: DesignC2C,
    localizationService: LocalizationService,
    numberService: NumberService,
    item: IAdvancedPointTableAddInput,
    internalUnitValueX: UnitValue,
    internalUnitValueY: UnitValue,
    addInputItem: IAdvancedPointTableAddInput,
    type: AdvancedPointsTableType) {

    const model = context.glModelComponent?.getModel();
    switch (type) {
        case AdvancedPointsTableType.PostInstallRebar: {
            const pirDiameter = getRebarDiameterData(design, item.diameter);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const offsetX = model.postInstalledRebar.points.length > 0 ? model.postInstalledRebar.points[0].offsetX : 0;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const offsetY = model.postInstalledRebar.points.length > 0 ? model.postInstalledRebar.points[0].offsetY : 0;
            const newPirPoint: RebarPointBase2dC2C = {
                x: internalUnitValueX.value + offsetX,
                y: internalUnitValueY.value + offsetY,
                diameter: pirDiameter,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                id: null,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                layerId: null,
                bond: item.bond,
                shape: item.shape,
                offsetX: 0,
                offsetY: 0
            };

            const result = context.glModelComponent.addPirPoint2dC2C(newPirPoint);
            const allowedDiameters = design.properties.get(PropertyMetaDataC2C.Product_C2C_AllowedFastenerSizes.id).allowedValues;
            const defaultDiameter = design.model[PropertyMetaDataC2C.Product_C2C_AllowedFastenerSizes.id] as number;
            const pirData = getPointsTableData(design, localizationService, numberService, DesignCodeList.FastenerSize, allowedDiameters, defaultDiameter);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            addInputItem.diameter = pirData.values.diameter;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            addInputItem.shape = pirData.values.shape;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            addInputItem.bond = pirData.values.bond;
            return result;
        }
        case AdvancedPointsTableType.ExistingReinforcement: {
            const rebarDiameter = getGenericRebarDiameterData(design, item.diameter);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const existingOffsetX = (model.baseMaterialsC2C.newStructure.offsetRight - model.baseMaterialsC2C.newStructure.offsetLeft) / 2;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const existingOffsetY = (model.baseMaterialsC2C.newStructure.offsetTop - model.baseMaterialsC2C.newStructure.offsetBottom) / 2;
            const newPoint: RebarPointBase2dC2C = {
                bond: item.bond,
                x: internalUnitValueX.value - existingOffsetX,
                y: internalUnitValueY.value - existingOffsetY,
                diameter: rebarDiameter,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                id: null,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                layerId: null,
                shape: item.shape
            };

            const result = context.glModelComponent.addPoint2dC2C(newPoint);
            const defaultDiameter = design.model[PropertyMetaDataC2C.Reinforcement_C2C_Longitudinal_TopDiameterIdL1.id] as number;
            const reinforcementData = getPointsTableData(design, localizationService, numberService, DesignCodeList.GenericRebarDiameter, undefined, defaultDiameter);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            addInputItem.diameter = reinforcementData.values.diameter;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            addInputItem.shape = reinforcementData.values.shape;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            addInputItem.bond = reinforcementData.values.bond;
            return result;
        }
        default:
            return undefined;
    }
}

function getPointTableDeleteResult(context: IMenu2dContext, index: number, type: AdvancedPointsTableType) {
    switch (type) {
        case AdvancedPointsTableType.PostInstallRebar:
            return context.glModelComponent.deletePirPoint2dC2C(index);
        case AdvancedPointsTableType.ExistingReinforcement:
            return context.glModelComponent.deletePoint2dC2C(index);
        default:
            return undefined;
    }
}
export function updatePointsTable(
    context: IMenu2dContext,
    design: DesignC2C,
    localizationService: LocalizationService,
    numberService: NumberService,
    type: AdvancedPointsTableType
) {
    const model = context.glModelComponent?.getModel();

    switch (type) {
        case AdvancedPointsTableType.PostInstallRebar: {
            const allowedDiameters = design.properties.get(PropertyMetaDataC2C.Product_C2C_AllowedFastenerSizes.id).allowedValues;
            const defaultDiameter = design.model[PropertyMetaDataC2C.Product_C2C_AllowedFastenerSizes.id] as number;
            const pirData = getPointsTableData(design, localizationService, numberService, DesignCodeList.FastenerSize, allowedDiameters, defaultDiameter);
            context.setState(menu => updateMainMenuControl(menu, `control-${Controls2dEditor.PostInstalledRebar}`, {
                items: model.postInstalledRebar?.points.map(p => {
                    return {
                        x: p.x - p.offsetX,
                        y: p.y - p.offsetY,
                        rebarDiameter: p.diameter.id,
                        rebarId: p.id,
                        installationLength: p.installationLength,
                        layerId: p.layerId,
                        shape: p.shape,
                        bond: p.bond
                    };
                }),
                bonds: !design.isPirASAS ? pirData.dropdowns.bonds : undefined,
                rebarDiameters: pirData.dropdowns.rebarDiameters,
                addInput: pirData.values
            } as any));
            break;
        }
        case AdvancedPointsTableType.ExistingReinforcement: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const offsetX = (model.baseMaterialsC2C.newStructure.offsetRight - model.baseMaterialsC2C.newStructure.offsetLeft) / 2;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const offsetY = (model.baseMaterialsC2C.newStructure.offsetTop - model.baseMaterialsC2C.newStructure.offsetBottom) / 2;

            context.setState(menu => updateMainMenuControl(menu, `control-${Controls2dEditor.ExistingReinforcement}`, {
                items: model.reinforcement?.longitudinalReinforcementPoints.map(p => {
                    return {
                        x: p.x + offsetX,
                        y: p.y + offsetY,
                        rebarDiameter: p.diameter.id,
                        shape: p.shape,
                        bond: p.bond
                    };
                })
            } as any));
            break;
        }
    }
}

function getPointsTableItems(context: IMenu2dContext, type: AdvancedPointsTableType) {
    const model = context.glModelComponent?.getModel();
    switch (type) {
        case AdvancedPointsTableType.PostInstallRebar:
            return model?.postInstalledRebar?.points?.map(p => {
                return {
                    x: p.x - p.offsetX,
                    y: p.y - p.offsetY,
                    rebarDiameter: p.diameter.id,
                    layerId: p.layerId,
                    bond: p.bond,
                    shape: p.shape
                } as IAdvancedPointsTableItem;
            }) ?? [];
        case AdvancedPointsTableType.ExistingReinforcement:
            return model?.reinforcement?.longitudinalReinforcementPoints?.map(p => {
                const offsetX = ((model.baseMaterialsC2C?.newStructure?.offsetRight ?? 0) - (model.baseMaterialsC2C?.newStructure.offsetLeft ?? 0)) / 2;
                const offsetY = ((model.baseMaterialsC2C?.newStructure?.offsetTop ?? 0) - (model.baseMaterialsC2C?.newStructure.offsetBottom ?? 0)) / 2;
                return {
                    x: p.x + offsetX,
                    y: p.y + offsetY,
                    rebarDiameter: p.diameter.id,
                    bond: p.bond,
                    shape: p.shape
                } as IAdvancedPointsTableItem;
            }) ?? [];
        default:
            return [];
    }
}

function getPointsTableData(design: DesignC2C, localizationService: LocalizationService, numberService: NumberService, diameterCodeList: DesignCodeList, allowedDiameters?: number[], defaultDiameter?: number) {
    const rebarDiameterProperties = getDesignCodeListAsDropdownItems(design, localizationService, numberService, diameterCodeList, allowedDiameters, defaultDiameter);
    const shapesProperties = getDesignCodeListAsDropdownItems(design, localizationService, numberService, DesignCodeList.ShapeType);
    const bondProperties = getDesignCodeListAsDropdownItems(design, localizationService, numberService, DesignCodeList.BondCondition);

    return {
        dropdowns: {
            bonds: bondProperties.items,
            rebarDiameters: rebarDiameterProperties.items,
            shapes: shapesProperties.items
        },
        values: {
            bond: bondProperties.selectedItem,
            diameter: rebarDiameterProperties.selectedItem,
            shape: shapesProperties.selectedItem
        }
    };
}

function getDesignCodeListAsDropdownItems(design: DesignC2C, localizationService: LocalizationService, numberService: NumberService, codeList: DesignCodeList, allowedValues?: number[], defaultValue?: number, isNew = false, addOnActive = false, addOnText = '', internalPortfolioOnly = false) {
    const codeListItems = design.designData.designCodeListsC2C[codeList];
    const codeListDeps = getCodeListTextDeps(localizationService, numberService);
    const dropdownItems: IAdvancedPointsTableDropdownItem[] = codeListItems
        .filter(item => allowedValues != null ? allowedValues.includes(item.id) : true)
        .map(item => {
            const text = item.getTranslatedNameText(codeListDeps) ?? item.name ?? '';
            const tag = item.tag != null ? localizationService.getString(item.tag) : '';
            return {
                text,
                value: item.id,
                isNew,
                internalPortfolioOnly,
                tag,
                addOnActive,
                addOnText,
                isExtended: tag != ''
            };
        });

    const getSelectedItemFn = (): number | undefined => {
        if (defaultValue != null) {
            return defaultValue;
        }

        return dropdownItems.length > 0 ? dropdownItems[0].value : undefined;
    };

    return {
        items: dropdownItems,
        selectedItem: getSelectedItemFn()
    };
}

function reinforcementTableVisualProperties(design: DesignC2C) {
    const hidden = design.properties.get(UIProperty.ExistingStructure_C2C_Reinforcement_RebarPoints).hidden;
    const disabled = design.properties.get(UIProperty.ExistingStructure_C2C_Reinforcement_RebarPoints).disabled;

    return {
        hidden,
        disabled
    };
}

function getPointTableControlName(type: AdvancedPointsTableType) {
    switch (type) {
        case AdvancedPointsTableType.PostInstallRebar:
            return Controls2dEditor.PostInstalledRebar;
        case AdvancedPointsTableType.ExistingReinforcement:
            return Controls2dEditor.ExistingReinforcement;
        default:
            return undefined;
    }
}

function minMaxFilter(design: DesignC2C, codeListService: CodeListService, unitValue: UnitValue, unitService: UnitService) {
    const limits = getDistancePointLimitations(design, codeListService);
    const maxPointDistance = unitService.convertUnitValueArgsToUnit(limits.max, unitService.getInternalUnit(UnitGroup.Length), unitService.getDefaultUnit(UnitGroup.Length));
    const minPointDistance = unitService.convertUnitValueArgsToUnit(limits.min, unitService.getInternalUnit(UnitGroup.Length), unitService.getDefaultUnit(UnitGroup.Length));

    if (unitValue.value > maxPointDistance) {
        unitValue.value = maxPointDistance;
    }
    else if (unitValue.value < minPointDistance) {
        unitValue.value = minPointDistance;
    }

    return unitValue;
}

function getDistancePointLimitations(design: DesignC2C, codeListService: CodeListService) {
    const max = getPropertyValues(design, codeListService, UIProperty.Product_C2C_PostInstalledRebar_RebarPointInputX)?.maxValue ?? 0;
    const min = getPropertyValues(design, codeListService, UIProperty.Product_C2C_PostInstalledRebar_RebarPointInputX)?.minValue ?? 0;

    return {
        min,
        max
    };
}

function getPropertyValues(design: DesignC2C, codeListService: CodeListService, propertyId: number) {
    const uiProperties = codeListService.projectCodeListsC2C[ProjectCodeList.UIPropertiesC2C] as unknown as UIPropertyEntityC2C[];
    const propertyValues = uiProperties.find(prop => prop.id == propertyId);

    const filterFn = (element: PropertyValueEntityC2C) => {
        let passed = true;
        if (element.applicationTypeId != 0) {
            passed = element.applicationTypeId == design.applicationType;
        }
        if (element.connectionTypeId != 0) {
            passed = element.connectionTypeId == design.connectionType;
        }
        if (element.designStandardId != 0) {
            passed = element.designStandardId == design.designStandardC2C?.id;
        }
        if (element.designMethodGroupId != 0) {
            passed = element.designMethodGroupId == design.designMethodGroup?.id;
        }
        if (element.regionId != 0) {
            passed = element.regionId == design.region.id;
        }

        return passed;
    };

    return propertyValues?.propertyValues.find(filterFn);
}

function getPointTableCommitResult(context: IMenu2dContext, design: DesignC2C, unitService: UnitService, item: IAdvancedModifiedItem, value: string, internalValue: UnitValue, type: AdvancedPointsTableType) {
    const model = context.glModelComponent?.getModel();
    switch (type) {
        case AdvancedPointsTableType.PostInstallRebar:
            return onCommitPointTableForPostInstallRebar(context, design, unitService, model, item, internalValue, value);
        case AdvancedPointsTableType.ExistingReinforcement:
            return onCommitPointTableForExistingReinforcement(context, design, unitService, model, item, internalValue, value);
        default:
            return undefined;
    }
}

function onCommitPointTableForPostInstallRebar(
    context: IMenu2dContext,
    design: DesignC2C,
    unitService: UnitService,
    model: any,
    item: IAdvancedModifiedItem,
    internalValue: any,
    value: string
): any {
    const offsetX = model.postInstalledRebar.points[item.id].offsetX;
    const offsetY = model.postInstalledRebar.points[item.id].offsetY;
    const oldPirPoint: IDiameterPosition = {
        x: model.postInstalledRebar.points[item.id].x - offsetX,
        y: model.postInstalledRebar.points[item.id].y - offsetY,
        diameter: model.postInstalledRebar.points[item.id].diameter.id,
        bond: model.postInstalledRebar.points[item.id].bond,
        shape: model.postInstalledRebar.points[item.id].shape
    };
    const newPirPoint: IDiameterPosition = {
        x: item.x ? internalValue.value + offsetX : oldPirPoint.x + offsetX,
        y: item.y ? internalValue.value + offsetY : oldPirPoint.y + offsetY,
        diameter: item.rebarDiameter ?? oldPirPoint.diameter,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        shape: undefined,
        bond: item.bond ?? oldPirPoint.bond
    };

    if (!hasPointTableItemChanged(unitService, item, value, newPirPoint, oldPirPoint)) {
        return null;
    }

    return context.glModelComponent.movePirPoint2dC2C(
        item.id,
        newPirPoint.x,
        newPirPoint.y,
        getRebarDiameterData(design, newPirPoint.diameter),
        newPirPoint.bond
    );
}

function onCommitPointTableForExistingReinforcement(
    context: IMenu2dContext,
    design: DesignC2C,
    unitService: UnitService,
    model: any,
    item: IAdvancedModifiedItem,
    internalValue: any,
    value: string
): any {
    const existingOffsetX = (model.baseMaterialsC2C.newStructure.offsetRight - model.baseMaterialsC2C.newStructure.offsetLeft) / 2;
    const existingOffsetY = (model.baseMaterialsC2C.newStructure.offsetTop - model.baseMaterialsC2C.newStructure.offsetBottom) / 2;
    const oldReinforcementPoint: IDiameterPosition = {
        x: model.reinforcement.longitudinalReinforcementPoints[item.id].x - existingOffsetX,
        y: model.reinforcement.longitudinalReinforcementPoints[item.id].y - existingOffsetY,
        diameter: model.reinforcement.longitudinalReinforcementPoints[item.id].diameter.id,
        bond: model.reinforcement.longitudinalReinforcementPoints[item.id].bond,
        shape: model.reinforcement.longitudinalReinforcementPoints[item.id].shape
    };
    const newReinforcementPoint: IDiameterPosition = {
        x: item.x ? internalValue.value - existingOffsetX : oldReinforcementPoint.x,
        y: item.y ? internalValue.value - existingOffsetY : oldReinforcementPoint.y,
        diameter: item.rebarDiameter ?? oldReinforcementPoint.diameter,
        shape: item.shape ?? oldReinforcementPoint.shape,
        bond: item.bond ?? oldReinforcementPoint.bond
    };

    if (!hasPointTableItemChanged(unitService, item, value, newReinforcementPoint, oldReinforcementPoint)) {
        return null;
    }

    return context.glModelComponent.movePoint2dC2C(
        item.id,
        item.x ? newReinforcementPoint.x : oldReinforcementPoint.x,
        item.y ? newReinforcementPoint.y : oldReinforcementPoint.y,
        getGenericRebarDiameterData(design, item.rebarDiameter ? newReinforcementPoint.diameter : oldReinforcementPoint.diameter),
        item.shape ? newReinforcementPoint.shape : oldReinforcementPoint.shape,
        item.bond ? newReinforcementPoint.bond : oldReinforcementPoint.bond
    );
}

function getRebarDiameterData(design: DesignC2C, diameterId: number): IdValuePair {
    const connectorSizeCodeList = design.designData.designCodeListsC2C[DesignCodeList.FastenerSize] as FastenerSize[];
    const connectorSize = connectorSizeCodeList.find((cs) => cs.id == diameterId);
    return {
        id: diameterId,
        value: connectorSize?.size ?? 0
    };
}

function getGenericRebarDiameterData(design: DesignC2C, diameterId: number): IdValuePair {
    const connectorSizeCodeList = design.designData.designCodeListsC2C[DesignCodeList.GenericRebarDiameter] as GenericRebar[];
    const connectorSize = connectorSizeCodeList.find((cs) => cs.id == diameterId);
    return {
        id: diameterId,
        value: connectorSize?.diameter ?? 0
    };
}

function hasPointTableItemChanged(unitService: UnitService, item: IAdvancedModifiedItem, value: string, newPoint: IDiameterPosition, existingPoint: IDiameterPosition): boolean {
    const pointPositionChanged = item.x != null && newPoint.x != existingPoint.x
        || item.y != null && newPoint.y != existingPoint.y;

    const pointPropertiesChanged = item.rebarDiameter != null && newPoint.diameter != existingPoint.diameter
        || item.bond != null && newPoint.bond != existingPoint.bond
        || item.shape != null && newPoint.shape != existingPoint.shape;

    if (pointPropertiesChanged) {
        return true;
    }

    if (pointPositionChanged) {
        // Check if text hasn't changed
        const valueFormatted = unitService.formatInternalValueAsDefault(
            item.x != null
                ? existingPoint.x
                : existingPoint.y,
            UnitGroup.Length
        );

        return value != valueFormatted;
    }

    return false;
}

function getPointTablePropertyId(type: AdvancedPointsTableType) {
    switch (type) {
        case AdvancedPointsTableType.PostInstallRebar:
            return PropertyMetaDataC2C.Product_C2C_PostInstalledRebar_RebarPoints.id;
        case AdvancedPointsTableType.ExistingReinforcement:
            return PropertyMetaDataC2C.ExistingStructure_C2C_Reinforcement_RebarPoints.id;
        default:
            return undefined;
    }
}
function updatePointTableAddInput(
    context: IMenu2dContext,
    type: number,
    item: IAdvancedPointTableAddInput
) {
    const input = { addInput: item } as any;
    const controlTypeName = getPointTableControlName(type);
    context.setState(menu => updateMainMenuControl(menu, `control-${controlTypeName}`, input));
}
