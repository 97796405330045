import { IConcreteMaterialSettingsInputsData } from '../entities/concrete-material-settings';
import { IDetailedDisplayDesign } from '../entities/display-design';
import {
    NewDesignDataEntityC2C
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';

export function setConcreteMaterialSettingsValuesToDesign(design: IDetailedDisplayDesign, data: IConcreteMaterialSettingsInputsData) {
    design.concreteEpsilonC2 = data.concreteMaterialSettingsEpsilonC2;
    design.concreteEpsionCu2 = data.concreteMaterialSettingsEpsilonCu2;
}

export function setConcreteMaterialSettingsValuesToNewDesign(newDesignC2C: NewDesignDataEntityC2C, data: IConcreteMaterialSettingsInputsData) {
    newDesignC2C.epsilonC2 = data.concreteMaterialSettingsEpsilonC2;
    newDesignC2C.epsilonCU2 = data.concreteMaterialSettingsEpsilonCu2;
}
