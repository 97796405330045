<pe-export-report-row-base
    [displayName]="displayDesign.name"
    [displayApprovalNumber]="displayDesign.regionDesignStandardApprovalNumber"
    [percentage]="percentage"
    [message]="message"
    [status]="status"
    (removeExportRow)="removeRow()"
    >
</pe-export-report-row-base>

<c2c-zone-analysis *ngIf="zoneAnalysisInput"
    [collapsed]="false"
    [zoneAnalysisInput]="zoneAnalysisInput"
    [design]="design"
    style="display: none;">
</c2c-zone-analysis>
