import { ZoneName } from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';

export function getZoneName(zone: number): string {
    switch (zone as ZoneName) {
      case ZoneName.Z1:
      case ZoneName.Z1Symmetric:
        return 'Z1';
      case ZoneName.Z2:
      case ZoneName.Z2Symmetric:
        return 'Z2';
      case ZoneName.Z3:
      case ZoneName.Z3Symmetric:
        return 'Z3';
      case ZoneName.Z4:
      case ZoneName.Z4Symmetric:
        return 'Z4';
      default:
        return 'Unknown';
    }
  }

  export function getZoneNameByName(zone: string): ZoneName {
    switch (zone) {
      case 'Z1':
      case 'Z1\'':
        return ZoneName.Z1;
      case 'Z2':
      case 'Z2\'':
        return ZoneName.Z2;
      case 'Z3':
      case 'Z3\'':
        return ZoneName.Z3;
      case 'Z4':
      case 'Z4\'':
        return ZoneName.Z4;
      default:
        return ZoneName.Unknown;
    }
  }