import {
    CommonRegion, ICommonRegionConstructor
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { GeneralValue } from '@profis-engineering/pe-ui-common/entities/user-settings';
import { getSpecialRegionName } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import {
    CommonCodeList, CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';

import { IC2CFixing } from '../entities/c2c-fixing';
import {
    MethodGroupConnTypeStandardRegion
} from '../entities/code-lists/connection-type-method-standard-regions';
import {
    DesignMethodGroup as DesignMethodGroupC2C
} from '../entities/code-lists/design-method-group';
import { DesignStandard as DesignStandardC2C } from '../entities/code-lists/design-standard';
import { ProjectCodeList as ProjectCodeListC2C } from '../enums/project-code-list';
import {
    ConnectionType, DesignStandard
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { defaultUiPropertyValue } from '../helpers/ui-property-helpers';
import { PropertyMetaDataC2C } from '../properties/properties';
import { CodeListServiceBase } from '../services/code-list.service.base';

export class AppSettingsHelperC2C {
    constructor(
        protected commonCodeList: CommonCodeListServiceBase,
        protected codeListC2C: CodeListServiceBase
    ) { }

    public getDesignMethodGroupsC2C(designStandardId: number, connectionTypeId: number, regionId: number) {
        const region = this.getRegionById(regionId);

        if (region == null || designStandardId == null) {
            return [] as DesignMethodGroupC2C[];
        }

        const connectionTypeIds = [connectionTypeId];
        if (connectionTypeId == ConnectionType.Splices) {
            connectionTypeIds.push(ConnectionType.StructuralJoints);
        }

        const methodGroupConnTypeStandardRegions = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];
        let designMethodGroups = (this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignMethodGroupsC2C] as DesignMethodGroupC2C[])
            .filter((methodGroup) => methodGroupConnTypeStandardRegions.some(x => x.designMethodGroupId == methodGroup.id && x.regionId == regionId &&
                x.designStandardId == designStandardId && connectionTypeIds.includes(x.connectionTypeId ?? 0)));

        designMethodGroups = designMethodGroups.filter((methodGroup, index, self) => {
            return self.findIndex(v => v.groupId == methodGroup.groupId) == index;
        });

        return designMethodGroups;
    }

    public setDefaultC2CUnits(c2cFixing: IC2CFixing, region: CommonRegion) {
        c2cFixing.lengthC2C = this.getRegionDefaultUnit(region.defaultUnitLength ?? 0, region?.id ?? 0, PropertyMetaDataC2C.Option_UnitLength.id);
        c2cFixing.areaC2C = this.getRegionDefaultUnit(region.defaultUnitArea ?? 0, region?.id ?? 0, PropertyMetaDataC2C.Option_UnitArea.id);
        c2cFixing.stressC2C = region.defaultUnitStress ?? 0;
        c2cFixing.forceC2C = region.defaultUnitForce ?? 0;
        c2cFixing.momentC2C = region.defaultUnitMoment ?? 0;
        c2cFixing.temperatureC2C = region.defaultUnitTemperature ?? 0;
        c2cFixing.forcePerLengthC2C = region.defaultUnitForcePerLength ?? 0;
        c2cFixing.momentPerLengthC2C = region.defaultUnitMomentPerLength ?? 0;
        c2cFixing.densityC2C = region.defaultUnitDensity ?? 0;
        c2cFixing.areaPerLengthC2C = this.getRegionDefaultUnit(region.defaultUnitAreaPerLength ?? 0, region?.id ?? 0, PropertyMetaDataC2C.Option_UnitAreaPerLength.id);
    }

    public getRegionDefaultUnit(regionDefaultUnit: number, regionId: number, propertyId: number): number {
        const propertyValue = this.codeListC2C.getPropertyValue(propertyId, regionId, 0, 0);

        return propertyValue?.defaultValue ?? regionDefaultUnit;
    }

    public setDefaultC2CDesignStandard(c2cFixing: IC2CFixing, regionId: number, connectionTypeId: number) {
        const propertyValue = this.codeListC2C.getPropertyValue(PropertyMetaDataC2C.Option_C2C_DesignStandard.id, regionId, connectionTypeId);

        if (connectionTypeId == ConnectionType.ConcreteOverlay) {
            c2cFixing.overlayDesignStandardIdC2C = propertyValue?.defaultValue ?? 0;
        }
        else {
            c2cFixing.designStandardIdC2C = propertyValue?.defaultValue ?? 0;
        }
    }

    public setDefaultC2CDesignMethod(c2cFixing: IC2CFixing, regionId: number, designStandardId: number, connectionTypeId: number) {
        const defaultValue = defaultUiPropertyValue(PropertyMetaDataC2C.Option_C2C_DesignMethodGroup.id,
            this.codeListC2C, regionId, designStandardId, connectionTypeId);

        let designMethodGroupId = -1;
        if (defaultValue) {
            designMethodGroupId = defaultValue;
        }
        else {
            const designMethodGroups = this.getDesignMethodGroupsC2C(designStandardId, connectionTypeId, regionId);
            designMethodGroupId = designMethodGroups.length > 0 ? designMethodGroups[0].id ?? 0 : -1;
        }

        if (connectionTypeId == ConnectionType.ConcreteOverlay) {
            c2cFixing.overlayDesignMethodGroupIdC2C = designMethodGroupId;
        }
        else {
            c2cFixing.designMethodGroupIdC2C = designMethodGroupId;
        }
    }

    public isEntryPointSplitted(regionId: number, designStandardId?: number) {
        switch(designStandardId) {
            case DesignStandard.ACI:
            case DesignStandard.CSA:
                return false;
            case DesignStandard.ETAG:
            case DesignStandard.ASBased:
                return true;
            default:
                return this.getDefaultEntryPointSplitted(regionId);
          }
    }

    private getDefaultEntryPointSplitted(regionId: number): boolean {
        const defaultValue = defaultUiPropertyValue(PropertyMetaDataC2C.Option_C2C_DesignStandard.id,
            this.codeListC2C, regionId);

        if (defaultValue == DesignStandard.ACI || defaultValue == DesignStandard.CSA) {
            return false;
        }

        return true;
    }

    public getRegionById(regionId: number) {
        if (regionId < 0) {
            this.getCustomRegion(regionId);
        }

        const regionCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(r => r.id == regionId);
    }

    public createDefaultRegion() {
        return new CommonRegion({
            id: 0,
            countryCode: '',
            supportPhone: '',
            supportHours: '',
            worldAreaId: 1,
            profis3KBUrl: '',
            profis3KBUrlAci: '',
            hiltiOnlineUrl: '',
            onlineTenderTextsUrl: '',
            hubId: 1,
            hiltiDataConsentUrl: '',
            bpRigidityCheckUrl: '',
            onlineTechnicalLibraryUrl: '',
            culture: '',
            zipCodeFormat: '',
            dlubalEnabled: false
        } as ICommonRegionConstructor);
    }

    public updateSetting(setting: GeneralValue<number | null>, value: number | undefined) {
        setting.value = value ?? null;
    }

    private getCustomRegion(regionId: number) {
        return new CommonRegion({
            id: regionId,
            countryCode: getSpecialRegionName(regionId),
            supportPhone: '',
            supportHours: '',
            worldAreaId: 1,
            profis3KBUrl: '',
            profis3KBUrlAci: '',
            hiltiOnlineUrl: '',
            onlineTenderTextsUrl: '',
            hubId: 1,
            hiltiDataConsentUrl: '',
            bpRigidityCheckUrl: '',
            onlineTechnicalLibraryUrl: '',
            culture: '',
            zipCodeFormat: '',
            dlubalEnabled: false
        } as ICommonRegionConstructor);
    }

    public getDesignStandards(regionId: number, connectionType: number | undefined = undefined) : DesignStandardC2C[] {
        const filterByConnectionType = connectionType != undefined;

        const allDesignStandards = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignStandardsC2C] as DesignStandardC2C[];

        const methodGroupConnTypeStandardRegions = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.MethodGroupConnTypeStandardRegionsC2C] as MethodGroupConnTypeStandardRegion[];

        const designMethodGroups = (this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignMethodGroupsC2C] as DesignMethodGroupC2C[])
            .filter((methodGroup) => methodGroupConnTypeStandardRegions
            .some(x => x.designMethodGroupId == methodGroup.id && x.regionId == regionId && (filterByConnectionType ? x.connectionTypeId == connectionType : true))) || [];

        const standards = allDesignStandards
            .filter(standard => designMethodGroups
                .filter(designMethod => methodGroupConnTypeStandardRegions
                    .filter(x => x.designMethodGroupId == designMethod.id && x.regionId == regionId && (filterByConnectionType ? x.connectionTypeId == connectionType : true))
                    .map(y => y.designStandardId)
                    .includes(standard.id)).length > 0);

        return standards;
    }
}
