import clone from 'lodash-es/clone';

import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { SafetyFactorRegion } from '../../../shared/entities/code-lists/safety-factor-region';
import { UIProperty as UIPropertyC2C } from '../../../shared/entities/code-lists/UI-property';
import {
    IConcreteMaterialInputsData
} from '../../../shared/entities/concrete-material-inputs-data';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    BaseMaterialDataEntityC2C
} from '../../../shared/generated-modules/Hilti.PE.C2CCodeListService.Entities';
import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ConcreteMaterialOptionsAs
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';
import {
    DesignMethodGroup
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IPropertyMetaDataC2C, PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { getPropertyValueById } from '../../helpers/ui-property-helper';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';

@Component({
    templateUrl: './concrete-material-inputs.component.html',
    styleUrls: ['./concrete-material-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ConcreteMaterialInputsComponent implements OnInit, DoCheck {
    @Input()
    public data!: IConcreteMaterialInputsData;

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    @Input()
    public designMethodGroupId?: number;

    public concreteAlphaCc!: NumericTextBoxProps;
    public concreteEpsilonC2!: NumericTextBoxProps;
    public concreteEpsilonCu2!: NumericTextBoxProps;
    public concreteAlphaCt!: NumericTextBoxProps;
    public concreteMinCover!: NumericTextBoxProps;
    public beta!: NumericTextBoxProps;
    public dataLoaded = false;

    private oldRegionId!: number;
    private oldDesignMethodGroupId: number | undefined;
    private oldData!: IConcreteMaterialInputsData;

    constructor(
        private localizationService: LocalizationService,
        private codeList: CodeListService,
        private unit: UnitService
    ) { }

    ngOnInit(): void {
        this.initConcreteMaterialControls();
    }

    ngDoCheck(): void {
        if (!this.dataLoaded && this.data != null) {
            this.setDefaultValues();
            this.refreshControls();

            this.dataLoaded = true;
        }

        this.updateData();
    }

    public get useAusOptions() {
        return this.designMethodGroupId == DesignMethodGroup.AS3600ETA;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public get epsilonC2Value() {
        return this.useAusOptions ? this.data.concreteMaterialOptionsAs.epsilonC2 : this.data.concreteEpsilonC2;
    }

    public get epsilonCu2Value() {
        return this.useAusOptions ? this.data.concreteMaterialOptionsAs.epsilonCU2 : this.data.concreteEpsilonCu2;
    }

    public get minConcreteCoverValue() {
        return this.useAusOptions ? this.data.concreteMaterialOptionsAs.minConcreteCover : this.data.minConcreteCover;
    }

    public onEpsilonC2Change(value: number) {
        if (this.useAusOptions)
            this.data.concreteMaterialOptionsAs.epsilonC2 = value;
        else
            this.data.concreteEpsilonC2 = value;

    }

    public onEpsilonCu2Change(value: number) {
        if (this.useAusOptions)
            this.data.concreteMaterialOptionsAs.epsilonCU2 = value;
        else
            this.data.concreteEpsilonCu2 = value;
    }

    public onMinConcreteCoverChange(value: number) {
        if (this.useAusOptions)
            this.data.concreteMaterialOptionsAs.minConcreteCover = value;
        else
            this.data.minConcreteCover = value;
    }

    private get defaultEpsilonC2() {
        if (this.useAusOptions) {
            const epsilonC2 = this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_EpsilonC2.id);
            return epsilonC2?.defaultValue ?? 0;
        }

        const baseMaterial = this.codeList.projectCodeListsC2C[ProjectCodeList.BaseMaterialDataC2C] as unknown as BaseMaterialDataEntityC2C[];
        return baseMaterial.find(sf => sf.regionId == this.regionId)?.epsilonC2;
    }

    private get defaultEpsilonCU2() {
        if (this.useAusOptions) {
            const epsilonCu2 = this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_EpsilonCu2.id);
            return epsilonCu2?.defaultValue ?? 0;
        }

        const baseMaterial = this.codeList.projectCodeListsC2C[ProjectCodeList.BaseMaterialDataC2C] as unknown as BaseMaterialDataEntityC2C[];
        return baseMaterial.find(sf => sf.regionId == this.regionId)?.epsilonCu2;
    }

    private get defaultAlphaCC() {
        const safetyFactors = this.codeList.projectCodeListsC2C[ProjectCodeList.SafetyFactorRegionC2C] as SafetyFactorRegion[];
        return safetyFactors.find(sf => sf.regionId == this.regionId)?.defaultAlphaCC;
    }

    private get defaultAlphaCT() {
        const safetyFactors = this.codeList.projectCodeListsC2C[ProjectCodeList.SafetyFactorRegionC2C] as SafetyFactorRegion[];
        return safetyFactors.find(sf => sf.regionId == this.regionId)?.defaultAlphaCT;
    }

    private get defaultBeta() {
        const beta = this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_FactorBeta.id);
        return beta?.defaultValue ?? 0;
    }

    private get defaultMinCoverTop() {
        if (this.useAusOptions) {
            const minCoverTop = this.getPropertyValueById(PropertyMetaDataC2C.Options_C2C_AS_ConcreteCover.id);
            return minCoverTop?.defaultValue ?? 0;
        }

        const c2cUIProperties = this.codeList.projectCodeListsC2C[ProjectCodeList.UIPropertiesC2C] as UIPropertyC2C[];
        const propertyValues = c2cUIProperties.find((property) => property.id == UIProperty.Option_C2C_MinCoverTop);

        let propertyValue = propertyValues?.propertyValues?.find((property) => property.regionId == this.regionId);
        if (!propertyValue) {
            propertyValue = propertyValues?.propertyValues?.find((property) => property.regionId == 0);
        }
        return propertyValue?.defaultValue;
    }

    private updateData(): void {
        let update = false;

        if (this.oldRegionId != this.regionId) {
            this.refreshControls();

            this.oldRegionId = this.regionId;
        }

        if (this.oldDesignMethodGroupId != this.designMethodGroupId) {
            this.refreshControls();
            this.refreshTooltips();

            this.oldDesignMethodGroupId = this.designMethodGroupId;
        }

        if (this.oldData != null) {
            if (this.oldData.connectionType != this.data.connectionType) {
                update = true;
                this.refreshControls();
            }

            if (this.oldData.lengthUnit != this.data.lengthUnit) {
                update = true;
                this.refreshControls();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);
        }
    }

    private updateControl(control: NumericTextBoxProps, propertyMetaData: IPropertyMetaDataC2C, defaultValue: number, unit: Unit) {
        const propertyValue = this.codeList.getPropertyValue(propertyMetaData.id, this.regionId, this.data.connectionType ?? 0, undefined, this.designMethodGroupId ?? 0);

        if (propertyValue) {
            control.maxValue = propertyValue.maxValue;
            control.minValue = propertyValue.minValue;
        }

        control.precision = this.unit.getPrecision(unit, propertyMetaData.id);
        control.placeholder = this.getTextBoxPlaceholder(defaultValue, unit, control.precision);
        control.unit = unit;
    }

    private getTextBoxPlaceholder(value: number, unit: Unit, precision?: number) {
        const defaultPrecision = this.unit.getPrecision(unit);

        const defaultUnit = unit == Unit.None ? Unit.None : Unit.mm;

        const result = this.unit.formatUnitValueArgs(
            this.unit.convertUnitValueArgsToUnit(
                value,
                defaultUnit,
                unit
            ),
            unit,
            precision ?? defaultPrecision
        );

        if (result == null) {
            return this.translate('Agito.Hilti.Profis3.ApplicationSettings.Default');
        }

        return result;
    }

    private setDefaultValues() {
        if (this.data.design != null) {
            const design = this.data.design();
            this.data.connectionType = design.connectionType;

            this.data.concreteAlphaCc = design.alphaCcC2C;
            this.data.concreteEpsilonC2 = design.concreteEpsilonC2;
            this.data.concreteEpsilonCu2 = design.concreteEpsionCu2;
            this.data.concreteAlphaCt = design.alphaCtC2C;
            this.data.minConcreteCover = design.minConcreteCover;

            this.data.concreteMaterialOptionsAs = {
                beta: design.designOptionsAs?.concreteMaterialOptions.beta,
                epsilonC2: design.designOptionsAs?.concreteMaterialOptions.epsilonC2,
                epsilonCU2: design.designOptionsAs?.concreteMaterialOptions.epsilonCU2,
                minConcreteCover: design.designOptionsAs?.concreteMaterialOptions.minConcreteCover
            } as ConcreteMaterialOptionsAs;
        }
    }

    private refreshControls() {
        this.updateControl(this.concreteAlphaCt, PropertyMetaDataC2C.Option_C2C_AlphaCT, this.defaultAlphaCT ?? 0, Unit.None);
        this.updateControl(this.concreteEpsilonC2, PropertyMetaDataC2C.Option_C2C_EpsilonC2, this.defaultEpsilonC2 ?? 0, Unit.None);
        this.updateControl(this.concreteEpsilonCu2, PropertyMetaDataC2C.Option_C2C_EpsilonCU2, this.defaultEpsilonCU2 ?? 0, Unit.None);
        this.updateControl(this.concreteAlphaCc, PropertyMetaDataC2C.Option_C2C_AlphaCC, this.defaultAlphaCC ?? 0, Unit.None);
        this.updateControl(this.concreteMinCover, PropertyMetaDataC2C.Option_C2C_MinCoverTop, this.defaultMinCoverTop ?? 0, this.data.lengthUnit as Unit);
        this.updateControl(this.beta, PropertyMetaDataC2C.Options_C2C_AS_FactorBeta, this.defaultBeta ?? 0, Unit.None);
    }

    private refreshTooltips() {
        this.concreteEpsilonC2.tooltip = this.epsilonC2Tooltip;
        this.concreteEpsilonCu2.tooltip = this.epsilonCu2Tooltip;
    }

    private getPropertyValueById(id: number) {
        return getPropertyValueById(id, this.codeList, this.regionId, undefined, this.data.connectionType, this.designMethodGroupId);
    }

    private initConcreteMaterialControls() {
        this.concreteAlphaCc = {
            id: 'concrete-material-alphaCC',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ReductionFactors.AlphaCC'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ReductionFactors.AlphaCC'), content: this.translate('Agito.Hilti.C2C.ApplicationSettings.ReductionFactors.AlphaCC.Tooltip') }
        };

        this.concreteAlphaCt = {
            id: 'concrete-material-alphaCT',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ReductionFactors.AlphaCT'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ReductionFactors.AlphaCT'), content: this.translate('Agito.Hilti.C2C.ApplicationSettings.ReductionFactors.AlphaCT.Tooltip') }
        };

        this.concreteEpsilonC2 = {
            id: 'concrete-material-epsilonC2',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.EpsilonC2'),
            tooltip: this.epsilonC2Tooltip
        };

        this.concreteEpsilonCu2 = {
            id: 'concrete-material-epsilonCU2',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.EpsilonCu2'),
            tooltip: this.epsilonCu2Tooltip
        };

        this.concreteMinCover = {
            id: 'concrete-material-minConcreteCover',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.MinConcreteCover')
        };

        this.beta = {
            id: 'concrete-material-beta',
            title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.Beta'),
            tooltip: { title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.Beta'), content: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.Beta.Tooltip') }
        };
    }

    private get epsilonCu2Tooltip() {
        return { title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.EpsilonCu2'), content: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.EpsilonCu2.Tooltip') };
    }

    private get epsilonC2Tooltip() {
        return { title: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.EpsilonC2'), content: this.translate('Agito.Hilti.C2C.ApplicationSettings.ConcreteMaterial.EpsilonC2.Tooltip') };
    }
}
