import { Component, ElementRef, Input, NgZone, OnChanges, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignC2C as Design } from '../../../shared/entities/design-c2c';
import { CollapseState } from '../../../shared/enums/collapse-state';
import { CollapsingControls } from '../../../shared/enums/collapsing-controls';
import { LoadCombinationC2C } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ConnectionType, DesignForYield, DesignMethod, LoadType, LongitudinalReinforcementOption, UtilizationValueEntityC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { FeatureFlagTypes } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import { DesignMethodHelper } from '../../helpers/design-method-helper';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { includeSprites } from '../../sprites';

export interface ILayerResult {
    id: string;
    title: string;
    subtitle: string;
    value: number;
    percentage: string;
    drillingLength: number;
    anchorageLengthTension: number;
    anchorageLengthCompression : number;
    lapLength: number;
    lapLengthCIP: number;
    lapLengthPI: number;
    valueError: boolean;
    isSpliceExtensionResult: boolean;
    showMainUtilization: boolean;
    detailedResults: ILayerResultDetailed[];
}

interface ILayerResultDetailed {
    title: string;
    value: number;
    unitGroup: UnitGroup;
}

export enum ViewType {
    TopLayer1 = 'TopLayer1',
    TopLayer2 = 'TopLayer2',
    BottomLayer1 = 'BottomLayer1',
    BottomLayer2 = 'BottomLayer2',
    DrillLength = 'DrillLength',
    Shear = 'Shear',
    ConcreteBreakout = 'ConcreteBreakout',
    ConcreteSplitting = 'ConcreteSplitting',
    CompressionStrut = 'CompressionStrut',
    ShearResistance = 'ShearResistance',
    Tie = 'Tie',
    TotalProductionEmissions = 'totalProductionEmissions',
}

@Component({
    templateUrl: './layer-utilizations.component.html',
    styleUrls: ['./utilizations-base.component.scss', './layer-utilizations.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LayerUtilizationsComponent implements OnChanges {
    CollapseState = CollapseState;

    @Input()
    public id!: string;

    @Input()
    public design?: Design;

    @Input()
    public collapsingControl: CollapsingControls;

    public collapsed = false;

    public layersData: Array<ILayerResult> = new Array<ILayerResult>();

    public collapseState: CollapseState = CollapseState.Collapsed;

    public unitGroupEnum = {
        Percentage: UnitGroup.Percentage,
        Length: UnitGroup.Length
    };

    private readonly validValueLimit: number = 100;

    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        public unit: UnitService,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone,
        private modalService: ModalService
    ) {
        this.collapsingControl = CollapsingControls.DrillLengthUtilization;
    }

    public get showUtilizations() {
        if (this.design?.designData.projectDesignC2C == undefined) {
            return false;
        }
        return this.design.isPirEuOrAus && this.hasUtilizations && this.haveResultsForMethod(this.design.designData.projectDesignC2C.options.selectedDesignMethod);
    }

    private get drillingLengthTitleKey() {
        if (this.isEN199211) {
            return 'Agito.Hilti.C2C.LayerUtilization.AnchorageLength';
        }

        const isAefac = this.design?.isPirAus && this.design.designData.projectDesignC2C.options.selectedDesignMethod == DesignMethod.AEFACTN08;
        return isAefac
            ? 'Agito.Hilti.C2C.LayerUtilization.AEFACTN08.AnchorageLength'
            : 'Agito.Hilti.C2C.LayerUtilization.HiltiMethod.AnchorageLength';
    }

    public get drillingLengthSpliceExtTitle(): string {
        return this.localizationService.getString(this.drillingLengthTitleKey);
    }

    public get hasUtilizations() {

        if (this.design?.designData.reportDataC2C?.utilizations == undefined || this.design.designData.projectDesignC2C == undefined) {
            return false;
        }

        const postInstalledRebarPointsExist = this.design.designData.projectDesignC2C.product.postInstalledRebarPoints.length > 0 && this.design.designData.projectDesignC2C.product.connectorEmbedmentDepth > 0;
        const haveLayers = postInstalledRebarPointsExist && this.design.designData.projectDesignC2C.product.reinforcementOption == LongitudinalReinforcementOption.ReinforcementLayers;
        const haveArrangement = postInstalledRebarPointsExist && [LongitudinalReinforcementOption.ReinforcementArrangement, LongitudinalReinforcementOption.IrregularPlacement, LongitudinalReinforcementOption.Single].includes(this.design.designData.projectDesignC2C.product.reinforcementOption);

        switch (this.collapsingControl) {
            case CollapsingControls.TopLayer1Utilization: {
                return haveLayers && this.design.designData.reportDataC2C.utilizations.some(u => u.name == ViewType.TopLayer1);
            }
            case CollapsingControls.TopLayer2Utilization: {
                return haveLayers && this.design.designData.reportDataC2C.utilizations.some(u => u.name == ViewType.TopLayer2);
            }
            case CollapsingControls.BottomLayer1Utilization: {
                return haveLayers && this.design.designData.reportDataC2C.utilizations.some(u => u.name == ViewType.BottomLayer1);
            }
            case CollapsingControls.BottomLayer2Utilization: {
                return haveLayers && this.design.designData.reportDataC2C.utilizations.some(u => u.name == ViewType.BottomLayer2);
            }
            case CollapsingControls.DrillLengthUtilization: {
                return haveArrangement && this.design.designData.reportDataC2C.utilizations.some(u => u.name == ViewType.DrillLength);
            }
            case CollapsingControls.ShearUtilization: {
                return postInstalledRebarPointsExist && this.selectedLoadCombination?.shear;
            }
            case CollapsingControls.ConcreteBreakoutUtilization: {
                return postInstalledRebarPointsExist && this.design.designData.reportDataC2C.utilizations.some(u => u.name == ViewType.ConcreteBreakout);
            }
            case CollapsingControls.ConcreteSplittingUtilization: {
                return postInstalledRebarPointsExist && this.design.designData.reportDataC2C.utilizations.some(u => u.name == ViewType.ConcreteSplitting);
            }
            case CollapsingControls.StrutAndTiesUtilization: {
                return this.design.designData.reportDataC2C.utilizations.some(u => u.name == ViewType.CompressionStrut || u.name == ViewType.ShearResistance || u.name.startsWith(ViewType.Tie));
            }
            default:
                throw new Error('Unknown control!');
        }
    }

    public get isEN199211() {
        return (this.design?.designData.projectDesignC2C?.options.selectedDesignMethod === DesignMethod.EN199211)
            || (this.design?.designData.projectDesignC2C?.options.selectedDesignMethod === DesignMethod.EN199211StrutAndTie);
    }

    public get isAS3600() {
        return this.design?.designData.projectDesignC2C?.options.selectedDesignMethod === DesignMethod.AS3600;
    }

    public get showCompressionTensionUtilizations(): boolean {
        return this.isAS3600 && this.design?.connectionType == ConnectionType.StructuralJoints;
    }

    public get maxLayersValue(): number | undefined {
        const values = this.layersData.filter(x => x.value != undefined).map(x => x.value);
        return this.layersData.length == 0
            ? undefined
            : Math.max(...values);
    }

    public get utilizationTitle(): string {
        if (this.collapsingControl == CollapsingControls.StrutAndTiesUtilization) {
            return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.StrutAndTies');
        }

        return this.getTitle(this.collapsingControlToViewType);
    }

    public get utilizationInvalid() {
        return this.layersData.length != 0 && this.isUtilizationValueError(this.maxLayersValue);
    }

    public get utilizationValue() {
        if (this.maxLayersValue == undefined) {
            return 0;
        }

        return +this.maxLayersValue;
    }

    public get isFireDesignSelected(): boolean {
        return this.design?.designData.projectDesignC2C?.loads.loadType === LoadType.Fire;
    }

    public get infoBoxPopup(): any {
        if (this.design?.isFeatureEnabled(FeatureFlagTypes.StrutAndTieResults) && this.collapsingControl == CollapsingControls.StrutAndTiesUtilization) {
            return () => { this.modalService.openInfoDialogC2C('OpenStrutAndTiePopup', 'lg'); };
        }

        return undefined;
    }

    private get collapsingControlToViewType(): ViewType {
        switch (this.collapsingControl) {
            case CollapsingControls.TopLayer1Utilization:
                return ViewType.TopLayer1;
            case CollapsingControls.TopLayer2Utilization:
                return ViewType.TopLayer2;
            case CollapsingControls.BottomLayer1Utilization:
                return ViewType.BottomLayer1;
            case CollapsingControls.BottomLayer2Utilization:
                return ViewType.BottomLayer2;
            case CollapsingControls.DrillLengthUtilization:
                return ViewType.DrillLength;
            case CollapsingControls.ShearUtilization:
                return ViewType.Shear;
            case CollapsingControls.ConcreteBreakoutUtilization:
                return ViewType.ConcreteBreakout;
            case CollapsingControls.ConcreteSplittingUtilization:
                return ViewType.ConcreteSplitting;
            default:
                throw new Error('Unknown control!');
        }
    }

    ngOnChanges(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-rebar-icon',
            'sprite-arrow-up',
            'sprite-arrow-down'
        );

        this.collapsed = this.userSettings.isSectionCollapsed(this.collapsingControl);
        this.design?.onStateChanged(() => {
            // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
            const onStateChanged = () => {
                this.setLayersData();
            };
            return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
        });
        this.setLayersData();
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(this.collapsingControl, collapsed);
    }

    public collapseToggled(state: CollapseState) {
        this.collapseState = state;
    }

    public getPercentageValue(value: number): string {
        return this.unit.formatInternalValueAsDefault(value, UnitGroup.Percentage);
    }

    public showDrillingLengthSpliceExt(layerData: ILayerResult): boolean {
        return layerData.drillingLength != undefined && !layerData.isSpliceExtensionResult && !this.showCompressionTensionUtilizations;
    }

    public checkYieldText(key: string): string {
        return this.design?.designData.projectDesignC2C?.loads.designForYield == DesignForYield.DesignForYield ? `${key}Yield` : key;
    }

    public showLapLengthSpliceExt(layerData: ILayerResult): boolean {
        return layerData.lapLength != undefined && layerData.isSpliceExtensionResult;
    }

    public showCollapsingUtilizations(layerData: ILayerResult): boolean {
        return this.collapseState == CollapseState.Extended && this.collapsingUtilizationsAvailable(layerData);
    }

    public collapsingUtilizationsAvailable(layerData: ILayerResult): boolean {
        return this.hasLapLengthCIP(layerData) || this.hasLapLengthPI(layerData);
    }

    public hasLapLengthCIP(layerData: ILayerResult): boolean {
        return !this.isFireDesignSelected && layerData.lapLengthCIP != undefined && layerData.isSpliceExtensionResult;
    }

    public hasLapLengthPI(layerData: ILayerResult): boolean {
        return !this.isFireDesignSelected && layerData.lapLengthPI != undefined && layerData.isSpliceExtensionResult;
    }

    public haveResultsForMethod(method: DesignMethod): boolean {
        const key = DesignMethodHelper.mapDesignMethodToString(method);
        return this.design?.designData.reportDataC2C?.designMethodResultsItems[key] !== undefined;
    }

    public setLayersData() {
        const data = new Array<ILayerResult>();

        if (this.hasUtilizations) {
            switch (this.collapsingControl) {
                case CollapsingControls.ShearUtilization:
                    data.push(this.prepareShearData(this.collapsingControlToViewType));
                    break;
                case CollapsingControls.StrutAndTiesUtilization:
                    data.push(this.prepareData(ViewType.CompressionStrut));
                    data.push(this.prepareData(ViewType.ShearResistance));
                    data.push(this.prepareStrutAndTieResultsData());
                    break;
                default:
                    data.push(this.prepareData(this.collapsingControlToViewType));
            }
        }

        this.layersData = data;
    }

    public progressPercent(value: number): number {
        return Number.isFinite(value) ? value : 100;
    }

    private getTitle(viewType: ViewType): string {
        switch (viewType) {
            case ViewType.TopLayer1: {
                return this.localizationService.getString('Agito.Hilti.C2C.RebarLoads.Table.Layers.TopLayer1');
            }
            case ViewType.TopLayer2: {
                return this.localizationService.getString('Agito.Hilti.C2C.RebarLoads.Table.Layers.TopLayer2');
            }
            case ViewType.BottomLayer1: {
                return this.localizationService.getString('Agito.Hilti.C2C.RebarLoads.Table.Layers.BottomLayer1');
            }
            case ViewType.BottomLayer2: {
                return this.localizationService.getString('Agito.Hilti.C2C.RebarLoads.Table.Layers.BottomLayer2');
            }
            case ViewType.DrillLength: {
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.DrillLength');
            }
            case ViewType.Shear: {
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.Shear');
            }
            case ViewType.ConcreteBreakout: {
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.ConcreteBreakout');
            }
            case ViewType.ConcreteSplitting: {
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.ConcreteSplitting');
            }
            case ViewType.ShearResistance: {
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.ShearResistance');
            }
            case ViewType.CompressionStrut: {
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.CompressionStrut');
            }
            default: {
                throw new Error('Unknown type.');
            }
        }
    }

    private getSubtitle(viewType: ViewType): string {
        switch (viewType) {
            case ViewType.TopLayer1:
            case ViewType.TopLayer2:
            case ViewType.BottomLayer1:
            case ViewType.BottomLayer2:
            case ViewType.DrillLength:
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.Steel');
            case ViewType.ConcreteBreakout:
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.ConcreteBreakout');
            case ViewType.ConcreteSplitting:
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.ConcreteSplitting');
            case ViewType.Shear:
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.Shear');
            case ViewType.CompressionStrut:
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.CompressionStrut');
            case ViewType.ShearResistance:
                return this.localizationService.getString('Agito.Hilti.C2C.LayerUtilization.ShearResistance');
            default:
                throw new Error('Unknown type.');
        }
    }

    private getId(viewType: ViewType): string {
        switch (viewType) {
            case ViewType.CompressionStrut:
                return 'compression-strut-utilization';
            case ViewType.ShearResistance:
                return 'shear-resistance-utilization';
            default: {
                return `${this.id}-profile-utilizations-button`;
            }
        }
    }

    private prepareStrutAndTieResultsData(): ILayerResult {
        const utilizations = this.design?.designData.reportDataC2C?.utilizations?.filter(u => u.name.startsWith(ViewType.Tie));

        const detailedResults: ILayerResultDetailed[] = new Array<ILayerResultDetailed>;
        utilizations?.forEach(utilization => {
            detailedResults.push({
                title: this.localizationService
                    .getString(`Agito.Hilti.C2C.LayerUtilization.StrutAndTieAsv`)
                    .replace('{param}', utilization.name
                    .slice(3)),
                value: +utilization.value.value,
                unitGroup: UnitGroup.Area
            } as ILayerResultDetailed);
        });

        return {
            id: 'tie-asv-utilizations',
            detailedResults: detailedResults,
        } as ILayerResult;
    }

    private prepareData(viewType: ViewType): ILayerResult {
        const utilization = this.design?.designData.reportDataC2C?.utilizations?.filter(u => u.name == viewType)[0];
        const utilizationValue = utilization?.value ?? {
            value: '99',
            installationLength: '123',
            isSpliceExtensionResult: false,
            lapLength: '8',
            lapLengthCIP: '92',
            lapLengthPI: '23',
            percentage: '8',
            status: true
        } as UtilizationValueEntityC2C;
        const value = +utilizationValue.value;

        return {
            id: this.getId(viewType),
            title: this.getTitle(viewType),
            subtitle: this.getSubtitle(viewType),
            percentage: utilizationValue.percentage,
            value,
            drillingLength: utilizationValue.installationLength != null ? parseFloat(utilizationValue.installationLength) : undefined,
            anchorageLengthTension: utilizationValue.installationLengthTension ? parseFloat(utilizationValue.installationLengthTension) : undefined,
            anchorageLengthCompression: utilizationValue.installationLengthCompression ? parseFloat(utilizationValue.installationLengthCompression) : undefined,
            lapLength: utilizationValue.lapLength != null ? parseFloat(utilizationValue.lapLength) : 0,
            lapLengthCIP: utilizationValue.lapLengthCIP != null ? parseFloat(utilizationValue.lapLengthCIP) : 0,
            lapLengthPI: utilizationValue.lapLengthPI != null ? parseFloat(utilizationValue.lapLengthPI) : 0,
            valueError: this.isUtilizationValueError(value),
            isSpliceExtensionResult: utilizationValue.isSpliceExtensionResult ?? false,
            showMainUtilization: true
        } as ILayerResult;
    }

    private prepareShearData(viewType: ViewType): ILayerResult {
        const shear = this.selectedLoadCombination?.shear;
        const value = +(shear ? shear?.value : Number.NaN);

        return {
            id: this.id,
            title: this.getTitle(viewType),
            subtitle: this.getSubtitle(viewType),
            percentage: shear?.percentage,
            value,
            valueError: this.isUtilizationValueError(value),
            showMainUtilization: true
        } as unknown as ILayerResult;
    }

    private isUtilizationValueError(value: number | undefined): boolean {
        return value == undefined || Number.isNaN(value) || Math.abs(value) > this.validValueLimit;
    }

    private get selectedLoadCombination(): LoadCombinationC2C | undefined {
        const loadCombinations = this.design?.loadCombinationsC2C ?? [];
        return loadCombinations.find(it => it.id == this.design?.selectedLoadCombinationIdC2C);
    }

    public translate(key: string) {
        return this.localizationService.getString(`Agito.Hilti.C2C.LayerUtilization${this.isAS3600 ? '.AS3600' : ''}.${key}`);
    }
}
