import { NewDesignDataEntityC2C } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { SafetyFactorsAs } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities.Design.Options.ASbased';
import { DesignStandard } from '../generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { IDetailedDisplayDesign } from './display-design';
import { UserSettingsC2C as UserSettings } from './user-settings';

export interface ISafetyFactorsInputsData {
    // Context
    design?: () => IDetailedDisplayDesign;
    settings?: () => UserSettings;
    connectionType?: number;

    // Values
    partialSafetyFactorSteel?: number;
    partialSafetyFactorConcrete?: number;
    gammaRd?: number;

    safetyFactorsAs: SafetyFactorsAs;
}

export function setSafetyFactorsValuesToDesign(design: IDetailedDisplayDesign, data: ISafetyFactorsInputsData) {
    design.partialSafetyFactorSteel = data.partialSafetyFactorSteel;
    design.partialSafetyFactorConcrete = data.partialSafetyFactorConcrete;
    design.gammaRd = data.gammaRd;

    if (design.designStandardC2C?.id != DesignStandard.ASBased || !design.designOptionsAs)
        return;

    design.designOptionsAs.safetyFactors = {
        steelStress: data.safetyFactorsAs.steelStress,
        concreteStressCrossSection: data.safetyFactorsAs.concreteStressCrossSection,
        concreteStressShearInterface: data.safetyFactorsAs.concreteStressShearInterface
    } as SafetyFactorsAs;
}

export function setSafetyFactorsValuesToNewDesign(newDesign: NewDesignDataEntityC2C, data: ISafetyFactorsInputsData) {
    newDesign.partialSafetyFactorSteel = data.partialSafetyFactorSteel;
    newDesign.partialSafetyFactorConcrete = data.partialSafetyFactorConcrete;
    newDesign.gammaRd = data.gammaRd;

    if (newDesign.designStandard != DesignStandard.ASBased || !newDesign.designOptionsAs)
        return;

    newDesign.designOptionsAs.safetyFactors = {
        steelStress: data.safetyFactorsAs.steelStress,
        concreteStressCrossSection: data.safetyFactorsAs.concreteStressCrossSection,
        concreteStressShearInterface: data.safetyFactorsAs.concreteStressShearInterface
    } as SafetyFactorsAs;
}
