<div class="select-connection-application" *ngIf="dataLoaded"
    [class.standalone]="standalone">
    <div *ngIf="showConnection" class="section data"
         id="add-edit-design-select-connection-section"
         [class.no-margin]="collapseRegion.Connection">
         <pe-section
             [title]="translate('Agito.Hilti.C2C.AddEditDesign.Connection')"
             [collapsed]="collapseRegion.Connection"
             (collapsedChange)="collapseRegion.Connection = $any($event).detail"
             [collapseTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
             <c2c-grid-image-select
                 [numberOfColumns]="selectConnectionTypeC2C.numberOfColumns"
                 [items]="selectConnectionTypeC2C.items"
                 [selectedValue]="selectConnectionTypeC2C.selectedValue"
                 (selectedValueChange)="onSelectConnectionTypeC2C($any($event).detail)">
             </c2c-grid-image-select>
        </pe-section>
    </div>

    <div *ngIf="showApplication" class="section data"
         id="add-edit-design-select-application-section"
         [class.no-margin]="collapseRegion.Application">
         <pe-section
             [title]="translate('Agito.Hilti.C2C.AddEditDesign.Application')"
             [collapsed]="collapseRegion.Application"
             (collapsedChange)="collapseRegion.Application = $any($event).detail"
             [collapseTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
             <c2c-grid-image-select
                 [numberOfColumns]="selectApplicationTypeC2C.numberOfColumns"
                 [items]="selectApplicationTypeC2C.items"
                 [selectedValue]="selectApplicationTypeC2C.selectedValue"
                 (selectedValueChange)="onSelectApplicationTypeC2C($any($event).detail)">
             </c2c-grid-image-select>
        </pe-section>
    </div>
</div>
