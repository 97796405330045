<table *ngIf="table != null" [id]="table.id" class="table" aria-label="table">
    <thead>
        <tr *ngFor="let header of table.headerRows" [attr.style]="header.style">
            <th *ngFor="let cell of header.cells" scope="col" [attr.style]="cell.style">
                <ng-container *ngFor="let content of cell.contents">
                    <span *ngIf="content.contentType === TableCellContentType.Constant" [innerHTML]="content.value"></span>
                    <span *ngIf="content.contentType === TableCellContentType.TranslatableString" [l10n]="content.value"></span>
                </ng-container>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of table.rows" [attr.style]="row.style">
            <ng-container *ngFor="let cell of row.cells">
                <td [attr.colspan]="cell.colspan" [attr.rowspan]="cell.rowspan" [attr.style]="cell.style">
                    <ng-container *ngFor="let content of cell.contents">
                        <span *ngIf="content.contentType === TableCellContentType.Constant" [innerHTML]="content.value" class="content-type-constant" [style]="content.style"></span>
                        <span *ngIf="content.contentType === TableCellContentType.TranslatableString" class="content-type-translatable" [l10n]="content.value" [style]="content.style"></span>
                        <img *ngIf="content.contentType === TableCellContentType.Image" src="{{content.value}}" class="content-type-image" alt="content-image" [style]="content.style">
                        <div *ngIf="content.contentType === TableCellContentType.UnorderedList" >
                            <c2c-info-dialog-unordered-list [unorderedList]="content.objectValue"></c2c-info-dialog-unordered-list>
                        </div>
                        <div *ngIf="content.contentType === TableCellContentType.LineBreak">
                            <br>
                        </div>
                        <c2c-math-jax-paragraph [mathString]="content.value" *ngIf="content.contentType === TableCellContentType.LaTex" [style]=""></c2c-math-jax-paragraph>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>
