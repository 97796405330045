import { IAdvancedRebarSettingsInputsData } from '../entities/advanced-rebar-settings';
import { IDetailedDisplayDesign } from '../entities/display-design';
import {
    NewDesignDataEntityC2C
} from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';

export function setAdvancedRebarSettingsValuesToDesign(design: IDetailedDisplayDesign, data: IAdvancedRebarSettingsInputsData) {
    design.advancedRebarSettingsSteelStrainLimit = data.advancedRebarSettingsSteelStrainLimit;
}

export function setAdvancedRebarSettingsValuesToNewDesign(newDesignC2C: NewDesignDataEntityC2C, data: IAdvancedRebarSettingsInputsData) {
    newDesignC2C.advancedRebarSettingsSteelStrainLimit = data.advancedRebarSettingsSteelStrainLimit;
}
