<div class="safety-factors-inputs content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <!-- General options (currently PIR EU) -->
            <ng-container *ngIf="!useAusOptions">
                <div class="control-partialSafetyFactor">
                    <pe-numeric-text-box
                        [id]="partialSafetyFactorSteel.id"
                        [title]="partialSafetyFactorSteel.title"
                        [placeholder]="partialSafetyFactorSteel.placeholder"
                        [value]="data.partialSafetyFactorSteel"
                        (valueChange)="data.partialSafetyFactorSteel = $any($event).detail"
                        [unit]="partialSafetyFactorSteel.unit"
                        [minValue]="partialSafetyFactorSteel.minValue"
                        [maxValue]="partialSafetyFactorSteel.maxValue"
                        [disabled]="disabled"
                        [tooltip]="partialSafetyFactorSteel.tooltip"
                        [precision]="partialSafetyFactorSteel.precision">
                    </pe-numeric-text-box>
                </div>

                <div class="control-partialSafetyFactor">
                    <pe-numeric-text-box
                        [id]="partialSafetyFactorConcrete.id"
                        [title]="partialSafetyFactorConcrete.title"
                        [placeholder]="partialSafetyFactorConcrete.placeholder"
                        [value]="data.partialSafetyFactorConcrete"
                        (valueChange)="data.partialSafetyFactorConcrete = $any($event).detail"
                        [unit]="partialSafetyFactorConcrete.unit"
                        [minValue]="partialSafetyFactorConcrete.minValue"
                        [maxValue]="partialSafetyFactorConcrete.maxValue"
                        [disabled]="disabled"
                        [tooltip]="partialSafetyFactorConcrete.tooltip"
                        [precision]="partialSafetyFactorConcrete.precision">
                    </pe-numeric-text-box>
                </div>

                <div class="control-partialSafetyFactor">
                    <pe-numeric-text-box
                        [id]="gammaRd.id"
                        [title]="gammaRd.title"
                        [placeholder]="gammaRd.placeholder"
                        [value]="data.gammaRd"
                        (valueChange)="data.gammaRd = $any($event).detail"
                        [unit]="gammaRd.unit"
                        [minValue]="gammaRd.minValue"
                        [maxValue]="gammaRd.maxValue"
                        [disabled]="disabled"
                        [tooltip]="gammaRd.tooltip"
                        [precision]="gammaRd.precision">
                    </pe-numeric-text-box>
                </div>
            </ng-container>

            <!-- PIR AUS based options -->
            <ng-container *ngIf="useAusOptions">
                <div class="control-partialSafetyFactor">
                    <pe-numeric-text-box
                        [id]="steelStress.id"
                        [title]="steelStress.title"
                        [placeholder]="steelStress.placeholder"
                        [value]="data.safetyFactorsAs.steelStress"
                        (valueChange)="data.safetyFactorsAs.steelStress = $any($event).detail"
                        [unit]="steelStress.unit"
                        [minValue]="steelStress.minValue"
                        [maxValue]="steelStress.maxValue"
                        [disabled]="disabled"
                        [tooltip]="steelStress.tooltip"
                        [precision]="steelStress.precision">
                    </pe-numeric-text-box>
                </div>

                <div class="control-partialSafetyFactor">
                    <pe-numeric-text-box
                        [id]="concreteStressCrossSection.id"
                        [title]="concreteStressCrossSection.title"
                        [placeholder]="concreteStressCrossSection.placeholder"
                        [value]="data.safetyFactorsAs.concreteStressCrossSection"
                        (valueChange)="data.safetyFactorsAs.concreteStressCrossSection = $any($event).detail"
                        [unit]="concreteStressCrossSection.unit"
                        [minValue]="concreteStressCrossSection.minValue"
                        [maxValue]="concreteStressCrossSection.maxValue"
                        [disabled]="true"
                        [tooltip]="concreteStressCrossSection.tooltip"
                        [precision]="concreteStressCrossSection.precision">
                    </pe-numeric-text-box>
                </div>

                <div class="control-partialSafetyFactor">
                    <pe-numeric-text-box
                        [id]="concreteStressShearInterface.id"
                        [title]="concreteStressShearInterface.title"
                        [placeholder]="concreteStressShearInterface.placeholder"
                        [value]="data.safetyFactorsAs.concreteStressShearInterface"
                        (valueChange)="data.safetyFactorsAs.concreteStressShearInterface = $any($event).detail"
                        [unit]="concreteStressShearInterface.unit"
                        [minValue]="concreteStressShearInterface.minValue"
                        [maxValue]="concreteStressShearInterface.maxValue"
                        [disabled]="true"
                        [tooltip]="concreteStressShearInterface.tooltip"
                        [precision]="concreteStressShearInterface.precision">
                    </pe-numeric-text-box>
                </div>
            </ng-container>
        </div>
    </div>
</div>
